import type {
  MenuItem,
  MenuItemSection,
} from "@editor/components/common/designSystem/Menu";
import type { ContextMenuActions } from "@editor/types/component-tree";
import type { Hotkey } from "@utils/hotkeys";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type { VariantWithState } from "replo-runtime/shared/types";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { ReploComponentType } from "schemas/component";

import * as React from "react";

import { successToast } from "@editor/components/common/designSystem/Toast";
import { HotkeyIndicator } from "@editor/components/common/HotkeyIndicator";
import AskAIHeader from "@editor/components/editor/ai/AskAiHeader";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import {
  selectComponentDataMapping,
  selectDraftComponentIds,
  selectDraftComponentName,
  selectDraftComponentOrAncestorVariants,
  selectDraftComponentOrDescendantIsOneOfTypes,
  selectDraftComponentType,
  selectNearestComponentWithVariantsId,
  selectRootComponentId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { canGroupIntoContainer } from "@editor/utils/component";
import useContextMenuActions from "@hooks/useContextMenuActions";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import copy from "copy-to-clipboard";
import cloneDeep from "lodash-es/cloneDeep";
import { forEachComponentAndDescendants } from "replo-runtime";
import { hasOwnProperty, isEmpty } from "replo-utils/lib/misc";

const MENU_ITEM_ORDER = [
  // AI & Generation Section
  { type: "section", items: ["replo-ai"] },

  // Basic Operations Section
  {
    type: "section",
    items: [
      "copy",
      "paste",
      "pasteFigma",
      "duplicate",
      "turnInto",
      "rename",
      "delete",
    ],
  },

  // Component Organization Section
  {
    type: "section",
    items: ["groupContainer", "createTemplate", "exportToSection", "styles"],
  },

  // Styles & Debug Section
  {
    type: "section",
    items: [
      "copyJson",
      "replaceComponentJSON",
      "copyAiResponsiveInfo",
      "debug",
    ],
  },
] as const;

export default function useContextMenuItems(
  source: "canvasRightClickMenu" | "componentTree",
  onRename?: (() => void) | null,
) {
  const rootComponentId = useEditorSelector(selectRootComponentId);
  const contextMenuActions = useContextMenuActions();
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const draftComponentType = useEditorSelector(selectDraftComponentType);
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const activeVariant = useEditorSelector(
    selectDraftComponentOrAncestorVariants,
  ).find((variant) => variant.isActive);
  const componentWithVariantsId = useEditorSelector(
    selectNearestComponentWithVariantsId,
  );
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const isDebugMode = useIsDebugMode();
  const draftComponentName = useEditorSelector(selectDraftComponentName);
  const draftComponentOrDescendantIsText = useEditorSelector((state) =>
    selectDraftComponentOrDescendantIsOneOfTypes(state, ["text"]),
  );
  const contextMenuItems = React.useMemo(
    () =>
      getContextMenuItems({
        contextMenuActions,
        selectedIds: draftComponentIds,
        activeVariant,
        componentWithVariantsId: componentWithVariantsId ?? undefined,
        source,
        rootComponentId,
        onStartRename: onRename ?? null,
        isDebugMode,
        activeCanvas,
        draftComponentName,
        draftComponentOrDescendantIsText,
        componentDataMapping,
        draftComponentType,
      }),
    [
      contextMenuActions,
      draftComponentIds,
      activeVariant,
      componentWithVariantsId,
      source,
      rootComponentId,
      onRename,
      isDebugMode,
      activeCanvas,
      draftComponentName,
      draftComponentOrDescendantIsText,
      componentDataMapping,
      draftComponentType,
    ],
  );

  return contextMenuItems;
}

function getContextMenuItems(config: {
  contextMenuActions: ContextMenuActions | null;
  selectedIds: string[];
  activeVariant: VariantWithState | undefined;
  componentWithVariantsId: string | undefined;
  source: "canvasRightClickMenu" | "componentTree";
  rootComponentId: string | null;
  onStartRename: (() => void) | null;
  isDebugMode?: boolean;
  activeCanvas: EditorCanvas;
  draftComponentName: string | null;
  draftComponentType: ReploComponentType | null;
  draftComponentOrDescendantIsText: boolean;
  componentDataMapping: ComponentDataMapping;
}): MenuItem[] {
  const {
    contextMenuActions,
    selectedIds,
    activeVariant,
    componentWithVariantsId,
    source,
    rootComponentId,
    onStartRename = null,
    isDebugMode = false,
    activeCanvas,
    draftComponentName,
    draftComponentOrDescendantIsText,
    draftComponentType,
    componentDataMapping,
  } = config;
  const selectedNodesLength = selectedIds.length;
  const isMultiNodeSelected = selectedNodesLength > 1;
  const [selectedComponentId] = selectedIds;
  const isRootSelected = rootComponentId
    ? selectedIds.includes(rootComponentId)
    : false;

  if (!contextMenuActions) {
    return [];
  }

  const {
    handlePaste,
    handlePasteFromFigma,
    handleCopy,
    handleDuplicate,
    hasParent,
    handleReplaceComponent,
    handleReplaceAllContentWithPlaceholders,
    getStringFromComponentJson,
    getComponent,
    handleGroupContainer,
    handleGroupDelete,
    handleDelete,
    canDelete,
    handleOpenModal,
    getAssetComponent,
    handleChangeImage,
    forceSentryError,
    setDraftComponentId,
    handleResetStylesToDefaultState,
    handlePushPropsToDefaultState,
    handleCopyStyles,
    handlePersistStylesToUpstreamDevices,
    generateAiCopy,
    resetComponentAlignment,
    getAlignSelf,
    resetStyleOverrides,
    openCodeEditor,
    turnInto,
  } = contextMenuActions;

  const unorderedItems: MenuItem[] = [];
  if (selectedComponentId) {
    unorderedItems.push({
      id: "replo-ai",
      type: "leaf",
      title: (
        <div className="grow px-4 py-2">
          <AskAIHeader
            content="Generate AI Copy"
            showIcon
            isDisabled={!draftComponentOrDescendantIsText}
          />
        </div>
      ),
      onSelect: () => {
        generateAiCopy(selectedComponentId);
      },
      isDisabled: !draftComponentOrDescendantIsText,
    });
    if (openCodeEditor) {
      unorderedItems.push({
        id: "openCodeEditor",
        type: "leaf",
        title: "Open Code Editor",
        onSelect: () => openCodeEditor(),
      });
    }
  }
  if (
    !isRootSelected &&
    canGroupIntoContainer(selectedIds, componentDataMapping)
      .canGroupIntoContainer
  ) {
    unorderedItems.push({
      type: "leaf",
      id: "groupContainer",
      title: "Group Into Container",
      onSelect: () => handleGroupContainer([...selectedIds]),
      endEnhancer: hotkeyEndEnhancer("groupIntoContainer"),
    });
  }

  unorderedItems.push(
    {
      id: "createTemplate",
      type: "leaf",
      title: "Create Saved Component",
      onSelect: () => {
        handleOpenModal({
          type: "saveTemplateModal",
          props: {
            initialName: draftComponentName,
          },
        });
      },
      endEnhancer: hotkeyEndEnhancer("saveComponentTemplate"),
    },
    {
      id: "exportToSection",
      type: "leaf",
      title: "Export To Section",
      onSelect: () => {
        handleOpenModal({
          type: "exportToSectionModal",
        });
      },
    },
  );

  const getTurnIntoItems = (): MenuItem[] => {
    const turnIntoItems: MenuItem[] = [];
    if (selectedComponentId) {
      const component = componentDataMapping[selectedComponentId];

      const ancestorButton = component?.ancestorComponentData?.some(
        ([, type]) => type === "button",
      );
      const childButton = component?.containedComponentData?.some(
        ([, type]) => type === "button",
      );

      turnIntoItems.push({
        id: "turnIntoButton",
        type: "leaf",
        title: "Button",
        onSelect: () => turnInto({ type: "button" }),
        isDisabled:
          isRootSelected ||
          draftComponentType !== "container" ||
          Boolean(ancestorButton) ||
          Boolean(childButton),
      });
      turnIntoItems.push({
        id: "turnIntoProduct",
        type: "leaf",
        title: "Product",
        onSelect: () => turnInto({ type: "product" }),
        isDisabled: isRootSelected || draftComponentType !== "container",
      });

      const ancestorTicker = component?.ancestorComponentData?.some(
        ([, type]) => type === "marquee",
      );
      const childTicker = component?.containedComponentData?.some(
        ([, type]) => type === "marquee",
      );

      turnIntoItems.push({
        id: "turnIntoTicker",
        type: "leaf",
        title: "Ticker",
        onSelect: () =>
          turnInto({ type: "ticker", selectedComponentIds: selectedIds }),
        isDisabled:
          isRootSelected ||
          draftComponentType !== "container" ||
          Boolean(ancestorTicker) ||
          Boolean(childTicker),
      });

      const ancestorTooltip = component?.ancestorComponentData?.some(
        ([, type]) => type === "tooltip",
      );
      const childTooltip = component?.containedComponentData?.some(
        ([, type]) => type === "tooltip",
      );

      turnIntoItems.push({
        id: "addTooltipToComponent",
        type: "leaf",
        title: "Tooltip Trigger",
        onSelect: () =>
          turnInto({
            type: "tooltipTrigger",
            selectedComponentIds: selectedIds,
          }),
        isDisabled:
          isRootSelected ||
          Boolean(ancestorTooltip) ||
          Boolean(childTooltip) ||
          draftComponentType === "tooltip",
      });
    }

    return turnIntoItems;
  };

  const turnIntoItems = getTurnIntoItems();

  if (turnIntoItems.length > 0) {
    unorderedItems.push({
      id: "turnInto",
      type: "nested",
      title: "Turn Into",
      items: turnIntoItems,
    });
  }

  if (isMultiNodeSelected) {
    return unorderedItems.concat([
      {
        type: "leaf",
        id: "groupDelete",
        title: `Delete (${selectedNodesLength})`,
        onSelect: () => handleGroupDelete([...selectedIds]),
        endEnhancer: hotkeyEndEnhancer("delete"),
      },
      {
        type: "leaf",
        id: "groupCopy",
        title: `Copy (${selectedNodesLength})`,
        onSelect: () => handleCopy([...selectedIds]),
        endEnhancer: hotkeyEndEnhancer("copy"),
      },
      {
        type: "leaf",
        id: "paste",
        title: "Paste",
        onSelect: () => {
          void handlePaste("normal");
        },
        endEnhancer: hotkeyEndEnhancer("paste"),
      },
      {
        type: "leaf",
        id: "pasteFigma",
        title: "Paste from Figma",
        onSelect: () => {
          void handlePasteFromFigma();
        },
      },
    ]);
  }

  unorderedItems.push(
    {
      type: "leaf",
      id: "copy",
      title: "Copy",
      endEnhancer: hotkeyEndEnhancer("copy"),
      onSelect: () => handleCopy([...selectedIds]),
    },
    {
      type: "leaf",
      id: "paste",
      title: "Paste",
      endEnhancer: hotkeyEndEnhancer("paste"),
      onSelect: () => {
        void handlePaste("normal");
      },
    },
    {
      type: "leaf",
      id: "pasteFigma",
      title: "Paste from Figma",
      onSelect: () => {
        void handlePasteFromFigma();
      },
    },
  );

  if (!isMultiNodeSelected && !isRootSelected) {
    const assetComponent = getAssetComponent(selectedComponentId as string);
    if (assetComponent?.component) {
      const { component, value } = assetComponent;
      unorderedItems.push({
        id: "changeMediaContent",
        type: "leaf",
        title: component.type === "image" ? "Change Image" : "Change Video",
        onSelect: () => {
          handleOpenModal(
            component.type === "image"
              ? {
                  type: "assetLibraryModal",
                  props: {
                    referrer: "modifier/image",
                    value,
                    onChange: (value) => {
                      if (value) {
                        handleChangeImage(selectedComponentId as string, value);
                      }
                    },
                    assetContentType: "image",
                  },
                }
              : {
                  type: "assetLibraryModal",
                  props: {
                    referrer: "modifier/video",
                    value,
                    assetContentType: "video",
                  },
                },
          );
        },
      });
    }
    unorderedItems.push(
      {
        type: "leaf",
        id: "rename",
        title: "Rename",
        onSelect: () => onStartRename?.(),
        isDisabled: !onStartRename,
      },
      {
        type: "leaf",
        id: "duplicate",
        title: "Duplicate",
        onSelect: () => handleDuplicate(selectedComponentId as string, source),
        isDisabled: !hasParent(selectedComponentId as string),
        endEnhancer: hotkeyEndEnhancer("duplicate"),
      },
      {
        type: "leaf",
        id: "delete",
        title: "Delete",
        onSelect: () => handleDelete(selectedComponentId as string, source),
        isDisabled: !canDelete(selectedComponentId as string),
        endEnhancer: hotkeyEndEnhancer("delete"),
      },
    );
  }

  const getStateStyleItems = (): MenuItem[] => {
    if (
      !isMultiNodeSelected &&
      activeVariant &&
      selectedComponentId &&
      componentWithVariantsId
    ) {
      const isDisabled = activeVariant.name === "default";
      return [
        {
          id: "resetStylesToDefaultState",
          type: "leaf",
          title: "Reset Styles to Default State",
          onSelect: () => {
            handleResetStylesToDefaultState(
              selectedComponentId,
              activeVariant.id,
            );
          },
          isDisabled,
        },
        {
          id: "pushPropsToDefaultState",
          type: "leaf",
          title: "Push Changes to Default State",
          onSelect: () => {
            handlePushPropsToDefaultState(
              selectedComponentId,
              activeVariant.id,
            );
          },
          isDisabled,
        },
      ];
    }
    return [];
  };

  unorderedItems.push({
    id: "styles",
    type: "nested",
    title: "Styles",
    items: [
      {
        type: "leaf",
        id: "copyStyles",
        title: "Copy Styles",
        onSelect: () =>
          handleCopyStyles(selectedComponentId as string, activeVariant?.id),
        endEnhancer: hotkeyEndEnhancer("copyStyles"),
      },
      {
        type: "leaf",
        id: "pasteStyles",
        title: "Paste Styles",
        onSelect: () => {
          void handlePaste("alt");
        },
        endEnhancer: hotkeyEndEnhancer("pasteStyles"),
      },
      {
        type: "leaf",
        id: "resetContentsAlignment",
        title: "Reset Content Alignment",
        isDisabled: Boolean(
          selectedComponentId && !getAlignSelf(selectedComponentId),
        ),
        onSelect: () => {
          if (selectedComponentId) {
            resetComponentAlignment(selectedComponentId);
          }
        },
      },
      {
        type: "leaf",
        id: "resetStyleOverrides",
        title: "Reset Style Overrides",
        isDisabled: activeCanvas === "desktop",
        onSelect: () => {
          if (selectedComponentId) {
            resetStyleOverrides(selectedComponentId, activeVariant?.id);
          }
        },
      },
      ...(isDebugMode
        ? [
            {
              type: "leaf",
              id: "stripNonDesktopStyles",
              title: "Strip Non-Desktop Styles",
              onSelect: () => {
                if (!selectedComponentId) {
                  return "No component selected";
                }
                const component = cloneDeep(getComponent(selectedComponentId));
                if (!component) {
                  return "Component not found";
                }

                forEachComponentAndDescendants(component, (component) => {
                  delete component.props["style@md"];
                  delete component.props["style@sm"];
                });
                handleReplaceComponent(
                  selectedComponentId as string,
                  component,
                );
              },
            } as const,
          ]
        : []),
      ...getStateStyleItems(),
    ],
  });

  const classNameForJSXMenuItems = "w-[12.5rem] px-4 py-2";
  if (isDebugMode) {
    unorderedItems.push(
      {
        type: "leaf",
        id: "copyJson",
        title: (
          <div
            className={classNameForJSXMenuItems}
            onClick={() => {
              copy(getStringFromComponentJson(selectedComponentId as string));
              successToast("Component JSON Copied", "");
            }}
          >
            Copy Component JSON
          </div>
        ),
      },
      {
        id: "replaceComponentJSON",
        type: "leaf",
        title: (
          <div
            className={classNameForJSXMenuItems}
            onClick={() => {
              let componentJSON = window.prompt();
              if (componentJSON) {
                try {
                  componentJSON = JSON.parse(componentJSON);
                } catch {}

                if (componentJSON) {
                  handleReplaceComponent(
                    selectedComponentId as string,
                    componentJSON,
                  );
                }
              }
            }}
          >
            Set Component JSON
          </div>
        ),
      },
      {
        type: "leaf",
        id: "copyAiResponsiveInfo",
        title: (
          <div
            className={classNameForJSXMenuItems}
            onClick={() => {
              const component = cloneDeep(
                getComponent(selectedComponentId as string),
              );
              if (!component) {
                return "Component not found";
              }
              const actions: object[] = [];

              forEachComponentAndDescendants(component, (component) => {
                // NOTE (Gabe 2024-07-09): We merge md and sm styles here
                // because we currently only care about mobile responsivity. If
                // we want to have tablet specific styles we'll have to generate
                // multiple actions.
                if (
                  !isEmpty(component.props["style@md"]) ||
                  !isEmpty(component.props["style@sm"])
                ) {
                  actions.push({
                    componentId: component.id,
                    type: "setStylesMobile",
                    value: {
                      ...component.props["style@md"],
                      ...component.props["style@sm"],
                    },
                  });
                }
                delete component.props["style@md"];
                delete component.props["style@sm"];
              });

              const result = { component, actions };
              copy(JSON.stringify(result, null, 2));
              successToast("AI Responsive Info Copied", "");
            }}
          >
            Copy AI Responsive Info
          </div>
        ),
      },
    );

    unorderedItems.push({
      type: "nested",
      id: "debug",
      title: "Other Debug Options",
      items: [
        {
          type: "leaf",
          id: "copyComponentId",
          title: (
            <div
              className={classNameForJSXMenuItems}
              onClick={() => {
                copy(selectedComponentId!);
                successToast("Component ID Copied", "");
              }}
            >
              Copy Component ID
            </div>
          ),
        },
        {
          id: "setDraftElement",
          type: "leaf",
          title: (
            <div
              className={classNameForJSXMenuItems}
              onClick={(e) => {
                e.stopPropagation();
                const id = window.prompt();
                if (id) {
                  setDraftComponentId(id);
                }
              }}
            >
              Set Draft Component ID
            </div>
          ),
        },
        {
          id: "cleanComponentWithoutImages",
          type: "leaf",
          title: "Replace all content with placeholders",
          onSelect: () => {
            if (selectedComponentId) {
              handleReplaceAllContentWithPlaceholders(selectedComponentId);
            }
          },
        },
        {
          id: "forceSentryError",
          type: "leaf",
          title: "Force Sentry Error",
          onSelect: () => {
            forceSentryError();
          },
        },
        {
          type: "nested",
          title: "Persist Styles Upstream",
          items: [
            {
              id: "persistStylesToUpstreamDevicesExcludingDescendants",
              type: "leaf",
              title: "Copy Styles to Larger Devices (This Component Only)",
              onSelect: () => {
                handlePersistStylesToUpstreamDevices(
                  selectedComponentId as string,
                  false,
                );
              },
            },
            {
              id: "persistStylesToUpstreamDevicesExcludingDescendants",
              type: "leaf",
              title: "Copy Styles to Larger Devices (Include Descendants)",
              onSelect: () => {
                handlePersistStylesToUpstreamDevices(
                  selectedComponentId as string,
                  true,
                );
              },
            },
          ],
        },
      ],
    });
  }

  // Instead of sorting, we'll organize items into sections
  const itemsById = Object.fromEntries(
    unorderedItems
      .filter((item) => hasOwnProperty(item, "id"))
      .map((item) => [item.id, item]),
  );

  // Create sections with ordered items
  const sections: MenuItemSection[] = MENU_ITEM_ORDER.map((section) => ({
    type: "section" as const,
    items: section.items.map((id) => itemsById[id]),
  })).filter((section) => section.items.length > 0);

  return sections;
}

function hotkeyEndEnhancer(hotkey: Hotkey) {
  const HotKeyEndEnhancer = () => <HotkeyIndicator hotkey={hotkey} title="" />;
  return HotKeyEndEnhancer;
}
