import type { Component } from "replo-runtime";
import type {
  DesignLibrarySavedStyleMetadata,
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import { selectComponent } from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";

import { forEachComponentAndDescendants } from "replo-runtime";
import { getSavedStyleValue } from "replo-runtime/shared/savedStyles";
import { allMediaSizeStyles } from "replo-runtime/shared/utils/breakpoints";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";

import useGetDesignLibrarySavedStyles from "./useGetDesignLibrarySavedStyles";

function useGetDesignLibraryComponentReferences() {
  const { textSavedStyles, colorSavedStyles, designLibrary } =
    useGetDesignLibrarySavedStyles();
  const store = useEditorStore();

  if (!designLibrary) {
    return {
      getDesignLibraryComponentReferences: () => null,
    };
  }

  const findSavedStylesInComponent = (component: Component) => {
    const savedStylesReferences: Record<
      string,
      SavedTextStyle | SavedColorStyle
    > = {};

    const processSavedStyleValue = (styleValue: string) => {
      const savedStyle = getSavedStyleValue(
        [...textSavedStyles, ...colorSavedStyles],
        styleValue,
      );

      if (savedStyle) {
        savedStylesReferences[savedStyle.id] = savedStyle;
      }
    };

    forEachComponentAndDescendants(component, (componentOrDescendant) => {
      allMediaSizeStyles.forEach((mediaSize) => {
        const styleProp = componentOrDescendant.props[mediaSize];
        if (styleProp) {
          Object.values(styleProp).forEach((styleValue) => {
            if (
              typeof styleValue === "string" &&
              isDynamicDesignLibraryValue(styleValue)
            ) {
              processSavedStyleValue(styleValue);
            }
          });
        }
      });
    });

    return savedStylesReferences;
  };

  const getDesignLibraryComponentReferences = (
    componentIds: string[],
  ): DesignLibrarySavedStyleMetadata => {
    const allSavedStylesReferences = componentIds.reduce(
      (acc, componentId) => {
        const component = selectComponent(store.getState(), componentId);

        if (!component) {
          return acc;
        }

        return {
          ...acc,
          ...findSavedStylesInComponent(component),
        };
      },
      {} as Record<string, SavedTextStyle | SavedColorStyle>,
    );

    return {
      library: {
        [designLibrary.id]: {
          styles: allSavedStylesReferences,
        },
      },
    };
  };

  return {
    getDesignLibraryComponentReferences,
  };
}

export default useGetDesignLibraryComponentReferences;
