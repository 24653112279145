import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { isNotNullish } from "replo-utils/lib/misc";

import useScript from "../../../shared/hooks/useScript";
import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { getCurrentComponentContext } from "../../../shared/utils/context";
import { useComponentClassNames } from "../../../shared/utils/renderComponents";
import { evaluateVariableAsString } from "../../AlchemyVariable";
import { RenderComponentPlaceholder } from "../RenderComponentPlaceholder";
import { customProperties } from "./config";

const TikTokEmbed: React.FC<RenderComponentProps> = ({
  component,
  context,
  componentAttributes,
}) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [scriptSrc, setScriptSrc] = React.useState<string>();
  const [html, setHTML] = React.useState<string>();
  const url = component.props.url;

  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);

  const componentContext = getCurrentComponentContext(component.id, 0);
  // TODO (Noah, 2023-05-23): Pretty sure we don't need this dynamic data checking
  // because we evaluate all props prior to passing them to runtime components now
  const evaluatedUrl =
    isDynamicDataValue(url) && componentContext
      ? evaluateVariableAsString(url!, componentContext)
      : null;
  const newUrl = evaluatedUrl ? evaluatedUrl.slice(3, -4) : url;

  const scriptPatternsToRemoveBeforeLoad = [
    scriptSrc,
    "ttEmbedLibScript",
  ].filter(isNotNullish);

  const { status } = useScript(scriptSrc, {
    scriptPatternsToRemoveBeforeLoad,
    globalWindow,
  });

  React.useEffect(() => {
    if (status === "success") {
      setIsLoaded(true);
    }
  }, [status]);

  React.useEffect(() => {
    setScriptSrc(undefined);
    // NOTE (Chance 2024-04-12): Because we will need to set some state after
    // fetching, we want to avoid race conditions in case the URL changes while
    // a request is in-flight. This flag gets reset when the effect clean-up
    // function is called so we can check it before updating state.
    let isFetchingForCurrentUrl = true;
    fetch(`https://www.tiktok.com/oembed?url=${newUrl}`)
      .then((res) => res.json())
      .then((res: any) => {
        if (!isFetchingForCurrentUrl) {
          return;
        }
        if (res && res.status_msg) {
          throw new Error(res.status_msg);
        }
        if (!res || !res.html) {
          throw new Error("API response doesn't look right");
        }

        const htmlString = res.html;

        const document = globalWindow?.document;
        if (document) {
          const tempElement = document.createElement("div");
          tempElement.innerHTML = htmlString;

          const scriptTag = tempElement.querySelectorAll("script")[0];

          setScriptSrc(scriptTag && scriptTag.src);
        }
        setHTML(
          htmlString.slice(0, Math.max(0, htmlString.indexOf("<script"))),
        );
      })
      .catch(() => {
        if (isFetchingForCurrentUrl) {
          setIsLoaded(false);
        }
      });

    return () => {
      isFetchingForCurrentUrl = false;
    };
  }, [globalWindow, newUrl]);

  const classNameMap = useComponentClassNames(
    "tikTokEmbed",
    component,
    context,
  );

  return (
    <div {...componentAttributes}>
      {isLoaded ? (
        <div
          className={classNameMap?.inner}
          style={{ [customProperties.display]: html ? "flex" : "none" }}
          dangerouslySetInnerHTML={{ __html: html || "" }}
        />
      ) : (
        isEditorApp && (
          <RenderComponentPlaceholder title="Once you set the TikTok embed URL, the TikTok embed will appear here." />
        )
      )}
    </div>
  );
};

export default TikTokEmbed;
