import React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

interface DescriptionProps {
  description: React.ReactNode;
  size: "base" | "sm" | "lg";
  validityState?: "valid" | "invalid";
  className?: string;
}

const Description: React.FC<DescriptionProps> = ({
  description,
  size = "base",
  validityState,
  className,
}) => {
  return (
    <p
      className={twMerge(
        "text-placeholder mt-1.5",
        validityState === "invalid" && "text-danger",
        size === "sm" && "text-xs",
        size === "base" && "text-sm",
        size === "lg" && "text-base",
        className,
      )}
    >
      {description}
    </p>
  );
};

export default Description;
