import type {
  GradientValue,
  SolidOrGradient,
  SolidValue,
} from "replo-runtime/shared/types";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { SavedStyleColorAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import {
  Group,
  GroupHeader,
  GroupHeaderActionButton,
  GroupTitleContainer,
} from "@common/designSystem/Group";
import { Input } from "@common/designSystem/Input";
import FormFieldXButton from "@common/FormFieldXButton";
import { ColorSavedStyleModifier } from "@editor/components/designLibrary/ColorSavedStyleModifier";
import SavedStyleRow from "@editor/components/designLibrary/SavedStyleRow";
import SavedStylesEmptyState from "@editor/components/designLibrary/SavedStylesEmptyState";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { useCurrentProjectId } from "@editor/hooks/useCurrentProjectId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  getFormattedColorWithoutOpacity,
  isSolidColor,
} from "@editor/utils/colors";
import { generateNewName } from "@editor/utils/designLibrary";
import { docs } from "@editor/utils/docs";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import IconButton from "@replo/design-system/components/button/IconButton";
import Tooltip from "@replo/design-system/components/tooltip";
import { BsInfoCircleFill, BsPlus, BsSearch, BsSliders } from "react-icons/bs";
import {
  gradientToCssGradient,
  isCssGradient,
} from "replo-runtime/shared/utils/gradient";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { v4 as uuidv4 } from "uuid";

type SavedStylesColorListProps = {
  onSelectSavedStyle?: (id: string) => void;
  allowsGradientSelection?: boolean;
  value?: string | SolidOrGradient | ReploMixedStyleValue | null;
};

const SavedStylesColorList: React.FC<SavedStylesColorListProps> = ({
  onSelectSavedStyle,
  allowsGradientSelection,
  value,
}) => {
  const logEvent = useLogAnalytics();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isColorModifierPopoverOpen, setIsColorModifierPopoverOpen] =
    React.useState(false);
  const [editingSavedStyleId, setEditingSavedStyleId] = React.useState<
    string | null
  >(null);
  const { colorSavedStyles, designLibrary } = useGetDesignLibrarySavedStyles();
  const projectId = useCurrentProjectId();
  const { mutateAsync: createStyleMutation } =
    trpc.designLibrary.savedStyles.create.useMutation({
      onSuccess: () => void trpcUtils.designLibrary.get.invalidate(),
    });

  const filteredStyles = colorSavedStyles.filter((style) => {
    const matchesSearch = style.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const isValidStyle =
      allowsGradientSelection ||
      (typeof style.attributes.color === "string" &&
        !isCssGradient(style.attributes.color));

    return matchesSearch && isValidStyle;
  });

  const getColorValue = () => {
    if (!value || isMixedStyleValue(value)) {
      return null;
    }

    if (typeof value === "string") {
      return value;
    }

    if (isSolidColor(value)) {
      return getFormattedColorWithoutOpacity(
        (value as SolidValue).color ?? null,
      );
    }
    return gradientToCssGradient((value as GradientValue).gradient);
  };

  const colorValue = getColorValue();

  const handleAddNewColorStyle = async () => {
    if (!projectId) {
      return null;
    }

    const newName = generateNewName("basic", {
      savedStyles: colorSavedStyles,
      type: "color",
      colorType: "basic",
    });

    const savedStyleToCreate = {
      id: uuidv4(),
      name: newName,
      attributes: {
        type: "color",
        color: colorValue,
        colorType: "basic",
      } as SavedStyleColorAttributes,
    };
    await createStyleMutation({
      projectId,
      type: "color",
      ...savedStyleToCreate,
    });

    logEvent("library.style.add", {
      type: "color",
      tag: savedStyleToCreate.attributes.colorType,
    });

    setIsColorModifierPopoverOpen(true);
    setEditingSavedStyleId(savedStyleToCreate.id);
  };

  return (
    <>
      <ColorSavedStyleModifier
        isPopoverOpen={isColorModifierPopoverOpen}
        onPopoverOpenChange={(isOpen: boolean) => {
          if (!isOpen) {
            setEditingSavedStyleId(null);
          }
          setIsColorModifierPopoverOpen(isOpen);
        }}
        editingSavedStyle={
          colorSavedStyles.find(
            (savedStyle) => savedStyle.id === editingSavedStyleId,
          ) ?? null
        }
        sideOffset={14}
        openedFrom="rightBar"
      />
      <Group
        name="Color Styles"
        className="w-full"
        header={
          <GroupHeader
            hideEndEnhancerOnGroupClosed={false}
            titleEnhancer={
              <Tooltip
                content="Learn more about color styles"
                triggerAsChild={false}
              >
                <a
                  href={docs.savedStyles.color}
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsInfoCircleFill className="text-subtle" size={12} />
                </a>
              </Tooltip>
            }
            endEnhancer={
              colorValue && (
                <GroupHeaderActionButton
                  aria-label="Add new color style"
                  onClick={() => void handleAddNewColorStyle()}
                >
                  <div className="flex flex-row items-center justify-end">
                    <IconButton
                      className="p-0 h-[unset]"
                      icon={<BsPlus size={20} className="text-muted" />}
                      tooltipText="Add new color style"
                      variant="tertiary"
                      size="sm"
                      // NOTE (Fran 2023-10-04): With need this to avoid nesting buttons.
                    />
                  </div>
                </GroupHeaderActionButton>
              )
            }
          >
            <GroupTitleContainer className="h-8" />
          </GroupHeader>
        }
      >
        <div className="flex flex-col gap-2 w-full">
          <Input
            size="sm"
            value={searchTerm}
            startEnhancer={<BsSearch />}
            endEnhancer={() =>
              searchTerm?.trim() && (
                <FormFieldXButton onClick={() => setSearchTerm("")} />
              )
            }
            placeholder="Search saved styles..."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="flex flex-col gap-1 h-28 overflow-y-auto styled-scrollbar">
            {filteredStyles.length > 0 ? (
              filteredStyles.map((style) => (
                <SavedStyleRow
                  key={style.id}
                  name={style.name}
                  value={
                    getFormattedColorWithoutOpacity(style.attributes.color)!
                  }
                  type="color"
                  onClick={() =>
                    onSelectSavedStyle?.(
                      `{{ library.${designLibrary?.id}.styles.${style.id}.attributes.color }}`,
                    )
                  }
                  endEnhancer={
                    <BsSliders
                      className="text-black rotate-90"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingSavedStyleId(style.id);
                        setIsColorModifierPopoverOpen(true);
                      }}
                    />
                  }
                />
              ))
            ) : (
              <SavedStylesEmptyState title="No Styles Found" showCta={false} />
            )}
          </div>
        </div>
      </Group>
    </>
  );
};

export default SavedStylesColorList;
