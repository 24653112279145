import type { ConstructedAnalyticsLink } from "schemas/generated/analyticsLink";
import type { Experiment } from "schemas/generated/experiment";

import * as React from "react";

import { successToast } from "@editor/components/common/designSystem/Toast";
import { routes } from "@editor/utils/router";

import { StatusTag } from "@/features/experiments/components/StatusTag";
import { NO_LINK_TEXT } from "@/features/experiments/utils";
import IconButton from "@replo/design-system/components/button/IconButton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@replo/design-system/components/shadcn/core/table";
import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import {
  format,
  formatDistanceStrict,
  formatDistanceToNowStrict,
} from "date-fns";
import { BiCopy } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { generatePath, Link, useParams } from "react-router-dom";
import { getExperimentStatus } from "schemas/experiment";

const TABLE_METRICS = [
  { label: "Name", key: "name", className: "w-[20%]" },
  { label: "Status", key: "status", className: "w-[10%]" },
  { label: "Path", key: "description", className: "w-[40%]" },
  { label: "Created", key: "createdAt", className: "w-[15%]" },
  { label: "Duration", key: "duration", className: "w-[15%]" },
];

type AllExperimentsTableProps = {
  experiments: Experiment[];
  links: ConstructedAnalyticsLink[];
};

export const AllExperimentsTable: React.FC<AllExperimentsTableProps> = ({
  experiments,
  links,
}) => {
  return (
    <div className="w-full">
      <Table className="table-fixed border-separate border-spacing-0">
        <TableHeader className="font-medium text-xs text-default">
          <TableRow>
            {TABLE_METRICS.map((metric, index) => (
              <CustomTableHead
                key={metric.key}
                label={metric.label}
                className={classNames(metric.className, {
                  "border-l rounded-tl": index === 0,
                  "border-r border-l-0 rounded-tr":
                    index === TABLE_METRICS.length - 1,
                })}
              />
            ))}
          </TableRow>
        </TableHeader>

        <TableBody>
          {experiments.map((experiment) => (
            <ExperimentRow
              link={links.find(
                (link) =>
                  link.id ===
                  (experiment.completedAnalyticsLinkId ??
                    experiment.analyticsLinkId),
              )}
              key={experiment.id}
              experiment={experiment}
            />
          ))}
        </TableBody>
      </Table>

      {experiments.length === 0 && (
        <div className="flex justify-center items-center h-[300px] text-slate-500">
          No experiments yet.
        </div>
      )}
    </div>
  );
};

const ExperimentRow = ({
  link,
  experiment: { id, name, createdAt, activatedAt, completedAt, archivedAt },
}: {
  link: ConstructedAnalyticsLink | undefined;
  experiment: Experiment;
}) => {
  const { workspaceId } = useParams();
  const status = getExperimentStatus({
    createdAt,
    activatedAt,
    completedAt,
    archivedAt,
  });
  const path = generatePath(
    status === "draft"
      ? routes.workspace.experiments.details
      : routes.workspace.experiments.results,
    {
      workspaceId,
      experimentId: id,
    },
  );
  const createDateRelative = formatDistanceToNowStrict(createdAt, {
    addSuffix: true,
  });
  const createDateHuman = format(createdAt, "MMMM d, hh:mma");
  const duration = activatedAt
    ? formatDistanceStrict(activatedAt, completedAt ?? archivedAt ?? new Date())
    : "-";
  const linkUrl = link?.url;
  const hasLinkUrl = Boolean(linkUrl);

  return (
    <TableRow className="border-collapse">
      <TableCell className="w-[20%] border-b border-l">
        <Link
          className="text-blue-600 hover:underline truncate block"
          to={path}
        >
          {name}
        </Link>
      </TableCell>
      <TableCell className="w-[10%] border-b">
        <StatusTag status={status} />
      </TableCell>
      <TableCell className="w-[30%] border-b">
        <div className="flex flex-row gap-x-2 items-center">
          <span
            className={classNames(
              "truncate",
              hasLinkUrl ? "text-default" : "text-slate-400",
            )}
          >
            {linkUrl ?? NO_LINK_TEXT}
          </span>
          {hasLinkUrl && (
            <IconButton
              tooltipText="Copy experiment URL"
              variant="tertiary"
              className="h-7 w-7 flex-shrink-0"
              icon={<BiCopy size={16} />}
              onClick={() => {
                copy(linkUrl!);
                successToast("Experiment URL Copied", "");
              }}
            />
          )}
        </div>
      </TableCell>
      <TableCell className="w-[20%] border-b">
        <div className="text-slate-500 truncate">
          <Tooltip content={createDateHuman} triggerAsChild>
            <span className="cursor-default" tabIndex={0}>
              {createDateRelative}
            </span>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell className="w-[10%] border-b border-r">
        <div className="text-slate-500 truncate">{duration}</div>
      </TableCell>
    </TableRow>
  );
};

type CustomTableHeadProps = {
  label: string;
  key: string;
  tooltip?: string;
  className?: string;
};

const CustomTableHead = ({
  label,
  tooltip,
  className,
}: CustomTableHeadProps) => {
  return (
    <TableHead
      className={classNames(
        "overflow-hidden bg-subtle border border-r-0 border-l-0",
        className,
      )}
    >
      <div className="flex items-center gap-2">
        <span className="truncate">{label}</span>
        {tooltip && (
          <Tooltip content={tooltip} triggerAsChild side="bottom">
            <span className="flex-shrink-0 pl-2">
              <BsInfoCircle className="text-slate-400" />
            </span>
          </Tooltip>
        )}
      </div>
    </TableHead>
  );
};
