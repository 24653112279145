import * as React from "react";

import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { Combobox } from "@replo/design-system/components/combobox";
import { BsCaretDownFill, BsGlobe2 } from "react-icons/bs";

type AnalyticsHostComboboxProps = {
  onChange: (value: string) => void;
  disabled?: boolean;
};

const AnalyticsUrlHostCombobox = ({
  onChange,
  disabled,
}: AnalyticsHostComboboxProps) => {
  const { query, workspaceUrlHosts } = useAnalyticsQueryContext();

  const workspaceUrlHostOptions = workspaceUrlHosts.data.map((host) => ({
    value: host,
    label: host,
  }));

  return workspaceUrlHosts.isLoading ? (
    <SimpleSkeletonLoader width="100%" height="24px" />
  ) : (
    <Combobox
      options={workspaceUrlHostOptions}
      value={query.urlHosts[0] ?? ""}
      onChange={onChange}
      isDisabled={disabled}
      startEnhancer={<BsGlobe2 className="h-4 w-4" />}
      endEnhancer={<BsCaretDownFill className="h-2 w-2 text-subtle" />}
      placeholder={query.urlHosts[0] ?? ""}
    />
  );
};

export default AnalyticsUrlHostCombobox;
