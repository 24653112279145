import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

type ModifierLabelProps = {
  label: string;
  className?: string;
};

const ModifierLabel: React.FC<ModifierLabelProps> = ({ label, className }) => {
  return (
    // NOTE (Sebas, 2024-10-01): This `flex` is necessary to make the label take
    // up the full width of its container. Adding it to the next div element does
    // not work.
    <div className="flex">
      <div
        className={twMerge(
          "flex text-xs text-muted whitespace-nowrap select-none w-[74px]",
          className,
        )}
      >
        {label}
      </div>
    </div>
  );
};

export default ModifierLabel;
