import type { ReploComponentType } from "schemas/component";
import type { ModifierGroupType } from "schemas/modifiers";
import type { RuntimeStyleAttribute } from "schemas/styleAttribute";

import { mapNull } from "replo-runtime/shared/utils/optional";
import { filterNulls } from "replo-utils/lib/array";
import { ItemsConfigType } from "schemas/dynamicData";

const getContainerModifierGroupTypes = (): ModifierGroupType[] => {
  return [
    { type: "size" },
    { type: "layout" },
    { type: "rowColumnSpans" },
    { type: "visibility" },
    { type: "spacing" },
    { type: "positioning" },
    {
      type: "fontAndColor",
      allowsGradientSelection: false,
      allowsFontSelection: true,
    },
    { type: "border" },
    { type: "background" },
    { type: "effects" },
  ];
};

export const getIconModifierGroupTypes = (): ModifierGroupType[] => {
  return [
    { type: "size" },
    { type: "layout" },
    { type: "rowColumnSpans" },
    { type: "visibility" },
    { type: "spacing" },
    { type: "positioning" },
    { type: "icon" },
    { type: "border" },
    { type: "background" },
    { type: "effects" },
  ];
};

export const getEmbedModifierGroupTypes = (): ModifierGroupType[] => {
  return [
    { type: "size" },
    { type: "layout" },
    { type: "rowColumnSpans" },
    { type: "visibility" },
    { type: "spacing" },
    { type: "positioning" },
    { type: "border" },
  ];
};

export const getVariantSelectDropdownModifierGroupTypes =
  (): ModifierGroupType[] => {
    return [
      { type: "size" },
      { type: "layout" },
      { type: "rowColumnSpans" },
      { type: "visibility" },
      { type: "positioning" },
      { type: "spacing" },
      { type: "textStyle", includeTextEditor: false },
      { type: "border" },
      { type: "background" },
    ];
  };

export const getTextStyleGroupTypes = (colorValue: string | null) => {
  const isGradient = colorValue === "alchemy:gradient";
  const groupTypes: ModifierGroupType[] = [
    { type: "size" },
    { type: "layout" },
    { type: "rowColumnSpans" },
    { type: "visibility" },
    { type: "textStyle", includeTextEditor: true },
    { type: "spacing" },
    { type: "positioning" },
    { type: "border" },
  ];

  if (!isGradient) {
    /* Note (Noah, 2021-08-25): Because of how gradient text works (background clip)
      it's not possible to have background color/image at the same time as gradient text */
    groupTypes.push({ type: "background" });
  }
  groupTypes.push({ type: "effects" });

  return groupTypes;
};

export const componentTypeToModifierGroups: Record<
  ReploComponentType,
  | ModifierGroupType[]
  | ((config: {
      colorValue: string | null;
      textValue?: string;
    }) => ModifierGroupType[])
  | null
> = {
  accordionBlock: getContainerModifierGroupTypes(),
  productCollection: getContainerModifierGroupTypes(),
  countdownTimer: getContainerModifierGroupTypes(),
  quantitySelector: getContainerModifierGroupTypes(),
  product: getContainerModifierGroupTypes(),
  variantSelect: getContainerModifierGroupTypes(),
  optionSelect: getContainerModifierGroupTypes(),
  sellingPlanSelect: getContainerModifierGroupTypes(),
  circle: getContainerModifierGroupTypes(),
  container: getContainerModifierGroupTypes(),
  toggleContainer: getContainerModifierGroupTypes(),
  toggleIndicator: getContainerModifierGroupTypes(),
  collapsible: getContainerModifierGroupTypes(),
  collapsibleV2: getContainerModifierGroupTypes(),
  collapsibleV2Header: getContainerModifierGroupTypes(),
  collapsibleV2Content: getContainerModifierGroupTypes(),
  starRating: getContainerModifierGroupTypes(),
  rechargeSubscriptionWidget: getContainerModifierGroupTypes(),
  staySubscriptionWidget: getContainerModifierGroupTypes(),
  okendoProductRatingSummary: getContainerModifierGroupTypes(),
  okendoReviewsWidget: getContainerModifierGroupTypes(),
  junipProductRating: getContainerModifierGroupTypes(),
  junipReviews: getContainerModifierGroupTypes(),
  yotpoProductRating: getContainerModifierGroupTypes(),
  yotpoReviews: getContainerModifierGroupTypes(),
  looxProductRating: getContainerModifierGroupTypes(),
  looxReviews: getContainerModifierGroupTypes(),
  knoCommerceWidget: getContainerModifierGroupTypes(),
  reviewsIoProductRating: getContainerModifierGroupTypes(),
  reviewsIoReviews: getContainerModifierGroupTypes(),
  spinner: getContainerModifierGroupTypes(),
  carouselPanelsCount: getContainerModifierGroupTypes(),
  text: ({ colorValue }) => getTextStyleGroupTypes(colorValue),
  image: [
    { type: "size" },
    { type: "rowColumnSpans" },
    { type: "visibility" },
    { type: "imageSource" },
    { type: "spacing" },
    { type: "positioning" },
    { type: "border" },
    { type: "effects" },
  ],
  button: ({ textValue }) => {
    return filterNulls([
      { type: "size" },
      { type: "layout" },
      { type: "visibility" },
      // Note (Noah, 2022-11-07, REPL-4932): Buttons with children should not
      // allow editing text, but for old buttons which don't have children (those
      // on old pages) we have to still show the text modifier so the user can
      // edit that text. Note that even if the text is an empty string, we still
      // want to show the text modifier. Newer buttons will always have no `text`
      // prop at all.
      textValue ? { type: "textStyle", includeTextEditor: true } : null,
      {
        type: "fontAndColor",
        allowsGradientSelection: true,
        allowsFontSelection: false,
      },
      { type: "spacing" },
      { type: "positioning" },
      { type: "border" },
      { type: "background" },
      { type: "effects" },
    ]);
  },
  modal: [
    { type: "size" },
    { type: "layout" },
    { type: "spacing" },
    { type: "background" },
    { type: "border" },
    { type: "effects" },
  ],
  spacer: [
    { type: "size" },
    { type: "layout" },
    { type: "visibility" },
    { type: "spacing" },
    { type: "positioning" },
    { type: "border" },
    { type: "background" },
    { type: "effects" },
  ],
  icon: getIconModifierGroupTypes(),
  collectionSelect: [
    { type: "size" },
    { type: "layout" },
    { type: "visibility" },
    { type: "spacing" },
    { type: "positioning" },
    {
      type: "items",
      allowsDynamicDataWhenAvailable: true,
      allowedItemTypes: [ItemsConfigType.dataTable, ItemsConfigType.inline],
      title: "Collection Detail",
      field: "props.items",
    },
    ...getContainerModifierGroupTypes(),
  ],
  player: [
    { type: "size" },
    { type: "layout" },
    { type: "visibility" },
    { type: "spacing" },
    { type: "positioning" },
    { type: "videoSource" },
    { type: "border" },
    { type: "effects" },
  ],
  dropdown: [
    { type: "size" },
    { type: "layout" },
    { type: "visibility" },
    { type: "spacing" },
    { type: "positioning" },
    {
      type: "items",
      allowsDynamicDataWhenAvailable: false,
      title: "Collection Detail",
      field: "props.items",
    },
    { type: "border" },
    { type: "background" },
  ],
  collection: [
    { type: "size" },
    { type: "layout" },
    { type: "visibility" },
    { type: "spacing" },
    { type: "positioning" },
    {
      type: "items",
      allowsDynamicDataWhenAvailable: true,
      title: "Collection Detail",
      field: "props.items",
    },
    ...getContainerModifierGroupTypes(),
  ],
  collectionV2: getContainerModifierGroupTypes(),
  temporaryCart: getContainerModifierGroupTypes(),
  temporaryCartItems: getContainerModifierGroupTypes(),
  carouselV2: [
    { type: "size" },
    { type: "layout" },
    { type: "visibility" },
    { type: "spacing" },
    { type: "positioning" },
    {
      type: "items",
      allowsDynamicDataWhenAvailable: true,
      allowedItemTypes: [
        ItemsConfigType.dataTable,
        ItemsConfigType.productImages,
        ItemsConfigType.inline,
      ],
      title: "Carousel Items",
      field: "props.items",
    },
    ...getContainerModifierGroupTypes(),
  ],
  carouselV2__panels: getContainerModifierGroupTypes(),
  carouselV2__indicator: getContainerModifierGroupTypes(),
  carouselV3: getContainerModifierGroupTypes(),
  carouselV3Slides: getContainerModifierGroupTypes(),
  carouselV3Control: getContainerModifierGroupTypes(),
  carouselV3Indicators: getContainerModifierGroupTypes(),
  player__playIcon: getIconModifierGroupTypes(),
  player__muteIcon: getIconModifierGroupTypes(),
  player__fullScreenIcon: getIconModifierGroupTypes(),
  symbolRef: null,
  googleMapsEmbed: getEmbedModifierGroupTypes(),
  klaviyoEmbed: getEmbedModifierGroupTypes(),
  vimeoEmbed: getEmbedModifierGroupTypes(),
  youtubeEmbed: getEmbedModifierGroupTypes(),
  vimeoEmbedV2: getEmbedModifierGroupTypes(),
  youtubeEmbedV2: getEmbedModifierGroupTypes(),
  tikTokEmbed: getEmbedModifierGroupTypes(),
  slidingCarousel: getContainerModifierGroupTypes(),
  variantSelectDropdown: getVariantSelectDropdownModifierGroupTypes(),
  optionSelectDropdown: getVariantSelectDropdownModifierGroupTypes(),
  sellingPlanSelectDropdown: getVariantSelectDropdownModifierGroupTypes(),
  shopifySection: getContainerModifierGroupTypes(),
  shopifyAppBlocks: getContainerModifierGroupTypes(),
  shopifyRawLiquid: getContainerModifierGroupTypes(),
  tabsBlock: getContainerModifierGroupTypes(),
  tabs__list: getContainerModifierGroupTypes(),
  tabs__panelsContent: getContainerModifierGroupTypes(),
  tabs__onePanelContent: getContainerModifierGroupTypes(),
  tabsV2__block: getContainerModifierGroupTypes(),
  tabsV2__list: getContainerModifierGroupTypes(),
  tabsV2__panelsContent: getContainerModifierGroupTypes(),
  marquee: getContainerModifierGroupTypes(),
  rawHtmlContent: getContainerModifierGroupTypes(),
  dynamicCheckoutButtons: getContainerModifierGroupTypes(),
  paymentTerms: getContainerModifierGroupTypes(),
  h1: ({ colorValue }) => getTextStyleGroupTypes(colorValue),
  h2: ({ colorValue }) => getTextStyleGroupTypes(colorValue),
  h3: ({ colorValue }) => getTextStyleGroupTypes(colorValue),
  rebuyWidget: getEmbedModifierGroupTypes(),
  buyWithPrimeButton: getEmbedModifierGroupTypes(),
  stampedProductReviewsWidget: getEmbedModifierGroupTypes(),
  stampedProductRatingWidget: getEmbedModifierGroupTypes(),
  feraProductRatingWidget: getEmbedModifierGroupTypes(),
  feraProductReviewsWidget: getEmbedModifierGroupTypes(),
  feraStoreReviewsWidget: getEmbedModifierGroupTypes(),
  feraMediaGalleryWidget: getEmbedModifierGroupTypes(),
  shopifyProductReviewsWidget: getEmbedModifierGroupTypes(),
  shopifyProductRatingWidget: getEmbedModifierGroupTypes(),
  judgeProductRatingWidget: getEmbedModifierGroupTypes(),
  judgeProductReviewsWidget: getEmbedModifierGroupTypes(),
  infiniteOptionsWidget: getEmbedModifierGroupTypes(),
  kachingBundles: getEmbedModifierGroupTypes(),
  postscriptSignupForm: getEmbedModifierGroupTypes(),
  tooltip: getContainerModifierGroupTypes(),
  tooltipContent: [
    { type: "layout" },
    { type: "rowColumnSpans" },
    { type: "visibility" },
    { type: "spacing" },
    {
      type: "fontAndColor",
      allowsGradientSelection: false,
      allowsFontSelection: true,
    },
    { type: "border" },
    { type: "background" },
    { type: "effects" },
  ],
  subscribeAndSave: getContainerModifierGroupTypes(),
  beforeAfterSlider: getContainerModifierGroupTypes(),
  beforeAfterSliderThumb: getContainerModifierGroupTypes(),
  beforeAfterSliderBeforeContent: getContainerModifierGroupTypes(),
  beforeAfterSliderAfterContent: getContainerModifierGroupTypes(),
  selectionList: getContainerModifierGroupTypes(),
};

export const modifierGroupTypesToCssPropertiesForCopying: Record<
  ModifierGroupType["type"],
  RuntimeStyleAttribute[]
> = {
  border: [
    "borderBottomWidth",
    "borderRightWidth",
    "borderTopWidth",
    "borderLeftWidth",
    "borderBottomLeftRadius",
    "borderBottomRightRadius",
    "borderTopLeftRadius",
    "borderTopRightRadius",
    "borderBottomColor",
    "borderTopColor",
    "borderLeftColor",
    "borderRightColor",
    "borderBottomStyle",
    "borderTopStyle",
    "borderLeftStyle",
    "borderRightStyle",
  ],
  items: [],
  icon: [],
  textStyle: [
    "fontFamily",
    "fontSize",
    "fontStyle",
    "fontWeight",
    "letterSpacing",
    "lineHeight",
    "textAlign",
    "textDecoration",
    "textTransform",
    "textShadow",
    "__textStroke",
    "color",
  ],
  background: [
    "backgroundColor",
    "backgroundImage",
    "backgroundPositionX",
    "backgroundPositionY",
    "backgroundRepeat",
    "backgroundSize",
    "__alchemyGradient__backgroundColor__stops",
    "__alchemyGradient__backgroundColor__tilt",
  ],
  layout: ["__flexGap"],
  size: [],
  effects: ["opacity", "cursor", "boxShadow"],
  imageSource: ["objectFit", "objectPosition"],
  videoSource: [],
  spacing: [
    "marginBottom",
    "marginLeft",
    "marginRight",
    "marginTop",
    "paddingBottom",
    "paddingLeft",
    "paddingRight",
    "paddingTop",
  ],
  positioning: [],
  visibility: [],
  rowColumnSpans: [],
  fontAndColor: ["fontFamily", "color"],
};

// Note (Sebas, 2023-03-31): Sometimes we need to copy extra properties that are not part original
// modifier group type. For example, when copying Relume components and when we need to paste styles
// from figma.
export const modifierGroupTypesToCssPropertiesForCopyingWithExtraProperties: Record<
  ModifierGroupType["type"],
  RuntimeStyleAttribute[]
> = {
  border: [],
  items: [],
  icon: [],
  textStyle: [],
  background: [],
  // TODO (Sebas, 2024-05-22): Remove properties from here once the new size modifier is enabled by default
  layout: [
    "display",
    "gridTemplateColumns",
    "__numberOfColumns",
    "columnGap",
    "rowGap",
    "flexBasis",
    "flexDirection",
    "flexGrow",
    "flexShrink",
    "flexWrap",
    "alignSelf",
    "alignItems",
    "justifyContent",
  ],
  size: [
    "flexBasis",
    "flexGrow",
    "flexShrink",
    "alignSelf",
    "alignItems",
    "justifyContent",
  ],
  effects: [],
  imageSource: ["width", "height"],
  videoSource: ["width", "height"],
  spacing: ["width", "height", "maxHeight", "maxWidth"],
  positioning: ["position", "zIndex", "top", "bottom", "left", "right"],
  visibility: [],
  rowColumnSpans: [],
  fontAndColor: [],
};

export const getApplicableRuntimeStyleAttributes = (
  componentType: ReploComponentType,
  colorValue?: string | null,
  textValue?: string,
  addStylesForWebflow = false,
) => {
  const modifierGroups = mapNull(
    componentTypeToModifierGroups[componentType as ReploComponentType],
    (modifierGroupsOrFunction) => {
      return typeof modifierGroupsOrFunction === "function"
        ? modifierGroupsOrFunction({
            textValue,
            colorValue: colorValue ?? null,
          })
        : modifierGroupsOrFunction;
    },
  );

  const applicableStyles = modifierGroups?.flatMap(
    (group) => modifierGroupTypesToCssPropertiesForCopying[group.type],
  );

  if (!addStylesForWebflow) {
    return applicableStyles;
  }

  return modifierGroups?.flatMap((group) =>
    modifierGroupTypesToCssPropertiesForCopying[group.type].concat(
      modifierGroupTypesToCssPropertiesForCopyingWithExtraProperties[
        group.type
      ],
    ),
  );
};
