import * as React from "react";

import {
  NEW_SHOPIFY_INTEGRATION_ID,
  ShopifyIntegrationSelectable,
} from "@editor/components/dashboard/projects/NewProjectFlow";
import { useSubscriptionTier } from "@editor/hooks/subscription";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import useInstallShopify from "@editor/hooks/useInstallShopify";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { selectLoadableProject } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

export const ConnectShopifyCallout: React.FC<{
  type:
    | "assets"
    | "productPicker"
    | "publish"
    | "sections"
    | "billingModal"
    | "preview";
  className?: string;
}> = ({ type, className }) => {
  const subscriptionTier = useSubscriptionTier();
  return (
    <div
      className={twMerge(
        classNames(
          "text-sm flex flex-col font-normal text-slate-500 gap-4 py-2",
          (type === "publish" || type === "preview") && "px-2",
        ),
        className,
      )}
    >
      <div className="flex flex-col gap-2">
        <h2 className="font-semibold text-default">
          {exhaustiveSwitch({ type })({
            publish: "Publish Page",
            preview: "Preview Page",
            productPicker: "Add Product Data",
            assets: "Upload Images",
            billingModal: "Connect Shopify",
            sections: "Load Sections",
          })}
        </h2>
        <p className="text-xs">
          {exhaustiveSwitch({ type })({
            // Note (Evan, 2024-04-09): We only tell people to upgrade their account when they are on the free plan -
            // there are other cases where an upgrade may be required (e.g. the published pages limit has been reached)
            // but the logic of figuring that out is a little annoying, so we just account for the most obvious case here.
            publish: `To publish this page, add Shopify as an integration${subscriptionTier === "free" ? " and upgrade your account" : ""}.`,
            preview: `To preview this page, add Shopify as an integration.`,
            productPicker:
              "Replo allows you to access Shopify data dynamically in the editor. To access product data, add Shopify as an integration.",
            assets: "To add images via upload, add Shopify as an integration.",
            billingModal:
              "To start a billing plan with Replo, first connect your Shopify store.",
            sections:
              "To pick a section from your Shopify theme, please connect a Shopify Store to this project.",
          })}
        </p>
      </div>
      <img
        src="/replo-shopify-connector.svg"
        alt="Connect Replo To Shopify"
        className="block w-full"
      />
      <div className="flex flex-col gap-2">
        <ConnectShopifyIntegrationCta type={type} />
        <p className="text-[10px] leading-relaxed">
          Don’t have a Shopify Store?{" "}
          <a
            href="https://airtable.com/appy2nf6tNKFEODB4/pagObddhF9f5Pln7P/form"
            className="underline"
          >
            Add yourself to the waitlist for non-Shopify deployment!
          </a>
        </p>
      </div>
    </div>
  );
};

export const ConnectShopifyIntegrationCta: React.FC<{
  type:
    | "sections"
    | "noShopifyErrorModal"
    | "projectSettings"
    | "publish"
    | "productPicker"
    | "assets"
    | "templateDefault"
    | "billingModal"
    | "integrationHub"
    | "preview";
  className?: string;
}> = ({ type, className }) => {
  const analytics = useLogAnalytics();
  const workspaceIdParam = useCurrentWorkspaceId();
  const projectId = useCurrentProjectId();
  const { project } = useEditorSelector(selectLoadableProject);
  const workspaceId = project?.ownerWorkspaceId ?? workspaceIdParam!;
  const [isLoading, setLoading] = React.useState(false);

  const { installShopify } = useInstallShopify();

  const { mutate: connectIntegration } =
    trpc.project.connectToIntegration.useMutation({
      onSuccess: () => {
        window.location.reload();
      },
    });

  const [selectedIntegrationId, setSelectedIntegrationId] = React.useState<
    string | null
  >(null);

  // NOTE (Matt 2024-09-17): Given that this component can be used to connect an integration to a project or to add a new integration,
  // we set `allowExistingIntegrations` to determine whether or not to show the existing integrations selectable.
  const allowExistingIntegrations = exhaustiveSwitch({ type })({
    sections: true,
    projectSettings: true,
    publish: true,
    productPicker: true,
    assets: true,
    templateDefault: true,
    integrationHub: false,
    billingModal: false,
    noShopifyErrorModal: false,
    preview: true,
  });

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    if (type === "integrationHub") {
      analytics("integration.shopify.add.initiate", {});
    }

    const isConnectingExistingIntegration =
      selectedIntegrationId &&
      selectedIntegrationId !== NEW_SHOPIFY_INTEGRATION_ID;
    if (
      allowExistingIntegrations &&
      project &&
      isConnectingExistingIntegration
    ) {
      try {
        connectIntegration({
          projectId: project.id,
          integrationId: selectedIntegrationId,
        });
      } catch {
        setLoading(false);
        return;
      }
    } else {
      await installShopify({
        workspaceId,
        projectId,
        type,
      });
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {allowExistingIntegrations && (
        <ShopifyIntegrationSelectable
          isDisabled={isLoading}
          workspaceId={workspaceId}
          selectedIntegrationId={selectedIntegrationId}
          handleChangeSelection={setSelectedIntegrationId}
        />
      )}
      <Button
        variant="primary"
        size="base"
        isFullWidth
        className={className}
        onClick={(e) => {
          void handleClick(e);
        }}
        isLoading={isLoading}
      >
        Add Shopify Integration
      </Button>
    </div>
  );
};
