import * as React from "react";

import { GOOGLE_FONTS } from "replo-runtime/shared/utils/font";

export type FontOption = {
  label: string;
  isDefaultActive: boolean;
  isDisabled: boolean;
  value: string | null;
  isSelectable: boolean;
  groupTitle?: string;
  endEnhancer?: JSX.Element | null;
};

export const GOOGLE_FONT_OPTIONS: FontOption[] = GOOGLE_FONTS.map((f) => ({
  value: f,
  label: f,
  isDisabled: false,
  isDefaultActive: false,
  isSelectable: true,
  groupTitle: "Google Fonts",
}));

// Helper
export const arrayContainsFontOption = (
  array: FontOption[],
  font: FontOption,
): boolean => {
  return array.some((fontFromArray) => fontFromArray.value === font.value);
};

export const filterOutPageFonts = (
  fontOptions: FontOption[],
  pageFontOptions: FontOption[],
): FontOption[] => {
  const filteredFonts = fontOptions.filter(
    (f) => !arrayContainsFontOption(pageFontOptions, f) || f.isDisabled,
  );
  return filteredFonts.length >= 1 ? filteredFonts : [];
};

// https://developer.mozilla.org/en-US/docs/Web/CSS/font-family#generic-name
export const GENERIC_FONT_FAMILIES = [
  "serif",
  "sans-serif",
  "monospace",
  "cursive",
  "fantasy",
  "system-ui",
  "ui-serif",
  "ui-sans-serif",
  "ui-monospace",
  "ui-rounded",
  "math",
  "emoji",
  "fangsong",
];
