import * as React from "react";

import * as ProgressPrimitive from "@radix-ui/react-progress";
import twMerge from "@replo/design-system/utils/twMerge";

type ProgressProps = React.ComponentPropsWithoutRef<
  typeof ProgressPrimitive.Root
> & {
  value: number;
  max?: number;
  className?: string;
  indicatorClassName?: string;
};
const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(({ className, value, max, indicatorClassName, ...props }, ref) => {
  let percentage = ((value || 0) / (max || 100)) * 100;
  if (max === 0 && value > 0) {
    percentage = 100;
  }
  return (
    <ProgressPrimitive.Root
      ref={ref}
      className={twMerge(
        "relative h-1.5 w-full overflow-hidden rounded-full bg-slate-100",
        className,
      )}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={twMerge(
          "h-full w-full flex-1 bg-primary transition-all",
          indicatorClassName,
        )}
        style={{ transform: `translateX(-${100 - percentage}%)` }}
      />
    </ProgressPrimitive.Root>
  );
});

Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
