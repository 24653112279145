import React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

interface LabelProps {
  size: "base" | "sm" | "lg";
  horizontal?: boolean;
  label: React.ReactNode;
  labelActionText?: React.ReactNode;
  labelAction?: () => void;
  className?: string;
  labelClassName?: string;
}

const Label: React.FC<LabelProps> = ({
  size = "base",
  horizontal = false,
  label,
  labelActionText,
  labelAction,
  labelClassName,
  className,
}) => {
  return (
    <div
      className={twMerge(
        "flex mb-1.5 items-center",
        size === "sm" && "typ-label-small",
        size === "base" && "typ-label-base",
        size === "lg" && "text-base",
        horizontal ? "mb-0 mr-4" : "w-full",
        horizontal && size === "sm" && "h-small",
        horizontal && size === "base" && "h-base",
        horizontal && size === "lg" && "h-10",
        className,
      )}
    >
      {label && (
        <label
          className={twMerge("text-default block min-w-0", labelClassName)}
        >
          {label}
        </label>
      )}
      {labelActionText && !horizontal && (
        <div
          onClick={labelAction}
          className="text-primary ml-auto cursor-pointer"
        >
          {labelActionText}
        </div>
      )}
    </div>
  );
};

export default Label;
