import type { ComponentTemplateTransform } from "@editor/types/component-template";
import type { ReploComponentType } from "schemas/component";
import type { ConditionField } from "schemas/generated/symbol";

import { findAncestorComponent } from "@editor/utils/component";
import { isElementPartial } from "@editor/utils/element";

import merge from "lodash-es/merge";
import pick from "lodash-es/pick";
import { v4 as uuid4 } from "uuid";

export const activeTabVariantActionTransform: ComponentTemplateTransform = (
  template,
  parent,
  element,
) => {
  if (!parent || isElementPartial(element)) {
    return template;
  }
  const ancestorTestToConditionField = {
    tabsV2__block: "state.tabsV2Block.isCurrentTab",
    variantSelect: "state.product.selectedVariant",
    optionSelect: "state.product.selectedOptionValues",
    sellingPlanSelect: "state.product.selectedSellingPlan",
  } as const satisfies Partial<Record<ReploComponentType, ConditionField>>;
  let hasSupportedAncestor = false;

  // Note (Ovishek, 2022-12-22): Searching by the state name 'Active'
  // b/c we can't search by id, all the ids are already replaced with new ones before this line.
  const activeVariant = template.variants?.find(
    (variant) => variant.name === "Active",
  );

  if (activeVariant) {
    for (const [componentType, conditionField] of Object.entries(
      ancestorTestToConditionField,
    )) {
      if (
        findAncestorComponent(
          element,
          parent.id,
          (component) => component.type === componentType,
        )
      ) {
        activeVariant.query = {
          type: "expression",
          operator: "or",
          statements: [
            {
              id: uuid4(),
              field: conditionField,
              value: null,
              operator: null,
            },
          ],
        };
        hasSupportedAncestor = true;
        break;
      }
    }
    // NOTE (Sebas, 2024-05-16): If the component doesn't have a supported ancestor, we want
    // to add the interactions to the default and active variants to make sure the component
    // can be toggled and doesn't require any extra configuration from the user.
    if (!hasSupportedAncestor) {
      const defaultVariant = template.variants?.find(
        (variant) => variant.name === "default",
      );
      if (defaultVariant) {
        const interactionId = uuid4();
        template.props.onClick = [
          {
            id: interactionId,
            type: "setActiveAlchemyVariant",
            value: {
              variantId: activeVariant.id,
              componentId: template.id,
            },
          },
        ];

        const variantOverrides = template.variantOverrides?.[activeVariant.id];
        const componentOverrides =
          variantOverrides?.componentOverrides?.[template.id];
        if (componentOverrides?.props) {
          componentOverrides.props.onClick = [
            {
              id: interactionId,
              type: "setActiveAlchemyVariant",
              value: {
                variantId: defaultVariant.id,
                componentId: template.id,
              },
            },
          ];
        }
      }
    }
  }

  return template;
};

export const copyContainerParentFlexStyles: ComponentTemplateTransform = (
  template,
  parent,
) => {
  if (!parent) {
    return template;
  }
  // NOTE (Fran, 2022-06-02): Sometimes when the user add a new component we
  // add a container to wrap the component with his siblings. If this happen
  // we need to copy some styles to imitate the alignment of the old container.
  if (
    parent &&
    template.type === "container" &&
    template.props.style?.display === "flex"
  ) {
    const parentFlexAttributes = pick(parent.props.style, [
      "alignItems",
      "justifyContent",
    ]);
    const width = parent.props.style?.width;

    merge(
      template.props.style,
      parentFlexAttributes,
      // NOTE (Sebas, 2025-01-14): In case the parent has a width, we need to
      // make the new container to stretch to the parent width. REPL-13821.
      Boolean(width) ? { alignSelf: "stretch", flexGrow: 1 } : {},
    );
  }

  return template;
};
