import * as React from "react";

import { ComboboxContext } from "../ComboboxContext";

export function useCombobox() {
  const context = React.useContext(ComboboxContext);
  if (!context) {
    throw new Error("useCombobox must be used within a ComboboxProvider");
  }
  return context;
}
