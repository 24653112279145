import type {
  ObjectRepeatType,
  ObjectSizeType,
} from "@editor/components/common/designSystem/BackgroundAssetPicker";
import type { SolidOrGradient } from "replo-runtime/shared/types";
import type { GradientStop } from "schemas/styleAttribute";

import * as React from "react";

import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import BackgroundAssetPicker from "@editor/components/common/designSystem/BackgroundAssetPicker";
import InlineAssetSelector from "@editor/components/common/designSystem/InlineAssetSelector";
import { BADGE_TRIGGER_OFFSET } from "@editor/components/editor/constants";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import { useGetModifierControls } from "@editor/hooks/rightBar/useGetModifierControls";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useDynamicCommandMenuItem } from "@editor/hooks/useDynamicCommandMenuItems";
import { useModal } from "@editor/hooks/useModal";
import {
  selectBackgroundColor,
  selectBackgroundGradientStops,
  selectBackgroundGradientTilt,
  selectBackgroundImage,
  selectBackgroundPositionX,
  selectBackgroundPositionY,
  selectBackgroundRepeat,
  selectBackgroundSize,
  selectDraftComponentContext,
  selectDraftComponentId,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { isImageSourceValid } from "@editor/utils/image";
import DocumentationInfoIcon from "@editorComponents/DocumentationInfoIcon";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import ModifierGroup from "@editorExtras/ModifierGroup";
import DynamicColorModifier from "@editorModifiers/DynamicColorModifier";
import { hasDynamicData } from "@editorModifiers/utils";

import Popover from "@replo/design-system/components/popover";
import { AiOutlineBgColors } from "react-icons/ai";
import { IoImage } from "react-icons/io5";
import { isDynamicDataValue } from "replo-runtime";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { evaluateVariableAsString } from "replo-runtime/store/AlchemyVariable";
import {
  isMixedStyleValue,
  REPLO_MIXED_STYLE_VALUE,
} from "replo-runtime/store/utils/mixed-values";
import { coerceNumberToString } from "replo-utils/lib/misc";

type BackgroundPopoverId =
  | "image-source-selector"
  | "background-color-selector"
  | null;

export const BackgroundModifier: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { src } = useBackgroundImage();
  const backgroundColor = useEditorSelector(selectBackgroundColor);
  const groupRef = React.useRef<HTMLDivElement>(null);
  const [controls, addControl] = useGetModifierControls<"background">(
    "background",
    groupRef,
  );
  const [openPopoverId, setOpenPopoverId] =
    React.useState<BackgroundPopoverId>(null);

  const hasControlsToRender = controls.size > 0;

  const onSelectBgColor = React.useCallback(() => {
    setOpenPopoverId("background-color-selector");
    addControl("backgroundColor");
  }, [addControl]);

  const backgroundColorDynamicMenuItem = React.useMemo(() => {
    return !controls.has("backgroundColor")
      ? ({
          label: "Add Background Color",
          group: "add",
          icon: { type: "iconComponent", component: AiOutlineBgColors },
          onSelect: onSelectBgColor,
        } as const)
      : null;
  }, [controls, onSelectBgColor]);

  useDynamicCommandMenuItem("backgroundColor", backgroundColorDynamicMenuItem);

  const onSelectBgImage = React.useCallback(() => {
    setOpenPopoverId("image-source-selector");
    addControl("backgroundImage");
  }, [addControl]);

  const backgroundImageDynamicMenuItem = React.useMemo(() => {
    return !controls.has("backgroundImage")
      ? ({
          label: "Add Background Image",
          group: "add",
          icon: { type: "iconComponent", component: IoImage },
          onSelect: onSelectBgImage,
        } as const)
      : null;
  }, [controls, onSelectBgImage]);

  useDynamicCommandMenuItem("backgroundImage", backgroundImageDynamicMenuItem);

  return (
    <ModifierGroup
      ref={groupRef}
      title="Background"
      tooltipText="Add Background"
      titleEnhancer={<DocumentationInfoIcon documentationType="background" />}
      isDefaultOpen={
        Boolean(src) || Boolean(backgroundColor) || hasControlsToRender
      }
      isCollapsible={hasControlsToRender}
      menuItems={[
        {
          id: "color",
          title: "Color",
          onSelect: () => {
            setOpenPopoverId("background-color-selector");
            addControl("backgroundColor");
          },
          type: "leaf",
          isDisabled: controls.has("backgroundColor"),
        },
        {
          id: "image",
          title: "Image",
          onSelect: () => {
            addControl("backgroundImage");
            setOpenPopoverId("image-source-selector");
          },
          type: "leaf",
          isDisabled: controls.has("backgroundImage"),
        },
      ]}
    >
      {hasControlsToRender && (
        <div className="flex flex-col w-full gap-1">
          {controls.has("backgroundImage") && (
            <BackgroundImageControl
              isOpen={openPopoverId === "image-source-selector"}
              setIsOpen={(isOpen) =>
                setOpenPopoverId(isOpen ? "image-source-selector" : null)
              }
            />
          )}
          {controls.has("backgroundColor") && (
            <BackgroundColorControl
              isOpen={openPopoverId === "background-color-selector"}
              setIsOpen={(isOpen) =>
                setOpenPopoverId(isOpen ? "background-color-selector" : null)
              }
            />
          )}
        </div>
      )}
    </ModifierGroup>
  );
};

const BackgroundImageControl: React.FC<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const backgroundSize = useEditorSelector(
    selectBackgroundSize,
  ) as ObjectSizeType;
  const backgroundRepeat = useEditorSelector(
    selectBackgroundRepeat,
  ) as ObjectRepeatType;
  const backgroundPositionX = useEditorSelector(selectBackgroundPositionX);
  const backgroundPositionY = useEditorSelector(selectBackgroundPositionY);
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();
  const { src, assetSrc, removeImageSrc } = useBackgroundImage();

  const onClickDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.URL,
        referrerData: {
          type: "style",
          styleAttribute: "backgroundImage",
        },
        initialPath: !isMixedStyleValue(src)
          ? getPathFromVariable(src ?? undefined)
          : undefined,
      },
    });
  };

  const openModal = () => {
    modal.openModal({
      type: "assetLibraryModal",
      props: {
        referrer: "modifier/background",
        value: assetSrc!,
        assetContentType: "image",
      },
    });
  };
  const openPopover = () => {
    setIsOpen(true);
  };

  return (
    <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
      <div className="flex flex-col gap-1">
        <div className="flex items-center w-full">
          <ModifierLabel label="Source" />
          {typeof src === "string" && isDynamicDataValue(src) ? (
            <DynamicDataValueIndicator
              type="color"
              templateValue={src}
              onClick={openPopover}
              onRemove={removeImageSrc}
              componentId={draftComponentId ?? undefined}
            />
          ) : (
            <InlineAssetSelector
              size="sm"
              emptyTitle="Choose Image"
              onClickSelectAsset={openPopover}
              onRemoveAsset={removeImageSrc}
              allowRemoveAsset
              allowsDynamicData={hasDynamicData(draftComponentId)}
              onClickDynamicData={onClickDynamicData}
              asset={
                isMixedStyleValue(assetSrc)
                  ? assetSrc
                  : {
                      type: "image",
                      src: assetSrc!,
                    }
              }
              onInputChange={(url) =>
                applyComponentAction({
                  type: "setStyles",
                  value: {
                    // Note (Sebas, 2022-10-12): We need to use double quotes to prevent
                    // data:image sources which may contain simple quotes from not working.
                    backgroundImage: `url("${url}")`,
                    backgroundSize: "cover",
                  },
                })
              }
            />
          )}
        </div>
        {!isMixedStyleValue(src) && !isImageSourceValid(src ?? null) && (
          <ErrorMessage error="Please enter a valid image source" />
        )}
      </div>
      <Popover.Anchor className="relative top-0 left-0" />
      {isOpen && (
        <Popover.Content
          title="Choose Image"
          shouldPreventDefaultOnInteractOutside={areModalsOpen}
        >
          <BackgroundAssetPicker
            url={assetSrc ?? undefined}
            emptyTitle="No Image Selected"
            selectAssetTitle="Select Background"
            changeAssetTitle="Change Background"
            onClickSelectAsset={openModal}
            onClickDynamicDataForUrl={onClickDynamicData}
            backgroundSizeValue={backgroundSize}
            onChangeBackgroundSize={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundSize: value },
              });
            }}
            backgroundPositionValue={{
              x: backgroundPositionX ? String(backgroundPositionX) : undefined,
              y: backgroundPositionY ? String(backgroundPositionY) : undefined,
            }}
            onChangeBackgroundPositionX={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundPositionX: value },
              });
            }}
            onChangeBackgroundPositionY={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundPositionY: value },
              });
            }}
            backgroundRepeatValue={backgroundRepeat ?? "repeat"}
            onChangeBackgroundRepeat={(value: any) => {
              applyComponentAction({
                type: "setStyles",
                value: { backgroundRepeat: value },
              });
            }}
            allowsSettingDynamicData
          />
        </Popover.Content>
      )}
    </Popover>
  );
};

const BackgroundColorControl: React.FC<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  const applyComponentAction = useApplyComponentAction();
  const backgroundColor = useEditorSelector(selectBackgroundColor);
  const backgroundGradientTilt = useEditorSelector(
    selectBackgroundGradientTilt,
  );
  const backgroundGradientStops = useEditorSelector(
    selectBackgroundGradientStops,
  ) as GradientStop[];

  const isMixedGradient =
    isMixedStyleValue(backgroundGradientTilt) ||
    isMixedStyleValue(backgroundGradientStops);

  return (
    <div className="flex items-center">
      <ModifierLabel label="Color" />
      <DynamicColorModifier
        isPopoverOpen={isOpen}
        onOpenPopoverChange={setIsOpen}
        previewProperty="backgroundColor"
        gradientSelectionType="backgroundColor"
        gradientData={
          isMixedGradient
            ? REPLO_MIXED_STYLE_VALUE
            : {
                tilt: coerceNumberToString(backgroundGradientTilt) ?? "90deg",
                stops: backgroundGradientStops ?? [
                  {
                    id: "c7795a8c-4e13-4011-889b-64adb0e11e41",
                    color: "#df9393",
                    location: "0%",
                  },
                ],
              }
        }
        field="style.backgroundColor"
        value={backgroundColor ?? undefined}
        popoverTitle="Background Color"
        popoverSideOffset={BADGE_TRIGGER_OFFSET}
        onChange={(value: SolidOrGradient) => {
          const solidOrGradient = value;
          if (solidOrGradient.type === "solid") {
            applyComponentAction({
              type: "setStyles",
              value: {
                backgroundColor: solidOrGradient.color,
                // NOTE (Fran 2024-11-27): Remove gradient data when setting solid color
                __alchemyGradient__backgroundColor__tilt: null,
                __alchemyGradient__backgroundColor__stops: null,
                __reploGradient__color__design_library: null,
              },
            });
          } else {
            const gradient = solidOrGradient.gradient;
            applyComponentAction({
              type: "setStyles",
              value: {
                backgroundColor: "alchemy:gradient",
                __alchemyGradient__backgroundColor__tilt: gradient.tilt,
                __alchemyGradient__backgroundColor__stops: gradient.stops,
                // NOTE (Fran 2024-11-27): Remove gradient data from design library when setting gradient color
                __reploGradient__color__design_library: null,
              },
            });
          }
        }}
        showSavedStyles
      />
    </div>
  );
};

function useBackgroundImage() {
  const applyComponentAction = useApplyComponentAction();
  const draftComponentContext = useEditorSelector(selectDraftComponentContext);
  const rawBackgroundImage = useEditorSelector(selectBackgroundImage);

  const removeImageSrc = React.useCallback(() => {
    applyComponentAction({
      type: "setStyles",
      value: { backgroundImage: "" },
    });
  }, [applyComponentAction]);

  if (isMixedStyleValue(rawBackgroundImage)) {
    return {
      src: rawBackgroundImage,
      assetSrc: rawBackgroundImage,
      removeImageSrc,
    };
  }

  const backgroundImage =
    rawBackgroundImage &&
    rawBackgroundImage?.length > 0 &&
    !isDynamicDataValue(rawBackgroundImage)
      ? rawBackgroundImage.slice(5, -2)
      : rawBackgroundImage;

  const assetSrc =
    isDynamicDataValue(backgroundImage ?? undefined) && draftComponentContext
      ? evaluateVariableAsString(backgroundImage!, draftComponentContext)
      : backgroundImage;

  return {
    src: backgroundImage,
    assetSrc,
    removeImageSrc,
  };
}
