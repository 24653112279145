import { z } from "zod";

export const variationSchema = z
  .object({
    id: z.string().uuid(),
    slug: z.string(),
    target: z.string().url().nullable(),
    allocationPercent: z.number().int().min(0).max(100),
    isWinner: z.boolean().nullish(),
  })
  .describe("Variation");
