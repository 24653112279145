import type {
  ComponentTemplateBadgeId,
  ComponentTemplateCategoryType,
  ComponentTemplateIndustryId,
  Filter,
  FilterOptionId,
  SelectedFilters,
} from "schemas/componentTemplates";
import type { ComponentTemplateSelectionId } from "schemas/generated/componentTemplates";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import {
  Group,
  GroupCollapsibleIndicator,
  GroupHeader,
  GroupTitle,
  GroupTitleContainer,
} from "@common/designSystem/Group";
import SelectableButtons from "@common/designSystem/SelectableButtons";

import twMerge from "@replo/design-system/utils/twMerge";
import { RiCollageLine, RiPagesLine, RiShareLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { getFiltersByType } from "replo-runtime/shared/componentTemplates";
import { SHARED_CATEGORIES_SELECTIONS } from "schemas/componentTemplates";

type FiltersSidebarProps = {
  selectedFilters: {
    badge: ComponentTemplateBadgeId[];
    industry: ComponentTemplateIndustryId[];
    category: ComponentTemplateSelectionId[];
  };
  setSelectedFilters(newFilters: SelectedFilters): void;
  templateType: ComponentTemplateCategoryType | null;
  setTemplateType(value?: "page" | "section" | "shared"): void;
  className?: string;
  elementType?: ReploElementType;
};

export const FiltersSidebar: React.FC<FiltersSidebarProps> = ({
  selectedFilters,
  setSelectedFilters,
  templateType,
  setTemplateType,
  className,
  elementType,
}) => {
  const { pathname } = useLocation();

  const filters = getFiltersByType(templateType ?? "page");

  const selectableOptions: {
    value: ComponentTemplateCategoryType;
    label: string;
    enhancer: React.ReactElement;
  }[] = [
    {
      value: "page",
      label: "Page",
      enhancer: <RiPagesLine size={24} />,
    },
    {
      value: "section",
      label: "Section",
      enhancer: <RiCollageLine size={24} />,
    },
  ];

  if (!pathname.includes("collection")) {
    selectableOptions.push({
      value: "shared",
      label: "Shared",
      enhancer: <RiShareLine size={24} />,
    });
  }

  return (
    <div className={twMerge("flex w-96 flex-col rounded-l gap-2", className)}>
      <h3 className="font-medium">Filter</h3>
      <SelectableButtons<ComponentTemplateCategoryType>
        options={selectableOptions}
        value={templateType ? [templateType] : []}
        buttonClassName="w-[4.75rem]"
        hasMinDimensions={false}
        onChange={([value]) => {
          // NOTE (Fran 2023-12-12): Template type should never be undefined, but we need to handle
          // because when you unselect an option, internally the value is undefined.
          if (value === undefined) {
            setTemplateType(templateType ?? undefined);
          } else if (templateType !== value) {
            setTemplateType(value);
            if (value === "shared" && templateType !== "shared") {
              setSelectedFilters({
                badge: [],
                industry: [],
                category:
                  elementType === "shopifySection"
                    ? [SHARED_CATEGORIES_SELECTIONS.sharedComponents]
                    : [SHARED_CATEGORIES_SELECTIONS.sharedPages],
              });
            } else {
              // NOTE (Sebas, 2024-01-09): When changing the template type, we need to reset the filters
              // to avoid having filters that are not compatible with the new template type.
              setSelectedFilters({
                badge: [],
                industry: [],
                category: [],
              });
            }
          }
        }}
        multiSelect={false}
        className="gap-2"
        selectedClassName="text-white flex flex-col justify-between items-start bg-blue-600 border border-blue-600 rounded-lg p-2 hover:bg-blue-600 !h-[unset]"
        unselectedClassName="text-default flex flex-col justify-between items-start bg-stone-50 border border-slate-400 rounded-lg p-2 hover:bg-blue-600 hover:text-white !h-[unset]"
        textClassName="text-sm justify-start mt-1"
        buttonTextClassNames="flex flex-col items-start"
      />
      <div className="no-scrollbar overflow-auto flex flex-col gap-2">
        {filters.map((filter) => {
          return (
            <Group
              key={filter.label}
              name={filter.label}
              isCollapsible
              isDefaultOpen
              header={
                <GroupHeader
                  className="flex flex-row"
                  endEnhancer={
                    selectedFilters[filter.type].length > 0 && (
                      <div
                        className="text-xs text-blue-300 cursor-pointer"
                        onClick={() => {
                          setSelectedFilters({
                            ...selectedFilters,
                            [filter.type]: [],
                          });
                        }}
                      >
                        Clear
                      </div>
                    )
                  }
                >
                  <GroupTitleContainer
                    collapsibleIndicator={
                      <GroupCollapsibleIndicator size="large" />
                    }
                  >
                    <GroupTitle className="text-sm font-medium">
                      {filter.label}
                    </GroupTitle>
                  </GroupTitleContainer>
                </GroupHeader>
              }
            >
              <div className="flex flex-col gap-1">
                <Filters
                  templateType={templateType}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  filter={filter}
                />
              </div>
            </Group>
          );
        })}
      </div>
    </div>
  );
};

type FilterProps = Pick<
  FiltersSidebarProps,
  "templateType" | "selectedFilters" | "setSelectedFilters"
> & { filter: Filter };

const Filters: React.FC<FilterProps> = ({
  templateType,
  selectedFilters,
  setSelectedFilters,
  filter,
}) => {
  const onFilterCheck = (
    checked: boolean,
    optionId: FilterOptionId,
    filterType: Filter["type"],
  ) => {
    if (
      templateType === "shared" &&
      !selectedFilters.category.includes(
        optionId as ComponentTemplateSelectionId,
      )
    ) {
      setSelectedFilters({
        badge: [],
        industry: [],
        category: [optionId as ComponentTemplateSelectionId],
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [filterType]: checked
          ? [...selectedFilters[filterType], optionId]
          : (selectedFilters[filterType] as string[]).filter(
              (id: string) => id !== optionId,
            ),
      });
    }
  };

  return (
    filter.options.length > 0 &&
    filter.options.map((option) => {
      const isChecked = filter.type
        ? Boolean(
            (selectedFilters[filter.type] as string[]).find(
              (id: string) => id === option.id,
            ),
          )
        : false;
      return (
        <div key={option.id} className="flex gap-2 ml-5 items-center">
          <input
            type="checkbox"
            className="w-4 h-4 bg-black border-gray-300 rounded focus:ring-blue-600 focus:ring-1"
            checked={isChecked}
            name={option.name}
            id={option.name}
            onChange={(e) => {
              onFilterCheck(e.target.checked, option.id, filter.type);
            }}
          />
          <label htmlFor={option.name} className="text-sm font-light">
            {option.name}
          </label>
        </div>
      );
    })
  );
};
