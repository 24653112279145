import type { RenderComponentProps } from "../../../shared/types";
import type { ActionType } from "./config";

import * as React from "react";

import { usePreloadImagesFromDataTable } from "../../../shared/hooks/usePreloadImagesFromDataTable";
import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mergeContext } from "../../../shared/utils/context";
import { getRowObjectsFromDataTable } from "../../AlchemyDataTable";
import { ReploComponent } from "../ReploComponent";

const CollectionSelect = (props: RenderComponentProps) => {
  const { component, context } = props;
  const itemsConfig = component.props.items;
  const dataTableMapping =
    useRuntimeContext(RuntimeHooksContext).useDataTableMapping();

  const {
    activeCurrency: currencyCode,
    moneyFormat,
    activeLanguage: language,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const isShopifyProductsLoading =
    useRuntimeContext(RuntimeHooksContext).useIsShopifyProductsLoading();

  const items = React.useMemo(() => {
    let result = getRowObjectsFromDataTable(itemsConfig?.id, dataTableMapping, {
      products,
      currencyCode,
      moneyFormat,
      language,
      templateProduct,
      isEditor: isEditorApp,
      isShopifyProductsLoading,
    });

    if (component.props.filter) {
      for (const statement of component.props.filter.statements) {
        result = result.filter((item) => {
          let getValue: () => any = () => null;
          if (statement.type === "dropdown") {
            getValue = () =>
              context.dropdownValues[statement.value.dropdownId]?.item?.[
                statement.value.dataTableColumn
              ];
          }
          return item[statement.columnName] === getValue();
        });
      }
    }

    return result;
  }, [
    component,
    context,
    itemsConfig,
    dataTableMapping,
    products,
    currencyCode,
    moneyFormat,
    language,
    templateProduct,
    isEditorApp,
    isShopifyProductsLoading,
  ]);

  usePreloadImagesFromDataTable(itemsConfig?.id);

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const currentItem = items && items.length > 0 ? items[currentIndex] : null;

  const actionHooks = {
    setCurrentCollectionSelection: (index: number) => {
      setCurrentIndex(index);
    },
  } satisfies {
    [K in ActionType]: Function;
  };

  const newContext = mergeContext(props.context, {
    state: {
      collectionSelect: {
        currentIndex: currentIndex,
        currentSelection: currentItem,
      },
    },
    attributes: {
      _currentSelection: currentItem,
    },
    actionHooks,
  });

  return (
    <div {...props.componentAttributes}>
      {props.component.children?.map((child) => {
        return (
          <ReploComponent
            {...props}
            key={child.id}
            component={child}
            context={newContext}
            repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
          />
        );
      })}
    </div>
  );
};

export default CollectionSelect;
