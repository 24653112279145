import type { Option } from "replo-runtime/shared/types";

import * as React from "react";

import InputComponent from "@editor/components/common/designSystem/Input";
import useSearcheableOptions from "@editor/hooks/useSearcheableOptions";

import isFunction from "lodash-es/isFunction";
import { BsSearch } from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";

type SearcheableListOption = Option & {
  content: React.ReactNode | ((option: Option) => React.ReactNode);
};

const SearcheableList: React.FC<{
  title: string;
  options: SearcheableListOption[];
  endEnhancer?: React.ReactNode;
  emptyMessage?: string;
}> = ({ title, options, endEnhancer, emptyMessage }) => {
  const { filteredOptions, searchTerm, setSearchTerm } =
    useSearcheableOptions(options);
  return (
    <>
      <div className="mb-2">
        <InputComponent
          size="sm"
          value={searchTerm}
          startEnhancer={<BsSearch />}
          endEnhancer={() =>
            searchTerm.trim().length > 0 && (
              <RiCloseFill
                size={12}
                className="cursor-pointer text-slate-400"
                onClick={() => setSearchTerm("")}
              />
            )
          }
          unsafe_className="mb-2"
          placeholder={`Search ${title}`}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-2 divide-y">
        {filteredOptions.length > 0 ? (
          <ul className="flex flex-col gap-2">
            {filteredOptions.map(({ content, ...option }) => {
              const contentElement = isFunction(content)
                ? content(option)
                : content;
              return <li key={option.value}>{contentElement}</li>;
            })}
          </ul>
        ) : (
          <div className="cursor-pointer p-1 text-left text-xs text-gray-400">
            {emptyMessage ?? "No results found"}
          </div>
        )}
        {endEnhancer && <div className="pt-2">{endEnhancer}</div>}
      </div>
    </>
  );
};

export default SearcheableList;
