import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";

import Button from "@replo/design-system/components/button";

type FlowActionButtonsProps = {
  shouldDisableNextButton?: boolean;
  hideBackButton?: boolean;
  handleSkip?: () => void;
  customLabels?: {
    nextButton?: string;
    backButton?: string;
    skipButton?: string;
  };
};

const FlowActionButtons: React.FC<FlowActionButtonsProps> = ({
  shouldDisableNextButton = false,
  hideBackButton = false,
  handleSkip,
  customLabels,
}) => {
  const { isSubmitting, goBack } = useReploFlowsStepContext();

  return (
    <div className="flex gap-2 flex-1 self-end justify-self-end  items-end">
      {handleSkip ? (
        <Button
          className="w-full lg:w-fit font-normal"
          variant="tertiary"
          size="lg"
          disabled={isSubmitting}
          onClick={handleSkip}
        >
          {customLabels?.skipButton ?? "Skip"}
        </Button>
      ) : null}
      {!hideBackButton ? (
        <Button
          className="w-full lg:w-fit bg-slate-100 text-slate-600 font-normal"
          variant="secondary"
          size="lg"
          disabled={isSubmitting}
          onClick={() => goBack()}
        >
          {customLabels?.backButton ?? "Back"}
        </Button>
      ) : null}
      <Button
        className="w-full lg:w-fit font-normal"
        variant="primary"
        size="lg"
        type="submit"
        isLoading={isSubmitting}
        disabled={shouldDisableNextButton}
      >
        {customLabels?.nextButton ?? "Next"}
      </Button>
    </div>
  );
};

export default FlowActionButtons;
