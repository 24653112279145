import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";

import twMerge from "@replo/design-system/utils/twMerge";
import { BsPhone } from "react-icons/bs";
import { RiComputerLine } from "react-icons/ri";

export type ResponsiveToggleAllowedDevices = "mobile" | "desktop";

type ResponsiveToggleProps = {
  value: ResponsiveToggleAllowedDevices;
  setValue: React.Dispatch<React.SetStateAction<"mobile" | "desktop">>;
  size?: "sm" | "lg" | "xl";
};

const ResponsiveToggle: React.FC<ResponsiveToggleProps> = ({
  setValue,
  value,
  size = "xl",
}) => {
  const paddingClassname = {
    sm: "px-3",
    lg: "px-4",
    xl: "px-6",
  };

  return (
    <ToggleGroup
      allowsDeselect={false}
      type="single"
      options={[
        {
          label: <RiComputerLine size={18} />,
          value: "desktop",
        },
        {
          label: <BsPhone size={18} />,
          value: "mobile",
        },
      ]}
      value={value}
      onChange={(value) => setValue(value)}
      size={size}
      className="rounded-full p-1"
      animatedItemClassName={twMerge("rounded-full", paddingClassname[size])}
      toggleItemClassName={paddingClassname[size]}
      isDark
    />
  );
};

export default ResponsiveToggle;
