import type { RenderComponentProps } from "../../../shared/types";
import type { ReploComponentProps } from "../ReploComponent";
import type { ActionType } from "./config";

import * as React from "react";

import isEqual from "lodash-es/isEqual";
import { isCloseTo } from "replo-utils/lib/math";

import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mergeContext } from "../../../shared/utils/context";
import RenderComponentPlaceholder from "../../components/RenderComponentPlaceholder";
import { getItemObjectsForRender } from "../../utils/items";
import { ReploComponent } from "../ReploComponent";
import { scrollParentToElementCustom } from "./utils";

type MouseDownPosition = {
  left: number;
  x: number;
};

export const CarouselV2 = (props: RenderComponentProps) => {
  const template =
    props.component.children?.length && props.component.children[0];

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [currentScrollListenerFunctions, setCurrentScrollListenerFunctions] =
    React.useState<((event: Event) => void)[]>([]);
  const [currentMouseListenerFunctions, setCurrentMouseListenerFunctions] =
    React.useState<((event: MouseEvent) => void)[]>([]);

  const indexToIntersecting: React.RefObject<
    Record<number, { node: Element; isIntersecting: boolean }>
  > = React.useRef({});
  const isMouseDragging = React.useRef<boolean>(false);
  const activeSliderIndexRef = React.useRef<number>();
  const timerIntervalIdRef = React.useRef<
    ReturnType<typeof setTimeout> | undefined
  >();

  const dataTableMapping =
    useRuntimeContext(RuntimeHooksContext).useDataTableMapping();
  const {
    fakeProducts,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const autoCenter = props.component.props["_autoCenter"] || false;
  const dragToScroll = props.component.props["_dragToScroll"] || false;
  const autoNextInterval = Number.parseFloat(
    String(props.component.props["_autoNextInterval"] || "0s"),
  );
  const observers = React.useRef<Array<[IntersectionObserver, Element]>>([]);
  const enableScroll = props.component.props["_enableScroll"] ?? true;
  const isShopifyProductsLoading =
    useRuntimeContext(RuntimeHooksContext).useIsShopifyProductsLoading();
  const itemsConfig = props.component.props.items;
  const items = React.useMemo(() => {
    return (
      getItemObjectsForRender(itemsConfig, dataTableMapping, props.context, {
        products,
        currencyCode,
        fakeProducts,
        moneyFormat,
        language,
        templateProduct,
        isEditor: isEditorApp,
        isShopifyProductsLoading,
      }) || []
    );
  }, [
    itemsConfig,
    dataTableMapping,
    props.context,
    products,
    currencyCode,
    fakeProducts,
    moneyFormat,
    language,
    templateProduct,
    isEditorApp,
    isShopifyProductsLoading,
  ]);

  const _scrollToIndexAndResetTimeout = (index: number, duration: number) => {
    if (indexToIntersecting.current![index]) {
      const { node } = indexToIntersecting.current![index] as any;
      if (timerIntervalIdRef.current) {
        clearInterval(timerIntervalIdRef.current);
      }
      scrollParentToElementCustom(node, 0, duration, "smooth");
      setTimeout(() => {
        setAutoNextIntervalIfNeeded();
      }, 400);
    }
  };

  const itemsRef = React.useRef(items);

  // Note (Noah, 2022-01-08): When carousel items change (could be the case if
  // the items are dynamic from a data table row or something) then reset to
  // the first item with 0 duration since it's weird to keep the same scroll
  // index (plus there could be a different number of items now)
  // biome-ignore lint/correctness/useExhaustiveDependencies: missing deps _scrollToIndexAndResetTimeout,
  React.useEffect(() => {
    const previousItems = itemsRef.current;
    itemsRef.current = items;
    // Note (Noah, 2022-01-10): We have to use isEqual here because items may be
    // re-computed every render (e.g. in the case of data collection
    // ItemsConfig) and useEffect compares dependencies using reference equality
    if (!isEqual(previousItems, items)) {
      _scrollToIndexAndResetTimeout(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const setAutoNextIntervalIfNeeded = React.useCallback(() => {
    if (
      !isEditorCanvas &&
      !isMouseDragging.current &&
      (isCloseTo(autoNextInterval - 0.1, 0) || autoNextInterval >= 0.1) &&
      activeSliderIndexRef.current !== items.length - 1
    ) {
      if (timerIntervalIdRef.current) {
        clearTimeout(timerIntervalIdRef.current);
      }
      timerIntervalIdRef.current = setTimeout(() => {
        const nextIndex = activeSliderIndexRef.current
          ? activeSliderIndexRef.current + 1
          : 0;
        const node = indexToIntersecting.current![nextIndex]!.node;
        scrollParentToElementCustom(node, 0, 400, "smooth");
      }, autoNextInterval * 1000);
    }
  }, [autoNextInterval, isEditorCanvas, items.length]);

  React.useEffect(() => {
    setAutoNextIntervalIfNeeded();
  }, [setAutoNextIntervalIfNeeded]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: missing deps isMouseDragging
  const carouselPanelRef = React.useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        let pos: MouseDownPosition;
        let activeItem = 0;
        const nextScrollListeners: ((event: Event) => void)[] = [];
        const nextMouseListeners: ((event: MouseEvent) => void)[] = [];
        for (const func of currentMouseListenerFunctions) {
          node.removeEventListener("mousedown", func);
        }
        for (const func of currentScrollListenerFunctions) {
          node.removeEventListener("scroll", func);
        }
        // Any time any of the children switch from being visible to more than 50%
        // not visible, make the _next_ one the active element
        const targets =
          node.querySelectorAll(
            `[data-alchemy-carousel-id="${props.component.id}"]`,
          ) || [];

        let scrollingTimeoutId: number;
        let animationFrameIdentifier: number | undefined;

        observers.current.forEach(([observer, childNode]) => {
          observer.unobserve(childNode);
        });
        observers.current = [];
        Array.from(targets).forEach((childNode: Element, index) => {
          const observer = new IntersectionObserver(
            (entries) => {
              for (const entry of entries) {
                indexToIntersecting.current![index] = {
                  node: childNode,
                  isIntersecting: entry.isIntersecting,
                };
              }
              const currentActiveIndex = Object.entries(
                indexToIntersecting.current!,
              ).find((e) => e[1].isIntersecting)?.[0];

              if (currentActiveIndex) {
                setActiveIndex(Number.parseInt(currentActiveIndex, 10));
                activeItem = Number.parseInt(currentActiveIndex, 10);
                activeSliderIndexRef.current = activeItem;
              }
              if (timerIntervalIdRef.current) {
                clearInterval(timerIntervalIdRef.current);
              }
              setAutoNextIntervalIfNeeded();
            },
            {
              root: node,
              threshold: 0.5,
            },
          );
          observer.observe(childNode);
          observers.current.push([observer, childNode]);
        });

        const mouseUpHandler = () => {
          node.style.cursor = "grab";
          node.style.removeProperty("user-select");
          isMouseDragging.current = false;
          if (autoCenter) {
            const activeNode = indexToIntersecting.current![activeItem]?.node;
            if (activeNode) {
              animationFrameIdentifier = scrollParentToElementCustom(
                activeNode,
                0,
                400,
                "smooth",
              );
            }
          }

          setTimeout(() => {
            setAutoNextIntervalIfNeeded();
          }, 200);

          node.removeEventListener("mousemove", mouseMoveHandler);
          node.removeEventListener("mouseup", mouseUpHandler);
          node.removeEventListener("mouseleave", mouseLeaveHandler);
        };

        const mouseLeaveHandler = () => {
          mouseUpHandler();
        };

        const mouseMoveHandler = (e: MouseEvent) => {
          const dx = e.clientX - pos.x;
          if (dragToScroll) {
            node.scrollLeft = pos.left - dx;
          }
        };

        const mouseDownHandler = (e: MouseEvent) => {
          e.preventDefault();
          isMouseDragging.current = true;
          node.style.cursor = "grabbing";
          node.style.userSelect = "none";
          pos = {
            left: node.scrollLeft,
            x: e.clientX,
          };
          node.addEventListener("mousemove", mouseMoveHandler);
          node.addEventListener("mouseup", mouseUpHandler);
          node.addEventListener("mouseleave", mouseLeaveHandler);
        };

        const handleScroll = () => {
          if (!globalWindow) {
            return;
          }
          globalWindow.clearTimeout(scrollingTimeoutId);
          globalWindow.cancelAnimationFrame(animationFrameIdentifier!);
          if (!isMouseDragging.current) {
            scrollingTimeoutId = globalWindow.setTimeout(() => {
              const activeNode = indexToIntersecting.current![activeItem]?.node;
              if (activeNode) {
                animationFrameIdentifier = scrollParentToElementCustom(
                  activeNode,
                  0,
                  400,
                  "linear",
                );
              }
            }, 30);
          }
        };

        if (dragToScroll) {
          nextMouseListeners.push(mouseDownHandler);
          node.addEventListener("mousedown", mouseDownHandler);
        }
        if (autoCenter && !isMouseDragging.current) {
          nextScrollListeners.push(handleScroll);
          node.addEventListener("scroll", handleScroll);
        }
        setCurrentScrollListenerFunctions(nextScrollListeners);
        setCurrentMouseListenerFunctions(nextMouseListeners);
      }
    },

    [
      currentMouseListenerFunctions,
      currentScrollListenerFunctions,
      setAutoNextIntervalIfNeeded,
      props.component.id,
      globalWindow,
      autoCenter,
      dragToScroll,
      isMouseDragging,
    ],
  );

  if (!template) {
    return null;
  }

  if (items.length === 0) {
    return (
      <div {...props.componentAttributes}>
        <RenderComponentPlaceholder title="Once you set the items for this carousel, components that use them will appear here." />
      </div>
    );
  }

  const actionHooks = {
    scrollToNextCarouselItem: () => {
      if (timerIntervalIdRef.current) {
        clearInterval(timerIntervalIdRef.current);
      }
      const nextIndex = Math.min(items.length - 1, activeIndex + 1);
      const node = indexToIntersecting.current![nextIndex]?.node;
      scrollParentToElementCustom(node!, 0, 400, "smooth");
      setTimeout(() => {
        setAutoNextIntervalIfNeeded();
      }, 200);
    },
    scrollToPreviousCarouselItem: () => {
      if (timerIntervalIdRef.current) {
        clearInterval(timerIntervalIdRef.current);
      }
      const prevIndex = Math.max(0, activeIndex - 1);
      const node = indexToIntersecting.current![prevIndex]?.node;
      scrollParentToElementCustom(node!, 0, 400, "smooth");
      setTimeout(() => {
        setAutoNextIntervalIfNeeded();
      }, 200);
    },
    scrollToSpecificCarouselItem: (index: number) => {
      _scrollToIndexAndResetTimeout(index, 400);
    },
  } satisfies {
    [K in ActionType]: Function;
  };

  const nextProps = { ...props };
  nextProps.context = mergeContext(props.context, {
    attributes: {
      _currentItem: items[activeIndex],
    },
    state: {
      carouselV2: {
        carouselComponentId: props.component.id,
        items: items,
        panelRef: carouselPanelRef,
        activeIndex,
        dragToScroll,
        enableScroll,
      },
    },
    actionHooks,
  });

  return (
    <div {...props.componentAttributes}>
      {(props.component.children || []).map((child) => {
        return (
          <ReploComponent
            {...nextProps}
            component={child}
            key={child.id}
            repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
          />
        );
      })}
    </div>
  );
};

export const CarouselV2Panels = (props: RenderComponentProps) => {
  const template =
    props.component.children?.length && props.component.children[0];
  const [overflow, setOverflow] = React.useState("scroll");

  const items = props.context.state?.carouselV2?.items || [];
  const panelRef = props.context.state?.carouselV2?.panelRef || null;
  const dragToScroll = props.context.state?.carouselV2?.dragToScroll;
  const enableScroll = props.context.state?.carouselV2?.enableScroll;

  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isPreviewMode = isEditorApp && !isEditorCanvas;

  // biome-ignore lint/correctness/useExhaustiveDependencies: missing deps enableScroll
  React.useEffect(() => {
    if (!isEditorCanvas) {
      setOverflow(enableScroll ? "scroll" : "hidden");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditorApp, isPreviewMode]);

  const carouselComponentId =
    props.context.state.carouselV2?.carouselComponentId;

  const extraAttributes = React.useMemo(() => {
    return { "data-alchemy-carousel-id": carouselComponentId };
  }, [carouselComponentId]);

  if (!template) {
    return null;
  }

  return (
    <div
      {...props.componentAttributes}
      ref={panelRef}
      style={{
        display: "flex",
        flexDirection: "row",
        ...props.componentAttributes.style,
        overflow,
        cursor: dragToScroll ? "grab" : "default",
      }}
    >
      {items?.map((item: any, index: number) => {
        return (
          <PanelOption
            key={item.id}
            item={item}
            component={template}
            context={props.context}
            extraAttributes={extraAttributes}
            repeatedIndexPath={`${props.context.repeatedIndexPath}.${index}`}
          />
        );
      })}
    </div>
  );
};

function PanelOption({
  context,
  item,
  ...props
}: ReploComponentProps & {
  item: any;
}) {
  const _context = React.useMemo(
    () =>
      mergeContext(context, {
        attributes: { _currentItem: item },
      }),
    [context, item],
  );
  return <ReploComponent context={_context} {...props} />;
}

export const CarouselV2Indicator = (props: RenderComponentProps) => {
  const template =
    props.component.children?.length && props.component.children[0];
  if (!template) {
    return null;
  }

  const items = props.context.state?.carouselV2?.items || [];

  return (
    <div
      {...props.componentAttributes}
      style={{
        display: "flex",
        flexDirection: "row",
        ...props.componentAttributes.style,
      }}
    >
      {items?.map((item: any, index: number) => {
        return (
          <IndicatorOption
            key={item.id}
            item={item}
            index={index}
            component={template}
            context={props.context}
            extraAttributes={props.extraAttributes}
            repeatedIndexPath={`${props.context.repeatedIndexPath}.${index}`}
          />
        );
      })}
    </div>
  );
};

function IndicatorOption({
  context,
  index,
  item,
  ...props
}: ReploComponentProps & {
  index: number;
  item: any;
}) {
  return (
    <ReploComponent
      {...props}
      context={React.useMemo(() => {
        return mergeContext(context, {
          // @ts-expect-error: NOTE (Chance 2024-04-28): Is this correct? TS
          // says `context` is not a key of ... context. Not sure if working as
          // intended.
          context: {
            attributes: {
              _currentItem: item,
            },
            state: {
              carouselV2: {
                currentIndicatorIndex: index,
              },
            },
          },
        });
      }, [context, index, item])}
      defaultActions={React.useMemo(() => {
        return {
          actions: {
            onClick: [
              {
                id: "alchemy:carouselIndicatorClick",
                type: "scrollToSpecificCarouselItem",
                value: { index },
              },
            ],
          },
          placement: "before",
        };
      }, [index])}
    />
  );
}
