import type { SavedTextStyle } from "schemas/generated/designLibrary";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import {
  TEXT_MENU_ITEMS,
  TEXT_STYLE_DEFAULTS,
} from "@common/constants/textStyles";
import ReploPreviewTextSavedStyle from "@editor/components/designLibrary/ReploPreviewTextSavedStyle";
import SavedStyleItemEndEnhancer from "@editor/components/designLibrary/SavedStyleItemEndEnhancer";
import SavedStyleRow from "@editor/components/designLibrary/SavedStyleRow";
import { SavedStyleSkeleton } from "@editor/components/designLibrary/SavedStyleSkeleton";
import TextSavedStyleModifier from "@editor/components/designLibrary/TextSavedStyleModifier";
import useDeleteDesignLibrarySavedStyle from "@editor/hooks/designLibrary/useDeleteDesignLibrarySavedStyle";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  generateNewName,
  generateTextSavedStylePreviewValue,
} from "@editor/utils/designLibrary";
import { trpc } from "@editor/utils/trpc";
import ModifierGroup from "@editorExtras/ModifierGroup";

import Popover from "@replo/design-system/components/popover";
import { v4 as uuidv4 } from "uuid";

type TextSavedStyleGroupProps = {
  overrideSavedStyles?: SavedTextStyle[];
  isLoading?: boolean;
};

const TextSavedStyleGroup: React.FC<TextSavedStyleGroupProps> = ({
  overrideSavedStyles,
  isLoading = false,
}) => {
  const [isTextModifierPopoverOpen, setIsTextModifierPopoverOpen] =
    React.useState(false);
  const [editingSavedStyleId, setEditingSavedStyleId] = React.useState<
    string | null
  >(null);

  const { textSavedStyles } = useGetDesignLibrarySavedStyles();

  const logEvent = useLogAnalytics();

  const isEditable = !Boolean(overrideSavedStyles);
  const projectId = useCurrentProjectId();
  const utils = trpc.useUtils();
  const { mutateAsync: createStyleMutation } =
    trpc.designLibrary.savedStyles.create.useMutation({
      onSuccess: () => {
        void utils.designLibrary.get.invalidate();
      },
    });

  const handleItemClick = async (id: string, name: string) => {
    if (!projectId || !isEditable) {
      return null;
    }

    const newName = generateNewName(name, {
      savedStyles: textSavedStyles,
      type: "text",
    });
    const savedStyleToCreate = {
      id: uuidv4(),
      name: newName,
      attributes: TEXT_STYLE_DEFAULTS[id] ?? { type: "text", htmlTag: "p" },
    };

    await createStyleMutation({
      projectId,
      type: "text",
      ...savedStyleToCreate,
    });

    logEvent("library.style.add", {
      type: "text",
      tag: savedStyleToCreate.attributes.htmlTag,
    });

    setIsTextModifierPopoverOpen(true);
    setEditingSavedStyleId(savedStyleToCreate.id);
  };

  const savedStyles = overrideSavedStyles ?? textSavedStyles;
  const isImported = overrideSavedStyles !== undefined;
  const menuItems = TEXT_MENU_ITEMS.map(({ id, title }) => ({
    id,
    title,
    onSelect: () => void handleItemClick(id, title),
    type: "leaf" as const,
    isDisabled: false,
  }));

  return (
    <>
      {isEditable && (
        <TextSavedStyleModifier
          isPopoverOpen={isTextModifierPopoverOpen}
          onPopoverOpenChange={(isOpen) => {
            if (!isOpen) {
              setEditingSavedStyleId(null);
            }
            setIsTextModifierPopoverOpen(isOpen);
          }}
          editingSavedStyle={
            textSavedStyles.find(
              (savedStyle) => savedStyle.id === editingSavedStyleId,
            ) ?? null
          }
          sideOffset={13}
          openedFrom="leftBar"
        />
      )}
      <ModifierGroup
        title="Text Styles"
        tooltipText="Add new text style"
        isDefaultOpen={savedStyles.length > 0 || isLoading}
        isCollapsible={savedStyles.length > 0}
        menuItems={isEditable && !isLoading ? menuItems : undefined}
      >
        <div className="flex flex-col gap-1">
          {isLoading ? (
            <SavedStyleSkeleton />
          ) : (
            savedStyles.map((savedStyle, index) => (
              <SavedStylePaneRow
                key={index}
                savedStyle={savedStyle}
                editingSavedStyleId={editingSavedStyleId}
                setEditingSavedStyleId={(id) => {
                  setIsTextModifierPopoverOpen(true);
                  setEditingSavedStyleId(id);
                }}
                isImported={isImported}
              />
            ))
          )}
        </div>
      </ModifierGroup>
    </>
  );
};

const SavedStylePaneRow: React.FC<{
  savedStyle: SavedTextStyle & { id?: string };
  editingSavedStyleId: string | null;
  setEditingSavedStyleId: (id: string | null) => void;
  isImported: boolean;
}> = ({
  savedStyle,
  editingSavedStyleId,
  setEditingSavedStyleId,
  isImported,
}) => {
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
  const { deleteSavedStyle, isLoading } = useDeleteDesignLibrarySavedStyle();

  const isSelected = editingSavedStyleId === savedStyle.id;

  return (
    <div
      onMouseOver={() =>
        !Boolean(editingSavedStyleId) && setIsPreviewOpen(true)
      }
      onMouseLeave={() => setIsPreviewOpen(false)}
    >
      <Popover isOpen={isPreviewOpen}>
        <Popover.Content
          shouldPreventDefaultOnInteractOutside
          side="right"
          align="start"
          // NOTE (Fran 2024-12-23): This is to make sure the popover is placed in the right position
          // This number came from the height of the row.
          alignOffset={-28}
          // NOTE (Fran 2025-01-06): This is to make sure the popover is placed in the right position
          // This number came from the spacing to the saved style panel.
          sideOffset={14}
          hideCloseButton
          className="w-56"
        >
          <div className="flex w-full h-16">
            <ReploPreviewTextSavedStyle
              attributes={savedStyle.attributes}
              text={savedStyle.name}
            />
          </div>
        </Popover.Content>
        <Popover.Anchor className="top-0 left-0" />
      </Popover>
      <SavedStyleRow
        name={savedStyle.name}
        value={generateTextSavedStylePreviewValue(
          savedStyle.attributes as SavedStyleTextAttributes,
        )}
        type="text"
        isLoading={isLoading}
        isSelected={isSelected}
        onClick={() => {
          setEditingSavedStyleId(savedStyle.id ?? null);
          setIsPreviewOpen(false);
        }}
        isImported={isImported}
        endEnhancer={
          // NOTE (Fran 2024-11-14): In the case we were showing the imported or duplicated saved styles,
          // we don't have an id. And we don't want to show the end enhancer in that case, because they
          // are not editable.
          savedStyle.id && (
            <SavedStyleItemEndEnhancer
              type="text"
              savedStyleAttributes={savedStyle.attributes}
              name={savedStyle.name}
              id={savedStyle.id}
              deleteSavedStyle={(id) => void deleteSavedStyle(id)}
              onSelectSavedStyle={() => {
                if (savedStyle.id) {
                  setEditingSavedStyleId(savedStyle.id);
                }
              }}
            />
          )
        }
      />
    </div>
  );
};

export default TextSavedStyleGroup;
