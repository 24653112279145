import type { Component } from "schemas/component";
import type { RenderComponentProps } from "../../../shared/types";
import type { Context } from "../../AlchemyVariable";
import type { DefaultActionsMap, ReploComponentProps } from "../ReploComponent";
import type { ActionType } from "./config";

import * as React from "react";

import merge from "lodash-es/merge";
import { useSetActiveStateId } from "replo-runtime/store/contexts/RuntimeActiveState/hooks";
import { useEffectEvent } from "replo-utils/react/use-effect-event";
import { mediaSizes } from "schemas/breakpoints";

import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { media } from "../../../shared/utils/breakpoints";
import { mergeContext } from "../../../shared/utils/context";
import { getRowObjectsFromDataTable } from "../../AlchemyDataTable";
import { ReploComponent } from "../ReploComponent";

const Dropdown = (props: RenderComponentProps) => {
  const itemsConfig = props.component.props.items;
  const itemsConfigId = itemsConfig?.id;
  const dataTableMapping =
    useRuntimeContext(RuntimeHooksContext).useDataTableMapping();
  const {
    activeCurrency: currencyCode,
    moneyFormat,
    activeLanguage: language,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const isShopifyProductsLoading =
    useRuntimeContext(RuntimeHooksContext).useIsShopifyProductsLoading();
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const items = React.useMemo(() => {
    const rows = getRowObjectsFromDataTable(itemsConfigId, dataTableMapping, {
      products,
      currencyCode,
      moneyFormat,
      language,
      templateProduct,
      isEditor: isEditorApp,
      isShopifyProductsLoading,
    });
    return rows ?? [];
  }, [
    currencyCode,
    moneyFormat,
    language,
    dataTableMapping,
    itemsConfigId,
    products,
    templateProduct,
    isEditorApp,
    isShopifyProductsLoading,
  ]);

  const currentDropdownValue = props.context.dropdownValues[
    props.component.id
  ] || {
    type: "dataTable",
    dataTableId: itemsConfigId,
    index: 0,
    item: itemsConfigId ? items[0] : null,
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: missing deps props.component.id, props.context.actionHooks.setDropdownValue, items[0]
  React.useEffect(() => {
    if (itemsConfigId && !props.context.dropdownValues[props.component.id]) {
      props.context.actionHooks.setDropdownValue?.(props.component.id, {
        type: "dataTable",
        dataTableId: itemsConfigId,
        index: 0,
        item: itemsConfigId ? items[0] : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsConfigId]);

  const setActiveStateId = useSetActiveStateId(props.component.id);
  const activeStateId = props.context.variants?.find((v) => v.isActive)?.id;

  const selectedComponent = props.component.props[
    "_selectedComponent"
  ] as Component;
  const itemComponent = props.component.props["_itemComponent"] as Component;

  const createItemContext = React.useCallback<DropdownItemContextCreator>(
    ({ currentIndex, currentItem, isSelected }) => {
      const actionHooks = {
        setDropdownItem: (index: number) => {
          props.context.actionHooks.setDropdownValue?.(props.component.id, {
            type: "dataTable",
            dataTableId: itemsConfigId,
            index: index,
            item: itemsConfigId ? items[index] : null,
          });
        },
        toggleDropdown: () => {
          setActiveStateId?.(
            activeStateId === "alchemy:dropdownOpen"
              ? "alchemy:dropdownClosed"
              : "alchemy:dropdownOpen",
          );
        },
      } satisfies {
        [K in ActionType]: Function;
      };

      return mergeContext(props.context, {
        state: {
          dropdown: { currentIndex, currentItem, isSelected },
        },
        actionHooks,
      });
    },
    [
      items,
      itemsConfigId,
      props.component.id,
      props.context,
      activeStateId,
      setActiveStateId,
    ],
  );

  const selectedContext = createItemContext({
    currentIndex: currentDropdownValue.index,
    currentItem: currentDropdownValue.item,
    isSelected: true,
  });

  // Register a body click listener so that clicking outside of the dropdown
  // closes it
  const handleClick = useEffectEvent(() => {
    setActiveStateId?.("alchemy:dropdownClosed");
  });
  React.useEffect(() => {
    const bodyElement = globalWindow?.document.body;
    const element = bodyElement ?? globalWindow;
    element?.addEventListener("click", handleClick);
    return () => {
      element?.removeEventListener("click", handleClick);
    };
  }, [globalWindow, handleClick]);

  const isShowingOpen = activeStateId === "alchemy:dropdownOpen";

  // Note (Noah, 2023-03-01, REPL-6482): We have to override several style properties
  // to ensure the dropdown container doesn't have overflow hidden and that the dropdown
  // menu (the absolutely positioned component) itself doesn't get its position reset by
  // our getAliasedStyles code.
  // TODO (Noah, 2023-03-01): This should really be rethought in the future - should we
  // have a specific DropdownMenu component, which we disallow positioning for? That seems
  // like a better solution than having the components here just be a container but being
  // _special_ styles
  const absolutelyPositionedComponentStyles = {
    style: {
      top: 0,
      left: 0,
      position: "absolute",
      overflow: "visible",
      zIndex: 1,
      WebkitMaskImage: "none",
      __dropdown: true,
      ...Object.fromEntries(
        mediaSizes.map((size) => {
          return [media[size], { position: "absolute", overflow: "visible" }];
        }),
      ),
    },
  };

  const containerComponentStyles = {
    style: {
      overflow: "visible",
      WebkitMaskImage: "none",
      ...Object.fromEntries(
        mediaSizes.map((size) => {
          return [media[size], { overflow: "visible" }];
        }),
      ),
    },
  };

  const defaultActions = React.useMemo<DefaultActionsMap>(
    () => ({
      actions: {
        // @ts-expect-error: TODO (Noah, 2024-01-01, REPL-9866): These are
        // errors because the toggleDropdown action doesn't have a value, but
        // that's only the case because our types are wrong. Fix them!
        onClick: [{ id: "alchemy:toggleDropdown", type: "toggleDropdown" }],
      },
      placement: "after",
    }),
    [],
  );

  return (
    // @ts-ignore
    <div {...merge({}, props.componentAttributes, containerComponentStyles)}>
      {/* Render the selected component first, this is the collapsed case. If
      we render the open state, it will be rendered OVER this */}
      <ReploComponent
        component={selectedComponent}
        defaultActions={defaultActions}
        context={selectedContext}
        repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
        extraAttributes={props.extraAttributes}
      />
      {isShowingOpen && (
        <div
          {...merge(
            {},
            props.componentAttributes,
            {
              style: {
                overflow: "visible",
                WebkitMaskImage: "none",
              },
            },
            absolutelyPositionedComponentStyles,
          )}
        >
          {/* Render the selected state again, then render all items (this is all
          positioned absolute so it covers the previously rendered closed state) */}
          <ReploComponent
            component={selectedComponent}
            defaultActions={defaultActions}
            context={selectedContext}
            repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
            extraAttributes={props.extraAttributes}
          />
          {items.map((item: any, index: number) => {
            return (
              <DropdownItem
                key={index}
                component={itemComponent}
                repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
                extraAttributes={props.extraAttributes}
                createItemContext={createItemContext}
                index={index}
                isSelected={index === currentDropdownValue.index}
                item={item}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

function DropdownItem({
  createItemContext,
  index,
  isSelected,
  item,
  ...props
}: Omit<ReploComponentProps, "context"> & {
  createItemContext: DropdownItemContextCreator;
  index: number;
  isSelected: boolean;
  item: any;
}) {
  return (
    <ReploComponent
      key={index}
      {...props}
      context={React.useMemo(() => {
        return createItemContext({
          currentIndex: index,
          currentItem: item,
          isSelected,
        });
      }, [createItemContext, index, isSelected, item])}
      // @ts-expect-error: TODO (Noah, 2024-01-01, REPL-9866): These are errors
      // because the toggleDropdown action doesn't have a value, but that's only
      // the case because our types are wrong. Fix them!
      defaultActions={React.useMemo(
        () => ({
          actions: {
            onClick: [
              {
                id: "alchemy:setDropdownItem",
                type: "setDropdownItem",
                value: index,
              },
              { id: "alchemy:toggleDropdown", type: "toggleDropdown" },
            ],
          },
          placement: "after",
        }),
        [index],
      )}
    />
  );
}

export default Dropdown;

type DropdownItemContextCreator = (args: {
  currentIndex: number;
  currentItem: any;
  isSelected: boolean;
}) => Context;
