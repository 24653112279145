import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type { ReorderableTreeNode } from "@editor/types/tree";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type { MediaSize } from "schemas/breakpoints";
import type { Component, ReploComponentType } from "schemas/component";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import { getComponentEditorData } from "@editor/components/editor/componentTypes";
import { elementTypeToEditorData } from "@editor/components/editor/element";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { DropEdge } from "@editor/types/tree";
import { lastItem } from "@utils/array";

import classNames from "classnames";
import difference from "lodash-es/difference";
import flatten from "lodash-es/flatten";
import isEqual from "lodash-es/isEqual";
import {
  BsBoundingBoxCircles,
  BsFillGridFill,
  BsFullscreen,
  BsLightningChargeFill,
} from "react-icons/bs";
import { getMediaSizeStyleAttributeValue } from "replo-runtime/shared/utils/breakpoints";
import { getChildrenIds } from "replo-runtime/shared/utils/component";
import { filterNulls } from "replo-utils/lib/array";

export function elementToDataTree({
  component,
  elementType,
  componentDataMapping,
}: {
  component: Component;
  elementType: ReploElementType;
  componentDataMapping: ComponentDataMapping;
}): ReorderableTreeNode {
  const dataNode = componentToDataNodeFromMapping(
    component,
    componentDataMapping,
  );

  return {
    ...dataNode,
    label: elementTypeToEditorData[elementType].singularDisplayName,
  };
}

function componentToDataNodeFromMapping(
  component: Component,
  componentDataMapping: ComponentDataMapping,
): ReorderableTreeNode {
  const componentData = componentDataMapping[component.id];

  // NOTE (Ovishek, 2022-04-26): We are only showing the first child in the tree if
  // there's a dynamic data involved, b/c in that case we are only using that first child
  // to dynamically render the others.
  // TODO (Martin, 2024-03-29): We should probably do this for more than the
  // "carouselV3Slides" component type.
  const childrenNodes =
    component.type === "carouselV3Slides" &&
    componentData?.ancestorIsDynamicRepetition
      ? component.children?.slice(0, 1)
      : component.children;

  const childrenNodesFromProps = filterNulls(
    componentData?.customPropDefinitions
      .filter((definition) => definition.type === "component")
      .map((definition) => component.props[definition.id] as Component),
  );

  return {
    id: component.id,
    label: componentData?.label ?? "Component",
    children:
      (childrenNodes ?? []).concat(childrenNodesFromProps).map((c) => {
        return componentToDataNodeFromMapping(c, componentDataMapping);
      }) ?? [],
  };
}

export function getDescendantNodeKeys(
  node: ReorderableTreeNode | null,
): string[] {
  // Return list of keys that are in node's subtree, including itself
  if (!node) {
    return [];
  }
  if (node.children?.length === 0) {
    return [node.id];
  }
  return [node.id].concat(
    flatten(node.children.map((c) => getDescendantNodeKeys(c))),
  );
}

export function getAncestorNodeKeys(
  node: ReorderableTreeNode,
  selectedNodeId: string,
): string[] {
  // Return list of keys that are node's ancestors (not including itself)
  if (node === null || node === undefined) {
    return [];
  }

  const findAmongChildren = (
    currNode: ReorderableTreeNode,
  ): [string[], boolean] => {
    if (currNode.id === selectedNodeId) {
      return [[], true];
    }

    if (currNode.children?.length === 0) {
      return [[], false];
    }

    const keysOnPath = currNode.children.map((childNode) => {
      const [childPath, didFindTargetNode] = findAmongChildren(childNode);
      if (didFindTargetNode) {
        return [currNode.id].concat(childPath);
      }
      return [];
    });
    const flattenedKeys = flatten(keysOnPath);

    return [flattenedKeys, flattenedKeys.length > 0];
  };
  const [ancestorNodeIds, didFindAncestorNode] = findAmongChildren(node);
  if (didFindAncestorNode) {
    return ancestorNodeIds;
  }
  return [];
}

export function getTreeNodeByKey(
  node: ReorderableTreeNode,
  id: string,
): ReorderableTreeNode | null {
  // Return the TreeNode in treeData that has the id
  if (!node) {
    return null;
  }
  if (node.id === id) {
    return node;
  }
  for (const child of node.children) {
    const possibleChildNode = getTreeNodeByKey(child, id);
    if (possibleChildNode) {
      return possibleChildNode;
    }
  }
  return null;
}

export function findAncestorIdAtDepth(
  nodeId: string,
  depth: number,
  componentDataMapping: ComponentDataMapping,
) {
  const componentData = componentDataMapping[nodeId];
  if (!componentData) {
    return null;
  }
  if (componentData.depth === depth) {
    return nodeId;
  }

  if (!componentData.parentId) {
    return null;
  }

  return findAncestorIdAtDepth(
    componentData.parentId,
    depth,
    componentDataMapping,
  );
}

type EdgeData = {
  componentId: string;
  depth: number;
  commonDepthComponentId: string | null;
};

export function getSelectedComponentIds(
  e: React.MouseEvent,
  targetComponentId: string,
  draftComponentIds: string[],
  componentDataMapping: ComponentDataMapping,
) {
  const { ctrlKey, shiftKey, metaKey, type } = e;

  if (type === "contextmenu") {
    return handleContextMenuClick({
      targetComponentId,
      draftComponentIds,
    });
  } else if (ctrlKey || metaKey) {
    return handleCtrlClick({
      targetComponentId,
      draftComponentIds,
      componentDataMapping,
    });
  } else if (shiftKey) {
    return handleShiftClick({
      targetComponentId,
      draftComponentIds,
      componentDataMapping,
    });
  }

  return [targetComponentId];
}

function handleContextMenuClick({
  targetComponentId,
  draftComponentIds,
}: {
  targetComponentId: string;
  draftComponentIds: string[];
}) {
  if (draftComponentIds.includes(targetComponentId)) {
    return draftComponentIds;
  }
  return [targetComponentId];
}

export function handleCtrlClick({
  targetComponentId,
  draftComponentIds,
  componentDataMapping,
}: {
  targetComponentId: string;
  draftComponentIds: string[];
  componentDataMapping: ComponentDataMapping;
}) {
  const anyDraftComponentId = draftComponentIds[0];
  if (
    !isFeatureEnabled("multi-selection") &&
    anyDraftComponentId &&
    componentDataMapping[anyDraftComponentId]?.parentId !==
      componentDataMapping[targetComponentId]?.parentId
  ) {
    return draftComponentIds;
  }

  if (draftComponentIds.includes(targetComponentId)) {
    return draftComponentIds.filter((id) => id !== targetComponentId);
  }

  // Check if target component has an ancestor that is a draft component
  if (
    hasAncestorDraftComponent(
      targetComponentId,
      draftComponentIds,
      componentDataMapping,
    )
  ) {
    return draftComponentIds;
  }

  return filterDescendantIdsFromDraftComponentIds({
    componentId: targetComponentId,
    draftComponentIds,
    componentDataMapping,
  }).concat(targetComponentId);
}

// TODO add a docblock about how overall the algorithm works
export function handleShiftClick({
  targetComponentId,
  draftComponentIds,
  componentDataMapping,
}: {
  targetComponentId: string;
  draftComponentIds: string[];
  componentDataMapping: ComponentDataMapping;
}) {
  const anyDraftComponentId = draftComponentIds[0];
  if (
    !isFeatureEnabled("multi-selection") &&
    anyDraftComponentId &&
    componentDataMapping[anyDraftComponentId]?.parentId !==
      componentDataMapping[targetComponentId]?.parentId
  ) {
    return draftComponentIds;
  }

  // Check if target component has an ancestor that is a draft component
  if (
    hasAncestorDraftComponent(
      targetComponentId,
      draftComponentIds,
      componentDataMapping,
    )
  ) {
    return draftComponentIds;
  }

  // Get edges of the operation (from and to)
  const fromNodeComponentId = lastItem(draftComponentIds) ?? null;
  if (!fromNodeComponentId) {
    // draftComponentIds is empty so we want to simply select the target component
    return draftComponentIds.concat(targetComponentId);
  }
  const fromNodeComponentData = componentDataMapping[fromNodeComponentId];

  const toNodeComponentId = targetComponentId;
  const toNodeComponentData = componentDataMapping[toNodeComponentId];

  if (!fromNodeComponentData || !toNodeComponentData) {
    return draftComponentIds.concat(targetComponentId);
  }

  let edgesData: [EdgeData, EdgeData];
  let shallowestCommonDepth = 0;
  if (fromNodeComponentData.depth === toNodeComponentData.depth) {
    // Both edges are at the same tree depth
    if (
      !fromNodeComponentData.parentId ||
      !toNodeComponentData.parentId ||
      fromNodeComponentData.parentId === toNodeComponentData.parentId
    ) {
      // Both edges have the same parent or no parent at all (they are siblings)
      shallowestCommonDepth = fromNodeComponentData.depth;
      edgesData = [
        {
          componentId: fromNodeComponentId,
          depth: fromNodeComponentData.depth,
          commonDepthComponentId: fromNodeComponentId,
        },
        {
          componentId: toNodeComponentId,
          depth: toNodeComponentData.depth,
          commonDepthComponentId: toNodeComponentId,
        },
      ];
    } else {
      // Edges have different parents, so the shallowest common depth is where
      // their ancestors share the same parent
      let currentFromNodeData =
        componentDataMapping[fromNodeComponentData.parentId];
      let currentToNodeData =
        componentDataMapping[toNodeComponentData.parentId];
      shallowestCommonDepth = currentFromNodeData?.depth ?? 0;
      while (currentFromNodeData?.parentId !== currentToNodeData?.parentId) {
        currentFromNodeData =
          componentDataMapping[currentFromNodeData!.parentId!];
        currentToNodeData = componentDataMapping[currentToNodeData!.parentId!];
        shallowestCommonDepth = currentFromNodeData?.depth ?? 0;
      }

      edgesData = [
        {
          componentId: fromNodeComponentId,
          depth: fromNodeComponentData.depth,
          commonDepthComponentId: currentFromNodeData!.parentId,
        },
        {
          componentId: targetComponentId,
          depth: toNodeComponentData.depth,
          commonDepthComponentId: currentToNodeData!.parentId,
        },
      ];
    }
  } else {
    // Edges are at different tree depths
    shallowestCommonDepth = Math.min(
      fromNodeComponentData.depth,
      toNodeComponentData.depth,
    );

    const deepestComponentId =
      fromNodeComponentData.depth > toNodeComponentData.depth
        ? fromNodeComponentId
        : toNodeComponentId;
    const deepestComponentDepth = Math.max(
      fromNodeComponentData.depth,
      toNodeComponentData.depth,
    );
    const shallowestComponentId =
      fromNodeComponentData.depth > toNodeComponentData.depth
        ? toNodeComponentId
        : fromNodeComponentId;

    const commonDepthComponentId = findAncestorIdAtDepth(
      deepestComponentId,
      shallowestCommonDepth,
      componentDataMapping,
    );

    if (commonDepthComponentId === targetComponentId) {
      // Target component is ancestor of latest draft component id so we want to
      // operate as if it was a ctrl+click
      return filterDescendantIdsFromDraftComponentIds({
        componentId: targetComponentId,
        draftComponentIds,
        componentDataMapping,
      }).concat(targetComponentId);
    }

    edgesData = [
      {
        componentId: shallowestComponentId,
        depth: shallowestCommonDepth,
        commonDepthComponentId: shallowestComponentId,
      },
      {
        componentId: deepestComponentId,
        depth: deepestComponentDepth,
        commonDepthComponentId,
      },
    ];
  }

  // Sort edges by sibling index so that we make sure edges are processed in the
  // correct order
  const orderedEdgesData = edgesData.sort(
    (a, b) =>
      componentDataMapping[a.commonDepthComponentId!]!.siblingIndex -
      componentDataMapping[b.commonDepthComponentId!]!.siblingIndex,
  );

  const [topEdgeData, bottomEdgeData] = orderedEdgesData;

  const topEdgeSelectedComponentIds = getEdgeSelectedComponentIds(
    topEdgeData,
    "top",
    shallowestCommonDepth,
    componentDataMapping,
  );

  const bottomEdgeSelectedComponentIds = getEdgeSelectedComponentIds(
    bottomEdgeData,
    "bottom",
    shallowestCommonDepth,
    componentDataMapping,
  );

  const edgesSiblingsSelectedComponentIds =
    getEdgesSiblingsSelectedComponentIds(
      orderedEdgesData,
      componentDataMapping,
    );

  const selectedComponentIds = topEdgeSelectedComponentIds.concat(
    edgesSiblingsSelectedComponentIds,
    bottomEdgeSelectedComponentIds,
  );

  // Reverse the order if the target component is the top edge component so that
  // the target component id is the last element as expected. Basically, act as if
  // the user has selected the components one by one in order, ENDING with the target
  // component
  const orderedSelectedComponentIds =
    targetComponentId === topEdgeData.componentId
      ? selectedComponentIds.reverse()
      : selectedComponentIds;
  return [...new Set(draftComponentIds.concat(orderedSelectedComponentIds))];
}

function getEdgeSelectedComponentIds(
  edgeData: EdgeData,
  edgePosition: "top" | "bottom",
  shallowestCommonDepth: number,
  componentDataMapping: ComponentDataMapping,
) {
  let currentDepth = edgeData.depth;
  let currentComponentId = edgeData.componentId;
  let selectedComponentIds: string[] = [currentComponentId];

  while (currentDepth > shallowestCommonDepth) {
    const currentComponentData = componentDataMapping[currentComponentId];
    if (!currentComponentData || !currentComponentData.parentId) {
      break;
    }

    const parentComponentChildren = getChildrenIds(
      currentComponentData.parentId,
      componentDataMapping,
    );

    // Add all corresponding siblings to the current selection
    let selectedIdsForThisDepth: string[] = [];
    const currentComponentIndex =
      componentDataMapping[currentComponentId]!.siblingIndex;
    const startIndex = edgePosition === "top" ? currentComponentIndex + 1 : 0;
    const endIndex =
      edgePosition === "top"
        ? parentComponentChildren.length
        : currentComponentIndex;
    for (let i = startIndex; i < endIndex; i++) {
      selectedIdsForThisDepth.push(parentComponentChildren[i]!);
    }

    // Check if all siblings are selected, if so select the parent instead
    if (
      parentComponentChildren.length === selectedIdsForThisDepth.length + 1 &&
      selectedComponentIds.includes(currentComponentId)
    ) {
      // Clean up currentComponentId from the final selection because we are
      // going to add its parent instead
      selectedComponentIds = selectedComponentIds.filter(
        // eslint-disable-next-line no-loop-func
        (id) => id !== currentComponentId,
      );
      selectedIdsForThisDepth = [currentComponentData.parentId];
    }

    selectedComponentIds.push(...selectedIdsForThisDepth);
    currentDepth--;
    currentComponentId = currentComponentData.parentId;
  }

  return edgePosition === "bottom"
    ? selectedComponentIds.reverse()
    : selectedComponentIds;
}

function getEdgesSiblingsSelectedComponentIds(
  edges: EdgeData[],
  componentDataMapping: ComponentDataMapping,
) {
  const selectedComponentIds: string[] = [];
  const commonDepthComponentId = edges[0]!.commonDepthComponentId;

  if (!commonDepthComponentId) {
    return [];
  }

  const currentComponentData = componentDataMapping[commonDepthComponentId];
  if (!currentComponentData) {
    return [];
  }

  const parentComponentChildren = getChildrenIds(
    currentComponentData.parentId,
    componentDataMapping,
  );

  const startIndex =
    componentDataMapping[edges[0]!.commonDepthComponentId!]!.siblingIndex;
  const endIndex =
    componentDataMapping[edges[1]!.commonDepthComponentId!]!.siblingIndex;
  for (let i = startIndex + 1; i < endIndex; i++) {
    selectedComponentIds.push(parentComponentChildren[i]!);
  }
  return selectedComponentIds;
}

// If we're adding a component that has descendants that are part of
// draftComponentIds, we want to unselect those descendants because the
// parent is being added.
function filterDescendantIdsFromDraftComponentIds(config: {
  componentId: string;
  draftComponentIds: string[];
  componentDataMapping: ComponentDataMapping;
}) {
  const { componentId, draftComponentIds, componentDataMapping } = config;
  const descendantsIdsToUnselect = getFilteredDescendantIds(
    componentId,
    componentDataMapping,
    (id) => draftComponentIds.includes(id),
  );

  return difference(draftComponentIds, descendantsIdsToUnselect);
}

function getFilteredDescendantIds(
  componentId: string,
  componentDataMapping: ComponentDataMapping,
  filter: (componentId: string) => boolean,
) {
  const componentData = componentDataMapping[componentId];
  if (!componentData) {
    return [];
  }

  const componentDescendantsIds = componentData.containedComponentData.map(
    (componentData) => componentData[0],
  );

  return componentDescendantsIds.filter(filter);
}

function hasAncestorDraftComponent(
  componentId: string,
  draftComponentIds: string[],
  componentDataMapping: ComponentDataMapping,
) {
  const componentData = componentDataMapping[componentId];
  if (!componentData) {
    return false;
  }

  const ancestorIds = componentData.ancestorComponentData.map(
    (componentData) => componentData[0],
  );

  return ancestorIds.some((id) => draftComponentIds.includes(id));
}

export function isComponentHidden(
  component: Component,
  getAttribute: GetAttributeFunction,
) {
  return getAttribute(component, "style.display").value === "none";
}

export function getIconColorClassName(
  isRowHidden: boolean,
  isSymbolRef: boolean,
) {
  if (isRowHidden) {
    return isSymbolRef ? "text-purple-200" : "text-blue-200";
  }
  return isSymbolRef ? "text-purple-600" : "text-blue-600";
}

export function forEachNodeAndDescendent(
  node: ReorderableTreeNode,
  parentNode: ReorderableTreeNode | null,
  callback: (
    node: ReorderableTreeNode,
    parentNode: ReorderableTreeNode | null,
    depth: number,
  ) => void,
  depth = 0,
) {
  if (node) {
    callback(node, parentNode, depth);
    for (const child of node.children) {
      forEachNodeAndDescendent(child, node, callback, depth + 1);
    }
  }
}

export function getDropEdge(
  currentTop: number,
  dropY: number,
  treeRowHeight: number,
  isItemDroppable: boolean,
  isItemExpanded: boolean,
): DropEdge {
  const percentage = ((dropY - currentTop) / treeRowHeight) * 100;

  let dropEdge = null;

  if (isItemDroppable) {
    if (percentage <= 33) {
      dropEdge = DropEdge.beforeCurrentNode;
    } else if (percentage <= 66) {
      dropEdge = isItemExpanded ? DropEdge.afterCurrentNode : DropEdge.middle;
    } else {
      dropEdge = DropEdge.afterCurrentNode;
    }
  } else {
    if (percentage <= 50) {
      dropEdge = DropEdge.beforeCurrentNode;
    } else {
      dropEdge = DropEdge.afterCurrentNode;
    }
  }

  return dropEdge;
}

export function isArrayUnOrderlyEqual(a: Array<any>, b: Array<any>) {
  const sortedA = [...a].sort();
  const sortedB = [...b].sort();
  return isEqual(sortedA, sortedB);
}

export function getComponentAlignment(
  size: MediaSize,
  component?: Component,
): string | null {
  if (!component || !component.props) {
    return null;
  }
  return getMediaSizeStyleAttributeValue("alignSelf", component.props, size);
}

export function getComponentFlexDirection(
  size: MediaSize,
  componentProps?: Partial<Component>,
): string | null {
  if (!componentProps) {
    return null;
  }
  return getMediaSizeStyleAttributeValue("flexDirection", componentProps, size);
}

export function getComponentPositioning(
  size: MediaSize,
  component?: Component,
): string | null {
  if (!component || !component.props) {
    return null;
  }
  return getMediaSizeStyleAttributeValue("position", component.props, size);
}

export function getComponentWidthProperties(
  size: MediaSize,
  component?: Component,
): {
  width: string | undefined;
  maxWidth: string | undefined;
  minWidth: string | undefined;
} {
  if (!component?.props) {
    return {
      width: undefined,
      maxWidth: undefined,
      minWidth: undefined,
    };
  }
  return {
    width: getMediaSizeStyleAttributeValue("width", component.props, size),
    maxWidth: getMediaSizeStyleAttributeValue(
      "maxWidth",
      component.props,
      size,
    ),
    minWidth: getMediaSizeStyleAttributeValue(
      "minWidth",
      component.props,
      size,
    ),
  };
}

export function getIconAndMessage(
  isPageRoot: boolean,
  direction: "next" | "previous" | undefined,
  componentType: ReploComponentType,
  iconName: string | undefined,
  componentPosition: string | null,
  componentHasActions: boolean,
  componentName: string,
  alignSelf: string | null,
  // Note (Sebas, 2023-05-16): When I logged this the "row" value was shown as
  // undefined. So if this value is undefined we should treat it as "row".
  ancestorFlexDirection: string | null = "row",
) {
  let icon,
    message: string | null = null;
  if (isPageRoot) {
    icon = <BsFillGridFill size="14px" className="text-blue-600" />;
  } else if (componentHasActions) {
    icon = <BsLightningChargeFill size="14px" className="text-blue-600" />;
    message = `${componentName} has interactions`;
  } else {
    icon = getComponentEditorData(componentType)?.getIcon({
      direction,
      iconName,
    }) || <BsBoundingBoxCircles size={16} className="text-red-500" />;
  }

  if (alignSelf && alignSelf !== "stretch" && ancestorFlexDirection) {
    const position = getPositionName(alignSelf, ancestorFlexDirection);
    if (position) {
      icon = withAlignSelfWrapper(icon, position, ancestorFlexDirection);
      message = `${componentName} is aligned ${position}`;
    }
  }

  if (componentPosition === "fixed") {
    icon = withFixedPositionWrapper(icon);
    message = `${componentName} is positioned Fixed To Page`;
  }

  if (componentPosition === "absolute") {
    icon = withFixedPositionWrapper(icon);
    message = `${componentName} is positioned Relative To Container`;
  }

  return { icon, message };
}

const getPositionName = (
  alignSelf: string,
  ancestorFlexDirection: string | null,
) => {
  let positionName;
  if (ancestorFlexDirection === "column") {
    switch (alignSelf) {
      case "flex-start":
        positionName = "left" as const;
        break;
      case "flex-end":
        positionName = "right" as const;
        break;
      case "center":
        positionName = "center" as const;
        break;
      default:
        positionName = null;
        break;
    }
  } else {
    switch (alignSelf) {
      case "start":
      case "flex-start":
        positionName = "top" as const;
        break;
      case "end":
      case "flex-end":
        positionName = "bottom" as const;
        break;
      case "center":
        positionName = "center" as const;
        break;
      default:
        positionName = null;
        break;
    }
  }

  return positionName;
};

function withFixedPositionWrapper(icon: React.ReactNode) {
  return (
    <div className="relative">
      <BsFullscreen />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-[0.65] transform">
        {icon}
      </div>
    </div>
  );
}

function withAlignSelfWrapper(
  icon: React.ReactNode,
  alignSelf: "top" | "bottom" | "left" | "right" | "center" | null,
  ancestorFlexDirection: string,
) {
  const borderClassNames = {
    top: "border-t",
    bottom: "border-b",
    left: "border-l",
    right: "border-r",
    center: ancestorFlexDirection === "column" ? "border-x" : "border-y",
  };
  return (
    <div
      className={classNames(
        "border-blue-600",
        alignSelf ? borderClassNames[alignSelf] : null,
      )}
    >
      <div className="scale-[0.65]">{icon}</div>
    </div>
  );
}
