import * as React from "react";

import { useLocation } from "react-router-dom";

const useCurrentAnalyticsTab = () => {
  const location = useLocation();
  const analyticsTab = location.pathname.includes("deep-dive")
    ? "analytics_deep_dive"
    : "analytics_overview";

  return analyticsTab;
};

export default useCurrentAnalyticsTab;
