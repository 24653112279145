import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { UpdateCanvasHeight } from "./canvas-types";

import * as React from "react";

import { getTargetFrameWindow } from "@editor/hooks/useTargetFrame";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { useEditorDispatch, useEditorStore } from "@editor/store";
import { getEditorElementRootNode } from "@editor/utils/dom";

import { selectCanvasesIFrames, setCanvasHeight } from "./canvas-reducer";

export function useUpdateCanvasHeight() {
  const store = useEditorStore();
  const dispatch = useEditorDispatch();
  const prevElementHeightsRef = React.useRef(new Map<EditorCanvas, number>());

  return React.useCallback<UpdateCanvasHeight>(
    (canvas, opts) => {
      const isNoMirrorEnabled = isFeatureEnabled("no-mirror");
      if (isNoMirrorEnabled) {
        return;
      }

      const { forceResetHeight = false } = opts ?? {};
      const canvasesIFrames = selectCanvasesIFrames(store.getState());
      const targetFrame = canvasesIFrames[canvas] ?? null;
      if (!targetFrame) {
        return;
      }
      const targetFrameContentWindow = getTargetFrameWindow(targetFrame);
      const targetFrameContentDocument = targetFrameContentWindow?.document;
      const rootNode = targetFrameContentDocument
        ? getEditorElementRootNode(targetFrameContentDocument)
        : null;
      if (
        !targetFrameContentWindow ||
        !targetFrameContentDocument?.body ||
        !rootNode
      ) {
        // If no frame has loaded yet, nothing to do here
        return;
      }

      const currentElementHeight =
        rootNode.firstElementChild?.scrollHeight ?? 0;
      const prevElementHeight = prevElementHeightsRef.current.get(canvas) ?? 0;
      prevElementHeightsRef.current.set(canvas, currentElementHeight);
      /**
       * NOTE (Noah, 2021-08-02): We calculate the element height and canvas
       * height independently and ONLY update if the element height has changed.
       * This is because in pages whose themes have something above the element
       * with a height that includes vh, the scrollHeight of the page is
       * actually dependent on the height of the iframe, and thus we can get
       * into an infinite state update loop where every event loop we look at
       * the scroll height and resize the iframe, but then due to that the
       * scroll height becomes bigger, over and over.
       *
       * This is a bit bug prone still but it eliminates recursive state errors
       * and generally lets people like Because Animals use the editor without
       * too much of a hassle.
       */
      let currentCanvasHeight =
        targetFrameContentDocument.body.scrollHeight || 0;

      // Note (Noah, 2021-12-12): If the element is >= the value that we'd get
      // by having an element with 100vh, we'll get into an infinite state
      // update if we tried to update the canvas height to the element's height.
      // Therefore we calculate the value of 100vh and check for it - if the
      // element's height is 100vh, we don't update.
      const suspectedVhValue = Math.max(
        targetFrameContentDocument.documentElement.clientHeight || 0,
        targetFrameContentWindow.innerHeight || 0,
      );

      // TODO (Noah, 2021-12-12): If we have an element which is e.g. 110vh
      // (anything greater than 100) then we add an artificial minimum since
      // otherwise we'll get into an infinite state update. I'm not sure if
      // there's a way to correctly handle this? Maybe we could look at the
      // component tree and figure out which element has the vh?
      if (
        currentElementHeight > suspectedVhValue &&
        // Don't add a minimum if we're initially figuring out the element's height
        prevElementHeight !== 0
      ) {
        currentCanvasHeight = Math.min(currentCanvasHeight, 10_000);
      }

      if (currentElementHeight > currentCanvasHeight) {
        dispatch(setCanvasHeight({ canvas, height: currentElementHeight }));
      } else if (
        currentCanvasHeight &&
        currentElementHeight &&
        currentElementHeight !== suspectedVhValue
      ) {
        dispatch(
          setCanvasHeight({
            canvas,
            height: (canvasHeight) =>
              forceResetHeight
                ? currentCanvasHeight
                : // If we're only updating the element height, only update it by the

                  // amount that it changed. This gets around random height being
                  // added when elements change if part of the theme DOM is
                  // height: 100vh
                  Math.min(
                    currentCanvasHeight,
                    canvasHeight +
                      currentElementHeight -
                      (prevElementHeight ?? 0),
                  ),
          }),
        );
      } else {
        // NOTE (USE-486, Chance 2023-10-25): If this callback is triggered for
        // any other reason, ensure that the canvas height is synced with our
        // measured height. Our checks above may not catch all cases where we
        // need to resize, which can result in the canvas being too small to
        // render a page's content. If the canvas height is unchanged then this
        // is a no-op since nothing changes in our reducer, so there shouldn't
        // be an infinite loop problem.
        dispatch(
          setCanvasHeight({
            canvas: canvas,
            height: currentCanvasHeight,
          }),
        );
      }
    },
    [dispatch, store],
  );
}
