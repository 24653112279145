import type { ReploElement } from "schemas/generated/element";

import * as React from "react";
import { useCallback, useState } from "react";

import { successToast } from "@common/designSystem/Toast";
import Modal from "@editor/components/common/designSystem/Modal";
import { withElementEditorErrorContext } from "@editor/contexts/ElementEditorErrorContext";
import { useModal } from "@editor/hooks/useModal";
import usePublishedInfo from "@editor/hooks/usePublishedInfo";
import { archiveElement as archiveElementAction } from "@editor/reducers/core-reducer";
import { useEditorDispatch } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import { BsFillCircleFill } from "react-icons/bs";
import { capitalizeFirstLetter } from "replo-utils/lib/string";

type ArchiveElementModalProps = {
  element: ReploElement;
};

export const ArchiveElementModal: React.FC<ArchiveElementModalProps> =
  withElementEditorErrorContext(({ element }) => {
    const modal = useModal();

    const [shouldUnpublish, setShouldUnpublish] = useState(false);
    const { path } = usePublishedInfo();
    const dispatch = useEditorDispatch();

    const { mutate: archiveMutation, isPending: isLoading } =
      trpc.element.archive.useMutation({
        onSuccess: () => {
          modal.closeModal({ type: "archiveElementModal" });
          successToast(
            `${capitalizeFirstLetter(element.type)} archived successfully`,
            `Your ${capitalizeFirstLetter(element.type)} has been archived successfully`,
          );
          dispatch(archiveElementAction(element.id));
        },
      });

    const onSubmit = React.useCallback(() => {
      void archiveMutation({
        elementId: element.id,
        shouldUnpublish,
      });
    }, [archiveMutation, element.id, shouldUnpublish]);

    const handleCheckboxChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setShouldUnpublish(e.target.checked);
      },
      [],
    );

    return (
      <Modal
        isOpen={true}
        onRequestClose={() => modal.closeModal({ type: "archiveElementModal" })}
        closesOnOverlayClick={true}
        className="w-[490px]"
        onEnterKeyPress={onSubmit}
      >
        <div className="no-scrollbar max-h-[85vh] overflow-y-auto">
          <div className="flex flex-col gap-2">
            <p className="text-default text-lg font-medium">
              This {element.type} is Published
            </p>
            <p className="text-muted text-sm">
              You cannot archive published {element.type}
            </p>
            <div>
              <div className="border border-muted-foreground rounded-md px-4 py-3 flex flex-col gap-2">
                <div className="flex items-start gap-2">
                  <BsFillCircleFill className="text-green-400 mt-2" size={8} />
                  <div className="flex flex-col gap-1">
                    {element.name}
                    <p className="text-xs text-subtle">{path}</p>
                  </div>
                </div>
              </div>

              <label className="flex flex-col gap-2 mt-4 cursor-pointer">
                <div className="flex gap-2 items-start">
                  <input
                    type="checkbox"
                    checked={shouldUnpublish}
                    onChange={handleCheckboxChange}
                    className="appearance-none mt-1 w-4 h-4 border border-muted rounded-full checked:bg-primary checked:border-primary relative"
                  />
                  <div>
                    <span>I agree to unpublish this page</span>
                    <p className="text-muted text-sm font-light">
                      Required to archive
                    </p>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className="mt-8 flex flex-row justify-end gap-x-2">
          <Button
            size="lg"
            variant="secondary"
            type="button"
            className="w-24"
            onClick={() => modal.closeModal({ type: "archiveElementModal" })}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            variant="primary"
            type="submit"
            className="w-24"
            isLoading={isLoading}
            data-testid="archive-submit-button"
            disabled={!shouldUnpublish}
            onClick={onSubmit}
          >
            Archive
          </Button>
        </div>
      </Modal>
    );
  });
