import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { CustomPropDefinition } from "schemas/component";

import * as React from "react";

import { getCustomComponentPropTypeEditorData } from "@components/editor/customComponentProps";
import { Group } from "@editor/components/common/designSystem/Group";
import { useApplyComponentAction } from "@editor/hooks/useApplyComponentAction";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectAllTextComponentNames,
  selectComponentDataMapping,
  selectDraftComponent,
  selectDraftComponentContext,
  selectDraftComponentId,
  selectDraftComponentType,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getExtraActionsBasedOnComponentType,
  getSelectableOptionValues,
} from "@editorModifiers/utils";

import Button from "@replo/design-system/components/button";
import { BsInfoCircle } from "react-icons/bs";
import { getCustomPropDefinitions } from "replo-runtime/shared/utils/component";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

const CustomPropModifier: React.FC = () => {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const context = useEditorSelector(selectDraftComponentContext);
  const allTextComponents = useEditorSelector(selectAllTextComponentNames);

  const getAttribute = useGetAttribute();
  const applyComponentAction = useApplyComponentAction();
  const { activeCurrency, activeLanguage, moneyFormat } =
    useEditorSelector(selectLocaleData);
  const { products } = useStoreProductsFromDraftElement();
  const { user } = useCurrentUser();
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;
  if (!draftComponent) {
    return null;
  }

  const renderCustomProp = (customPropDefinition: CustomPropDefinition) => {
    const editorData = getCustomComponentPropTypeEditorData(
      customPropDefinition.type,
    );
    const { value } = getAttribute(
      draftComponent,
      `props.${customPropDefinition.id}`,
      {
        defaultValue: customPropDefinition.defaultValue,
      },
    );

    const onChange = (value: any) => {
      const actions: UseApplyComponentActionType[] = [
        {
          componentId: draftComponent.id,
          type: "setProps",
          value: { [customPropDefinition.id]: value },
        },
      ];

      const extraActionsBasedOnComponentType =
        getExtraActionsBasedOnComponentType(
          value,
          draftComponent,
          customPropDefinition,
        );

      applyComponentAction({
        type: "applyCompositeAction",
        value: [...actions, ...extraActionsBasedOnComponentType],
      });
    };

    const onDelete = () => {
      applyComponentAction({
        type: "deleteProps",
        propName: customPropDefinition.id,
      });
    };

    if (!editorData?.shouldRender(draftComponent)) {
      return null;
    }

    let isEnabled = true;
    if (
      customPropDefinition.isEnabled &&
      !customPropDefinition.isEnabled({ component: draftComponent })
    ) {
      isEnabled = false;
    }

    let shouldDisplay = true;
    if (
      customPropDefinition.shouldDisplay &&
      !customPropDefinition.shouldDisplay({
        component: draftComponent,
        userIsSuperuser: user?.isSuperuser ?? false,
      })
    ) {
      shouldDisplay = false;
    }

    const options = getSelectableOptionValues({
      customPropDefinition,
      draftComponent,
      context,
      productResolutionDependencies: {
        products,
        currencyCode: activeCurrency,
        language: activeLanguage,
        moneyFormat,
        templateProduct,
        isEditor: true,
        isShopifyProductsLoading: false,
      },
      allTextComponents,
    });

    const contentToRender = editorData?.render({
      component: draftComponent,
      products,
      value,
      customPropId: customPropDefinition.id,
      description: customPropDefinition.description ?? "",
      onChange,
      onDelete,
      options,
      attributeName: customPropDefinition.id,
      isEnabled,
      disabledDescription: customPropDefinition.disabledDescription,
      definition: customPropDefinition,
      context,
      defaultValue: customPropDefinition.defaultValue,
      disableDynamicData: customPropDefinition.disableDynamicData,
      templateProduct,
      placeholder: customPropDefinition.placeholder,
    });

    if (!shouldDisplay) {
      return null;
    }

    if (editorData.hasCustomWrapper) {
      return contentToRender;
    }

    return (
      <Group
        name={customPropDefinition.name}
        isDefaultOpen
        isCollapsible
        key={customPropDefinition.id}
      >
        <div className="w-full">{contentToRender}</div>
      </Group>
    );
  };

  const customProps = getCustomPropDefinitions(draftComponent);

  return (
    <div className="flex flex-col gap-4">
      <ComponentInformation />
      {customProps
        .filter((definition) => definition.type !== "component")
        .map((customPropDefinition) => renderCustomProp(customPropDefinition))}
    </div>
  );
};

const ComponentInformation: React.FC = () => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftComponentType = useEditorSelector(selectDraftComponentType);
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const setDraftElement = useSetDraftElement();

  if (!draftComponentType || !draftComponentId) {
    return null;
  }
  const componentData = componentDataMapping[draftComponentId];

  return exhaustiveSwitch({ type: draftComponentType })({
    text: null,
    container: null,
    symbolRef: null,
    button: null,
    spacer: null,
    circle: null,
    icon: null,
    modal: null,
    image: null,
    accordionBlock: null,
    subscribeAndSave: null,
    collapsible: null,
    slidingCarousel: null,
    player: null,
    player__playIcon: null,
    player__muteIcon: null,
    player__fullScreenIcon: null,
    collectionSelect: null,
    product: null,
    productCollection: null,
    quantitySelector: null,
    dropdown: null,
    variantSelect: null,
    optionSelect: null,
    variantSelectDropdown: null,
    optionSelectDropdown: null,
    sellingPlanSelect: null,
    sellingPlanSelectDropdown: null,
    collection: null,
    collectionV2: null,
    googleMapsEmbed: null,
    klaviyoEmbed: null,
    temporaryCart: null,
    temporaryCartItems: null,
    vimeoEmbed: null,
    vimeoEmbedV2: null,
    rebuyWidget: null,
    buyWithPrimeButton: null,
    youtubeEmbed: null,
    youtubeEmbedV2: null,
    carouselV2: null,
    carouselV2__panels: null,
    carouselV2__indicator: null,
    carouselV3: null,
    carouselV3Slides: null,
    carouselV3Control: null,
    carouselV3Indicators: null,
    carouselPanelsCount: null,
    shopifySection: null,
    shopifyAppBlocks: null,
    shopifyRawLiquid: null,
    collapsibleV2: null,
    collapsibleV2Header: null,
    collapsibleV2Content: null,
    tabsBlock: null,
    tabs__list: null,
    tabs__panelsContent: null,
    tabs__onePanelContent: null,
    tabsV2__block: null,
    tabsV2__list: null,
    tabsV2__panelsContent: null,
    marquee: null,
    rawHtmlContent: null,
    starRating: null,
    tikTokEmbed: null,
    rechargeSubscriptionWidget: null,
    staySubscriptionWidget: null,
    okendoReviewsWidget: null,
    okendoProductRatingSummary: null,
    junipProductRating: null,
    junipReviews: null,
    yotpoProductRating: null,
    yotpoReviews: null,
    looxProductRating: null,
    looxReviews: null,
    reviewsIoProductRating: null,
    reviewsIoReviews: null,
    h1: null,
    h2: null,
    h3: null,
    spinner: null,
    dynamicCheckoutButtons: null,
    paymentTerms: null,
    countdownTimer: null,
    judgeProductRatingWidget: null,
    judgeProductReviewsWidget: null,
    feraProductRatingWidget: null,
    feraProductReviewsWidget: null,
    feraStoreReviewsWidget: null,
    feraMediaGalleryWidget: null,
    shopifyProductReviewsWidget: null,
    shopifyProductRatingWidget: null,
    stampedProductReviewsWidget: null,
    stampedProductRatingWidget: null,
    knoCommerceWidget: null,
    infiniteOptionsWidget: null,
    kachingBundles: null,
    postscriptSignupForm: null,
    toggleContainer: null,
    toggleIndicator: null,
    tooltip: null,
    tooltipContent: () => {
      return (
        <div className="flex gap-2 bg-gray-50 rounded-lg p-2 text-slate-600 text-xs">
          <BsInfoCircle className="shrink-0 mt-0.5" />
          <div>
            Edit tooltip configurations on the parent component.
            <div className="text-blue-600 underline">
              <Button
                variant="inherit"
                size="sm"
                onClick={() => {
                  if (componentData?.parentId) {
                    setDraftElement({ componentIds: [componentData.parentId] });
                  }
                }}
              >
                <span>Go To Parent</span>
              </Button>
            </div>
          </div>
        </div>
      );
    },
    beforeAfterSlider: null,
    beforeAfterSliderThumb: null,
    beforeAfterSliderBeforeContent: null,
    beforeAfterSliderAfterContent: null,
    selectionList: null,
  });
};

export default CustomPropModifier;
