import type { Component } from "replo-runtime";

import isEqual from "lodash-es/isEqual";

function checkForMixedValues(
  draftComponents: Component[],
  getValue: (component: Component) => any,
): { firstValue: any; hasMixedValues: boolean } {
  const values = draftComponents.map((draftComponent) =>
    getValue(draftComponent),
  );

  const firstValue = values[0];
  const hasMixedValues = values.some((value) => !isEqual(value, firstValue));

  return { firstValue, hasMixedValues };
}

export default checkForMixedValues;
