import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import { elementTypeToEditorData } from "@editor/components/editor/element";
import { useModal } from "@editor/hooks/useModal";
import { docs } from "@editor/utils/docs";
import { generateMarketplacePathnameAndCategoryId } from "@editor/utils/router";
import { getEmptyTemplate } from "@editor/utils/template";
import Blank from "@svg/blank";
import BlankFilled from "@svg/blank-filled";
import BrowseTemplateLibrary from "@svg/browse-template-library";
import BrowseTemplateLibraryFilled from "@svg/browse-template-library-filled";
import ReploAIPage from "@svg/replo-ai-page";
import ReploAIPageFilled from "@svg/replo-ai-page-filled";

import Button from "@replo/design-system/components/button";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { BsArrowUpRight } from "react-icons/bs";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isNotNullish } from "replo-utils/lib/misc";

type HoveredElements =
  | "createBlankElement"
  | "browseTempates"
  | "generateWithReploAI";

type QuickStartModalProps = {
  id: string;
  title: string;
  description: string;
  buttonText: string;
  secondaryButtonText: React.ReactNode;
  image: {
    preview: SvgComponent;
    selectedPreview: SvgComponent;
  };
  colorsClassNames: {
    background?: string;
    text?: string;
    button?: string | undefined;
  };
  onClick: () => void;
  dataTestId?: string;
};

type SvgComponent =
  | React.FC<React.SVGProps<SVGSVGElement>>
  | React.FC<React.ComponentPropsWithoutRef<"svg">>
  | React.FC<React.ComponentPropsWithRef<"svg">>;

const DEFAULT_ELEMENT_TYPE = "page";

export function QuickStartModal() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialName: string | null = searchParams.get("initialName");
  const navigate = useNavigate();
  const isNewProject = Boolean(searchParams.get("referer"));
  const params = useParams();

  const modal = useModal();

  function navigateToParentRoute() {
    return navigate("..", {
      relative: "path",
    });
  }

  const elementTypeParam = searchParams.get("type") as ReploElementType | null;
  let elementType: ReploElementType;
  if (!elementTypeParam) {
    elementType = DEFAULT_ELEMENT_TYPE;
    searchParams.set("type", elementType);
    setSearchParams(searchParams);
  } else {
    elementType = elementTypeParam;
  }

  const elementTypeName =
    elementTypeToEditorData[elementType].singularDisplayName;

  const quickStartOptions: QuickStartModalProps[] = [
    {
      id: "createBlankElement",
      title: `Create Blank ${elementTypeName}`,
      description:
        "Start from a blank canvas and create your next masterpiece in Replo",
      buttonText: `Create Blank ${elementTypeName}`,
      secondaryButtonText: null,
      image: {
        preview: Blank,
        selectedPreview: BlankFilled,
      },
      colorsClassNames: {
        background: "bg-blue-50",
        text: "text-blue-700",
        button: undefined,
      },
      onClick: () => {
        navigateToParentRoute();
        modal.openModal({
          type: "createElementModal",
          props: {
            initialElementType: elementType,
            initialTemplate: getEmptyTemplate(elementType),
            initialName: initialName ?? undefined,
          },
        });
      },
      dataTestId: "blank-template",
    },
    {
      id: "browseTempates",
      title: "Browse Templates",
      description:
        "Browse our marketplace of templates from our partners, community, and Replo UI design system",
      buttonText: "Start From a Template",
      secondaryButtonText: null,
      image: {
        preview: BrowseTemplateLibrary,
        selectedPreview: BrowseTemplateLibraryFilled,
      },
      colorsClassNames: {
        background: "bg-blue-50",
        text: "text-blue-700",
        button: undefined,
      },
      onClick: () => {
        const { generatedPath, categoryId } =
          generateMarketplacePathnameAndCategoryId({
            elementType,
            projectId: params.projectId,
            elementId: params.elementId,
          });
        navigate(generatedPath, {
          state: {
            elementType,
            initialName,
            marketplaceModalRequestType: "create",
            // NOTE (Fran 2024-10-16): When we create a new element using a template, we need to pass
            // the pre-selected filters so the user can find a template that matches the element type.
            // This is only needed if the user creates a new Blog Post or Product Page. For the
            // other element types, we can have no selected filters.
            selectedFilters: {
              category: categoryId ? [categoryId] : [],
              badge: [],
              industry: [],
            },
          },
        });
      },
      dataTestId: "browse-template",
    },
  ];
  if (elementType === "page") {
    quickStartOptions.push({
      id: "generateWithReploAI",
      title: "Generate With Replo AI",
      description:
        "Tell us what you need and watch Replo AI generate an amazing landing page in front of your eyes",
      buttonText: "Generate With Replo AI",
      secondaryButtonText: (
        <div className="w-full text-center bg-gradient-to-r from-orange-500 to-yellow-500 text-transparent bg-clip-text">
          Learn More
        </div>
      ),
      image: {
        preview: ReploAIPage,
        selectedPreview: ReploAIPageFilled,
      },
      colorsClassNames: {
        background: "bg-gradient-to-r from-orange-50 to-yellow-50",
        button:
          "bg-gradient-to-r from-orange-500 to-yellow-500 hover:from-orange-400 hover:to-yellow-400",
      },
      onClick: () => {
        navigateToParentRoute();
        modal.openModal({
          type: "aiPageModal",
          props: {
            initialName: initialName ?? undefined,
          },
        });
      },
    });
  }

  return (
    <Modal isOpen onRequestClose={navigateToParentRoute} className="w-auto p-0">
      <ModalLayout
        height="auto"
        width="70vw"
        minWidth="900px"
        wrapperClassnames="rounded bg-stone-50"
        layoutClassnames="p-0"
        mainContentClassnames="m-0"
        mainContent={() => {
          return (
            <div
              className="flex h-full flex-col justify-between p-9"
              data-testid="template-modal"
            >
              <p className="bg-gradient-to-r from-blue-600 via-blue-400 to-teal-300 bg-clip-text font-medium text-transparent sm:text-xl">
                {isNewProject
                  ? "Project successfully created! Choose an option below to create your first page."
                  : "Let’s Build! Choose How To Get Started"}
              </p>
              {isNewProject && (
                <p className="sm:text-sm font-light pt-2">
                  For first timers, we recommend starting with one of our
                  pre-built templates to explore what Replo can do for you!
                </p>
              )}

              <hr className="sm:my-4" />
              <div className="flex flex-row">
                {quickStartOptions.map((option) => {
                  return <QuickStartOption key={option.id} option={option} />;
                })}
              </div>
              <hr className="sm:my-4" />
              <div className="flex items-center justify-between">
                <div>
                  <p className="bg-gradient-to-r from-blue-600 via-blue-400 to-teal-300 bg-clip-text font-semibold text-transparent sm:text-base">
                    Need Expert Help?
                  </p>
                  <p className="w-2/3 pt-1 text-slate-600 sm:text-sm">
                    Need professional help with your Replo project? Hire an
                    independent Replo Certified Agency, Expert, or Builder for
                    your next project.
                  </p>
                </div>
                <Button
                  target="_blank"
                  variant="tertiary"
                  to="https://www.replo.app/experts"
                  size="lg"
                  className="border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white sm:h-6 sm:px-3 sm:py-4"
                >
                  <div className="flex items-center gap-2 sm:px-2 sm:text-sm">
                    Find an Expert <BsArrowUpRight />
                  </div>
                </Button>
              </div>
            </div>
          );
        }}
      />
    </Modal>
  );
}

const QuickStartOption: React.FC<{
  option: QuickStartModalProps;
}> = ({ option }) => {
  const [hover, setHover] = React.useState<HoveredElements | null>(null);
  const isHovered = hover === option.id;
  const ImageComponentPreview = option.image.preview;
  const ImageComponentSelectedPreview = option.image.selectedPreview;
  const descriptionId = `${option.id}-description${React.useId()}`;
  return (
    <div
      className={classNames("flex flex-1 px-2", {
        "border-l border-slate-200 pr-0": option.id === "generateWithReploAI",
        "pl-0": option.id === "createBlankElement",
      })}
    >
      <div
        className={twMerge(
          "bg-opacity-50 hover:bg-opacity-100 h-full flex flex-1 flex-col",
          option.colorsClassNames.background,
        )}
        onMouseEnter={() => setHover(option.id as HoveredElements)}
        onMouseLeave={() => setHover(null)}
      >
        <div
          className="flex flex-1 basis-full cursor-pointer flex-col justify-between gap-4 rounded p-6 select-none text-start"
          // NOTE (Chance 2024-02-08): onClick is fine here without a button
          // role because the same interaction is triggered on a focusable
          // button below. No reason to add another interactive element to the
          // accessibility tree.
          onClick={option.onClick}
        >
          <div className="mb-2">
            <div>
              {isHovered ? (
                <ImageComponentSelectedPreview />
              ) : (
                <ImageComponentPreview />
              )}
            </div>
            <p
              className={twMerge(
                "pt-4 font-semibold sm:text-base",
                option.colorsClassNames.text,
              )}
            >
              {option.title}
            </p>
            <p className="pt-3 text-gray-900 sm:text-sm" id={descriptionId}>
              {option.description}
            </p>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-end gap-4 rounded p-6 pt-0">
          <div className="flex flex-col gap-2">
            {isNotNullish(option.secondaryButtonText) && (
              <Button
                variant="primary"
                className="border border-orange-500 bg-orange-50 hover:bg-orange-50 text-sm font-medium bg-gradient-to-r from-orange-500 to-yellow-500 bg-clip-text text-transparent"
                size="lg"
                to={docs.ai}
                target="_blank"
                rel="noreferrer"
                isFullWidth
              >
                {option.secondaryButtonText}
              </Button>
            )}
            <Button
              variant="primary"
              className={twMerge(
                `${option.colorsClassNames.button} text-sm font-medium`,
              )}
              size="lg"
              onClick={option.onClick}
              isFullWidth
              data-testid={option.dataTestId}
              aria-describedby={descriptionId}
            >
              {option.buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickStartModal;
