import * as React from "react";

import { MAX_STYLE_PROPERTY_VALUE_LENGTH } from "@editor/components/editor/constants";
import { useErrorToast } from "@editor/hooks/useErrorToast";

import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { useComposedEventHandlers } from "replo-utils/react/use-composed-event-handlers";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";

export type OverridableInputProps = {
  /**
   * NOTE (Chance 2023-12-08): The change handler that is fired immediately when
   * the input changes. This function is not debounced, but it can be used to
   * handle things on the event itself before the debounced `onValueChange`
   * handler is triggered.
   */
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  /**
   * NOTE (Chance 2023-12-08): This is the debounced function that fires in
   * response to the value changing at a given interval.
   */
  onValueChange: ((value: string) => void) | undefined;
  /**
   * The debounce timeout for `onValueChange`
   * @default 300
   */
  timeout?: number;
  value: string;
};

/**
 * A utility hook for getting handling inputs where there is an internal value,
 * but the provided value is controlled by a parent component and may be updated
 * by a change handler that is debounced given a timeout value. When the
 * provided value changes it will override the internal value.
 */
export function useOverridableInput({
  onChange,
  onValueChange,
  timeout = 300,
  value,
  ...props
}: OverridableInputProps) {
  const [inputValue, setInputValue] = useOverridableState(
    value,
    useDebouncedCallback((value: string) => onValueChange?.(value), timeout),
  );
  const errorToast = useErrorToast();
  const handleChange = useComposedEventHandlers(
    onChange,
    React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value != null && value.length > MAX_STYLE_PROPERTY_VALUE_LENGTH) {
          // NOTE (Fran 2024-04-22): Add this check to avoid the editor freeze because of a huge string.
          // Example: a huge svg source.
          errorToast(
            "Invalid Input Value",
            `Please input a string with less than ${MAX_STYLE_PROPERTY_VALUE_LENGTH} characters.`,
            {
              eventName: "error.input.string.exceeds_max_length",
              eventProperties: {
                value: value,
                stringLength: value.length,
                maxLength: MAX_STYLE_PROPERTY_VALUE_LENGTH,
              },
            },
          );
        } else {
          setInputValue(event.target.value);
        }
      },
      [setInputValue, errorToast],
    ),
  );

  return {
    value: inputValue,
    onChange: handleChange,
    ...props,
  };
}
