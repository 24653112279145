import type { ReploEditableElementWithId } from "@editor/utils/element";
import type { SetStateAction } from "react";
import type { ReploPartialElement } from "schemas/generated/element";

import * as React from "react";

import { updateAndSaveElement } from "@editor/actions/core-actions";
import { useElementValidation } from "@editor/hooks/element";
import { analytics } from "@editor/infra/analytics";
import {
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectElementIsLoading,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { getFormattedElementName } from "@editor/utils/element";

import { ReploError } from "schemas/errors";

class NoElementResolvableError extends ReploError {
  constructor(message: string, additionalData: Record<string, unknown>) {
    super({ message, additionalData });
  }
}

/**
 * This hook handles update, validation and the state of a Replo element.
 */
export function useEditableElement({
  element: initialElement,
}: {
  element: ReploEditableElementWithId | undefined;
}) {
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const originalElement = initialElement ?? draftElement;
  if (!originalElement) {
    throw new NoElementResolvableError(
      "No element resolvable - this should never happen",
      {
        initialElementId: initialElement?.id,
      },
    );
  }
  const [element, setElement] =
    React.useState<ReploEditableElementWithId>(originalElement);

  const validateElement = useElementValidation(element.type);
  const initialIsHomepage = React.useRef(initialElement?.isHomepage ?? false);
  const dispatch = useEditorDispatch();
  const isElementLoading = useEditorSelector(selectElementIsLoading);

  const onChangeElement = React.useCallback(
    (value: SetStateAction<ReploPartialElement>) => {
      setElement((prev) => ({
        ...prev,
        ...(typeof value === "function" ? value(prev) : value),
      }));
    },
    [],
  );

  const onUpdateElement = React.useCallback(
    function onUpdateElementFn(element: ReploEditableElementWithId) {
      const isSettingHomepage =
        element.isHomepage && !initialIsHomepage.current;
      analytics.logEvent("element.update", {
        type: element.type,
        elementId: element.id,
      });

      return dispatch(
        updateAndSaveElement(
          element.id,
          {
            ...element,
            name: getFormattedElementName(element),
          },
          isSettingHomepage,
        ),
      );
    },
    [dispatch],
  );

  return {
    element,
    onChangeElement,
    updateElement: onUpdateElement,
    validateElement,
    isUpdatingElement: isElementLoading,
  };
}
