import type { InternalQuery } from "@/features/analytics/query";
import type { ChartConfig } from "@replo/design-system/components/shadcn/core/chart";
import type {
  AnalyticsReadQuery,
  AnalyticsReadQueryFilters,
  MetricName,
} from "schemas/generated/analyticsRead";

import { convertDaysToMs } from "replo-utils/lib/datetime";
import { ConditionOperatorEnum, PageTypeEnum } from "schemas/analyticsRead";

export const RESULTS_PER_PAGE = 25;

export const DEFAULT_SORT_METRIC: MetricName = "unique_sessions";
export const DEFAULT_QUERY_RESULT_LIMIT = RESULTS_PER_PAGE;
export const DEFAULT_QUERY_RESULT_OFFSET = 0;
export const DEFAULT_ORDER: AnalyticsReadQuery["order"] = "DESC";

export const DEFAULT_URL_HOST = "ALL_URLS";

export const DEFAULT_METRICS: AnalyticsReadQuery["metrics"] = [
  "conversions",
  "views",
  "conversion_rates",
  "unique_sessions",
  "revenue",
  "revenue_per_session",
  "average_order_value",
];

export const DEFAULT_FILTERS: AnalyticsReadQueryFilters = {
  urlPath: [
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/checkouts/",
    },
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/orders/",
    },
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/tools/recurring/portal/",
    },
    {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN,
      value: "/account/",
    },
  ],
  pageType: PageTypeEnum.ENTRY_PAGES,
  urlParams: {},
};

export const DEFAULT_QUERY: InternalQuery = {
  order: DEFAULT_ORDER,
  sortMetric: DEFAULT_SORT_METRIC,
  metrics: DEFAULT_METRICS,
  offset: DEFAULT_QUERY_RESULT_OFFSET,
  limit: DEFAULT_QUERY_RESULT_LIMIT,
};

export const METRICS_REQUIRING_DECIMALS = [
  "revenue",
  "revenue_per_session",
  "conversion_rates",
  "average_order_value",
];
export const METRICS_REQUIRING_PERCENTAGE = ["conversion_rates"];
export const METRICS_REQUIRING_CURRENCY = [
  "revenue",
  "revenue_per_session",
  "average_order_value",
];

export const DEFAULT_CURRENCY = "$";

export const PAGE_NAME_KEY = "page_name";

export const TABLE_METRICS: {
  label: string;
  key: MetricName;
}[] = [
  { label: "Sessions", key: "unique_sessions" },
  { label: "Conversions", key: "conversions" },
  { label: "Revenue", key: "revenue" },
  { label: "Conversion Rate", key: "conversion_rates" },
  { label: "AOV", key: "average_order_value" },
  { label: "Revenue per Session", key: "revenue_per_session" },
];

export const METRIC_SORT_TOOLTIP_TEXT: Record<
  AnalyticsReadQuery["sortMetric"],
  string
> = {
  conversion_rates: "Sort by Conversion Rate",
  average_order_value: "Sort by AOV",
  revenue_per_session: "Sort by Revenue/Session",
  unique_sessions: "Sort by Unique Sessions",
  conversions: "Sort by Conversions",
  revenue: "Sort by Revenue",
  views: "Sort by Views",
};

export const DETAILS_PAGE_CARD_METRICS: {
  label: string;
  key: MetricName;
}[] = [
  { label: "Conversion Rate", key: "conversion_rates" },
  { label: "Revenue/Session", key: "revenue_per_session" },
  { label: "Average Order Value", key: "average_order_value" },
  { label: "Total Sessions", key: "unique_sessions" },
  { label: "Total Conversions", key: "conversions" },
  { label: "Total Revenue", key: "revenue" },
];

export const QUERY_INTERVAL_OPTIONS: {
  label: string;
  value: AnalyticsReadQuery["ranges"]["mainRange"]["interval"];
}[] = [
  { label: "1 Day", value: convertDaysToMs(1) },
  { label: "7 Days", value: convertDaysToMs(7) },
  { label: "14 Days", value: convertDaysToMs(14) },
  { label: "30 Days", value: convertDaysToMs(30) },
  { label: "60 Days", value: convertDaysToMs(60) },
  { label: "90 Days", value: convertDaysToMs(90) },
  { label: "365 Days", value: convertDaysToMs(365) },
];

export const DEFAULT_ANALYTICS_CHART_CONFIG: ChartConfig = {
  lines: {
    previousPeriod: {
      label: "Previous Period",
      color: "blue-300",
      dataKey: "previousPeriod",
      type: "linear",
      strokeWidth: 2,
    },
    thisPeriod: {
      label: "This Period",
      color: "blue-600",
      dashed: {
        numberOfTicks: 1,
      },
      dataKey: "thisPeriod",
      type: "linear",
      strokeWidth: 2,
    },
  },
  xAxis: {
    dataKey: "x",
  },
  tooltipKeyFormatter: (value: string) => {
    return `${value.slice(0, 16).replace("T", " ")}`;
  },
};

export const METRIC_DEFINITIONS_URL =
  "https://replo.notion.site/Replo-Analytics-Metric-Definitions-For-Replo-Customers-10a8170f84a68032964cf586a7c04455";
