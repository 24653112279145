import type { ElementRequestType } from "@editor/utils/element";

import * as React from "react";

import ElementNameEditor from "@components/editor/elementEditors/ElementNameEditor";
import Switch from "@editor/components/common/designSystem/Switch";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useModal } from "@editor/hooks/useModal";
import { selectSections } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { removeFolderNameFromElementName } from "replo-utils/element";
import { slugify } from "replo-utils/lib/string";

import { ElementEditorDescription } from "./ElementEditorDescription";
import { ElementEditorHeading } from "./ElementEditorHeading";

export const SectionElementEditor: React.FC<{
  requestType: ElementRequestType;
  initialName?: string;
}> = ({ requestType, initialName }) => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const modal = useModal();
  const sections = useEditorSelector(selectSections);
  const currentSection = sections.find(
    (section) => section.name === element.name,
  );
  const handleNameChange = (name: string) => {
    // NOTE (Matt 08-14-2023): In this context, the 'shopifyPagePath' is the Shopify Section File name.
    // This is used in the modal to validate whether or not a section already exists in this theme with
    // the same name.
    const shopifyPagePath =
      requestType !== "update" || !element?.shopifyPagePath
        ? slugify(removeFolderNameFromElementName(name))
        : element.shopifyPagePath;
    onChangeElement({
      ...element,
      name,
      shopifyPagePath,
    });
  };

  return (
    <>
      <div className="my-4 mx-2 flex flex-col gap-y-2">
        <ElementNameEditor
          type="shopifySection"
          initialName={initialName ?? element.name}
          inputName="section-name"
          onChange={handleNameChange}
        />
        {requestType === "update" && (
          <div className="mt-4 flex flex-col gap-y-2">
            <div className="flex flex-row items-center justify-between text-slate-400">
              <div>
                <ElementEditorHeading>Is Published</ElementEditorHeading>
                <ElementEditorDescription>
                  If the section is published, it will be available to use in
                  the Shopify Theme Customizer.
                </ElementEditorDescription>
              </div>
              <Switch
                isOn={element.isPublished}
                onChange={(isPublished) => {
                  if (!isPublished && currentSection?.isPublished) {
                    modal.openModal({
                      type: "deleteElementConfirmationModal",
                      props: {
                        type: "unpublish",
                        element: currentSection!,
                        onDelete: () => {
                          onChangeElement({ ...element, isPublished });
                          modal.closeModal({
                            type: "deleteElementConfirmationModal",
                          });
                        },
                      },
                    });
                  } else {
                    onChangeElement({ ...element, isPublished });
                  }
                }}
                backgroundOnColor="bg-blue-600"
              />
            </div>
          </div>
        )}
        <div className="mt-4 flex flex-col gap-y-2">
          <div className="flex flex-row items-center justify-between text-slate-400">
            <div>
              <ElementEditorHeading>
                Editable in Shopify Theme Editor
              </ElementEditorHeading>
              <ElementEditorDescription>
                If this setting is enabled, text and images in this section will
                be editable in the Shopify Theme Editor.
              </ElementEditorDescription>
            </div>
            <Switch
              isOn={element.useSectionSettings}
              onChange={(useSectionSettings) => {
                onChangeElement({ ...element, useSectionSettings });
              }}
              backgroundOnColor="bg-blue-600"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionElementEditor;
