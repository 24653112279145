import type { SavedStyleAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import useGetDesignLibrarySavedStyles from "@hooks/designLibrary/useGetDesignLibrarySavedStyles";

import { getSavedStyleValue } from "replo-runtime/shared/savedStyles";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";

/**
 * Returns the saved style value for a dynamic design library value. If the saved style is deleted
 * we want to show the static value to allow the user change it.
 */
function useGetDeletedSavedStyleValueIfNeeded<T extends SavedStyleAttributes>(
  dynamicDesignLibraryValue: string | null,
  // NOTE (Fran 2024-12-06): There is a case where we want to show the saved style value even if it's not deleted.
  // This case is the Text Color which can be overridden by the user.
  includeNotDeleted: boolean = false,
): T | null {
  const { savedStylesIncludingDeletedOnes } = useGetDesignLibrarySavedStyles();

  if (
    !dynamicDesignLibraryValue ||
    !isDynamicDesignLibraryValue(dynamicDesignLibraryValue)
  ) {
    return null;
  }

  const savedStyle = getSavedStyleValue(
    savedStylesIncludingDeletedOnes,
    dynamicDesignLibraryValue,
  );

  if (!includeNotDeleted && !savedStyle?.deletedAt) {
    return null;
  }

  return (
    (getSavedStyleValue(
      savedStylesIncludingDeletedOnes,
      dynamicDesignLibraryValue,
    )?.attributes as T) ?? null
  );
}

export default useGetDeletedSavedStyleValueIfNeeded;
