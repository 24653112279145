import type {
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "@/features/analytics/time";
import type { DateRange } from "react-day-picker";

import * as React from "react";

import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { Input } from "@editor/components/common/designSystem/Input";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import AppliedFiltersTags from "@/features/analytics/moreFilters/AppliedFiltersTags";
import BarPopover from "@/features/analytics/moreFilters/BarPopover";
import TriggerBarPopoverCombobox from "@/features/analytics/moreFilters/TriggerBarPopoverCombobox";
import AnalyticsHostPagesCombobox from "@/features/analytics/selects/AnalyticsHostPagesCombobox";
import { AnalyticsTimeRangeCombobox } from "@/features/analytics/selects/AnalyticsTimeRangeCombobox";
import AnalyticsUrlHostCombobox from "@/features/analytics/selects/AnalyticsUrlHostCombobox";
import {
  COMPARE_RANGE_TIME_FRAME_OPTIONS,
  MAIN_RELATIVE_TIME_FRAMES,
} from "@/features/analytics/time";
import useCurrentAnalyticsTab from "@/features/analytics/useCurrentAnalyticsTab";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsSearch } from "react-icons/bs";

type AnalyticsFiltersProps = {
  resetPagination?: () => void;
  search?: {
    value: string;
    onChange: (value: string) => void;
  };
};

const AnalyticsFilters: React.FC<AnalyticsFiltersProps> = ({
  resetPagination,
  search,
}) => {
  const { dispatchAnalyticsQuery } = useAnalyticsQueryContext();

  const handleUrlHostChange = (value: string) => {
    dispatchAnalyticsQuery({
      type: "urlHosts",
      payload: [value],
    });
  };

  const analyticsTab = useCurrentAnalyticsTab();

  const [isMainRangePickerOpen, setIsMainRangePickerOpen] =
    React.useState<boolean>(false);
  const [isCompareRangePickerOpen, setIsCompareRangePickerOpen] =
    React.useState<boolean>(false);

  /**
   * This function only ever updates the compareAt range.
   * Either the user selects to compare with a predefined period
   * (e.g. previous-period), or the user selects to compare with a
   * custom date period.
   *
   * @author Max 2024-09-17
   */
  const handleCompareRangeChange = (
    selectedComparePeriodValue: ComparisonTimeFrame | DateRange,
  ) => {
    dispatchAnalyticsQuery({
      type: "updateCompareRange",
      payload: selectedComparePeriodValue,
    });

    resetPagination?.();
  };

  /**
   * Unlike handleCompareRangeChange(), this function can update
   * both ranges: it always updates the mainRange, AND if the compare period
   * is set to 'previous-period', it also updates the compareAt range (by setting
   * it to be the previous continuous range).
   *
   * @author Max 2024-09-17
   */
  const handleMainRangeChange = (
    selectedTimeFrameValue: RelativeTimeFrame | DateRange,
  ) => {
    dispatchAnalyticsQuery({
      type: "updateMainRange",
      payload: selectedTimeFrameValue,
    });

    resetPagination?.();
  };

  const searchUrlPathInputProps = useOverridableInput({
    value: search?.value ?? "",
    onValueChange: search?.onChange ?? (() => {}),
  });

  const [isMoreFiltersBarOpen, setIsMoreFiltersBarOpen] =
    React.useState<boolean>(false);
  const [selectedUrlParamOptionValue, setSelectedUrlParamOptionValue] =
    React.useState<string>("");

  const handleUrlParamOptionClick = (value: string) => {
    setSelectedUrlParamOptionValue(value);
    setIsMoreFiltersBarOpen(!isMoreFiltersBarOpen);
  };

  return (
    <div className="grid grid-cols-12">
      <div className={twMerge(search ? "col-span-9" : "col-span-12")}>
        <div className="flex flex-row gap-2 mr-2">
          <AnalyticsTimeRangeCombobox<RelativeTimeFrame | DateRange>
            options={MAIN_RELATIVE_TIME_FRAMES}
            handleRangeChange={handleMainRangeChange}
            open={isMainRangePickerOpen}
            onOpenChange={setIsMainRangePickerOpen}
            isCompare={false}
          />
          <AnalyticsTimeRangeCombobox<ComparisonTimeFrame | DateRange>
            options={COMPARE_RANGE_TIME_FRAME_OPTIONS}
            placeholderPrefix="Compare to: "
            handleRangeChange={handleCompareRangeChange}
            open={isCompareRangePickerOpen}
            onOpenChange={setIsCompareRangePickerOpen}
            isCompare={true}
          />
          <AnalyticsUrlHostCombobox onChange={handleUrlHostChange} />
          {analyticsTab === "analytics_deep_dive" && (
            <AnalyticsHostPagesCombobox />
          )}
          <TriggerBarPopoverCombobox
            handleUrlParamOptionClick={handleUrlParamOptionClick}
          />
        </div>
        <div className="mt-2 flex flex-wrap gap-2">
          <AppliedFiltersTags />
        </div>
      </div>
      {search && (
        <div className="col-span-3">
          <Input
            placeholder="Search path..."
            size="base"
            startEnhancer={<BsSearch className="text-slate-400" />}
            {...searchUrlPathInputProps}
          />
        </div>
      )}
      <div className="absolute">
        <BarPopover
          isOpen={isMoreFiltersBarOpen}
          onOpenChange={setIsMoreFiltersBarOpen}
          selectedUrlParamOptionValue={selectedUrlParamOptionValue}
        />
      </div>
    </div>
  );
};

export default AnalyticsFilters;
