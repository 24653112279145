import type {
  FlowStepConfigPropsValueOf,
  FlowStepDataValueOf,
} from "schemas/flow";

import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import ResponsiveToggle from "@editor/components/common/designSystem/ResponsiveToggle";
import {
  SimpleSkeletonLoader,
  SkeletonLoaderItem,
  SkeletonLoaderWrapper,
} from "@editor/components/common/designSystem/SkeletonLoader";
import { useGetCurrentFlow } from "@editor/components/flows/hooks/useGetCurrentFlow";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepLayout, {
  OnboardingStepForm,
} from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import { trpc } from "@editor/utils/trpc";

import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { Controller, useForm, useWatch } from "react-hook-form";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { useGetStepResultsData } from "../hooks/useGetCurrentStepResultsData";
import { CheckboxCallout } from "./components/CheckboxCallout";
import StepSubtitle from "./components/StepSubtitle";

type FormValues = {
  templateId?: string;
};

type TemplatesExtraData = {
  id: string;
  order: number;
  matchTo: string[];
};

type ExpectedTemplatePreview = {
  id: string;
  name: string;
  template: string;
  kind: string;
  desktopPreviewUrl: string;
  mobilePreviewUrl: string;
};

const transformTemplatePreviews = (
  data: {
    id: string;
    name: string;
    template: unknown;
    kind: string | null;
    desktopPreviewUrl: string;
    mobilePreviewUrl: string;
  }[],
): ExpectedTemplatePreview[] => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    template: typeof item.template === "string" ? item.template : "",
    kind: item.kind ?? "",
    desktopPreviewUrl: item.desktopPreviewUrl,
    mobilePreviewUrl: item.mobilePreviewUrl,
  }));
};

const StartFrom: React.FC = () => {
  const analytics = useLogAnalytics();
  const { currentStep, submitStep } = useReploFlowsStepContext();

  const { currentInstance } = useGetCurrentFlow();
  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"onboarding.user.do-you-want-to-start-from-template">;
  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const { queueGeneration } = useAIStreaming();

  // NOTE (Gabe 2024-11-15): Depending on whether or not the user provides a
  // url, we may still use the original industry step results.
  const oldIndustrySelected = (
    currentInstance?.stepResults["which-best-describes-your-industry"]
      ?.data as FlowStepDataValueOf<"onboarding.user.which-best-describes-your-industry">
  )?.industry;

  const brandDetailsStepResultsData =
    useGetStepResultsData<"onboarding.user.brand-details">("brand-details");

  const newIndustrySelected = brandDetailsStepResultsData
    ? brandDetailsStepResultsData.industry
    : undefined;

  const industryLoading = !oldIndustrySelected && !newIndustrySelected;
  const industrySelected =
    newIndustrySelected || oldIndustrySelected || "other";

  const hasBrandDetails = Boolean(
    brandDetailsStepResultsData?.brandVoice ||
      brandDetailsStepResultsData?.whatBusinessSells ||
      brandDetailsStepResultsData?.whoIsCustomer,
  );

  const [isApplyCopyUpdatesChecked, setIsApplyCopyUpdatesChecked] =
    React.useState(false);

  React.useEffect(() => {
    if (hasBrandDetails) {
      setIsApplyCopyUpdatesChecked(true);
    }
  }, [hasBrandDetails]);

  const templates =
    (stepProps.options.find((option) => option.value === "template")?.extraData
      ?.templates as TemplatesExtraData[]) ?? [];
  let filteredTemplatesByIndustry = templates.filter(
    (template) =>
      // NOTE (Fran 2024-07-22): We need to check if the matchTo field is an array to avoid errors with
      // retrocompatibility. This Will be remove in a few days when we know everyone is using the new
      // Onboarding flow.
      Array.isArray(template.matchTo) &&
      template.matchTo.includes(industrySelected),
  );
  // NOTE (Fran 2024-07-19): If there are no templates for the selected industry, we will show the templates
  // that are marked as "other" in the matchTo field.
  filteredTemplatesByIndustry =
    filteredTemplatesByIndustry.length === 0
      ? templates.filter(
          (template) =>
            // NOTE (Fran 2024-07-22): We need to check if the matchTo field is an array to avoid errors with
            // retrocompatibility. This Will be remove in a few days when we know everyone is using the new
            // Onboarding flow.
            Array.isArray(template.matchTo) &&
            template.matchTo.includes("other"),
        )
      : filteredTemplatesByIndustry;

  // NOTE (Sebas, 2024-02-08): We sort the templates here because we cannot use the `orderedTemplatePreviews`
  // prop to get the id from the first template. The reason of this is that it can be undefined at this point.
  const templateIds = [...filteredTemplatesByIndustry]
    .sort((a, b) => a.order - b.order)
    .map((t) => t.id);

  const { data, isPending } = trpc.componentTemplates.getPreviews.useQuery({
    templateIds: templateIds.length > 0 ? templateIds : [],
  });

  const templatePreviews = data?.componentTemplates
    ? transformTemplatePreviews(data?.componentTemplates)
    : [];

  const orderedTemplatePreviews = [...templatePreviews].sort((a, b) => {
    const aOrder =
      templates.find((template) => template.id === a.id)?.order ?? 0;
    const bOrder =
      templates.find((template) => template.id === b.id)?.order ?? 0;
    return aOrder - bOrder;
  });

  const { handleSubmit, control, setValue } = useForm<FormValues>({
    defaultValues: {
      templateId: templateIds[0] ?? "",
    },
  });
  const firstTemplateId = templateIds[0];

  React.useEffect(() => {
    if (firstTemplateId) {
      setValue("templateId", firstTemplateId);
    }
  }, [setValue, firstTemplateId]);

  const selectedTemplateValue = useWatch({ control, name: "templateId" });

  const onSubmit = ({ templateId }: FormValues) => {
    if (currentStep) {
      if (hasBrandDetails && isApplyCopyUpdatesChecked && templateId) {
        queueGeneration({
          type: "textV2",
          userPrompt: "Rewrite this to be relevant to our brand",
        });
      }

      void submitStep(
        currentStep.id,
        currentStep.type,
        {
          hasBrandDetails,
          isCopyUpdateSelected: isApplyCopyUpdatesChecked,
          templateId,
        },
        ({ instance, nextStep }) => {
          // NOTE (Fran 2024-02-22): We should save in localStorage the projectId created in the
          // step four (are you using solo or team) to redirect to the correct project
          // after the onboarding flow is completed.
          // We should do this always in the last step of the flow!
          const stepResultsData = instance.stepResults["workspace-name"]
            ?.data as FlowStepDataValueOf<"onboarding.user.workspace-name">;
          if (stepResultsData?.projectId) {
            localStorage.setItem("firstProjectId", stepResultsData.projectId);
          }
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          });
        },
      );
      const componentTemplateId = currentSelectedTemplate?.id ?? "";
      const componentTemplateName = currentSelectedTemplate?.name ?? "";

      analytics("store.componentTemplate.used", {
        componentTemplateId,
        collectionId: "",
        componentTemplateName,
        componentTemplateType: "page",
        categoryId: "",
        type: "onboarding",
      });
    }
  };

  const currentSelectedTemplate =
    orderedTemplatePreviews?.find(
      (template) => template.id === selectedTemplateValue,
    ) ?? orderedTemplatePreviews[0];

  return (
    <OnboardingStepLayout
      // NOTE (Fran 2024-03-27): We should hide the right panel content on mobile. Because the preview
      // is not responsive and it's not possible to see the whole image, also will hide the form on mobile
      rightPanelContent={
        <div className="hidden lg:flex lg:flex-col">
          <TemplatePreview
            desktopPreview={currentSelectedTemplate?.desktopPreviewUrl}
            mobilePreview={currentSelectedTemplate?.mobilePreviewUrl}
          />
        </div>
      }
    >
      <OnboardingStepForm
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <div className="flex flex-col gap-14">
          <div>
            <StepTitle>
              <div className="flex flex-row gap-2 items-center">
                Top pages for{" "}
                {industryLoading ? (
                  <div className="inline">
                    <SimpleSkeletonLoader width="100px" height="28px" />
                  </div>
                ) : (
                  <span className="capitalize">
                    {industrySelected.replace(/-/g, " ").replace(/and/g, "&")}
                  </span>
                )}
              </div>
            </StepTitle>
            <StepSubtitle>
              High converting pages based on your business
            </StepSubtitle>
          </div>

          <Controller
            name="templateId"
            control={control}
            render={({ field: { onChange, value } }) => {
              if (isPending) {
                return <StartFromTemplatePreviewSkeleton />;
              }

              return (
                <SelectableTemplates
                  value={value ?? ""}
                  onChange={onChange}
                  templates={orderedTemplatePreviews}
                />
              );
            }}
          />
          <div
            className={classNames(
              "flex flex-col gap-4",
              hasBrandDetails ? "opacity-100" : "opacity-0",
            )}
          >
            <CheckboxCallout
              title="Apply branded copy"
              description="Rewrite the text to be specific to your brand"
              isChecked={isApplyCopyUpdatesChecked}
              onCheckedChange={setIsApplyCopyUpdatesChecked}
            />
          </div>
        </div>
        <FlowActionButtons
          customLabels={{
            nextButton: "Let's Build",
            skipButton: "Start a with Blank Page",
          }}
          handleSkip={() => onSubmit({ templateId: undefined })}
          shouldDisableNextButton={!Boolean(selectedTemplateValue)}
        />
      </OnboardingStepForm>
    </OnboardingStepLayout>
  );
};

const SelectableTemplates: React.FC<{
  value: string;
  onChange: (value: string) => void;
  templates?: {
    id: string;
    name: string;
    template: string;
    kind: string;
    desktopPreviewUrl: string;
    mobilePreviewUrl: string;
    brandSlug?: string;
  }[];
}> = ({ onChange, value, templates }) => {
  return (
    <div className="grid grid-cols-3 w-full gap-3">
      {templates?.map((option) => (
        <button
          key={option.id}
          className={twMerge(
            classNames(
              "flex gap-1 col-span-1 overflow-hidden aspect-square border-2 border-gray-300 rounded flex-col p-1",
              { "border-blue-600": option.id === value },
            ),
          )}
          type="button"
          onClick={() => onChange(option.id)}
        >
          {option.brandSlug ? (
            <p className="text-xs text-default capitalize">
              {option.brandSlug.replace("-", " ")}
            </p>
          ) : null}
          <img
            src={option.desktopPreviewUrl}
            className="w-full"
            alt={`Preview for ${option.brandSlug ?? option.name} Template`}
          />
        </button>
      ))}
    </div>
  );
};

const TemplatePreview: React.FC<{
  mobilePreview?: string;
  desktopPreview?: string;
}> = ({ desktopPreview, mobilePreview }) => {
  const [selectedDevice, setSelectedDevice] = React.useState<
    "mobile" | "desktop"
  >("desktop");
  return (
    <div className="relative flex w-full h-screen overflow-hidden cursor-grab justify-center bg-gray-100 active:cursor-grabbing">
      <TransformWrapper
        limitToBounds
        maxScale={2}
        panning={{ lockAxisX: true }}
        wheel={{ disabled: true }}
      >
        <TransformComponent
          wrapperStyle={{
            height: "100%",
          }}
        >
          <div className="pt-40 px-32">
            <img
              src={desktopPreview ?? "/images/flows/template.png"}
              width={600}
              height={600}
              className={classNames(
                "h-full object-cover object-top lg:w-full shadow-lg",
                { hidden: selectedDevice === "mobile" },
              )}
              alt="Desktop preview"
            />
            <img
              src={mobilePreview ?? "/images/flows/template.png"}
              width={600}
              height={600}
              className={classNames(
                "h-full object-cover object-top lg:w-full shadow-lg",
                { hidden: selectedDevice === "desktop" },
              )}
              alt="Mobile preview"
            />
          </div>
        </TransformComponent>
      </TransformWrapper>
      <div className="z-50 absolute bottom-4 left-1/2 transform -translate-x-1/2">
        <ResponsiveToggle setValue={setSelectedDevice} value={selectedDevice} />
      </div>
    </div>
  );
};

export const StartFromTemplatePreviewSkeleton: React.FC = () => {
  return (
    <div className="grid grid-cols-3 gap-3">
      <SkeletonLoaderWrapper
        className="box-border bg-white rounded aspect-square"
        speed={2}
        backgroundColor="#e2e8f0"
        foregroundColor="#cbd5e1"
      >
        <SkeletonLoaderItem yAxis="0" width="100%" height="100%" />
      </SkeletonLoaderWrapper>
      <SkeletonLoaderWrapper
        className="box-border bg-white rounded aspect-square"
        speed={2}
        backgroundColor="#e2e8f0"
        foregroundColor="#cbd5e1"
      >
        <SkeletonLoaderItem yAxis="0" width="100%" height="100%" />
      </SkeletonLoaderWrapper>
      <SkeletonLoaderWrapper
        className="box-border bg-white rounded aspect-square"
        speed={2}
        backgroundColor="#e2e8f0"
        foregroundColor="#cbd5e1"
      >
        <SkeletonLoaderItem yAxis="0" width="100%" height="100%" />
      </SkeletonLoaderWrapper>
    </div>
  );
};

export default StartFrom;
