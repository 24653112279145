export type ReploMixedStyleValue = {
  _reploMixedStyleValue: true;
};

export const REPLO_MIXED_STYLE_VALUE = {
  _reploMixedStyleValue: true,
} satisfies ReploMixedStyleValue;

export function isMixedStyleValue(value: any): value is ReploMixedStyleValue {
  return value && typeof value === "object" && "_reploMixedStyleValue" in value;
}
