import type {
  SavedStyleAttributes,
  SavedStyleColorAttributes,
} from "schemas/generated/savedStyles";

import * as React from "react";

import useAddToDesignLibraryCache from "@editor/hooks/designLibrary/useAddtoDesignLibraryCache";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { generateNewName } from "@editor/utils/designLibrary";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import IconButton from "@replo/design-system/components/button/IconButton";
import { BsThreeDots } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";

import { Menu, MenuTrigger } from "../common/designSystem/Menu";
import { successToast } from "../common/designSystem/Toast";

const SavedStyleItemEndEnhancer: React.FC<{
  id: string;
  savedStyleAttributes: SavedStyleAttributes;
  name: string;
  type: "text" | "color";
  deleteSavedStyle: (id: string) => void;
  onSelectSavedStyle: (id?: string) => void;
}> = ({
  id,
  savedStyleAttributes,
  name,
  type,
  deleteSavedStyle,
  onSelectSavedStyle,
}) => {
  const projectId = useEditorSelector(selectProjectId);
  const logEvent = useLogAnalytics();
  const { addToDesignLibraryCache } = useAddToDesignLibraryCache();
  const { textSavedStyles, colorSavedStyles } =
    useGetDesignLibrarySavedStyles();
  const errorToast = useErrorToast();

  // TODO (Fran 2024-11-15 REPL-14578): Create a wrapper hook for this mutation to allow optimistic updates
  // every time we create a saved style.
  const { mutate: duplicateSavedStyle } =
    trpc.designLibrary.savedStyles.create.useMutation({
      onMutate: ({ id, attributes, type, name }) => {
        if (id) {
          void addToDesignLibraryCache({
            savedStyleId: id,
            attributes,
            type,
            name,
          });
        }
      },
      onSuccess: async (_, { id }) => {
        await trpcUtils.designLibrary.get.invalidate({ projectId });
        successToast(
          "Saved style duplicated",
          "The saved style selected was duplicated",
        );
        if (id) {
          onSelectSavedStyle?.(id);
        }
      },
      onError: async () => {
        await trpcUtils.designLibrary.get.invalidate({ projectId });
        errorToast(
          "Failed to duplicate saved style",
          "Please try again, or contact support@replo.app for help.",
          {
            eventName: "error.savedStyle.duplicate",
            eventProperties: {},
          },
        );
      },
    });

  if (!projectId) {
    return null;
  }

  return (
    <Menu
      align="end"
      size="xs"
      side="right"
      disableTriggerFocusOnClose
      stopPropagationOnItemClick={false}
      items={[
        {
          type: "leaf",
          id: "edit",
          title: "Edit",
          onSelect: () => onSelectSavedStyle?.(),
        },
        {
          type: "leaf",
          id: "duplicate",
          title: "Duplicate",
          onSelect: () => {
            const newName = generateNewName(
              name,
              type === "text"
                ? { savedStyles: textSavedStyles, type: "text" }
                : {
                    savedStyles: colorSavedStyles,
                    type: "color",
                    colorType: (
                      savedStyleAttributes as SavedStyleColorAttributes
                    ).colorType,
                  },
              "duplicate",
            );
            logEvent("library.style.duplicate", {
              type: type,
              tag:
                savedStyleAttributes.type === "color"
                  ? savedStyleAttributes.colorType
                  : savedStyleAttributes.htmlTag,
            });
            duplicateSavedStyle({
              id: uuidv4(),
              attributes: savedStyleAttributes,
              type,
              name: newName,
              projectId,
            });
          },
        },
        {
          type: "leaf",
          id: "delete",
          title: "Delete",
          onSelect: () => {
            logEvent("library.style.delete", {
              type: type,
              tag:
                savedStyleAttributes.type === "color"
                  ? savedStyleAttributes.colorType
                  : savedStyleAttributes.htmlTag,
            });
            deleteSavedStyle(id);
          },
        },
      ]}
      trigger={
        <MenuTrigger>
          <IconButton
            variant="tertiary"
            size="sm"
            className="h-6 w-6 p-1"
            icon={<BsThreeDots />}
            aria-hidden
            hasMinDimensions={false}
          />
        </MenuTrigger>
      }
    />
  );
};

export default SavedStyleItemEndEnhancer;
