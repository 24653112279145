import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { createArrayOfLength } from "replo-utils/lib/array";

import { useComponentClassNames } from "../../../shared/utils/renderComponents";
import { ReploComponent } from "../ReploComponent";

const Marquee: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const repetition = component.props._internalUseRepetition ?? true;
  const classNameMap = useComponentClassNames("marquee", component, context);
  const template = component.children?.length && component.children[0];
  if (!template) {
    return null;
  }
  // Note (Noah, 2021-09-09): Reset whitespace to normal here, otherwise
  // it cascades to text components inside the marquee and they don't line wrap
  // Note (Ovishek, 2022-12-16): We are not merging here b/c of performance issues
  // merge takes a little bit longer time than copy destructuring
  const templateWithStyles = {
    ...template,
    props: {
      ...template.props,
      style: {
        ...template.props.style,
        flexShrink: 0,
        whiteSpace: "normal",
      },
    },
  };

  const numberOfRepetitions = repetition ? 20 : 2;

  return (
    <div data-replo-marquee-root {...componentAttributes}>
      <div className={classNameMap?.inner} data-replo-marquee-track>
        {createArrayOfLength(numberOfRepetitions).map((_, index) => {
          return (
            <ReploComponent
              context={context}
              key={index}
              component={templateWithStyles}
              repeatedIndexPath={`${context.repeatedIndexPath}.${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Marquee;
