import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const LooxProductRatingSummary: React.FC<RenderComponentProps> = (props) => {
  const showAllProductRatings = props.component.props._showAllRatings;
  const allowEmptyStars = props.component.props._allowEmptyStars;
  const liquidSourceWidget = `
    {% assign avg_rating = product.metafields.loox.avg_rating %}
    {% assign num_reviews = product.metafields.loox.num_reviews %}
    {% if ${Boolean(
      showAllProductRatings,
    )} and shop.metafields.loox["global_stats"] != blank %}
      {% assign looxstats = shop.metafields.loox["global_stats"] | split: ',' %}
      {% assign avg_rating = looxstats[0] %}
      {% assign num_reviews = looxstats[1] %}
    {% endif %}
    <div
      class="loox-rating"
      data-id="{{ product.id }}"
      data-show-empty-stars="${Boolean(allowEmptyStars)}"
      data-rating="{{ avg_rating }}"
      data-raters="{{ num_reviews }}"></div>`;

  return (
    <SharedShopifyLiquid
      component={props.component}
      componentId={props.component.id}
      componentAttributes={props.componentAttributes}
      liquidSource={liquidSourceWidget}
      placeholder="The Loox Reviews Widget will appear here when you publish. Something not right? Let us know at support@replo.app"
      forceEditorPlaceholder={true}
      repeatedIndexPath={props.context.repeatedIndexPath}
      isLiquidSupported
      placeholderTitle="Loox Product Rating"
      placeholderType="reviewStars"
    />
  );
};

export default LooxProductRatingSummary;
