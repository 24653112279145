import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

export type WorkspaceUrlHost = {
  data: AnalyticsReadQuery["urlHosts"];
  isLoading: boolean;
};

const useWorkspaceUrlHosts = (workspaceId: string | null): WorkspaceUrlHost => {
  const { data, isLoading } = trpc.analytics.getUrlHostsWithSubDomains.useQuery(
    workspaceId ? { workspaceId } : skipToken,
  );

  const sortedDataRootDomainsFirst = data?.sort(
    (a, b) => (b.isRootDomain ? 1 : 0) - (a.isRootDomain ? 1 : 0),
  );

  return {
    data: sortedDataRootDomainsFirst?.map((host) => host.urlHost) ?? [],
    isLoading,
  };
};

export default useWorkspaceUrlHosts;
