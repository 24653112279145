import * as React from "react";

import useStandardSpring from "@editor/hooks/useStandardSpring";

import * as Collapsible from "@radix-ui/react-collapsible";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsFillCaretDownFill } from "react-icons/bs";
import { MdArrowDropDown } from "react-icons/md";
import { animated } from "react-spring";
import { isFunction } from "replo-utils/lib/type-check";
import { useControllableState } from "replo-utils/react/use-controllable-state";

type GroupProps = {
  /**
   * When true, `GroupHeader` will render a trigger that collapses or expands
   * the `Group`'s child contents when clicked.
   */
  isCollapsible?: boolean;
  /**
   * Whether or not the group's child contents are initially expanded. The Group
   * will manage its own state internally.
   */
  isDefaultOpen?: boolean;
  /**
   * Whether or not the group's child contents are expanded. The consumer
   * component is responsible for managing this state and providing a change
   * handler via `onOpenChange`.
   */
  isOpen?: boolean;
  /**
   * A function fired when the open state of a collapsible group should change.
   */
  onOpenChange?: (value: boolean) => void;
  /**
   * The `name` will provide the text to be rendered by the `GroupTitle` unless
   * a custom title is rendered via `header`.
   */
  name: string;
  /**
   * A slot to render a custom `GroupHeader` component. If omitted, a default
   * header is rendered with a `GroupTitle` using the group's `name`.
   */
  header?: React.ReactNode;
  /**
   * A classname applied to the group's container element.
   */
  className?: string;
  /**
   * A classname applied to the element that wraps the group's child contents.
   */
  contentClassName?: string;
};

type GroupContextValue = {
  name: string;
  isCollapsible: boolean;
  isOpen: boolean;
};

const GroupContext = React.createContext<GroupContextValue | null>(null);
GroupContext.displayName = "GroupContext";
function useGroupContext(componentName: string) {
  const ctx = React.useContext(GroupContext);
  if (!ctx) {
    throw new Error(`${componentName} must be used within a Group component`);
  }
  return ctx;
}

// NOTE (Chance 2023-11-08): If a group is collapsible we need to render Radix
// `Collapsible` components that wrap and clone their children via `asChild`.
// Otherwise we can just render the children directly in a fragment. These
// wrapper components just simplify that a bit.
type OptionalCollapsibleProps<P = {}> = React.PropsWithChildren<P> & {
  isCollapsible: boolean;
};

function Root({
  isCollapsible,
  children,
  isOpen,
  onOpenChange,
}: OptionalCollapsibleProps<{
  isOpen: boolean;
  onOpenChange(value: boolean): void;
}>) {
  if (!isCollapsible) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <Collapsible.Root asChild open={isOpen} onOpenChange={onOpenChange}>
      {children}
    </Collapsible.Root>
  );
}

function Trigger({ children, isCollapsible }: OptionalCollapsibleProps) {
  if (!isCollapsible) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return <Collapsible.Trigger asChild>{children}</Collapsible.Trigger>;
}

function Content({
  isCollapsible,
  children,
  forceMount,
  style,
}: OptionalCollapsibleProps<{
  forceMount?: true;
  style?: React.CSSProperties;
}>) {
  if (!isCollapsible) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <Collapsible.Content asChild forceMount={forceMount} style={style}>
      {children}
    </Collapsible.Content>
  );
}

/**
 * `Group` is one of our lower-level DS components that is used as a building
 * block for others, like `ModifierGroup`, which is itself a building block for
 * components like `ActionsModifier` and `VariantModifier`. Because the
 * higher-level `ModifierGroup` is used in many different contexts, it often
 * needs to be customized for each use case.
 *
 * `Group` can be customized by rendering its internal parts via composition
 * props. This is optional and allows for you to drop down to the specific level
 * you need to customize.
 *
 * @example
 * ```tsx
 * // Works fine! Uses defaults to render its parts
 * <Group  name="Actions" isCollapsible>
 *   <ActionItems />
 * </Group>
 *
 * // Need to add a start enhancer or classname to the header?
 * // Just render a custom header and customize directly. GroupHeader
 * // has its own defaults for nested parts.
 * <Group
 *   name="Actions"
 *   isCollapsible
 *   header={
 *     <GroupHeader className="fancy-header" />
 *   }
 * >
 *   <ActionItems />
 * </Group>
 *
 * // Need to customize the title inside the header? Same idea!
 * <Group
 *   name="Actions"
 *   isCollapsible
 *   header={
 *     <GroupHeader className="fancy-header">
 *       <GroupTitle className="fancy-title">
 *         {({ name }) => <h2 className="fancy-heading">{name}</h2>}
 *       </GroupTitle>
 *     </GroupHeader>
 *   }
 * >
 *   <ActionItems />
 * </Group>
 * ```
 */
const Group = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<GroupProps>
>(
  (
    {
      name,
      header,
      isCollapsible = false,
      isDefaultOpen = false,
      isOpen: isControlledOpen,
      onOpenChange: onControllableOpenChange,
      className,
      children,
      contentClassName,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useControllableState<boolean>(
      isControlledOpen,
      isDefaultOpen || !isCollapsible,
      onControllableOpenChange,
    );

    function onOpenChange(value: boolean) {
      if (isCollapsible) {
        setIsOpen(value);
      }
    }

    return (
      <Root
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isCollapsible={isCollapsible}
      >
        <div ref={ref} className={twMerge("flex flex-col", className)}>
          <GroupContext.Provider
            value={{
              isCollapsible,
              isOpen,
              name,
            }}
          >
            {header || <GroupHeader />}
            <Content
              isCollapsible={isCollapsible}
              forceMount
              style={{
                position: "relative",
                zIndex: isOpen ? 0 : -1,
              }}
            >
              {isOpen && children && (
                <div
                  className={twMerge(isCollapsible && "pt-2", contentClassName)}
                >
                  {children}
                </div>
              )}
            </Content>
          </GroupContext.Provider>
        </div>
      </Root>
    );
  },
);

Group.displayName = "Group";

type GroupHeaderProps = React.PropsWithChildren<{
  /**
   * A classname applied to the header's container element.
   */
  className?: string;
  /**
   * A classname applied to the container element for the title (as opposed to the end enhancer).
   */
  contentClassName?: string;
  /**
   * A node rendered before the group header's contents.
   */
  startEnhancer?: React.ReactNode;
  /**
   * A node rendered after the group header's contents.
   */
  endEnhancer?: React.ReactNode;
  /**
   * A node rendered before the title's contents.
   */
  titleEnhancer?: React.ReactNode;
  /**
   * Whether or not the `endEnhancer` is collapsed when the collapsible `Group`
   * is closed. This has no effect on non-collapsible groups.
   * @default true
   */
  hideEndEnhancerOnGroupClosed?: boolean;
  /**
   * Whether or not the title enhancer should stop propagation on click.
   * We need this in most cases to allow clicking the enhancer to open a link or
   * trigger some other action.
   * @default true
   */
  shouldStopPropagationOnTitleEnhancer?: boolean;
}>;

type GroupHeaderContextValue = Pick<GroupHeaderProps, "startEnhancer">;
const GroupHeaderContext = React.createContext<GroupHeaderContextValue | null>(
  null,
);
GroupHeaderContext.displayName = "GroupHeaderContext";

const GroupHeader: React.FC<GroupHeaderProps> = ({
  children,
  className,
  contentClassName,
  endEnhancer,
  startEnhancer,
  titleEnhancer,
  hideEndEnhancerOnGroupClosed = true,
  shouldStopPropagationOnTitleEnhancer = true,
}) => {
  const { isCollapsible, isOpen } = useGroupContext("GroupHeader");

  const handleEnhancerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Trigger isCollapsible={isCollapsible}>
      <div
        className={twMerge(
          "flex flex-row items-center justify-start gap-2",
          className,
          isCollapsible && "cursor-pointer",
        )}
      >
        <div
          className={twMerge(
            "flex items-center gap-1.5",
            contentClassName,
            endEnhancer ? "flex-1" : "w-full",
          )}
        >
          <GroupHeaderContext.Provider value={{ startEnhancer }}>
            {children ?? (
              <GroupTitleContainer>
                <GroupTitle />
              </GroupTitleContainer>
            )}
            {titleEnhancer && (
              <div
                className="flex items-center"
                onClick={
                  shouldStopPropagationOnTitleEnhancer
                    ? handleEnhancerClick
                    : undefined
                }
              >
                {titleEnhancer}
              </div>
            )}
          </GroupHeaderContext.Provider>
        </div>
        {endEnhancer && (
          <div
            className="flex items-center justify-end"
            onClick={handleEnhancerClick}
          >
            <GroupEndEnhancer
              isOpen={isOpen}
              isCollapsible={hideEndEnhancerOnGroupClosed}
            >
              {endEnhancer}
            </GroupEndEnhancer>
          </div>
        )}
      </div>
    </Trigger>
  );
};

const GroupTitleContainer: React.FC<{
  /**
   * A classname applied to the container element.
   */
  className?: string;
  /**
   * The incicator icon that is rendered when the group is collapsible. By
   * default a `GroupCollapsibleIndicator` will be rendered. When using this
   * prop, you should always render a `GroupCollapsibleIndicator` directly for
   * visual consistency, but this may be useful if you want to customize the
   * indicator in some way (like changing its size).
   *
   * @example
   * ```tsx
   * <GroupTitleContainer
   *   collapsibleIndicator={
   *     <GroupCollapsibleIndicator className="fill-blue" size="large" />
   *   }
   * />
   * ```
   */
  collapsibleIndicator?: React.ReactNode;
  /**
   * a className that can be applied to the div wrapped around the collapsibleIndicator
   */
  collapsibleIndicatorClassName?: string;
  /**
   * `children` can either be a standard React node or a function providing the
   * group's internal context if customization is needed based on the group's
   * state.
   */
  children?:
    | React.ReactNode
    | ((context: GroupContextValue) => React.ReactNode);
  /**
   * A data test id for the group title container.
   */
  groupDataTestId?: string;
}> = ({
  children,
  className,
  collapsibleIndicator,
  collapsibleIndicatorClassName,
  groupDataTestId,
}) => {
  const context = useGroupContext("GroupTitleContainer");
  const { isCollapsible, isOpen } = context;
  const { startEnhancer } = React.useContext(GroupHeaderContext) ?? {};
  const triangleAnimation = useStandardSpring({
    rotate: isOpen ? "0" : "-90deg",
  });

  return (
    <div
      data-testid={groupDataTestId}
      className={twMerge("flex items-center gap-1", className)}
    >
      {/* NOTE (Fran 2024-12-05): We will show the startEnhancer only if the group is not open */}
      {!isOpen && startEnhancer}
      {isCollapsible &&
        (collapsibleIndicator ?? (
          <animated.div
            style={triangleAnimation}
            className={twMerge(
              "p-1 h-4 cursor-pointer hover:bg-light-surface",
              collapsibleIndicatorClassName,
            )}
          >
            <BsFillCaretDownFill aria-hidden className="text-subtle" size={8} />
          </animated.div>
        ))}
      {isFunction(children) ? children(context) : children || <GroupTitle />}
    </div>
  );
};

const GroupCollapsibleIndicator: React.FC<{ size?: "small" | "large" }> = ({
  size = "small",
}) => {
  return (
    <MdArrowDropDown
      aria-hidden
      className="text-slate-400"
      size={size === "large" ? 16 : 12}
    />
  );
};

/** @internal */
const GroupEndEnhancer: React.FC<
  OptionalCollapsibleProps<{
    isOpen?: boolean;
  }>
> = ({ children, isOpen, isCollapsible }) => {
  const enhancerAnimation = useStandardSpring({
    opacity: isOpen ? 1 : 0,
    visibility: isOpen ? "visible" : "hidden",
  });
  const className = "flex flex-row items-center gap-x-2 self-end";
  if (isCollapsible) {
    return (
      <animated.div style={enhancerAnimation} className={className}>
        {children}
      </animated.div>
    );
  }
  return <div className={className}>{children}</div>;
};

const GroupTitle: React.FC<{
  /**
   * A classname applied to the title's element.
   */
  className?: string;
  /**
   * `children` can either be a standard React node or a function providing the
   * group's internal context if customization is needed based on the group's
   * state.
   */
  children?:
    | React.ReactNode
    | ((context: GroupContextValue) => React.ReactNode);
}> = ({ children, className }) => {
  const context = useGroupContext("GroupTitle");
  return (
    <div
      className={twMerge(
        "select-none whitespace-nowrap text-xs font-semibold text-default overflow-hidden text-ellipsis",
        className,
      )}
    >
      <span className="truncate">
        {isFunction(children) ? children(context) : children || context.name}
      </span>
    </div>
  );
};

type AriaLabelProps =
  | {
      /**
       * An accessible label for the button.
       */
      "aria-label": string;
      "aria-labelledby"?: never;
    }
  | {
      "aria-label"?: never;
      /**
       * An ID that points to the element that provides an accessible label for
       * the button.
       */
      "aria-labelledby": string;
    };

/**
 * A component that renders a visually consistent button for some action in the
 * group header. This should be used in a `GroupHeader` component via the
 * `endEnhancer` prop.
 *
 * @example
 * ```tsx
 * <Group
 *   header={
 *     <GroupHeader
 *       endEnhancer={
 *         <GroupHeaderActionButton
 *           aria-label="Some action"
 *           onClick={handleClick}
 *         >
 *           <Icon />
 *         </GroupHeaderActionButton>
 *       }
 *     />
 *   }
 * />
 * ```
 */
const GroupHeaderActionButton: React.FC<
  React.PropsWithChildren<
    AriaLabelProps & {
      /**
       * NOTE (Chance 2023-11-08): `onClick` is required because
       * `GroupHeaderActionButton` should only be used for real buttons. If you
       * don't need an onClick handler, as is the case when a rendered child is a
       * button with its own event handling or decorative components, use the
       * `GroupHeaderActionWrapper` component instead.
       */
      onClick: React.MouseEventHandler<HTMLButtonElement>;
    }
  >
> = ({ children, onClick, ...ariaProps }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex items-center justify-center"
      {...ariaProps}
    >
      {children}
    </button>
  );
};

const GroupHeaderActionWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return <div className="flex items-center justify-center">{children}</div>;
};

export {
  Group,
  GroupHeader,
  GroupTitle,
  GroupTitleContainer,
  GroupCollapsibleIndicator,
  GroupHeaderActionButton,
  GroupHeaderActionWrapper,
};
