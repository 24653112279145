import type { IconType } from "react-icons/lib";

import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
} from "react-icons/io";

type ArrowIndicatorProps = {
  isVertical: boolean;
  arrowLength: number | string;
  label: string;
  top: string;
  left: string;
  perpendicularSpace: string;
  arrowheadClassName: string;
  arrowClassName: string;
};

const arrows: Record<string, IconType> = {
  up: IoIosArrowUp,
  down: IoIosArrowDown,
  left: IoIosArrowBack,
  right: IoIosArrowForward,
};

const Arrow: React.FC<{ orientation: string; className: string }> = ({
  orientation,
  className,
}) => {
  const ArrowComponent = arrows[orientation]!;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 3,
        marginTop: orientation === "down" ? "-11px" : 0,
        marginLeft: orientation === "right" ? "-11px" : 0,
      }}
    >
      <ArrowComponent className={className} size="18px" />
    </div>
  );
};

const ArrowIndicator: React.FC<ArrowIndicatorProps> = ({
  isVertical,
  arrowLength: length,
  top,
  left,
  perpendicularSpace,
  label,
  arrowheadClassName,
  arrowClassName,
}) => {
  const areArrowheadsVisible = Number.parseFloat(String(length)) > 35;
  if (!areArrowheadsVisible) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isVertical ? "column" : "row",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top,
        left,
        width: !isVertical ? length : perpendicularSpace,
        height: isVertical ? length : perpendicularSpace,
        zIndex: 150,
      }}
    >
      <Arrow
        orientation={isVertical ? "up" : "left"}
        className={arrowheadClassName}
      />
      <div
        className={arrowClassName}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 3,
          width: isVertical ? "1.5px" : "100%",
          height: !isVertical ? "1.5px" : "100%",
          marginLeft: !isVertical ? "-11px" : 0,
          marginTop: isVertical ? "-11px" : 0,
        }}
      />
      <div
        style={{
          zIndex: 3,
          alignItems: "start",
          justifyContent: "left",
          position: "absolute",
        }}
      >
        <p
          className={twMerge("text-white", arrowClassName)}
          style={{
            padding: "0px 10px",
            borderRadius: "20px",
          }}
        >
          {label}
        </p>
      </div>
      <Arrow
        orientation={isVertical ? "down" : "right"}
        className={arrowheadClassName}
      />
    </div>
  );
};

export default ArrowIndicator;
