import * as React from "react";

import Selectable from "@common/designSystem/Selectable";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { BsChevronDown } from "react-icons/bs";
import { PageTypeEnum } from "schemas/analyticsRead";

const PageTypeWithDescription: React.FC<{
  title: string;
  description: string;
  isSelected: boolean;
  isHovered: boolean;
  className?: string;
}> = ({ title, description, isSelected, isHovered, className }) => {
  return (
    <div
      className={twMerge(
        className,
        "flex flex-col rounded p-3 w-full",
        classNames({
          "bg-blue-200": isSelected,
          "hover:bg-hover": isHovered && !isSelected,
        }),
      )}
    >
      <p className="text-xs font-normal text-default">{title}</p>
      <p className="text-xs font-normal text-slate-500">{description}</p>
    </div>
  );
};

const PageTypeSelect: React.FC = () => {
  const { query, dispatchAnalyticsQuery } = useAnalyticsQueryContext();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpenChange = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Selectable
      options={[
        {
          simpleSelectedLabel: "All Viewed Pages",
          label: ({ isSelected, isHovered }) => {
            return (
              <PageTypeWithDescription
                title="All Viewed Pages"
                description="Data for any page visited within the session"
                isSelected={isSelected}
                isHovered={isHovered}
                className="mb-1 font-medium text-default"
              />
            );
          },
          value: PageTypeEnum.ALL_PAGES,
          isDisabled: false,
        },
        {
          simpleSelectedLabel: "Entry Pages",
          label: ({ isSelected, isHovered }) => {
            return (
              <PageTypeWithDescription
                title="Entry Pages"
                description="Shows metrics for pages that began the session"
                isSelected={isSelected}
                isHovered={isHovered}
                className="font-medium text-default"
              />
            );
          },
          value: PageTypeEnum.ENTRY_PAGES,
          isDisabled: false,
        },
      ]}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      value={query.filters?.pageType ?? PageTypeEnum.ALL_PAGES}
      className={classNames(
        "h-8 w-auto bg-transparent rounded hover:bg-slate-100 p-0 px-2",
        {
          "bg-slate-100": isOpen,
        },
      )}
      labelClassName="flex items-center justify-between text-xs font-medium"
      contentClassName="border border-slate-300"
      dropdownAlign="end"
      size="sm"
      disableDropdownFixedWidth={true}
      overrideItemSpacing={true}
      endEnhancer={() => <BsChevronDown className="text-blue-600" size={12} />}
      onSelect={(value) => {
        dispatchAnalyticsQuery({
          type: "filters.pageType",
          payload: value as PageTypeEnum,
        });
      }}
    />
  );
};

export default PageTypeSelect;
