import type { ToggleGroupOption } from "@editor/components/common/designSystem/ToggleGroup";
import type { ComponentTemplate } from "@editor/types/component-template";
import type { ElementContent, ElementErrorType } from "@editor/utils/element";
import type { EditorRoute } from "@editor/utils/router";
import type {
  ReploElement,
  ReploElementType,
  ReploPartialElement,
} from "schemas/generated/element";
import type { CreateElementModalProps } from "../AppModalTypes";

import * as React from "react";

import { clearTemplateLibraryRedirection } from "@common/utils";
import {
  HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE,
  prepareComponentTemplate,
} from "@components/editor/defaultComponentTemplates";
import PageElementEditor from "@components/editor/elementEditors/PageElementEditor";
import ProductTemplateElementEditor from "@components/editor/elementEditors/ProductTemplateElementEditor";
import { SectionElementEditor } from "@components/editor/elementEditors/SectionElementEditor";
import ToggleGroup from "@editor/components/common/designSystem/ToggleGroup";
import { ElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import {
  useElementEditorErrorContext,
  withElementEditorErrorContext,
} from "@editor/contexts/ElementEditorErrorContext";
import { useElementValidation } from "@editor/hooks/element";
import { useCreateElement } from "@editor/hooks/useCreateElement";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { useLocalStorage } from "@editor/hooks/useLocalStorage";
import { useModal } from "@editor/hooks/useModal";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useAnyStoreProducts } from "@editor/hooks/useStoreProducts";
import { analytics } from "@editor/infra/analytics";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectComponentDataMapping,
  selectIsShopifyIntegrationEnabled,
  setElementsLoading,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import {
  elementFormHasErrors,
  getFormattedElementName,
} from "@editor/utils/element";
import { routes } from "@editor/utils/router";
import { getEmptyTemplate } from "@editor/utils/template";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  isPageOrShopifyArticleType,
  isReploElementType,
} from "schemas/element";

import { ElementEditorModalForm } from "../editor/elementEditors/ElementEditorModalForm";
import { ElementEditorTitle } from "../editor/elementEditors/ElementEditorTitle";

const DISABLED_ELEMENT_TYPES_TOOLTIP_TEXT =
  "Enabled with Shopify store integration";

const CREATE_ELEMENT_TYPE_CONTENT: Record<ReploElementType, ElementContent> = {
  page: {
    header: "New Page",
    submitText: "Create Page",
  },
  shopifyArticle: {
    header: "New Blog Post",
    submitText: "Create Blog Post",
  },
  shopifyProductTemplate: {
    header: "New Product Template",
    submitText: "Create Product Template",
  },
  shopifySection: {
    header: "New Section",
    submitText: "Create Section",
  },
};

export const CreateElementModal: React.FC<CreateElementModalProps> =
  withElementEditorErrorContext(
    ({
      initialTemplate,
      initialElementType,
      initialName,
      renavigateOnClose = false,
      folderId,
    }) => {
      const {
        element,
        onChangeElement,
        createElement,
        validateElement,
        isCreatingElement,
      } = useNewElement({
        initialTemplate,
        initialName,
        initialElementType,
        folderId,
      });
      const { setMenuState } = useAIStreaming();

      const onClose = useOnCloseCreateElementModal(renavigateOnClose);
      const modal = useModal();
      const dispatch = useEditorDispatch();
      const { errorMapping, setErrors, clearErrors } =
        useElementEditorErrorContext();

      const setDraftElement = useSetDraftElement();

      const navigate = useNavigate();
      const elementTypeOptions = useElementTypeOptions();
      const content = CREATE_ELEMENT_TYPE_CONTENT[element.type];
      const isPageOrShopifyArticle = isPageOrShopifyArticleType(element.type);

      async function onSubmit() {
        clearErrors("path", "allErrorsFromAllFormKeys");

        const { isValid, errors: validationErrors } = validateElement(element);
        if (!isValid) {
          if (validationErrors.title.length > 0) {
            setErrors("title", validationErrors.title);
          }
          if (validationErrors.path.length > 0) {
            setErrors("path", validationErrors.path);
          }
          return;
        }

        const result = await createElement(element);
        dispatch(setElementsLoading(false));

        if (result.isCreated) {
          modal.closeModal({ type: "createElementModal" });
          // NOTE (Fran 2024-05-13): We need to set the draft element before navigating so the editor
          // can load the element correctly.
          setDraftElement({
            id: result.data!.element.id,
          });
          navigate(
            generatePath(routes.editor.element, {
              projectId: result.data!.element.projectId,
              elementId: result.data!.element.id,
            } as EditorRoute),
          );
          if (!initialTemplate.id.startsWith("blank")) {
            setMenuState("template");
          }
        } else {
          if (result.errors && result.errors.path.length > 0) {
            setErrors("path", result.errors.path);
          }
        }
      }

      return (
        <ElementEditorDataContext.Provider value={{ element, onChangeElement }}>
          <ElementEditorModalForm
            testId="create-element-form"
            onSubmit={(e) => {
              e.preventDefault();
              if (isCreatingElement) {
                return;
              }
              void onSubmit();
            }}
            onCloseModal={onClose}
          >
            <div className="no-scrollbar max-h-[85vh] overflow-y-auto">
              <ToggleGroup
                allowsDeselect={false}
                type="single"
                options={elementTypeOptions}
                value={isPageOrShopifyArticle ? "page" : element.type}
                onChange={(value) => {
                  clearErrors("title", "allErrorsFromAllFormKeys");
                  onChangeElement({
                    ...element,
                    type: value as ReploElementType,
                  });
                }}
                className="mt-6 mx-2"
                style={{ width: "auto" }}
              />
              <div
                className={classNames({
                  "h-full": isPageOrShopifyArticle,
                })}
              >
                <ElementEditorTitle>{content.header}</ElementEditorTitle>
                {(() => {
                  switch (element.type) {
                    case "page":
                    case "shopifyArticle":
                      return (
                        <PageElementEditor
                          requestType="new"
                          initialName={initialName}
                        />
                      );
                    case "shopifyProductTemplate":
                      return (
                        <ProductTemplateElementEditor
                          initialName={initialName}
                        />
                      );
                    case "shopifySection":
                      return (
                        <SectionElementEditor
                          requestType="new"
                          initialName={initialName}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </div>
            </div>
            <div className="mt-8 flex flex-row justify-end gap-x-2">
              <Button
                size="lg"
                variant="primary"
                type="submit"
                isLoading={isCreatingElement}
                data-testid="page-submit-button"
                disabled={elementFormHasErrors(errorMapping)}
              >
                {content?.submitText}
              </Button>
            </div>
          </ElementEditorModalForm>
        </ElementEditorDataContext.Provider>
      );
    },
  );

// #region Helper hooks

/**
 * This hook handles creation, validation and the state of a new Replo element.
 */
function useNewElement({
  initialName,
  initialTemplate,
  initialElementType,
  folderId,
}: CreateElementModalProps) {
  const elementType = useElementType(initialElementType);
  const projectId = useCurrentProjectId();

  const [element, setElement] = React.useState<ReploPartialElement>({
    name: initialName ?? "",
    projectId: projectId ?? "",
    type: elementType,
    hideDefaultHeader: false,
    hideDefaultFooter: false,
    hideShopifyAnnouncementBar: false,
    shopifyPagePath: "",
    shopifyTemplateSuffix: "",
    isHomepage: false,
    isPublished: false,
    shopifyMetafields: [],
    shopifyArticleImage: { src: "" },
  });

  // Note (Noah, 2024-05-12, USE-940): It might be that we opened the modal which
  // uses this hook automatically from claiming a template, in which case the project
  // id might not be loaded yet. We need to update the projectId if the projectId changes,
  // so that it will validate correctly on the backend.
  // TODO (Noah, 2024-05-12): the fact that we use an entire element partial here makes
  // no sense at all - it should just be a form state object, which we mutate into
  // an element when we call the create endpoint. If we did that this effect could go away.
  const elementProjectId = element.projectId;
  React.useEffect(() => {
    if (projectId && projectId !== elementProjectId) {
      setElement((element) => ({ ...element, projectId }));
    }
  }, [projectId, elementProjectId]);

  const validateElement = useElementValidation(element.type);
  const template = useComponentTemplate(initialTemplate, element.type);
  const getAttribute = useGetAttribute();
  const { activeCurrency, activeLanguage, moneyFormat } =
    useEditorSelector(selectLocaleData);
  const { products } = useAnyStoreProducts();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const { createElement, isLoading } = useCreateElement();
  const isPageOrShopifyArticle = isPageOrShopifyArticleType(element.type);
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;

  const { data: shopifyBlogs } = trpc.shopify.getBlogs.useQuery(
    isPageOrShopifyArticle && projectId && isShopifyIntegrationEnabled
      ? projectId
      : skipToken,
  );

  const firstShopifyBlogId = shopifyBlogs?.[0]?.id;

  // If user is trying to create a new article, we should set blog id as
  // the first one that results from fetching them from the API.
  React.useEffect(() => {
    if (element.type === "shopifyArticle" && firstShopifyBlogId) {
      setElement((element) => {
        if (!element.shopifyBlogId) {
          return {
            ...element,
            shopifyBlogId: firstShopifyBlogId,
          };
        }
        return element;
      });
    }
  }, [element.type, firstShopifyBlogId]);

  const onCreateElement = React.useCallback(
    async function onCreateElementFn(element: ReploPartialElement): Promise<
      | {
          isCreated: true;
          data: { element: ReploElement };
        }
      | {
          isCreated: false;
          errors?: { path: ElementErrorType[] };
        }
    > {
      // NOTE (Sebas, 2023-02-10): We should check if the template type is a
      // container and add one if it's not. REPL-6296
      const sanitizedTemplate =
        template?.template?.type !== "container"
          ? {
              ...HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE,
              template: {
                ...HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE.template!,
                children: [template.template],
              },
            }
          : template;

      const sanitizedElement = {
        ...element,
        name: getFormattedElementName(element),
        component: prepareComponentTemplate(sanitizedTemplate, null, element, {
          getAttribute,
          productResolutionDependencies: {
            products,
            currencyCode: activeCurrency,
            language: activeLanguage,
            moneyFormat,
            templateProduct,
            isEditor: true,
            isShopifyProductsLoading: false,
          },
          componentDataMapping,
          context: null,
        }),
        folderId,
      };

      try {
        const response = await createElement(sanitizedElement);
        if ("key" in response && response.key === "pathAlreadyInUse") {
          return {
            isCreated: false,
            errors: { path: ["pathAlreadyExists"] },
          };
        }

        if (response.element?.id) {
          analytics.logEvent("element.new", {
            operation: "create",
            type: element.type,
            creationMethod:
              template!.id === getEmptyTemplate(element.type).id
                ? "blank"
                : "template",
          });
          return {
            isCreated: true,
            data: {
              element: response.element,
            },
          };
        }
      } catch {
        return { isCreated: false };
      }

      return { isCreated: false };
    },
    [
      createElement,
      template,
      getAttribute,
      products,
      moneyFormat,
      activeCurrency,
      activeLanguage,
      templateProduct,
      componentDataMapping,
      folderId,
    ],
  );

  return {
    element,
    onChangeElement: setElement,
    createElement: onCreateElement,
    validateElement,
    isCreatingElement: isLoading,
  };
}

/**
 * This hook returns the options for the element type toggle group.
 */
function useElementTypeOptions(): ToggleGroupOption[] {
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  return React.useMemo(
    () => [
      {
        label: "Page",
        value: "page",
      },
      {
        label: "Product Template",
        value: "shopifyProductTemplate",
        attributes: {
          disabled: !isShopifyIntegrationEnabled,
        },
        tooltipContent: !isShopifyIntegrationEnabled
          ? DISABLED_ELEMENT_TYPES_TOOLTIP_TEXT
          : null,
      },
      {
        label: "Shopify Section",
        value: "shopifySection",
        attributes: {
          disabled: !isShopifyIntegrationEnabled,
        },
        tooltipContent: !isShopifyIntegrationEnabled
          ? DISABLED_ELEMENT_TYPES_TOOLTIP_TEXT
          : null,
      },
    ],
    [isShopifyIntegrationEnabled],
  );
}

/**
 * This hook returns the proper template for any element type. If the initial
 * template is not provided, it will return an empty template.
 */
function useComponentTemplate(
  initialTemplate: ComponentTemplate | undefined,
  elementType: ReploElementType,
) {
  const [template, setTemplate] = React.useState<ComponentTemplate>(
    initialTemplate ?? getEmptyTemplate(elementType),
  );

  // NOTE (Chance, 2023-09-11) In the event that the user changes the element
  // type while interacting with the modal, we need to update the modal's state
  // with the blank template to effectively reset it so that it's compatible
  // with the new element type.
  const elementTypeRef = React.useRef(elementType);
  React.useEffect(() => {
    const previousElementType = elementTypeRef.current;
    elementTypeRef.current = elementType;
    if (elementType !== previousElementType) {
      setTemplate(getEmptyTemplate(elementType));
    }
  }, [elementType]);

  return template;
}

/**
 * This hook returns a function that closes the create element modal and does
 * some additional actions if needed.
 */
function useOnCloseCreateElementModal(renavigateOnClose: boolean) {
  const modal = useModal();
  const projectId = useCurrentProjectId();
  const params = useParams();
  const navigate = useNavigate();
  const localStorage = useLocalStorage();

  return React.useCallback(() => {
    clearTemplateLibraryRedirection(localStorage);
    if (renavigateOnClose && projectId && params?.elementId) {
      navigate(
        generatePath(routes.editor.element, {
          projectId,
          elementId: params.elementId,
        } as EditorRoute),
      );
    }
    modal.closeModal({ type: "createElementModal" });
  }, [modal, projectId, params, navigate, renavigateOnClose, localStorage]);
}

/**
 * This hook returns asanitized element type value for the create element
 * modal, based on the initial value and the search params.
 */
function useElementType(initialValue: string | undefined): ReploElementType {
  const [searchParams] = useSearchParams();
  if (isReploElementType(initialValue)) {
    return initialValue;
  }

  const initialValueFromParams = searchParams.get("type") ?? "";
  return isReploElementType(initialValueFromParams)
    ? initialValueFromParams
    : "page";
}

// #endregion
