import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

type BackButtonProps = {
  label?: string;
  classname?: string;
  onClick?: () => void;
};
export const BackButton: React.FC<BackButtonProps> = ({
  label,
  classname,
  onClick,
}) => {
  const navigate = useNavigate();

  // NOTE (Max, 2024-12-20): If an onClick handler is explicitely provided,
  // we execute it (it should have the navigate() in it). Otherwise, we navigate
  // to the previous page.
  const onBack = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <button
      className={twMerge(
        "flex items-center gap-1 font-medium text-lg text-blue-600",
        classname,
      )}
      onClick={onBack}
    >
      <BsArrowLeft size={18} />
      {label}
    </button>
  );
};
