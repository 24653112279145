import type { ReploLineChartProps } from "@replo/design-system/components/shadcn/LineChart";
import type { LineProps } from "recharts";
import type { AxisDomain, BaseAxisProps } from "recharts/types/util/types";

import * as React from "react";

import { DEFAULT_DASHED_SUFFIX } from "@replo/design-system/components/shadcn/constants";
import clsxMerge from "@replo/design-system/components/shadcn/utils/cn-merge";
import * as RechartsPrimitive from "recharts";

export type AxisConfig = {
  dataKey?: string;
  tickFormatter?: BaseAxisProps["tickFormatter"];
  domain?: AxisDomain;
  tickMargin?: number;
  width?: number;
  ticks?: number[];
  // NOTE (Kurt, 2024-10-31): We should allow for any BaseAxisProps here as the axis config
  // object passed into ReploLineChart is directly destructed inside the <XAxis> & <YAxis>
  // components.
} & Partial<BaseAxisProps>;

export type DashedConfig = {
  suffix?: string;
  numberOfTicks: number;
};

export type LineConfig = {
  [k in string]: {
    label?: React.ReactNode;
    icon?: React.ComponentType;
    color?: string;
    dashed?: DashedConfig;
    dataKey: string;
    type?: LineProps["type"];
    stroke?: string;
    strokeWidth?: number;
    dot?: boolean | object;
  };
};

export type ChartConfig = {
  lines: LineConfig;
  xAxis: AxisConfig;
  yAxis?: AxisConfig;
  tooltipKeyFormatter?: (value: string) => string;
  tooltipValueFormatter?: (value: string | number) => string;
};

type ChartContextProps = {
  config: ChartConfig;
};

const ChartContext = React.createContext<ChartContextProps | null>(null);

function useChart() {
  const context = React.useContext(ChartContext);

  if (!context) {
    throw new Error("useChart must be used within a <ChartContainer />");
  }

  return context;
}

const ChartContainer = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & {
    config: ChartConfig;
    children: React.ComponentProps<
      typeof RechartsPrimitive.ResponsiveContainer
    >["children"];
  }
>(({ id, className, children, config, ...props }, ref) => {
  const uniqueId = React.useId();
  const chartId = `chart-${id || uniqueId.replace(/:/g, "")}`;

  return (
    <ChartContext.Provider value={{ config }}>
      <div
        data-chart={chartId}
        ref={ref}
        className={clsxMerge(
          "flex justify-between text-xs [&_.recharts-cartesian-grid_line[stroke='#ccc']]:stroke-border/50 [&_.recharts-curve.recharts-tooltip-cursor]:stroke-border [&_.recharts-dot[stroke='#fff']]:stroke-transparent [&_.recharts-layer]:outline-none [&_.recharts-polar-grid_[stroke='#ccc']]:stroke-border [&_.recharts-radial-bar-background-sector]:fill-muted [&_.recharts-rectangle.recharts-tooltip-cursor]:fill-muted [&_.recharts-reference-line_[stroke='#ccc']]:stroke-border [&_.recharts-sector[stroke='#fff']]:stroke-transparent [&_.recharts-sector]:outline-none [&_.recharts-surface]:outline-none w-full h-full",
          className,
        )}
        {...props}
      >
        <ChartStyle id={chartId} config={config} />
        <RechartsPrimitive.ResponsiveContainer>
          {children}
        </RechartsPrimitive.ResponsiveContainer>
      </div>
    </ChartContext.Provider>
  );
});
ChartContainer.displayName = "Chart";

const ChartStyle = ({ id, config }: { id: string; config: ChartConfig }) => {
  const colorConfig = Object.entries(config.lines)
    .filter(([_, itemConfig]) => itemConfig.color)
    .map(([key, itemConfig]) => {
      const cssVar = `var(--replo-color-${itemConfig.color}-a100)`;
      return [key, cssVar];
    });

  if (!colorConfig.length) {
    return null;
  }
  // NOTE (Kurt, 2024-08-06): We dynamically generate inline styles based on the `config` object
  // to set the color for each chart element (denoted by the `data-chart` attribute). We need to do this
  // because recharts does not provide a way to set the color of each element in the chart dynamically.
  // This approach ensures that our chart elements are loosely coupled with the color configuration, allowing
  // us to easily update the color scheme without modifying the chart components.
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
        [data-chart=${id}] {
        ${colorConfig
          .map(([key, cssVar]) => {
            return ` --color-${key}: ${cssVar};`;
          })
          .join("\n")}
        }`,
      }}
    />
  );
};

const ChartTooltip = RechartsPrimitive.Tooltip;

const getItemName = (item: any, config: ChartConfig): string | undefined => {
  if (item.name) {
    return item.name;
  }

  const matchingLine = Object.values(config.lines).find(
    (line) => line.dataKey === item.dataKey,
  );
  return matchingLine?.label?.toString() || matchingLine?.dataKey;
};

const filterDashedPayload = (
  payload: any[] | undefined,
  config: ChartConfig,
) => {
  if (!payload || payload.length === 0) {
    return payload || [];
  }
  const seen = new Set();
  return payload.filter((item) => {
    item.name = getItemName(item, config);
    const baseName = item.name?.toString().replace(DEFAULT_DASHED_SUFFIX, "");
    if (!baseName) {
      return false;
    }
    if (seen.has(baseName)) {
      return false;
    }
    seen.add(baseName);
    return true;
  });
};

const ChartTooltipContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof RechartsPrimitive.Tooltip> &
    React.ComponentProps<"div"> & {
      hideLabel?: boolean;
      hideIndicator?: boolean;
      indicator?: "line" | "dot" | "dashed";
      nameKey?: string;
      labelKey?: string;
      xAxisDataKey?: string;
      xAxisDataKeyFormatter?: ReploLineChartProps<string>["tooltipKeyFormatter"];
      tooltipValueFormatter?: (value: any) => string;
    }
>(
  (
    {
      active,
      payload,
      className,
      indicator = "dot",
      hideLabel = false,
      hideIndicator = false,
      labelFormatter,
      labelClassName,
      formatter,
      tooltipValueFormatter,
      xAxisDataKey,
      xAxisDataKeyFormatter,
    },
    ref,
  ) => {
    const { config } = useChart();

    const filteredPayload = React.useMemo(
      () => filterDashedPayload(payload, config),
      [payload, config],
    );

    const tooltipLabel = React.useMemo(() => {
      if (hideLabel || !filteredPayload?.length) {
        return null;
      }

      const key = Object.keys(config.lines)[0]!;
      const itemConfig = filteredPayload.find(
        (p) =>
          p.name === key ||
          p.name ===
            `${key}${config.lines[key]?.dashed?.suffix ?? DEFAULT_DASHED_SUFFIX}`,
      )!;
      const initialValue = xAxisDataKey
        ? itemConfig.payload[xAxisDataKey]
        : itemConfig.value;
      const value = xAxisDataKeyFormatter
        ? xAxisDataKeyFormatter(initialValue)
        : initialValue;
      if (labelFormatter) {
        return (
          <div className={clsxMerge("font-medium", labelClassName)}>
            {labelFormatter(value, filteredPayload)}
          </div>
        );
      }

      if (!value) {
        return null;
      }

      return (
        <div className={clsxMerge("font-medium", labelClassName)}>{value}</div>
      );
    }, [
      labelFormatter,
      filteredPayload,
      hideLabel,
      labelClassName,
      config,
      xAxisDataKey,
      xAxisDataKeyFormatter,
    ]);

    if (!active || !filteredPayload?.length) {
      return null;
    }

    const nestLabel = filteredPayload.length === 1 && indicator !== "dot";

    return (
      <div
        ref={ref}
        className={clsxMerge(
          "grid min-w-[8rem] items-start gap-1.5 rounded-lg border border-slate-100 bg-white px-2.5 py-1.5 text-xs shadow-xl text-slate-800",
          className,
        )}
      >
        {!nestLabel ? tooltipLabel : null}
        <div className="grid gap-1.5">
          {filteredPayload.map((item, index) => {
            const indicatorColor = item.color;
            const itemConfig = Object.values(config.lines).find(
              (line) =>
                line.dataKey === item.name ||
                (typeof item.name === "string" &&
                  line.dataKey ===
                    item.name?.replace(DEFAULT_DASHED_SUFFIX, "")),
            );
            return (
              <div
                key={item.dataKey}
                className={clsxMerge(
                  "flex w-full flex-wrap items-stretch gap-2 [&>svg]:h-2.5 [&>svg]:w-2.5 [&>svg]:text-muted-foreground",
                  indicator === "dot" && "items-center",
                )}
              >
                {formatter && item?.value !== undefined && item.name ? (
                  formatter(item.value, item.name, item, index, item.payload)
                ) : (
                  <>
                    {!hideIndicator && (
                      <div
                        className={clsxMerge(
                          "shrink-0 rounded-sm border-[--color-border] bg-[--color-bg]",
                          {
                            "h-2.5 w-2.5": indicator === "dot",
                            "w-1": indicator === "line",
                            "w-0 border-[1.5px] border-dashed bg-transparent":
                              indicator === "dashed",
                            "my-0.5": nestLabel && indicator === "dashed",
                          },
                        )}
                        style={
                          {
                            "--color-bg": indicatorColor,
                            "--color-border": indicatorColor,
                          } as React.CSSProperties
                        }
                      />
                    )}
                    <div
                      className={clsxMerge(
                        "flex flex-1 justify-between leading-none gap-2",
                        nestLabel ? "items-end" : "items-center",
                      )}
                    >
                      <div className="grid gap-1.5">
                        {nestLabel ? tooltipLabel : null}
                        <span className="text-muted-foreground">
                          {itemConfig?.label || item.name}
                        </span>
                      </div>
                      {item.value !== undefined && (
                        <div className="text-slate-800 font-medium tabular-nums ml-2">
                          {tooltipValueFormatter
                            ? tooltipValueFormatter(item.value)
                            : item.value.toLocaleString()}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);
ChartTooltipContent.displayName = "ChartTooltip";

const ChartLegend = RechartsPrimitive.Legend;

const ChartLegendContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> &
    Pick<RechartsPrimitive.LegendProps, "payload" | "verticalAlign"> & {
      nameKey?: string;
    }
>(({ className, payload, verticalAlign = "bottom" }, ref) => {
  const { config } = useChart();

  const filteredPayload = React.useMemo(
    () => filterDashedPayload(payload, config),
    [payload, config],
  );

  if (!filteredPayload.length) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={clsxMerge(
        "flex items-center justify-center gap-4",
        verticalAlign === "top" ? "pb-3" : "pt-3",
        className,
      )}
    >
      {filteredPayload.map((item) => {
        const itemConfig = Object.values(config.lines).find(
          (line) =>
            line.dataKey === item.name ||
            (typeof item.name === "string" &&
              line.dataKey === item.name?.replace(DEFAULT_DASHED_SUFFIX, "")),
        );

        return (
          <div
            key={item.value}
            className={clsxMerge(
              "flex items-center gap-1.5 [&>svg]:h-3 [&>svg]:w-3 [&>svg]:text-muted-foreground",
            )}
          >
            <div
              className="h-2 w-2 shrink-0 rounded-sm"
              style={{
                backgroundColor: item.color,
              }}
            />
            {itemConfig?.label || item.name}
          </div>
        );
      })}
    </div>
  );
});
ChartLegendContent.displayName = "ChartLegend";

export {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
};
