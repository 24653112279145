import type {
  SavedStyleColorAttributes,
  SavedStyleTextAttributes,
} from "schemas/generated/savedStyles";

import { useErrorToast } from "@editor/hooks/useErrorToast";
import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";
import { trpc, trpcUtils } from "@editor/utils/trpc";

function useUpdateDesignLibrarySavedStyle() {
  const store = useEditorStore();
  const errorToast = useErrorToast();
  const { mutateAsync: updateSavedStyle } =
    trpc.designLibrary.savedStyles.update.useMutation({
      onMutate: ({ savedStyleId, attributes, name }) => {
        if (!savedStyleId) {
          return;
        }
        const projectId = selectProjectId(store.getState());

        if (!projectId) {
          return;
        }

        // NOTE (Fran 2024-11-13): Cancel any ongoing queries that could override the optimistic update.
        void trpcUtils.designLibrary.get.cancel();
        trpcUtils.designLibrary.get.setData({ projectId }, (data) => {
          if (!data) {
            return null;
          }

          const savedStyle = data.savedStyles[savedStyleId];

          // NOTE (Fran 2024-11-14): We are not creating a new saved style here, so if the saved style doesn't exist,
          // we should not update the design library.
          if (!savedStyle) {
            return data;
          }

          return {
            ...data,
            savedStyles: {
              ...data.savedStyles,
              [savedStyleId]:
                attributes.type === "text"
                  ? {
                      attributes: attributes as SavedStyleTextAttributes,
                      type: "text",
                      name: name ?? savedStyle.name,
                    }
                  : {
                      attributes: attributes as SavedStyleColorAttributes,
                      type: "color",
                      name: name ?? savedStyle.name,
                    },
            },
          };
        });
      },
      onError: () => {
        errorToast(
          "Failed to duplicate saved style",
          "The saved style selected could not be duplicated",
          {
            eventName: "error.saved_style.duplicate",
            eventProperties: {},
          },
        );
      },
      onSettled: () => {
        const projectId = selectProjectId(store.getState());
        if (projectId) {
          void trpcUtils.designLibrary.get.invalidate({ projectId });
        }
      },
    });

  return {
    updateSavedStyle: async (input: {
      savedStyleId: string;
      attributes: SavedStyleColorAttributes | SavedStyleTextAttributes;
      name?: string;
    }) => {
      const projectId = selectProjectId(store.getState());
      if (!projectId) {
        return;
      }
      await updateSavedStyle({ ...input, projectId });
    },
  };
}

export default useUpdateDesignLibrarySavedStyle;
