import type {
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "@/features/analytics/time";
import type { ComboboxRootProps } from "@replo/design-system/components/combobox/types";
import type { DateAfter, DateRange } from "react-day-picker";

import * as React from "react";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import {
  calculateCompareRangePredefined,
  calculateCustomRange,
  calculateQueryRangesForRelativeTimeFrame,
  calculateRangesForCustomTimeFrame,
} from "@/features/analytics/time";
import { Combobox } from "@replo/design-system/components/combobox";
import { DatePickerWithRange } from "@replo/design-system/components/shadcn/core/date-range-picker";
import { BsCalendar3, BsCaretDownFill } from "react-icons/bs";

type AnalyticsTimeRangeComboboxProps<
  T extends RelativeTimeFrame | CompareRangeHandleChange,
> = Pick<ComboboxRootProps, "options" | "open" | "onOpenChange" | "size"> & {
  handleRangeChange: (timeFrame: T) => void;
  isCompare: boolean;
  placeholderPrefix?: string;
};

type CompareRangeHandleChange = ComparisonTimeFrame | DateRange;
type MainRangeHandleChange = RelativeTimeFrame | DateRange;

const rangeCustomDatePicker = (
  startDateTime: number,
  endDateTime: number,
  handleRangeChange: (dateRange: DateRange) => void,
  setIsRangePickerOpen: (isOpen: boolean) => void,
  size?: ComboboxRootProps["size"],
): React.ReactNode => {
  return (
    <DatePickerWithRange
      initialDateRange={{
        from: new Date(startDateTime),
        to: new Date(endDateTime),
      }}
      disabledDates={{ after: new Date() } as DateAfter}
      updateAction={(dateRange) => {
        handleRangeChange(dateRange);
        setIsRangePickerOpen(false);
      }}
      popoverTriggerComponent={() => (
        <span
          className={`font-medium cursor-pointer flex items-center text-blue-600 ${
            size === "sm" ? "text-xs" : "text-sm"
          }`}
        >
          <BsCalendar3 size={size === "sm" ? 12 : 14} className="mr-1.5" />
          Custom date range
        </span>
      )}
      closeAction={() => setIsRangePickerOpen(false)}
      rangeExtendsOnSelect={false}
    />
  );
};

export const AnalyticsTimeRangeCombobox = <
  T extends MainRangeHandleChange | CompareRangeHandleChange,
>({
  handleRangeChange,
  isCompare,
  size = "sm",
  ...props
}: AnalyticsTimeRangeComboboxProps<T>) => {
  const { query } = useAnalyticsQueryContext();

  let startDatetime;
  let endDatetime;
  let selectedValue;
  const selectedTimePeriod = query.selectedTimePeriod;
  const selectedComparePeriod = query.selectedComparePeriod;
  const { updatedRanges, selectedTimeFrame } =
    selectedTimePeriod.type === "custom"
      ? calculateRangesForCustomTimeFrame({
          start: selectedTimePeriod.value.from,
          end: selectedTimePeriod.value.to,
        })
      : calculateQueryRangesForRelativeTimeFrame({
          timeFrame: selectedTimePeriod.value as RelativeTimeFrame,
        });

  if (!isCompare) {
    selectedValue = selectedTimeFrame;
    startDatetime = updatedRanges.mainRange.startDatetime;
    endDatetime = updatedRanges.mainRange.endDatetime;
  } else {
    const { range: compareAtRange, selectedPeriod } =
      selectedComparePeriod.type === "custom"
        ? calculateCustomRange({
            start: new Date(selectedComparePeriod.value.from),
            end: new Date(selectedComparePeriod.value.to),
          })
        : calculateCompareRangePredefined(
            selectedComparePeriod.value as ComparisonTimeFrame,
            updatedRanges.mainRange,
          );

    selectedValue = selectedPeriod;
    startDatetime = compareAtRange.startDatetime;
    endDatetime = compareAtRange.endDatetime;
  }

  const [isRangePickerOpen, setIsRangePickerOpen] = React.useState(false);

  const footerContent = (
    <>
      {rangeCustomDatePicker(
        startDatetime ?? 0,
        endDatetime ?? 0,
        (dateRange) => handleRangeChange(dateRange as T),
        setIsRangePickerOpen,
        size,
      )}
    </>
  );

  return (
    <Combobox
      {...props}
      value={selectedValue}
      size={size}
      onChange={(value) => {
        handleRangeChange(value as T);
      }}
      open={isRangePickerOpen}
      onOpenChange={(isOpen) => {
        setIsRangePickerOpen(isOpen);
        props.onOpenChange?.(isOpen);
      }}
      startEnhancer={<BsCalendar3 className="h-4 w-4" />}
      endEnhancer={<BsCaretDownFill className="h-2 w-2 text-subtle" />}
      itemsOnViewCount={props.options?.length}
    >
      {footerContent && <Combobox.Footer>{footerContent}</Combobox.Footer>}
    </Combobox>
  );
};
