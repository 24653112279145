import * as React from "react";

import ProjectsTable from "@editor/components/dashboard/projects/ProjectsTable";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useGetProjectsByWorkspaceIdQuery } from "@editor/reducers/api-reducer";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { Outlet, useNavigate } from "react-router-dom";

const WorkspaceProjects: React.FC = () => {
  const navigate = useNavigate();
  const workspaceId = useCurrentWorkspaceId();

  const { data: workspaceData, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : reactQuerySkipToken,
    );

  const { data, isLoading } = useGetProjectsByWorkspaceIdQuery(
    workspaceId ?? skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <>
      <ProjectsTable
        title={!isLoading ? `${workspaceData?.workspace?.name} Projects` : null}
        subtitle={`These are the projects within ${workspaceData?.workspace?.name}`}
        projects={data?.projects}
        isLoading={isLoading || isLoadingWorkspace}
        buttonLabel="New Replo Project"
        onButtonClick={() => navigate("../project/new")}
      />
      <Outlet />
    </>
  );
};

export default WorkspaceProjects;
