import type { ReploSimpleProject } from "schemas/generated/project";

import * as React from "react";

import TableHeadTile from "@components/dashboard/tables/TableHeadTitle";
import InputComponent from "@editor/components/common/designSystem/Input";
import Separator from "@editor/components/common/designSystem/Separator";
import { Loader } from "@editor/components/common/Loader";
import Header from "@editor/components/dashboard/Header";
import { EmptyProjectMembership } from "@editor/components/dashboard/projects/memberships/EmptyProjectMembership";
import ProjectItem from "@editor/components/dashboard/projects/ProjectItem";
import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { getProjectName, getStoreData } from "@editor/utils/project-utils";
import { generateEditorPathname, routes } from "@editor/utils/router";

import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";
import { useDebouncedState } from "replo-utils/react/use-debounced-state";

type Field =
  | "project.shopifyUrl"
  | "project.lastEditedAt"
  | "project.workspace";
type Direction = "asc" | "desc";

type OrderState = {
  field: Field;
  direction: Direction;
};

type ProjectsTableProps = {
  title: string | null;
  subtitle: string;
  projects: ReploSimpleProject[] | undefined;
  isLoading: boolean;
  href?: string;
  onButtonClick?(): void;
  buttonLabel?: string;
  showWorkspaceColumn?: boolean;
};

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  title,
  subtitle,
  projects,
  isLoading,
  href,
  onButtonClick,
  buttonLabel = "",
  showWorkspaceColumn = false,
}) => {
  const { setWorkspaceId } = useWorkspaceDashboardContext();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const logAnalytics = useLogAnalytics();
  const [order, setOrder] = React.useState<OrderState>({
    field: "project.shopifyUrl",
    direction: "asc",
  });
  const sortedProjects = React.useMemo(
    () => sortProjectMemberships(order.field, order.direction, projects),
    [projects, order.field, order.direction],
  );

  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);

  const [searchQueryInputValue, searchQuery, setSearchQuery] =
    useDebouncedState("");

  const filteredProjects = sortedProjects.filter((project) =>
    getProjectName(project).toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const shouldShowEmptyProjectMembership = !isLoading && isEmpty(projects);
  const shouldShowProjectsTable = !isLoading && !isEmpty(projects);

  const handleSortClick = (field: Field) => {
    if (field === order.field) {
      setOrder({
        field: field,
        direction: order.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setOrder({ field, direction: "desc" });
    }
  };

  const onSelectProject = (
    project: ReploSimpleProject,
    e?: React.MouseEvent,
  ) => {
    const storeData = getStoreData(project);
    logAnalytics("dashboard.project.click", {
      storeId: project.id,
      name: project.name ?? "",
      storeUrl: storeData?.shopifyUrl ?? undefined,
      from:
        location.pathname === routes.allProjects
          ? "allProjects"
          : "orgProjects",
    } as const);
    setWorkspaceId(project.ownerWorkspace?.id ?? params.workspaceId ?? null);

    // Note (Juan, 2024-12-30) Allow users to open project in new tab with Cmd/Ctrl + click
    // This matches the default browser behavior for links
    if (e?.metaKey || e?.ctrlKey) {
      window.open(
        generateEditorPathname("", { projectId: project.id }),
        "_blank",
      );
      return;
    }

    navigate(generateEditorPathname("", { projectId: project.id }), {
      state: { getCanvasDoc: true },
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex((prev) => {
        if (prev === null) {
          return 0;
        } else {
          return (prev + 1) % filteredProjects.length;
        }
      });
      e.preventDefault();
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prev) => {
        if (prev === null) {
          return filteredProjects.length - 1;
        } else {
          return (prev - 1 + filteredProjects.length) % filteredProjects.length;
        }
      });
      e.preventDefault();
    } else if (
      e.key === "Enter" &&
      selectedIndex !== null &&
      filteredProjects[selectedIndex]
    ) {
      onSelectProject(filteredProjects[selectedIndex]);
      e.preventDefault();
    } else if (e.key === "Escape") {
      // Extra UX feature to escape the search input
      setSelectedIndex(null);
      e.preventDefault();
      (e.target as HTMLElement).blur();
    }
  };

  return (
    <div className="flex flex-col px-6 w-full">
      <Header
        title={title}
        subtitle={subtitle}
        buttonLabel={buttonLabel}
        href={href}
        onButtonClick={!isEmpty(projects) ? onButtonClick : undefined}
        isLoading={isLoading}
      />
      <Separator className="my-4 col-span-12" />
      {shouldShowEmptyProjectMembership ? (
        <EmptyProjectMembership
          shouldShowCreateProjectButton={isEmpty(projects)}
        />
      ) : null}
      {isLoading ? (
        <div className="col-span-12">
          <Loader className="mt-16" />
        </div>
      ) : null}
      {shouldShowProjectsTable ? (
        <>
          <div className="mb-4 max-w-xs">
            <InputComponent
              size="base"
              placeholder="Search by project name"
              type="text"
              value={searchQueryInputValue}
              onChange={(event) => setSearchQuery(event.target.value)}
              onEnter={() => {
                if (filteredProjects.length === 1 && filteredProjects[0]) {
                  onSelectProject(filteredProjects[0]);
                }
              }}
              onKeyDown={handleKeyDown}
              startEnhancer={<BsSearch className="text-slate-400" />}
              onBlur={() => setSelectedIndex(null)}
            />
          </div>
          <div className="grid grid-cols-12 items-center my-2">
            {/* Table Header */}
            <TableHeadTile
              title="Projects"
              onClick={() => handleSortClick("project.shopifyUrl")}
              arrowDirection={order.direction}
              shouldShowArrow={order.field === "project.shopifyUrl"}
              wrapperClassName="col-span-3 pl-4"
            />
            {showWorkspaceColumn ? (
              <TableHeadTile
                title="Workspace"
                onClick={() => handleSortClick("project.workspace")}
                arrowDirection={order.direction}
                shouldShowArrow={order.field === "project.workspace"}
                wrapperClassName="col-span-3 pl-2"
              />
            ) : null}
            <TableHeadTile
              title="Edited"
              onClick={() => handleSortClick("project.lastEditedAt")}
              arrowDirection={order.direction}
              shouldShowArrow={order.field === "project.lastEditedAt"}
              wrapperClassName="col-span-3 pl-2"
            />
            <TableHeadTile
              title="Integrations"
              shouldShowArrow={false}
              wrapperClassName={`${
                showWorkspaceColumn ? "col-span-2" : "col-span-3"
              } pl-2`}
            />
            {/* Table Body */}
            {filteredProjects.length === 0 && (
              <div className="col-span-10 py-12 justify-center flex text-slate-400">
                No results found
              </div>
            )}
            {/* NOTE (Sebas, 2024-03-20): This padding bottom is necessary is for the support button */}
            <div className="col-span-12 mt-2 gap-2 pb-10">
              {filteredProjects.map((project, index) => (
                <ProjectItem
                  key={project.id}
                  project={project}
                  showWorkspaceColumn={showWorkspaceColumn}
                  onSelectProject={(e) => onSelectProject(project, e)}
                  className={`${selectedIndex === index ? "bg-blue-100" : ""}`}
                />
              ))}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const sortProjectMemberships = (
  field: string,
  direction: string,
  data?: ReploSimpleProject[],
) => {
  const sortedData = [...(data ?? [])];
  sortedData.sort((a, b) => {
    if (field === "project.lastEditedAt") {
      const dateA: number = new Date(a.lastEditedAt ?? a.createdAt).getTime();
      const dateB: number = new Date(b.lastEditedAt ?? b.createdAt).getTime();
      return direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (field === "project.workspace" && a.ownerWorkspace && b.ownerWorkspace) {
      const nameOfA = a.ownerWorkspace.name;
      const nameOfB = b.ownerWorkspace.name;
      return direction === "asc"
        ? nameOfA.localeCompare(nameOfB)
        : nameOfB.localeCompare(nameOfA);
    }

    const nameOfA = getProjectName(a);
    const nameOfB = getProjectName(b);
    return direction === "asc"
      ? nameOfA.localeCompare(nameOfB)
      : nameOfB.localeCompare(nameOfA);
  });
  return sortedData;
};

export default ProjectsTable;
