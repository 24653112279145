import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import {
  buildGoogleFontApiUrl,
  GOOGLE_FONTS,
} from "replo-runtime/shared/utils/font";

type ReploPreviewTextSavedStyleProps = {
  attributes: SavedStyleTextAttributes;
  text: string;
};

const ReploPreviewTextSavedStyle: React.FC<ReploPreviewTextSavedStyleProps> = ({
  attributes,
  text,
}) => {
  return (
    <div className="bg-neutral-soft rounded flex items-center overflow-hidden w-full whitespace-nowrap">
      {/* TODO (Fran 2024-12-16 REPL-15099): Remove this with No-Mirror project */}
      {attributes.fontFamily && (
        <style
          dangerouslySetInnerHTML={{
            __html: getFontFamilyStyleHTML(attributes.fontFamily),
          }}
        />
      )}
      <div
        style={getPreviewStyles(attributes)}
        className="p-1 w-full text-center"
      >
        {text}
      </div>
    </div>
  );
};

const getPreviewStyles = (attributes: SavedStyleTextAttributes) => ({
  fontSize: attributes.fontSize,
  fontWeight: attributes.fontWeight,
  fontFamily: attributes.fontFamily ?? undefined,
  lineHeight: attributes.lineHeight,
  textDecoration:
    attributes.textDecoration &&
    ["line-through", "underline"].includes(attributes.textDecoration)
      ? attributes.textDecoration
      : undefined,
  fontStyle:
    attributes.textDecoration && ["italic"].includes(attributes.textDecoration)
      ? "italic"
      : undefined,
  letterSpacing: attributes.letterSpacing,
  textAlign: attributes.textAlign,
  color: attributes.color,
  textTransform: attributes.textTransform,
  textShadow: attributes.textShadow,
  "-webkit-text-stroke": attributes.textStroke,
});

const getFontFamilyStyleHTML = (fontFamily: string) => {
  const isFontFamilyGoogleFont = GOOGLE_FONTS.includes(fontFamily);
  if (isFontFamilyGoogleFont) {
    return `@import url(${buildGoogleFontApiUrl([fontFamily])})`;
  }
  return `@font-face { font-family: "${fontFamily}"; src: url("${fontFamily}") format("${fontFamily}") }`;
};

export default ReploPreviewTextSavedStyle;
