import type { PreviewableProperty } from "schemas/preview";

// NOTE (Sebas, 2024-04-23): If you need to add a new previewable property
// make sure to add it to this list as well.
export const PreviewableProperties: PreviewableProperty[] = [
  "fontSize",
  "lineHeight",
  "color",
  "backgroundColor",
  "letterSpacing",
  "topOffset",
  "bottomOffset",
  "leftOffset",
  "rightOffset",
  "width",
  "height",
  "minHeight",
  "maxHeight",
  "minWidth",
  "maxWidth",
  "opacity",
  "transform",
  "transformOrigin",
  "borderWidth",
  "borderBottomWidth",
  "borderLeftWidth",
  "borderRightWidth",
  "borderTopWidth",
  "borderRadius",
  "borderTopLeftRadius",
  "borderTopRightRadius",
  "borderBottomLeftRadius",
  "borderBottomRightRadius",
  "flexGap",
  "boxShadow",
  "objectPosition",
  "backgroundSize",
  "backgroundPositionX",
  "backgroundPositionY",
  "zIndex",
  "rowGap",
  "columnGap",
  "marginBottom",
  "marginLeft",
  "marginRight",
  "marginTop",
  "paddingBottom",
  "paddingLeft",
  "paddingRight",
  "paddingTop",
  "textShadow",
  "__textStroke",
  "__numberOfColumns",
];

/**
 * Get the css className to apply to the node of a component which is previewing
 * a specific value.
 * @param property Value to preview
 */
export const getPreviewPropertyClassName = (property: PreviewableProperty) => {
  return `alchemy-preview-${property}`;
};

export const previewablePropertyDataMapping: Record<
  PreviewableProperty,
  { cssVariable: string }
> = {
  __textStroke: {
    cssVariable: "-webkit-text-stroke",
  },
  fontSize: { cssVariable: "font-size" },
  lineHeight: { cssVariable: "line-height" },
  color: { cssVariable: "color" },
  backgroundColor: { cssVariable: "background-color" },
  letterSpacing: { cssVariable: "letter-spacing" },
  topOffset: {
    cssVariable: "top",
  },
  bottomOffset: {
    cssVariable: "bottom",
  },
  leftOffset: {
    cssVariable: "left",
  },
  rightOffset: {
    cssVariable: "right",
  },
  width: {
    cssVariable: "width",
  },
  height: {
    cssVariable: "height",
  },
  minHeight: {
    cssVariable: "min-height",
  },
  maxHeight: {
    cssVariable: "max-height",
  },
  minWidth: {
    cssVariable: "min-width",
  },
  maxWidth: {
    cssVariable: "max-width",
  },
  opacity: {
    cssVariable: "opacity",
  },
  transform: {
    cssVariable: "transform",
  },
  transformOrigin: {
    cssVariable: "transform-origin",
  },
  borderWidth: {
    cssVariable: "border-width",
  },
  borderBottomWidth: {
    cssVariable: "border-bottom-width",
  },
  borderLeftWidth: {
    cssVariable: "border-left-width",
  },
  borderRightWidth: {
    cssVariable: "border-right-width",
  },
  borderTopWidth: {
    cssVariable: "border-top-width",
  },
  borderRadius: {
    cssVariable: "border-radius",
  },
  borderTopLeftRadius: {
    cssVariable: "border-top-left-radius",
  },
  borderTopRightRadius: {
    cssVariable: "border-top-right-radius",
  },
  borderBottomLeftRadius: {
    cssVariable: "border-bottom-left-radius",
  },
  borderBottomRightRadius: {
    cssVariable: "border-bottom-right-radius",
  },
  flexGap: {
    cssVariable: "gap",
  },
  boxShadow: {
    cssVariable: "box-shadow",
  },
  objectPosition: {
    cssVariable: "object-position",
  },
  backgroundPositionX: {
    cssVariable: "background-position-x",
  },
  backgroundPositionY: {
    cssVariable: "background-position-y",
  },
  backgroundSize: {
    cssVariable: "background-size",
  },
  zIndex: {
    cssVariable: "z-index",
  },
  columnGap: {
    cssVariable: "column-gap",
  },
  rowGap: {
    cssVariable: "row-gap",
  },
  marginBottom: {
    cssVariable: "margin-bottom",
  },
  marginLeft: {
    cssVariable: "margin-left",
  },
  marginRight: {
    cssVariable: "margin-right",
  },
  marginTop: {
    cssVariable: "margin-top",
  },
  paddingBottom: {
    cssVariable: "padding-bottom",
  },
  paddingLeft: {
    cssVariable: "padding-left",
  },
  paddingRight: {
    cssVariable: "padding-right",
  },
  paddingTop: {
    cssVariable: "padding-top",
  },
  textShadow: {
    cssVariable: "text-shadow",
  },
  __numberOfColumns: {
    cssVariable: "grid-template-columns",
  },
};

/**
 * Styles to inject into the canvas such that previews work when their
 * previewPropertyClassNames are applied. This function generates CSS which overrides
 * the regular component-rendered styles and our CSS reset.
 */
const getPreviewEditorStyles = () => {
  return PreviewableProperties.map((property) => {
    return `.alchemy__element .${getPreviewPropertyClassName(property)},
         #alchemy-modal-mount-point .${getPreviewPropertyClassName(property)},
         #alchemy-modal-body-child .${getPreviewPropertyClassName(property)} {
           ${previewablePropertyDataMapping[property].cssVariable}: var(--${
             previewablePropertyDataMapping[property].cssVariable
           }) !important
         }`;
  }).join("\n");
};

/**
 * Styles to inject for when this element is rendered in the editor.
 *
 * Note (Noah, 2021-11-01): The .alchemy__element etc is important because it
 * provides extra CSS specificity necessary to override media queries for device
 * sizes. Without it, the padding indicators don't work on non-desktop device
 * sizes.
 *
 * Note (Noah, 2024-07-24): The picture[data-rid] selector is necessary to allow
 * the user to _always_ be able to select images in edit mode, since the runtime
 * styles sometimes add pointer-events none (e.g. so that dropdown arrow images don't
 * block clicks on variant dropdowns, etc). It's more performant to do this with
 * a CSS rule, rather than having to rerender all images components whenever
 * preview mode changes.
 *
 * Note (Noah, 2024-07-24): The [data-replo-marquee-track] selector is necessary to
 * make sure we don't run the marquee animation in edit mode. More performant to do
 * this in CSS than to rerender the marquee component.
 */
export const editorStyles = `
 .replo-editor-edit-mode [data-rid] {
   transition: none !important;
 }

 .replo-editor-edit-mode picture[data-rid] {
   pointer-events: auto !important;
 }

 .replo-editor-edit-mode [data-replo-marquee-track] {
   animation-name: none !important;
 }

 .alchemy__element .indicator-width,
 #alchemy-modal-mount-point .indicator-width,
 #alchemy-modal-body-child .indicator-width {
   width: var(--width) !important;
   height: var(--height) !important;
   align-self: var(--align-self) !important;
   flex-grow: var(--flex-grow) !important;
   flex-basis: var(--flex-basis) !important;
   flex-shrink: var(--flex-shrink) !important;
 }

 .alchemy__element .indicator-height,
 #alchemy-modal-mount-point .indicator-height,
 #alchemy-modal-body-child .indicator-height {
   height: var(--height) !important;
   width: var(--width) !important;
   align-self: var(--align-self) !important;
 }

 ${getPreviewEditorStyles()}
`;
