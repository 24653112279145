import startCase from "lodash-es/startCase";

export default function getFontFamilyNameFromSrcUrl(url: string): string {
  const patterns = {
    files: /\/files\/([^.]+)/,
    fonts: /\/fonts\/([^/]+)\//,
    assets: /\/assets\/([^.]+)/,
  };

  for (const pattern of Object.values(patterns)) {
    const match = url.match(pattern)?.[1];
    if (match) {
      return startCase(match.replace(/_/g, " "));
    }
  }

  return "ShopifyFont";
}
