import type { CodeEditorModalProps } from "@editor/components/AppModalTypes";
import type { OnMount } from "@monaco-editor/react";

import * as React from "react";

import Banner from "@editor/components/common/designSystem/Banner";
import Modal from "@editor/components/common/designSystem/Modal";
import { useModal } from "@editor/hooks/useModal";
import { docs } from "@editor/utils/docs";
import { validateLiquidSyntax } from "@editor/utils/liquidValidator";

import Editor, { useMonaco } from "@monaco-editor/react";
import Button from "@replo/design-system/components/button";
import debounce from "lodash-es/debounce";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type MonacoEditor = Parameters<OnMount>[0];
export type CodeEditorLanguage = "html" | "css" | "javascript" | "liquid";

const getDisplayName = (language: CodeEditorLanguage) => {
  return exhaustiveSwitch({ type: language })({
    html: "HTML",
    css: "CSS",
    javascript: "Javascript",
    liquid: "Liquid",
  });
};

const incorrectHtmlTagsRegex = /<\/?(body|html|head).*>/i;

type ErrorResult = {
  errors: React.ReactNode[];
  lineWithError: number | null;
};

const getErrorsFromContent = (
  value: string | null,
  language: CodeEditorLanguage,
): ErrorResult => {
  const errors: React.ReactNode[] = [];
  let lineWithError: number | null = null;

  if (language === "liquid" && value) {
    const liquidError = validateLiquidSyntax(value);
    if (liquidError) {
      errors.push(`${liquidError.message}`);
      lineWithError = liquidError.line ?? null;
    }
  }

  if (
    ["html", "liquid"].includes(language) &&
    value &&
    incorrectHtmlTagsRegex.test(value)
  ) {
    errors.push(
      "Incorrect HTML tags detected. Replo embeds custom HTML directly into the page, so <html>, <head>, or <body> tags will not work.",
    );
  }

  if (value?.includes("[data-rid=")) {
    errors.push(
      <span>
        {
          "Query selector for [data-rid] detected. The values for data-rid may change when you copy/paste components, so you should not rely on them in CSS or Javascript. You may want to use "
        }
        <a
          href={docs.dataAttributes}
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          Data Attributes
        </a>
        {" instead."}
      </span>,
    );
  }

  return { errors, lineWithError };
};

export const CodeEditorModal = (props: CodeEditorModalProps) => {
  const { closeModal } = useModal();
  const [errors, setErrors] = React.useState<React.ReactNode[]>(
    getErrorsFromContent(props.value, props.language).errors,
  );
  const monaco = useMonaco();

  const editor = React.useRef<MonacoEditor>();

  const handleMount: OnMount = (e) => {
    editor.current = e;
  };

  const handleSave = () => {
    const newValue = editor.current?.getValue();
    props.onChange(newValue ?? "");
    closeModal({ type: "codeEditorModal" });
  };

  const _handleContentChange = React.useCallback(
    (value: string | undefined) => {
      if (!value) {
        return;
      }
      const { errors, lineWithError } = getErrorsFromContent(
        value,
        props.language,
      );

      setErrors(errors);

      if (!editor.current || !monaco) {
        return;
      }
      const model = editor.current.getModel();
      if (!model) {
        return;
      }

      if (lineWithError) {
        const markers = [
          {
            severity: monaco.MarkerSeverity.Error,
            message: errors[0]?.toString() || "Syntax error",
            startLineNumber: lineWithError,
            startColumn: 1,
            endLineNumber: lineWithError,
            endColumn: 1000,
          },
        ];

        monaco.editor.setModelMarkers(model, props.language, markers);
      } else {
        monaco.editor.setModelMarkers(model, props.language, []);
      }
    },
    [monaco, props.language],
  );

  const handleContentChange = React.useMemo(
    () =>
      debounce(_handleContentChange, 300, { leading: true, trailing: true }),
    [_handleContentChange],
  );

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => closeModal({ type: "codeEditorModal" })}
      closesOnOverlayClick={false}
      style={{ width: "50vw" }}
    >
      <div className="flex flex-col gap-2">
        <h2>
          <span>{getDisplayName(props.language)}</span> Editor
        </h2>
        <div className="py-5">
          <Editor
            width="100%"
            height="50vh"
            defaultLanguage={props.language}
            defaultValue={props.value ?? undefined}
            onMount={handleMount}
            options={{
              minimap: { enabled: false },
            }}
            onChange={handleContentChange}
          />
        </div>
        {errors.length > 0 && (
          <Banner
            isDismissable={false}
            backgroundColor="bg-red-300"
            className="rounded px-4 py-2 text-xs text-default"
          >
            <div className="flex flex-col gap-2">
              <p>
                Replo detected issues with this custom code, which may cause
                problems after this page is published. If you see this page not
                working correctly, please reach out to support@replo.app with
                any questions.
              </p>
              <ul>
                {errors.map((error) => (
                  <li
                    key="error"
                    className="ml-4"
                    style={{ listStyleType: "circle" }}
                  >
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          </Banner>
        )}
        <div className="flex justify-end">
          <Button
            variant="primary"
            onClick={handleSave}
            size="base"
            disabled={errors.length > 0}
          >
            Save Code
          </Button>
        </div>
      </div>
    </Modal>
  );
};
