import type { CreateOrConfigureDataCollectionModalProps } from "@editor/components/AppModalTypes";
import type { DataTableColumn } from "replo-runtime/shared/DataTable";

import * as React from "react";

import Input from "@common/designSystem/Input";
import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import { dataCollectionColumnTypeToEditorData } from "@components/editor/page/data-tables-page/EditorData";
import NewFieldModal from "@components/editor/page/data-tables-page/NewFieldModal";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";
import {
  mappingDataTables,
  selectDraftDataTable,
  updateDraftDataTable,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import {
  getPluralOrCollectionName,
  getSingularOrCollectionName,
} from "@utils/dataTable";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { BsTrash } from "react-icons/bs";

export const CreateOrConfigureDataCollectionModal = (
  props: CreateOrConfigureDataCollectionModalProps,
) => {
  const dataTable = useEditorSelector(selectDraftDataTable);
  const [isCollectionModal, setIsCollectionModal] = React.useState(true);
  const [dataCollectionName, setDataCollectionName] = React.useState(
    dataTable?.name,
  );
  const [singularItemName, setSingularItemName] = React.useState(
    dataTable?.singularItemName,
  );
  const [pluralItemName, setPluralItemName] = React.useState(
    dataTable?.pluralItemName,
  );
  const [changedPluralName, setChangedPluralName] = React.useState(false);
  const dispatch = useEditorDispatch();
  const modal = useModal();

  const projectId = useCurrentProjectId();

  const { mutateAsync: updateOrCreateDataTable } =
    trpc.dataTable.updateOrCreate.useMutation({
      onMutate: (props) => {
        dispatch(updateDraftDataTable(props.dataTable));
      },
      onSuccess: (response) => {
        if (response.success) {
          dispatch(mappingDataTables());
        }
      },
    });
  const _renderTitle = () => {
    return (
      <div className="flex flex-row justify-between pb-2 text-base font-medium">
        {props.modalType === "configure"
          ? `Configure ${getSingularOrCollectionName(
              singularItemName!,
              dataCollectionName!,
            )}`
          : "New Collection"}
      </div>
    );
  };

  const _renderNewCollectionInputs = () => {
    return (
      <div className="mb-4 flex flex-col gap-3">
        <LabeledControl label="Data Collection Name">
          <div className="flex flex-row" style={{ width: "30%" }}>
            <Input
              value={dataCollectionName}
              placeholder="Collection Name"
              onChange={(e) => setDataCollectionName(e.target.value)}
            />
          </div>
        </LabeledControl>
        <LabeledControl label="Singular Item Name">
          <div className="flex flex-row" style={{ width: "30%" }}>
            <Input
              value={singularItemName ?? undefined}
              placeholder="Singular Item Name"
              onChange={(e) => {
                setSingularItemName(e.target.value);
                if (props.modalType === "create" && !changedPluralName) {
                  setPluralItemName(e.target.value);
                }
              }}
            />
          </div>
        </LabeledControl>
        <LabeledControl label="Plural Item Name">
          <div className="flex flex-row" style={{ width: "30%" }}>
            <Input
              value={pluralItemName ?? undefined}
              placeholder="Plural Item Name"
              onChange={(e) => {
                setPluralItemName(e.target.value);
                setChangedPluralName(true);
              }}
            />
          </div>
        </LabeledControl>
      </div>
    );
  };

  const _renderAddFieldRow = (fromTable: boolean) => (
    <div
      className="mb-2 flex flex-row justify-between"
      style={{ alignItems: "center" }}
    >
      {!fromTable && (
        <div className="pb-0.5 text-lg text-slate-400">
          {`${getSingularOrCollectionName(
            singularItemName!,
            dataCollectionName!,
          )} Fields`}
        </div>
      )}
      <div className="pb-4 text-3xl">{}</div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setIsCollectionModal(false);
        }}
        className="self-start"
        variant="secondary"
      >
        Add Field
      </Button>
    </div>
  );

  const _renderColumnName = (column: DataTableColumn) => {
    if (!dataTable) {
      return null;
    }
    if (props.modalType === "configure") {
      return (
        <div className="w-40">
          <Input
            unsafe_className="bold"
            unsafe_style={{ wordWrap: "break-word" }}
            value={column.name}
            placeholder="Collection Name"
            onChange={(e) =>
              dispatch(
                updateDraftDataTable({
                  ...dataTable,
                  data: {
                    ...dataTable.data,
                    schema: dataTable.data.schema.map((schema) => {
                      if (schema.id === column.id) {
                        return { ...schema, name: e.target.value };
                      }
                      return schema;
                    }),
                  },
                }),
              )
            }
          />
        </div>
      );
    } else if (props.modalType === "create") {
      return (
        <h1
          className="bold text-base"
          style={{ width: "150px", wordWrap: "break-word" }}
        >
          {column.name}
        </h1>
      );
    }
    return null;
  };

  const _renderDataTable = () => {
    if (!dataTable) {
      return null;
    }
    return (
      <div
        className="grid-cols-1"
        style={{
          height: 300,
          maxHeight: "50vh",
          overflowY: "auto",
        }}
      >
        {Object.values(dataTable.data.schema).map(
          (column: DataTableColumn, index) => {
            return (
              <div
                key={column.id}
                className={classNames(
                  "cursor-pointer border-b-2 bg-white p-4 transition duration-200 ease-in-out hover:bg-gray-50",
                )}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  return e.stopPropagation();
                }}
              >
                <div
                  className="flex flex-row justify-between"
                  style={{ alignItems: "center" }}
                >
                  <div>{_renderColumnName(column)}</div>
                  <div>
                    <h1
                      className="text-base text-default"
                      style={{
                        width: "100px",
                        wordWrap: "break-word",
                        textTransform: "capitalize",
                      }}
                    >
                      {
                        dataCollectionColumnTypeToEditorData[column.type]
                          .displayName
                      }
                    </h1>
                  </div>
                  <div>
                    <BsTrash
                      size="20px"
                      color="gray"
                      onClick={() => {
                        const newSchema = [...dataTable.data.schema];
                        newSchema.splice(index, 1);
                        dispatch(
                          updateDraftDataTable({
                            ...dataTable,
                            data: {
                              ...dataTable.data,
                              schema: newSchema,
                            },
                          }),
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          },
        )}
      </div>
    );
  };

  const _renderEmptyDataTable = () => {
    return (
      <div
        className="border-top border-dark flex flex-col items-center justify-start"
        style={{
          height: 300,
          maxHeight: "50vh",
        }}
      >
        <div className="flex flex-col items-center justify-center pt-8">
          <p className="mb-2 text-default">
            {`Define the fields that every ${getSingularOrCollectionName(
              singularItemName!,
              dataCollectionName!,
            )} should have here.`}
          </p>
          {_renderAddFieldRow(true)}
        </div>
      </div>
    );
  };

  const _renderDataTableList = () => {
    if (!dataTable) {
      return null;
    }
    return (
      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-between p-2">
          <div
            className="w-[150px] text-base"
            style={{ wordWrap: "break-word" }}
          >
            Field Name
          </div>
          <div
            className="w-[100px] text-base capitalize"
            style={{
              wordWrap: "break-word",
            }}
          >
            Type
          </div>
          <div style={{ width: "20px" }} />
        </div>
        <div className="border-t-2 border-gray-900" />
        {Object.values(dataTable.data.schema).length > 0
          ? _renderDataTable()
          : _renderEmptyDataTable()}
      </div>
    );
  };

  const _renderDeleteSaveCollectionButtons = () => {
    if (!dataTable) {
      return null;
    }

    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          void updateOrCreateDataTable({
            projectId: projectId ?? "",
            dataTable: {
              ...dataTable,
              name: dataCollectionName!,
              singularItemName: singularItemName ?? null,
              pluralItemName: pluralItemName ?? null,
            },
          });
          if (props.modalType === "create") {
            modal.openModal({
              type: "dataCollectionEditModal",
            });
          }
          modal.closeModal({
            type: "createOrConfigureDataCollectionModal",
          });
        }}
        variant="primary"
      >{`Save ${getPluralOrCollectionName(
        pluralItemName!,
        dataCollectionName!,
      )}`}</Button>
    );
  };

  const _renderNewFieldModal = () => {
    return (
      <NewFieldModal
        // @ts-ignore
        onClose={() => {
          setIsCollectionModal(true);
        }}
      />
    );
  };

  const _renderCollectionModal = () => {
    return (
      <ModalLayout
        height={600}
        mainContentClassnames="overflow-x-visible overflow-y-scroll"
        mainContent={() => (
          <div className="relative flex flex-col justify-between bg-white w-full">
            <div className="flex flex-col">
              <div className="grid grid-cols-12 gap-4 pt-2">
                <div className="col-span-12">
                  {_renderTitle()}
                  {_renderNewCollectionInputs()}
                  {_renderAddFieldRow(false)}
                  {_renderDataTableList()}
                </div>
              </div>
            </div>
          </div>
        )}
        footerContent={() => _renderDeleteSaveCollectionButtons()}
      />
    );
  };

  return (
    <>
      <Modal
        isOpen={true}
        includesCloseIcon
        onRequestClose={() => {
          modal.closeModal({
            type: "createOrConfigureDataCollectionModal",
          });
        }}
        className="w-auto"
      >
        {isCollectionModal ? _renderCollectionModal() : _renderNewFieldModal()}
      </Modal>
    </>
  );
};
