import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const SHOPIFY_PRODUCT_RATING_WIDGET_LIQUID_SOURCE =
  '<span class="shopify-product-reviews-badge" data-id="{{ product.id }}"></span>';

function ShopifyProductRatingWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: SHOPIFY_PRODUCT_RATING_WIDGET_LIQUID_SOURCE,
      context: props.context,
    },
  );
  return (
    <SharedShopifyLiquid
      componentId={component.id}
      componentAttributes={componentAttributes}
      liquidSource={liquidSourceWithProductAssignment}
      placeholderTitle="Shopify Product Rating"
      placeholderType="reviewStars"
      isLiquidSupported
      repeatedIndexPath={context.repeatedIndexPath}
      forceEditorPlaceholder={true}
    />
  );
}

export default ShopifyProductRatingWidget;
