import type { ButtonProps } from "@replo/design-system/components/button";
import type { ButtonSize } from "@replo/design-system/components/button/button-shared";

import * as React from "react";

import { useExperimentApi } from "@components/projectDashboard/experiments/common";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { useSubscriptionTier } from "@editor/hooks/subscription";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { routes } from "@editor/utils/router";

import { buildCreateExperimentArgs } from "@/features/experiments/utils";
import Button from "@replo/design-system/components/button";
import { generatePath, useNavigate } from "react-router-dom";

type CreateExperimentButtonProps = {
  size?: ButtonSize;
};
export const CreateExperimentButton: React.FC<CreateExperimentButtonProps> = ({
  size = "base",
}) => {
  const commonProps: ButtonProps = {
    size: "base",
    variant: "primary",
    className:
      "bg-blue-600 text-white py-2 px-2.5 transition-colors font-medium",
  };

  const workspaceId = useCurrentWorkspaceId() ?? undefined;

  const {
    list: { data },
    create: { mutateAsync: create, isPending: isCreatingExperiment },
  } = useExperimentApi({ workspaceId, projectId: null });

  const logEvent = useLogAnalytics();
  const errorToast = useErrorToast();
  const navigate = useNavigate();
  const subscriptionTier = useSubscriptionTier();

  const handleCreateNewExperiment = async () => {
    const experiments = data?.experiments;

    if (!workspaceId || !experiments) {
      errorToast(
        "Failed Creating Experiment",
        "Please try again, or contact support@replo.app for help.",
        {
          eventName: "error.experiment.create",
          eventProperties: {
            error: "No workspace id or experiments",
            workspaceId,
            experiments,
          },
        },
      );
    } else {
      const experimentData = buildCreateExperimentArgs(
        workspaceId,
        experiments,
      );
      await create(experimentData);
      logEvent("experiment.create", { billingPlanTier: subscriptionTier });
      successToast("Experiment Created", "");
      const path = generatePath(routes.workspace.experiments.details, {
        workspaceId,
        experimentId: experimentData.id,
      });

      navigate(path, {
        state: { newExperiment: true },
      });
    }
  };

  return (
    <Button
      {...commonProps}
      size={size}
      onClick={() => void handleCreateNewExperiment()}
      isLoading={isCreatingExperiment}
    >
      Create New Experiment
    </Button>
  );
};
