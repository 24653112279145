/**
 * When linking to support docs from inside our app, this lets us keep our links
 * clean and in one place.
 */
export const docs = {
  home: "https://docs.replo.app/",
  abTesting: {
    main: "https://support.replo.app/hc/en-us/articles/20693018801549",
    analytics:
      "https://support.replo.app/hc/en-us/articles/20693018801549#h_01HDJ5HA9VHCF2WMX46Q7SHHTG",
    workspaceCustomDomains:
      "https://support.replo.app/articles/2069301880-experiments-(a/b-testing)#how-to-use-replo-experiments-12",
  },
  klaviyo: "https://support.replo.app/hc/en-us/articles/17512842513165-Klaviyo",
  templateDefaults:
    "https://support.replo.app/hc/en-us/articles/20118402411021",
  sellingPlans: {
    thirdPartyAtc:
      "https://support.replo.app/articles/1716416054-subscriptions-and-selling-plans#allowing-third-party-apps-to-override-selling-plan-selections-16",
  },
  editorContentTypes: {
    pages: "https://docs.replo.app/docs/content-types/pages",
    shopifySections:
      "https://docs.replo.app/docs/content-types/shopify-sections",
    productTemplates:
      "https://support.replo.app/hc/en-us/articles/17881635030797-Product-Templates",
  },
  dynamicData:
    "https://support.replo.app/hc/en-us/articles/17131155971213-Dynamic-Data",
  permissions:
    "https://support.replo.app/hc/en-us/articles/25180416846861-Permissions-In-Replo",
  swatches: "https://docs.replo.app/docs/building/swatches",
  faq: "https://docs.replo.app/docs/getting-started/faq",
  shopifyMaxProductsOnPage:
    "https://support.replo.app/articles/8672651932-how-many-shopify-products-can-be-on-one-page",
  multiCanvas:
    "https://support.replo.app/articles/5169277701-multi-device-editing",
  billingPlans:
    "https://support.replo.app/articles/2238970734-what-replo-plan-is-right-for-me",
  dataAttributes:
    "https://support.replo.app/articles/1799213363-custom-development-with-replo#adding-data-attributes-to-replo-dom-nodes-18",
  errors: {
    wrongJsonExtension:
      "https://support.replo.app/articles/9676650469-i-see-an-error-that-says-the-template-for-this-content-in-shopify-has-been-manually-changed-from-liquid-to-json",
    imageSourceTooLong:
      "https://support.replo.app/articles/1168122633-why-am-i-getting-an-image-source-error",
    chunkingError:
      "https://support.replo.app/articles/4458256377-why-am-i-getting-a-liquid-content-present-that-is-too-large-error",
    indexBackupError:
      "https://support.replo.app/articles/5206098091-why-am-i-getting-a-failed-to-backup-homepage-template-error",
  },
  leftBar: {
    savedStyles: "https://support.replo.app/articles/6084305582-saved-styles",
    insert: "https://support.replo.app/collections/8618926849-components",
    elements: "https://support.replo.app/collections/9149515681-content-types",
  },
  modifiers: {
    text: "https://support.replo.app/articles/6715966694-text-modifier",
  },
  multiSelect: {
    multipleStates:
      "https://support.replo.app/articles/3149142653-selecting-multiple-components-beta#editing-multiple-states-3",
  },
  savedStyles: {
    main: "https://support.replo.app/articles/6084305582-saved-styles",
    text: "https://support.replo.app/articles/6084305582-saved-styles#text-styles-3",
    color:
      "https://support.replo.app/articles/6084305582-saved-styles#color-styles-6",
  },
  ai: "https://www.replo.app/product/content-ai",
};
