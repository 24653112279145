import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  FeatureFlagsContext,
  RenderEnvironmentContext,
  useRuntimeContext,
} from "replo-runtime/shared/runtime-context";
import { processCustomHtmlForEditor } from "replo-runtime/store/utils/customCode";

import { RenderComponentPlaceholder } from "../RenderComponentPlaceholder";

const RawHTMLContent: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
}) => {
  let htmlContent = component.props._htmlContent;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const { featureFlags } = useRuntimeContext(FeatureFlagsContext);
  if (htmlContent && isEditorApp && featureFlags.noMirror) {
    htmlContent = processCustomHtmlForEditor(htmlContent);
  }
  return htmlContent ? (
    <div
      {...componentAttributes}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  ) : (
    <div {...componentAttributes}>
      <RenderComponentPlaceholder
        title={
          "Once you set this component's HTML content, the code will be embedded here."
        }
      />
    </div>
  );
};

export default RawHTMLContent;
