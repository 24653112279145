import type { BoxSizes } from "@editor/types/component-template";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type { Exception } from "@sentry/react";
import type {
  NumericBounds,
  Position,
  WidthOrHeight,
} from "replo-runtime/shared/types";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { BoxSide } from "replo-utils/lib/types";
import type { Component, ReploComponentType } from "schemas/component";
import type { PersistedCanvasState } from "schemas/generated/canvas";
import type { RuntimeStyleAttribute } from "schemas/styleAttribute";

import {
  ReploStorageQuotaExceededError,
  setStorageItem,
} from "@editor/hooks/useLocalStorage";
import { trackError } from "@editor/infra/analytics";

import { parseUnit } from "replo-runtime/shared/utils/units";
import { clamp, isCloseTo, parseFloat } from "replo-utils/lib/math";
import { persistedCanvasStateSchema } from "schemas/canvas";

import { CANVAS_DATA, DEFAULT_UNIT_MAP, PRESETS } from "./canvas-constants";

export const ALLOWED_RANGE = {
  minValue: 240,
  maxValue: 10_000,
};

const canvasList = Object.values(CANVAS_DATA);

export function getCanvasData(frameWidth: number) {
  const width = clamp(
    frameWidth,
    ALLOWED_RANGE.minValue,
    ALLOWED_RANGE.maxValue,
  );
  const canvasData = canvasList.find((data) => {
    return data.range[0] <= width && width <= data.range[1];
  });
  return canvasData;
}

export function getPresets(canvas?: EditorCanvas | undefined) {
  const presets = canvas
    ? PRESETS[canvas]
    : Object.values(PRESETS).flatMap((PRESET_LIST) =>
        PRESET_LIST.map((value) => value),
      );

  return presets.map((preset) => ({
    ...preset,
    get canvas() {
      const canvasData = getCanvasData(this.value);
      if (!canvasData) {
        throw new Error(`Invalid frame width: ${this.value}`);
      }
      return canvasData.canvasName;
    },
  }));
}

function getLocalStorageKey(id: string) {
  return `replo:${id}:canvas-config`;
}

export function getCanvasLocalStorageState(id: string) {
  const key = getLocalStorageKey(id);
  const state = localStorage.getItem(key);
  return state ? deserializeCanvasState(state) : null;
}

export function setCanvasLocalStorageState(
  id: string,
  state: PersistedCanvasState,
) {
  const key = getLocalStorageKey(id);
  try {
    setStorageItem(key, serializeCanvasState(state));
  } catch (error) {
    if (error instanceof ReploStorageQuotaExceededError) {
      // Note (Noah, 2024-07-09): If the local storage call fails, just ignore,
      // it's fine to just have the state not persisted in this case
      return;
    }
    throw error;
  }
}

export function serializeCanvasState(state: PersistedCanvasState) {
  const { mobile, tablet, desktop } = state;

  // NOTE (Chance 2024-06-18): Explicit here because we don't want to try
  // serializing possibly un-seriazable values. TS won't catch us if the object
  // passed has extra properties.
  return JSON.stringify({
    desktop: {
      canvasWidth: desktop.canvasWidth,
      isVisible: desktop.isVisible,
    },
    tablet: {
      canvasWidth: tablet.canvasWidth,
      isVisible: tablet.isVisible,
    },
    mobile: {
      canvasWidth: mobile.canvasWidth,
      isVisible: mobile.isVisible,
    },
  } satisfies PersistedCanvasState);
}

export function deserializeCanvasState(state: string) {
  try {
    const parsed = JSON.parse(state);
    return persistedCanvasStateSchema.parse(parsed);
  } catch {
    return null;
  }
}

export function clientPosToTranslatedPos(
  element: Element,
  { x, y }: Position,
  translation: Position,
) {
  const origin = translatedOrigin(element, translation);
  return {
    x: x - origin.x,
    y: y - origin.y,
  };
}

function translatedOrigin(element: Element, translation: Position) {
  const clientOffset = element.getBoundingClientRect();
  return {
    x: clientOffset.left + translation.x,
    y: clientOffset.top + translation.y,
  };
}

/**
 * Set state to scale the new scale around a focal point.
 *
 * NOTE (Noah, 2021-07-16): I'm not good enough at linear algebra to implement
 * this myself - most of this implementation is taken from
 * https://github.com/strateos/react-map-interaction/blob/55b75c806d794cbf3131d00f4ecc205b391b4400/src/MapInteraction.jsx#L218
 */
export function scaleFromPoint(
  oldScale: number,
  newScale: number,
  oldX: number,
  oldY: number,
  focalPt: Position,
) {
  const { translation, scale } = {
    translation: { x: oldX, y: oldY },
    scale: oldScale,
  };
  const scaleRatio = newScale / (scale != 0 ? scale : 1);

  const focalPtDelta = {
    x: coordChange(focalPt.x, scaleRatio),
    y: coordChange(focalPt.y, scaleRatio),
  };

  const newTranslation = {
    x: translation.x - focalPtDelta.x,
    y: translation.y - focalPtDelta.y,
  };

  return {
    deltaX: newTranslation.x,
    deltaY: newTranslation.y,
  };
}

function coordChange(coordinate: number, scaleRatio: number) {
  return scaleRatio * coordinate - coordinate;
}

export function isVerticalSide(
  orientation: string,
): orientation is "top" | "bottom" {
  return orientation === "top" || orientation === "bottom";
}

export function isTopLeftSide(
  orientation: string,
): orientation is "left" | "top" {
  return orientation === "top" || orientation === "left";
}

export function getUpdatedDimensionValue(
  deltaX: number,
  deltaY: number,
  orientation: BoxSide,
  modifiedOrientation: WidthOrHeight,
  boxSizes: BoxSizes,
  dynamicDragValues: DynamicIndicatorDragValues,
  lastSignedValue: number,
  isParentVerticalStack: boolean,
) {
  const { paddings, borders, margins, bounds } = boxSizes;
  let updatedValue = null;
  let signedValue = null;
  let updatedBounds = bounds;
  let currentValue = null;
  let alignSelf = null;
  const {
    valueBeforeDrag,
    parentHasDefinedWidth,
    parentHasDefinedHeight,
    parentContentWidth,
    parentContentHeight,
  } = dynamicDragValues;

  updatedBounds = getUpdatedBounds(
    deltaX,
    deltaY,
    lastSignedValue,
    orientation,
    modifiedOrientation,
    bounds,
  );
  currentValue = bounds[modifiedOrientation];
  updatedValue = updatedBounds[modifiedOrientation];
  signedValue = updatedValue;

  const paddingAndBorderHorizontal =
    parseFloat(borders.right) +
    parseFloat(borders.left) +
    parseFloat(paddings.left) +
    parseFloat(paddings.right);

  const paddingAndBorderVertical =
    parseFloat(borders.top) +
    parseFloat(borders.bottom) +
    parseFloat(paddings.top) +
    parseFloat(paddings.bottom);

  const minLimitForWidthHeight =
    modifiedOrientation === "width"
      ? paddingAndBorderHorizontal
      : paddingAndBorderVertical;

  if (parseFloat(updatedValue) < minLimitForWidthHeight) {
    updatedBounds[modifiedOrientation] = minLimitForWidthHeight;
    updatedValue = `${minLimitForWidthHeight}px`;
  }

  const horizontalMargin = parseFloat(margins.left) + parseFloat(margins.right);

  const verticalMargin = parseFloat(margins.top) + parseFloat(margins.bottom);

  if (
    modifiedOrientation === "width" &&
    parentHasDefinedWidth &&
    (isLessThanOrCloseTo(
      valueBeforeDrag.value,
      parentContentWidth - horizontalMargin,
    ) ||
      isLessThanOrCloseTo(
        parseFloat(currentValue) + horizontalMargin,
        parentContentWidth,
      )) &&
    parseFloat(updatedValue) + horizontalMargin > parentContentWidth
  ) {
    updatedBounds[modifiedOrientation] = parentContentWidth - horizontalMargin;
    updatedValue = `${parentContentWidth - horizontalMargin}px`;
    if (isParentVerticalStack) {
      alignSelf = "stretch";
    }
  }

  if (
    parentHasDefinedHeight &&
    ["top", "bottom"].includes(modifiedOrientation) &&
    (isGreaterThanOrCloseTo(valueBeforeDrag.verticalAvailableSpace, 0) ||
      isLessThanOrCloseTo(
        parseFloat(currentValue) - valueBeforeDrag.value,
        valueBeforeDrag.verticalAvailableSpace,
      )) &&
    isGreaterThanOrCloseTo(
      parseFloat(updatedValue) - valueBeforeDrag.value,

      valueBeforeDrag.verticalAvailableSpace,
    )
  ) {
    updatedValue =
      valueBeforeDrag.value + valueBeforeDrag.verticalAvailableSpace;
  }

  if (
    modifiedOrientation === "height" &&
    parentHasDefinedHeight &&
    isGreaterThanOrCloseTo(valueBeforeDrag.verticalAvailableSpace, 0) &&
    parseFloat(updatedValue) + verticalMargin > parentContentHeight
  ) {
    updatedBounds[modifiedOrientation] = parentContentHeight - verticalMargin;
    updatedValue = `${parentContentHeight - verticalMargin}px`;
    alignSelf = "stretch";
  }

  if (
    parentHasDefinedWidth &&
    ["left", "right"].includes(modifiedOrientation) &&
    (isGreaterThanOrCloseTo(valueBeforeDrag.horizontalAvailableSpace, 0) ||
      isLessThanOrCloseTo(
        parseFloat(currentValue) - valueBeforeDrag.value,
        valueBeforeDrag.horizontalAvailableSpace,
      )) &&
    isGreaterThanOrCloseTo(
      parseFloat(updatedValue) - valueBeforeDrag.value,
      valueBeforeDrag.horizontalAvailableSpace,
    )
  ) {
    updatedValue = `${valueBeforeDrag.value + valueBeforeDrag.horizontalAvailableSpace}px`;
  }

  return {
    updatedBounds,
    updatedValue: `${parseFloat(updatedValue)}px`,
    signedValue,
    alignSelf,
  };
}

export interface DynamicIndicatorDragValues {
  valueBeforeDrag: {
    horizontalAvailableSpace: number;
    verticalAvailableSpace: number;
    value: number;
  };
  parentHasDefinedWidth: boolean;
  parentHasDefinedHeight: boolean;
  parentContentWidth: number;
  parentContentHeight: number;
  componentHorizontalSpace: number | null;
  componentVerticalSpace: number | null;
}

function getUpdatedBounds(
  x: number,
  y: number,
  lastSignedValue: number | string,
  orientation: BoxSide,
  modifiedOrientation: WidthOrHeight,
  bounds: NumericBounds,
) {
  const updatedBounds: NumericBounds = { ...bounds };
  const sign = isTopLeftSide(orientation) ? -1 : 1;
  const isVertical = isVerticalSide(orientation);
  updatedBounds[modifiedOrientation] =
    parseFloat(lastSignedValue) + sign * (isVertical ? y : x);

  return updatedBounds;
}

function isGreaterThanOrCloseTo(
  myNumber: number,
  otherNumber: number,
  eps?: number,
) {
  return isCloseTo(myNumber, otherNumber, eps) || myNumber > otherNumber;
}

function isLessThanOrCloseTo(
  myNumber: number,
  otherNumber: number,
  eps?: number,
) {
  return isCloseTo(myNumber, otherNumber, eps) || myNumber < otherNumber;
}
export function convertToUnit(
  node: HTMLElement,
  field: string,
  value: number,
  getAttribute: GetAttributeFunction,
  component: Component,
  roundDown = false,
) {
  if (!node?.parentElement) {
    // TODO (Ovishek, 2022-07-21): When calling this function there should be a
    // draftComponent always and also draftComponentNode.parentElement should
    // also exists so this block of code should not be reachable but in any case
    // we don't have those b/c of some other bugs we should avoid breaking the
    // editor just by returning we should remove this after being sure this
    // block never reaches
    trackError(new Error("Non reachable code! canvas-utils.ts") as Exception);
    return `${value}px`;
  }
  const {
    width: parentWidth,
    paddingLeft,
    paddingRight,
    borderLeft,
    borderRight,
    paddingBottom,
    paddingTop,
    borderBottom,
    borderTop,
    height: parentHeight,
  } = getComputedStyle(node.parentElement);
  const defaultUnit =
    DEFAULT_UNIT_MAP[component.type as ReploComponentType] || "%";

  const unitObj = parseUnit(
    getAttribute(component, field as `style.${RuntimeStyleAttribute}`, null)
      .value || "0px",
    { value: "", unit: "" },
    "default",
    "px",
  );

  let previousUnit = unitObj.unit || defaultUnit;
  const { value: previousValue } = unitObj;

  if (previousValue === "auto" && isWidthOrHeight(field)) {
    previousUnit =
      parseUnit(
        getAttribute(component, field as `style.${RuntimeStyleAttribute}`, null)
          .value,
        { value: "auto", unit: defaultUnit },
        "default",
        defaultUnit,
      ).unit || defaultUnit;
  }

  const calculatedParentWidth =
    parseFloat(parentWidth) -
    parseFloat(paddingRight) -
    parseFloat(paddingLeft) -
    parseFloat(borderLeft) -
    parseFloat(borderRight);

  const calculatedParentHeight =
    parseFloat(parentHeight) -
    parseFloat(paddingBottom) -
    parseFloat(paddingTop) -
    parseFloat(borderTop) -
    parseFloat(borderBottom);

  const baseRatioValue =
    field === "height" ? calculatedParentHeight : calculatedParentWidth;

  if (previousUnit === "%") {
    let percentage = (parseFloat(value) / (baseRatioValue || 1)) * 100;

    if (roundDown) {
      percentage = Math.floor(percentage + 0.1);
    }

    return `${percentage}%`;
  }

  if (roundDown) {
    return `${Math.floor(value + 0.1)}px`;
  }

  return `${value}px`;
}

// Note (Evan, 2024-05-09): By taking the inverse of the scale, we get a scaling
// factor we can use to make controls stay the same size relative to the editor
// (i.e., NOT the canvas). Along with the maximum of 1, this means that when
// zooming in (from 100%), controls stay the same size, but when zooming out
// (from 100%) they shrink along with the canvas. I think this looks nice.
export function scalingFactor(scale: number) {
  // Note (Evan, 2024-05-09): Yay for edge cases
  if (scale === 0) {
    return 1;
  }
  return Math.min(1, 1 / scale);
} // #region Other functions

export function isWidthOrHeight(
  orientation: string,
): orientation is "width" | "height" {
  return orientation === "width" || orientation === "height";
}
