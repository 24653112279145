import type {
  ActionWithNullableValue,
  StoreProduct,
} from "replo-runtime/shared/types";
import type { ProductRef, ProductRefOrDynamic } from "schemas/product";

import * as React from "react";

import {
  productsApi,
  useGetShopifyProductsQuery,
} from "@editor/reducers/api-reducer";
import {
  selectAllProducts,
  selectProductData,
} from "@editor/reducers/commerce-reducer";
import {
  selectDataTablesMapping,
  selectIsShopifyIntegrationEnabled,
  selectProductsIdsFromDraftElement,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import useCurrentProjectId from "@hooks/useCurrentProjectId";

import unionWith from "lodash-es/unionWith";
import uniqBy from "lodash-es/uniqBy";
import { shallowEqual } from "react-redux";
import {
  getCurrentComponentContext,
  resolveContextValue,
} from "replo-runtime/shared/utils/context";
import { isContextRef } from "replo-runtime/store/ReploProduct";
import { fakeProductsMap } from "replo-runtime/store/utils/fakeProducts";
import { filterNulls } from "replo-utils/lib/array";
import {
  exhaustiveSwitch,
  isEmpty,
  isNotNullish,
  isNullish,
} from "replo-utils/lib/misc";

export const storeProductRequestLimits = {
  infiniteLoading: 5,
  defaultForAnyProducts: 5,
};

export const formatQueryWithIds = (ids: number[]) => {
  if (ids.length === 0) {
    return undefined;
  }

  return `(${ids.map((id) => `id:${id}`).join(" OR ")})`;
};

const resolveProductRef = (
  value: ProductRefOrDynamic | null | undefined,
  draftComponentId: string,
) => {
  if (isNullish(value)) {
    return null;
  }
  const productRef = resolveContextValue(
    value,
    // Note (Noah, 2022-08-04): The repeated index 0 here is okay because
    // if we're inside something dynamic over products, we'll only be
    // selecting a variant via dynamic data anyways. These productIds are
    // only used for selecting variants right now.
    getCurrentComponentContext(draftComponentId, 0),
  );

  return productRef;
};

const productIdsFromContextRef = (
  value: ProductRefOrDynamic | null | undefined,
  draftComponentId: string,
) => {
  const productRef =
    isNotNullish(value) && isContextRef(value)
      ? resolveProductRef(value, draftComponentId)
      : value;
  return productRef ? [Number(productRef.productId)] : [];
};

const productIdsFromPartialAction = (
  draftAction: ActionWithNullableValue,
  draftComponentId: string,
) => {
  return exhaustiveSwitch(draftAction)({
    addProductVariantToCart: (action) => {
      return productIdsFromContextRef(action.value?.product, draftComponentId);
    },
    updateCurrentProduct: (action) => {
      return productIdsFromContextRef(action.value, draftComponentId);
    },
    redirect: [],
    clearCart: [],
    redirectToProductPage: (action) => {
      const productRefFromAction =
        action.value && "product" in action.value
          ? action.value.product
          : action.value;
      return productIdsFromContextRef(productRefFromAction, draftComponentId);
    },
    applyDiscountCode: [],
    clearDiscountCode: [],
    updateCart: (action) => {
      return productIdsFromContextRef(action.value, draftComponentId);
    },
    multipleProductVariantsAddToCart: (action) => {
      const productIds = action.value?.flatMap(({ product }) => {
        return productIdsFromContextRef(product, draftComponentId);
      });
      return productIds ?? [];
    },
    close: [],
    closeModalComponent: [],
    scrollContainerLeft: [],
    scrollContainerRight: [],
    scrollToPreviousCarouselItem: [],
    scrollToNextCarouselItem: [],
    scrollToUrlHashmark: [],
    setCurrentCollectionSelection: [],
    scrollToSpecificCarouselItem: [],
    addTemporaryCartProductsToCart: [],
    openModal: [],
    openKlaviyoModal: [],
    removeVariantFromTemporaryCart: [],
    setSelectedListItem: [],
    toggleCollapsible: [],
    toggleDropdown: [],
    toggleMute: [],
    togglePlay: [],
    toggleFullScreen: [],
    setActiveAlchemyVariant: [],
    setDropdownItem: [],
    setActiveOptionValue: [],
    addVariantToTemporaryCart: (action) => {
      return productIdsFromContextRef(action.value?.product, draftComponentId);
    },
    decreaseVariantCountInTemporaryCart: [],
    activateTabId: [],
    setActiveTabIndex: [],
    executeJavascript: [],
    increaseProductQuantity: [],
    decreaseProductQuantity: [],
    setProductQuantity: [],
    phoneNumber: [],
    setActiveVariant: [],
    goToItem: [],
    goToNextItem: [],
    goToPrevItem: [],
    setActiveSellingPlan: [],
  });
};

type ProductDataMap = {
  cachedProducts: StoreProduct[];
  productIdsToBeRequested: number[];
};

const initialProductDataMap: ProductDataMap = {
  cachedProducts: [],
  productIdsToBeRequested: [],
};

const useCachedProducts = (ids?: (string | number)[]) => {
  const productDataFromStore: Record<string, StoreProduct> =
    useEditorSelector(selectProductData);

  // NOTE (Chance 2023-06-28): This is a quick and dirty workaround to avoid the
  // need to memoize the incoming array of product ids unless one of its values
  // actually changes.
  let idsStringified: string | null = null;
  try {
    if (ids) {
      idsStringified = JSON.stringify(ids);
    }
  } catch {}

  const productDataMap: ProductDataMap = React.useMemo(() => {
    const ids: number[] = idsStringified ? JSON.parse(idsStringified) : [];
    const uniqueIds = uniqBy(ids, Number);
    const productDataFromStoreWithFakeProducts: Record<string, StoreProduct> = {
      ...productDataFromStore,
      ...fakeProductsMap,
    };

    return {
      cachedProducts: filterNulls(
        uniqueIds.map((id) => productDataFromStoreWithFakeProducts[id]),
      ),
      productIdsToBeRequested: uniqueIds.filter(
        (id) =>
          !Object.keys(productDataFromStoreWithFakeProducts).includes(
            String(id),
          ),
      ),
    };
  }, [idsStringified, productDataFromStore]);

  if (isEmpty(ids)) {
    return initialProductDataMap;
  }

  return productDataMap;
};

/**
 * Hook which fetches the given products.
 *
 * @param productsIds The ids of the products to fetch. If undefined, the first
 * N default number of products will be fetched.
 * @param forceSkip If true, request will never be executed (useful for hooks
 * where you) only sometimes want to actually fetch.
 */

export function useFetchStoreProducts(
  ids?: (string | number)[],
  forceSkip = false,
) {
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const projectId = useCurrentProjectId();
  const { cachedProducts, productIdsToBeRequested } = useCachedProducts(ids);
  const shouldSkipRequest = ids && productIdsToBeRequested.length === 0;

  const formattedIds =
    productIdsToBeRequested.length > 0
      ? formatQueryWithIds(productIdsToBeRequested)
      : undefined;
  const { data, error, isLoading } = useGetShopifyProductsQuery(
    {
      storeId: projectId ?? "",
      pageSize: storeProductRequestLimits.defaultForAnyProducts,
      query: formattedIds ?? "",
    },
    {
      skip:
        !projectId ||
        forceSkip ||
        shouldSkipRequest ||
        !isShopifyIntegrationEnabled,
    },
  );

  const shouldMergeQueriedProductsWithCache = Boolean(
    ids && cachedProducts.length > 0,
  );

  const products = React.useMemo(() => {
    if (shouldSkipRequest) {
      return cachedProducts;
    }
    const productsFromQuery = data?.products ?? [];
    return shouldMergeQueriedProductsWithCache
      ? [...cachedProducts, ...productsFromQuery]
      : productsFromQuery;
  }, [
    cachedProducts,
    data?.products,
    shouldMergeQueriedProductsWithCache,
    shouldSkipRequest,
  ]);

  if (shouldSkipRequest) {
    return { products, isLoading: false, error: null };
  }

  return { products, isLoading, error };
}

/**
 * Hook which loads and returns a list of any of the products in the store.
 * Useful for when you just need to choose one default product.
 */
export function useAnyStoreProducts(config?: { forceSkip?: boolean }) {
  return useFetchStoreProducts(undefined, config?.forceSkip ?? false);
}

/**
 * Hook which loads and returns a list of all the products referenced by
 * components in the current draft element. Useful for passing into Repainter etc.
 */
export function useStoreProductsFromDraftElement(config?: {
  forceSkip?: boolean;
}) {
  const projectId = useCurrentProjectId();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const isLoadingQueryWithoutParams = useEditorSelector((state) => {
    const queryData = productsApi.endpoints.getShopifyProducts.select({
      storeId: projectId ?? "",
      query: "",
      pageSize: storeProductRequestLimits.defaultForAnyProducts,
    })(state);
    return queryData.isLoading || queryData.isUninitialized;
  });
  const productIds = useDraftElementProductsIds();
  const draftElementProductsQueryData = useFetchStoreProducts(
    productIds,
    isLoadingQueryWithoutParams ||
      (config?.forceSkip ?? false) ||
      productIds.length === 0,
  );

  return {
    ...draftElementProductsQueryData,
    // Note (Martin, 2023-11-10): consider both possible queries loading
    isLoading:
      isShopifyIntegrationEnabled &&
      (draftElementProductsQueryData.isLoading || isLoadingQueryWithoutParams),
  };
}

/**
 * Hook which loads and returns a list of any of the products in the store and
 * merge with the products in the draft element and all the products in the state.
 * Useful to grab as much products possible in the state for things like drop
 * component templates with specific products.
 */
export function useStoreAndDraftElementProducts(config?: {
  forceSkip?: boolean;
}) {
  const { products } = useAnyStoreProducts(config);
  const { products: productsFromDraftElement } =
    useStoreProductsFromDraftElement(config);
  const productsFromStore = useEditorSelector(selectAllProducts);

  return {
    products: unionWith(
      [...products, ...productsFromDraftElement, ...productsFromStore],
      (productOne, productTwo) => {
        return String(productOne.id) === String(productTwo.id);
      },
    ),
  };
}

export const useSpecificStoreProducts = (
  productIds: (string | number)[],
  config?: {
    forceSkip?: boolean;
  },
) => {
  return useFetchStoreProducts(productIds, config?.forceSkip ?? false);
};

export const useStoreProductsFromPartialAction = (
  action: ActionWithNullableValue | null,
  draftComponentId: string | null,
  config?: {
    forceSkip?: boolean;
  },
) => {
  return useFetchStoreProducts(
    draftComponentId && action
      ? productIdsFromPartialAction(action, draftComponentId)
      : [],
    config?.forceSkip || isNullish(draftComponentId) || isNullish(action),
  );
};

/**
 * Hook which fetches and returns products from the current store, based on type.
 * Useful for times when you might want to fetch one type of products of a specific
 * type, based on a prop (only one request will be made by this hook, for the appropriate type)
 */

export type ProductRequestType =
  | "anyProducts"
  | "productsFromDataCollections"
  | "productsFromDraftElement";
export const useStoreProducts = (type: ProductRequestType) => {
  const anyProductsQueryData = useAnyStoreProducts({
    forceSkip: type !== "anyProducts",
  });
  const dataCollectionsQueryData = useStoreProductsFromDataTablesMapping({
    forceSkip: type !== "productsFromDataCollections",
  });
  const draftElementProductsQueryData = useStoreProductsFromDraftElement({
    forceSkip: type !== "productsFromDraftElement",
  });
  return exhaustiveSwitch({ type })({
    anyProducts: anyProductsQueryData,
    productsFromDataCollections: dataCollectionsQueryData,
    productsFromDraftElement: draftElementProductsQueryData,
  });
};

/**
 * Hook which loads and returns a list of any of the products in all data tables. Useful
 * for rendering the data tables edit UI.
 */
export const useStoreProductsFromDataTablesMapping = (config?: {
  forceSkip?: boolean;
}) => {
  const dataTablesMapping = useEditorSelector(selectDataTablesMapping);

  const productIds = Object.values(dataTablesMapping).flatMap((dataTable) => {
    const productColumnIds = dataTable.data.schema
      .filter((column) => column.type === "product")
      .map((column) => column.id);
    return dataTable.data.rows.flatMap((row) => {
      return filterNulls(
        productColumnIds.map((columnId) => {
          const productRef = row[columnId] as ProductRef;
          if (productRef) {
            return Number(productRef.productId);
          }
          return null;
        }),
      );
    });
  });
  return useFetchStoreProducts(productIds, config?.forceSkip ?? false);
};

export function useDraftElementProductData() {
  const { products } = useStoreProductsFromDraftElement();
  return React.useMemo(() => {
    return {
      // Note (Evan, 2024-03-11): It's important that the products are not sorted, in
      // order to keep the template product at index [0] for getProduct (USE-792)
      products,
      productIds: products.map((product) => Number(product.id)).sort(),
    };
  }, [products]);
}

export function useDraftElementProductsIds() {
  const productIds = useEditorSelector(
    selectProductsIdsFromDraftElement,
    // Note (Noah, 2022-01-13): Use shallowEqual because it's an array which
    // will change on every run of the selector
    shallowEqual,
  );

  return productIds;
}
