import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { ComponentNameID } from "@editor/reducers/core-reducer";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type {
  LayoutPreset,
  Side,
  Spacing,
  SpacingSide,
} from "@editor/types/modifiers";
import type { RichTextEditorTag } from "@editor/types/rich-text-editor";
import type { Editor as CoreEditor } from "@tiptap/core";
import type * as React from "react";
import type { ComponentMapping } from "replo-runtime/shared/Component";
import type { SolidOrGradient } from "replo-runtime/shared/types";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { Action } from "schemas/actions";
import type { Component, CustomPropDefinition } from "schemas/component";
import type { ProductRef } from "schemas/product";
import type { GradientStop } from "schemas/styleAttribute";

import {
  getAlignSelf,
  getParentComponentFromMapping,
} from "@editor/utils/component";
import { DraggingDirections, DraggingTypes } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

import get from "lodash-es/get";
import {
  findComponentByTypeAndMaxLengthBFS,
  getChildren,
} from "replo-runtime/shared/utils/component";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { isEmptyOrAuto } from "replo-runtime/shared/utils/css";
import { getNormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";
import { gradientToCssGradient } from "replo-runtime/shared/utils/gradient";
import { parseUnit } from "replo-runtime/shared/utils/units";
import { getProduct } from "replo-runtime/store/ReploProduct";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { filterNulls } from "replo-utils/lib/array";

export const isEmptyOrCssDefault = (
  value: string | null | undefined,
  defaultValue?: string,
) => {
  if (!value || value === defaultValue) {
    return true;
  }
  return false;
};

export const hasDynamicData = (id: string | null) => {
  if (!id) {
    return false;
  }
  const currentComponentContext = getCurrentComponentContext(id, 0);

  const dynamicDataContext = get(currentComponentContext, `attributes`, {});
  return !Object.values(dynamicDataContext).every(
    (data) => Boolean(data) === false,
  );
};

export const getTilt = (
  component: Component,
  gradientSelectionType: string,
  getAttribute: any,
): string => {
  return (
    getAttribute(
      component,
      `style.__alchemyGradient__${gradientSelectionType}__tilt`,
    ).value || "90deg"
  );
};

export const getStops = (
  component: Component,
  gradientSelectionType: string,
  getAttribute: any,
): GradientStop[] => {
  return (
    getAttribute(
      component,
      `style.__alchemyGradient__${gradientSelectionType}__stops`,
    ).value || [{ color: "#df9393", location: "0%" }]
  );
};

export const getSolidOrGradient = (
  component: Component,
  gradientSelectionType: string | null,
  getAttribute: any,
  solidColorValue: string,
): SolidOrGradient => {
  if (gradientSelectionType) {
    return {
      type: "gradient",
      gradient: {
        tilt: getTilt(component, gradientSelectionType, getAttribute),
        stops: getStops(component, gradientSelectionType, getAttribute),
      },
    };
  }
  return { type: "solid", color: solidColorValue };
};

export const componentHasDefinedWidth = (
  component: Component | null,
  getAttribute: GetAttributeFunction,
  componentMapping: ComponentMapping,
): boolean => {
  if (!component) {
    return false;
  }

  const width = getAttribute(component, "style.width", null).value;
  const hasWidth = !isEmptyOrAuto(width);

  if (hasWidth) {
    return true;
  }

  const parent = getParentComponentFromMapping(componentMapping, component.id);

  if (!parent) {
    return true;
  }

  const componentPosition =
    getAttribute(component, "style.position", null).value ??
    styleAttributeToEditorData.position.defaultValue;

  const parentFlexDirection = getNormalizedFlexDirection(
    getAttribute(parent, "style.flexDirection", {
      defaultValue: styleAttributeToEditorData.flexDirection.defaultValue,
    }).value,
  );
  const alignSelf = getAlignSelf(component, parent, getAttribute);
  const isAlignSelfStretched = alignSelf === "stretch";
  const hasFlexGrow =
    (getAttribute(component, "style.flexGrow", null).value || "unset") !==
    "unset";

  if (
    parentFlexDirection === "row" &&
    hasFlexGrow &&
    !["fixed", "absolute"].includes(componentPosition) &&
    componentHasDefinedWidth(parent, getAttribute, componentMapping)
  ) {
    return true;
  }

  if (
    parentFlexDirection === "column" &&
    isAlignSelfStretched &&
    !["fixed", "absolute"].includes(componentPosition) &&
    componentHasDefinedWidth(parent, getAttribute, componentMapping)
  ) {
    return true;
  }

  return false;
};

export const componentHasDefinedHeight = (
  component: Component | null,
  getAttribute: GetAttributeFunction,
  componentMapping: ComponentMapping,
): boolean => {
  if (!component) {
    return false;
  }

  const height = getAttribute(component, "style.height", null).value;
  const hasHeight = !isEmptyOrAuto(height);

  if (hasHeight) {
    return true;
  }

  const parent = getParentComponentFromMapping(componentMapping, component.id);

  if (!parent) {
    return false;
  }

  const componentPosition =
    getAttribute(component, "style.position", null).value ??
    styleAttributeToEditorData.position.defaultValue;

  const parentFlexDirection = getNormalizedFlexDirection(
    getAttribute(parent, "style.flexDirection", null).value ||
      styleAttributeToEditorData.flexDirection.defaultValue,
  );
  const alignSelf = getAlignSelf(component, parent, getAttribute);
  const isAlignSelfStretched = alignSelf === "stretch";
  const hasFlexGrow =
    (getAttribute(component, "style.flexGrow", null).value || "unset") !==
    "unset";

  const parentHasDefinedHeight = componentHasDefinedHeight(
    parent,
    getAttribute,
    componentMapping,
  );

  if (
    parentFlexDirection === "column" &&
    hasFlexGrow &&
    parentHasDefinedHeight &&
    !["fixed", "absolute"].includes(componentPosition)
  ) {
    return true;
  }

  if (
    parentFlexDirection === "row" &&
    isAlignSelfStretched &&
    parentHasDefinedHeight &&
    !["fixed", "absolute"].includes(componentPosition)
  ) {
    return true;
  }

  return false;
};

const breakpoints = [
  { breakpoint: "style@sm", dependents: ["style@md", "style"] },
  { breakpoint: "style@md", dependents: ["style"] },
  { breakpoint: "style", dependents: [] },
];

export const getVisibleBreakpoints = (
  component: Component,
  getAttribute: GetAttributeFunction,
) => {
  const visibleBreakpoints: string[] = [];
  for (const breakpoint of breakpoints) {
    let attributeValue = getAttribute(
      component,
      `props.${breakpoint.breakpoint}.display`,
    );
    if (!attributeValue?.value) {
      const [dependentValue] = filterNulls(
        breakpoint.dependents.map((dependent) => {
          return getAttribute(component, `props.${dependent}.display`);
        }),
      );
      if (dependentValue) {
        attributeValue = dependentValue;
      }
    }
    const displayValue = attributeValue?.value;
    const isVisible = displayValue !== "none";
    if (isVisible) {
      visibleBreakpoints.push(breakpoint.breakpoint);
    }
  }
  return visibleBreakpoints;
};

const flexGrowOneProp = {
  style: {
    flexGrow: 1,
  },
};

const flexGrowTwoProp = {
  style: {
    flexGrow: 2,
  },
};

export const layouts: LayoutPreset[] = [
  {
    children: [flexGrowOneProp],
    parentStyle: {
      display: "grid",
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      __numberOfColumns: 1,
    },
  },
  {
    children: [flexGrowOneProp, flexGrowOneProp],
    parentStyle: {
      display: "grid",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      __numberOfColumns: 2,
    },
  },
  {
    children: [flexGrowOneProp, flexGrowTwoProp],
    parentStyle: {
      display: "grid",
      gridTemplateColumns: "minmax(0, 1fr) minmax(0, 2fr)",
      __numberOfColumns: 2,
    },
  },
  {
    children: [flexGrowTwoProp, flexGrowOneProp],
    parentStyle: {
      display: "grid",
      gridTemplateColumns: "minmax(0, 2fr) minmax(0, 1fr)",
      __numberOfColumns: 2,
    },
  },
  {
    children: [flexGrowOneProp, flexGrowOneProp, flexGrowOneProp],
    parentStyle: {
      display: "grid",
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      __numberOfColumns: 3,
    },
  },
  {
    children: [
      flexGrowOneProp,
      flexGrowOneProp,
      flexGrowOneProp,
      flexGrowOneProp,
    ],
    parentStyle: {
      display: "grid",
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
      __numberOfColumns: 4,
    },
  },
];

/**
 * Return the updated lineHeight to use for the given value of a fontSize. This
 * function applies the heuristic that the lineHeight should always be a little
 * larger than the font size (the user should almost never want to have a smaller
 * lineHeight than fontSize, the font would just get cut off).
 *
 * @param fontSizeValue Value of fontSize to calcluate lineHeight for
 * @param component Component containing the fontSize
 * @param getAttribute Attribute accessor for the current redux state
 */
export const getUpdatedLineHeightForFontSize = (
  fontSizeValue: string,
  component: Component,
  getAttribute: GetAttributeFunction,
) => {
  const fontSize = parseUnit(
    fontSizeValue,
    { value: "", unit: "" },
    "default",
    "px",
  );
  let lineHeight = getAttribute(component, "style.lineHeight").value;
  const lineHeightParse = parseUnit(
    lineHeight,
    {
      value: "",
      unit: "",
    },
    "default",
    "px",
  );
  if (
    fontSize.unit === "px" &&
    lineHeight &&
    Number(fontSize.value) > Number(lineHeightParse.value) - 2
  ) {
    lineHeight = `${Number(fontSize.value) + 2}px`;
  }
  return lineHeight;
};

export const normalizeFontFamily = (family: string | null): string | null => {
  if (!family) {
    return null;
  }

  return family.split(",")[0]!.replace('"', "").replace('"', "");
};

export const getCurrentTag = (editor: CoreEditor | null): RichTextEditorTag => {
  if (!editor || editor.isActive("paragraph")) {
    return "P";
  }
  const activeHeader = [1, 2, 3, 4, 5, 6].find((level) => {
    return editor.isActive("heading", { level });
  });

  return (String(activeHeader) as RichTextEditorTag) || "P";
};

export const verticals: Side[] = ["Top", "Bottom"];
export const horizontals: Side[] = ["Left", "Right"];
export const spaceBarX = 44;
export const spaceBarY = 23;
export const spaceBarGap = 4;
const leftClipPath = `polygon(0 0, 100% ${spaceBarY}px, 100% calc(100% - ${spaceBarY}px), 0% 100%)`;
const rightClipPath = `polygon(0 ${spaceBarY}px, 100% 0, 100% 100%, 0 calc(100% - ${spaceBarY}px))`;
const topClipPath = `polygon(0 0, 100% 0, calc(100% - ${spaceBarX}px) 100%, ${spaceBarX}px 100%)`;
const bottomClipPath = `polygon(${spaceBarX}px 0, calc(100% - ${spaceBarX}px) 0, 100% 100%, 0 100%)`;

export const spacings: Record<Spacing, SpacingSide[]> = {
  margin: [...verticals, ...horizontals].map(
    (direction) => `margin${direction}` as SpacingSide,
  ),
  padding: [...verticals, ...horizontals].map(
    (direction) => `padding${direction}` as SpacingSide,
  ),
};

export const allSpacings: SpacingSide[] = Object.values(spacings).flat();

export const spacingSideDirectory: Record<
  SpacingSide,
  {
    style: React.CSSProperties;
    draggingType: DraggingTypes;
    draggingDirection: DraggingDirections;
  }
> = {
  marginLeft: {
    style: {
      clipPath: leftClipPath,
      WebkitClipPath: leftClipPath,
      height: "100%",
      width: spaceBarX,
      left: 0,
    },
    draggingType: DraggingTypes.Horizontal,
    draggingDirection: DraggingDirections.Negative,
  },
  marginRight: {
    style: {
      clipPath: rightClipPath,
      WebkitClipPath: rightClipPath,
      height: "100%",
      width: spaceBarX,
      right: 0,
    },
    draggingType: DraggingTypes.Horizontal,
    draggingDirection: DraggingDirections.Positive,
  },
  marginTop: {
    style: {
      clipPath: topClipPath,
      WebkitClipPath: topClipPath,
      width: "100%",
      height: spaceBarY,
      top: 0,
    },
    draggingType: DraggingTypes.Vertical,
    draggingDirection: DraggingDirections.Positive,
  },
  marginBottom: {
    style: {
      clipPath: bottomClipPath,
      WebkitClipPath: bottomClipPath,
      width: "100%",
      height: spaceBarY,
      bottom: 0,
    },
    draggingType: DraggingTypes.Vertical,
    draggingDirection: DraggingDirections.Negative,
  },
  /* Padding */
  paddingLeft: {
    style: {
      clipPath: leftClipPath,
      WebkitClipPath: leftClipPath,
      height: `calc(100% - ${2 * spaceBarGap}px - ${2 * spaceBarY}px)`,
      width: spaceBarX,
      left: spaceBarX + spaceBarGap,
      top: spaceBarY + spaceBarGap,
    },
    draggingType: DraggingTypes.Horizontal,
    draggingDirection: DraggingDirections.Positive,
  },
  paddingRight: {
    style: {
      clipPath: rightClipPath,
      WebkitClipPath: rightClipPath,
      height: `calc(100% - ${2 * spaceBarGap}px - ${2 * spaceBarY}px)`,
      width: spaceBarX,
      right: spaceBarX + spaceBarGap,
      top: spaceBarY + spaceBarGap,
    },
    draggingType: DraggingTypes.Horizontal,
    draggingDirection: DraggingDirections.Negative,
  },
  paddingTop: {
    style: {
      clipPath: topClipPath,
      WebkitClipPath: topClipPath,
      width: `calc(100% - ${2 * spaceBarGap}px - ${2 * spaceBarX}px)`,
      height: spaceBarY,
      left: spaceBarX + spaceBarGap,
      top: spaceBarY + spaceBarGap,
    },
    draggingType: DraggingTypes.Vertical,
    draggingDirection: DraggingDirections.Negative,
  },
  paddingBottom: {
    style: {
      clipPath: bottomClipPath,
      WebkitClipPath: bottomClipPath,
      width: `calc(100% - ${2 * spaceBarGap}px - ${2 * spaceBarX}px)`,
      height: spaceBarY,
      left: spaceBarX + spaceBarGap,
      bottom: spaceBarY + spaceBarGap,
    },
    draggingType: DraggingTypes.Vertical,
    draggingDirection: DraggingDirections.Positive,
  },
};

export const getDefaultActionValue = (action: Partial<Action>) => {
  if (action.type === "addProductVariantToCart") {
    return {
      redirectToCart: true,
      allowThirdPartySellingPlan: false,
    };
  }

  return action.value;
};

export const transformDefaultValues = {
  translateX: "0px",
  translateY: "0px",
  translateZ: "0px",
  scaleX: "100%",
  scaleY: "100%",
  scaleZ: "100%",
  rotateX: "0deg",
  rotateY: "0deg",
  rotateZ: "0deg",
  skewX: "0deg",
  skewY: "0deg",
};

export const getSelectableOptionValues = (props: {
  customPropDefinition: CustomPropDefinition;
  draftComponent: Component;
  context: Context | null;
  allTextComponents: ComponentNameID[];
  productResolutionDependencies: ProductResolutionDependencies;
}) => {
  const {
    customPropDefinition,
    draftComponent,
    context,
    productResolutionDependencies,
    allTextComponents,
  } = props;
  const {
    moneyFormat,
    currencyCode,
    language,
    products,
    templateProduct,
    isShopifyProductsLoading,
  } = productResolutionDependencies;
  if (customPropDefinition.selectableValues?.type === "options") {
    return customPropDefinition.selectableValues.options;
  }

  if (customPropDefinition.id === "_accessibilityLabelledBy") {
    // TODO (Ovishek, 2022-12-21): We should support Dynamic Values here as well, currently we are not
    // evaluating dynamic value (which we should), that's why we are filtering it. Also if there're more than
    // one dynamic value then it get's confusing on the dropdown what to choose.
    return filterNulls(
      allTextComponents.map((component) => {
        if (component.name === "Dynamic Value") {
          return null;
        }
        return {
          label: component.name,
          isSelectable: true,
          isActive:
            component.id === draftComponent.props._accessibilityLabelledBy,
          value: component.id,
        };
      }),
    );
  }

  if (draftComponent.type === "tabsV2__block") {
    let tabV2Options = [];
    // Note (Ovishek, 2023-01-04): It's necessary to get the max length for tabs, because there can be
    // multiple Tabs List components (we do support multiple Tabs Lists component)
    const tabListComponent = findComponentByTypeAndMaxLengthBFS(
      draftComponent,
      "tabsV2__list",
      (c) => c.id !== draftComponent.id && c.type === "tabsV2__block",
    ).component;
    tabV2Options = getChildren(tabListComponent).map((_tab, index) => ({
      label: `Tab ${index + 1} (${draftComponent.name})`,
      value: String(index),
    }));

    return tabV2Options;
  } else if (draftComponent.type === "product") {
    const product = getProduct(draftComponent.props._product ?? null, context, {
      productMetafieldValues: {},
      variantMetafieldValues: {},
      products,
      currencyCode,
      language,
      moneyFormat,
      templateProduct,
      isEditor: true,
      isShopifyProductsLoading,
    });
    return (
      product?.variants.map((v) => ({
        label: v.title,
        isSelectable: true,
        isActive:
          (draftComponent.props._product as ProductRef)?.variantId === v.id,
        value: v.id,
      })) ?? []
    );
  }

  return [];
};

export const getExtraActionsBasedOnComponentType = (
  value: unknown,
  component: Component,
  customPropDefinition: CustomPropDefinition,
) => {
  const actions: UseApplyComponentActionType[] = [];
  // If we just set the items of a tab component, make sure we apply an action
  // to sync the component content of the tabs with the items (so that there's
  // one tab panel component per item)
  if (
    component.type === "tabsBlock" &&
    customPropDefinition.type === "inlineItems"
  ) {
    actions.push({
      type: "syncTabContent",
      analyticsExtras: {
        actionType: "other",
        createdBy: "replo",
      },
    });
  }

  if (
    component.type === "product" &&
    customPropDefinition.type === "product_variant"
  ) {
    actions.push({
      type: "setProps",
      value: {
        _product: {
          ...component.props._product,
          variantId: value,
        },
      },
    });
  }

  // NOTE (Evan, 7/7/23) This makes sure that default mute is always true for autoplaying
  // vimeo embeds.
  if (
    component.type === "vimeoEmbedV2" &&
    customPropDefinition.id === "autoPlay" &&
    value === true
  ) {
    actions.push({
      type: "setProps",
      value: {
        defaultMuted: true,
      },
      analyticsExtras: {
        actionType: "edit",
        createdBy: "replo",
      },
    });
  }
  return actions;
};

export const getColorBadgeValue = (
  color: string | SolidOrGradient | ReploMixedStyleValue | null,
): string => {
  if (!color) {
    return "";
  }

  if (isMixedStyleValue(color)) {
    return "Mixed";
  }

  if (typeof color === "string") {
    return color;
  }

  if (color.type === "solid") {
    return color.color ?? "";
  }

  return gradientToCssGradient(color.gradient) ?? "";
};
