import type { MetafieldsNamespaceKeyTypeMapping } from "@editor/reducers/commerce-reducer";
import type { DataTable } from "replo-runtime/shared/DataTable";
import type { AlchemyActionTrigger } from "replo-runtime/shared/enums";
import type {
  Dependencies,
  MetafieldKey,
  MetafieldNamespace,
  MetafieldsDependency,
  MetafieldType,
  ProductId,
  ProductsDependency,
  ReploShopifyProduct,
  ReploShopifyVariant,
  StoreProduct,
  VariantId,
} from "replo-runtime/shared/types";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";
import type { Component } from "schemas/component";
import type { ItemsConfig } from "schemas/dynamicData";
import type { ShopifyMetafieldNamespacedKey } from "schemas/generated/shopifyMetafield";
import type { ReploState } from "schemas/generated/symbol";
import type { ProductRef } from "schemas/product";

import forEach from "lodash-es/forEach";
import mapValues from "lodash-es/mapValues";
import uniqBy from "lodash-es/uniqBy";
import { AlchemyActionTriggers } from "replo-runtime/shared/enums";
import { mapStoreProductToReploProduct } from "replo-runtime/shared/mappers/product";
import {
  DependencyType,
  MetafieldEntityType,
} from "replo-runtime/shared/types";
import {
  calculateProductDependenciesForActionTriggers,
  calculateProductDependenciesFromProps,
  forEachComponentAndDescendants,
  getCustomPropDefinitions,
} from "replo-runtime/shared/utils/component";
import {
  getCurrentComponentContext,
  resolveContextValue,
} from "replo-runtime/shared/utils/context";
import { getNonDesignLibraryDynamicDataExpressions } from "replo-runtime/shared/utils/dynamic-data";
import { getRowObjectsFromDataTable } from "replo-runtime/store/AlchemyDataTable";
import { getProduct } from "replo-runtime/store/ReploProduct";
import { actionTypeToRenderData } from "replo-runtime/store/utils/action";
import { fakeProductsMap } from "replo-runtime/store/utils/fakeProducts";
import { filterNulls } from "replo-utils/lib/array";
import { hasOwnProperty, isNotNullish } from "replo-utils/lib/misc";
import { mediaSizes } from "schemas/breakpoints";
import { ItemsConfigType } from "schemas/dynamicData";

/**
 * Given a component, execute the closure for every property that is a dynamic
 * value. The same value may be repeated more than once.
 *
 * Note (Noah, 2023-10-03, USE-413): This function also reports dynamic values
 * which are set in a Replo State (variant) override.
 *
 * @param component Component to iterate over
 * @param componentOverrides Mapping of component id -> any overrides which have
 * been applied by Replo States for the component or its parents (this generally
 * should come from forEachComponentAndDescendants)
 * @param execute Closure to execute for each dynamic value
 */
const forEachDynamicValueProperty = (
  component: Component,
  componentOverrides: Record<
    Component["id"],
    { variantId: ReploState["id"]; partialComponent: Partial<Component> }[]
  >,
  execute: (handlebarsPath: string) => void,
) => {
  // Note (Noah, 2023-10-03): We'll construct an array of all the props we need
  // to check. In the simple case this is just component props, but if there are
  // state overrides, then we'll need to check the props of those overrides as well
  const propsObjectsToCheck: Partial<Component["props"]>[] = [
    component.props ?? {},
  ];

  // Note (Noah, 2023-10-03): Go through all the state overrides and add their props
  // to the list of ones to check. This ensures that even if there are overrides
  // for a component in two variants, or at two levels of the component-state tree,
  // we'll check all possible prop values for dynamic data strings.
  const overrides = componentOverrides[component.id] ?? [];
  for (const override of overrides) {
    if (override.partialComponent.props) {
      propsObjectsToCheck.push(override.partialComponent.props ?? {});
    }
  }

  // Note (Noah, 2023-10-03): Now go through all of the props, and for each
  // set of props, go through all the values and check if they're dynamic data
  // (treating the styles as a special case, since we need to check the inner
  // objects of each media device override)
  for (const props of propsObjectsToCheck) {
    for (const [key, value] of Object.entries(props)) {
      if (typeof value === "string" && value.includes("{{")) {
        execute(value);
      }
      if (AlchemyActionTriggers.includes(key as AlchemyActionTrigger)) {
        const actions = component.props?.[key as AlchemyActionTrigger];
        (actions ?? []).forEach((action) => {
          const renderData = actionTypeToRenderData[action.type];
          if (renderData.supportsDynamicData) {
            for (const dynamicDataValue of renderData.getDynamicDataValues({
              action,
            })) {
              execute(dynamicDataValue);
            }
          }
        });
      }
    }

    for (const styleAttribute of mediaSizes
      .map((size) => `style@${size}`)
      .concat("style")) {
      const styleValue = props?.[styleAttribute];
      if (styleValue && typeof styleValue === "object") {
        for (const value of Object.values(styleValue)) {
          if (typeof value === "string" && value.includes("{{")) {
            execute(value);
          }
        }
      }
    }
  }
};

const getApplicableProductsForMetafields = (args: {
  dynamicDataPath: string;
  componentId: string;
  rootComponent: Component;
  dataTables: Record<string, DataTable>;
  productResolutionDependencies: ProductResolutionDependencies;
}): ReploShopifyProduct[] => {
  const {
    dynamicDataPath,
    componentId,
    rootComponent,
    dataTables,
    productResolutionDependencies,
  } = args;

  // Hack (Noah, 2021-10-11): If the dynamic data path is from a product/variant
  // in a temporary cart, we don't know where the product/variant was actually
  // defined (could be a static product, or could be from a data table collection,
  // etc). So as a brute force way to make sure we have the metafields data if
  // needed, we go through all repeater-style components and add their metafields
  // as dependencies. This creates more dependencies than are necessary but we'd
  // rather over-estimate than under-estimate.
  if (dynamicDataPath.includes("_currentTemporaryCartItem")) {
    const results: ReploShopifyProduct[] = [];
    forEachComponentAndDescendants(rootComponent, (component) => {
      if (component.type === "collection") {
        const itemsConfig = component.props?.items;
        if (
          !itemsConfig?.type ||
          itemsConfig?.type === ItemsConfigType.dataTable
        ) {
          const rows = getRowObjectsFromDataTable(
            itemsConfig?.id,
            dataTables,
            productResolutionDependencies,
          );
          rows.forEach((row) => {
            Object.values(row).forEach((cellValue) => {
              if (cellValue?.productId) {
                results.push(cellValue);
              }
            });
          });
        }
      }
    });
    return results;
  }

  const products = new Set<ReploShopifyProduct>();

  const context = getCurrentComponentContext(componentId, 0);
  const resolvedContextValue = resolveContextValue(
    {
      type: "contextRef",
      ref: dynamicDataPath
        .slice(0, Math.max(0, dynamicDataPath.indexOf(".productMetafields")))
        .replace("{{", ""),
    },
    context,
  );
  if (resolvedContextValue) {
    // Note (Noah, 2023-11-09, USE-547): depending on where we are in the tree,
    // the context value might be a fully formed ReploShopifyProduct (e.g. if
    // we're an option list component whose product config value is set to
    // "Current Product") or simply a ProductRef (e.g. if we're an option list
    // component whose product is set to some product directly). We need to
    // handle both cases, and we assume that if the object has a handle then
    // it's a fully resolved product, otherwise if it's a product ref we try to
    // resolve it
    if (hasOwnProperty(resolvedContextValue, "handle")) {
      products.add(resolvedContextValue as ReploShopifyProduct);
    } else if (hasOwnProperty(resolvedContextValue, "productId")) {
      const effectiveProduct = getProduct(
        resolvedContextValue,
        context ?? null,
        {
          productMetafieldValues: {},
          variantMetafieldValues: {},
          products: productResolutionDependencies.products,
          currencyCode: productResolutionDependencies.currencyCode,
          moneyFormat: productResolutionDependencies.moneyFormat,
          language: productResolutionDependencies.language,
          templateProduct: productResolutionDependencies.templateProduct,
          isEditor: productResolutionDependencies.isEditor,
          isShopifyProductsLoading: false,
        },
      );
      if (effectiveProduct) {
        products.add(effectiveProduct as ReploShopifyProduct);
      }
    }
  }

  // Note (Martin, 2022-11-22): If there are products in the state context,
  // add them as well. This is useful for Product Collections where there is
  // only one component in the tree, but we need to load all the products it
  // loops over (repeatable components).
  const currentContext = getCurrentComponentContext(componentId, 0);
  for (const product of currentContext?.state.products ?? []) {
    products.add(product as ReploShopifyProduct);
  }

  productResolutionDependencies.products.forEach((product) => {
    const alchemyShopifyProduct = mapStoreProductToReploProduct(product, {
      // Note (Noah, 2024-03-17): Okay to pass 1 here, since we don't use any
      // of the quantity-dependent attribute
      quantity: 1,
      metafields: {
        productMetafieldsMapping: {},
        variantMetafieldsMapping: {},
      },
      currencyCode: productResolutionDependencies.currencyCode,
      moneyFormat: productResolutionDependencies.moneyFormat,
      language: productResolutionDependencies.language,
    });
    products.add(alchemyShopifyProduct);
  });

  return Array.from(products).filter(isNotNullish);
};

const getApplicableVariantsForMetafields = (
  dynamicDataPath: string,
  componentId: string,
  productResolutionDependencies: ProductResolutionDependencies,
) => {
  const variants = new Set<{ productHandle: string; variantId: number }>();

  // TODO (Noah, 2023-07-21, REPL-7478): I bet this getCurrentComponentContext is making
  // unit tests flake
  const dynamicDataPathVariant = resolveContextValue(
    {
      type: "contextRef",
      ref: dynamicDataPath
        .slice(0, Math.max(0, dynamicDataPath.indexOf(".variantMetafields")))
        .replace("{{", ""),
    },
    getCurrentComponentContext(componentId, 0),
  ) as ReploShopifyVariant | null;
  if (dynamicDataPathVariant) {
    variants.add({
      variantId: dynamicDataPathVariant.id,
      productHandle: dynamicDataPathVariant.productHandle,
    });
  }

  productResolutionDependencies.products.forEach((product) => {
    product.variants.forEach((variant) =>
      variants.add({ variantId: variant.id, productHandle: product.handle }),
    );
  });

  return filterNulls(Array.from(variants));
};

/**
 * Given a component, return an array of all the things that component depends on.
 *
 * This is the main function which tells us what we have to fetch in the backend,
 * serialize to liquid, etc during the publish flow. In particular, this determines
 * all the products/metafields/data collections that we need.
 *
 * @param rootComponent Component to calculate dependencies for
 * @param extras Extra data needed to figure out dependencies.
 * @returns Mapping of component id -> dependencies for that component
 */
export const calculateDependencies = (
  rootComponent: Component,
  extras: {
    dataTables: Record<string, DataTable>;
    productResolutionDependencies: ProductResolutionDependencies;
    metafieldsNamespaceKeyTypeMapping: MetafieldsNamespaceKeyTypeMapping;
  },
): {
  dependencies: Dependencies;
  allReferencedMetafields: Record<
    MetafieldEntityType,
    ShopifyMetafieldNamespacedKey[]
  >;
} => {
  const result: Dependencies = {};

  const productMetafieldMap: Map<string, ShopifyMetafieldNamespacedKey> =
    new Map();
  const variantMetafieldMap: Map<string, ShopifyMetafieldNamespacedKey> =
    new Map();

  forEachComponentAndDescendants(
    rootComponent,
    (component, _, context) => {
      const metafieldKeyMapping: Record<
        ProductId | VariantId,
        {
          metafieldKeys: { key: string; namespace: string; type: string }[];
          handle: string;
          entityType: MetafieldEntityType;
        }
      > = {};

      const productDependenciesFromProps =
        calculateProductDependenciesFromProps(component);
      if (productDependenciesFromProps?.length > 0) {
        result[component.id] = [
          ...(result[component.id] ?? []),
          ...productDependenciesFromProps,
        ];
      }

      const itemsPropDefinitions = getCustomPropDefinitions(component).filter(
        (prop) => prop.type === "dynamicItems",
      );
      for (const itemsPropDefinition of itemsPropDefinitions) {
        const itemsConfig = component.props?.[
          itemsPropDefinition.id
        ] as ItemsConfig | null;
        if (
          itemsConfig?.type === ItemsConfigType.dataTable &&
          itemsConfig?.id
        ) {
          result[component.id] = [
            ...(result[component.id] ?? []),
            {
              type: DependencyType.dataTable,
              dataTableId: itemsConfig.id,
            },
          ];

          const dataTable = extras.dataTables[itemsConfig.id];
          if (dataTable) {
            const productColumns = dataTable.data.schema.filter(
              (column) => column.type === "product",
            );
            for (const row of dataTable.data.rows) {
              for (const column of productColumns) {
                const productRef: ProductRef | null = row[
                  column.id
                ] as ProductRef | null;
                if (productRef) {
                  result[component.id] = [
                    ...(result[component.id] ?? []),
                    {
                      type: DependencyType.products,
                      productIds: [Number(productRef.productId)],
                    },
                  ];
                }
              }
            }
          }
        }
      }

      const productDependenciesFromActionTriggers =
        calculateProductDependenciesForActionTriggers(component);
      if (productDependenciesFromActionTriggers?.length > 0) {
        result[component.id] = [
          ...(result[component.id] ?? []),
          ...productDependenciesFromActionTriggers,
        ];
      }

      forEachDynamicValueProperty(
        component,
        // Note (Noah, 2023-12-04, REPL-9613): Important to note here for
        // performance, that componentOverrides could be quite a large objects
        // (potentially 1000s of nested objects). Weird to pass the whole thing
        // here, but if we did something like a mapValues, we'd be doing a lot
        // of work, and we'd be doing it for every single component in the tree,
        // which can slow down element updates a lot.
        context.componentOverrides ?? {},
        (propertyValue) => {
          // NOTE (Fran 2025-01-09): In the future we may want to support multiple
          // dynamic data expressions, but for now we only support one.
          const nonDesignLibraryDynamicDataExpressions =
            getNonDesignLibraryDynamicDataExpressions(propertyValue) ?? [];
          const propertyValueWithoutHtmlTags =
            nonDesignLibraryDynamicDataExpressions.length > 0
              ? nonDesignLibraryDynamicDataExpressions[0]!
              : propertyValue;

          if (propertyValue.includes(".productMetafields.")) {
            const namespaceAndKey = propertyValueWithoutHtmlTags
              .split(".productMetafields.")[1]!
              .replace("}}", "");

            const [namespace, key] = namespaceAndKey.split(".");

            if (!namespace || !key) {
              throw new Error(`Invalid dynamic data path: ${propertyValue}`);
            }

            const type =
              extras.metafieldsNamespaceKeyTypeMapping?.product?.[
                namespace as string
              ]?.[key as string] ?? "single_line_text_field";

            productMetafieldMap.set(namespaceAndKey, {
              namespace,
              key,
            });

            const applicableProducts = getApplicableProductsForMetafields({
              dynamicDataPath: propertyValue,
              componentId: component.id,
              rootComponent: rootComponent,
              dataTables: extras.dataTables,
              productResolutionDependencies:
                extras.productResolutionDependencies,
            });
            for (const product of applicableProducts) {
              if (!product || !product.productId) {
                continue;
              }
              if (!metafieldKeyMapping[product.id]) {
                metafieldKeyMapping[product.id] = {
                  metafieldKeys: [],
                  handle: product.handle,
                  entityType: MetafieldEntityType.product,
                };
              }

              // Note (Fran, 2023-02-17): When we build the text prop values with
              // the RTE editor we add html tags, we need to remove this to build
              // the dependency to find the actual value after.
              // E.g.:
              // {
              //   "props": {
              //      "text": "<p>{{attributes._product.productMetafields.my_fields.test_field}}</p>",
              //   }
              // }
              // So in this case we need to remove of the html p tag to have the
              // dynamic value clean to build the editor dependency.
              // Note (Noah, 2023-07-21, USE-210): Since this function is called
              // frequently, we don't want to do a regex replace if we don't
              // have to. The "includes" check here is not perfect, but works
              // for now and avoids checking in most cases

              metafieldKeyMapping[product.id]!.metafieldKeys.push({
                key: key as string,
                namespace: namespace as string,
                type,
              });
            }
          }
          if (propertyValue.includes(".variantMetafields.")) {
            const namespaceAndKey = propertyValueWithoutHtmlTags
              .split(".variantMetafields.")[1]!
              .replace("}}", "");

            const [namespace, key] = namespaceAndKey.split(".");
            if (!namespace || !key) {
              throw new Error(`Invalid dynamic data path: ${propertyValue}`);
            }

            const type =
              extras.metafieldsNamespaceKeyTypeMapping?.variant?.[
                namespace as string
              ]?.[key as string] ?? "single_line_text_field";

            variantMetafieldMap.set(namespaceAndKey, {
              namespace,
              key,
            });

            const applicableVariants = getApplicableVariantsForMetafields(
              propertyValue,
              component.id,
              extras.productResolutionDependencies,
            );

            if (applicableVariants?.length > 0) {
              applicableVariants?.forEach((variant) => {
                if (!metafieldKeyMapping[variant.variantId]) {
                  metafieldKeyMapping[variant.variantId] = {
                    metafieldKeys: [],
                    handle: variant.productHandle,
                    entityType: MetafieldEntityType.variant,
                  };
                }
                // Note (Fran, 2023-02-17): When we build the text prop values with
                // the RTE editor we add html tags, we need to remove this to build
                // the dependency to find the actual value after.
                // E.g.:
                // {
                //   "props": {
                //      "text": "<p>{{attributes._product.productMetafields.my_fields.test_field}}</p>",
                //   }
                // }
                // So in this case we need to remove of the html p tag to have the
                // dynamic value clean to build the editor dependency.
                // Note (Noah, 2023-07-21, USE-210): Since this function is called
                // frequently, we don't want to do a regex replace if we don't
                // have to. The "includes" check here is not perfect, but works
                // for now and avoids checking in most cases

                metafieldKeyMapping[variant.variantId]!.metafieldKeys.push({
                  key,
                  namespace,
                  type,
                });
              });
            }
          }
        },
      );

      for (const [entityId, data] of Object.entries(metafieldKeyMapping)) {
        const {
          metafieldKeys: possiblyDuplicatedMetafieldKeys,
          handle,
          entityType,
        } = data;
        const metafieldKeys = uniqBy(
          possiblyDuplicatedMetafieldKeys,
          (object) => {
            return JSON.stringify(object);
          },
        );
        if (metafieldKeys.length > 0) {
          if (!result[component.id]) {
            result[component.id] = [];
          }
          const dependencyToPush: MetafieldsDependency =
            entityType === MetafieldEntityType.variant
              ? {
                  type: DependencyType.metafields,
                  variantId: entityId,
                  productHandle: handle,
                  entityType: MetafieldEntityType.variant,
                  metafieldKeys: Array.from(metafieldKeys),
                }
              : {
                  type: DependencyType.metafields,
                  productId: entityId,
                  productHandle: handle,
                  entityType: MetafieldEntityType.product,
                  metafieldKeys: Array.from(metafieldKeys),
                };
          result[component.id]!.push(dependencyToPush);
        }
      }

      return "continue";
    },
    null,
    {},
    { executeForAllVariantOverrides: true },
  );

  return {
    dependencies: result,
    allReferencedMetafields: {
      product: Array.from(productMetafieldMap.values()),
      variant: Array.from(variantMetafieldMap.values()),
    },
  };
};

export const getProductsDataForDependencies = (
  dependencies: Dependencies,
  productDataFromStore: Record<number, StoreProduct>,
) => {
  const productDataFromStoreWithFakeProducts: Record<string, StoreProduct> = {
    ...productDataFromStore,
    ...fakeProductsMap,
  };
  const productsDependencies = Object.values(dependencies)
    .flat()
    .filter(
      (dependency) => dependency.type === DependencyType.products,
    ) as ProductsDependency[];

  const productsIds = productsDependencies.reduce(
    (productsIdArray: (string | number)[], d) => {
      return [...productsIdArray, ...d.productIds];
    },
    [],
  );

  const uniqProductsIds = uniqBy(productsIds, Number);

  const productsData = filterNulls(
    uniqProductsIds.map(
      (id) => productDataFromStoreWithFakeProducts[String(id)],
    ),
  );

  return productsData;
};

export const getMetafieldsNamespaceKeyTypeMapping = (
  metafieldValues: Record<number, Record<MetafieldNamespace, any>>,
) => {
  const metafieldsNamespaceKeyTypeMapping: Record<
    MetafieldNamespace,
    Record<MetafieldKey, MetafieldType>
  > = {};

  forEach(metafieldValues, (metafieldValuesForVariant) => {
    forEach(metafieldValuesForVariant, (metafieldObj, namespace) => {
      metafieldsNamespaceKeyTypeMapping[namespace] = {
        ...metafieldsNamespaceKeyTypeMapping[namespace],
        ...mapValues(metafieldObj, (metafield) => metafield.type),
      };
    });
  });

  return metafieldsNamespaceKeyTypeMapping;
};
