import * as React from "react";

import Switch from "@common/designSystem/Switch";

import twMerge from "@replo/design-system/utils/twMerge";

type CheckboxProps = {
  onChange?: (isOn: boolean) => void;
  isChecked?: boolean;
  className?: string;
};

const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  onChange,
  isChecked = false,
  children,
  className,
}) => {
  return (
    <Switch
      className={twMerge("mr-2", className)}
      isOn={isChecked}
      onChange={onChange}
      backgroundOnColor="bg-blue-600"
      label={children}
    />
  );
};

export default Checkbox;
