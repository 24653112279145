import type { UrlSyncedQuery } from "@/features/analytics/query";
import type {
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "@/features/analytics/time";
import type { DateRange } from "react-day-picker";

import { compressObjectToLzString } from "replo-utils/lib/lzString";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

export const URL_SYNCED_QUERY_URL_PARAM = "query";

export function calculateDelta(
  newValue: number | undefined,
  oldValue: number | undefined,
) {
  if (!oldValue || newValue === undefined) {
    return 0;
  }

  return oldValue === 0 ? newValue : ((newValue - oldValue) / oldValue) * 100;
}

/**
 * Given a selected timeframe/period, returns true if it's a custom date range.
 * If it returns true, we can be 100% sure that the value has a .from/.to
 *
 * @author Max 2024-09-17
 */
export function isCustomDateRange(
  value: ComparisonTimeFrame | RelativeTimeFrame | DateRange,
): value is { from: Date; to: Date } {
  return (
    (value as DateRange).from !== undefined &&
    (value as DateRange).to !== undefined
  );
}

/**
 * Sanitizes the page URL path by replacing "/" with "Homepage".
 *
 * @author Kurt 2024-10-13
 */
export function sanitizePageUrlPath(urlPath: string) {
  return urlPath !== "/" ? urlPath : "Homepage";
}

/**
 * Get url without query params.
 *
 * @author Kurt 2024-10-14
 */
export const getUrlWithoutQueryParams = (url: string) => {
  return url.split("?")[0];
};

/**
 * Should be used when navigating to the overview page or deep dive page.
 *
 * Given a path and a urlSyncedQueryConfig, it returns the path with the compressed config's
 * urlSyncedQuery appended to the path with ?query=''.
 *
 * The config's type is either:
 * 1) compressed: we already have the compressed string (i.e. we extracted the ?query='' param and are
 * redirecting to a new analytics path -> this happens in the DashboardMenuItems.tsx)
 *
 * 2) decompressed: we have the urlSyncedQuery as an object, which we now need to compress & append to the path. This
 * happens e.g. in AnalyticsMenuPane, where we manually construct a default urlSyncedQuery, which we have as an object,
 * and we now want to redirect to the deep dive page.
 *
 * @author Max 2024-12-16
 */
export function generatePathWithCompressedUrlSyncedQuery({
  path,
  urlSyncedQueryConfig,
}: {
  path: string | null;
  urlSyncedQueryConfig:
    | { type: "compressed"; urlSyncedQuery: string | null }
    | { type: "decompressed"; urlSyncedQuery: UrlSyncedQuery };
}) {
  if (!path) {
    return "";
  }

  return exhaustiveSwitch(urlSyncedQueryConfig)({
    compressed: ({ urlSyncedQuery }) => {
      return urlSyncedQuery
        ? `${path}?${URL_SYNCED_QUERY_URL_PARAM}=${urlSyncedQuery}`
        : path;
    },
    decompressed: ({ urlSyncedQuery }) => {
      if (!urlSyncedQuery) {
        return path;
      }

      const compressedUrlSyncedQuery = compressObjectToLzString(urlSyncedQuery);
      return `${path}?${URL_SYNCED_QUERY_URL_PARAM}=${compressedUrlSyncedQuery}`;
    },
  });
}
