import type { ComboboxRootProps } from "./types";

import * as React from "react";

export type ComboboxContextType = {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: string;
  setValue: (value: string) => void;
  input: string;
  setInput: (input: string) => void;
  selectedLabel: string | null;
  options: ComboboxRootProps["options"];
  isDisabled?: boolean;
  size: "sm" | "base";
  previewOnHover?: boolean;
  triggerInputRef: React.RefObject<HTMLInputElement>;
  isUsingTriggerInput: boolean;
  setIsUsingTriggerInput: (isUsingTriggerInput: boolean) => void;
};

export const ComboboxContext = React.createContext<
  ComboboxContextType | undefined
>(undefined);
