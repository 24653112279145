import type { Component } from "schemas/component";
import type {
  RenderComponentProps,
  ReploShopifyVariant,
} from "../../../shared/types";

import * as React from "react";

import { useKlaviyoIdentifiersStore } from "replo-runtime/store/contexts/KlaviyoIdentifiers/context";
import { useRuntimeActiveStateStore } from "replo-runtime/store/contexts/RuntimeActiveState/hooks";
import { isEmpty } from "replo-utils/lib/misc";
import { isString } from "replo-utils/lib/type-check";

import {
  RenderEnvironmentContext,
  ReploElementContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mapNull } from "../../../shared/utils/optional";
import { withLiquidAlternate } from "../../../shared/utils/withLiquidAlternate";
import { executeAction } from "../../AlchemyAction";
import { getProduct } from "../../ReploProduct";
import { fakeSwatches } from "../../utils/fakeSwatches";
import { isReploShopifyOptionList } from "../../utils/option";
import { enhanceVariantsAndOptions } from "../../utils/product";
import ReploLiquidChunk from "../ReploLiquid/ReploLiquidChunk";

const OptionSelectDropdown: React.FC<RenderComponentProps> = ({
  context,
  componentAttributes,
  component,
}) => {
  const { option, defaultText } = getCustomProps(component);
  const optionName = option?.label;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const {
    fakeProducts,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const isShopifyProductsLoading =
    useRuntimeContext(RuntimeHooksContext).useIsShopifyProductsLoading();
  const productMetafieldValues =
    useRuntimeContext(RuntimeHooksContext).useShopifyProductMetafieldValues();
  const variantMetafieldValues =
    useRuntimeContext(RuntimeHooksContext).useShopifyVariantMetafieldValues();
  const { elementType } = useRuntimeContext(ReploElementContext);
  const swatches = useRuntimeContext(RuntimeHooksContext).useSwatches();

  const [hasEverSelected, setHasEverSelected] = React.useState(false);
  const runtimeActiveStateStore = useRuntimeActiveStateStore();
  const klaviyoIdentifiersStore = useKlaviyoIdentifiersStore();

  const { selectedOptionValues } = context.state.product || {};
  const variants: ReploShopifyVariant[] = context.attributes?._variants || [];

  // Note (Chance 2023-08-08) We should guard against this and ensure that
  // `_options` is typed correctly, but since it's currently typed as `any` this
  // provides an extra guard to at least prevent confusing errors for users.
  const attributeOptions = isReploShopifyOptionList(
    context.attributes?._options,
  )
    ? context.attributes!._options
    : [];

  // NOTE (Evan, 9/8/23) This will be the product from props (if there is one),
  // falling back to the product from context (if there is one). If neither of these
  // exists, we fall back to attributeOptions below. This allows enhanceVariantsAndOptions
  // to run even when no product prop is defined (as is the default when you drag it in).
  const product = (() => {
    if (component.props._product) {
      const componentProduct = getProduct(component.props._product, context, {
        products,
        currencyCode,
        language,
        moneyFormat,
        productMetafieldValues,
        variantMetafieldValues,
        isEditor: isEditorApp,
        // Note (Noah, 2024-03-20, USE-824): Fall back to null since if the
        // product was not found (e.g. is a reference to a product which no
        // longer exists) we want this component to behave as if no direct
        // product was specified, so that it pulls options from the context
        // (ancestor product component)
        fallbackStrategy: null,
        fakeProducts,
        isShopifyProductsLoading,
        templateProduct,
      });
      if (componentProduct) {
        return componentProduct;
      }
    }
    if (context.state.product) {
      return context.state.product.product;
    }
    return null;
  })();

  const options =
    mapNull(product, (product) => {
      const showOptionsNotSoldTogether =
        component.props._showOptionsNotSoldTogether ?? false;
      const { options } = enhanceVariantsAndOptions({
        product,
        variantMetafieldValues,
        swatches: [...swatches, ...fakeSwatches],
        selectedOptionValues: context.state.product?.selectedOptionValues ?? {},
        showOptionsNotSoldTogether,
      });
      return options;
    }) ?? attributeOptions;
  const noDefaultText = isEmpty(defaultText);

  const selectedOptionOrNull =
    options.find((option) => option.name === optionName) ?? null;
  const activeOption = selectedOptionOrNull ?? options[0]!;

  const activeOptionValues = !option?.values
    ? activeOption?.values
    : activeOption?.values?.filter(
        ({ title }) => !option.values || option.values.includes(title),
      );

  if (!isEditorApp && variants.length === 0) {
    return null;
  }

  // NOTE (Gabe 2023-07-21): This hides options on published pages that don't
  // exist for the product in question. This is only relevant on Product
  // Templates where the template may have been configured with a product that
  // has options not consistent with the product for which the template is now
  // being used.
  if (
    elementType === "shopifyProductTemplate" &&
    !isEditorApp &&
    optionName &&
    !context.state.product?.product?.options.find((o) => o.name === optionName)
  ) {
    return null;
  }

  const getCurrentValue = () => {
    if (!hasEverSelected && defaultText) {
      return defaultText;
    }

    if (!hasEverSelected && noDefaultText) {
      return selectedOptionValues?.[activeOption.name] ?? undefined;
    }

    return hasEverSelected
      ? selectedOptionValues?.[activeOption.name] ?? undefined
      : defaultText;
  };

  return (
    <select
      {...componentAttributes}
      role="listbox"
      value={getCurrentValue()}
      data-replo-option-select-dropdown
      // Note (Noah, 2024-09-11, USE-1209): Stop propagation on click so that any
      // interactions on parent containers don't get triggered
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        const { value } = e.target;
        void executeAction(
          {
            id: `alchemy:selectOptionValue`,
            type: "setActiveOptionValue",
            value: { label: activeOption.name, value },
          },
          {
            componentId: component.id,
            componentContext: context,
            repeatedIndex: context.repeatedIndexPath,
            products: products,
            templateProduct,
            stores: {
              runtimeActiveState: runtimeActiveStateStore,
              klaviyoIdentifiers: klaviyoIdentifiersStore,
            },
          },
        );
        setHasEverSelected(true);
      }}
    >
      {!hasEverSelected &&
        defaultText &&
        activeOptionValues &&
        activeOptionValues.length > 0 && (
          <option value={defaultText} key={defaultText} role="option">
            {defaultText}
          </option>
        )}
      {activeOptionValues?.map((optionValue) => (
        <option value={optionValue.title} key={optionValue.title} role="option">
          {optionValue.title}
        </option>
      ))}
    </select>
  );
};

const OptionSelectDropdownLiquid: React.FC<RenderComponentProps> = ({
  component,
  context,
  componentAttributes,
}) => {
  const { option, defaultText } = getCustomProps(component);
  const optionName = option?.label;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const variants: ReploShopifyVariant[] = context.attributes?._variants || [];

  if (!isEditorApp && variants.length === 0) {
    return null;
  }
  return (
    <ReploLiquidChunk>
      {`{% capture captured_reploOptionSelectDropdownName %}${optionName ? escape(optionName) : ""}{% endcapture %}`}
      {`{% assign reploOptionSelectDropdownName = captured_reploOptionSelectDropdownName | url_decode %}`}
      {`{% capture reploOptionValues %}${
        option?.values ? `||${option.values.join("||")}||` : ""
      }{% endcapture %}`}
      {`{% capture reploOptionDelimiter %}||{% endcapture %}`}
      {/* NOTE (Gabe 2023-08-31): if no optionName is provided we default to the first option. */}
      {`{% if reploOptionSelectDropdownName == blank %}`}
      {`{% capture reploOptionSelectDropdownName %}{{product.options[0]}}{% endcapture %}`}
      {`{% endif %}`}
      {/*
        NOTE (Martin 2024-04-26): if shopifyProductTemplate element then we
        don't render the wrapping div if reploOptionSelectName is not found on
        product.options in order to match what we do in the runtime component.
        For more info look for: NOTE (Gabe 2023-07-21) above.
      */}
      {context.elementType === "shopifyProductTemplate" &&
        `{% if product.options contains reploOptionSelectDropdownName %}`}
      <select
        {...componentAttributes}
        role="listbox"
        value={defaultText}
        data-replo-option-select-dropdown
      >
        {context.elementType !== "shopifyProductTemplate" && (
          <>
            {/*
              NOTE (Martin 2024-04-26): if reploOptionSelectName is not found on
              product.options we should default to the first option in order to
              match what we do in the runtime component.
            */}
            {`{% unless product.options contains reploOptionSelectDropdownName %}`}
            {`{% capture reploOptionSelectDropdownName %}{{product.options[0]}}{% endcapture %}`}
            {`{% endunless %}`}
          </>
        )}
        {`{% if product.options contains reploOptionSelectDropdownName %}`}
        {defaultText && (
          <>
            {`{% if product.options_by_name[reploOptionSelectDropdownName].values[0] != blank %}`}
            <option value={defaultText} key={defaultText} role="option">
              {defaultText}
            </option>
            {`{% endif %}`}
          </>
        )}
        {`{% for reploRepeatedOptionValue in product.options_by_name[reploOptionSelectDropdownName].values %}`}
        {`{% assign optionValueName = reploOptionDelimiter | append: reploRepeatedOptionValue | append: reploOptionDelimiter | escape %}`}
        {`{% if reploOptionValues == blank or reploOptionValues contains optionValueName %}`}
        {`{% capture reploOptionKey %}option{% endcapture %}`}
        {`{% assign optionPositionKey = reploOptionKey | append: product.options_by_name[reploOptionSelectDropdownName].position %}`}
        {`{% if reploSelectedVariant == blank or reploSelectedVariant[optionPositionKey] == reploRepeatedOptionValue %}`}
        <option
          value="{{reploRepeatedOptionValue | escape }}"
          key="{{reploRepeatedOptionValue | escape }}"
          role="option"
          selected
        >
          {"{{reploRepeatedOptionValue}}"}
        </option>
        {`{% else %}`}
        <option
          value="{{reploRepeatedOptionValue | escape }}"
          key="{{reploRepeatedOptionValue | escape }}"
          role="option"
        >
          {"{{reploRepeatedOptionValue}}"}
        </option>
        {`{% endif %}`}
        {`{% endif %}`}
        {"{% endfor %}"}
        {`{% endif %}`}
      </select>
      {context.elementType === "shopifyProductTemplate" && `{% endif %}`}
    </ReploLiquidChunk>
  );
};

function getCustomProps(component: Component) {
  const option = component.props._option;
  return {
    option: isString(option) ? { label: option } : option,
    defaultText: component.props._defaultText,
  };
}

export default withLiquidAlternate(
  OptionSelectDropdown,
  OptionSelectDropdownLiquid,
);
