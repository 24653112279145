import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const STAMPED_PRODUCT_RATING_WIDGET_LIQUID_SOURCE = `<span
  class="stamped-product-reviews-badge stamped-main-badge"
  data-id="{{ product.id }}"
  data-product-sku="{{ product.handle }}"
  data-product-title="{{product.title}}"
  data-product-type="{{product.type}}"
  style="display: block;">
  {{product.metafields.stamped.badge}}
</span>`;

function StampedProductRatingWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: STAMPED_PRODUCT_RATING_WIDGET_LIQUID_SOURCE,
      context: props.context,
    },
  );

  return (
    <SharedShopifyLiquid
      componentId={component.id}
      componentAttributes={componentAttributes}
      component={component}
      liquidSource={liquidSourceWithProductAssignment}
      forceEditorPlaceholder={true}
      repeatedIndexPath={context.repeatedIndexPath}
      isLiquidSupported
      placeholderTitle="Stamped Product Rating"
      placeholderType="reviewStars"
    />
  );
}

export default StampedProductRatingWidget;
