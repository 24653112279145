import type { UnsafeStyleProps } from "../../utils/props";
import type {
  ButtonCornerStyle,
  ButtonFillStyle,
  ButtonGroupVariant,
  ButtonSize,
  ButtonVariant,
} from "./button-shared";

import * as React from "react";

import { filterDOMProps } from "@react-aria/utils";
import classNames from "classnames";

import {
  ButtonGroupProvider,
  DEFAULT_CORNER_STYLE,
  DEFAULT_FILL_STYLE,
  DEFAULT_SIZE,
  DEFAULT_VARIANT,
} from "./button-shared";

interface ButtonGroupOwnProps {
  children: React.ReactNode;
  fillStyle?: ButtonFillStyle;
  id?: string;
  size?: ButtonSize;
  variant?: ButtonGroupVariant;
  buttonVariant?: Exclude<ButtonVariant, "noStyle">;
  cornerStyle?: ButtonCornerStyle;
  isDisabled?: boolean;
  isBusy?: boolean;
}

export interface ButtonGroupProps
  extends UnsafeStyleProps,
    ButtonGroupOwnProps {}

/**
 * ButtonGroup is a container for rendering a group of buttons with logical
 * relationships. It is not necessary for all cases but it can be useful for
 * maintaining consistent styles and behaviors. It is also useful for rendering
 * "segmented" button groups where the buttons are visually connected without
 * space between them.
 */
export const ButtonGroup = React.forwardRef<HTMLDivElement, ButtonGroupProps>(
  function ButtonGroup(
    {
      unsafe_className,
      unsafe_style,
      buttonVariant = DEFAULT_VARIANT,
      size = DEFAULT_SIZE,
      variant: buttonGroupVariant = "spaced",
      children,
      isDisabled = false,
      isBusy = false,
      fillStyle = DEFAULT_FILL_STYLE,
      cornerStyle = DEFAULT_CORNER_STYLE,
      ...props
    },
    ref,
  ) {
    return (
      <div
        ref={ref}
        {...filterDOMProps(props)}
        className={classNames(
          unsafe_className,
          "replo--button-group",
          "flex",
          `variant--${buttonGroupVariant}`,
          `button-variant--${buttonVariant}`,
        )}
        style={unsafe_style}
      >
        <ButtonGroupProvider
          value={{
            buttonGroupVariant,
            buttonVariant,
            size,
            isDisabled,
            isBusy,
            fillStyle,
            cornerStyle,
          }}
        >
          {children}
        </ButtonGroupProvider>
      </div>
    );
  },
);
