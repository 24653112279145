import type {
  GradientValue,
  SolidOrGradient,
  SolidValue,
} from "replo-runtime/shared/types";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { SavedStyleColorAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import ColorPickerWrapper from "@editor/components/common/designSystem/color/ColorPickerWrapper";
import GradientPicker from "@editor/components/common/designSystem/color/GradientPicker";
import SolidColorPicker from "@editor/components/common/designSystem/color/SolidColorPicker";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import Input from "@editor/components/common/designSystem/Input";
import { ToggleGroup } from "@editor/components/common/designSystem/ToggleGroup";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import useGetDeletedSavedStyleValueIfNeeded from "@editor/hooks/designLibrary/useGetDeletedSavedStyleValueIfNeeded";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import {
  selectColorGradientDesignLibrary,
  selectDraftComponentId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getAutoCompletedColor,
  getFormattedColor,
  getFormattedColorWithoutOpacity,
  getHex8Color,
  isSolidColor,
} from "@editor/utils/colors";
import { getColorBadgeValue, hasDynamicData } from "@editorModifiers/utils";

import { Badge } from "@replo/design-system/components/badge";
import Tooltip from "@replo/design-system/components/tooltip";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { getSavedStyleValue } from "replo-runtime/shared/savedStyles";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { useControllableState } from "replo-utils/react/use-controllable-state";
import { v4 as uuidv4 } from "uuid";

type Props = {
  value: SolidOrGradient | ReploMixedStyleValue | null;
  onPreviewChange?(value: SolidOrGradient | null): void;
  onChange(value: SolidOrGradient | null): void;
  onRemove?(): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  openDynamicData?(): void;
  popoverTitle?: string;
  isDisabled?: boolean;
  allowColorPickerPopover?: boolean;
  popoverSideOffset?: number;
  isPopoverOpen?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  onSelectSavedStyle?(value: string): void;
  showSavedStyles?: boolean;
};

const GradientSelector: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  onRemove,
  onPreviewChange,
  onDragEnd,
  onDragStart,
  openDynamicData,
  popoverTitle = "Color",
  isDisabled,
  allowColorPickerPopover = true,
  popoverSideOffset,
  isPopoverOpen: controlledIsPopoverOpen,
  onOpenPopoverChange,
  onSelectSavedStyle,
  showSavedStyles,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useControllableState(
    controlledIsPopoverOpen,
    false,
    onOpenPopoverChange,
  );

  const { textSavedStyles, colorSavedStyles } =
    useGetDesignLibrarySavedStyles();
  const colorGradientDesignLibrary = useEditorSelector(
    selectColorGradientDesignLibrary,
  );
  const hasMixedStyleValue = isMixedStyleValue(value);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change to it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleColorAttributes>(
      String(value) ?? null,
    )?.color;
  // NOTE (Fran 2024-11-29): We know that if the saved style value is undefined or null, the saved style
  // is not deleted, and we need to show it as it is.
  const wasSavedStyleDeleted = Boolean(deletedSavedStyleValue);
  const isGradientFromDesignLibrary =
    !hasMixedStyleValue &&
    value?.type === "gradient" &&
    !isMixedStyleValue(colorGradientDesignLibrary) &&
    Boolean(colorGradientDesignLibrary);
  const isColorFromDesignLibrary =
    !hasMixedStyleValue &&
    value?.type === "solid" &&
    isDynamicDesignLibraryValue(value.color ?? null);
  const isDesignLibraryValue =
    isColorFromDesignLibrary || isGradientFromDesignLibrary;

  const isSolid = isSolidColor(value);
  const [toggleGroupValue, setToggleGroupValue] = React.useState(
    isSolid || isDesignLibraryValue ? "solid" : "linear",
  );
  const [lastValidColorValue, setLastValidColorValue] = React.useState(
    !hasMixedStyleValue ? value : null,
  );

  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const dynamicData =
    hasDynamicData(draftComponentId) && openDynamicData !== undefined;

  const getInputValue = () => {
    if (!value) {
      return "";
    }

    if (hasMixedStyleValue) {
      return "Mixed";
    }

    if (
      !wasSavedStyleDeleted &&
      (isColorFromDesignLibrary || isGradientFromDesignLibrary)
    ) {
      // NOTE (Fran 2024-11-27): If the color is from the design library, we need to
      // show the saved style value reference instead of the value itself.
      const savedStyleValueReference = isGradientFromDesignLibrary
        ? colorGradientDesignLibrary!
        : (value as SolidValue).color ?? "";
      const savedStyle = getSavedStyleValue(
        // NOTE (Sebas, 2024-11-14): We look for the color in both text and color type saved
        // styles. This is because when we select a text style with a font color we
        // want to show the color in the value indicator.
        [...textSavedStyles, ...colorSavedStyles],
        savedStyleValueReference,
      );

      return savedStyle?.type === "text"
        ? `${savedStyle?.attributes.color} (${savedStyle?.name})`
        : savedStyle?.name;
    }

    return value?.type === "gradient"
      ? "Gradient"
      : getFormattedColorWithoutOpacity(value?.color ?? "") ?? "";
  };
  const valueText = getInputValue();

  const [inputValue, setInputValue] = useOverridableState(valueText);

  const isInputReadOnly =
    hasMixedStyleValue || value?.type === "gradient" || isDesignLibraryValue;

  const handleColorChange = () => {
    // NOTE (Fran 2025-01-24): We don't need to update anything if the input is read only.
    // Meaning if the Color Applied is solid.
    // Mixed Values, Saved Styles and Gradient Colors are handled diferently.
    if (isInputReadOnly) {
      return;
    }

    const color = getAutoCompletedColor(inputValue);
    if (color) {
      // Always convert to uppercase for display in the input field
      const hexColor = getFormattedColor(color);
      setLastValidColorValue({ type: "solid", color: hexColor });
      onChange({ type: "solid", color: hexColor });
    } else if (lastValidColorValue) {
      onChange(lastValidColorValue);
    }
  };

  const handleColorRemove = () => {
    if (onRemove) {
      onRemove();
    } else {
      onChange({ type: "solid", color: "" });
    }
  };

  const getBadgeValue = () => {
    if (!value || hasMixedStyleValue) {
      return "";
    }

    if (
      !wasSavedStyleDeleted &&
      (isColorFromDesignLibrary || isGradientFromDesignLibrary)
    ) {
      // NOTE (Fran 2024-11-27): If the color is from the design library, we need to
      // show the saved style value reference instead of the value itself.
      const savedStyle = getSavedStyleValue(
        // NOTE (Sebas, 2024-11-14): We look for the color in both text and color type saved
        // styles. This is because when we select a text style with a font color we
        // want to show the color in the value indicator.
        [...textSavedStyles, ...colorSavedStyles],
        isGradientFromDesignLibrary
          ? colorGradientDesignLibrary!
          : (value as SolidValue).color ?? "",
      );

      return savedStyle?.attributes.color ?? "";
    }

    return getColorBadgeValue(value);
  };
  const badgeValue = getBadgeValue();

  if (isDisabled) {
    return (
      <div className="flex flex-grow flex-row justify-items-start">
        <Input
          unsafe_className="flex-grow"
          value={valueText}
          startEnhancer={
            <Badge
              type={!isMixedStyleValue(value) ? "color" : "mixed"}
              isFilled
              backgroundColor={badgeValue}
            />
          }
          isDisabled
          endEnhancer={() =>
            handleColorRemove &&
            valueText && (
              <FormFieldXButton
                onClick={() => {
                  setLastValidColorValue(null);
                  handleColorRemove?.();
                }}
              />
            )
          }
        />
      </div>
    );
  }

  const _onSelectSavedStyle = (value: string) => {
    setToggleGroupValue("solid");
    onSelectSavedStyle?.(value);
  };

  return (
    <div
      className="flex flex-grow flex-row justify-items-start gap-1"
      onClick={() => {
        if (isDesignLibraryValue) {
          setIsPopoverOpen(true);
        }
      }}
    >
      <Input
        unsafe_className="flex-grow"
        value={inputValue}
        placeholder="#000000"
        onChange={(e) => {
          if (!isInputReadOnly) {
            setInputValue(e.target.value);
          }
        }}
        onKeyDown={(e) => e.stopPropagation()}
        startEnhancer={
          allowColorPickerPopover ? (
            <ColorPickerWrapper
              isPopoverOpen={isPopoverOpen}
              onOpenPopoverChange={setIsPopoverOpen}
              popoverTitle={popoverTitle}
              popoverSideOffset={popoverSideOffset}
              allowsGradientSelection
              onSelectSavedStyle={_onSelectSavedStyle}
              showSavedStyles={showSavedStyles}
              trigger={
                <Tooltip content={popoverTitle ?? ""} triggerAsChild>
                  <div
                    tabIndex={0}
                    // NOTE (Sebas, 2024-11-05): This is needed to prevent selecting the input when
                    // clicking on the color picker popover trigger. If we don't do this, the input
                    // will focus and unfocus triggering an onChange event because of the onBlur event.
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <Badge
                      type={isMixedStyleValue(value) ? "mixed" : "color"}
                      isFilled
                      backgroundColor={badgeValue}
                    />
                  </div>
                </Tooltip>
              }
              value={value}
              content={
                <>
                  <ToggleGroup
                    allowsDeselect={false}
                    type="single"
                    style={{ width: "100%" }}
                    options={[
                      { label: "Solid", value: "solid" },
                      { label: "Gradient", value: "linear" },
                    ]}
                    value={toggleGroupValue}
                    onChange={(toggleValue) => {
                      let color = null;
                      if (hasMixedStyleValue) {
                        color = "#000000";
                      } else {
                        color =
                          value?.type === "solid"
                            ? value.color
                            : value?.gradient?.stops[0]?.color;
                      }

                      const hexColor = getHex8Color(color ?? "#000000");

                      setToggleGroupValue(toggleValue);

                      if (toggleValue === "solid") {
                        onChange({
                          type: "solid",
                          color: hexColor,
                        });
                      } else {
                        onChange({
                          type: "gradient",
                          gradient: {
                            tilt: "0deg",
                            stops: [
                              {
                                id: uuidv4(),
                                color: hexColor,
                                location: "0%",
                              },
                            ],
                          },
                        });
                      }
                    }}
                  />
                  {toggleGroupValue === "solid" ? (
                    <SolidColorPicker
                      value={
                        !isMixedStyleValue(value)
                          ? (value as SolidValue)?.color ?? null
                          : value
                      }
                      onChange={(value) => {
                        if (value) {
                          onChange({
                            type: "solid",
                            color: getFormattedColor(value),
                          });
                        }
                      }}
                      onPreviewChange={(value) =>
                        onPreviewChange?.({
                          type: "solid",
                          color: value,
                        })
                      }
                      onDragEnd={onDragEnd}
                      onDragStart={onDragStart}
                    />
                  ) : (
                    <GradientPicker
                      value={
                        !isMixedStyleValue(value)
                          ? (value as GradientValue)
                          : value
                      }
                      onChange={(value) => {
                        onChange(value);
                      }}
                      onPreviewChange={(value) => onPreviewChange?.(value)}
                      onDragEnd={onDragEnd}
                      onDragStart={onDragStart}
                    />
                  )}
                </>
              }
            />
          ) : (
            <Badge type="color" isFilled backgroundColor={badgeValue} />
          )
        }
        onOptionClick={handleColorRemove}
        onBlur={handleColorChange}
        onEnter={handleColorChange}
        isReadOnly={isInputReadOnly}
        endEnhancer={() =>
          handleColorRemove &&
          valueText && (
            <FormFieldXButton
              onClick={() => {
                setLastValidColorValue(null);
                handleColorRemove?.();
              }}
            />
          )
        }
        autoFocus={false}
      />
      {dynamicData && (
        <div className="flex">
          <DynamicDataButton
            tooltipText="Add Dynamic Data"
            onClick={(e) => {
              e.stopPropagation();
              openDynamicData?.();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GradientSelector;
