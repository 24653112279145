import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import { getHotKey } from "@editor/utils/getHotKey";

import Label from "@replo/design-system/components/label";
import Description from "@replo/design-system/components/label/Description";
import twMerge from "@replo/design-system/utils/twMerge";

import { useOverridableInput } from "./hooks/useOverridableInput";

export type InputSize = "sm" | "base" | "lg";

type InputOwnProps = {
  value?: string | number;
  defaultValue?: string | number;
  size?: InputSize;
  type?: string;
  placeholder?: string | null;
  autoFocus?: boolean;
  autoComplete?: string;
  startEnhancer?: React.ReactNode;
  endEnhancer?(): React.ReactNode;
  isDisabled?: boolean;
  inputContainerRef?: React.Ref<HTMLDivElement>;
  isReadOnly?: boolean;
  labelActionText?: React.ReactNode;
  // NOTE (Chance 2023-11-13): Similar to `isPhonyButton` in `Button`, this prop
  // renders a container `div` that is styled like an input but does not accept
  // user input or hold a value.
  isPhonyInput?: boolean;
  validityState?: "valid" | "invalid";
  name?: string;
  id?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  onEnter?(): void;
  onEscape?(): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyPress?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onMouseDown?(e: React.MouseEvent<HTMLInputElement>): void;
  onMouseUp?(e: React.MouseEvent<HTMLInputElement>): void;
  onMouseMove?(e: React.MouseEvent<HTMLInputElement>): void;
  onClick?(e: React.MouseEvent<HTMLInputElement>): void;
  onPaste?(e: React.ClipboardEvent<HTMLInputElement>): void;
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding classnames to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system.
   */
  unsafe_className?: string;
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding classnames to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system.
   */
  unsafe_inputClassName?: string;
  /**
   * **IMPORTANT** (Chance 2023-11-10): Adding classnames to design system
   * components should be considered an escape hatch and avoided if possible.
   * Things like spacing and layout can generally be handled with wrapper layout
   * components. Design variations should be considered in the design system.
   */
  unsafe_style?: React.CSSProperties;
  allowsDragOnStartEnhancer?: boolean;
  isFocused?: boolean;
  isRequired?: boolean;
  min?: string | number;
  max?: string | number;
  labelAction?(): void;
  maxLength?: number;
  allowsDynamicData?: boolean;
  onClickDynamicData?(): void;
  shouldSelectTextOnFocus?: boolean;
  onOptionClick?(): void;
  startEnhancerClassName?: string;
  horizontal?: boolean;
  label?: React.ReactNode;
  description?: React.ReactNode;
};

export type InputProps = InputOwnProps &
  Omit<
    React.ComponentPropsWithRef<"input">,
    keyof InputOwnProps | "className" | "style"
  >;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  function Input(
    {
      placeholder,
      size = "sm",
      type = "text",
      description,
      autoFocus = false,
      autoComplete = "on",
      horizontal = false,
      label,
      labelActionText,
      labelAction,
      startEnhancer,
      endEnhancer,
      isDisabled = false,
      validityState,
      isReadOnly,
      isPhonyInput,
      id,
      inputContainerRef,
      onBlur,
      onFocus,
      onEnter,
      onEscape,
      onKeyPress,
      onMouseDown,
      onMouseUp,
      onMouseMove,
      unsafe_className: className,
      unsafe_inputClassName: inputClassName,
      allowsDragOnStartEnhancer = false,
      unsafe_style: style,
      // TODO (Noah, Chance, REPL-9830): We need to fix the controlledFocus
      // of these inputs, currently it's broken
      isFocused: _controlledFocus = false,
      isRequired = false,
      allowsDynamicData = false,
      onClickDynamicData,
      startEnhancerClassName,
      shouldSelectTextOnFocus = false,
      onOptionClick,
      ...props
    }: InputOwnProps,
    forwardedRef,
  ) {
    const inputClassNames = twMerge(
      "bg-inherit text-default border-0 rounded w-full focus:outline-none",
      "placeholder:text-subtle placeholder:font-normal",
      "disabled:cursor-not-allowed",
      size === "sm" && "p-small text-xs h-small leading-small",
      size === "base" && "p-base text-sm h-base leading-base",
      size === "lg" && "px-3 h-10",
      startEnhancer && size === "sm" && "pl-[6px]",
      startEnhancer && size === "base" && "pl-2",
      startEnhancer && size === "lg" && "pl-2",
      endEnhancer && size === "sm" && "pr-[6px]",
      endEnhancer && size === "base" && "pr-2",
      endEnhancer && size === "lg" && "pr-2",
      inputClassName,
    );

    const enhancerSizeClasses = {
      sm: "text-xs p-1 pl-[6px]",
      base: "text-sm p-[4.75px] pl-2",
      lg: "text-base p-1",
    }[size];

    const enhancerClassNames = twMerge(
      "flex items-center text-subtle px-1",
      enhancerSizeClasses,
    );

    function onKeyPressInput(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === "Enter" && onEnter) {
        onEnter?.();
      }
      if (e.key === "Escape" && onEscape) {
        onEscape?.();
      }
      return onKeyPress?.(e);
    }

    const shouldAddMouseHandlers =
      !Boolean(startEnhancer) || !allowsDragOnStartEnhancer;

    const onInputMouseUp = (
      e: React.MouseEvent<HTMLInputElement, globalThis.MouseEvent>,
    ) => {
      onMouseUp?.(e);
      if (onOptionClick) {
        const hotkey = getHotKey(e);
        if (hotkey === "altKey") {
          onOptionClick();
        }
      }
    };
    const endEnhancerContent = endEnhancer?.();

    return (
      <div className={twMerge(horizontal && "flex", "w-full")}>
        {(label || labelActionText) && (
          <Label
            horizontal={horizontal}
            size={size}
            label={label}
            labelAction={labelAction}
            labelActionText={labelActionText}
          />
        )}
        {/* NOTE (Patrick, 2024-12-10): This first div with w-full class is here so when we're in horizontal mode, 
          the description won't be flex'ed out to the right of the input, but displayed below it as a block
        */}
        <div className="w-full h-full">
          <div className="flex w-full h-full gap-2">
            <div
              className={twMerge(
                "bg-light-surface flex justify-content-around w-full items-center text-default rounded transition-all duration-75 relative",
                isDisabled && "opacity-50 cursor-not-allowed",
                validityState !== "invalid" &&
                  "focus-within:outline focus-within:outline-primary focus-within:outline-1",
                validityState === "invalid" &&
                  "bg-danger-emphasis border-2 border-danger",
                className,
              )}
              ref={inputContainerRef}
              // NOTE (Sebas, 2024-06-27): We need to stop propagation of the keydown event
              // to prevent the hotkeys from being triggered.
              onKeyDown={(e) => e.stopPropagation()}
            >
              {startEnhancer && (
                <div
                  className={twMerge(
                    enhancerClassNames,
                    "pr-0",
                    startEnhancerClassName,
                  )}
                  style={{
                    cursor: allowsDragOnStartEnhancer
                      ? "ns-resize"
                      : style?.cursor,
                  }}
                  onBlur={onBlur}
                  onFocus={onFocus}
                >
                  {startEnhancer}
                </div>
              )}
              {isPhonyInput ? (
                <div className={inputClassNames} style={style} id={id}></div>
              ) : (
                <input
                  {...props}
                  className={inputClassNames}
                  style={style}
                  type={type}
                  placeholder={placeholder ?? undefined}
                  autoFocus={autoFocus}
                  autoComplete={autoComplete}
                  disabled={isDisabled}
                  readOnly={isReadOnly}
                  onBlur={onBlur}
                  onFocus={(event) => {
                    onFocus?.(event);
                    if (!event.defaultPrevented && shouldSelectTextOnFocus) {
                      event.target.select();
                    }
                  }}
                  onKeyPress={onKeyPressInput}
                  onMouseDown={shouldAddMouseHandlers ? onMouseDown : undefined}
                  onMouseUp={
                    shouldAddMouseHandlers ? onInputMouseUp : undefined
                  }
                  onMouseMove={shouldAddMouseHandlers ? onMouseMove : undefined}
                  ref={forwardedRef}
                  id={id}
                  required={isRequired}
                />
              )}
              {endEnhancerContent && (
                <span className={enhancerClassNames}>{endEnhancerContent}</span>
              )}
            </div>
            {allowsDynamicData && (
              <DynamicDataButton
                onClick={(e) => {
                  e.stopPropagation();
                  onClickDynamicData?.();
                }}
              />
            )}
          </div>
          {description && (
            <Description
              validityState={validityState}
              size={size}
              description={description}
            />
          )}
        </div>
      </div>
    );
  },
);

export default Input;

export function DebouncedInput({
  value,
  onValueChange,
  ...props
}: Omit<InputProps, "defaultValue" | "onChange"> & {
  value: string;
  onValueChange(value: string): any;
}) {
  const inputProps = useOverridableInput({ value, onValueChange });
  return <Input {...props} {...inputProps} />;
}
