import type { Component } from "schemas/component";
import type {
  RenderComponentProps,
  ReploShopifyOptionValue,
} from "../../../shared/types";

import * as React from "react";

import { isString } from "replo-utils/lib/type-check";

import {
  RenderEnvironmentContext,
  ReploElementContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mergeContext } from "../../../shared/utils/context";
import { mapNull } from "../../../shared/utils/optional";
import { withLiquidAlternate } from "../../../shared/utils/withLiquidAlternate";
import { getProduct } from "../../ReploProduct";
import { fakeSwatches } from "../../utils/fakeSwatches";
import { isReploShopifyOptionList } from "../../utils/option";
import { enhanceVariantsAndOptions } from "../../utils/product";
import { ReploComponent } from "../ReploComponent";
import ReploLiquidChunk from "../ReploLiquid/ReploLiquidChunk";

interface OptionComponentProps {
  component: Component;
  extraAttributes: RenderComponentProps["extraAttributes"];
  context: RenderComponentProps["context"];
  template: Component;
  activeOptionName?: string;
  option?: ReploShopifyOptionValue;
  index: number;
  isSelectedOverride?: boolean;
  isUnavailableOverride?: boolean;
  onClickOverride?: boolean;
}

interface OptionComponentImplProps extends OptionComponentProps {
  childComponent: Component;
}

function OptionComponent(props: OptionComponentProps) {
  const childComponent =
    props.component.children?.[props.index] ?? props.template;
  if (!childComponent) {
    return null;
  }

  return <OptionComponentImpl {...props} childComponent={childComponent} />;
}

function OptionComponentImpl({
  component,
  template,
  extraAttributes,
  activeOptionName,
  option,
  context,
  index,
  isSelectedOverride,
  isUnavailableOverride,
  onClickOverride,
  childComponent,
}: OptionComponentImplProps) {
  const selectedOptionValues =
    context.state.product?.selectedOptionValues ?? {};

  const thisOptionIsSelected =
    isSelectedOverride ??
    Boolean(
      activeOptionName &&
        option?.title === selectedOptionValues[activeOptionName],
    );
  const nextContext = mergeContext(context, {
    attributes: {
      _currentOption: option,
      // TODO: Remove once we migrate current pages that use this attribute
      _currentOptionValue: option?.title,
    },
    attributeKeyToComponentId: {
      _currentOption: component.id,
      _currentOptionValue: component.id,
    },
    state: {
      optionSelect: {
        isOptionValueUnavailable: isUnavailableOverride ?? !option?.available,
        isSelected: thisOptionIsSelected,
      },
    },
  });

  const repeatedIndex = template.id === childComponent.id ? index : 0;

  return (
    <ReploComponent
      key={index}
      component={childComponent}
      defaultActions={
        // NOTE (Matt 2024-05-28): The onClickOverride attribute is used by the liquid
        // version of this component to ensure that all associated html attributes
        // of the onClick action are applied to the pre-hydrated HTML to reduce
        // differences upon hydration.
        onClickOverride || (activeOptionName && option?.title)
          ? {
              actions: {
                onClick: [
                  {
                    id: `alchemy:selectOptionValue`,
                    type: "setActiveOptionValue",
                    value: {
                      label: activeOptionName!,
                      value: option?.title ?? "",
                    },
                  },
                ],
              },
              placement: "after",
            }
          : undefined
      }
      extraAttributes={{
        ...extraAttributes,
        role: "option",
        "aria-selected": thisOptionIsSelected ?? false,
      }}
      context={nextContext}
      repeatedIndexPath={`${context.repeatedIndexPath}.${repeatedIndex}`}
    />
  );
}

const OptionSelect: React.FC<RenderComponentProps> = (props) => {
  const { component, context, componentAttributes, extraAttributes } = props;
  const { option, template } = getCustomProps(component);

  const optionName = option?.label;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const swatches = useRuntimeContext(RuntimeHooksContext).useSwatches();
  const {
    fakeProducts,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const isShopifyProductsLoading =
    useRuntimeContext(RuntimeHooksContext).useIsShopifyProductsLoading();
  const productMetafieldValues =
    useRuntimeContext(RuntimeHooksContext).useShopifyProductMetafieldValues();
  const variantMetafieldValues =
    useRuntimeContext(RuntimeHooksContext).useShopifyVariantMetafieldValues();
  const { elementType } = useRuntimeContext(ReploElementContext);

  // Note (Chance 2023-08-08) We should guard against this and ensure that
  // `_options` is typed correctly, but since it's currently typed as `any` this
  // provides an extra guard to at least prevent confusing errors for users.
  const attributeOptions = isReploShopifyOptionList(
    context.attributes?._options,
  )
    ? context.attributes!._options
    : [];

  // NOTE (Evan, 9/8/23) This will be the product from props (if there is one),
  // falling back to the product from context (if there is one). If neither of these
  // exists, we fall back to attributeOptions below. This allows enhanceVariantsAndOptions
  // to run even when no product prop is defined (as is the default when you drag it in).
  const product = (() => {
    if (component.props._product) {
      const componentProduct = getProduct(component.props._product, context, {
        products,
        currencyCode,
        moneyFormat,
        productMetafieldValues,
        variantMetafieldValues,
        isEditor: isEditorApp,
        // Note (Noah, 2024-03-20, USE-824): Fall back to null since if the
        // product was not found (e.g. is a reference to a product which no
        // longer exists) we want this component to behave as if no direct
        // product was specified, so that it pulls options from the context
        // (ancestor product component)
        fallbackStrategy: null,
        fakeProducts,
        isShopifyProductsLoading,
        language,
        templateProduct,
      });
      // NOTE (Gabe 2024-02-02): If the product being gotten is not the same as
      // the currentProduct (in context) then we use the component configured
      // product. If it's the same as the one in context (aka the Current
      // Product) then we just allow that one to be returned. This is so that if
      // a user configures the option select to use the same product as it's
      // parent product component, we'll ensure that we use the variants/options
      // from the current product.
      if (
        componentProduct &&
        context.state.product?.product?.variantId &&
        String(componentProduct?.productId) !==
          String(context?.state.product?.product?.productId)
      ) {
        return componentProduct;
      }
    }
    if (context.state.product) {
      return context.state.product.product;
    }
    return null;
  })();

  const options =
    mapNull(product, (product) => {
      const showOptionsNotSoldTogether =
        component.props._showOptionsNotSoldTogether ?? false;
      const { options } = enhanceVariantsAndOptions({
        product,
        variantMetafieldValues,
        swatches: [...swatches, ...fakeSwatches],
        selectedOptionValues: context.state.product?.selectedOptionValues ?? {},
        showOptionsNotSoldTogether,
      });
      return options;
    }) ?? attributeOptions;

  const activeOption = optionName
    ? options.find((option) => option.name === optionName) ?? options[0]
    : options[0];

  if (!template || !activeOption) {
    return null;
  }

  // NOTE (Gabe 2023-07-21): This hides options on published pages that don't
  // exist for the product in question. This is only relevant on Product
  // Templates where the template may have been configured with a product that
  // has options not consistent with the product for which the template is now
  // being used.
  if (
    elementType === "shopifyProductTemplate" &&
    !isEditorApp &&
    optionName &&
    !context.state.product?.product?.options.find((o) => o.name === optionName)
  ) {
    return null;
  }
  let activeOptionValues = activeOption?.values;

  // NOTE (Gabe 2024-04-15): We should only use option.values to filter from
  // `activeOption.values` if it is defined as is a subset of activeOption.values.
  // If it is not a subset then we know this component was configured for a
  // product other than the one we are currently using and we should not filter.
  const optionValuesAreSubsetOfActiveOption = option?.values?.every((value) =>
    activeOption?.values?.find(({ title }) => title === value),
  );
  if (option?.values && optionValuesAreSubsetOfActiveOption) {
    activeOptionValues = activeOption?.values?.filter(
      ({ title }) => !option.values || option.values.includes(title),
    );
  }

  return (
    <div {...componentAttributes} role="listbox" data-replo-option-select>
      {activeOptionValues?.map((value, index) => (
        <OptionComponent
          key={value.title}
          component={component}
          template={template}
          extraAttributes={extraAttributes}
          activeOptionName={activeOption.name}
          option={value}
          context={context}
          index={index}
        />
      ))}
    </div>
  );
};

const OptionSelectLiquid: React.FC<RenderComponentProps> = (props) => {
  const { component, context, componentAttributes, extraAttributes } = props;
  const { option, template } = getCustomProps(component);
  if (!template) {
    return null;
  }
  const optionName = option?.label;
  const index = 0;
  const childComponent = component.children?.[index] ?? template;
  if (!childComponent) {
    return null;
  }

  const children = component.children ?? [template];

  return (
    <ReploLiquidChunk>
      {`{% capture captured_reploOptionSelectName %}${optionName ? escape(optionName) : ""}{% endcapture %}`}
      {`{% assign reploOptionSelectName = captured_reploOptionSelectName | url_decode %}`}
      {`{% capture reploOptionValues %}${
        option?.values ? `||${option.values.join("||")}||` : ""
      }{% endcapture %}`}
      {`{% capture reploOptionDelimiter %}||{% endcapture %}`}
      {/* NOTE (Gabe 2023-08-31): if no optionName is provided we default to the first option. */}
      {`{% if reploOptionSelectName == blank %}`}
      {`{% capture reploOptionSelectName %}{{product.options[0]}}{% endcapture %}`}
      {`{% endif %}`}
      {/*
        NOTE (Martin 2024-04-26): if shopifyProductTemplate element then we
        don't render the wrapping div if reploOptionSelectName is not found on
        product.options in order to match what we do in the runtime component.
        For more info look for: NOTE (Gabe 2023-07-21) above.
      */}
      {context.elementType === "shopifyProductTemplate" &&
        `{% if product.options contains reploOptionSelectName %}`}
      <div {...componentAttributes} role="listbox" data-replo-option-select>
        {context.elementType !== "shopifyProductTemplate" && (
          <>
            {/*
              NOTE (Martin 2024-04-26): if reploOptionSelectName is not found on
              product.options we should default to the first option in order to
              match what we do in the runtime component.
            */}
            {`{% unless product.options contains reploOptionSelectName %}`}
            {`{% capture reploOptionSelectName %}{{product.options[0]}}{% endcapture %}`}
            {`{% endunless %}`}
          </>
        )}
        {`{% if product.options contains reploOptionSelectName %}`}
        {`{% for reploRepeatedOptionValue in product.options_by_name[reploOptionSelectName].values %}`}
        {`{% assign optionValueName = reploOptionDelimiter | append: reploRepeatedOptionValue | append: reploOptionDelimiter | escape %}`}
        {`{% if reploOptionValues == blank or reploOptionValues contains optionValueName %}`}
        {`{% capture reploOptionKey %}option{% endcapture %}`}
        {`{% assign optionPositionKey = reploOptionKey | append: product.options_by_name[reploOptionSelectName].position %}`}
        {/* NOTE (Matt 2024-03-27): this case statement allows us to render more than the first child pre-hydration while still using liquid options */}
        {`{% case forloop.index0 %}`}
        {children.map((_, childIndex) => {
          return (
            <>
              {`{% when ${childIndex} %}`}
              {`{% if reploSelectedVariant == blank or reploSelectedVariant[optionPositionKey] == reploRepeatedOptionValue %}`}
              <OptionComponent
                // biome-ignore lint/correctness/useJsxKeyInIterable: ignore key
                component={component}
                template={template}
                extraAttributes={extraAttributes}
                context={context}
                index={childIndex}
                isSelectedOverride
                isUnavailableOverride
                onClickOverride
              />
              {`{% else %}`}
              <OptionComponent
                // biome-ignore lint/correctness/useJsxKeyInIterable: ignore key
                component={component}
                template={template}
                extraAttributes={extraAttributes}
                context={context}
                index={childIndex}
                isSelectedOverride={false}
                isUnavailableOverride
                onClickOverride
              />
              {`{% endif %}`}
            </>
          );
        })}
        {`{% else %}`}
        {`{% if reploSelectedVariant == blank or reploSelectedVariant[optionPositionKey] == reploRepeatedOptionValue %}`}
        <OptionComponent
          component={component}
          template={template}
          extraAttributes={extraAttributes}
          context={context}
          index={index}
          isSelectedOverride
          isUnavailableOverride
          onClickOverride
        />
        {`{% else %}`}
        <OptionComponent
          component={component}
          template={template}
          extraAttributes={extraAttributes}
          context={context}
          index={index}
          isSelectedOverride={false}
          isUnavailableOverride
          onClickOverride
        />
        {`{% endif %}`}
        {`{% endcase %}`}
        {`{% endif %}`}
        {"{% endfor %}"}
        {"{% endif %}"}
      </div>
      {context.elementType === "shopifyProductTemplate" && `{% endif %}`}
    </ReploLiquidChunk>
  );
};

function getCustomProps(component: Component) {
  const option = component.props._option;
  const template = component.children?.length
    ? component.children[0]
    : undefined;

  return {
    template,
    option: isString(option) ? { label: option } : option,
  };
}

export default withLiquidAlternate(OptionSelect, OptionSelectLiquid);
