import type * as React from "react";
import type { BoxShadow } from "replo-runtime/shared/types";

import { getFormattedColorWithoutOpacity } from "@editor/utils/colors";

import { v4 as uuidv4 } from "uuid";

import { regexForSplittingPropValuesWithDynamicData } from "./designLibrary";

export const getBoxShadowString = (
  boxShadows: BoxShadow[],
  withoutOpacity = false,
) => {
  const propertiesOrder = [
    "shadowType",
    "offsetX",
    "offsetY",
    "blur",
    "spread",
    "shadowColor",
  ] as const;

  const boxShadowsString = boxShadows.map((value) => {
    const orderedShadowValues = propertiesOrder
      .map((p) => {
        if (value[p] === "dropShadow") {
          return null;
        }
        if (value[p]?.includes("#") && withoutOpacity) {
          return getFormattedColorWithoutOpacity(value[p]);
        }
        return value[p];
      })
      .filter((v) => v !== null);

    return orderedShadowValues.join(" ");
  });
  const newBoxShadowsString = boxShadowsString.join(",").trim();
  if (newBoxShadowsString.includes("none")) {
    return "none";
  }
  return newBoxShadowsString;
};

// Convert string shadows to multiple objects for each individual shadow.
export const parseBoxShadows = (boxShadow: string[]) => {
  const boxShadowObject = boxShadow.map((shadow) => {
    const boxShadowComponents = shadow.match(
      regexForSplittingPropValuesWithDynamicData,
    )!;
    const isNone = boxShadowComponents[0] === "none";
    const isInset = boxShadowComponents[0] === "inset";
    const id = uuidv4();

    if (isInset) {
      const [, offsetX, offsetY, blur, spread, shadowColor] =
        boxShadowComponents;
      return {
        id,
        shadowType: "inset" as const,
        offsetX: offsetX!,
        offsetY: offsetY!,
        blur: blur!,
        spread: spread!,
        shadowColor: shadowColor!,
      };
    }
    if (isNone) {
      return {
        id,
        shadowType: "none" as const,
        offsetX: "0px",
        offsetY: "0px",
        blur: "0px",
        spread: "0px",
        shadowColor: "#00000040",
      };
    }
    const [offsetX, offsetY, blur, spread, shadowColor] = boxShadowComponents;

    return {
      id,
      shadowType: "dropShadow" as const,
      offsetX: offsetX!,
      offsetY: offsetY!,
      blur: blur!,
      spread: spread!,
      shadowColor: shadowColor!,
    };
  });

  return boxShadowObject;
};
