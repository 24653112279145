import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

export function getImageSourceComponentActions(args: {
  imageUrl: string;
  targetComponentId: string | undefined;
  imageSourceProp: any;
  canvas: EditorCanvas;
}) {
  const { canvas, imageSourceProp, imageUrl, targetComponentId } = args;
  const actions: UseApplyComponentActionType[] = [
    {
      type: "setStyles",
      value: { __imageSource: imageUrl },
      componentId: targetComponentId,
    },
    // NOTE (Gabe 2023-08-24, USE-380): When a user updates the image source we should
    // make sure to clear the legacy prop.src value so that extra data is not
    // stored in the component json
    {
      type: "deleteProps",
      propName: "src",
      componentId: targetComponentId,
      analyticsExtras: {
        actionType: "edit",
        createdBy: "replo",
      },
    },
  ];
  if (canvas !== "desktop" && !imageSourceProp) {
    actions.push({
      type: "setProps",
      value: { style: { __imageSource: imageUrl } },
      componentId: targetComponentId,
    });
  }
  return actions;
}
