import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import { selectDraftComponentsInnerTextsAndIds } from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

import isEmpty from "lodash-es/isEmpty";
import { filterNulls } from "replo-utils/lib/array";

import useGetDesignLibraryComponentReferences from "./useGetDesignLibraryComponentReferences";

function useResetDesignLibraryTextValue() {
  const store = useEditorStore();
  const { getDesignLibraryComponentReferences } =
    useGetDesignLibraryComponentReferences();

  const getRemoveDesignLibraryValuesActions = (newTag?: string) => {
    const textsAndIds = selectDraftComponentsInnerTextsAndIds(store.getState());
    const textIds = textsAndIds?.map(({ id }) => id) ?? [];

    if (textIds.length === 0) {
      return null;
    }

    const componentReferences = getDesignLibraryComponentReferences(textIds);

    if (isEmpty(componentReferences)) {
      return null;
    }

    // NOTE (Sebas, 2025-01-21): We only support one design library.
    const designLibrary = Object.values(componentReferences.library)[0]?.styles;

    if (!designLibrary) {
      return null;
    }

    const setPropActions =
      textsAndIds?.map(({ id, text, appliedSavedStyleId }) => {
        if (!appliedSavedStyleId && !newTag) {
          return null;
        }

        const designLibraryStyles = appliedSavedStyleId
          ? (designLibrary[appliedSavedStyleId]
              ?.attributes as SavedStyleTextAttributes)
          : null;

        const parsedTag = newTag === "P" ? "p" : `h${newTag}`;

        return {
          type: "setProps" as const,
          componentId: id,
          value: {
            // NOTE (Sebas, 2025-01-22): In case we are setting a new tag from the tag selector, we parse it
            // and then apply it here. If not, we calculate the tag based on the design library styles or the
            // default tag.
            text: `<${(newTag && parsedTag) ?? (designLibraryStyles ? designLibraryStyles.htmlTag : "p")}>${text}</${(newTag && parsedTag) ?? (designLibraryStyles ? designLibraryStyles.htmlTag : "p")}>`,
          },
        };
      }) ?? [];

    const setStyleActions =
      textsAndIds?.map(({ id, appliedSavedStyleId }) => {
        if (!appliedSavedStyleId) {
          return null;
        }

        const designLibraryStyles = appliedSavedStyleId
          ? (designLibrary[appliedSavedStyleId]
              ?.attributes as SavedStyleTextAttributes)
          : null;

        return {
          type: "setStyles" as const,
          componentId: id,
          value: {
            color: getValueToSet(designLibraryStyles, "color"),
            fontFamily: getValueToSet(designLibraryStyles, "fontFamily"),
            fontSize: getValueToSet(designLibraryStyles, "fontSize"),
            fontWeight: getValueToSet(designLibraryStyles, "fontWeight"),
            letterSpacing: getValueToSet(designLibraryStyles, "letterSpacing"),
            lineHeight: getValueToSet(designLibraryStyles, "lineHeight"),
            textAlign: getValueToSet(designLibraryStyles, "textAlign"),
            textDecoration: getValueToSet(
              designLibraryStyles,
              "textDecoration",
            ),
            textTransform: getValueToSet(designLibraryStyles, "textTransform"),
            textShadow: getValueToSet(designLibraryStyles, "textShadow"),
            // NOTE (Sebas, 2025-01-22): I can't use the function here because we use
            // different default value properties from the design library.
            fontStyle: designLibraryStyles
              ? designLibraryStyles?.textDecoration ??
                styleAttributeToEditorData.fontStyle.defaultValue
              : null,
            __textStroke: designLibraryStyles
              ? designLibraryStyles?.textStroke ??
                styleAttributeToEditorData.__textStroke.defaultValue
              : null,
          },
        };
      }) ?? [];

    return [...filterNulls(setPropActions), ...filterNulls(setStyleActions)];
  };

  return {
    getRemoveDesignLibraryValuesActions,
  };
}

const getValueToSet = (
  designLibraryStyles: SavedStyleTextAttributes | null,
  attribute: keyof SavedStyleTextAttributes,
) => {
  const valueFromDesignLibrary = designLibraryStyles?.[attribute];
  if (valueFromDesignLibrary) {
    return valueFromDesignLibrary;
  }

  const defaultValue =
    styleAttributeToEditorData[
      attribute as keyof typeof styleAttributeToEditorData
    ].defaultValue;

  return typeof defaultValue === "string" ? defaultValue : null;
};

export default useResetDesignLibraryTextValue;
