import type {
  LiquidProduct,
  MetafieldsMappings,
  MetafieldValuesMapping,
  MetafieldValuesMappingWithoutType,
  ReploShopifyOptionKey,
  ReploShopifyProduct,
  ReploShopifyVariant,
  ShopifySellingPlanGroup,
  StoreProduct,
  StoreSellingPlanGroup,
  StoreVariant,
  VariantMetafieldMapping,
} from "../types";

import mapValues from "lodash-es/mapValues";
import { round } from "replo-utils/lib/math";
import { isNotNullish } from "replo-utils/lib/misc";

import { formatCurrencyWithShopifyMoneyFormat } from "../liquid";
import {
  getProductOptionValues,
  isOptionValueAvailable,
} from "../utils/component";

/**
 * Given a metafield values mapping where the values include the metafield types,
 * return one which only has the values themselves.
 */
export const mapEntityIdMetafieldsMappingToMappingWithoutType = (
  mapping?: MetafieldValuesMapping,
): MetafieldValuesMappingWithoutType | undefined => {
  if (!mapping) {
    return undefined;
  }
  return mapValues(mapping, (keyToMetafieldValues) => {
    return mapValues(keyToMetafieldValues, (metafieldValue) => {
      return metafieldValue.value;
    });
  });
};

/**
 * Given a store variant (which comes from the GraphQL api or from alchemy-data.liquid),
 * return a normalized version of the variant that can be used in the runtime.
 * @param args.variant Store variant to normalize
 * @param args.productId Id of the product this variant belongs to
 * @param args.productHandle Handle of the product this variant belongs to
 * @param args.quantity Quantity of items we're displaying in this variant
 */
const mapStoreVariantToReploState = (args: {
  variant: StoreVariant;
  productId: number;
  productHandle: string;
  quantity: number;
  currencyCode: string;
  metafields: VariantMetafieldMapping;
  product: StoreProduct;
  moneyFormat: string | null;
  language: string;
}): ReploShopifyVariant => {
  const {
    variant,
    productId,
    productHandle,
    quantity,
    metafields,
    product,
    moneyFormat,
    language,
    currencyCode,
  } = args;
  const price = Number(variant.price);
  const compareAtPrice = variant.compare_at_price;
  const variantPrice = variant.price;
  const compareAtPriceNumber = Number(compareAtPrice);
  const roundedVariantPrice = round(price, 0);
  const roundedCompareAtPrice = round(compareAtPriceNumber, 0);
  return {
    id: variant.id,
    sku: variant.sku,
    variantId: variant.id,
    productId: productId,
    title: variant.title,
    name: variant.name,
    option1: variant.option1,
    option2: variant.option2,
    option3: variant.option3,
    available: variant.available,
    priceWithoutSellingPlanDiscount: String(price),
    priceWithoutSellingPlanDiscountRounded: String(roundedVariantPrice),
    displayPriceWithoutSellingPlanDiscount:
      formatCurrencyWithShopifyMoneyFormat(Number(variantPrice) * quantity, {
        currencyCode,
        language,
        moneyFormat,
      }),
    price: String(price),
    priceRounded: String(roundedVariantPrice),
    compareAtPrice: isNotNullish(compareAtPrice)
      ? String(compareAtPrice)
      : null,
    compareAtPriceRounded: isNotNullish(compareAtPrice)
      ? String(roundedCompareAtPrice)
      : null,
    /**
     * Note (Noah, 2022-11-12, REPL-5028): If you add more currency formatted fields here,
     * be sure to update useComponentRerenderKey to look for them as well, otherwise components
     * which use these fields will not render properly in international stores in Shopify
     * Markets (I tried to figure out a way to enforce this with typescript, but I couldn't
     * think of a good one)
     */
    compareAtDisplayPrice: isNotNullish(compareAtPrice)
      ? formatCurrencyWithShopifyMoneyFormat(compareAtPriceNumber * quantity, {
          currencyCode,
          language,
          showCents: true,
          moneyFormat,
        })
      : null,
    compareAtDisplayPriceRounded: isNotNullish(compareAtPrice)
      ? formatCurrencyWithShopifyMoneyFormat(roundedCompareAtPrice * quantity, {
          currencyCode,
          language,
          showCents: false,
          moneyFormat,
        })
      : null,
    displayPrice: formatCurrencyWithShopifyMoneyFormat(price * quantity, {
      currencyCode,
      language,
      showCents: true,
      moneyFormat,
    }),
    displayPriceWithoutQuantity: formatCurrencyWithShopifyMoneyFormat(price, {
      currencyCode,
      language,
      showCents: true,
      moneyFormat,
    }),
    displayPriceRounded: formatCurrencyWithShopifyMoneyFormat(
      roundedVariantPrice * quantity,
      {
        currencyCode,
        language,
        showCents: false,
        moneyFormat,
      },
    ),
    compareAtPriceDifference: isNotNullish(compareAtPrice)
      ? formatCurrencyWithShopifyMoneyFormat(
          compareAtPriceNumber * quantity - price * quantity,
          {
            currencyCode,
            language,
            hideSymbol: true,
            moneyFormat,
          },
        )
      : null,
    compareAtPriceDifferencePercentage: `${
      compareAtPrice
        ? getPercentageDifference(
            compareAtPriceNumber * quantity,
            price * quantity,
          )
        : 0
    }%`,
    compareAtPriceDifferenceRounded: isNotNullish(compareAtPrice)
      ? formatCurrencyWithShopifyMoneyFormat(
          round(roundedCompareAtPrice * quantity - price * quantity, 0),
          {
            currencyCode,
            language,
            showCents: false,
            hideSymbol: true,
            moneyFormat,
          },
        )
      : null,
    compareAtDisplayPriceDifference: isNotNullish(compareAtPrice)
      ? formatCurrencyWithShopifyMoneyFormat(
          compareAtPriceNumber * quantity - price * quantity,
          {
            currencyCode,
            language,
            zeroString: "0",
            moneyFormat,
          },
        )
      : null,
    compareAtDisplayPriceDifferenceRounded: isNotNullish(compareAtPrice)
      ? formatCurrencyWithShopifyMoneyFormat(
          roundedCompareAtPrice * quantity - price * quantity,
          {
            currencyCode,
            language,
            showCents: false,
            zeroString: "0",
            moneyFormat,
          },
        )
      : null,
    featuredImage: variant.featured_image?.src || null,
    productHandle,
    variantMetafields:
      mapEntityIdMetafieldsMappingToMappingWithoutType(
        metafields[variant.id],
      ) ?? {},
    sellingPlanIds:
      variant.selling_plan_ids ??
      product.selling_plan_groups?.flatMap(({ selling_plans }) =>
        selling_plans.map(({ id }) => id),
      ) ??
      [],
    sellingPlanGroupIds:
      variant.selling_plan_group_ids ??
      product.selling_plan_groups?.map(({ id }) => id) ??
      [],
  };
};

export function getDefaultSelectedVariant(
  product: StoreProduct,
  autoSelectVariant: true,
  defaultSelectedVariantId?: number,
): StoreVariant;

export function getDefaultSelectedVariant(
  product: ReploShopifyProduct | null,
  autoSelectVariant: boolean,
  defaultSelectedVariantId?: number,
  prevSelectedVariant?: ReploShopifyVariant | null,
): ReploShopifyVariant | null;

/**
 * Get either the previous products selected variant or default selected variant if it exists and autoSelectVariant is true,
 * or fall back to the first variant of the product (if product is not defined, return null)
 */
export function getDefaultSelectedVariant(
  product: ReploShopifyProduct | StoreProduct | null,
  autoSelectVariant: boolean,
  defaultSelectedVariantId?: number,
  prevSelectedVariant?: ReploShopifyVariant | null,
) {
  if (!autoSelectVariant) {
    return null;
  }

  if (!product?.variants) {
    return null;
  }

  if (prevSelectedVariant) {
    const variantWithSameTitle = product.variants.find(
      (v) => v.title === prevSelectedVariant.title,
    );
    if (variantWithSameTitle) {
      return variantWithSameTitle;
    }

    const variantWithSameOptions = product.variants.find((v) => {
      return (
        v.option1 === prevSelectedVariant.option1 &&
        v.option2 === prevSelectedVariant.option2 &&
        v.option3 === prevSelectedVariant.option3
      );
    });
    if (variantWithSameOptions) {
      return variantWithSameOptions;
    }
  }

  const selectedVariant = product.variants.find((v) => {
    if (defaultSelectedVariantId) {
      // Note (Noah, 2022-03-27): convert to strings, since in liquid
      // products the ids are integers, but in GraphQL they're strings
      return String(v.id) === String(defaultSelectedVariantId);
    }
    return v.available;
  });
  if (selectedVariant) {
    return selectedVariant;
  }

  return product.variants[0];
}

export function mapStoreProductToReploProduct(
  product: StoreProduct,
  args: {
    variantId?: number | null;
    quantity: number;
    metafields: MetafieldsMappings;
    moneyFormat: string | null;
    currencyCode: string;
    language: string;
  },
): ReploShopifyProduct {
  const {
    variantId,
    quantity,
    metafields,
    currencyCode,
    moneyFormat,
    language,
  } = args;
  const selectedStoreVariant = getDefaultSelectedVariant(
    product,
    true,
    variantId ?? undefined,
  )!;

  const selectedAlchemyVariant = mapStoreVariantToReploState({
    variant: selectedStoreVariant,
    productId: Number(product.id),
    productHandle: product.handle,
    quantity,
    currencyCode,
    metafields: metafields.variantMetafieldsMapping,
    product,
    moneyFormat,
    language,
  });

  const variants = product.variants.map((variant) =>
    mapStoreVariantToReploState({
      variant,
      productId: Number(product.id),
      productHandle: product.handle,
      quantity,
      currencyCode,
      metafields: metafields.variantMetafieldsMapping,
      product,
      moneyFormat,
      language,
    }),
  );

  const options = product.options.map((optionName, index) => {
    return {
      key: `option${index + 1}` as ReploShopifyOptionKey,
      name: optionName,
      values: getProductOptionValues(
        product.variants,
        product.options,
        optionName,
      ).map((value) => ({
        title: value,
        available: variants
          ? isOptionValueAvailable(
              variants,
              product.options,
              value,
              `option${index + 1}` as ReploShopifyOptionKey,
              selectedAlchemyVariant,
            )
          : false,
      })),
    };
  });

  const result: ReploShopifyProduct = {
    id: Number(product.id),
    productId: Number(product.id),
    title: product.title,
    type: product.type,
    images: product.images,
    featured_image: product.featured_image,
    variant: selectedAlchemyVariant,
    variantId: selectedAlchemyVariant?.id,
    variants,
    options,
    optionsValues: product.options,
    description: product.description,
    handle: product.handle,
    status: product.status,
    quantity: quantity || 1,
    sellingPlanGroups: (product.selling_plan_groups ?? []).map((group) =>
      mapStoreSellingPlanGroupToSellingPlanGroup(group),
    ),
    productMetafields: mapEntityIdMetafieldsMappingToMappingWithoutType(
      metafields?.productMetafieldsMapping?.[Number(product.id)],
    ),
  };

  return result;
}

export const mapStoreSellingPlanGroupToSellingPlanGroup = (
  storeSellingPlanGroup: StoreSellingPlanGroup,
): ShopifySellingPlanGroup => {
  return {
    id: storeSellingPlanGroup.id,
    appId: storeSellingPlanGroup.app_id,
    options: storeSellingPlanGroup.options,
    sellingPlans: storeSellingPlanGroup.selling_plans.map(
      (storeSellingPlan) => {
        return {
          id: storeSellingPlan.id,
          name: storeSellingPlan.name,
          description: storeSellingPlan.description,
          options: storeSellingPlan.options,
          priceAdjustments: storeSellingPlan.price_adjustments,
        };
      },
    ),
  };
};

const normalizePriceAdjustmentsForSellingPlanGroup = (
  group: StoreSellingPlanGroup,
): StoreSellingPlanGroup => {
  return {
    ...group,
    selling_plans: group.selling_plans.map((plan) => ({
      ...plan,
      price_adjustments: plan.price_adjustments.map((adjustment) => {
        // Note (Evan, 2023-11-29): For dollar-value adjustments (fixed prices and fixed discount amounts)
        // we want to divide the price by 100 since the value from liquid is in cents.
        const value = ["price", "fixed_amount"].includes(adjustment.value_type)
          ? adjustment.value / 100
          : adjustment.value;
        return {
          ...adjustment,
          value,
        };
      }),
    })),
  };
};

/**
 * Given a LiquidProduct, return an equivalent StoreProduct. This is useful for
 * when we initially read product data when our published-page script loads, so that
 * we can take the shape of the data we get from liquid and format it to our standardized
 * StoreProduct shape.
 */
export const mapLiquidProductToStoreProduct = (
  liquidProduct: LiquidProduct,
): StoreProduct => {
  const mapped = {
    ...liquidProduct,
    variants: liquidProduct.variants.map((variant) => {
      const variantPrice = Number(variant.price);
      const compareAtPrice = Number(variant.compare_at_price);
      return {
        ...variant,
        price: (variantPrice / 100).toString(),
        compare_at_price: compareAtPrice
          ? (compareAtPrice / 100).toString()
          : null,
        selling_plan_ids:
          variant.selling_plan_allocations?.map(
            ({ selling_plan_id }) => selling_plan_id,
          ) ?? [],
        selling_plan_group_ids:
          variant.selling_plan_allocations?.map(
            ({ selling_plan_group_id }) => selling_plan_group_id,
          ) ?? [],
      };
    }),
    selling_plan_groups: (liquidProduct.selling_plan_groups ?? []).map(
      normalizePriceAdjustmentsForSellingPlanGroup,
    ),
  };
  return mapped;
};

function getPercentageDifference(v1: number, v2: number) {
  return Math.floor(((v1 - v2) * 100) / v1);
}
