import * as React from "react";

import * as RadixAvatar from "@radix-ui/react-avatar";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

export type AvatarSize = "xxs" | "xs" | "sm" | "base" | "lg" | "xl";

type AvatarProps = {
  name?: string | null;
  icon?: React.ReactNode;
  imageSrc?: string;
  className?: string;
  size?: AvatarSize;
  imageClassName?: string;
  isSquare?: boolean;
};

const Avatar = ({
  imageSrc,
  name,
  icon,
  className,
  isSquare,
  size = "base",
  imageClassName = "",
}: AvatarProps) => {
  const sizeClass = exhaustiveSwitch({ type: size })({
    xxs: "h-4 w-4",
    xs: "h-5 w-5",
    sm: "h-6 w-6",
    base: "h-9 w-9",
    lg: "h-12 w-12",
    xl: "h-24 w-24",
  });
  const containerClassName = twMerge(
    "inline-flex items-center justify-items-center align-middle flex-shrink-0 text-white",
    isSquare ? "rounded-sm" : "rounded-full",
    sizeClass,
    className,
  );

  const fallbackClassName = classNames({
    "typ-header-small font-semibold": size === "xxs",
    "text-xs": size === "xs",
    "text-sm": size === "sm",
    "text-lg": size === "base" || size === "lg",
    "text-3xl": size === "xl",
  });

  const firstLetter = name?.charAt(0) || "?";
  const delayMs = Boolean(imageSrc) ? 600 : 0;
  return (
    <RadixAvatar.Root className={containerClassName}>
      {imageSrc && name && (
        <RadixAvatar.Image
          style={{ borderRadius: "inherit" }}
          className={twMerge("h-full w-full object-cover", imageClassName)}
          src={imageSrc}
          alt={name}
        />
      )}
      {name || icon ? (
        <RadixAvatar.AvatarFallback
          delayMs={delayMs}
          style={{ borderRadius: "inherit" }}
          className={twMerge(
            "flex h-full w-full items-center justify-center bg-blue-600 text-slate-50",
            fallbackClassName,
            imageClassName,
          )}
        >
          {icon ? icon : firstLetter?.toUpperCase()}
        </RadixAvatar.AvatarFallback>
      ) : (
        <RadixAvatar.AvatarFallback
          delayMs={delayMs}
          style={{ borderRadius: "inherit" }}
          className={twMerge(
            "h-full w-full border-2 border-dotted border-slate-400",
            imageClassName,
          )}
        ></RadixAvatar.AvatarFallback>
      )}
    </RadixAvatar.Root>
  );
};

export default Avatar;
