import type { MetricName, Order } from "schemas/generated/analyticsRead";

import * as React from "react";

import { METRIC_SORT_TOOLTIP_TEXT } from "@/features/analytics/constants";
import IconButton from "@replo/design-system/components/button/IconButton";
import Tooltip from "@replo/design-system/components/tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { BsSortUpAlt } from "react-icons/bs";

type SortIndicatorProps = {
  sortOrder: Order;
  isActiveMetric: boolean;
  sortMetric: MetricName;
  onClick: () => void;
};

const SortIndicator: React.FC<SortIndicatorProps> = ({
  isActiveMetric,
  sortOrder,
  onClick,
  sortMetric,
}) => {
  return (
    <Tooltip
      triggerAsChild
      content={
        isActiveMetric
          ? `Sort ${sortOrder === "ASC" ? "Descending" : "Ascending"}`
          : METRIC_SORT_TOOLTIP_TEXT[sortMetric]
      }
      delay={0}
    >
      <IconButton
        tooltipText={
          isActiveMetric
            ? "Change Sort Order"
            : METRIC_SORT_TOOLTIP_TEXT[sortMetric]
        }
        icon={
          sortOrder === "ASC" ? (
            <BsSortUpAlt
              className={classNames("h-[18px] w-[18px]", {
                "fill-blue-600": isActiveMetric,
                "fill-slate-500 transform scale-y-[-1]": !isActiveMetric,
              })}
            />
          ) : (
            <BsSortUpAlt
              className={classNames(
                "h-[18px] w-[18px] transform scale-y-[-1]",
                {
                  "fill-blue-600 hover:bg-blue-100": isActiveMetric,
                  "fill-slate-500": !isActiveMetric,
                },
              )}
            />
          )
        }
        isFullWidth={false}
        variant="secondary"
        className={twMerge(
          "!p-[3px] rounded gap-[6px] !h-[unset]",
          isActiveMetric && "hover:bg-blue-100",
          !isActiveMetric && "hover:fill-slate-800",
        )}
        onClick={onClick}
      />
    </Tooltip>
  );
};

interface SortButtonProps {
  sortMetric: MetricName;
  sortOrder: Order;
  isActiveMetric: boolean;
  onChange: () => void;
}

const SortButton: React.FC<SortButtonProps> = ({
  sortMetric,
  sortOrder,
  isActiveMetric,
  onChange,
}) => {
  return (
    <SortIndicator
      isActiveMetric={isActiveMetric}
      sortMetric={sortMetric}
      sortOrder={sortOrder}
      onClick={onChange}
    />
  );
};

export { SortIndicator, SortButton };
