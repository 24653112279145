import type { DataTable } from "../shared/DataTable";
import type { ProductResolutionDependencies } from "./ReploProduct";

import { dataTableColumnToEvaluator } from "../shared/DataTable";
import {
  DEFAULT_ACTIVE_CURRENCY,
  DEFAULT_ACTIVE_LANGUAGE,
  DEFAULT_MONEY_FORMAT,
} from "../shared/liquid";
import { preloadImage } from "./utils/image";

/**
 * Return the content of a given data table as an array of { column name: resolved column value }
 * @param id Data table id
 * @param dataTables Mapping of data tables (defaults to window.alchemy)
 * @param productResolutionDependencies Product resolution dependencies
 */
export function getRowObjectsFromDataTable(
  id: string,
  dataTables: Record<string, DataTable>,
  productResolutionDependencies: ProductResolutionDependencies,
): Record<string, any>[] {
  return getRowObjectsFromDataTablesMapping(
    dataTables,
    id,
    productResolutionDependencies,
  );
}

export function getRowObjectsFromDataTablesMapping(
  mapping: Record<string, DataTable>,
  id: string,
  productResolutionDependencies: ProductResolutionDependencies,
): Record<string, any>[] {
  const table = mapping[id];
  if (!table) {
    return [];
  }
  return table.data.rows.map((row: any) => {
    const object: Record<string, any> = {};
    for (const column of table.data.schema) {
      object[column.name] = dataTableColumnToEvaluator[column.type](
        row[column.id],
        productResolutionDependencies,
      );
    }
    return object;
  });
}

export function preloadAllImagesFromDataTable(
  id: string,
  dataTables: Record<string, DataTable>,
) {
  const items = getRowObjectsFromDataTable(
    id,
    dataTables,
    // Note (Noah, 2022-09-05): It's okay to hardcode the currency code/money
    // format here because we're just preloading images, we don't need to render
    // price correctly
    {
      products: [],
      currencyCode: DEFAULT_ACTIVE_CURRENCY,
      language: DEFAULT_ACTIVE_LANGUAGE,
      moneyFormat: DEFAULT_MONEY_FORMAT,
      templateProduct: null,
      isEditor: false,
      isShopifyProductsLoading: false,
    },
  );
  for (const item of items) {
    for (const [key, value] of Object.entries(item)) {
      if (
        value &&
        typeof value === "string" &&
        key.toLowerCase().includes("image")
      ) {
        preloadImage(value);
      } else if (
        value &&
        Array.isArray(value) &&
        key.toLowerCase().includes("image")
      ) {
        for (const url of value) {
          if (typeof url === "string") {
            preloadImage(url);
          }
        }
      }
    }
  }
}
