import { z } from "zod";

const persistedIndividualCanvasStateSchema = z.object({
  canvasWidth: z.number(),
  isVisible: z.boolean(),
});

export const persistedCanvasStateSchema = z
  .object({
    mobile: persistedIndividualCanvasStateSchema,
    tablet: persistedIndividualCanvasStateSchema,
    desktop: persistedIndividualCanvasStateSchema,
  })
  .describe("PersistedCanvasState");
