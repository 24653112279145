import type { ModifierGroupType } from "schemas/modifiers";

import * as React from "react";

import { BackgroundModifier } from "@editorModifiers/BackgroundModifier";
import BorderModifier from "@editorModifiers/BorderModifier";
import EffectsModifier from "@editorModifiers/EffectsModifier";
import { IconModifier } from "@editorModifiers/IconModifier";
import ImageSourceModifier from "@editorModifiers/ImageSourceModifier";
import ItemsModifier from "@editorModifiers/ItemsModifier";
import LayoutModifier from "@editorModifiers/LayoutModifier";
import PositioningModifier from "@editorModifiers/PositioningModifier";
import { RowColumnSpanModifier } from "@editorModifiers/RowColumnSpanModifier";
import SpacingModifier from "@editorModifiers/SpacingModifier";
import TextStyleModifier from "@editorModifiers/TextStyleModifier";
import VideoSourceModifier from "@editorModifiers/VideoSourceModifier";
import { VisibilityModifier } from "@editorModifiers/VisibilityModifier";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

import FontAndColorModifier from "./modifiers/FontAndColorModifier";
import SizeModifier from "./modifiers/SizeModifier";

export const ModifierGroups: React.FC<
  React.PropsWithChildren<{ groupTypes: ModifierGroupType[] }>
> = ({ groupTypes }) => {
  return (
    <>
      {groupTypes.map((groupType) => (
        <React.Fragment key={groupType.type}>
          {exhaustiveSwitch(groupType)({
            rowColumnSpans: <RowColumnSpanModifier />,
            spacing: <SpacingModifier />,
            visibility: <VisibilityModifier />,
            textStyle: ({ includeTextEditor }) => (
              <TextStyleModifier includeTextEditor={includeTextEditor} />
            ),
            fontAndColor: ({
              allowsGradientSelection,
              allowsFontSelection,
            }) => (
              <FontAndColorModifier
                allowsGradientSelection={allowsGradientSelection}
                allowsFontSelection={allowsFontSelection}
              />
            ),
            size: <SizeModifier />,
            border: <BorderModifier />,
            positioning: <PositioningModifier />,
            background: <BackgroundModifier />,
            items: ({
              allowsDynamicDataWhenAvailable,
              field,
              title,
              allowedItemTypes,
            }) => (
              <ItemsModifier
                allowsDynamicDataWhenAvailable={allowsDynamicDataWhenAvailable}
                allowedItemTypes={allowedItemTypes}
                title={title}
                field={field}
              />
            ),
            layout: () => <LayoutModifier />,
            effects: <EffectsModifier />,
            icon: <IconModifier />,
            imageSource: <ImageSourceModifier />,
            videoSource: <VideoSourceModifier />,
          })}
        </React.Fragment>
      ))}
    </>
  );
};
