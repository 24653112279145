import type { FlowStepConfig, FlowStepData } from "schemas/generated/flow";

import { z } from "zod";

import { brandDetailsSchema } from "./ai";
import { componentTemplateIndustryNamesSchema } from "./componentTemplates";
import { savedColorStyleSchema, savedTextStyleSchema } from "./designLibrary";

// #region Flow input components schemas
const optionSchema = z.object({
  value: z.string(),
  label: z.string(),
  hasInput: z.boolean(),
  extraData: z.record(z.string(), z.unknown()).optional(),
});

const selectableButtonWithInputPropsSchema = z.object({
  type: z.literal("selectable-button"),
  label: z.string(),
  options: z.array(optionSchema),
});

const textInputPropsSchema = z.object({
  type: z.literal("input"),
  label: z.string(),
  placeholder: z.string(),
  inputType: z.literal("text"),
  isRequired: z.boolean(),
});

const stepResultPropertySchema = z.union([
  z.literal("buildingFor"),
  z.literal("isUserReferredOrInvited"),
  z.literal("wasUrlProvided"),
  z.literal("isUserInstallingFromShopify"),
]);

// #endregion

const flowSteps = [
  // #region Onboarding flow steps
  "onboarding.user.full-name",
  "onboarding.user.import-from-url",
  "onboarding.user.how-did-you-hear-about-us",
  "onboarding.user.who-are-you-building-pages-for",
  "onboarding.user.workspace-name",
  "onboarding.user.what-do-you-want-to-do-in-replo",
  "onboarding.user.who-are-you-building-pages-for.revenue",
  "onboarding.user.which-best-describes-your-industry",
  "onboarding.user.brand-details",
  "onboarding.user.saved-styles",
  "onboarding.user.do-you-want-to-start-from-template",
  // #endregion
  // #region Editor tour flow steps
  "tour.editor.generic",
  // #endregion
  // Add other flow steps here

  // ---------- DEPRECATED STEPS ----------
  "onboarding.user.experience-level-with-nocode-builders",
  "onboarding.user.are-you-using-solo-or-team",
] as const;

export const stepTypeSchema = z.enum(flowSteps).describe("StepType");

export type FlowStepType = (typeof flowSteps)[number];

export const flowSlugSchema = z
  .enum([
    "onboarding",
    "onboarding-ai",
    "onboarding-styles",
    "editor-tour",
    // Add other flow slugs here
  ])
  .describe("FlowSlugs");

const onboardingFlowConfigSchema = z.union([
  z.object({
    type: z.literal("onboarding.user.full-name"),
    props: z.array(textInputPropsSchema),
  }),
  z.object({
    type: z.literal("onboarding.user.how-did-you-hear-about-us"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.workspace-name"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.what-do-you-want-to-do-in-replo"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.do-you-want-to-start-from-template"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for.revenue"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.which-best-describes-your-industry"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.import-from-url"),
    props: z.object({}),
  }),
  z.object({
    type: z.literal("onboarding.user.brand-details"),
    props: z.object({}),
  }),
  z.object({
    type: z.literal("onboarding.user.saved-styles"),
    props: z.object({}),
  }),

  // ---------- DEPRECATED STEPS ----------
  z.object({
    type: z.literal("onboarding.user.experience-level-with-nocode-builders"),
    props: selectableButtonWithInputPropsSchema,
  }),
  z.object({
    type: z.literal("onboarding.user.are-you-using-solo-or-team"),
    props: selectableButtonWithInputPropsSchema,
  }),
]);
const editorTourConfigSchema = z.object({
  type: z.literal("tour.editor.generic"),
  props: z.object({
    content: z.object({
      type: z.literal("text"),
      text: z.string(),
    }),
    side: z.union([
      z.literal("left"),
      z.literal("right"),
      z.literal("top"),
      z.literal("bottom"),
      z.literal("bottom-left"),
    ]),
  }),
});

export const flowStepConfigSchema = z
  .union([onboardingFlowConfigSchema, editorTourConfigSchema])
  .describe("FlowStepConfig");

const onboardingFlowStepDataSchema = z.union([
  z.object({
    type: z.literal("onboarding.user.full-name"),
    data: z.object({
      firstName: z.string(),
      lastName: z.string(),
      shopifyUrl: z.string().nullish(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.how-did-you-hear-about-us"),
    data: z.record(z.literal("heardFrom"), z.array(z.string())),
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for"),
    data: z.record(z.literal("buildingFor"), z.string()),
  }),
  z.object({
    type: z.literal("onboarding.user.workspace-name"),
    data: z.object({
      workspaceName: z.string(),
      projectId: z.string().optional(),
      createdWorkspaceId: z.string().optional(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.what-do-you-want-to-do-in-replo"),
    data: z.record(z.literal("whatToDo"), z.array(z.string())),
  }),

  z.object({
    type: z.literal("onboarding.user.do-you-want-to-start-from-template"),
    data: z.object({
      templateId: z.string().optional(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.who-are-you-building-pages-for.revenue"),
    data: z.object({
      yearlyRevenue: z.string().nullish(),
      clientCount: z.string().nullish(),
      clientYearlyRevenue: z.string().nullish(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.which-best-describes-your-industry"),
    data: z.record(z.literal("industry"), z.string()),
  }),
  z.object({
    type: z.literal("onboarding.user.import-from-url"),
    data: z.object({
      url: z.string().url().or(z.literal("")),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.brand-details"),
    data: brandDetailsSchema.extend({
      industry: componentTemplateIndustryNamesSchema.or(z.string()).optional(),
    }),
  }),
  z.object({
    type: z.literal("onboarding.user.saved-styles"),
    data: z.object({
      saveStyles: z.boolean(),
      primaryStyles: z
        .object({
          color: savedColorStyleSchema.array(),
          text: savedTextStyleSchema.array(),
        })
        .optional(),
    }),
  }),
  // ---------- DEPRECATED STEPS ----------
  z.object({
    type: z.literal("onboarding.user.experience-level-with-nocode-builders"),
    data: z.record(z.literal("experienceLevel"), z.string()),
  }),
  z.object({
    type: z.literal("onboarding.user.are-you-using-solo-or-team"),
    data: z.object({
      usingAs: z.string(),
      workspaceName: z.string(),
      projectId: z.string().optional(),
      createdWorkspaceId: z.string().optional(),
    }),
  }),
]);
const editorTourStepDataSchema = z.object({
  type: z.literal("tour.editor.generic"),
  data: z.object({}),
});

export const flowStepDataSchema = z
  .union([onboardingFlowStepDataSchema, editorTourStepDataSchema])
  .describe("FlowStepData");

export const flowConditionSchema = z
  .union([
    z.object({
      type: z.literal("response"),
      nextStep: z.string().nullable(),
      operator: z.literal("eq"),
      property: stepResultPropertySchema,
      value: z.union([z.string(), z.boolean(), z.null()]),
    }),
    z.object({
      type: z.literal("response"),
      nextStep: z.string().nullable(),
      operator: z.literal("notIn"),
      property: stepResultPropertySchema,
      value: z.array(z.string()),
    }),
    z.object({
      type: z.literal("and"),
      nextStep: z.string().nullable(),
      conditions: z.array(
        z.union([
          z.object({
            type: z.literal("response"),
            operator: z.literal("eq"),
            property: stepResultPropertySchema,
            value: z.union([z.string(), z.boolean(), z.null()]),
          }),
          z.object({
            type: z.literal("response"),
            operator: z.literal("notIn"),
            property: stepResultPropertySchema,
            value: z.array(z.string()),
          }),
        ]),
      ),
    }),
  ])
  .describe("FlowStepCondition");

export const flowNextStepSchema = z
  .union([z.string(), z.array(flowConditionSchema), z.null()])
  .describe("FlowNextStep");

export const flowNextStepSubmitResponseSchema = z.union([
  z.string(),
  z.null(),
  z.undefined(),
]);

export const flowStepSchema = z
  .object({
    id: z.string(),
    isSkippable: z.boolean(),
    nextStep: flowNextStepSchema,
  })
  .and(flowStepConfigSchema)
  .describe("FlowStep");

export const arrayOfFlowStepSchema = z.array(flowStepSchema);

export const flowEntityTypeSchema = z
  .union([z.literal("project"), z.literal("workspace"), z.literal("user")])
  .describe("FlowEntity");

export const flowTriggerSchema = z
  .union([z.literal("automatic"), z.literal("manual")])
  .describe("FlowTrigger");

export const flowStatusSchema = z
  .union([z.literal("draft"), z.literal("live")])
  .describe("FlowStatus");

export const flowSchema = z
  .object({
    slug: flowSlugSchema,
    name: z.string(),
    trigger: flowTriggerSchema,
    type: flowEntityTypeSchema,
    status: flowStatusSchema,
    steps: arrayOfFlowStepSchema,
    isRepeatable: z.boolean(),
  })
  .describe("Flow");

// #region Flow Response Schemas
export const flowResponseSchema = z
  .object({
    slug: flowSlugSchema,
    name: z.string(),
    trigger: flowTriggerSchema,
    type: flowEntityTypeSchema,
    status: flowStatusSchema,
    description: z.string().nullable(),
    steps: arrayOfFlowStepSchema,
    isRepeatable: z.boolean(),
    updatedAt: z.coerce.date(),
    createdAt: z.coerce.date(),
  })
  .describe("FlowResponse");

export const flowInstanceStepResultSchema = z
  .intersection(
    z.object({ completedAt: z.string().nullable(), skipped: z.boolean() }),
    flowStepDataSchema,
  )
  .describe("FlowInstanceStepResult");

export const flowStepResultsSchema = z
  .record(z.string(), flowInstanceStepResultSchema)
  .describe("FlowStepResultsDataType");

export const flowInstanceSchema = z
  .object({
    id: z.string(),
    flow: flowSchema,
    entityId: z.string(),
    entityType: z.string(),
    stepResults: flowStepResultsSchema,
    completedAt: z.coerce.date().nullable(),
    isDebug: z.boolean(),
  })
  .describe("FlowInstance");

export const flowInstanceResponseSchema = z
  .object({
    instance: flowInstanceSchema,
    nextStep: z.string().nullish(),
  })
  .describe("FlowInstanceResponse");
// #endregion

// TODO (Gabe 2024-09-27): Lets get the functionality working before working
// about these types.

export type FlowStepDataValueOf<TargetType extends FlowStepData["type"]> =
  Extract<FlowStepData, { type: TargetType }>["data"];
export type FlowStepConfigPropsValueOf<
  TargetType extends FlowStepConfig["type"],
> = Extract<FlowStepConfig, { type: TargetType }>["props"];
export type FlowStepOptionSchema = {
  value: string;
  label: string;
  hasInput: boolean;
  extraData?: Record<string, unknown>;
};
