export type AnalyticsReadQuery = {
  urlHosts: string[];
  metrics: MetricName[];
  order: Order;
  sortMetric: MetricName;
  ranges: QueryRanges;
  offset: number;
  limit: number;
  filters: AnalyticsReadQueryFilters;
};

export type AnalyticsReadResponse = {
  totalRowsCount: number;
  rangeResults: RangeResult[];
};

export enum ConditionOperatorEnum {
  EQUALS = "equals",
  DOES_NOT_EQUAL = "does_not_equal",
  CONTAINS = "contains",
  DOES_NOT_CONTAIN = "does_not_contain",
}

export type FilterCondition =
  | {
      operator: ConditionOperatorEnum.EQUALS;
      value: string[];
    }
  | {
      operator: ConditionOperatorEnum.DOES_NOT_EQUAL;
      value: string[];
    }
  | {
      operator: ConditionOperatorEnum.CONTAINS;
      value: string;
    }
  | {
      operator: ConditionOperatorEnum.DOES_NOT_CONTAIN;
      value: string;
    };

export type FilterConditions = FilterCondition[];

export type AnalyticsReadQueryFilters = {
  urlPath: FilterConditions;
  pageType: PageTypeEnum;
  urlParams: QueryFiltersUrlParams;
};

export type QueryRange = {
  id: string;
  startDatetime: number;
  endDatetime: number;
  interval: number;
};

export type QueryRanges = {
  mainRange: QueryRange;
  compareAtRanges: QueryRange[];
};

export type QueryFiltersUrlParams = { [x: string]: FilterConditions };

export type FindUrlParamsQuery = {
  urlHosts: string[];
  order: Order;
  offset: number;
  limit: number;
  key: string;
  value: string;
};

export type FindUrlParamsResponse = {
  urlParams: string[];
};

export type MetricName =
  | "conversions"
  | "views"
  | "unique_sessions"
  | "conversion_rates"
  | "revenue"
  | "revenue_per_session"
  | "average_order_value";

export type Order = "ASC" | "DESC";

export enum PageTypeEnum {
  ALL_PAGES = "allPages",
  ENTRY_PAGES = "entryPages",
}

export type RangeResult = {
  urlPath: string;
  metadata: {
    elementId: string | null;
    title: string;
    url: string;
  };
  rangeId: string;
  metrics: { [x: string]: number[] };
};

export type FindSubDomainsForUrlHostsResponse = {
  urlHost: string;
  isRootDomain: boolean;
}[];
