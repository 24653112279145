import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

function FeraMediaGalleryWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const liquidSource = '<div data-fera-container="store_customer_media"></div>';

  return (
    <SharedShopifyLiquid
      forceEditorPlaceholder={true}
      liquidSource={liquidSource}
      componentId={component.id}
      componentAttributes={componentAttributes}
      placeholder="Fera Media Gallery Widget will appear here"
      placeholderType="gallery"
      placeholderTitle="Fera Media Gallery"
      repeatedIndexPath={context.repeatedIndexPath}
      isLiquidSupported
    />
  );
}

export default FeraMediaGalleryWidget;
