import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import React from "react";

import { TEXT_STYLE_DEFAULTS } from "@common/constants/textStyles";
import { Input } from "@common/designSystem/Input";
import SelectionIndicator from "@common/designSystem/SelectionIndicator";
import FormFieldXButton from "@common/FormFieldXButton";
import SavedStyleRow from "@components/designLibrary/SavedStyleRow";
import TextSavedStyleModifier from "@editor/components/designLibrary/TextSavedStyleModifier";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  selectProjectId,
  selectTextModifierValues,
} from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";
import {
  generateNewName,
  generateTextSavedStylePreviewValue,
} from "@editor/utils/designLibrary";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { Badge } from "@replo/design-system/components/badge";
import IconButton from "@replo/design-system/components/button/IconButton";
import InlinePopover from "@replo/design-system/components/popover/InlinePopover";
import { BsPlus, BsSearch, BsSliders, BsType } from "react-icons/bs";
import { getSavedStyleValue } from "replo-runtime/shared/savedStyles";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { v4 as uuidv4 } from "uuid";

import SavedStylesEmptyState from "./SavedStylesEmptyState";

type DesignLibraryTextValueIndicatorProps = {
  savedStyleValueReference: string | ReploMixedStyleValue;
  onSelectSavedStyle(value: string): void;
  onRemove?(): void;
  popoverSideOffset?: number;
};

const DesignLibraryTextValueIndicator: React.FC<
  DesignLibraryTextValueIndicatorProps
> = ({
  savedStyleValueReference,
  onSelectSavedStyle,
  onRemove,
  popoverSideOffset,
}) => {
  const { textSavedStyles } = useGetDesignLibrarySavedStyles();
  const textSavedStyleName = !isMixedStyleValue(savedStyleValueReference)
    ? getSavedStyleValue(textSavedStyles, savedStyleValueReference)?.name
    : "Mixed";

  const isTextSavedStyleSelected = Boolean(textSavedStyleName);
  const shouldRenderXButton = onRemove && isTextSavedStyleSelected;

  return (
    <InlinePopover
      shouldPreventDefaultOnInteractOutside={false}
      title="Text Saved Style"
      content={
        <TextValueIndicatorPopoverContent
          onSelectSavedStyle={onSelectSavedStyle}
        />
      }
      sideOffset={popoverSideOffset}
      triggerAsChild
    >
      <SelectionIndicator
        title={textSavedStyleName}
        startEnhancer={<TextBadge />}
        placeholder="Select..."
        endEnhancer={
          shouldRenderXButton && <FormFieldXButton onClick={onRemove} />
        }
        style={{
          width: "100%",
        }}
      />
    </InlinePopover>
  );
};

const TextValueIndicatorPopoverContent: React.FC<{
  onSelectSavedStyle: (value: string) => void;
}> = ({ onSelectSavedStyle }) => {
  const store = useEditorStore();
  const [searchTerm, setSearchTerm] = React.useState("");
  const { textSavedStyles, designLibrary } = useGetDesignLibrarySavedStyles();
  const [isTextModifierPopoverOpen, setIsTextModifierPopoverOpen] =
    React.useState(false);
  const [editingSavedStyleId, setEditingSavedStyleId] = React.useState<
    string | null
  >(null);

  const filteredTextSavedStyles = textSavedStyles.filter((style) =>
    style.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const logEvent = useLogAnalytics();
  const { mutateAsync: createStyleMutation } =
    trpc.designLibrary.savedStyles.create.useMutation({
      onSuccess: () => {
        void trpcUtils.designLibrary.get.invalidate();
      },
    });

  const handleCreateNewStyle = async () => {
    const projectId = selectProjectId(store.getState())!;

    if (!projectId) {
      return;
    }

    const textAttributes = selectTextModifierValues(store.getState());

    const newName = generateNewName("New Style", {
      savedStyles: textSavedStyles,
      type: "text",
    });
    const savedStyleToCreate = {
      id: uuidv4(),
      name: newName,
      attributes: {
        ...TEXT_STYLE_DEFAULTS["p"]!,
        ...textAttributes,
      },
    };

    await createStyleMutation({
      projectId,
      type: "text",
      ...savedStyleToCreate,
    });

    logEvent("library.style.add", {
      type: "text",
      tag: savedStyleToCreate.attributes.htmlTag,
    });

    setIsTextModifierPopoverOpen(true);
    setEditingSavedStyleId(savedStyleToCreate.id);
  };

  return (
    <div className="flex flex-col">
      <TextSavedStyleModifier
        isPopoverOpen={isTextModifierPopoverOpen}
        onPopoverOpenChange={(isOpen) => {
          if (!isOpen) {
            setEditingSavedStyleId(null);
          }
          setIsTextModifierPopoverOpen(isOpen);
        }}
        editingSavedStyle={
          textSavedStyles.find(
            (savedStyle) => savedStyle.id === editingSavedStyleId,
          ) ?? null
        }
        sideOffset={13}
        openedFrom="rightBar"
      />
      <div className="flex flex-col gap-2 w-full">
        <div className="flex w-full justify-between gap-1">
          <Input
            size="sm"
            value={searchTerm}
            startEnhancer={<BsSearch />}
            endEnhancer={() =>
              searchTerm?.trim() && (
                <FormFieldXButton onClick={() => setSearchTerm("")} />
              )
            }
            placeholder="Search saved styles..."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IconButton
            variant="tertiary"
            className="h-6 w-6"
            icon={<BsPlus size={20} />}
            onClick={() => void handleCreateNewStyle()}
            size="sm"
            tooltipText="Create new saved style"
          />
        </div>
        <div className="flex flex-col w-full h-56">
          {filteredTextSavedStyles?.length > 0 ? (
            <div className="flex flex-col gap-1 overflow-y-auto styled-scrollbar">
              {filteredTextSavedStyles.map((savedStyle) => (
                <SavedStyleRow
                  key={savedStyle.id}
                  name={savedStyle.name}
                  value={generateTextSavedStylePreviewValue(
                    savedStyle.attributes as SavedStyleTextAttributes,
                  )}
                  type="text"
                  onClick={() =>
                    onSelectSavedStyle?.(
                      `library.${designLibrary?.id}.styles.${savedStyle.id}.attributes`,
                    )
                  }
                  endEnhancer={
                    <BsSliders
                      className="text-black rotate-90"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingSavedStyleId(savedStyle.id);
                        setIsTextModifierPopoverOpen(true);
                      }}
                    />
                  }
                />
              ))}
            </div>
          ) : (
            <SavedStylesEmptyState title="No Styles Found" showCta={false} />
          )}
        </div>
      </div>
    </div>
  );
};

const TextBadge: React.FC = () => {
  return <Badge type="icon" icon={<BsType className="text-subtle" />} />;
};

export default DesignLibraryTextValueIndicator;
