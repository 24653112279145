import type { FullPageErrorModalProps } from "@editor/components/AppModalTypes";

import * as React from "react";

import Modal from "@editor/components/common/designSystem/Modal";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useModal } from "@editor/hooks/useModal";
import { publisherApi } from "@editor/reducers/api-reducer";
import { useEditorDispatch } from "@editor/store";
import { docs } from "@editor/utils/docs";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import isArray from "lodash-es/isArray";
import { useNavigate, useParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import { ConnectShopifyIntegrationCta } from "./ConnectShopifyCallout";

export const FullPageErrorModal: React.FC<FullPageErrorModalProps> = ({
  details,
}) => {
  const { projectId } = useParams();
  const { project: currentProject } = useCurrentProjectContext();
  const fullPageError = details;
  const dispatch = useEditorDispatch();
  const navigate = useNavigate();
  const { closeModal } = useModal();

  if (!fullPageError) {
    return null;
  }

  const {
    header,
    subheader,
    message,
    messageDetail,
    isRecoverable,
    callToAction,
  } = fullPageError;

  const getCustomCallbackData = () => {
    if (callToAction?.type === "custom" && callToAction.callToActionType) {
      if (typeof callToAction?.callToActionType === "object") {
        return exhaustiveSwitch(callToAction?.callToActionType)({
          navigateToLink: (data) => {
            return exhaustiveSwitch(data.link)({
              "errors.wrongJsonExtension": () => {
                return {
                  name: "How To Fix",
                  callback: () => {
                    navigate(docs.errors.wrongJsonExtension);
                  },
                };
              },
              "errors.chunkingError": () => {
                return {
                  name: "Learn more",
                  callback: () => {
                    window.location.href = docs.errors.chunkingError;
                  },
                };
              },
              "errors.indexBackupError": () => {
                return {
                  name: "Learn more",
                  callback: () => {
                    window.location.href = docs.errors.indexBackupError;
                  },
                };
              },
              customLink: (data) => {
                return {
                  name: "View More",
                  callback: () => {
                    navigate(data.url);
                  },
                };
              },
            });
          },
        });
      }
      return exhaustiveSwitch({
        type: callToAction?.callToActionType,
      })({
        navigateToProjectEditor: () => ({
          name: "Go back to project",
          callback: () => {
            closeModal({ type: "fullPageErrorModal" });
            // NOTE (Sebas, 2024-03-12): We need to invalidate the project cache
            // to make sure we fetch the project again when navigating back to the
            // project to trigger a re-render of the canvas. Otherwise, the canvas
            // will not show.
            dispatch(publisherApi.util.invalidateTags(["project"]));
            navigate(`/editor/${projectId}`);
          },
        }),
        reload: () => null,
        installApp: () => null,
        goToHomepage: () => null,
        closeModal: () => null,
      });
    }
    return null;
  };

  const customCallbackData = getCustomCallbackData();
  const button = callToAction ? (
    exhaustiveSwitch(callToAction)({
      link: ({ to, name, target }) => (
        <Button
          variant="primary"
          size="lg"
          to={to}
          target={target}
          isFullWidth={true}
          data-testid="refresh-editor-button"
        >
          {name}
        </Button>
      ),
      callback: ({ onClick, name }) => (
        <Button
          variant="primary"
          size="lg"
          isFullWidth={true}
          data-testid="refresh-editor-button"
          onClick={onClick}
        >
          {name}
        </Button>
      ),
      closeModal: ({ name }) => (
        <Button
          variant="primary"
          size="lg"
          isFullWidth={true}
          data-testid="refresh-editor-button"
          onClick={() => dispatch(closeModal({ type: "fullPageErrorModal" }))}
        >
          {name}
        </Button>
      ),
      installApp: () => (
        <ConnectShopifyIntegrationCta type="noShopifyErrorModal" />
      ),
      custom: () => (
        <Button
          variant="primary"
          size="lg"
          isFullWidth={true}
          data-testid="refresh-editor-button"
          onClick={customCallbackData?.callback}
        >
          {customCallbackData?.name}
        </Button>
      ),
      navigate: (data) => {
        return exhaustiveSwitch({ type: data.location })({
          integrationHub: () => (
            <Button
              variant="primary"
              size="lg"
              to={`/workspace/${currentProject?.ownerWorkspaceId}/integrations`}
              onClick={() =>
                dispatch(closeModal({ type: "fullPageErrorModal" }))
              }
              isFullWidth={true}
              data-testid="refresh-editor-button"
            >
              View Integrations
            </Button>
          ),
        });
      },
    })
  ) : (
    <Button
      variant="primary"
      size="lg"
      isFullWidth={true}
      data-testid="refresh-editor-button"
      onClick={() => {
        window.location.reload();
      }}
    >
      Refresh Editor
    </Button>
  );

  const messages = isArray(message) ? message : [message];

  return (
    <Modal
      onRequestClose={() => closeModal({ type: "fullPageErrorModal" })}
      isOpen={true}
      style={{ width: 420 }}
    >
      <div className="flex flex-col items-start bg-white p-2 text-default gap-4">
        <div className="text-xl font-semibold text-default w-full">
          {header}
        </div>
        <div className="flex flex-col gap-4 w-full">
          {subheader && (
            <div className="text-sm text-slate-400">{subheader}</div>
          )}
          {messages.map((msg) => (
            <p
              key={msg}
              className={classNames("text-sm", {
                "text-red-600": !isRecoverable,
              })}
            >
              {msg}
            </p>
          ))}
          {messageDetail && (
            <p className="text-sm text-slate-400">{messageDetail}</p>
          )}
          {button}
        </div>
      </div>
    </Modal>
  );
};
