// NOTE (Matt 2025-01-17): Now that the canvas is rendered in the same context as the rest of the editor,
// we need to prevent script tags from executing and we need to scope any custom style tags to not
// affect styles outside the canvas.
export function processCustomHtmlForEditor(inputHtml: string): string {
  // Remove all <script> tags and their contents
  const htmlWithoutScriptTags = inputHtml.replace(
    /<script.*?>.*?<\/script>/gs,
    "",
  );

  // Update <style> tags to wrap contents with @scope
  const htmlWithScopedStyleTags = htmlWithoutScriptTags.replace(
    /<style.*?>(.*?)<\/style>/gs,
    (_, styleContent) => {
      const scopedContent = `@scope ([data-replo-canvas]) { ${styleContent} }`;
      return `<style>${scopedContent}</style>`;
    },
  );

  return htmlWithScopedStyleTags;
}
