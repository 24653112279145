import type { SaturationProps } from "react-color/lib/components/common/Saturation";

import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { CustomPicker } from "react-color";
import { Alpha, Hue, Saturation } from "react-color/lib/components/common";

const Picker = (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Saturation> &
    Readonly<SaturationProps> &
    Readonly<{ children?: React.ReactNode }> & {
      onDragStart?(e: React.MouseEvent): void;
      onDragEnd?(e: React.MouseEvent): void;
      isMixedStyleValue?: boolean;
    },
) => {
  // Note (Noah, 2024-11-15, REPL-14691): We need to listen to a global mouseup event in case
  // the user starts dragging the color picker inside, but lifts their mouse outside of the div.
  // otherwise, we'd never get a dragEnd and we might accidentally keep preview CSS properties
  // enabled.
  const isDragging = React.useRef(false);
  React.useEffect(() => {
    const handleMouseUp = (e: MouseEvent) => {
      if (isDragging.current) {
        isDragging.current = false;
        props.onDragEnd?.(e as any);
      }
    };

    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
    // Note (Noah, 2024-11-15): Eslint is wrong about this - it thinks we need props as
    // dependency, even though we're only using props.onDragEnd
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onDragEnd]);

  return (
    <div
      className="w-full flex flex-col gap-2"
      onMouseDown={(e) => {
        isDragging.current = true;
        props.onDragStart?.(e);
      }}
      onMouseUp={(e) => {
        isDragging.current = false;
        props.onDragEnd?.(e);
      }}
    >
      <div
        className="relative h-[150px] w-full"
        data-testid="colorPickerSaturation"
      >
        <Saturation
          {...props}
          pointer={
            (() => (
              <CustomPointer
                isSaturation
                isMixedStyleValue={props.isMixedStyleValue}
              />
            )) as unknown as React.ReactNode
          }
          // NOTE (Sebas, 2024-09-13): The type definition for these components seems to be incorrect,
          // the prop `radius` exists but is not recognized by typescript.
          // @ts-ignore
          radius={4}
        />
      </div>
      <div className="relative h-[10px] w-full">
        <Hue
          {...props}
          pointer={
            (() => (
              <CustomPointer isMixedStyleValue={props.isMixedStyleValue} />
            )) as unknown as React.ReactNode
          }
          // @ts-ignore
          radius={4}
          className="w-full h-[10px]"
        />
      </div>
      <div className="relative h-[10px] w-full">
        <Alpha
          {...props}
          pointer={
            (() => (
              <CustomPointer isMixedStyleValue={props.isMixedStyleValue} />
            )) as unknown as React.ReactNode
          }
          // @ts-ignore
          radius={4}
        />
      </div>
    </div>
  );
};

const CustomPointer = ({ isSaturation = false, isMixedStyleValue = false }) => {
  if (isMixedStyleValue) {
    return null;
  }

  return (
    <div
      className={twMerge(
        "relative flex h-4 w-4 self-center justify-self-center rounded-full border-[3.8px] border-white shadow",
        isSaturation ? "-top-2 -left-2" : "-top-[3px] -left-2",
      )}
    />
  );
};

export const PickerComponent = CustomPicker(Picker);
