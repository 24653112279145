import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const STAMPED_PRODUCT_REVIEWS_WIDGET_LIQUID_SOURCE = `<div
  id="stamped-main-widget"
  data-product-id="{{ product.id }}"
  data-name="{{ product.title | escape }}"
  data-url="{{ shop.url }}{{ product.url }}"
  data-image-url="{{ product.featured_image | product_img_url: "large" |replace: '?', '%3F' | replace: '&','%26'}}"
  data-description="{{ product.description | escape }}"
  data-product-sku="{{product.handle}}"
  data-product-title="{{product.title}}"
  data-product-type="{{product.type}}">
  {{product.metafields.stamped.reviews}}
</div>`;

function StampedProductReviewsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: STAMPED_PRODUCT_REVIEWS_WIDGET_LIQUID_SOURCE,
      context: props.context,
    },
  );

  return (
    <SharedShopifyLiquid
      componentId={component.id}
      componentAttributes={componentAttributes}
      component={component}
      liquidSource={liquidSourceWithProductAssignment}
      forceEditorPlaceholder={true}
      repeatedIndexPath={context.repeatedIndexPath}
      isLiquidSupported
      placeholderTitle="Stamped Product Reviews"
      placeholderType="reviewFull"
    />
  );
}

export default StampedProductReviewsWidget;
