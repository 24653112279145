import type {
  Hotkey,
  HotkeyIndicatorCharacter,
  HotkeyMetaKey,
} from "@editor/utils/hotkeys";

import * as React from "react";

import { getHotkeyData, HotkeyMetaKeyToLabel } from "@editor/utils/hotkeys";
import { isMacintosh } from "@editor/utils/platform";

import { Badge } from "@replo/design-system/components/badge";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";

const FancyHotkeyMetaKeyToLabel = {
  ...HotkeyMetaKeyToLabel,
  meta: {
    mac: (HotkeyMetaKeyToLabel.meta as { mac: string }).mac,
    windows: <span style={{ fontSize: "6px", fontWeight: "bold" }}>CTRL</span>,
  },
  esc: {
    mac: (HotkeyMetaKeyToLabel.esc as { mac: string }).mac,
    windows: <span style={{ fontSize: "6px", fontWeight: "bold" }}>ESC</span>,
  },
};

export const HotkeyIndicator: React.FC<{
  title?: string;
  hotkey: Hotkey;
  className?: string;
  badgeBackgroundColor?: string;
}> = ({ hotkey, title, className, badgeBackgroundColor }) => {
  const hotkeyData = getHotkeyData(hotkey);
  return (
    <div
      className={twMerge(
        "flex flex-row items-center justify-center gap-1 text-xs",
        className,
      )}
    >
      <span className="block">{title ?? hotkeyData.displayName}</span>
      <div className="flex flex-row items-center gap-1">
        {getHotkeyLabel(hotkeyData.indicatorCharacters).map(
          ({ id, display }) => (
            <Badge
              key={id}
              type="icon"
              icon={display}
              isFilled
              className={classNames(
                "text-subtle",
                badgeBackgroundColor ? badgeBackgroundColor : "bg-slate-200",
              )}
            />
          ),
        )}
      </div>
    </div>
  );
};

function getHotkeyLabel(hotkey: HotkeyIndicatorCharacter[]) {
  return hotkey.map((character) => {
    const label = FancyHotkeyMetaKeyToLabel[character as HotkeyMetaKey];
    if (label) {
      if (typeof label === "string") {
        return { id: character, display: label };
      }
      return {
        id: character,
        display: !isMacintosh() ? label.windows : label.mac,
      };
    }
    return { id: character, display: character.toUpperCase() };
  });
}
