import type { SelectedTimePeriod } from "@/features/analytics/query";
import type { Option } from "@replo/design-system/components/combobox/types";
import type { LineChartData } from "@replo/design-system/components/shadcn/LineChart";
import type {
  AnalyticsReadQuery,
  MetricName,
  RangeResult,
} from "schemas/generated/analyticsRead";

import { getRangesFromTimePeriod } from "@/features/analytics/time";
import { convertDaysToMs } from "replo-utils/lib/datetime";

const HOUR_OPTION = {
  value: "hour",
  label: "hour",
};
const DAY_OPTION = { value: "day", label: "day" };

// NOTE (Kurt, 2024-09-19): This function is used to get the line chart data from the analytics read query.
// We take the data from the analytics read query and format it into the line chart data format that is then
// consumed by the LineChart component which ultimately passes to the shadcn chart component.
export const getLineChartDataFromAnalyticsRead = (
  pageData: RangeResult,
  compareAtData: RangeResult,
  query: AnalyticsReadQuery,
  metricName: MetricName,
) => {
  const data: LineChartData = [];
  const dataLength = pageData.metrics[metricName]?.length ?? 0;

  const formatDate = (date: Date) => {
    return date.toISOString();
  };

  for (let i = 0; i < dataLength; i++) {
    const targetDate = new Date(
      query.ranges.mainRange?.startDatetime +
        i * (query.ranges.mainRange?.interval ?? 0),
    );

    const formattedTargetDate = formatDate(targetDate);

    data.push({
      x: formattedTargetDate,
      [`thisPeriod`]: pageData.metrics[metricName]?.[i] ?? 0,
      [`previousPeriod`]: compareAtData.metrics[metricName]?.[i] ?? 0,
    });
  }

  return data;
};

export function getChartIntervalOptions(
  selectedTimePeriod: SelectedTimePeriod,
) {
  const { updatedRanges } = getRangesFromTimePeriod({
    selectedTimePeriod,
  });
  const { startDatetime, endDatetime } = updatedRanges.mainRange;
  const start = new Date(startDatetime);
  const end = new Date(endDatetime);

  const rangeInDays = Math.ceil(
    (end.getTime() - start.getTime()) / convertDaysToMs(1),
  );

  let intervalOptions: Option[] = [];

  if (rangeInDays <= 1) {
    intervalOptions = [HOUR_OPTION];
  } else if (rangeInDays <= 31) {
    intervalOptions = [HOUR_OPTION, DAY_OPTION];
  } else {
    intervalOptions = [DAY_OPTION];
  }

  return { rangeInDays, intervalOptions };
}
