import type { ReploComponentType } from "schemas/component";

import * as React from "react";

import {
  CarouselConfigMenu,
  CarouselSlidesConfigMenu,
} from "@editor/components/editor/page/element-editor/components/config-menus/CarouselConfigMenu";
import {
  ContainerAccessibilityMenu,
  ContainerConfigMenu,
} from "@editor/components/editor/page/element-editor/components/config-menus/ContainerConfigMenus";

import { StandardAccessibilityConfigMenu } from "./StandardAccessibilityConfigMenu";

export const configMenuMap: Partial<
  Record<ReploComponentType, { config?: React.FC; accessibility?: React.FC }>
> = {
  carouselV3: {
    config: CarouselConfigMenu,
    accessibility: () => {
      return (
        <StandardAccessibilityConfigMenu
          accessibilityPropIds={[
            "_title",
            "_accessibilityScreenreaderInstructions",
          ]}
        />
      );
    },
  },
  carouselV3Slides: {
    config: CarouselSlidesConfigMenu,
  },
  container: {
    config: ContainerConfigMenu,
    accessibility: ContainerAccessibilityMenu,
  },
  image: {
    config: ContainerConfigMenu,
    accessibility: ContainerAccessibilityMenu,
  },
  // NOTE (Fran 2024-11-25): All of these components are containers, but they have
  // some specific config menus, so we need to explicitly define them here.
  carouselPanelsCount: {
    accessibility: ContainerAccessibilityMenu,
  },
  collapsibleV2Header: {
    accessibility: ContainerAccessibilityMenu,
  },
  collapsibleV2Content: {
    accessibility: ContainerAccessibilityMenu,
  },
  circle: {
    accessibility: ContainerAccessibilityMenu,
  },
  toggleContainer: {
    accessibility: ContainerAccessibilityMenu,
  },
  toggleIndicator: {
    accessibility: ContainerAccessibilityMenu,
  },
  quantitySelector: {
    accessibility: ContainerAccessibilityMenu,
  },
  tooltipContent: {
    accessibility: ContainerAccessibilityMenu,
  },
  subscribeAndSave: {
    accessibility: ContainerAccessibilityMenu,
  },
  beforeAfterSliderThumb: {
    accessibility: ContainerAccessibilityMenu,
  },
  beforeAfterSliderBeforeContent: {
    accessibility: ContainerAccessibilityMenu,
  },
  beforeAfterSliderAfterContent: {
    accessibility: ContainerAccessibilityMenu,
  },
  icon: {
    accessibility: () => {
      return (
        <StandardAccessibilityConfigMenu
          accessibilityPropIds={[
            "_accessibilityHidden",
            "_accessibilityLabelledBy",
            "_accessibilityLabel",
          ]}
        />
      );
    },
  },
  starRating: {
    accessibility: () => {
      return (
        <StandardAccessibilityConfigMenu
          accessibilityPropIds={[
            "_accessibilityHidden",
            "_accessibilityLabelledBy",
            "_accessibilityLabel",
          ]}
        />
      );
    },
  },
};
