import React from "react";

import DashboardLayout from "../layouts/DashboardLayout";

export const PartnerOffersFrame: React.FC = () => {
  return (
    <DashboardLayout>
      <div className="w-full h-full">
        <iframe
          src="https://www.replo.app/partner-offers"
          className="w-full h-full"
          style={{ border: "none" }}
          title="Partner Offers"
          allow="fullscreen"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        />
      </div>
    </DashboardLayout>
  );
};
