import type { SavedColorStyle } from "schemas/generated/designLibrary";
import type {
  SavedStyleColorAttributes,
  SavedStyleColorType,
} from "schemas/generated/savedStyles";

import * as React from "react";

import { COLOR_DEFAULTS } from "@common/constants/colorStyles";
import { ColorSavedStyleModifier } from "@editor/components/designLibrary/ColorSavedStyleModifier";
import SavedStyleItemEndEnhancer from "@editor/components/designLibrary/SavedStyleItemEndEnhancer";
import SavedStyleRow from "@editor/components/designLibrary/SavedStyleRow";
import { SavedStyleSkeleton } from "@editor/components/designLibrary/SavedStyleSkeleton";
import useDeleteDesignLibrarySavedStyle from "@editor/hooks/designLibrary/useDeleteDesignLibrarySavedStyle";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { getFormattedColorWithoutOpacity } from "@editor/utils/colors";
import { generateNewName } from "@editor/utils/designLibrary";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import ModifierGroup from "@editorExtras/ModifierGroup";

import Popover from "@replo/design-system/components/popover";
import { BsPlus } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";

type ColorStyleGroupProps = {
  overrideSavedStyles?: SavedColorStyle[];
  isLoading?: boolean;
};

const ColorSavedStyleGroup: React.FC<ColorStyleGroupProps> = ({
  overrideSavedStyles,
  isLoading = false,
}) => {
  const logEvent = useLogAnalytics();

  const [isColorModifierPopoverOpen, setIsColorModifierPopoverOpen] =
    React.useState(true);
  const [editingSavedStyleId, setEditingSavedStyleId] = React.useState<
    string | null
  >(null);

  const isEditable = !Boolean(overrideSavedStyles);
  const { colorSavedStyles } = useGetDesignLibrarySavedStyles();
  const projectId = useCurrentProjectId();
  const { mutateAsync: createStyleMutation } =
    trpc.designLibrary.savedStyles.create.useMutation({
      onSuccess: () => {
        void trpcUtils.designLibrary.get.invalidate();
      },
    });

  const handleItemClick = async (colorType: SavedStyleColorType) => {
    if (!projectId || !isEditable) {
      return null;
    }

    const newName = generateNewName(colorType, {
      savedStyles: colorSavedStyles,
      type: "color",
      colorType,
    });

    const savedStyleToCreate = {
      id: uuidv4(),
      name: newName,
      attributes: {
        type: "color",
        color: COLOR_DEFAULTS[colorType] ?? "#000000",
        colorType,
      } as SavedStyleColorAttributes,
    };
    await createStyleMutation({
      projectId,
      type: "color",
      ...savedStyleToCreate,
    });

    logEvent("library.style.add", {
      type: "color",
      tag: savedStyleToCreate.attributes.colorType,
    });

    setIsColorModifierPopoverOpen(true);
    setEditingSavedStyleId(savedStyleToCreate.id);
  };

  const savedStyles = overrideSavedStyles ?? colorSavedStyles;
  const isImported = overrideSavedStyles !== undefined;

  return (
    <div className="mt-4">
      {isEditable && (
        <ColorSavedStyleModifier
          isPopoverOpen={isColorModifierPopoverOpen}
          onPopoverOpenChange={(isOpen) => {
            if (!isOpen) {
              setEditingSavedStyleId(null);
            }
            setIsColorModifierPopoverOpen(isOpen);
          }}
          editingSavedStyle={
            colorSavedStyles.find(
              (savedStyle) => savedStyle.id === editingSavedStyleId,
            ) ?? null
          }
          sideOffset={13}
          openedFrom="leftBar"
        />
      )}
      <ModifierGroup
        title="Color Styles"
        isDefaultOpen={savedStyles.length > 0 || isLoading}
        isCollapsible={savedStyles.length > 0}
        icon={isEditable && !isLoading ? BsPlus : undefined}
        iconTooltip="Add new color style"
        iconAriaLabel="Add new color style"
        onClick={() => void handleItemClick("basic")}
      >
        <div className="flex flex-col gap-1">
          {isLoading ? (
            <SavedStyleSkeleton />
          ) : (
            savedStyles.map((savedStyle, index) => (
              <SavedStylePaneRow
                key={index}
                savedStyle={savedStyle}
                editingSavedStyleId={editingSavedStyleId}
                setEditingSavedStyleId={(id) => {
                  setIsColorModifierPopoverOpen(true);
                  setEditingSavedStyleId(id);
                }}
                isImported={isImported}
              />
            ))
          )}
        </div>
      </ModifierGroup>
    </div>
  );
};

const SavedStylePaneRow: React.FC<{
  savedStyle: SavedColorStyle & { id?: string };
  editingSavedStyleId: string | null;
  setEditingSavedStyleId: (id: string | null) => void;
  isImported?: boolean;
}> = ({
  savedStyle,
  editingSavedStyleId,
  setEditingSavedStyleId,
  isImported,
}) => {
  const { deleteSavedStyle, isLoading } = useDeleteDesignLibrarySavedStyle();
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);

  return (
    <div
      onMouseOver={() =>
        !Boolean(editingSavedStyleId) && setIsPreviewOpen(true)
      }
      onMouseLeave={() => setIsPreviewOpen(false)}
    >
      <Popover isOpen={isPreviewOpen}>
        <Popover.Content
          shouldPreventDefaultOnInteractOutside
          side="right"
          align="start"
          // NOTE (Fran 2024-12-23): This is to make sure the popover is placed in the right position
          // This number came from the height of the row.
          alignOffset={-28}
          // NOTE (Fran 2025-01-06): This is to make sure the popover is placed in the right position
          // This number came from the spacing to the saved style panel.
          sideOffset={14}
          hideCloseButton
          className="w-40"
        >
          <div className="flex flex-col gap-2 w-full h-fit">
            <div
              className="w-full h-24 rounded"
              style={{
                background: savedStyle.attributes.color,
              }}
            />
            <span className="typ-label-small text-default">
              {getFormattedColorWithoutOpacity(savedStyle.attributes.color)!}
            </span>
          </div>
        </Popover.Content>
        <Popover.Anchor className="top-0 left-0" />
      </Popover>
      <SavedStyleRow
        name={savedStyle.name}
        value={getFormattedColorWithoutOpacity(savedStyle.attributes.color)!}
        type="color"
        isLoading={isLoading}
        isSelected={editingSavedStyleId === savedStyle.id}
        onClick={() => setEditingSavedStyleId(savedStyle.id ?? null)}
        isImported={isImported}
        endEnhancer={
          // NOTE (Fran 2024-11-14): In the case we were showing the imported or duplicated saved styles,
          // we don't have an id. And we don't want to show the end enhancer in that case, because they
          // are not editable.
          savedStyle.id && (
            <SavedStyleItemEndEnhancer
              type="color"
              savedStyleAttributes={savedStyle.attributes}
              name={savedStyle.name}
              id={savedStyle.id}
              deleteSavedStyle={(id) => void deleteSavedStyle(id)}
              onSelectSavedStyle={() => {
                setEditingSavedStyleId(savedStyle.id ?? null);
              }}
            />
          )
        }
      />
    </div>
  );
};

export default ColorSavedStyleGroup;
