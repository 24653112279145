import type { Option } from "@replo/design-system/components/combobox/types";
import type {
  FilterCondition,
  QueryFiltersUrlParams,
} from "schemas/generated/analyticsRead";

import { ConditionOperatorEnum } from "schemas/analyticsRead";
import { ReploError } from "schemas/errors";

class FailedUpdatingUrlParamsFilters extends ReploError {}

export function findValueForLabel(
  options: Option[],
  label: string,
): string | undefined {
  const selectedOption = options.find((option) => option.label === label);
  return selectedOption?.value ?? undefined;
}

export function findLabelForValue(
  options: Option[],
  value: string,
): string | undefined {
  const selectedOption = options.find((option) => option.value === value);
  return selectedOption?.label ?? undefined;
}

export function addEntryToUrlParams({
  currentUrlParams,
  urlParamToAdd,
}: {
  currentUrlParams: QueryFiltersUrlParams;
  urlParamToAdd: {
    attribute: string;
    operator: ConditionOperatorEnum;
    value: string;
  };
}): QueryFiltersUrlParams {
  const { attribute, operator, value } = urlParamToAdd;

  const newUrlParamEntry = {
    operator,
    value: [
      ConditionOperatorEnum.EQUALS,
      ConditionOperatorEnum.DOES_NOT_EQUAL,
    ].includes(operator)
      ? [value]
      : value,
  } as FilterCondition;

  const updatedUrlParams: QueryFiltersUrlParams = {
    ...currentUrlParams,
    [attribute]: [...(currentUrlParams[attribute] ?? []), newUrlParamEntry],
  };

  return updatedUrlParams;
}

export function removeEntryFromUrlParams({
  currentUrlParams,
  urlParamToRemove,
}: {
  currentUrlParams: QueryFiltersUrlParams;
  urlParamToRemove: {
    attribute: string;
    operator: ConditionOperatorEnum;
    value: string | string[];
  };
}): QueryFiltersUrlParams {
  const { attribute, operator, value } = urlParamToRemove;

  const attributeFilterConditions = currentUrlParams[attribute];

  if (!attributeFilterConditions) {
    throw new FailedUpdatingUrlParamsFilters({
      message:
        "The attribute attempted to be removed doesn't exist in the filter's urlParams",
      additionalData: {
        currentUrlParams,
        urlParamToRemove,
      },
    });
  }

  const updatedFilterConditions: FilterCondition[] =
    attributeFilterConditions.filter(
      (filterCondition: FilterCondition) =>
        !(
          filterCondition.operator === operator &&
          JSON.stringify(filterCondition.value) === JSON.stringify(value)
        ),
    );

  return {
    ...currentUrlParams,
    [attribute]: updatedFilterConditions,
  };
}
