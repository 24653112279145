import * as React from "react";

import Avatar from "@editor/components/common/designSystem/Avatar";
import Separator from "@editor/components/common/designSystem/Separator";
import TablePagination from "@editor/components/common/designSystem/TablePagination";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import orderBy from "lodash-es/orderBy";
import { useParams } from "react-router-dom";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";

import Header from "../../Header";
import TableHeadTitle from "../../tables/TableHeadTitle";
import ReferredTableEmptyState from "./ReferredTableEmptyState";

type Field = "name" | "referralCode.code" | "createdAt";
type Direction = "asc" | "desc";

type OrderState = {
  field: Field;
  direction: Direction;
};

const AMOUNT_TO_SHOW = 10;

const ReferredUsersTable: React.FC = () => {
  const { workspaceId } = useParams();
  const { data, isPending } = trpc.referralCode.getReferredUsers.useQuery(
    workspaceId
      ? {
          workspaceId,
        }
      : skipToken,
  );
  const referredUsers = data?.referredUsers ?? [];
  const [currentPage, setCurrentPage] = React.useState(1);

  const [order, setOrder] = React.useState<OrderState>({
    field: "name",
    direction: "asc",
  });
  const [usersOrdered] = useOverridableState(
    orderBy(referredUsers, order.field, order.direction),
  );

  const [skip, setSkip] = React.useState(0);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * AMOUNT_TO_SHOW);
  };

  const handleSortClick = (field: Field) => {
    if (field === order.field) {
      setOrder({
        field: field,
        direction: order.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setOrder({ field: field, direction: "desc" });
    }
  };

  if (isPending) {
    return null;
  }

  const usersToShow = usersOrdered.slice(skip, AMOUNT_TO_SHOW + skip);

  return (
    <div className="flex flex-col gap-y-4">
      <Header
        title="Users Referred"
        subtitle="Users who signed up using your referral link."
        isLoading={isPending}
      />
      {usersOrdered.length === 0 ? (
        <ReferredTableEmptyState type="users" />
      ) : (
        <div className="w-full">
          <div className="grid w-full grid-cols-12 gap-2 text-slate-400">
            <TableHeadTitle
              title="User"
              wrapperClassName="col-span-6"
              shouldShowArrow={order.field === "name"}
              onClick={() => handleSortClick("name")}
              arrowDirection={order.direction}
            />
            <TableHeadTitle
              title="Referred Code"
              wrapperClassName="col-span-3"
              shouldShowArrow={order.field === "referralCode.code"}
              onClick={() => handleSortClick("referralCode.code")}
              arrowDirection={order.direction}
            />
            <TableHeadTitle
              title="Signed Up At"
              wrapperClassName="col-span-3"
              shouldShowArrow={order.field === "createdAt"}
              onClick={() => handleSortClick("createdAt")}
              arrowDirection={order.direction}
            />
          </div>
          <div className="grid-col mt-2 grid gap-2">
            {usersToShow?.map((user, index) => (
              <div
                className="grid w-full grid-cols-12 items-center justify-center gap-2 text-base text-default"
                key={user.email}
              >
                <div className="col-span-6 flex flex-row items-center gap-x-4">
                  <Avatar
                    name={`${user.name}`}
                    size="base"
                    imageClassName="bg-purple-600"
                  />
                  <div>
                    <div className="text-sm font-light text-default">
                      {user.name}
                    </div>
                    <div className="text-sm font-light text-slate-300">
                      {user.email}
                    </div>
                  </div>
                </div>
                <div className="col-span-3 text-default">
                  {user.referralCode?.code}
                </div>
                <div className="col-span-3 text-default">
                  {formatDistanceToNow(user.createdAt, { addSuffix: true })}
                </div>
                {index !== usersToShow.length - 1 && (
                  <Separator className="col-span-12 bg-slate-100" />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {usersOrdered.length > AMOUNT_TO_SHOW && (
        <div className="flex flex-col items-center gap-1">
          <Separator className="w-1/4 my-2" />
          <TablePagination
            currentPageNumber={currentPage}
            totalRowsCount={usersOrdered.length}
            resultsPerPage={AMOUNT_TO_SHOW}
            handleOnPageNumberChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default ReferredUsersTable;
