import type { DeleteProjectModalProps } from "../AppModalTypes";

import * as React from "react";

import Input from "@common/designSystem/Input";
import Modal from "@common/designSystem/Modal";
import { Loader } from "@common/Loader";
import { ModalLayout } from "@common/ModalLayout";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import toast from "@editor/components/common/designSystem/Toast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import {
  publisherApi,
  useGetProjectsByWorkspaceIdQuery,
} from "@editor/reducers/api-reducer";
import { useEditorDispatch } from "@editor/store";
import { getProjectName } from "@editor/utils/project-utils";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import Button from "@replo/design-system/components/button";
import { useForm, useWatch } from "react-hook-form";
import { generatePath } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  projectName: string;
};

export const DeleteProjectModal: React.FC<DeleteProjectModalProps> = ({
  projectId,
  callback,
  from,
}) => {
  const { closeModal } = useModal();
  // NOTE (Fran 2024-08-29): We don't want to use the current project context here because this will
  // depend on which project the user clicks on in the dashboard. So, we only want to get the project
  // the user needs to delete.
  const { data: project, isLoading: isLoadingProject } =
    trpc.project.get.useQuery(projectId ?? skipToken);
  const { data: workspaceData } = useGetProjectsByWorkspaceIdQuery(
    project?.ownerWorkspaceId ?? skipToken,
  );
  const { data: projectActiveSubscription } =
    trpc.subscriptions.getActiveSubscriptionByProject.useQuery(
      projectId ?? skipToken,
    );
  const dispatch = useEditorDispatch();
  const logEvent = useLogAnalytics();
  const { mutate: deleteProject, isPending } = trpc.project.delete.useMutation({
    onSuccess: () => {
      toast({
        header: "Project deleted",
      });
      closeModal({ type: "deleteProjectModal" });
      callback?.();
      if (project?.id) {
        logEvent("project.delete", {
          projectId: project.id,
          projectName: projectNameFromData,
          from,
        });
      }
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();
      void trpcUtils.project.findByUserId.invalidate();
      void trpcUtils.user.get.invalidate();
      // TODO (Sebas, 2024-07-15): Remove once we migrate all the project related
      // endpoints to TRPc
      dispatch(publisherApi.util.invalidateTags(["projects", "project"]));
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm<FormValues>({
    mode: "onBlur",
    defaultValues: {
      projectName: "",
    },
  });
  const projectNameValue = useWatch({ control, name: "projectName" });
  const hasProjectNameErrors = !isEmpty(errors.projectName);
  const publishedElements = project?.elements?.filter(
    (element) => element.isPublished,
  );
  const hasPublishedElements =
    !isLoadingProject && publishedElements && publishedElements.length > 0;
  // NOTE (Fran 2024-05-02): There were cases where the user created a project with a space at the end
  // but is not super obvious in the UI, so we are trimming the project name to avoid this problem.
  const projectNameFromData = getProjectName(project).trim();

  const onSubmit = ({ projectName }: FormValues) => {
    if (projectNameFromData === projectName && project?.id) {
      void deleteProject({ projectId: project.id });
    }
  };

  // NOTE (Sebas, 2024-03-07): When a user publishes a page, and goes back to the dashboard, if we log the data from
  // the project, it will not show the updated published elements quantity until the user reloads the page.
  // To avoid this problem we are manually invalidating the "project" tag to get the updated projects.
  const invalidateProjectsTag = React.useCallback(
    () => dispatch(publisherApi.util.invalidateTags(["project"])),
    [dispatch],
  );

  React.useEffect(() => {
    invalidateProjectsTag();
  }, [invalidateProjectsTag]);

  const shopifyUrl = project?.integrations?.shopify?.store.shopifyUrl;

  const isLastProjectOnWorkspaceWithShopifySubscription = Boolean(
    projectActiveSubscription?.paymentProcessor === "shopify" &&
      shopifyUrl &&
      workspaceData?.projects.filter(
        ({ integrations }) =>
          integrations?.shopify?.store.shopifyUrl == shopifyUrl,
      ).length === 1,
  );

  const isDeleteButtonDisabled =
    hasProjectNameErrors || !projectNameValue || hasPublishedElements;
  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        closeModal({ type: "deleteProjectModal" });
      }}
      className="w-auto"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        mainContent={() => (
          <div className="flex flex-col gap-6 p-2 w-full">
            <h2 className="text-xl font-medium">Delete Project</h2>
            <p className="text-sm text-default">
              {!hasPublishedElements
                ? "Deleting this project will permanently erase it. The Replo Team can assist with recovery if needed."
                : "You must unpublish all live pages before deleting this project."}
            </p>
            {!hasPublishedElements &&
              isLastProjectOnWorkspaceWithShopifySubscription &&
              project?.ownerWorkspaceId && (
                <p className="text-sm text-default">
                  Your subscription will not be affected and you can update it
                  in the
                  <a
                    className="font-medium text-sm text-blue-600"
                    href={generatePath("/workspace/:workspaceId/billing", {
                      workspaceId: project.ownerWorkspaceId,
                    })}
                  >
                    Workspace Billing Settings
                  </a>
                  .
                </p>
              )}
            {isLoadingProject ? (
              <div className="w-16 mx-auto">
                <Loader />
              </div>
            ) : (
              <>
                {hasPublishedElements ? (
                  <div className="text-sm font-semibold text-slate-700">
                    Live Pages: {publishedElements.length}
                  </div>
                ) : (
                  <form
                    className="flex flex-col gap-6"
                    onSubmit={(data) => {
                      void handleSubmit(onSubmit)(data);
                    }}
                  >
                    <div className="flex flex-col gap-2">
                      <div className="text-sm text-slate-400 flex flex-col">
                        <div>
                          Type the name of the project to confirm deletion.
                        </div>
                        <div>Project Name: {projectNameFromData}</div>
                      </div>
                      <label htmlFor="workspaceName" className="sr-only">
                        Workspace name
                      </label>
                      <Input
                        id="projectName"
                        aria-invalid={hasProjectNameErrors ? "true" : undefined}
                        aria-describedby={
                          hasProjectNameErrors
                            ? "error-project-name"
                            : undefined
                        }
                        autoComplete="off"
                        placeholder={projectNameFromData}
                        {...register("projectName", {
                          required: "Please type the name of the project.",
                          validate: (value) => value === projectNameFromData,
                          // NOTE (Fran 2024-05-02): We should clear the errors when the user is typing
                          onChange: () => clearErrors("projectName"),
                        })}
                        type="text"
                        size="base"
                        validityState={
                          hasProjectNameErrors ? "invalid" : "valid"
                        }
                        autoFocus
                      />
                      <ErrorMessage
                        id="error-project-name"
                        error={errors.projectName?.message}
                      />
                    </div>
                    <div className="flex gap-2 self-end">
                      <Button
                        variant="secondary"
                        size="base"
                        className="text-xs"
                        onClick={() =>
                          closeModal({ type: "deleteProjectModal" })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="danger"
                        size="base"
                        className="text-xs"
                        type="submit"
                        disabled={isDeleteButtonDisabled}
                        isLoading={isPending}
                      >
                        Delete
                      </Button>
                    </div>
                  </form>
                )}
              </>
            )}
          </div>
        )}
      />
    </Modal>
  );
};
