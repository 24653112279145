import type { LeftBarTab } from "@reducers/ui-reducer";

import * as React from "react";

import { HotkeyIndicator } from "@common/HotkeyIndicator";
import ComponentTemplatePane from "@components/ComponentTemplatePane";
import { ComponentTreePane } from "@components/ComponentTreePane";
import {
  DEBUG_PANEL_WIDTH,
  HEADER_HEIGHT,
  MINIMUM_LEFT_BAR_WIDTH,
} from "@components/editor/constants";
import ElementsPane from "@components/ElementsPane";
import TourStepTrigger from "@components/flows/TourStepTrigger";
import ResizablePane from "@components/ResizablePane";
import SavedStylesPane from "@editor/components/designLibrary/SavedStylesPane";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useEditorPerformanceContext } from "@editor/contexts/editor-performance.context";
import { useSubscriptionTier } from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectLeftBarActiveTab,
  selectLeftBarNavigationOpenedMenu,
  selectLeftBarWidth,
  setLeftBarActiveTab,
  setLeftBarWidth,
} from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { docs } from "@editor/utils/docs";
import useCreateComponentForm from "@hooks/useCreateComponentForm";
import {
  selectDraftElementId,
  selectEditorMode,
  selectInternalDebugModeOn,
  setEditorMode,
} from "@reducers/core-reducer";

import IconButton from "@replo/design-system/components/button/IconButton";
import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import {
  BsChevronLeft,
  BsClockHistory,
  BsFileText,
  BsFileTextFill,
  BsGear,
  BsGearFill,
  BsLayers,
  BsLayersFill,
  BsPalette,
  BsPaletteFill,
  BsPlusSquare,
  BsPlusSquareFill,
  BsQuestionLg,
} from "react-icons/bs";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import DocumentationInfoIcon from "./editor/page/element-editor/components/DocumentationInfoIcon";

type LeftPanelMenuTab = {
  id: LeftBarTab;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  label?: React.ReactNode;
};

const LeftBar: React.FC = React.memo(function LeftBar() {
  const isDebugPanelVisible = useEditorSelector(selectInternalDebugModeOn);
  const editorMode = useEditorSelector(selectEditorMode);
  const { isLoading } = useCurrentProjectContext();
  const dispatch = useEditorDispatch();

  // Note (Evan, 2024-10-08): This hook ~will~ re-render with each streamed action,
  // but it's not an issue because we're just returning null while streaming. There is
  // a more global issue with this, which see: https://replohq.slack.com/archives/C03AACVP08Y/p1728413180369459.
  const { hasQueuedGeneration } = useAIStreaming();

  useCreateComponentForm(() => dispatch(setLeftBarActiveTab("components")));

  if (editorMode !== EditorMode.edit || isLoading || hasQueuedGeneration) {
    return null;
  }

  return (
    <div
      // NOTE (Gabe 2023-06-13): z-0 creates a new stacking context which enables
      // using z-index for sticky headers that don't overlay portals in the rest
      // of the app.
      className="absolute flex z-0"
      style={{
        // NOTE (Fran 2024-09-24): We should position the left panel based on the header's height and
        // the debug panel's width if it's open.
        top: HEADER_HEIGHT,
        left: isDebugPanelVisible ? DEBUG_PANEL_WIDTH : 0,
        // NOTE (Fran 2024-09-26): The left panel should take the entire viewport height minus the
        // header height.
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      }}
    >
      <LeftPanelMenu />
      <LeftPanelContent />
    </div>
  );
});

export default LeftBar;

const tabToTitleMap: Record<LeftBarTab, string> = {
  tree: "Layers",
  elements: "All Pages",
  components: "Insert",
  savedStyles: "Saved Styles",
};

const LeftPanelMenu: React.FC = () => {
  const dispatch = useEditorDispatch();

  const modal = useModal();
  const logEvent = useLogAnalytics();
  const subscriptionTier = useSubscriptionTier();

  const leftBarActiveTab = useEditorSelector(selectLeftBarActiveTab);
  const isGivenTabActive = (tab: LeftBarTab) => leftBarActiveTab === tab;
  const draftElementExists = Boolean(useEditorSelector(selectDraftElementId));

  const tabs: Array<LeftPanelMenuTab> = [
    {
      id: "elements",
      icon: <BsFileText />,
      activeIcon: <BsFileTextFill />,
      label: <HotkeyIndicator hotkey="setElementsPanel" title="All Pages" />,
    },
    {
      id: "tree",
      icon: <BsLayers />,
      activeIcon: <BsLayersFill />,
      label: <HotkeyIndicator hotkey="setLayersPanel" title="Layers" />,
    },
    {
      id: "components",
      icon: <BsPlusSquare />,
      activeIcon: <BsPlusSquareFill />,
      label: <HotkeyIndicator hotkey="setComponentsPanel" title="Insert" />,
    },
    {
      id: "savedStyles",
      icon: <BsPalette />,
      activeIcon: <BsPaletteFill />,
      label: (
        <HotkeyIndicator hotkey="setSavedStylesPanel" title="Saved Styles" />
      ),
    },
  ];

  const isModalSettingsOpened = Object.keys(modal.modals).some(
    (modal) => modal === "updateElementModal",
  );

  return (
    <div
      className={classNames(
        "bg-default flex flex-col gap-2 h-full border-r border-t border-slate-200 py-3 px-2 justify-between z-10",
        { "shadow-lg": leftBarActiveTab === null },
      )}
    >
      <div className="flex flex-col gap-2">
        {tabs.map((tab) => {
          const isActive = isGivenTabActive(tab.id);
          const title = tabToTitleMap[tab.id];
          return (
            <LeftPanelMenuButton
              key={tab.id}
              label={tab.label}
              ariaLabel={title}
              isActive={isActive}
              onClick={() => {
                logEvent("editor.panel.toggle", {
                  toggle: tab.id,
                  isOpened: !isActive,
                  openedWithShortcut: false,
                });
                dispatch(setLeftBarActiveTab(!isActive ? tab.id : null));
              }}
            >
              {isActive ? tab.activeIcon : tab.icon}
            </LeftPanelMenuButton>
          );
        })}
        <LeftPanelMenuButton
          label={
            <HotkeyIndicator
              hotkey="toggleVersionHistory"
              title="Version History"
            />
          }
          ariaLabel="Version History"
          // NOTE (Matt 2024-12-10): We can set this to false
          // because the button is not visible when the modal
          // is open. No need to look through all modals to verify.
          isActive={false}
          onClick={() => {
            dispatch(setLeftBarActiveTab(null));
            dispatch(setEditorMode(EditorMode.versioning));
          }}
        >
          <BsClockHistory />
        </LeftPanelMenuButton>
        {draftElementExists && (
          <LeftPanelMenuButton
            label={
              <HotkeyIndicator
                hotkey="openPageSettings"
                title="Page Settings"
              />
            }
            ariaLabel="Page Settings"
            isActive={isModalSettingsOpened}
            onClick={() => {
              // NOTE (Fran 2024-09-26): We need to set the left bar active tab to null
              // before opening the modal to avoid having to selected icons in the left bar.
              dispatch(setLeftBarActiveTab(null));
              modal.openModal({
                type: "updateElementModal",
              });
            }}
          >
            {isModalSettingsOpened ? <BsGearFill /> : <BsGear />}
          </LeftPanelMenuButton>
        )}
      </div>

      <LeftPanelMenuButton
        label="Get Help"
        ariaLabel="Get Help"
        onClick={() => {
          logEvent("user.help.requested", {
            billingPlan: subscriptionTier,
            from: "button",
          });
          modal.openModal({
            type: "supportModal",
          });
        }}
      >
        <BsQuestionLg />
      </LeftPanelMenuButton>
    </div>
  );
};

const LeftPanelMenuButton: React.FC<
  React.PropsWithChildren<{
    label: React.ReactNode;
    ariaLabel: string;
    onClick: () => void;
    isActive?: boolean;
  }>
> = ({ label, isActive, ariaLabel, children, onClick }) => {
  return (
    <Tooltip content={label} triggerAsChild>
      <button
        className={classNames(
          "p-1.5 cursor-pointer rounded text-xl",
          isActive
            ? "bg-accent text-onEmphasis"
            : "hover:bg-hover hover:text-accent",
        )}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        {children}
      </button>
    </Tooltip>
  );
};

const LeftPanelContent: React.FC = () => {
  const dispatch = useEditorDispatch();
  const logEvent = useLogAnalytics();
  const leftBarActiveTab = useEditorSelector(selectLeftBarActiveTab);

  if (leftBarActiveTab === null) {
    return null;
  }

  const content = exhaustiveSwitch({ type: leftBarActiveTab })<{
    component: React.ReactNode;
    documentationLink: string | null;
  }>({
    components: () => ({
      documentationLink: docs.leftBar.insert,
      component: <ComponentTemplatePane />,
    }),
    elements: () => ({
      documentationLink: docs.leftBar.elements,
      component: (
        <TourStepTrigger step="step-1">
          <ElementsPane />
        </TourStepTrigger>
      ),
    }),
    tree: () => ({
      // TODO (Fran 2024-09-20): Add this documentation link
      documentationLink: null,
      component: <ComponentTreePane />,
    }),
    savedStyles: () => ({
      documentationLink: docs.savedStyles.main,
      component: <SavedStylesPane />,
    }),
  });

  return (
    <ResizableLeftPanelContentWrapper>
      <div className="flex flex-col gap-2 h-full">
        <div className="flex justify-between items-center px-3 pt-3">
          <div className="flex gap-2 items-center">
            <span className="typ-header-base text-default">
              {tabToTitleMap[leftBarActiveTab]}
            </span>
            {content.documentationLink ? (
              <DocumentationInfoIcon href={content.documentationLink} />
            ) : null}
          </div>
          <IconButton
            className="text-subtle p-0 h-[unset]"
            variant="tertiary"
            icon={<BsChevronLeft size={12} />}
            onClick={() => {
              logEvent("editor.panel.toggle", {
                toggle: leftBarActiveTab,
                isOpened: false,
                openedWithShortcut: false,
              });
              dispatch(setLeftBarActiveTab(null));
            }}
            aria-label="Close left bar panel"
          />
        </div>
        {content.component}
      </div>
    </ResizableLeftPanelContentWrapper>
  );
};

const ResizableLeftPanelContentWrapper: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const dispatch = useEditorDispatch();
  const logEvent = useLogAnalytics();
  const leftBarWidth = useEditorSelector(selectLeftBarWidth);
  const leftBarNavigationOpenedMenu = useEditorSelector(
    selectLeftBarNavigationOpenedMenu,
  );
  const activeTab = useEditorSelector(selectLeftBarActiveTab);
  const previousResizableWidth = React.useRef(leftBarWidth);

  const { leftBarElementRef } = useEditorPerformanceContext();

  const isLeftBarNavigationOpen = leftBarNavigationOpenedMenu !== "";

  return (
    <div className="flex" ref={leftBarElementRef}>
      <ResizablePane
        minSize={MINIMUM_LEFT_BAR_WIDTH}
        maxSize={500}
        handleClassName="bg-slate-200 w-[1px]"
        className="flex shadow-lg"
        size={leftBarWidth}
        contentClassName="bg-default border-r border-t border-slate-200"
        onResize={(size) => dispatch(setLeftBarWidth(size))}
        onResizeStop={() => {
          logEvent("editor.panel.resize", {
            previousSize: previousResizableWidth.current,
            newSize: leftBarWidth,
            activePanel: activeTab,
          });
          previousResizableWidth.current = leftBarWidth;
        }}
        isDisabled={isLeftBarNavigationOpen}
      >
        {children}
      </ResizablePane>
    </div>
  );
};
