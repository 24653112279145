import type { FontOption } from "@editor/utils/font";

import * as React from "react";

import { normalizeFontFamily } from "@editor/components/editor/page/element-editor/components/modifiers/utils";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectDraftComponentId,
  selectDraftElementFontFamilies,
  selectDraftElementId,
  selectProject,
  selectSrcDocFontUrls,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { GOOGLE_FONT_OPTIONS } from "@editor/utils/font";
import getFontFamilyNameFromSrcUrl from "@editor/utils/getFontFamilyNameFromSrcUrl";
import { trpc } from "@editor/utils/trpc";
import { useTargetFrameDocument } from "@hooks/useTargetFrame";

import { skipToken } from "@tanstack/react-query";
import { shallowEqual } from "react-redux";
import { filterNulls } from "replo-utils/lib/array";

const isNoMirrorEnabled = isFeatureEnabled("no-mirror");

export const usePageFontOptions = (): FontOption[] => {
  const pageFonts = useEditorSelector(
    selectDraftElementFontFamilies,
    shallowEqual,
  );
  const pageFontOptions = filterNulls(Array.from(pageFonts)).map(
    (fontFamily) => {
      return {
        value: fontFamily,
        label: fontFamily,
        isDisabled: false,
        isDefaultActive: false,
        isSelectable: true,
      };
    },
  );
  return filterNulls([
    ...pageFontOptions
      .map((option) => {
        return {
          ...option,
          isSelectable: true,
          isDefaultActive: false,
          endEnhancer: null,
          groupTitle: "From Page",
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
  ]);
};

export const useShopifyFontOptions = () => {
  const elementId = useEditorSelector(selectDraftElementId);
  const componentId = useEditorSelector(selectDraftComponentId);
  const project = useEditorSelector(selectProject);
  const { data: uploadedFonts, refetch: refetchUploadedFonts } =
    trpc.store.getFonts.useQuery(
      project?.id ? { storeId: project?.id } : skipToken,
    );

  // NOTE (Chance 2024-06-24): It shouldn't matter which canvas we use here
  // since the DOM contents should be the same for each. We just need to read
  // the document's `fonts` property.
  const targetFrameDocument = useTargetFrameDocument("desktop");
  const srcDocFontUrls = useEditorSelector(selectSrcDocFontUrls);

  const uploadedFontNames = uploadedFonts?.map((font) => font.name) ?? [];

  const nameToDisplayName: Record<string, string | undefined> = uploadedFonts
    ? uploadedFonts.reduce(
        (acc, { name, displayName }) => ({
          ...acc,
          [name]: displayName,
        }),
        {},
      )
    : {};

  // NOTE (Jackson 2025-01-03): For post-no-mirror launch cleanup,
  // remove the dependency on elementId, componentId, and targetFrameDocument
  // and just use the srcDocFontUrls
  const themeFontNames = React.useMemo(() => {
    if (!isNoMirrorEnabled) {
      if (!elementId || !componentId || !targetFrameDocument?.fonts) {
        return [];
      }

      const fonts = Array.from(targetFrameDocument.fonts).map((font) =>
        normalizeFontFamily(font.family),
      );

      return filterNulls(fonts);
    }

    const srcDocFontFamilies = srcDocFontUrls.map((url) =>
      getFontFamilyNameFromSrcUrl(url),
    );

    return filterNulls(srcDocFontFamilies);
  }, [elementId, componentId, targetFrameDocument, srcDocFontUrls]);

  const combinedFontNames = [
    ...new Set(uploadedFontNames.concat(themeFontNames)),
  ];

  const formattedFonts = combinedFontNames
    ?.map((fontName) => {
      return {
        label: nameToDisplayName[fontName] ?? fontName,
        isDefaultActive: false,
        isDisabled: false,
        value: fontName ?? null,
        isSelectable: true,
        groupTitle: "From Shopify",
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const refetch = async () => {
    const result = await refetchUploadedFonts();
    return result.data;
  };

  return {
    fontOptions: formattedFonts,
    fallbacks: ["sans-serif"],
    refetch,
    nameToDisplayName,
  };
};

export const useAvailableFontFamilies = () => {
  const { fontOptions: shopifyFontOptions, nameToDisplayName } =
    useShopifyFontOptions();

  return React.useMemo(() => {
    const allOptions = [...shopifyFontOptions, ...GOOGLE_FONT_OPTIONS];

    return Array.from(
      new Set(filterNulls(allOptions.map((option) => option.value))),
    ).map((value) => ({
      name: value,
      displayName: nameToDisplayName[value],
    }));
  }, [shopifyFontOptions, nameToDisplayName]);
};
