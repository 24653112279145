import type { Context } from "replo-runtime/store/AlchemyVariable";
import type {
  DataTablesItemsConfig,
  HTMLAttributesConfig,
  InlineItemsConfig,
  ItemsConfig,
} from "schemas/dynamicData";

import * as React from "react";

import { ProductSelector } from "@common/designSystem/ProductSelector";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import { DebouncedInput } from "@editor/components/common/designSystem/Input";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { useModal } from "@editor/hooks/useModal";
import {
  selectDataTables,
  selectDraftComponent,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { hasDynamicData } from "@editorModifiers/utils";

import { Badge } from "@replo/design-system/components/badge";
import classNames from "classnames";
import intersection from "lodash-es/intersection";
import { BiData } from "react-icons/bi";
import { BsPlus, BsX } from "react-icons/bs";
import { FiMinus, FiPlus, FiTrash2 } from "react-icons/fi";
import { isDynamicDataValue } from "replo-runtime";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { itemTypeToRenderData } from "replo-runtime/store/utils/items";
import { filterNulls } from "replo-utils/lib/array";
import { isEmpty, isNullish } from "replo-utils/lib/misc";
import { ItemsConfigType } from "schemas/dynamicData";
import { v4 as uuidv4 } from "uuid";

// TODO (Juan, 2024-18-10 [REPL-14173]: Check if this modifier is still needed.

const getItemTypes = (context?: Context): ItemsConfigType[] => {
  const result = [ItemsConfigType.dataTable, ItemsConfigType.inline];
  if (context?.attributes?.["_product"]) {
    result.push(ItemsConfigType.productImages);
  }
  return result;
};

export const ItemsSelector: React.FC<
  React.PropsWithChildren<{
    value?: ItemsConfig | null;
    allowedItemTypes?: ItemsConfigType[];
    allowsDynamicDataWhenAvailable: boolean;
    onChange(value: ItemsConfig): void;
    onDelete?(): void;
    componentId?: string;
  }>
> = ({
  allowsDynamicDataWhenAvailable,
  onChange,
  value: itemsConfig,
  allowedItemTypes,
  componentId,
  onDelete,
}) => {
  const modal = useModal();
  const dataTables = useEditorSelector(selectDataTables);

  const selectedItemType =
    itemsConfig?.type ||
    (!isEmpty(allowedItemTypes)
      ? allowedItemTypes[0]
      : ItemsConfigType.dataTable);
  const itemTypes = allowedItemTypes;

  const _renderItemsTypeSelect = () => {
    return (
      <Selectable
        className="w-full"
        value={selectedItemType}
        options={(itemTypes ?? []).map((itemType) => {
          return {
            label: itemTypeToRenderData(itemType).displayName,
            value: itemType,
          };
        })}
        onSelect={(value) => {
          // @ts-ignore
          onChange({ ...itemsConfig, type: value });
        }}
      />
    );
  };

  const _openInlineItemsDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.ANY_LIST,
        referrerData: {
          type: "callback",
          onChange: (value: string) => {
            onChange({
              type: ItemsConfigType.inline,
              valueType: "dynamic",
              dynamicPath: value,
            });
          },
        },
        initialPath:
          itemsConfig?.type === "inline" && itemsConfig?.valueType === "dynamic"
            ? getPathFromVariable(itemsConfig.dynamicPath)
            : undefined,
      },
    });
  };

  const _renderInlineItemsEditor = (itemsConfig: InlineItemsConfig) => {
    return (
      <ModifierGroup
        isCollapsible={false}
        endEnhancer={
          itemsConfig?.valueType !== "dynamic" &&
          allowsDynamicDataWhenAvailable && (
            <DynamicDataButton
              onClick={() => {
                _openInlineItemsDynamicData();
              }}
            />
          )
        }
        title="Items"
        icon={itemsConfig?.valueType === "dynamic" ? null : FiPlus}
        iconAriaLabel={itemsConfig?.valueType === "dynamic" ? null : "Add Item"}
        onClick={() => {
          if (itemsConfig?.valueType === "dynamic") {
            return;
          }
          onChange({
            type: ItemsConfigType.inline,
            valueType: "string",
            values: [
              ...(itemsConfig?.values || []),
              // Note (Noah, 2021-08-29): This is undefined instead of null so
              // that dynamic data ignores it instead of converting to the string
              // "null" in the DOM
              { id: uuidv4(), value: undefined },
            ],
          });
        }}
      >
        {itemsConfig?.valueType === "dynamic" && (
          <DynamicDataValueIndicator
            type="other"
            templateValue={itemsConfig.dynamicPath}
            onClick={() => _openInlineItemsDynamicData()}
            onRemove={() => {
              onChange({
                type: ItemsConfigType.inline,
                valueType: "string",
                values: null,
              });
            }}
            componentId={componentId}
          />
        )}
        {itemsConfig?.valueType !== "dynamic" &&
          (itemsConfig?.values ?? []).map((value, index, values) => {
            return (
              <div key={value.id} className="flex flex-row items-center">
                <StaticItemsInput
                  key={value.id}
                  value={value.value ?? ""}
                  onChange={onChange}
                  index={index}
                  values={values}
                  placeholder="Enter value..."
                />
                <FiMinus
                  className="m-2 cursor-pointer justify-self-end"
                  size="20"
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange({
                      type: ItemsConfigType.inline,
                      valueType: "string",
                      values: filterNulls(
                        itemsConfig.values?.map((value, existingIndex) => {
                          if (existingIndex === index) {
                            return null;
                          }
                          return value;
                        }),
                      ),
                    });
                  }}
                />
              </div>
            );
          })}
      </ModifierGroup>
    );
  };

  const _renderDataTableSelectButton = (itemsConfig: DataTablesItemsConfig) => {
    const buttonLabel =
      getFromRecordOrNull(dataTables.mapping, itemsConfig?.id)?.name ??
      "Select Data Collection";

    return (
      <SelectionIndicator
        onClick={() =>
          modal.openModal({
            type: "dataTableModal",
            props: {
              referrer: "modifier",
              selectedDataTableId: itemsConfig?.id,
              onDataTableClick: (id: string) => {
                onChange({
                  ...itemsConfig,
                  type: ItemsConfigType.dataTable,
                  id,
                });
              },
            },
          })
        }
        title={buttonLabel}
        startEnhancer={
          <Badge
            type="icon"
            icon={<BiData />}
            className={classNames({
              "bg-accent text-white": itemsConfig?.id,
              "text-accent bg-transparent": !itemsConfig?.id,
            })}
          />
        }
        endEnhancer={
          itemsConfig?.id && (
            <BsX
              size={14}
              onClick={(e) => {
                e.stopPropagation();
                onDelete?.();
              }}
            />
          )
        }
      />
    );
  };

  const _renderProductSelect = () => {
    return (
      <ProductSelector
        // @ts-ignore
        selectedProductRef={itemsConfig?.productRef}
        onChange={(value) => {
          if (isNullish(value)) {
            onDelete?.();
          } else {
            onChange({
              type: ItemsConfigType.productImages,
              productRef: value,
            });
          }
        }}
        productRequestType="anyProducts"
        isMultiProducts={false}
      />
    );
  };

  const _renderHTMLAttributeSelector = (itemsConfig: HTMLAttributesConfig) => {
    const getChangeHandler = (attributeType: string, index: number) => {
      return (newValue: string) => {
        onChange({
          type: ItemsConfigType.htmlAttributes,
          values: (itemsConfig.values ?? []).map((value, existingIndex) =>
            existingIndex === index
              ? { ...value, [attributeType]: newValue }
              : value,
          ),
        });
      };
    };

    const openDynamicDataModal = (
      value: string | undefined,
      onChangeHandler: (value: string) => void,
    ) => {
      modal.openModal({
        type: "dynamicDataModal",
        props: {
          requestType: "prop",
          targetType: DynamicDataTargetType.TEXT,
          referrerData: {
            type: "callback",
            onChange: onChangeHandler,
          },
          initialPath: value ? getPathFromVariable(value) : undefined,
        },
      });
    };
    return (
      <ModifierGroup
        isCollapsible={false}
        title="Attributes"
        icon={BsPlus}
        iconAriaLabel="Add Attribute"
        onClick={() => {
          onChange({
            type: ItemsConfigType.htmlAttributes,
            values: [
              ...(itemsConfig?.values ?? []),
              {
                id: uuidv4(),
                valueType: "dataset",
                key: undefined,
                value: undefined,
              },
            ],
          });
        }}
      >
        {(itemsConfig?.values ?? []).map(
          ({ id, valueType, key, value }, index) => {
            return (
              <div key={id} className="mb-3 flex flex-col gap-2">
                <div className="w-full flex items-center justify-between gap-2">
                  <DebouncedInput
                    startEnhancer={
                      valueType === "dataset" && (
                        <span className="text-xs bg-slate-200 rounded px-1 text-slate-400">
                          data-
                        </span>
                      )
                    }
                    value={key ?? ""}
                    placeholder="Key"
                    onValueChange={(value) =>
                      getChangeHandler("key", index)(value)
                    }
                  />
                  <div className="flex h-6 w-6 cursor-pointer items-center justify-center gap-1 rounded shrink-0">
                    <FiTrash2
                      className="text-slate-400 cursor-pointer"
                      size="14"
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange({
                          type: ItemsConfigType.htmlAttributes,
                          values: itemsConfig.values?.filter(
                            (_, existingIndex) => index !== existingIndex,
                          ),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between">
                  {isDynamicDataValue(value) ? (
                    <DynamicDataValueIndicator
                      type="other"
                      templateValue={value!}
                      onClick={() =>
                        openDynamicDataModal(
                          value,
                          getChangeHandler("value", index),
                        )
                      }
                      onRemove={() => {
                        onChange({
                          type: ItemsConfigType.htmlAttributes,
                          values: itemsConfig.values?.filter(
                            (_, existingIndex) => index !== existingIndex,
                          ),
                        });
                      }}
                      componentId={componentId}
                    />
                  ) : (
                    <DebouncedInput
                      value={value ?? ""}
                      placeholder="Value"
                      allowsDynamicData
                      onClickDynamicData={() =>
                        openDynamicDataModal(
                          value,
                          getChangeHandler("value", index),
                        )
                      }
                      onValueChange={(value) =>
                        getChangeHandler("value", index)(value)
                      }
                    />
                  )}
                </div>
              </div>
            );
          },
        )}
      </ModifierGroup>
    );
  };
  return (
    <div className="flex flex-col gap-2">
      {itemTypes && itemTypes.length > 1 && _renderItemsTypeSelect()}
      {selectedItemType === ItemsConfigType.dataTable &&
        // @ts-ignore
        _renderDataTableSelectButton(itemsConfig)}
      {selectedItemType === ItemsConfigType.productImages &&
        _renderProductSelect()}
      {selectedItemType === ItemsConfigType.inline &&
        // @ts-ignore
        _renderInlineItemsEditor(itemsConfig)}
      {selectedItemType === ItemsConfigType.htmlAttributes &&
        _renderHTMLAttributeSelector(itemsConfig as HTMLAttributesConfig)}
    </div>
  );
};

const getInitialPath = (itemsConfig: ItemsConfig | null | undefined) => {
  if (
    itemsConfig?.type === ItemsConfigType.inline &&
    itemsConfig?.valueType === "dynamic"
  ) {
    return getPathFromVariable(itemsConfig.dynamicPath);
  }
  if (itemsConfig?.type === ItemsConfigType.dataTable && itemsConfig?.id) {
    return ["_dataTables", itemsConfig.id];
  }
  return undefined;
};

export const CombinedItemsSelector: React.FC<
  React.PropsWithChildren<{
    value?: ItemsConfig | null;
    onChange(value: ItemsConfig): void;
    onDelete(): void;
    componentId?: string;
  }>
> = ({ onChange, value: itemsConfig, componentId, onDelete }) => {
  const modal = useModal();
  const dataTables = useEditorSelector(selectDataTables);

  const openDynamicDataModal = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.ANY_LIST,
        referrerData: {
          type: "callback",
          onChange: (value: string, type?: "dynamicData" | "dataTableId") => {
            if (type === "dataTableId") {
              onChange({ id: value, type: ItemsConfigType.dataTable });
            } else {
              onChange({
                type: ItemsConfigType.inline,
                valueType: "dynamic",
                dynamicPath: value,
              });
            }
          },
        },
        initialPath: getInitialPath(itemsConfig),
        allowDataTables: true,
      },
    });
  };

  let templateValue: string | null = null;
  if (itemsConfig?.type === ItemsConfigType.productImages) {
    templateValue = "Product Images";
  } else if (
    itemsConfig?.type === ItemsConfigType.dataTable &&
    itemsConfig.id
  ) {
    templateValue = dataTables.mapping[itemsConfig.id]?.name ?? null;
  } else if (
    itemsConfig?.type === ItemsConfigType.inline &&
    itemsConfig?.valueType === "dynamic"
  ) {
    templateValue = itemsConfig.dynamicPath;
  }

  return (
    <div className="flex flex-row gap-2">
      <DynamicDataValueIndicator
        type="other"
        templateValue={templateValue}
        onClick={openDynamicDataModal}
        onRemove={onDelete}
        componentId={componentId}
      />
      <DynamicDataButton onClick={openDynamicDataModal} />
    </div>
  );
};

const ItemsModifier: React.FC<
  React.PropsWithChildren<{
    field: string;
    title?: string;
    allowedItemTypes?: ItemsConfigType[];
    allowsDynamicDataWhenAvailable: boolean;
  }>
> = ({ field, title, allowsDynamicDataWhenAvailable, allowedItemTypes }) => {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const applyComponentAction = useApplyComponentAction();
  const getAttribute = useGetAttribute();

  if (!draftComponent) {
    return null;
  }

  const itemsConfig: ItemsConfig | undefined = getAttribute(
    draftComponent,
    field,
    {},
  ).value;

  const possibleItemTypes = getItemTypes(
    getCurrentComponentContext(draftComponent?.id, 0),
  );
  const itemTypes = allowedItemTypes
    ? intersection(possibleItemTypes, allowedItemTypes)
    : possibleItemTypes;

  return (
    <ModifierGroup title={title || "Items"}>
      <ItemsSelector
        allowsDynamicDataWhenAvailable={
          allowsDynamicDataWhenAvailable && hasDynamicData(draftComponent?.id)
        }
        allowedItemTypes={itemTypes}
        value={itemsConfig}
        onChange={(newConfig) => {
          applyComponentAction({
            type: "setProps",
            value: {
              items: newConfig,
            },
          });
        }}
        onDelete={() => {
          // Note (Martin, 2022-04-29): props.field comes with a "props." prefix
          // that is not part of the name.
          const propName = field.split(".").pop();
          if (propName) {
            applyComponentAction({
              type: "deleteProps",
              propName,
            });
          }
        }}
        componentId={draftComponent.id}
      />
    </ModifierGroup>
  );
};

export default ItemsModifier;

function StaticItemsInput({
  value,
  onChange,
  values,
  index,
  placeholder,
}: {
  value: string;
  values: {
    id: string;
    value: string | undefined;
  }[];
  onChange(config: ItemsConfig): void;
  index: number;
  placeholder?: string;
}) {
  const onValueChange = React.useCallback(
    (newValue: string) => {
      const nextValues = values.map((value, existingIndex) => {
        if (existingIndex === index) {
          return { ...value, value: newValue };
        }
        return value;
      });
      onChange({
        type: ItemsConfigType.inline,
        valueType: "string",
        values: nextValues,
      });
    },
    [values, index, onChange],
  );
  return (
    <DebouncedInput
      value={value}
      onValueChange={onValueChange}
      placeholder={placeholder}
    />
  );
}
