import * as React from "react";

import { BackButton } from "@editor/components/common/BackButton";
import Separator from "@editor/components/common/designSystem/Separator";
import Header from "@editor/components/dashboard/Header";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc } from "@editor/utils/trpc";

import { METRIC_DEFINITIONS_URL } from "@/features/analytics/constants";
import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import PageTypeSelect from "@/features/analytics/selects/PageTypeSelect";
import Tooltip from "@replo/design-system/components/tooltip";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BsBook } from "react-icons/bs";
import { useLocation } from "react-router-dom";

interface AnalyticsLayoutProps {
  headerTitle?: string;
  children: React.ReactNode;
  showBackButton?: boolean;
}

const AnalyticsLayout: React.FC<AnalyticsLayoutProps> = ({
  headerTitle,
  children,
  showBackButton = false,
}) => {
  const analytics = useLogAnalytics();
  const workspaceId = useCurrentWorkspaceId();
  const { data: workspaceData, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : reactQuerySkipToken,
    );
  const isLoadingRequiredData = isLoadingWorkspace;

  const { dispatchAnalyticsQuery } = useAnalyticsQueryContext();
  const location = useLocation();

  const [isFromAnalyticsOverviewPage, setIsFromAnalyticsOverviewPage] =
    React.useState(false);

  /**
   * NOTE (Max, 2024-12-20): We check whether we're coming from the Analytics Overview page
   * to satisfy a Deep Dive page edge case. Consider this scenario:
   * 1) User is on the Overview page
   * 2) Clicks on the page name -> goes on Deep Dive page
   * 3) Changes the filters, e.g. the time period
   * 4) Clicks the "Back" button to go back to the Overview page
   *
   * If we don't have this check, then the "Back" button will navigate to the Overview page
   * WITHOUT the changes in filters made in step 3. That's because navigate(-1) in BackButton
   * will use the last URL params that were used in the Overview page -> but those changed in the
   * meantime (step 3).
   *
   * That's why we manually generate the overview path if we're coming from the Overview page,
   * so that the "Back" button will navigate to the Overview page WITH the filter changes in the
   * URL params.
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  React.useEffect(() => {
    if (location.state?.isFromAnalyticsOverviewPage) {
      setIsFromAnalyticsOverviewPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackButtonClick = () => {
    dispatchAnalyticsQuery({ type: "openOverviewPageFromDeepDivePage" });
  };

  return (
    <div className="flex flex-col px-6 w-full relative overflow-hidden">
      <div className="flex flex-row gap-4 justify-between">
        <div className="flex flex-row gap-4">
          {showBackButton &&
            (isFromAnalyticsOverviewPage ? (
              <BackButton onClick={handleBackButtonClick} />
            ) : (
              <BackButton />
            ))}
          <Header
            title={headerTitle ?? `${workspaceData?.workspace?.name} Analytics`}
            isLoading={isLoadingRequiredData}
          />
        </div>
        <div className="w-auto flex items-center justify-between gap-2  ">
          <PageTypeSelect />
          <Tooltip
            triggerAsChild
            content="Metric Definitions"
            delay={300}
            collisionPadding={10}
          >
            <a
              href={METRIC_DEFINITIONS_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-end justify-center gap-[4px]"
              onClick={() => {
                analytics("analytics.definitions.open", {});
              }}
            >
              <div className="flex items-center justify-center bg-slate-100 hover:bg-slate-200 gap-[10px] h-8 w-8 rounded group">
                <BsBook className="h-4 w-4 group-hover:text-default" />
              </div>
            </a>
          </Tooltip>
        </div>
      </div>
      <Separator className="my-4 col-span-12" />
      {children}
    </div>
  );
};

export default AnalyticsLayout;
