import type { SimpleExperiment } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import type {
  AnalyticsReadQuery,
  RangeResult,
} from "schemas/generated/analyticsRead";
import type { Experiment } from "schemas/generated/experiment";

import * as React from "react";

import MetricWithDelta from "@editor/components/analytics/MetricWithDelta";
import { Loader } from "@editor/components/common/Loader";

import { DEFAULT_FILTERS, DEFAULT_QUERY } from "@/features/analytics/constants";
import useBasicAnalyticsRead from "@/features/analytics/useBasicAnalyticsRead";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import { ExperimentActionsBar } from "@/features/experiments/components/ExperimentActionsBar";
import { ExperimentsLayout } from "@/features/experiments/Layout";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import { TabMenu } from "@/features/experiments/tabs/TabMenu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@replo/design-system/components/shadcn/core/table";
import Tooltip from "@replo/design-system/components/tooltip";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { endOfDay, startOfDay } from "date-fns";
import { BsBoxArrowUpRight, BsDash, BsInfoCircle } from "react-icons/bs";
import { convertMsToDays } from "replo-utils/lib/datetime";
import { ConditionOperatorEnum } from "schemas/analyticsRead";
import { getExperimentStatus } from "schemas/experiment";

const TABLE_METRICS: {
  label: string;
  key: string;
}[] = [
  { label: "Conversion Rate", key: "conversion_rates" },
  { label: "Sessions", key: "unique_sessions" },
  { label: "Average Order Value", key: "average_order_value" },
  { label: "Revenue per Session", key: "revenue_per_session" },
];

type ValueContainerProps = {
  className?: string;
  children: React.ReactNode;
};

const ValuesContainer: React.FC<ValueContainerProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={twMerge(
        "col-span-6 border border-slate-300 p-4 rounded-md flex flex-row gap-8",
        className,
      )}
    >
      {children}
    </div>
  );
};

type TitleWithValueProps = {
  title: string;
  value: string | null;
};

const TitleWithValue: React.FC<TitleWithValueProps> = ({ title, value }) => {
  return (
    <div className="flex flex-col gap-2 text-xs text-default">
      <span className="text-xs font-medium">{title}</span>
      <span className="text-xs font-medium">
        {value !== null ? value : <BsDash />}
      </span>
    </div>
  );
};

const DEFAULT_QUERY_MAIN_RANGE_ID = crypto.randomUUID();

const useAnalyticsReadForExperiments = (
  experimentId: string,
  startDatetime: number,
  endDatetime: number,
  interval: number,
  workspaceId: string | null,
) => {
  const workspaceUrlHosts = useWorkspaceUrlHosts(workspaceId);

  /**
   * NOTE (Max, 2024-10-17): We use a basic query where we get:
   * 1) urlHosts from the useWorkspaceUrlHosts hook
   * 2) mainRange datetimes from the Experiment's createdAt / endedAt dates
   * 3) filters using the experimentId: we're only interested in CH rows that contain
   * the experimentId as a urlParam
   */
  const query: AnalyticsReadQuery = {
    ...DEFAULT_QUERY,
    urlHosts: workspaceUrlHosts.data,
    order: "DESC",
    sortMetric: "conversion_rates",
    ranges: {
      mainRange: {
        id: DEFAULT_QUERY_MAIN_RANGE_ID,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
        interval,
      },
      compareAtRanges: [],
    },
    filters: {
      ...DEFAULT_FILTERS,
      urlParams: {
        "replo-experiment-id": [
          {
            operator: ConditionOperatorEnum.EQUALS,
            value: [`${experimentId}`],
          },
        ],
      },
    },
  };

  return useBasicAnalyticsRead({
    workspaceId,
    type: "withoutUrlSyncQuery",
    queryConfig: query,
  });
};

const ExperimentResultsTabInner: React.FC<{
  simpleExperiment: SimpleExperiment;
  workspaceId: string;
  experiment: Experiment | null;
}> = ({ simpleExperiment, experiment, workspaceId }) => {
  const {
    id,
    createdAt,
    createdAtHuman,
    endedAt,
    endedAtHuman,
    variations,
    name,
  } = simpleExperiment;

  const status = experiment ? getExperimentStatus(experiment) : null;

  const winner = variations.find(
    (variation) =>
      experiment?.selectedDisplayWinnerVariationId === variation.id,
  );

  const startDatetime = startOfDay(createdAt).valueOf();
  const endDatetime = endOfDay(endedAt ?? Date.now()).valueOf() + 1;
  const interval = endDatetime - startDatetime;

  const durationDays = convertMsToDays(interval);

  const { mainRangeResults, isLoading } = useAnalyticsReadForExperiments(
    id,
    startDatetime,
    endDatetime,
    interval,
    workspaceId ?? null,
  );

  const totalSessions = mainRangeResults
    ? mainRangeResults.reduce((total, rangeResult) => {
        const sessions = rangeResult.metrics.unique_sessions?.[0] || 0;
        return total + sessions;
      }, 0)
    : 0;

  const experimentResults = variations
    .filter((variation) => variation.target !== null)
    .map((variation) => {
      const targetURL = new URL(variation.target!);
      const urlPath = targetURL.pathname;

      const variationSlug = variation.slug;

      const associatedRangeResult: RangeResult | null =
        mainRangeResults?.find(
          (rangeResult) => rangeResult.urlPath === urlPath,
        ) ?? null;

      const metrics = associatedRangeResult?.metrics ?? {};

      return {
        targetURL,
        variationSlug,
        metrics,
      };
    });

  const getWinnerDisplay = () => {
    if (!endedAt) {
      return null;
    }

    return winner?.slug ?? "No Winner";
  };

  return (
    <ExperimentsLayout
      headerTitle={name}
      headerActions={
        experiment ? (
          <ExperimentActionsBar
            workspaceId={workspaceId}
            experiment={experiment}
          />
        ) : null
      }
      status={status ?? undefined}
      editable
      showBackButton
    >
      <TabMenu />
      <div className="grid grid-cols-12 gap-5">
        <ValuesContainer className="col-span-6">
          <TitleWithValue title="Sessions" value={`${totalSessions}`} />
          <TitleWithValue
            title="Duration"
            value={`${durationDays} day${durationDays !== 1 ? "s" : ""}`}
          />
          <TitleWithValue title="Winner" value={getWinnerDisplay()} />
        </ValuesContainer>

        <ValuesContainer className="col-span-6">
          <TitleWithValue title="Start Date" value={createdAtHuman} />
          <TitleWithValue title="End Date" value={endedAtHuman} />
        </ValuesContainer>
      </div>
      <div className="mt-5">
        {!isLoading ? (
          <Table className="table-fixed border-separate border-spacing-0">
            <TableHeader className="font-medium text-xs text-default">
              <TableRow>
                <CustomTableHead
                  label="Variant name"
                  className="border-l rounded-tl w-[20%]"
                />
                {TABLE_METRICS.map((metric, index) => (
                  <CustomTableHead
                    key={metric.key}
                    label={metric.label}
                    className={classNames({
                      "border-r border-l-0 rounded-tr":
                        index === TABLE_METRICS.length - 1,
                    })}
                  />
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {experimentResults?.map((experimentResult) => {
                return (
                  <ExperimentResultsTableRow
                    key={experimentResult.variationSlug}
                    experimentResult={experimentResult}
                    isLastMetric={TABLE_METRICS.length - 1}
                  />
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Loader />
        )}
      </div>
    </ExperimentsLayout>
  );
};

const ExperimentResultsTab: React.FC = () => {
  const { isLoadingExperiment, simpleExperiment, workspaceId, experiment } =
    useExperimentDetails();

  if (isLoadingExperiment || !simpleExperiment || !workspaceId) {
    return <Loader />;
  }
  return (
    <ExperimentResultsTabInner
      simpleExperiment={simpleExperiment}
      workspaceId={workspaceId}
      experiment={experiment}
    />
  );
};

type ExperimentResult = {
  targetURL: URL;
  variationSlug: string;
  metrics: RangeResult["metrics"];
};

type ExperimentResultsTableRowProps = {
  experimentResult: ExperimentResult;
  isLastMetric: number;
};

const ExperimentResultsTableRow: React.FC<ExperimentResultsTableRowProps> = ({
  experimentResult,
  isLastMetric,
}) => {
  const { targetURL, variationSlug, metrics } = experimentResult;
  return (
    <TableRow className="border-collapse">
      <TableCell className="border-b border-l">
        <div className="font-medium text-xs flex items-center gap-2">
          <Tooltip triggerAsChild content={targetURL.toString()} delay={800}>
            <span>{variationSlug}</span>
          </Tooltip>
          <a
            href={targetURL.toString()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsBoxArrowUpRight size={12} className="text-blue-600" />
          </a>
        </div>
      </TableCell>
      {TABLE_METRICS.map((metric, index) => {
        const currentMetricValues = metrics[metric.key] ?? [];
        return (
          <TableCell
            key={metric.key}
            className={classNames("border-b", {
              "border-r": index === isLastMetric,
            })}
          >
            <MetricWithDelta
              name={metric.key}
              value={currentMetricValues[0] ?? null}
              doesCompareValueExist={false}
              wrapperClassName="justify-start flex-grow"
              valueClassName="text-xs"
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

type CustomTableHeadProps = {
  label: string;
  tooltip?: string;
  className?: string;
};

const CustomTableHead = ({
  label,
  tooltip,
  className,
}: CustomTableHeadProps) => {
  return (
    <TableHead
      className={classNames(
        "overflow-hidden bg-subtle border border-r-0 border-l-0",
        className,
      )}
    >
      <div className="flex items-center gap-2">
        <span className="truncate">{label}</span>
        {tooltip && (
          <Tooltip content={tooltip} triggerAsChild side="bottom">
            <span className="flex-shrink-0 pl-2">
              <BsInfoCircle className="text-slate-400" />
            </span>
          </Tooltip>
        )}
      </div>
    </TableHead>
  );
};

export default ExperimentResultsTab;
