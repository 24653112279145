import type { EditorRepaintDependencies } from "@editor/reducers/utils/core-reducer-utils";
import type {
  ComponentDataMapping,
  ComponentStylesForChildren,
} from "replo-runtime/shared/Component";
import type { RuntimeContextValueMap } from "replo-runtime/shared/runtime-context";
import type { ComponentIdToStyleRulesMapping } from "replo-runtime/shared/styles";
import type { RuntimeFeatureFlags } from "replo-runtime/shared/types";
import type { ElementToPaint } from "replo-runtime/store/paint";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "schemas/component";
import type { ReploElement } from "schemas/generated/element";
import type { ReploSymbol } from "schemas/generated/symbol";
import type { PartialCanvases } from "./canvas-types";

import * as React from "react";

import {
  useInitComponentErrorContext,
  useInitComponentInventoryContext,
  useInitComponentUpdateContext,
  useInitCustomFontsContext,
  useInitDesignLibraryContext,
  useInitDraftElementContext,
  useInitDynamicDataStoreContext,
  useInitExtraContext,
  useInitFeatureFlagsContext,
  useInitRenderEnvironmentContext,
  useInitReploEditorActiveCanvasContext,
  useInitReploEditorCanvasContext,
  useInitReploElementContext,
  useInitReploSymbolsContext,
  useInitRuntimeHooksContext,
  useInitShopifyStoreContext,
  useInitSyncRuntimeStateContext,
} from "@editor/contexts/editor-runtime-context";
import {
  getTargetFrameDocument,
  getTargetFrameWindow,
} from "@editor/hooks/useTargetFrame";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectComponentDataMapping,
  selectComponentIdsToStyle,
  selectElementWithRevisionState,
  selectIsPreviewMode,
  setComponentDataMappingStylesForChildren,
} from "@editor/reducers/core-reducer";
import { editorNeedsRepaint } from "@editor/reducers/utils/core-reducer-utils";
import { store, useEditorDispatch, useEditorSelector } from "@editor/store";
import { findComponentById } from "@editor/utils/component";
import {
  generateStyles,
  injectStylesIntoDocument,
  upsertStyles,
} from "@editor/utils/styles";

import difference from "lodash-es/difference";
import omit from "lodash-es/omit";
import pick from "lodash-es/pick";
import { Provider } from "react-redux";
import { generateComponentStyleDataMapping } from "replo-runtime/shared/styles";
import { getFullPageOffset } from "replo-runtime/shared/utils/dom";
import { paint } from "replo-runtime/store/paint";
import { isEmpty } from "replo-utils/lib/misc";

import {
  increasePaintVersion,
  selectCanvases,
  selectCanvasesIFrames,
  selectCanvasesKeys,
  selectCanvasLoadingType,
  selectVisibleCanvases,
} from "./canvas-reducer";

const WrappedInReduxProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return <Provider store={store}>{children}</Provider>;
};

/**
 * Hook for running paint on the targetFrame when necessary.
 */
export function usePaintTargetFrames() {
  const canvasesKeys = useEditorSelector(selectCanvasesKeys);

  const canvasFramesRef = React.useRef<
    Record<string, HTMLIFrameElement | null>
  >(Object.fromEntries(canvasesKeys.map((canvas) => [canvas, null])));

  const previousRepaintDependenciesRef =
    React.useRef<EditorRepaintDependencies | null>(null);
  const previousVisibleCanvasesKeysRef = React.useRef<EditorCanvas[]>([]);

  const dispatch = useEditorDispatch();
  const canvasLoadingType = useEditorSelector(selectCanvasLoadingType);
  const canvasesIframes = useEditorSelector(selectCanvasesIFrames);
  const componentIdsToStyle = useEditorSelector(selectComponentIdsToStyle);
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const { elementMapping } = useEditorSelector(selectElementWithRevisionState);
  const canvases = useEditorSelector(selectCanvases);
  const visibleCanvases = useEditorSelector(selectVisibleCanvases);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const renderEnvironmentContext = useInitRenderEnvironmentContext();
  const componentErrorContext = useInitComponentErrorContext();
  const componentInventoryContext = useInitComponentInventoryContext();
  const componentUpdateContext = useInitComponentUpdateContext();
  const customFontsContext = useInitCustomFontsContext();
  const draftElementContext = useInitDraftElementContext();
  const dynamicDataStoreContext = useInitDynamicDataStoreContext();
  const shopifyStoreContext = useInitShopifyStoreContext();
  const extraContext = useInitExtraContext();
  const featureFlagsContext = useInitFeatureFlagsContext();
  const reploEditorCanvasContext = useInitReploEditorCanvasContext();
  const reploEditorActiveCanvasContext =
    useInitReploEditorActiveCanvasContext();
  const reploElementContext = useInitReploElementContext();
  const reploSymbolsContext = useInitReploSymbolsContext();
  const syncRuntimeStateContext = useInitSyncRuntimeStateContext();
  const runtimeHooksContext = useInitRuntimeHooksContext();
  const designLibraryContext = useInitDesignLibraryContext();

  const { draftElementId } = draftElementContext;
  const { featureFlags } = featureFlagsContext;
  const { symbols } = reploSymbolsContext;

  const draftElement = draftElementId
    ? elementMapping[draftElementId] ?? null
    : null;

  const isNoMirrorEnabled = isFeatureEnabled("no-mirror");

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    const canvasesToPaint = isPreviewMode
      ? { desktop: canvases.desktop }
      : visibleCanvases;
    const isSafeToPaint = Object.values(canvasesToPaint).every((canvas) => {
      const frame = canvas.targetFrame ?? null;
      // NOTE (Matt 2023-10-23): It is possible for targetFrameWindow to be null
      // for only a brief moment when a user switches the element type they are
      // editing (ie switching from a "Page" element to a "Section"). It is okay
      // to not throw an error here, as the same check is made in useFrameOnLoad
      // and an error is correctly thrown at that time.
      const frameWindow = frame ? getTargetFrameWindow(frame) : null;
      return Boolean(frameWindow) || isNoMirrorEnabled;
    });

    const previousTargetFrames = canvasFramesRef.current;
    canvasFramesRef.current = canvasesIframes;

    if (!isSafeToPaint) {
      return;
    }

    const repaintDependencies: EditorRepaintDependencies = {
      elementMapping,
      draftElementId,
    };
    const previousRepaintDependencies = previousRepaintDependenciesRef.current;
    previousRepaintDependenciesRef.current = repaintDependencies;

    const visibleCanvasesKeys = Object.keys(visibleCanvases) as EditorCanvas[];
    const previousVisibleCanvasesKeys = previousVisibleCanvasesKeysRef.current;
    previousVisibleCanvasesKeysRef.current = visibleCanvasesKeys;
    const elementToPaint = getElementToPaint(draftElement);
    const needsRepaint = editorNeedsRepaint(
      previousRepaintDependencies,
      repaintDependencies,
    );

    // Note (Evan, 2024-07-18): We call execUpdateElementStylesheets in the
    // same cases in which we repaint - paintType is not "none" or the target frame
    // is new. In the multicanvas case, we check that any target frame is new.
    const isAnyCanvasNewTargetFrame = Object.entries(visibleCanvases).some(
      ([key, canvas]) =>
        canvas.targetFrame !== previousTargetFrames[key as EditorCanvas],
    );

    if (isAnyCanvasNewTargetFrame || needsRepaint.paintType !== "none") {
      const targetFramesToStyle = [];
      for (const [key, { targetFrame }] of Object.entries(canvasesToPaint)) {
        if (!targetFrame) {
          continue;
        }

        targetFramesToStyle.push(targetFrame);
        execPaint(
          key as EditorCanvas,
          targetFrame,
          previousTargetFrames[key as EditorCanvas] ?? null,
        );
      }
      dispatch(increasePaintVersion());
      execUpdateElementStylesheets();
    }

    function execPaint(
      canvas: EditorCanvas,
      targetFrame: HTMLIFrameElement,
      previousTargetFrame: HTMLIFrameElement | null,
    ) {
      const isNoMirrorEnabled = isFeatureEnabled("no-mirror");
      if (isNoMirrorEnabled) {
        return;
      }
      // NOTE (Chance 2024-06-25): Non-null assertion is safe here because we
      // checked for the target frame window before exiting via `isSafeToPaint`
      const targetFrameWindow = getTargetFrameWindow(targetFrame)!;

      const contexts: RuntimeContextValueMap = {
        componentError: componentErrorContext,
        componentInventory: componentInventoryContext,
        componentUpdate: componentUpdateContext,
        customFonts: customFontsContext,
        draftElement: draftElementContext,
        dynamicDataStore: dynamicDataStoreContext,
        shopifyStore: shopifyStoreContext,
        syncRuntimeState: syncRuntimeStateContext,
        extraContext: extraContext,
        featureFlags: featureFlagsContext,
        // NOTE (Chance 2024-06-25): This is a hack to get around the fact
        // that we are initializing context outside of a specific canvas, so
        // we don't know the target window or canvas until now.
        globalWindow: targetFrameWindow,
        editorCanvas: canvas,
        renderEnvironment: renderEnvironmentContext,
        reploEditorCanvas: reploEditorCanvasContext,
        reploEditorActiveCanvas: reploEditorActiveCanvasContext,
        reploElement: reploElementContext,
        reploSymbols: reploSymbolsContext,
        runtimeHooks: runtimeHooksContext,
        designLibrary: designLibraryContext,
      };

      // Paint is required initially, when targetFrame has just been loaded.
      // Note (Noah, 2022-02-01): If the iframe was updated to a different element
      // (which happens sometimes, for example if this component is HMR updated)
      // then we always repaint
      if (previousTargetFrame !== targetFrame) {
        paint({
          targetWindow: targetFrameWindow,
          elements: Object.values(repaintDependencies.elementMapping),
          elementToPaint,
          // Note (Mariano, 2022-05-26): if we are here it means the iframe was
          // re-mounted, probably due to a new call to mirror which means we are
          // loading everything from scratch, so it makes sense to force the
          // remount. Otherwise we won't see any element in the canvas
          forceRemount: true,
          // TODO (Noah, 2024-07-03, REPL-12467): Simplify the types to make null make sense here
          // @ts-expect-error
          contexts,
          ProviderComponent: WrappedInReduxProvider,
        });
      } else {
        if (needsRepaint.paintType === "none") {
          return;
        }

        const forceRemount =
          needsRepaint.paintType === "all" ? needsRepaint.forceRemount : false;

        paint({
          targetWindow: targetFrameWindow,
          elements: Object.values(repaintDependencies.elementMapping),
          elementToPaint,
          forceRemount,
          // TODO (Noah, 2024-07-03, REPL-12467): Simplify the types to make null make sense here
          // @ts-expect-error
          contexts,
          ProviderComponent: WrappedInReduxProvider,
        });
      }
    }

    function execUpdateElementStylesheets() {
      const isNoMirrorEnabled = isFeatureEnabled("no-mirror");

      if (
        elementToPaint.type === "specificElement" && // Note (Chance 2023-07-17, REPL-7972) When switching between a page and a
        // section, a new canvas is created and we need to make sure it's finished
        // loading before we try to update the stylesheets. Otherwise the new
        // styles will be attached to the document from the old canvas and the new
        // page will end up without styles.
        canvasLoadingType === "loaded"
      ) {
        // NOTE (Chance 2024-06-25): Non-null assertion is safe here because we
        // checked for the target frame window before exiting via `isSafeToPaint`
        const { stylesForChildrenMapping } =
          updateElementStylesheets({
            draftElement:
              repaintDependencies.elementMapping[
                elementToPaint.specificElementId!
              ]!,
            symbols,
            featureFlags,
            canvases: Object.fromEntries(
              Object.entries(visibleCanvases).filter(([, canvas]) => {
                return Boolean(canvas.targetFrame) || isNoMirrorEnabled;
              }),
            ),
            componentIds: componentIdsToStyle,
            componentDataMapping,
            newVisibleCanvases:
              difference(visibleCanvasesKeys, previousVisibleCanvasesKeys) ??
              [],
          }) ?? {};

        if (!isEmpty(stylesForChildrenMapping)) {
          dispatch(
            setComponentDataMappingStylesForChildren(stylesForChildrenMapping),
          );
        }
      }
    }
    // NOTE (Chance 2024-03-28): EXTREMELY IMPORTANT: We should *not* be
    // ignoring this, but since we're constructing new context objects in the
    // effect we need to be careful not to trigger unwanted re-runs of `paint`.
    // If new dependencies are introduced to this effect, make sure to update
    // the dependency array below, as the linter won't catch it. Once we
    // completely eliminate `renderExtras` and `repaintDependencies` we should
    // be able to remove this before the hook itself is removed.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvases.desktop,
    canvasLoadingType,
    componentDataMapping,
    canvasesIframes,
    componentIdsToStyle,
    dispatch,
    draftElement,
    draftElementId,
    elementMapping,
    featureFlags,
    symbols,
    visibleCanvases,
  ]);
}

function getElementToPaint(draftElement: ReploElement | null) {
  return {
    type: "specificElement" as const,
    specificShopifyPageId: draftElement?.shopifyPageId,
    specificElementId: draftElement?.id,
  } satisfies ElementToPaint;
}

function updateElementStylesheets(opts: {
  draftElement: ReploElement;
  symbols: ReploSymbol[];
  featureFlags: RuntimeFeatureFlags;
  canvases: PartialCanvases;
  componentDataMapping: ComponentDataMapping;
  componentIds: string[];
  newVisibleCanvases: EditorCanvas[];
}) {
  const {
    draftElement,
    symbols,
    canvases,
    featureFlags,
    componentIds,
    componentDataMapping,
    newVisibleCanvases,
  } = opts;

  const rootComponent = draftElement?.component;

  if (!rootComponent) {
    return;
  }

  const buildConfig = {
    isEditor: true,
    symbols,
    featureFlags,
  };

  const stylesForChildrenMapping: Record<
    Component["id"],
    ComponentStylesForChildren
  > = {};

  const firstCanvasFrame = Object.values(canvases)[0]?.targetFrame;
  const canvasDocument = getTargetFrameDocument(firstCanvasFrame);
  const fullPageOffset = canvasDocument ? getFullPageOffset(canvasDocument) : 0;

  const styleRules: ComponentIdToStyleRulesMapping = {};
  // NOTE (Martin, 2024-05-27): If there are new visible canvases or no specific
  // updated component ids, we generate and apply styles for the root component.
  if (newVisibleCanvases.length > 0 || componentIds.length === 0) {
    const canvasesToStyle =
      componentIds.length === 0 ? canvases : pick(canvases, newVisibleCanvases);
    const componentStyleDataMapping = generateComponentStyleDataMapping(
      rootComponent,
      {
        componentContext: {
          isRoot: true,
          parentHasVariants: (rootComponent.variants?.length ?? 0) > 0,
          parentStyleProps: null,
          parentOverrideStyleRules: null,
          fullPageOffset,
        },
        buildConfig,
        overridesRegistry: {},
      },
    );
    for (const [componentId, componentStyleData] of Object.entries(
      componentStyleDataMapping,
    )) {
      const { styleRulesMapping, childComponentContext } = componentStyleData;
      if (styleRulesMapping) {
        styleRules[componentId] = styleRulesMapping;
        if (
          childComponentContext?.parentStyleProps ||
          childComponentContext?.parentOverrideStyleRules
        ) {
          stylesForChildrenMapping[componentId] = {
            parentStyleProps: childComponentContext.parentStyleProps ?? null,
            parentOverrideStyleRules:
              childComponentContext.parentOverrideStyleRules ?? null,
          };
        }
      }
    }
    const styles = generateStyles(styleRules);

    for (const canvas of Object.values(canvasesToStyle)) {
      const paintableDocument = getTargetFrameDocument(
        canvas.targetFrame as unknown as HTMLIFrameElement,
      )!;
      injectStylesIntoDocument(styles, paintableDocument);
    }
  }

  if (componentIds.length > 0) {
    const canvasesToStyle: PartialCanvases = omit(canvases, newVisibleCanvases);
    const styledComponentIds = new Set();
    // NOTE (Martin, 2024-05-27): componentIds might have repeated values for
    // some actions like "Group Into Container" so we create a new in order
    // to be sure that we don't run the loop below unnecessarily.
    const uniqueComponentIds = new Set(componentIds);
    for (const componentId of uniqueComponentIds) {
      // NOTE (Martin, 2024-04-30): this guard optimizes re-styling by
      // preventing style trees that have been already recreated to be
      // done again for no reason. This happens when an ancestor has already
      // triggered a restyle for a child component.
      if (!styledComponentIds.has(componentId)) {
        // Note (Evan, 2024-08-21): We cannot use the componentMapping here
        // (as things currently stand) because it doesn't reflect AI streaming updates.
        const component = findComponentById(draftElement, componentId);
        if (component) {
          const componentData = componentDataMapping[componentId];
          const parentComponentStylesForChildren = componentData?.parentId
            ? componentDataMapping[componentData.parentId]?.stylesForChildren ??
              null
            : null;
          const parsedParentComponentStylesForChildren =
            parentComponentStylesForChildren
              ? (JSON.parse(
                  parentComponentStylesForChildren,
                ) as ComponentStylesForChildren)
              : null;

          const componentStyleDataMapping = generateComponentStyleDataMapping(
            component,
            {
              componentContext: {
                isRoot: componentData?.parentId === null,
                parentHasVariants: componentData?.ancestorHasVariants ?? false,
                parentOverrideStyleRules:
                  parsedParentComponentStylesForChildren?.parentOverrideStyleRules,
                parentStyleProps:
                  parsedParentComponentStylesForChildren?.parentStyleProps ??
                  null,
                fullPageOffset,
              },
              buildConfig,
              overridesRegistry: {},
            },
          );
          for (const [componentId, componentStyleData] of Object.entries(
            componentStyleDataMapping,
          )) {
            const { styleRulesMapping, childComponentContext } =
              componentStyleData;
            if (styleRulesMapping) {
              styleRules[componentId] = styleRulesMapping;
              if (
                childComponentContext?.parentStyleProps ||
                childComponentContext?.parentOverrideStyleRules
              ) {
                stylesForChildrenMapping[componentId] = {
                  parentStyleProps:
                    childComponentContext.parentStyleProps ?? null,
                  parentOverrideStyleRules:
                    childComponentContext.parentOverrideStyleRules ?? null,
                };
              }

              styledComponentIds.add(componentId);
            }
          }
        }
      }
    }

    const updatedStyles = upsertStyles(
      styleRules,
      getTargetFrameDocument(
        Object.values(canvasesToStyle)[0]
          ?.targetFrame as unknown as HTMLIFrameElement,
      )!,
    );
    for (const canvas of Object.values(canvasesToStyle)) {
      const paintableDocument = getTargetFrameDocument(
        canvas.targetFrame as unknown as HTMLIFrameElement,
      )!;
      injectStylesIntoDocument(updatedStyles, paintableDocument);
    }
  }

  return { stylesForChildrenMapping };
}
