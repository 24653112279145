import type { Nullish } from "replo-utils/lib/types";
import type { Action } from "schemas/actions";
import type {
  Component,
  CustomPropDefinition,
  ReploComponentType,
} from "schemas/component";
import type { ReploElement } from "schemas/generated/element";
import type { ReploState } from "schemas/generated/symbol";
import type { ProductRefOrDynamic } from "schemas/product";
import type { ComponentDataMapping } from "../Component";
import type {
  Dependency,
  ReploShopifyOptionKey,
  ReploShopifyProduct,
  ReploShopifyVariant,
  StoreVariant,
} from "../types";
import type { SupportedMediaSizeWithDefault } from "./breakpoints";
import type { FlexDirection } from "./flexDirection";

import get from "lodash-es/get";
import zip from "lodash-es/zip";
import { filterNulls } from "replo-utils/lib/array";
import {
  deepCloneAndMergeReplacingArrays,
  exhaustiveSwitch,
} from "replo-utils/lib/misc";

import { getRenderData } from "../../store/components";
import { selectedVariantFromStoreProductOptions } from "../../store/utils/product";
import { AlchemyActionTriggers } from "../enums";
import { DependencyType } from "../types";
import { defaultMediaSize } from "./breakpoints";
import { getCurrentComponentContext, resolveContextValue } from "./context";
import { getNormalizedFlexDirection } from "./flexDirection";

export const getCustomPropDefinitions = (
  component: Component,
): CustomPropDefinition[] => {
  const customProps: CustomPropDefinition[] = [];

  // Unique props definitions for this component
  const componentCustomPropsDefinitions = component.customPropDefinitions;
  if (componentCustomPropsDefinitions) {
    for (const key in componentCustomPropsDefinitions) {
      const value = componentCustomPropsDefinitions[key];
      if (value) {
        customProps.push({ ...value, id: key });
      }
    }
  }

  // Generic props by component type
  const componentTypeProps = getRenderData(component.type)?.customProps;
  if (componentTypeProps) {
    customProps.push(...componentTypeProps);
  }

  // Extra props for "tabs__panelsContent" components
  if (component.type === "tabs__panelsContent") {
    const context = getCurrentComponentContext(component.id, 0);
    const items: { id: string; value: string }[] =
      context?.state.tabsBlock?.items ||
      // @ts-ignore
      (component.props["_tabPanelIds"] || []).map((id: string) => {
        return { id, value: "" };
      });
    customProps.push(
      ...items.map<CustomPropDefinition>((item) => {
        return {
          id: `_tabPanel_${item.id}`,
          type: "component",
          name: `Panel: ${item.value}`,
          defaultValue: null,
          description: "This is a dynamic tab component.",
        };
      }),
    );
  }

  // NOTE (Matt 2023-10-16): We need this here to allow all components to have dataset attributes
  // automatically included as a configurable option in the config panel.
  customProps.push({
    id: "_datasetAttributes",
    type: "htmlAttributes",
    name: "Dataset Attributes",
    description:
      "If set, dataset attributes will appear in the HTML tag at the root of this component.",
    defaultValue: null,
  });

  return customProps;
};

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum TreeOrCustomProp {
  tree = "tree",
  customComponentProp = "customComponentProp",
}
export type TypedChildren = {
  child: Component;
  type: TreeOrCustomProp;
};

export const getTypedChildren = (
  component: Component | null,
  // NOTE (Martin, 2024-04-22): This is an optional parameter that allows us
  // to bypass getting custom prop definitions from the component itself by
  // looking at the ones already present on componentDataMapping. This is useful
  // for the editor, when we already have the map being built and it's good
  // for performance to cut down on the number of times we have to call this.
  componentDataMapping?: ComponentDataMapping,
): TypedChildren[] => {
  if (!component) {
    return [];
  }

  const children = component?.children || [];

  const componentPropDefinitions =
    componentDataMapping?.[component.id]?.customPropDefinitions ??
    getCustomPropDefinitions(component).filter(
      (definition) => definition.type === "component",
    );

  const propComponents = componentPropDefinitions
    .map((definition) => component.props[definition.id])
    .filter(Boolean) as Component[];
  const typedChildren = children.map((child) => ({
    child,
    type: TreeOrCustomProp.tree,
  }));
  const typedPropComponents = propComponents.map((child) => ({
    child,
    type: TreeOrCustomProp.customComponentProp,
  }));
  return filterNulls(typedChildren.concat(typedPropComponents));
};

export const getChildren = (
  component: Component | null,
  componentDataMapping?: ComponentDataMapping,
): Component[] => {
  // Note (Noah, 2022-03-23, REPL-1374): There's a bug where sometimes null
  // children get added somehow - we filter nulls here to not make the app
  // crash when this happens.
  return filterNulls(
    getTypedChildren(component, componentDataMapping).map(
      (typedChild) => typedChild.child,
    ),
  );
};

export const getChildrenIds = (
  componentId: Component["id"] | null,
  componentDataMapping?: ComponentDataMapping,
) => {
  if (!componentId) {
    return [];
  }

  const componentData = componentDataMapping?.[componentId];
  if (!componentData) {
    return [];
  }

  return componentData.containedComponentData
    .filter(([, , level]) => level === 1)
    .map(([id]) => id);
};

type ForEachComponentAndDescendantsContext = {
  componentOverrides?: Record<
    Component["id"],
    { variantId: ReploState["id"]; partialComponent: Partial<Component> }[]
  >;
};

/**
 * Executes the given function on a component and its descendants recursively,
 * in depth-first-search order. If the function returns "stop", the traversal
 * will stop.
 */
export function forEachComponentAndDescendants(
  component: Component | null,
  execute: (
    component: Component,
    parent: Component | null,
    context: ForEachComponentAndDescendantsContext,
    variantId: ReploState["id"] | null,
  ) => "stop" | "continue" | "ignoreSubtree" | void,
  parentComponent: Component | null = null,
  context: ForEachComponentAndDescendantsContext = {},
  config?: { executeForAllVariantOverrides?: boolean },
): "stop" | "continue" {
  if (!component) {
    return "continue";
  }

  if (component.variants) {
    for (const variant of component.variants) {
      for (const [componentId, partialComponent] of Object.entries(
        variant.componentOverrides ?? {},
      )) {
        if (!context.componentOverrides) {
          context.componentOverrides = {};
        }
        context.componentOverrides[componentId] =
          context.componentOverrides[componentId] ?? [];
        context.componentOverrides![componentId]!.push({
          variantId: variant.id,
          // TODO (Chance 2024-04-25): Consider validating this type (it may be
          // too complex and not worth it).
          partialComponent: partialComponent as Partial<Component>,
        });
      }
    }
  }

  if (component.variantOverrides) {
    for (const [variantId, partialVariant] of Object.entries(
      component.variantOverrides,
    )) {
      for (const [componentId, partialComponent] of Object.entries(
        partialVariant.componentOverrides ?? {},
      )) {
        if (!context.componentOverrides) {
          context.componentOverrides = {};
        }
        context.componentOverrides[componentId] =
          context.componentOverrides[componentId] ?? [];
        context.componentOverrides![componentId]!.push({
          variantId,
          partialComponent,
        });
      }
    }
  }

  const componentsToExecute: {
    component: Component;
    variantId: ReploState["id"] | null;
  }[] = [{ component, variantId: null }];
  if (config?.executeForAllVariantOverrides) {
    context.componentOverrides?.[component.id]?.forEach((override) => {
      const newComponent: Component = deepCloneAndMergeReplacingArrays(
        { ...component, children: [] },
        override.partialComponent,
      );
      newComponent.children = component.children;
      componentsToExecute.push({
        component: newComponent,
        variantId: override.variantId,
      });
    });
  }

  for (const { component, variantId } of componentsToExecute) {
    const result = execute(component, parentComponent, context, variantId);
    if (result === "stop") {
      return "stop";
    }
    if (result === "ignoreSubtree") {
      return "continue";
    }
  }

  const children = getChildren(component);
  for (const child of children) {
    const result = forEachComponentAndDescendants(
      child,
      execute,
      component,
      context,
      config,
    );
    if (result === "stop") {
      return "stop";
    }
  }

  return "continue";
}

/**
 * This function return all the component ids which are selected via any
 * container as accessibility label
 */
export const getAllAriaLabelledByIds = (
  element: { component: Component } | null,
) => {
  if (!element) {
    return [];
  }
  const ariaLabelledByIds: string[] = [];
  forEachComponentAndDescendants(element.component, (component) => {
    if (component?.props?._accessibilityLabelledBy) {
      ariaLabelledByIds.push(component.props._accessibilityLabelledBy);
    }
  });
  // NOTE (Chance 2024-04-26): Sorting the array to ensure stability in the
  // event individual IDs don't actually change. Order shouldn't matter.
  return ariaLabelledByIds.sort();
};

type VariantOptionKey = "option1" | "option2" | "option3";

export const getProductOptionValues = (
  variants: StoreVariant[],
  options: string[],
  selectedOptionLabel?: string,
) => {
  const optionValues: string[] = [];
  const optionLabel = selectedOptionLabel ?? options[0];
  const selectedOptionIndex = options.indexOf(optionLabel!);

  variants.forEach((variant) => {
    const optionKey = `option${Math.min(
      selectedOptionIndex + 1,
      3,
    )}` as VariantOptionKey;

    const option = variant[optionKey];

    // TODO (Ovishek, Noah, 2022-05-04, REPL-1961): Somehow, this option value
    // can be an array. We think this is a graphql issue graphql issue, it is
    // definitely supposed to be a string all the time. We should figure out why
    // this is happening
    if (typeof option === "string") {
      optionValues.push(option);
    }
  });

  return [...new Set(optionValues)];
};

/**
 * This function returns if the variant wich would be selected if the user changed
 * the given `optionKey` to `optionValue` is available.
 */
export const isOptionValueAvailable = (
  variants: ReploShopifyVariant[],
  options: string[],
  optionValue: string,
  optionKey: ReploShopifyOptionKey,
  selectedAlchemyVariant: ReploShopifyVariant,
): boolean => {
  const availableVariants = variants.filter((variant) => variant.available);

  if (availableVariants?.length > 0) {
    const optionsFromSelectedVariant = Object.fromEntries(
      ["option1", "option2", "option3"].map((key) => [
        key,
        selectedAlchemyVariant[key as ReploShopifyOptionKey],
      ]),
    );

    const optionsValues = {
      ...(optionsFromSelectedVariant as Record<ReploShopifyOptionKey, string>),
      [optionKey]: optionValue,
    };

    const mappedOptions = options.map((optionName, index) => {
      return {
        key: `option${index + 1}` as ReploShopifyOptionKey,
        name: optionName,
      };
    });

    const variantIfThisOptionWasSelected =
      selectedVariantFromStoreProductOptions(
        mappedOptions,
        variants,
        optionsValues,
      );

    return availableVariants.some(
      (variant) => variant.id === variantIfThisOptionWasSelected?.id,
    );
  }

  return false;
};

export function forEachOnlyTreeComponentAndDescendants(
  component: Component,
  execute: (component: Component) => void,
) {
  if (!component) {
    return;
  }
  execute(component);
  getTypedChildren(component)
    .filter((typedChild) => typedChild.type === TreeOrCustomProp.tree)
    .map((typedChild) => typedChild.child)
    .forEach((child) => {
      forEachOnlyTreeComponentAndDescendants(child, execute);
    });
}

/**
 * This function finds the component by type, but the one with the max children length
 * if there's more than one component has max children length then the shallower will be returned
 */
export function findComponentByTypeAndMaxLengthBFS(
  component: Component,
  type: ReploComponentType,
  stopTest: (component: Component) => boolean = () => false,
) {
  let comparingComponentObj = {
    component: null as Component | null,
    numberOfChildren: 0,
  };

  if (stopTest(component)) {
    return comparingComponentObj;
  }

  if (component.type === type) {
    comparingComponentObj = {
      component,
      numberOfChildren: getChildren(component).length,
    };
  }

  for (const child of getChildren(component)) {
    const {
      component: foundComponent,
      numberOfChildren: foundNumberOfChildren,
    } = findComponentByTypeAndMaxLengthBFS(child, type, stopTest);
    if (
      (foundComponent && !comparingComponentObj.component) ||
      (foundComponent &&
        comparingComponentObj.component &&
        foundNumberOfChildren > comparingComponentObj.numberOfChildren)
    ) {
      comparingComponentObj = {
        component: foundComponent,
        numberOfChildren: foundNumberOfChildren,
      };
    }
  }

  return comparingComponentObj;
}

/**
 * Finds a component by type with a breadth first search algorithm
 */
export function findComponentByTypeBreadthFirst(
  component: Component,
  type: ReploComponentType,
) {
  if (!component.children) {
    return null;
  }

  const foundComponent = component.children.find((c) => c.type === type);

  if (foundComponent) {
    return foundComponent;
  }

  let result;
  component.children.some(
    // biome-ignore lint/suspicious/noAssignInExpressions: allow set expression
    (c) => (result = findComponentByTypeBreadthFirst(c, type)),
  );
  return result ?? null;
}

/**
 * Finds a component by type with a depth first search algorithm
 */
export function findComponentByTypeDepthFirst(
  component: Component,
  type: ReploComponentType,
  stopBoundaryType?: ReploComponentType,
  ignoreSubtree?: (component: Component) => boolean,
): Component | null | undefined {
  let targetComponent: Component | null = null;
  forEachComponentAndDescendants(component, (currentComponent) => {
    if (ignoreSubtree?.(currentComponent)) {
      return "ignoreSubtree";
    }

    if (currentComponent.type === type) {
      targetComponent = currentComponent;
      return "stop";
    }

    if (
      stopBoundaryType &&
      component.id !== currentComponent.id &&
      currentComponent.type === stopBoundaryType
    ) {
      return "stop";
    }

    return "continue";
  });

  return targetComponent;
}

/**
 * Finds the path from a specific root component to a child component.
 */
export const findChildComponentPath = (
  component: Component | null,
  targetComponentId: string | null | undefined,
  suffix = "c",
): string => {
  if (!component || !targetComponentId) {
    return "";
  }

  if (component.id === targetComponentId) {
    return suffix;
  }

  const children = getChildren(component);
  for (const [i, child] of children.entries()) {
    const result = findChildComponentPath(child, targetComponentId, String(i));
    if (result.length > 0) {
      return `${suffix}.${result}`;
    }
  }

  return "";
};

export const getExpectedFlexBasis = ({
  hasFlexGrow,
  parentFlexDirection,
  parentHasDefinedWidth,
  parentHasDefinedHeight,
}: {
  hasFlexGrow: boolean | Nullish;
  parentFlexDirection: string | Nullish;
  parentHasDefinedWidth: boolean | Nullish;
  parentHasDefinedHeight: boolean | Nullish;
}) => {
  const parentHasHeightOrWidth =
    (parentFlexDirection === "column" && parentHasDefinedHeight) ||
    (parentFlexDirection === "row" && parentHasDefinedWidth);

  return hasFlexGrow && parentHasHeightOrWidth ? 0 : "auto";
};

// TODO (Ovishek, 2022-07-28): There is another function componentHasDefinedWidth what does similar, componentHasDefinedWidth
// is being used when changing flexGrow/flexDirection, we should remove flexBasis calculations during those actions, b/c it'll be
// always calculated on runtime.
export const componentHasDefinedWidthV2 = (
  parentFlexDirection: FlexDirection,
  parentHasDefinedWidth: boolean,
  currentStyles: Record<string, any>,
  isRoot: boolean,
): boolean => {
  const currentComponentHasWidth =
    currentStyles?.width && currentStyles?.width !== "auto";

  if (["fixed", "absolute"].includes(currentStyles?.position)) {
    return currentComponentHasWidth;
  }

  const normalizedParentFlexDirection =
    getNormalizedFlexDirection(parentFlexDirection);
  const hasFlexGrow =
    currentStyles?.flexGrow && currentStyles?.flexGrow !== "unset";

  return (
    isRoot ||
    currentComponentHasWidth ||
    (parentHasDefinedWidth &&
      normalizedParentFlexDirection === "column" &&
      currentStyles?.alignSelf === "stretch") ||
    (parentHasDefinedWidth &&
      normalizedParentFlexDirection === "row" &&
      hasFlexGrow)
  );
};

export const componentHasDefinedHeightV2 = (
  parentFlexDirection: FlexDirection,
  parentHasDefinedHeight: boolean,
  currentStyles: Record<string, any>,
): boolean => {
  parentFlexDirection = getNormalizedFlexDirection(parentFlexDirection);
  const hasFlexGrow =
    currentStyles?.flexGrow && currentStyles?.flexGrow !== "unset";

  const currentComponentHasHeight =
    currentStyles?.height && currentStyles?.height !== "auto";

  if (["fixed", "absolute"].includes(currentStyles?.position)) {
    return currentComponentHasHeight;
  }

  return (
    currentComponentHasHeight ||
    (parentHasDefinedHeight &&
      parentFlexDirection === "row" &&
      currentStyles?.alignSelf === "stretch") ||
    (parentHasDefinedHeight && parentFlexDirection === "column" && hasFlexGrow)
  );
};

export function findComponentInComponent(
  component: Component,
  test: (component: Component) => boolean,
): Component | null {
  return findComponent({ component: component }, test);
}

export function findComponent(
  element: { component: Component },
  test: (component: Component) => boolean,
): Component | null {
  const [, component] = findComponentIndexPath(element, test);
  return component;
}

export function findComponentIndexPath(
  element: { component: Component },
  test: (component: Component) => boolean,
): [string | null, Component | null] {
  const childPath = findComponentPath(element, test);
  if (childPath !== null) {
    const component = get(element, childPath, null);
    return [childPath, component];
  }
  return [null, null];
}

export const findComponentPath = (
  element: { component: Component },
  test: (c: Component) => boolean,
): string | null => {
  if (!element) {
    return null;
  }
  const helper = (component: Component, temp: string): string | null => {
    if (!component) {
      return null;
    }
    if (test(component)) {
      return temp;
    }
    const children = component?.children || [];
    for (const [index, child] of children.entries()) {
      const hasPath = helper(child, `${temp}.children[${index}]`);
      if (hasPath) {
        return hasPath;
      }
    }

    const componentPropDefinitions = getCustomPropDefinitions(component).filter(
      (definition) => definition.type === "component",
    );

    // @ts-ignore
    const propComponents: Component[] = componentPropDefinitions.map(
      (definition) => component.props[definition.id],
    );

    for (const [definition, propComponent] of zip(
      componentPropDefinitions,
      propComponents,
    )) {
      const hasPath = helper(propComponent!, `${temp}.props.${definition!.id}`);
      if (hasPath) {
        return hasPath;
      }
    }
    return null;
  };
  return helper(element.component, "component");
};

export function findParent(
  element: ReploElement | null,
  targetComponentId: string | null,
): Component | null {
  if (!targetComponentId || !element) {
    return null;
  }
  return findComponent(element, (possibleParent) => {
    return getChildren(possibleParent)
      .map((child) => child.id)
      .includes(targetComponentId);
  });
}

export function findComponentAncestorComponentOrSelf(
  rootComponent: Component,
  targetComponent: Component,
  test: (component: Component, parent: Component | null) => boolean,
): Component | null {
  if (!rootComponent || !targetComponent) {
    return null;
  }

  if (
    test(
      targetComponent,
      findParent(
        { component: rootComponent } as ReploElement,
        targetComponent.id,
      ),
    )
  ) {
    return targetComponent;
  }

  const { foundComponent } = findIndexPathFromComponentToComponent(
    rootComponent,
    null,
    targetComponent.id,
    test,
  );

  return foundComponent;
}

/**
 * Recursive helper:
 * For a given element treated as a root, find any component which is an ancestor
 * of targetComponentId and that matches `test`, and return it and its index
 * path relative to `rootComponent`
 */
export function findIndexPathFromComponentToComponent(
  rootComponent: Component,
  rootComponentParent: Component | null,
  targetComponentId: string,
  test: (component: Component, parent: Component | null) => boolean,
): {
  shouldCheckAncestors: boolean;
  indexPath: string | null;
  foundComponent: Component | null;
} {
  // If we found the target component we want to check ancestors of, return
  // immediately, and we'll check all the ancestors up the recursive tree
  if (rootComponent?.id === targetComponentId) {
    return {
      shouldCheckAncestors: true,
      indexPath: null,
      foundComponent: null,
    };
  }

  let shouldCheckAncestors = false;
  for (const [index, child] of getChildren(rootComponent).entries()) {
    const {
      shouldCheckAncestors: shouldCheckThisComponent,
      indexPath,
      foundComponent,
    } = findIndexPathFromComponentToComponent(
      child,
      rootComponent,
      targetComponentId,
      test,
    );
    // If we found a matching component by checking this subtree, just return
    // that all the way up the recursion
    if (foundComponent !== null) {
      return {
        shouldCheckAncestors: false,
        indexPath: `children[${index}].${indexPath}`,
        foundComponent: foundComponent,
      };
    }

    // If we didn't find a matching component but we know we're an ancestor of
    // the target component, do the test, and if it succeeds just return that
    // up the recursion
    if (shouldCheckThisComponent && test(rootComponent, rootComponentParent)) {
      return {
        shouldCheckAncestors: false,
        indexPath: `children[${index}].${indexPath}`,
        foundComponent: rootComponent,
      };
    }

    // If this component didn't match the test but we found the target component
    // in a subtree, inform the level above us to continue checking, since the
    // level above is the target component's ancestor
    shouldCheckAncestors = shouldCheckAncestors || shouldCheckThisComponent;
  }
  return {
    shouldCheckAncestors,
    indexPath: null,
    foundComponent: null,
  };
}

export const createDefaultVerticalContainer = (
  id: string,
  children: Component[],
): Component => {
  return {
    id,
    type: "container",
    props: {
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        maxWidth: "100%",
      },
    },
    children,
  };
};

// TODO (Fran 2024-03-07): Implement this in another package. It is a shared function that both
// publisher and editor use.
export const calculateProductDependenciesFromProps = (component: Component) => {
  const dependency: Dependency[] = [];
  const productPropDefinitions = getCustomPropDefinitions(component).filter(
    (prop) =>
      prop.type === "product" ||
      prop.type === "products" ||
      prop.type === "productFromPropsOrFromContext",
  );

  for (const propDefinition of productPropDefinitions) {
    const contextValue = resolveContextValue(
      component.props?.[propDefinition.id] as
        | ReploShopifyProduct
        | ProductRefOrDynamic,
    );
    let productIds = [];

    if (Array.isArray(contextValue)) {
      productIds = contextValue.map((c) => c.productId);
    } else if (contextValue?.productId) {
      productIds = [String(contextValue?.productId)];
    }

    if (productIds.length > 0) {
      dependency.push({
        type: DependencyType.products,
        productIds: productIds,
      });
    }
  }
  return dependency;
};

export const calculateProductDependenciesForActionTriggers = (
  component: Component,
) => {
  const dependency: Dependency[] = [];
  AlchemyActionTriggers.forEach((trigger) => {
    if (component.props?.[trigger]) {
      const actions = component.props?.[trigger] as Action[] | undefined;
      actions?.forEach((action) => {
        if (action.type === "addProductVariantToCart") {
          const value = resolveContextValue(action.value.product);
          if (value) {
            dependency.push({
              type: DependencyType.products,
              productIds: [Number(value.productId)],
            });
          }
        }
        if (action.type === "redirectToProductPage") {
          const productRefFromAction =
            action.value && "product" in action.value
              ? action.value.product
              : action.value;
          const value = resolveContextValue(productRefFromAction);
          if (value) {
            dependency.push({
              type: DependencyType.products,
              productIds: [Number(value.productId)],
            });
          }
        }
        if (action.type === "updateCurrentProduct") {
          const value = resolveContextValue(action.value);
          if (value) {
            dependency.push({
              type: DependencyType.products,
              productIds: [Number(value.productId)],
            });
          }
        }
      });
    }
  });
  return dependency;
};

/**
 * Applies prop overrides, depending on the current media size.
 * We apply overrides from larger media sizes (same as with styles)
 */
export const getPropsWithOverrides = (
  props: Component["props"],
  mediaSize: SupportedMediaSizeWithDefault,
): Component["props"] => {
  if (mediaSize === defaultMediaSize) {
    return props;
  }
  const mdOverrides = props["overrides"]?.md;
  const smOverrides = props["overrides"]?.sm;

  return exhaustiveSwitch({ type: mediaSize })({
    sm: () => ({
      ...props,
      ...mdOverrides,
      ...smOverrides,
    }),
    md: () => ({ ...props, ...mdOverrides }),
  });
};
