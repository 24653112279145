import type { AnalyticsQueryAction } from "@/features/analytics/contexts/AnalyticsQueryContext";
import type {
  ChartInterval,
  InternalQuery,
  SelectedTimePeriod,
  UrlSyncedQuery,
} from "@/features/analytics/query";
import type {
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "@/features/analytics/time";
import type { FilterCondition } from "schemas/generated/analyticsRead";
import type { AnalyticsUrlParamsFilterTypes } from "./moreFilters/constants";

import * as React from "react";

import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  generatePathWithCompressedUrlSyncedQuery,
  isCustomDateRange,
} from "@editor/utils/analytics";
import { routes } from "@editor/utils/router";

import { getChartIntervalOptions } from "@/features/analytics/chart";
import { DEFAULT_FILTERS, DEFAULT_QUERY } from "@/features/analytics/constants";
import { AnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import {
  addEntryToUrlParams,
  removeEntryFromUrlParams,
} from "@/features/analytics/moreFilters/utils";
import { generateDefaultUrlSyncedQuery } from "@/features/analytics/query";
import { COMPARE_PREVIOUS_PERIOD } from "@/features/analytics/time";
import useCurrentAnalyticsTab from "@/features/analytics/useCurrentAnalyticsTab";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import isEqual from "lodash-es/isEqual";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import {
  compressObjectToLzString,
  decompressObjectFromLzString,
} from "replo-utils/lib/lzString";
import { exhaustiveSwitchGeneric } from "replo-utils/lib/misc";
import { ConditionOperatorEnum, PageTypeEnum } from "schemas/analyticsRead";

/**
 * The AnalyticsContext is the ONLY place where we should be manipulating fields
 * that will be used in the AnalyticsReadQuery.
 *
 * It exposes the query, which is a combination of the urlSyncedQuery (fields that are synced with the URL)
 * and internalQuery (other fields that don't need to be synced with the URL). To update the query (whether
 * it's the urlSyncedQuery or internalQuery), we should use the dispatchAnalyticsQuery() function.
 *
 * We should NEVER be directly accessing the URL to update fields in there, otherwise
 * we'll result in a state where our URL fields are not in sync with the context -> 2 sources of truth issue.
 *
 * @author Max 2024-12-15
 */
const AnalyticsContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const workspaceId = useCurrentWorkspaceId();

  const workspaceUrlHosts = useWorkspaceUrlHosts(workspaceId);

  const defaultUrlSyncedQuery = generateDefaultUrlSyncedQuery({
    urlHosts: workspaceUrlHosts.data,
  });

  const analytics = useLogAnalytics();
  const navigate = useNavigate();

  const analyticsTab = useCurrentAnalyticsTab();

  const compressedUrlSyncedQuery = searchParams.get("query");

  // Returns the urlSyncedQuery that was stored in the URL, under the ?query='' param.
  // If there wasn't a ?query='' param in the URL, it returns the defaultUrlSyncedQuery.
  const urlSyncedQuery = compressedUrlSyncedQuery
    ? decompressObjectFromLzString<UrlSyncedQuery>(compressedUrlSyncedQuery)
    : defaultUrlSyncedQuery;

  const [internalQuery, setInternalQuery] =
    React.useState<InternalQuery>(DEFAULT_QUERY);

  // ALWAYS use this function to update the urlSyncedQuery. This will ensure that it's
  // synced with the URL, under the ?query='' param. This function updates both the context
  // and the URL params.
  const updateUrlSyncedQuery = (urlSyncedQuery: UrlSyncedQuery) => {
    const compressedUrlSyncedQuery = compressObjectToLzString(urlSyncedQuery);

    setSearchParams(
      (prev) => {
        prev.set("query", compressedUrlSyncedQuery);
        return prev;
      },
      { replace: true },
    );
  };

  /**
   * NOTE (Max, 2024-12-16):
   * On initial load, defaultUrlSyncedQuery won't have any urlHosts (as it's using the
   * useWorkspaceUrlHosts() hook, and it won't have had time to fetch the urlHosts
   * on initial load, so the urlHosts will be empty).
   *
   * This means that on initial load, IF there was no ?query='', then urlSyncedQuery
   * will have no urlHosts (as if there's no ?query='' it'll use defaultUrlSyncedQuery,
   * which would have no urlHosts on initial load, as explained above).
   *
   * So as soon as the useWorkspaceUrlHosts() hook has fetched the urlHosts, the
   * defaultUrlSyncedQuery will have urlHosts, and we'll need to update the urlSyncedQuery
   * to contain these urlHosts (again, only if there was no ?query='', as this would mean
   * urlHosts were empty).
   *
   * This below is essentially doing: "if urlSyncedQuery doesn't have any urlHosts, AND
   * defaultUrlSyncedQuery now has urlHosts (as they loaded), then update the urlSyncedQuery
   * (which will also update the ?query='')"
   */
  if (
    urlSyncedQuery.urlHosts.length === 0 &&
    !isEqual(urlSyncedQuery.urlHosts, defaultUrlSyncedQuery.urlHosts)
  ) {
    updateUrlSyncedQuery({
      ...urlSyncedQuery,
      urlHosts: defaultUrlSyncedQuery.urlHosts,
    });
  }

  const resetInternalAndUrlSyncedQueries = () => {
    setInternalQuery(DEFAULT_QUERY);
    updateUrlSyncedQuery(defaultUrlSyncedQuery);
  };

  const getOrder = (isActiveMetric: boolean) => {
    if (!isActiveMetric) {
      return internalQuery.order;
    }
    return internalQuery.order === "DESC" ? "ASC" : "DESC";
  };

  const dispatchAnalyticsQuery = (action: AnalyticsQueryAction) => {
    exhaustiveSwitchGeneric(
      action,
      "type",
    )({
      updatePagination: (action) => {
        setInternalQuery({
          ...internalQuery,
          offset: action.payload,
        });
      },
      sortMetric: (action) => {
        const sortMetric = action.payload;
        const isActiveMetric = internalQuery.sortMetric === sortMetric;

        setInternalQuery({
          ...internalQuery,
          sortMetric,
          order: getOrder(isActiveMetric),
          offset: 0,
        });
      },
      urlHosts: (action) => {
        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          urlHosts: action.payload,
        });
      },
      updateMainRange: (action) => {
        const selectedTimeFrameValue = action.payload;

        const selectedTimePeriod: SelectedTimePeriod = isCustomDateRange(
          selectedTimeFrameValue,
        )
          ? {
              type: "custom",
              value: {
                from: selectedTimeFrameValue.from.getTime(),
                to: selectedTimeFrameValue.to.getTime(),
              },
            }
          : {
              type: "relative",
              value: selectedTimeFrameValue as RelativeTimeFrame,
            };

        const { intervalOptions } = getChartIntervalOptions(selectedTimePeriod);

        const defaultChartInterval = intervalOptions[0]?.value ?? "day";

        const chartInterval = intervalOptions
          .map((option) => option.value)
          .includes(urlSyncedQuery.chartInterval)
          ? urlSyncedQuery.chartInterval
          : (defaultChartInterval as ChartInterval);

        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          selectedTimePeriod,
          chartInterval,
        });

        analytics("analytics.time.filter", {
          range:
            typeof selectedTimeFrameValue === "string"
              ? selectedTimeFrameValue
              : "custom",
          location: analyticsTab,
        });
      },
      updateCompareRange: (action) => {
        const selectedComparePeriodValue = action.payload;

        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          selectedComparePeriod: isCustomDateRange(selectedComparePeriodValue)
            ? {
                type: "custom",
                value: {
                  from: selectedComparePeriodValue.from.getTime(),
                  to: selectedComparePeriodValue.to.getTime(),
                },
              }
            : {
                type: "relative",
                value: selectedComparePeriodValue as ComparisonTimeFrame,
              },
        });

        analytics("analytics.compare", {
          compareDetail:
            selectedComparePeriodValue === COMPARE_PREVIOUS_PERIOD
              ? "previous-period"
              : "custom",
          location: analyticsTab,
        });
      },
      /**
       * NOTE (Max, 2024-12-15): For the urlPath <Input />, we're using the CONTAINS operator
       * to find all urlPaths that contain the search term. When the search term updates, we
       * simply update urlSyncedQuery so that the CONTAINS operator contains the new searchTerm.
       */
      "filters.updateUrlPathSearch": (action) => {
        const searchTerm = action.payload;
        const urlPathFiltersWithoutContains =
          urlSyncedQuery.filters.urlPath.filter(
            (filterCondition) =>
              filterCondition.operator !== ConditionOperatorEnum.CONTAINS,
          );

        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          filters: {
            ...urlSyncedQuery.filters,
            urlPath: [
              ...urlPathFiltersWithoutContains,
              {
                operator: ConditionOperatorEnum.CONTAINS,
                value: searchTerm,
              },
            ],
          },
        });
      },
      "filters.replaceUrlPath": (action) => {
        const urlPath = action.payload;

        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          filters: {
            ...urlSyncedQuery.filters,
            urlPath: [
              {
                operator: ConditionOperatorEnum.EQUALS,
                value: [urlPath],
              },
            ],
          },
        });
      },
      updateChartInterval: (action) => {
        const chartInterval = action.payload;

        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          chartInterval,
        });
      },

      initDeepDivePage: (action) => {
        const { urlPath } = action.payload;

        const newUrlPathFilters: FilterCondition[] = [
          {
            operator: ConditionOperatorEnum.EQUALS,
            value: [urlPath],
          },
        ];

        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          filters: {
            ...urlSyncedQuery.filters,
            urlPath: newUrlPathFilters,
          },
        });
      },
      initOverviewPage: () => {
        /**
         * NOTE (Max, 2024-12-14): If we were on the deep dive page, then the urlSyncQuery will have
         * the urlPathFilters set to be equal to whatever urlPath we were on the deep dive page. If we don't
         * do the following operation on initial mount, then we'll just be querying for that 1 urlPath.
         */
        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          filters: {
            ...urlSyncedQuery.filters,
            urlPath: DEFAULT_FILTERS.urlPath,
          },
        });
      },
      "filters.pageType": (action) => {
        const pageType = action.payload;

        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          filters: { ...urlSyncedQuery.filters, pageType },
        });

        analytics("analytics.report.switch", {
          switchTo:
            pageType === PageTypeEnum.ENTRY_PAGES ? "entry_page" : "sessions",
          location: analyticsTab,
        });
      },
      "filters.deleteUrlParam": (action) => {
        const { attribute, operator, value } = action.payload;

        const urlParamToRemove = {
          attribute,
          operator,
          value,
        };

        const updatedUrlParams = removeEntryFromUrlParams({
          currentUrlParams: urlSyncedQuery.filters.urlParams,
          urlParamToRemove,
        });

        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          filters: { ...urlSyncedQuery.filters, urlParams: updatedUrlParams },
        });
      },
      "filters.addUrlParam": (action) => {
        const updatedUrlParams = addEntryToUrlParams({
          currentUrlParams: urlSyncedQuery.filters.urlParams,
          urlParamToAdd: action.payload,
        });

        updateUrlSyncedQuery({
          ...urlSyncedQuery,
          filters: { ...urlSyncedQuery.filters, urlParams: updatedUrlParams },
        });

        const { attribute, operator } = action.payload;

        analytics("analytics.filter", {
          type: attribute as AnalyticsUrlParamsFilterTypes,
          filterDetail: operator,
          location: analyticsTab,
        });
      },
      openDeepDivePageFromOverviewPage: (action) => {
        const urlPath = action.payload;

        analytics("analytics.record.click", {
          from: "analytics_overview",
        });

        const urlSyncedQueryWithUrlPathFilter: UrlSyncedQuery = {
          ...urlSyncedQuery,
          filters: {
            ...urlSyncedQuery.filters,
            urlPath: [
              {
                operator: ConditionOperatorEnum.EQUALS,
                value: [urlPath],
              },
            ],
          },
        };

        const deepDivePath = generatePath(routes.analytics.pageDetails, {
          workspaceId,
        });

        const deepDivePathWithCompressedUrlSyncedQuery =
          generatePathWithCompressedUrlSyncedQuery({
            path: deepDivePath,
            urlSyncedQueryConfig: {
              type: "decompressed",
              urlSyncedQuery: urlSyncedQueryWithUrlPathFilter,
            },
          });

        navigate(deepDivePathWithCompressedUrlSyncedQuery, {
          state: { isFromAnalyticsOverviewPage: true },
        });
      },
      openOverviewPageFromDeepDivePage: () => {
        const overviewPath = generatePath(routes.analytics.overview, {
          workspaceId,
        });
        const overviewPathWithCompressedUrlSyncedQuery =
          generatePathWithCompressedUrlSyncedQuery({
            path: overviewPath,
            urlSyncedQueryConfig: {
              type: "decompressed",
              urlSyncedQuery,
            },
          });

        navigate(overviewPathWithCompressedUrlSyncedQuery);
      },
    });
  };

  const contextValues = {
    resetInternalAndUrlSyncedQueries,
    dispatchAnalyticsQuery,
    query: { ...internalQuery, ...urlSyncedQuery },
    workspaceUrlHosts,
  };

  return (
    <AnalyticsQueryContext.Provider value={contextValues}>
      {children}
    </AnalyticsQueryContext.Provider>
  );
};

export default AnalyticsContext;
