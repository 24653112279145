import * as React from "react";

import { errorToast } from "@common/designSystem/Toast";

import { useLogAnalytics } from "./useLogAnalytics";

export const useErrorToast = () => {
  const logEvent = useLogAnalytics();

  return React.useCallback(
    (title: string, message: string, errorEvent: any) => {
      logEvent(errorEvent.type, errorEvent.eventProperties);
      return errorToast(message, title);
    },
    [logEvent],
  );
};
