import type { ComponentTemplate } from "@editor/types/component-template";
import type { MarketplaceModalRequestType } from "replo-runtime/shared/types";
import type { GetComponentTemplateArgs } from "schemas/generated/componentTemplates";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import { prepareComponentTemplate } from "@editor/components/editor/defaultComponentTemplates";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectComponentDataMapping,
  selectDraftComponentId,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import { findFirstChildComponentWithComponentId } from "@editor/utils/component";
import { trpcUtils } from "@editor/utils/trpc";
import useHandleReplaceComponentWithDesignLibraryReferences from "@hooks/designLibrary/useHandleReplaceComponentWithDesignLibraryReferences";
import useApplyComponentAction from "@hooks/useApplyComponentAction";
import { useGetAttribute } from "@hooks/useGetAttribute";
import { useLogAnalytics } from "@hooks/useLogAnalytics";
import { useModal } from "@hooks/useModal";
import { useAnyStoreProducts } from "@hooks/useStoreProducts";

import { useLocation } from "react-router-dom";

import useSetDraftElement from "./useSetDraftElement";

/**
 * This hook returns a 'onSubmit' function that is useful for
 * when you want to open a modal to create a new page or when
 * you want to add a component of a template to the current
 * page/section/blog.
 */
const useOnSubmitTemplateAction = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const modal = useModal();
  const { state } = useLocation();
  const marketplaceModalRequestType: MarketplaceModalRequestType =
    state?.marketplaceModalRequestType;
  const setDraftElement = useSetDraftElement();
  const getAttribute = useGetAttribute();
  const { products } = useAnyStoreProducts();
  const { setMenuState: setAIMenuState } = useAIStreaming();
  const {
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
  } = useEditorSelector(selectLocaleData);
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;
  const productResolutionDependencies = {
    products,
    currencyCode,
    moneyFormat,
    language,
    templateProduct,
    isEditor: true,
    isShopifyProductsLoading: false,
  };
  const applyComponentAction = useApplyComponentAction();
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const getTemplateById = trpcUtils.componentTemplates.get.fetch;

  const analytics = useLogAnalytics();

  const { handlePasteComponentWithDesignLibraryReferences } =
    useHandleReplaceComponentWithDesignLibraryReferences();

  const onSubmit = async (
    template: ComponentTemplate,
    elementType: ReploElementType,
  ) => {
    setIsLoading(true);
    const initialName = state?.initialName ?? "";
    const getTemplateByIdParams: GetComponentTemplateArgs = {
      templateId: template.id,
    };

    const templateFromService = await getTemplateById(getTemplateByIdParams);
    if (!templateFromService) {
      setIsLoading(false);
      return;
    }
    if (marketplaceModalRequestType !== "browse") {
      analytics("store.componentTemplate.used", {
        componentTemplateId: template.id,
        collectionId: template.collectionId,
        componentTemplateName: template.name,
        componentTemplateType: template.type,
        categoryId: template.categoryId,
        type: marketplaceModalRequestType,
      });
      modal.closeModal({});
      modal.openModal({
        type: "createElementModal",
        props: {
          initialElementType: elementType,
          initialTemplate: templateFromService,
          initialName,
        },
      });
    } else {
      const preparedComponent = prepareComponentTemplate(
        templateFromService,
        null,
        draftElement,
        {
          getAttribute,
          productResolutionDependencies,
          componentDataMapping,
          context: null,
        },
      );
      const [newComponent] = handlePasteComponentWithDesignLibraryReferences(
        [preparedComponent],
        templateFromService.designLibraryMetadata ?? null,
      );

      if (!newComponent) {
        setIsLoading(false);
        return;
      }

      const targetFirstChildComponent =
        draftElement &&
        draftComponentId &&
        findFirstChildComponentWithComponentId(draftElement, draftComponentId);
      applyComponentAction({
        type: "addComponentToComponent",
        componentId: targetFirstChildComponent ?? draftElement?.component?.id,
        elementId: draftElement?.id,
        value: {
          newComponent,
          position: targetFirstChildComponent ? "sibling-after" : "child",
        },
        analyticsExtras: {
          actionType: "create",
          createdBy: "user",
        },
      });
      setDraftElement({
        id: draftElement?.id,
        componentIds: [newComponent.id],
      });

      setAIMenuState("template");
      analytics("store.componentTemplate.used", {
        componentTemplateId: template.id,
        collectionId: template.collectionId,
        componentTemplateName: template.name,
        componentTemplateType: template.type,
        categoryId: template.categoryId,
        type: "add",
      });
      modal.closeModal({});
      setIsLoading(false);
    }
  };

  return { onSubmit, isLoadingSubmitTemplate: isLoading };
};

export default useOnSubmitTemplateAction;
