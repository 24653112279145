import React from "react";

import { docs } from "@editor/utils/docs";

import Button from "@replo/design-system/components/button";
import { BsArrowUpRight } from "react-icons/bs";

type SavedStylesEmptyStateProps = {
  title: string;
  showCta?: boolean;
};

const SavedStylesEmptyState: React.FC<SavedStylesEmptyStateProps> = ({
  title,
  showCta = true,
}) => (
  <div className="flex flex-col text-default bg-menu-item-hover gap-3 justify-center items-center p-10 rounded-lg text-center h-full">
    <div className="typ-header-base">{title}</div>
    <div className="typ-body-small text-muted">
      Styles allow you to set consistent design styles across colors and text.
    </div>
    {showCta && (
      <Button
        target="_blank"
        variant="tertiary"
        to={docs.savedStyles.main}
        size="sm"
        className="text-primary"
      >
        <div className="flex items-center gap-2 typ-body-small font-medium">
          Learn More <BsArrowUpRight />
        </div>
      </Button>
    )}
  </div>
);

export default SavedStylesEmptyState;
