import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import get from "lodash-es/get";

export const getSavedStyleId = (savedStyleDynamicDataValue: string) => {
  return savedStyleDynamicDataValue.split(".")[3];
};

export const getSavedStyleValue = (
  savedStyles: ((SavedTextStyle | SavedColorStyle) & { id: string })[],
  selectedSavedStyleValue: string,
) => {
  const parsedSavedStyleId = getSavedStyleId(selectedSavedStyleValue);
  return savedStyles.find((style) => style.id === parsedSavedStyleId);
};

export const getSavedStyleAttributeValue = (
  savedStyle: SavedTextStyle | SavedColorStyle,
  dynamicDataString: string,
): string | null => {
  const parsedSavedStyle = dynamicDataString
    .replace("{{", "")
    .replace("}}", "");
  // NOTE (Sebas, 2024-12-16): This will return the attribute name we need to
  // get the value of the attribute from the saved style object.
  // e.g "attributes.htmlTag"
  const match = parsedSavedStyle.match(/attributes\.[\w-]+/);

  if (!match) {
    return null;
  }

  return get(savedStyle, match[0]);
};
