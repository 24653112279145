import type { DesignLibrary } from "schemas/generated/designLibrary";

import {
  getSavedStyleAttributeValue,
  getSavedStyleId,
} from "replo-runtime/shared/savedStyles";

export const designLibraryValueRegex = /library\.[^.]+\.styles\.[^.]+/;

export function isDynamicDesignLibraryValue(value: string | null) {
  if (!value) {
    return false;
  }

  return (
    (value.startsWith("{{") || value.startsWith("<{{")) &&
    designLibraryValueRegex.test(value)
  );
}

export function resolveTextWithSavedStyles(
  text: string,
  designLibrary: DesignLibrary | null,
) {
  if (!designLibrary || !isDynamicDesignLibraryValue(text)) {
    return text;
  }
  return text.replace(/{{\s*library\.[^}]+}}/g, (savedStyleValue) => {
    const savedStyleId = getSavedStyleId(savedStyleValue);
    const savedStyleData =
      designLibrary && savedStyleId
        ? designLibrary.savedStyles[savedStyleId]
        : null;

    if (!savedStyleData) {
      return "";
    }

    return getSavedStyleAttributeValue(savedStyleData, savedStyleValue) ?? "";
  });
}
