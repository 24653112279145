import * as React from "react";

import { Skeleton } from "@common/designSystem/SkeletonLoader";

import { Badge } from "@replo/design-system/components/badge";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsDot, BsType } from "react-icons/bs";

type SavedStyleRowProps = {
  name: string;
  value: string;
  type: "text" | "color";
  endEnhancer?: React.ReactNode;
  isLoading?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  isImported?: boolean;
};

const SavedStyleRow: React.FC<SavedStyleRowProps> = ({
  name,
  value,
  type,
  endEnhancer,
  isSelected,
  onClick,
  isLoading = false,
  isImported = false,
}) => {
  if (isLoading) {
    return <Skeleton className="w-full h-8" />;
  }

  const isText = type === "text";
  return (
    <div
      className={twMerge(
        "flex items-center gap-2 p-1 text-xs rounded group/saved-style-row w-full justify-between h-7",
        isSelected && "bg-selected",
        isImported && "bg-hover",
        !isImported && "cursor-pointer hover:bg-hover",
      )}
      onClick={
        !isImported
          ? () => {
              onClick?.();
            }
          : undefined
      }
    >
      <div className="flex items-center gap-2">
        {isText ? (
          <BsType
            size={16}
            className="text-default shrink-0 bg-white border-border border-0.5 rounded p-0.5"
          />
        ) : (
          <Badge backgroundColor={value} type="color" isFilled />
        )}
        <div className="flex items-center gap-1 w-full">
          <div className="inline-grid">
            <span className="truncate">{name}</span>
          </div>
          {type === "text" && (
            <>
              <BsDot
                className="flex justify-center items-center text-subtle text-xs"
                size={6}
              />

              <div className="inline-grid">
                <span className="text-subtle truncate">{value}</span>
              </div>
            </>
          )}
        </div>
      </div>
      {!isImported && (
        <div className="invisible group-hover/saved-style-row:visible shrink-0">
          {endEnhancer}
        </div>
      )}
    </div>
  );
};

export default SavedStyleRow;
