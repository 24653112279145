import type { Locale } from "@editor/reducers/commerce-reducer";
import type {
  ReploComponentIssue,
  ReploSpecificComponentIssue,
} from "@editor/types/component-issues";
import type {
  ComponentData,
  ComponentDataMapping,
  ComponentIdAndTypeWithLevel,
  ContainedComponentData,
} from "replo-runtime/shared/Component";
import type { StoreProduct } from "replo-runtime/shared/types";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type { Action } from "schemas/actions";
import type { Animation } from "schemas/animations";
import type { Component, ReploComponentType } from "schemas/component";
import type { ReploElementType } from "schemas/generated/element";
import type { ProductRef } from "schemas/product";

import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { getRenderData } from "replo-runtime/store/components";
import { getProduct } from "replo-runtime/store/ReploProduct";
import { isValidDefaultSectionSchemaText } from "replo-runtime/store/utils/section-settings";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type Variant = {
  id: string;
  name: string;
};

type ComponentActions = Record<string, Action[]>;

type UnpublishableContext = {
  useSectionSettings: boolean | undefined;
  shopify?: {
    products?: StoreProduct[];
    templateProduct: StoreProduct | null;
    locale: Locale;
  };
};

type GetIssuesForComponentProps =
  | {
      type: "actions";
      componentDataMapping: ComponentDataMapping;
      component: Component | null;
      activeVariant: Variant | null;
    }
  | {
      type: "unpublishable";
      component: Component;
      context: UnpublishableContext;
    }
  | {
      type: "animations";
      animations: Animation[] | null;
    }
  | {
      type: "dynamicData";
      dynamicDataValue: string | undefined;
    }
  | {
      type: "componentMismatch";
      componentDataMapping: ComponentDataMapping;
      componentId: string;
    }
  | {
      type: "nesting";
      componentDataMapping: ComponentDataMapping;
      currentDraggingComponentId: string;
      currentDraggingComponentType: ReploComponentType;
      dragToComponentId: string;
      dragToComponentType: ReploComponentType;
      elementType: ReploElementType;
    }
  | {
      type: "childrenType";
      componentId: string;
      componentType: ReploComponentType;
      componentDataMapping: ComponentDataMapping;
    }
  | {
      type: "product";
      componentType: ReploComponentType;
      context: Context;
    };

const getIssuesForComponent = (
  props: GetIssuesForComponentProps,
): ReploComponentIssue[] => {
  const componentIssues: ReploComponentIssue[] = [];

  exhaustiveSwitch(props)({
    actions: (prop) => {
      if (!prop.component) {
        return null;
      }
      const componentData = prop.componentDataMapping[prop.component.id];
      const componentActions = getActionsFromComponent(prop.component);

      // #region Check for component descendants issues
      const ancestorData = componentData?.ancestorComponentData.find(
        ([ancestorId, ancestorType]) => {
          if (ancestorType === "button") {
            return true;
          }

          // NOTE (Fran 2024-10-14): We want to avoid checking this issue on a direct child of these
          // components.
          // These component types are wrappers of the actual trigger. So, that trigger should be
          // allowed to have interactions but not the descendants.
          return (
            ["tabs__list", "tabsV2__list", "selectionList"].includes(
              ancestorType,
            ) && componentData?.parentId !== ancestorId
          );
        },
      );

      if (ancestorData) {
        const [ancestorId, ancestorType] = ancestorData;

        const childWithInteractionIssue = getChildWithInteractionsIssue(
          componentActions,
          prop.activeVariant,
        );

        if (childWithInteractionIssue) {
          componentIssues.push({
            ...childWithInteractionIssue,
            ancestorId,
            ancestorType,
          });
        }
        // #endregion
      }
      // #region Check for multiple discount codes
      const multipleDiscountCodesIssue = getMultipleDiscountCodesIssue(
        componentActions,
        prop.activeVariant,
      );
      if (multipleDiscountCodesIssue) {
        componentIssues.push(multipleDiscountCodesIssue);
      }
      // #endregion

      // #region Check for actions after redirection
      const afterRedirectionIssue = getAfterRedirectionIssue(
        componentActions,
        prop.activeVariant,
      );
      if (afterRedirectionIssue) {
        componentIssues.push(afterRedirectionIssue);
      }
      // #endregion
    },
    animations: (prop) => {
      if (!prop.animations || prop.animations.length === 0) {
        return null;
      }
      const duplicatedDevicesIssue = getDuplicatedDevicesAnimationIssue(
        prop.animations,
      );
      if (duplicatedDevicesIssue) {
        componentIssues.push(duplicatedDevicesIssue);
      }
    },
    nesting: (prop) => {
      const dragToComponentFromMapping =
        prop.componentDataMapping[prop.dragToComponentId];

      if (!dragToComponentFromMapping) {
        return null;
      }

      // #region Check for parent to child nesting
      const parentToChildNestingIssue = getParentToChildNestingIssue(
        dragToComponentFromMapping,
        prop.currentDraggingComponentId,
        prop.dragToComponentId,
      );
      if (parentToChildNestingIssue) {
        componentIssues.push(parentToChildNestingIssue);
      }
      // #endregion

      const dragToComponentAncestorOrSelfTypes = [
        ...dragToComponentFromMapping.ancestorComponentData.map(
          ([, ancestorType]) => ancestorType,
        ),
        prop.dragToComponentType,
      ];
      const draggingComponentRenderData = getRenderData(
        prop.currentDraggingComponentType,
      );

      // #region Check for allowed children
      const allowedChildrenIssue = getAllowedChildrenIssue({
        componentId: prop.currentDraggingComponentId,
        parentComponentType: prop.dragToComponentType,
        componentDataMapping: prop.componentDataMapping,
      });

      if (allowedChildrenIssue) {
        componentIssues.push(allowedChildrenIssue);
      }
      // #endregion

      // #region Check for ancestor allow
      const specificAncestorAllow = draggingComponentRenderData?.ancestorAllow;
      if (specificAncestorAllow) {
        const isNestingAllowed = dragToComponentAncestorOrSelfTypes.some(
          (ancestorType) =>
            specificAncestorAllow.ancestorTypes?.includes(ancestorType),
        );

        if (!isNestingAllowed) {
          componentIssues.push({
            type: "nesting.ancestorNotAllow",
            message: specificAncestorAllow.message,
          });
        }

        // NOTE (Sebas, 2024-05-21): If the config specifies that the component can
        // only be directly nested inside the ancestor, we need to check if the
        // last ancestor or self is one allowed by the component.
        if (specificAncestorAllow.directChildOnly) {
          const lastAncestorOrSelfType =
            dragToComponentAncestorOrSelfTypes.at(-1);

          if (
            lastAncestorOrSelfType &&
            !specificAncestorAllow.ancestorTypes?.includes(
              lastAncestorOrSelfType,
            )
          ) {
            componentIssues.push({
              type: "nesting.ancestorDisallow",
              message: specificAncestorAllow.message,
            });
          }
        }
      }
      // #endregion

      // #region Check for ancestor disallow
      const specificAncestorDisallowList =
        draggingComponentRenderData?.ancestorDisallowList;
      if (specificAncestorDisallowList) {
        // NOTE (Gabe 2023-06-23): We have multiple configs to check, so we iterate
        // through them.
        for (const ancestorConfig of specificAncestorDisallowList) {
          // NOTE (Gabe 2023-06-23): If elementTypes are specified we should first
          // check these and return terminateSearch: true to indicate this component
          // isn't allowed anywhere in this element.
          if (
            ancestorConfig.elementTypes &&
            ancestorConfig.elementTypes.includes(prop.elementType)
          ) {
            componentIssues.push({
              type: "nesting.ancestorDisallow",
              message: ancestorConfig.message,
              terminatedSearch: true,
            });
            break;
          }

          // NOTE (Sebas, 2024-05-21): If the config specifies that the component can
          // only be directly nested inside the ancestor, we need to check if the
          // current dragging component is a direct child of the ancestor.
          if (ancestorConfig.directChildOnly) {
            const lastAncestorOrSelfType =
              dragToComponentAncestorOrSelfTypes.at(-1);

            if (
              lastAncestorOrSelfType &&
              ancestorConfig.ancestorTypes?.includes(lastAncestorOrSelfType)
            ) {
              componentIssues.push({
                type: "nesting.ancestorDisallow",
                message: ancestorConfig.message,
              });
            }
            break;
          }

          const isNestingDisallowed = dragToComponentAncestorOrSelfTypes.some(
            (ancestorType) =>
              ancestorConfig.ancestorTypes?.includes(ancestorType),
          );
          if (isNestingDisallowed) {
            componentIssues.push({
              type: "nesting.ancestorDisallow",
              message: ancestorConfig.message,
            });
            break;
          }
        }
      }

      const currentDraggingComponentFromMapping =
        prop.componentDataMapping[prop.currentDraggingComponentId];

      // NOTE (Fran 2024-05-15): We should check if any of the component's child is not allowed to
      // be nested in the possible target.
      if (currentDraggingComponentFromMapping) {
        for (const [
          ,
          containedComponentType,
        ] of currentDraggingComponentFromMapping.containedComponentData) {
          const containedComponentRenderData = getRenderData(
            containedComponentType,
          );

          // NOTE (Fran 2024-05-15): We need to check if the contained component type is explicity
          // alowed by the current dragging component type. If so we should not check if it is
          // disallowed by the target component.
          const specificAncestorAllow =
            containedComponentRenderData?.ancestorAllow;
          const isNestingAllowed = specificAncestorAllow
            ? specificAncestorAllow.ancestorTypes?.includes(
                prop.currentDraggingComponentType,
              )
            : null;

          if (
            isNestingAllowed !== true &&
            containedComponentRenderData?.ancestorDisallowList
          ) {
            for (const ancestorConfig of containedComponentRenderData.ancestorDisallowList) {
              const isNestingDisallowed =
                dragToComponentAncestorOrSelfTypes.some((ancestorType) =>
                  ancestorConfig.ancestorTypes?.includes(ancestorType),
                );
              if (isNestingDisallowed) {
                componentIssues.push({
                  type: "nesting.ancestorDisallow",
                  message: ancestorConfig.message,
                });
                break;
              }
            }
          }
        }
      }
      // #endregion
    },
    unpublishable: ({ component, context }) => {
      // Check for notActiveProducts
      if (
        component.type === "product" &&
        (component?.props?._product as ProductRef)?.productId &&
        context.shopify?.products
      ) {
        const product = getProduct(
          component.props._product ?? null,
          getCurrentComponentContext(component.id, 0) || null,
          {
            productMetafieldValues: {},
            variantMetafieldValues: {},
            products: context.shopify.products,
            currencyCode: context.shopify.locale.activeCurrency,
            moneyFormat: context.shopify.locale.moneyFormat,
            language: context.shopify.locale.activeLanguage,
            templateProduct: context.shopify.templateProduct,
            isEditor: true,
            isShopifyProductsLoading: false,
          },
        );
        if (product && product?.status !== "ACTIVE") {
          componentIssues.push({
            type: "unpublishable.notActiveProducts",
            message: "Some products are not active in Shopify",
          });
        }
      }

      // Check for invalidSectionSettingsText
      if (context.useSectionSettings) {
        const invalidSectionSettingValueIssue =
          getInvalidSectionSettingValueIssue(component);
        if (invalidSectionSettingValueIssue) {
          componentIssues.push(invalidSectionSettingValueIssue);
        }
      }
    },
    componentMismatch: ({ componentDataMapping, componentId }) => {
      const componentData = componentDataMapping[componentId];
      const componentType = componentData?.type;
      if (componentType === "tabsV2__block") {
        const tabV2ListComponents =
          componentData?.containedComponentData.filter(
            ([, type]) => type === "tabsV2__list",
          ) ?? [];

        // NOTE (Sebas, 2024-08-14): We should check for a mismatch between the nearest tabs list
        // and tabs content component.
        const tabListComponent =
          getComponentWithLowestLevel(tabV2ListComponents);

        const tabListData = tabListComponent
          ? componentDataMapping[tabListComponent[0]]
          : null;

        const tabListChildren = tabListData?.containedComponentData.filter(
          ([, , level]) => level === 1,
        );

        const tabV2PanelsContentComponents =
          componentData?.containedComponentData.filter(
            ([, type]) => type === "tabsV2__panelsContent",
          ) ?? [];

        const tabPanelsComponent = getComponentWithLowestLevel(
          tabV2PanelsContentComponents,
        );
        const tabsContentData = tabPanelsComponent
          ? componentDataMapping[tabPanelsComponent[0]]
          : null;

        const tabsContentChildren =
          tabsContentData?.containedComponentData.filter(
            ([, , level]) => level === 1,
          );

        if (tabListChildren?.length !== tabsContentChildren?.length) {
          componentIssues.push({
            type: "componentMismatch.tabsListContentMismatch",
            message:
              "The number of tabs in the menu does not match the number of tabs in the content.",
          });
        }
      }
    },
    product: ({ context, componentType }) => {
      const optionSelectTypes = ["optionSelect", "optionSelectDropdown"];
      if (
        optionSelectTypes.includes(componentType) &&
        !hasMoreThanOneValue(context.attributes?._options?.[0]?.values)
      ) {
        componentIssues.push({
          type: "product.noOptionsAvailable",
          message: "No options available for the selected product.",
        });
      }

      const variantSelectTypes = ["variantSelect", "variantSelectDropdown"];
      if (
        variantSelectTypes.includes(componentType) &&
        !hasMoreThanOneValue(context.attributes?._variants)
      ) {
        componentIssues.push({
          type: "product.noVariantsAvailable",
          message: "No variants available for the selected product.",
        });
      }

      const sellingPlanSelectTypes = [
        "sellingPlanSelect",
        "sellingPlanSelectDropdown",
      ];
      if (
        sellingPlanSelectTypes.includes(componentType) &&
        context.attributes?._sellingPlans &&
        context.attributes._sellingPlans.length === 0
      ) {
        componentIssues.push({
          type: "product.noSellingPlansAvailable",
          message: "No selling plans available for the selected product.",
        });
      }
    },
    dynamicData: ({ dynamicDataValue }) => {
      // NOTE (Sebas, 2024-05-16): Inside evaluateVariable there is a function
      // called compileHandlebars that in case of an error it returns an empty
      // string.
      if (!dynamicDataValue || dynamicDataValue === "") {
        componentIssues.push({
          type: "dynamicData.unresolvedDynamicData",
          message:
            "This component has dynamic data which may not be available in all cases.",
        });
      }
    },
    childrenType: ({ componentId, componentType, componentDataMapping }) => {
      const issue = getAllowedChildrenIssue({
        componentId,
        componentType,
        componentDataMapping,
      });

      if (issue) {
        componentIssues.push({
          type: "childrenType.notAllowed",
          message: issue.message,
        });
      }
    },
  });

  return componentIssues;
};

// #region Specific issues functions

const getMultipleDiscountCodesIssue = (
  actions: ComponentActions,
  activeVariant: Variant | null,
): ReploSpecificComponentIssue<"actions.multipleDiscountCodes"> | null => {
  const actionWithIssuesIdentifiers: string[] = [];
  const activeVariantId =
    activeVariant?.name === "default"
      ? "default"
      : activeVariant?.id ?? "default";

  const filteredActions =
    actions?.[activeVariantId]?.filter(
      (action) => action.type === "applyDiscountCode",
    ) ?? [];

  if (filteredActions.length > 1) {
    for (const [index, action] of filteredActions.entries()) {
      if (index > 0) {
        actionWithIssuesIdentifiers.push(action.id);
      }
    }
  }

  if (actionWithIssuesIdentifiers.length > 0) {
    return {
      type: "actions.multipleDiscountCodes",
      actionWithIssuesIdentifiers,
      message:
        "Only one discount code can be used per component. The first one listed will be applied.",
    };
  }
  return null;
};

const getAfterRedirectionIssue = (
  actions: ComponentActions,
  activeVariant: Variant | null,
): ReploSpecificComponentIssue<"actions.actionAfterRedirect"> | null => {
  const actionWithIssuesIdentifiers: string[] = [];
  const activeVariantId =
    activeVariant?.name === "default"
      ? "default"
      : activeVariant?.id ?? "default";
  const activeComponentActions = actions[activeVariantId] ?? [];
  let redirectionLabel = null;
  if (activeComponentActions.length > 1) {
    let isActionAfterRedirect = null;
    for (const action of activeComponentActions) {
      if (isActionAfterRedirect) {
        actionWithIssuesIdentifiers.push(action.id);
      }
      if (isRedirectionAction(action) && !isActionAfterRedirect) {
        redirectionLabel = getRedirectionLabel(action);
        isActionAfterRedirect = true;
      }
    }
  }

  if (actionWithIssuesIdentifiers.length > 0) {
    return {
      type: "actions.actionAfterRedirect",
      actionWithIssuesIdentifiers,
      message: `Anything below the ${redirectionLabel} action will not be triggered.`,
    };
  }
  return null;
};

const getChildWithInteractionsIssue = (
  actions: ComponentActions,
  activeVariant: Variant | null,
): Omit<
  ReploSpecificComponentIssue<"actions.childWithInteractions">,
  "ancestorId" | "ancestorType"
> | null => {
  const actionWithIssuesIdentifiers: string[] = [];
  const activeVariantId =
    activeVariant?.name === "default"
      ? "default"
      : activeVariant?.id ?? "default";
  const activeComponentActions = actions[activeVariantId] ?? [];

  if (activeComponentActions.length > 0) {
    actionWithIssuesIdentifiers.push(
      ...activeComponentActions.map((action) => action.id),
    );

    return {
      type: "actions.childWithInteractions",
      actionWithIssuesIdentifiers,
      message: `${
        actionWithIssuesIdentifiers.length > 1
          ? "These interactions are inside"
          : "This interaction is inside"
      } a component which already has its own interactions, which might cause unintended effects.`,
    };
  }

  return null;
};

const getInvalidSectionSettingValueIssue = (
  component: Component,
): ReploComponentIssue | null => {
  if (
    component.props.text &&
    !isValidDefaultSectionSchemaText(component.props.text)
  ) {
    return {
      type: "unpublishable.invalidSectionSettingsText",
      message: "Text includes characters not supported by Shopify",
    };
  }
  return null;
};

const getDuplicatedDevicesAnimationIssue = (
  animations: Animation[],
): ReploSpecificComponentIssue<"animations.animationWithRepeatedDevices"> | null => {
  const coveredDevices = new Map<string, string>();
  const invalidIdsWithMediaSizes: ReploSpecificComponentIssue<"animations.animationWithRepeatedDevices">["animationWithIssuesIdentifiers"] =
    {};

  for (const [index, animation] of animations.entries()) {
    if (index === 0) {
      // NOTE (Sebas, 2024-04-29): This is the first animation, so we don't need to check for duplicates,
      // we just need to add all the devices to the covered set.
      for (const device of animation.devices) {
        coveredDevices.set(device, animation.id);
      }
      continue;
    }

    const overlaps = animation.devices.filter((device) =>
      coveredDevices.has(device),
    );
    if (overlaps.length > 0) {
      invalidIdsWithMediaSizes[animation.id] = overlaps;
    }

    for (const device of animation.devices) {
      coveredDevices.set(device, animation.id);
    }
  }

  if (Object.keys(invalidIdsWithMediaSizes).length > 0) {
    return {
      type: "animations.animationWithRepeatedDevices",
      animationWithIssuesIdentifiers: invalidIdsWithMediaSizes,
      message: "You can only apply one animation per trigger per device.",
    };
  }

  return null;
};

// #endregion

// #region Helper functions

export const hasActionIssues = (
  id: string,
  actionIssues: ReploComponentIssue[],
) =>
  actionIssues.some(
    (componentIssue) =>
      "actionWithIssuesIdentifiers" in componentIssue &&
      componentIssue.actionWithIssuesIdentifiers.includes(id),
  );

export const hasAnimationIssues = (
  id: string,
  animationIssues: ReploComponentIssue[],
) =>
  animationIssues.some(
    (componentIssue) =>
      "animationWithIssuesIdentifiers" in componentIssue &&
      Object.keys(componentIssue.animationWithIssuesIdentifiers).includes(id),
  );

export const hasParticularTypeActionIssue = (
  actionType: ReploComponentIssue["type"],
  actionIssues: ReploComponentIssue[],
) => actionIssues.some((actionIssue) => actionIssue.type === actionType);

const isRedirectionAction = (action: Action): boolean => {
  if (action.type === "redirect" || action.type === "redirectToProductPage") {
    return true;
  }
  if (action.type === "addProductVariantToCart") {
    return (
      action.value?.redirectToCart || action.value?.redirectToCheckout || false
    );
  }
  return false;
};

const getRedirectionLabel = (action: Action): string | null => {
  switch (action.type) {
    case "redirect":
      return "redirect to";
    case "redirectToProductPage":
      return "redirect to product page";
    case "addProductVariantToCart":
      if (action.value?.redirectToCart) {
        return "redirect to cart";
      }
      if (action.value?.redirectToCheckout) {
        return "redirect to checkout";
      }
      return null;
    default:
      return null;
  }
};

// NOTE (Sebas, 2024-04-12): This function only returns actions from the `onClick` prop
// of the component. We don't return the actions from `onHover` because we don't have any
// specific rule for that at the moment.
const getActionsFromComponent = (component: Component): ComponentActions => {
  const actions = component.props.onClick
    ? { default: component.props.onClick }
    : {};

  const actionsFromVariantOverrides = component.variantOverrides
    ? Object.entries(component.variantOverrides).reduce(
        (actions, [variantId, variant]) => {
          const onClick =
            variant.componentOverrides?.[component.id]?.props?.onClick;
          if (onClick) {
            actions[variantId] = onClick;
          }
          return actions;
        },
        {} as ComponentActions,
      )
    : {};

  return Object.assign(actions, actionsFromVariantOverrides);
};

type AllowedChildrenIssueProps = {
  componentId: string;
  componentDataMapping: ComponentDataMapping;
} & (
  | { parentComponentType: ReploComponentType }
  | {
      componentType: ReploComponentType;
    }
);

// NOTE (Sebas, 2024-08-06): This function checks if the component allows the specified
// children types based on the configuration. Depending on the context, it can take
// either a parentComponentType or a componentType. For drag-and-drop checks, we use
// parentComponentType to see if the component can be nested inside the parent.
// When checking componentType, it's to show any issues on the tree component.
const getAllowedChildrenIssue = (
  props: AllowedChildrenIssueProps,
): ReploComponentIssue | null => {
  const { componentId, componentDataMapping } = props;
  const isCheckingParent = "parentComponentType" in props;
  const componentType = isCheckingParent
    ? props.parentComponentType
    : props.componentType;

  const renderData = getRenderData(componentType);
  const childrenAllowData = renderData?.childrenAllow;
  if (childrenAllowData) {
    let childTypesToCheck: ReploComponentType[] | null = null;
    const componentData = componentDataMapping[componentId];

    if (componentData) {
      if (childrenAllowData.directChildOnly) {
        if (isCheckingParent) {
          childTypesToCheck = [componentData.type];
        } else {
          childTypesToCheck = componentData.containedComponentData
            .filter(([, , level]) => level === 1)
            .map(([, type]) => type);
        }
      } else {
        childTypesToCheck = componentData.containedComponentData.map(
          ([, type]) => type,
        );
        if (isCheckingParent) {
          childTypesToCheck.push(componentData.type);
        }
      }

      // NOTE (Sebas, 2024-08-05): If any of the children types are not allowed by the component
      // parent we should return an issue.
      const hasNotAllowedChildrenType = childTypesToCheck?.some(
        (childType) => !childrenAllowData.childTypes?.includes(childType),
      );

      if (hasNotAllowedChildrenType) {
        return {
          type: "nesting.childNotAllowed",
          message: childrenAllowData.message,
        };
      }
    }
  }
  return null;
};

const getParentToChildNestingIssue = (
  dragToComponentFromMapping: ComponentData,
  currentDraggingComponentId: string,
  dragToComponentId: string,
): ReploSpecificComponentIssue<"nesting.moveParentToChild"> | null => {
  const isCurrentDraggingComponentAncestor =
    dragToComponentFromMapping.ancestorComponentData.some(
      ([ancestorComponentId]) =>
        ancestorComponentId === currentDraggingComponentId,
    );
  if (
    isCurrentDraggingComponentAncestor ||
    currentDraggingComponentId === dragToComponentId
  ) {
    return {
      type: "nesting.moveParentToChild",
      message: "Component cannot be moved into itself",
    };
  }
  return null;
};

const getComponentWithLowestLevel = (components: ContainedComponentData) => {
  return components.reduce(
    (component, componentData) => {
      const level = componentData[2];
      if (!component || level < component[2]) {
        component = componentData;
      }
      return component;
    },
    null as ComponentIdAndTypeWithLevel | null,
  );
};

const hasMoreThanOneValue = (list?: Array<any>) => {
  return (list ?? []).length > 1;
};

export default getIssuesForComponent;
