import * as React from "react";

import Modal from "@common/designSystem/Modal";
import { routes } from "@editor/utils/router";

import Button from "@replo/design-system/components/button";

export const EditorNoAccessToProjectScreen: React.FC = () => {
  return (
    <div id="app" className="andytownApp h-screen w-full bg-slate-200">
      <Modal isOpen className="w-96" closesOnOverlayClick={false}>
        <div className="flex flex-col items-start rounded-lg bg-white p-3 max-w-[450px] text-default">
          <p className="text-xl font-semibold mb-6">
            Request access to this project
          </p>
          <div className="mb-6">
            You don&apos;t have access to this Replo project.
          </div>
          <div className="mb-6">
            Please request access from the project admin. You can view this
            project once your request is approved.
          </div>
          <Button
            variant="primary"
            size="lg"
            isFullWidth
            className="mt-4"
            to={routes.dashboard}
          >
            Back to Dashboard
          </Button>
        </div>
      </Modal>
    </div>
  );
};
