import * as React from "react";

import { useExperimentApi } from "@components/projectDashboard/experiments/common";
import { Loader } from "@editor/components/common/Loader";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { routes } from "@editor/utils/router";

import { AllExperimentsTable } from "@/features/experiments/AllExperimentsTable";
import { CreateExperimentButton } from "@/features/experiments/components/CreateExperimentButton";
import { HelpDocumentationIcon } from "@/features/experiments/components/HelpDocumentationIcon";
import { ExperimentsLayout } from "@/features/experiments/Layout";
import ExperimentsSetup from "@/features/experiments/Setup";
import IconButton from "@replo/design-system/components/button/IconButton";
import { BsGear } from "react-icons/bs";
import { generatePath, useNavigate } from "react-router-dom";

const ExperimentActions: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const navigate = useNavigate();
  return (
    <div className="flex flex-row gap-2">
      <HelpDocumentationIcon />
      <IconButton
        variant="secondary"
        size="base"
        icon={<BsGear size={16} className="text-slate-800" />}
        onClick={() => {
          navigate(
            generatePath(routes.workspace.experiments.settings, {
              workspaceId,
            }),
          );
        }}
      ></IconButton>
      <CreateExperimentButton />
    </div>
  );
};

const Experiments: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId() ?? undefined;

  const {
    list: { data, isFetching: isFetchingExperiments },
    links: { data: links, isFetching: isFetchingLinks },
  } = useExperimentApi({ workspaceId, projectId: null });

  if (isFetchingExperiments || isFetchingLinks || !links || !data) {
    return <Loader />;
  }

  const experiments = data.experiments;
  const hasExperiments = experiments.length > 0;

  return (
    <ExperimentsLayout headerActions={<ExperimentActions />}>
      {hasExperiments ? (
        <AllExperimentsTable experiments={experiments} links={links} />
      ) : (
        <ExperimentsSetup />
      )}
    </ExperimentsLayout>
  );
};

export default Experiments;
