import type { ComboboxFooterProps } from "../types";

import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

import { useCombobox } from "../hooks/useCombobox";

export function ComboboxFooter({ children, className }: ComboboxFooterProps) {
  const { size } = useCombobox();

  if (!children) {
    return null;
  }

  return (
    <div
      className={twMerge(
        "border-t border-slate-200",
        size === "sm" ? "text-xs p-2" : "text-sm p-3",
        className,
      )}
    >
      {children}
    </div>
  );
}
