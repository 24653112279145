import type { DynamicCommandItem } from "@editor/hooks/useDynamicCommandMenuItems";
import type {
  CommandGroupWithActions,
  CommandItem,
} from "@editor/utils/commands";
import type {
  HotkeyIndicatorCharacter,
  HotkeyMetaKey,
} from "@editor/utils/hotkeys";

import * as React from "react";

import {
  allElementTypes,
  elementTypeToEditorData,
} from "@editor/components/editor/element";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useDynamicCommandMenuItems } from "@editor/hooks/useDynamicCommandMenuItems";
import { useGlobalEditorActions } from "@editor/hooks/useGlobalEditorActions";
import { useModal } from "@editor/hooks/useModal";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  disableFeatureFlag,
  enableFeatureFlag,
  isFeatureEnabled,
} from "@editor/infra/featureFlags";
import {
  selectCommandMenuItems,
  selectPages,
  toggleDebugPanelVisibility,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import {
  commandItemHasChildren,
  commandItemHasGlobalCommand,
} from "@editor/utils/commands";
import { HotkeyMetaKeyToLabel } from "@editor/utils/hotkeys";
import { isMacintosh } from "@editor/utils/platform";
import { generateEditorPathname, routes } from "@editor/utils/router";
import { getEmptyTemplate } from "@editor/utils/template";

import twMerge from "@replo/design-system/utils/twMerge";
import { Command } from "cmdk";
import { MdAdd, MdFlag } from "react-icons/md";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { featureFlags } from "replo-utils/lib/featureFlags";
import { exhaustiveSwitch, hasOwnProperty } from "replo-utils/lib/misc";
import { CATEGORIES_IDS } from "schemas/componentTemplates";

import { useIsDebugMode } from "./editor/debug/useIsDebugMode";

/**
 * State that can be pushed onto the command menu stack. Default means the default
 * command menu items, depending on editor context. Custom means we're viewing a child
 * command group with specific items.
 */
type CommandMenuState =
  | "default"
  | { type: "custom"; commandGroup: CommandGroupWithActions };

const isCustomCommandMenuState = (
  state: CommandMenuState,
): state is { type: "custom"; commandGroup: CommandGroupWithActions } =>
  hasOwnProperty(state, "type") && state.type === "custom";

export function CommandMenu() {
  const location = useLocation();
  const defaultCommandMenuItems = useCommandMenuItems();

  // Note (Noah, 2025-01-22): We use a stack here since there can be multiple
  // levels of nested child command groups. Backspace pops off the stack, and the
  // last item of the stack is the "current" one. Empty stack means the cmd-k modal
  // is not shown at all
  const [commandMenuStack, setCommandMenuStack] = React.useState<
    CommandMenuState[]
  >([]);
  const currentCommandMenuState = commandMenuStack[commandMenuStack.length - 1];

  // Note (Noah, 2025-01-22): Listener for cmd-k, to open the menu from anywhere
  React.useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setCommandMenuStack((prevState) =>
          prevState.length === 0 ? ["default"] : [],
        );
      }
    }

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  // Note (Noah, 2025-01-22): If the location ever changes, just close the menu
  React.useEffect(() => {
    if (location) {
      setCommandMenuStack([]);
    }
  }, [location]);

  const [searchTerm, setSearchTerm] = React.useState("");

  const commandMenuItems =
    currentCommandMenuState && isCustomCommandMenuState(currentCommandMenuState)
      ? [currentCommandMenuState.commandGroup]
      : defaultCommandMenuItems;

  const globalActions = useGlobalEditorActions();

  const handleItemSelect = (item: CommandItem, key: string) => {
    if (commandItemHasChildren(item)) {
      setCommandMenuStack((prevStack) => {
        const newStack = [...prevStack];
        newStack.push({
          type: "custom",
          commandGroup: {
            id: key,
            heading: item.label,
            items: item.children,
          },
        });
        return newStack;
      });
      setSearchTerm("");
    } else if (commandItemHasGlobalCommand(item)) {
      // TODO (Noah, 2025-01-19): globalActions has all the actions including
      // paste actions, which are never actually indexed by command. We should fix
      // this to access a mapping with ONLY action commands
      (globalActions[item.command] as () => void)();
      setCommandMenuStack([]);
      setSearchTerm("");
    } else {
      item.onSelect();
      setCommandMenuStack([]);
      setSearchTerm("");
    }
  };

  return (
    <Command.Dialog
      open={Boolean(currentCommandMenuState)}
      onOpenChange={(isOpen) => setCommandMenuStack(isOpen ? ["default"] : [])}
      label="Replo Command Menu"
      onKeyDown={(e) => {
        // Backspace goes to previous page when search is empty
        if (e.key === "Escape" || (e.key === "Backspace" && !searchTerm)) {
          e.preventDefault();
          if (
            currentCommandMenuState &&
            isCustomCommandMenuState(currentCommandMenuState)
          ) {
            // Note (Noah, 2025-01-22): Go back one stack frame by popping the last item
            // off the stack
            setCommandMenuStack((prevStack) => prevStack.slice(0, -1));
          }
        }

        if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Enter") {
          e.stopPropagation();
        }
      }}
    >
      <Command.Input
        value={searchTerm}
        onValueChange={setSearchTerm}
        placeholder="Type a command or search"
      />

      <Command.List>
        <Command.Empty>No results found.</Command.Empty>
        {commandMenuItems.map((group) => (
          <Command.Group key={group.heading} heading={group.heading}>
            {Object.entries(group.items)
              .filter(([, item]) => !item.isDisabled)
              .map(([key, item]) => {
                return (
                  <>
                    <SingleCommandListItem
                      key={key}
                      item={item}
                      onSelect={() => handleItemSelect(item, key)}
                    />
                    {/* Note (Noah, 2025-01-19): If we're an item that has children,
                    also add in our children as items so that users can directly find
                    the children without having click us, if they want. This is how
                    Linear's cmd-k works.*/}
                    {commandItemHasChildren(item) &&
                      Object.entries(item.children).map(([key, child]) => (
                        <SingleCommandListItem
                          key={`child-${key}`}
                          item={child}
                          onSelect={() => handleItemSelect(child, key)}
                        />
                      ))}
                  </>
                );
              })}
          </Command.Group>
        ))}
      </Command.List>
    </Command.Dialog>
  );
}

/**
 * Renders a single <Command.Item> for the given item. If the item has children,
 * this renders only the parent (children should be rendered separately).
 */
function SingleCommandListItem({
  item,
  onSelect,
}: {
  item: CommandItem;
  onSelect: () => void;
}) {
  return (
    <Command.Item onSelect={onSelect}>
      <div className="icon">
        <CommandIcon item={item} />
      </div>
      <div className="label">{item.label}</div>
      {item.hotkey && (
        <div className="ml-auto text-slate-400">
          {getHotkeyLabel(item.hotkey)}
        </div>
      )}
    </Command.Item>
  );
}

function CommandIcon({ item }: { item: CommandItem }) {
  if (item.icon) {
    return exhaustiveSwitch(item.icon)({
      iconComponent: (icon) => <icon.component size={16} />,
      publishedStatus: ({ isPublished }) => (
        <div className="w-[16px] h-[16px] flex items-center justify-center">
          <div
            className={twMerge(
              "w-[8px] h-[8px] rounded-full",
              isPublished ? "bg-green-500" : "bg-slate-400",
            )}
          />
        </div>
      ),
      create: () => <MdAdd size={16} />,
      custom: ({ content }) => {
        return <>{content}</>;
      },
    });
  }

  return <div className="w-[16px] h-[16px] bg-slate-200 rounded-full" />;
}

function useDebugCommandItems(): CommandGroupWithActions["items"] {
  const sortedFlags = [...featureFlags].sort((a, b) => a.localeCompare(b));
  const dispatch = useEditorDispatch();
  return {
    featureFlags: {
      label: "Feature Flags...",
      icon: { type: "iconComponent", component: MdFlag },
      type: "children",
      children: Object.fromEntries(
        sortedFlags.map((flag) => [
          flag,
          {
            label: `Set ${flag} to ${isFeatureEnabled(flag) ? "OFF" : "ON"} (Currently ${isFeatureEnabled(flag) ? "ON" : "OFF"})`,
            icon: {
              type: "custom",
              content: (
                <div className="w-[16px] h-[16px] flex items-center justify-center">
                  <div
                    className={twMerge(
                      "w-[8px] h-[8px] rounded-full",
                      isFeatureEnabled(flag) ? "bg-green-500" : "bg-slate-400",
                    )}
                  />
                </div>
              ),
            },
            onSelect: () => {
              if (isFeatureEnabled(flag)) {
                disableFeatureFlag(flag);
              } else {
                enableFeatureFlag(flag);
              }
            },
          },
        ]),
      ),
    },
    toggleDebugPanel: {
      label: "Toggle Debug Panel",
      icon: { type: "custom", content: <div>🐛</div> },
      onSelect: () => {
        dispatch(toggleDebugPanelVisibility());
      },
    },
  };
}

function useCommandMenuItems(): CommandGroupWithActions[] {
  const commandMenuItems = useEditorSelector(selectCommandMenuItems);
  const createMenuItems = useCreateMenuItems();
  const pagesMenuItems = usePagesMenuItems();
  const dynamicMenuItems = useDynamicCommandMenuItems();
  const debugCommandItems = useDebugCommandItems();

  const isDebugMode = useIsDebugMode();

  const menuItems = commandMenuItems
    .map((group) => {
      return {
        ...group,
        items: appendDynamicItemsToGroup(
          group.items,
          dynamicMenuItems,
          group.id,
        ),
      };
    })
    .concat([
      {
        id: "create",
        heading: "Create",
        items: appendDynamicItemsToGroup(
          createMenuItems,
          dynamicMenuItems,
          "create",
        ),
      },
      {
        id: "navigate",
        heading: "Navigate to...",
        items: appendDynamicItemsToGroup(
          pagesMenuItems,
          dynamicMenuItems,
          "navigate",
        ),
      },
    ]);

  if (isDebugMode) {
    menuItems.push({
      id: "debug",
      heading: "Debug",
      items: debugCommandItems,
    });
  }

  return menuItems;
}

function useCreateMenuItems(): CommandGroupWithActions["items"] {
  const modal = useModal();
  const navigate = useNavigate();
  const params = useParams();

  const items: CommandGroupWithActions["items"] = {};

  for (const type of allElementTypes) {
    items[`${type}-blank`] = {
      label: `Create blank ${elementTypeToEditorData[type].singularDisplayName.toLowerCase()}`,
      icon: { type: "create" },
      onSelect: () => {
        modal.openModal({
          type: "createElementModal",
          props: {
            initialElementType: type,
            initialTemplate: getEmptyTemplate(type),
            initialName: undefined,
          },
        });
      },
    };
    items[`${type}-template`] = {
      label: `Create ${elementTypeToEditorData[type].singularDisplayName.toLowerCase()} from template`,
      icon: { type: "create" },
      onSelect: () => {
        let categoryId: string | undefined;
        let generatedPath: string;
        if (type === "shopifyArticle") {
          categoryId = CATEGORIES_IDS.blogPostPage;
          generatedPath = generateEditorPathname(routes.marketplaceModal, {
            projectId: params.projectId ?? "",
            elementId: params.elementId,
          });
        } else if (type === "shopifyProductTemplate") {
          categoryId = CATEGORIES_IDS.productPageTemplates;
          generatedPath = generateEditorPathname(routes.marketplaceModal, {
            projectId: params.projectId ?? "",
            elementId: params.elementId,
          });
        } else {
          generatedPath = generateEditorPathname(routes.marketplaceModal, {
            projectId: params.projectId ?? "",
            elementId: params.elementId,
          });
        }
        navigate(generatedPath, {
          state: {
            elementType: type,
            initialName: undefined,
            marketplaceModalRequestType: "create",
            // NOTE (Fran 2024-10-16): When we create a new element using a template, we need to pass
            // the pre-selected filters so the user can find a template that matches the element type.
            // This is only needed if the user creates a new Blog Post or Product Page. For the
            // other element types, we can have no selected filters.
            selectedFilters: {
              category: categoryId ? [categoryId] : [],
              badge: [],
              industry: [],
            },
          },
        });
      },
    };
  }

  return items;
}

function usePagesMenuItems(): CommandGroupWithActions["items"] {
  const navigate = useNavigate();
  const { project } = useCurrentProjectContext();
  const setDraftElement = useSetDraftElement();
  const projectId = project?.id;
  const pages = useEditorSelector(selectPages);
  return Object.fromEntries(
    pages.map((page) => {
      return [
        page.id,
        {
          label: page.name,
          icon: {
            type: "publishedStatus",
            isPublished: page.isPublished ?? false,
          },
          onSelect: () => {
            setDraftElement({
              componentIds: [],
            });
            navigate(
              generatePath(routes.editor.element, {
                projectId,
                elementId: page.id,
              }),
            );
          },
        },
      ];
    }),
  );
}

function appendDynamicItemsToGroup(
  items: CommandGroupWithActions["items"],
  dynamicItems: Record<string, DynamicCommandItem>,
  groupId: string,
): CommandGroupWithActions["items"] {
  return Object.assign(
    items,
    Object.fromEntries(
      Object.entries(dynamicItems).filter(([, item]) => item.group === groupId),
    ),
  );
}

const getHotkeyLabel = (hotkey: HotkeyIndicatorCharacter[]) => {
  return hotkey.map((character) => {
    const metaKeyLabel = HotkeyMetaKeyToLabel[character as HotkeyMetaKey];
    if (!metaKeyLabel) {
      return character.toUpperCase();
    }

    if (typeof metaKeyLabel === "string") {
      return metaKeyLabel;
    }

    return isMacintosh() ? metaKeyLabel.mac : metaKeyLabel.windows;
  });
};
