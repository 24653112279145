import type { ComboboxTriggerProps } from "../../types";

import * as React from "react";

import Button from "@replo/design-system/components/button";
import { PopoverTrigger } from "@replo/design-system/components/shadcn/core/popover";
import twMerge from "@replo/design-system/utils/twMerge";

import { useHoveredLabel } from "../../ComboboxHoverStore";
import { useCombobox } from "../../hooks/useCombobox";

const BoxLabel: React.FC<{
  placeholderPrefix: ComboboxTriggerProps["placeholderPrefix"];
  staticPlaceholder: ComboboxTriggerProps["staticPlaceholder"];
  placeholder: ComboboxTriggerProps["placeholder"];
}> = ({ placeholderPrefix, staticPlaceholder, placeholder }) => {
  const hoveredLabel = useHoveredLabel();
  const { selectedLabel, open, previewOnHover } = useCombobox();

  if (previewOnHover && hoveredLabel && open) {
    return (
      <div className="truncate">
        {placeholderPrefix}
        {hoveredLabel}
      </div>
    );
  }

  return (
    <div className="truncate">
      {staticPlaceholder ? (
        staticPlaceholder
      ) : (
        <>
          {placeholderPrefix}
          {selectedLabel ? (
            selectedLabel
          ) : (
            <span className="text-subtle">{placeholder}</span>
          )}
        </>
      )}
    </div>
  );
};

export const ComboboxTriggerButton = React.forwardRef<
  HTMLButtonElement,
  ComboboxTriggerProps
>(
  (
    {
      startEnhancer,
      endEnhancer,
      staticPlaceholder,
      placeholderPrefix,
      placeholder,
      triggerClassName,
      buttonProps,
    },
    ref,
  ) => {
    const { isDisabled, open } = useCombobox();

    return (
      <PopoverTrigger asChild>
        <Button
          {...buttonProps}
          childContainerClassName={twMerge(
            "block overflow-hidden",
            buttonProps?.childContainerClassName,
          )}
          contentClassName={twMerge("w-full", buttonProps?.contentClassName)}
          ref={ref}
          variant="noStyle"
          role="combobox"
          aria-expanded={open}
          hasMinDimensions={false}
          className={twMerge(
            "h-[32px] rounded p-2 flex justify-between items-center gap-[8px] overflow-hidden",
            isDisabled
              ? "bg-gray-200 text-gray-500 cursor-not-allowed"
              : "bg-slate-100 hover:bg-slate-200 text-slate-800",
            triggerClassName,
          )}
          disabled={isDisabled}
          startEnhancer={startEnhancer}
          endEnhancer={endEnhancer}
        >
          <div className="text-xs leading-5 items-center font-normal overflow-hidden">
            <BoxLabel
              placeholderPrefix={placeholderPrefix}
              staticPlaceholder={staticPlaceholder}
              placeholder={placeholder}
            />
          </div>
        </Button>
      </PopoverTrigger>
    );
  },
);

ComboboxTriggerButton.displayName = "ComboboxTriggerButton";
