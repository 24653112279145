import type * as React from "react";
import type { SavedColorStyle } from "schemas/generated/designLibrary";
import type { TextShadow } from "schemas/textShadow";

import { getFormattedColorWithoutOpacity } from "@editor/utils/colors";

import { getSavedStyleValue } from "replo-runtime/shared/savedStyles";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { filterNulls } from "replo-utils/lib/array";
import { v4 as uuidv4 } from "uuid";

import { regexForSplittingPropValuesWithDynamicData } from "./designLibrary";

export const parseTextShadows = (textShadows: string[]) => {
  const textShadowObject = textShadows.map((shadow) => {
    const id = uuidv4();

    const [offsetX, offsetY, blur, color] = shadow.match(
      regexForSplittingPropValuesWithDynamicData,
    )!;

    return {
      id,
      offsetX: offsetX!,
      offsetY: offsetY!,
      blur: blur!,
      color: color ?? "",
    };
  });

  return textShadowObject;
};

export const getTextShadowString = (
  textShadows: TextShadow[],
  withoutOpacity = false,
) => {
  const propertiesOrder = ["offsetX", "offsetY", "blur", "color"] as const;

  const textShadowsString = textShadows.map((value) => {
    const orderedShadowValues = filterNulls(
      propertiesOrder.map((prop) => {
        if (value[prop]?.includes("#") && withoutOpacity) {
          return getFormattedColorWithoutOpacity(value[prop]);
        }

        return value[prop];
      }),
    );

    return orderedShadowValues.join(" ");
  });

  return textShadowsString.join(",").trim();
};

export const formatTitle = (
  textShadow: TextShadow,
  colorSavedStyles?: (SavedColorStyle & { id: string })[],
) => {
  // NOTE (Fran 2024-12-03): If the color is a dynamic design library value, we need to get the saved style name
  // and replace the color value with the saved style name.
  if (
    textShadow.color &&
    isDynamicDesignLibraryValue(textShadow.color) &&
    colorSavedStyles
  ) {
    const savedStyle = getSavedStyleValue(colorSavedStyles, textShadow.color);
    return getTextShadowString(
      [
        {
          ...textShadow,
          color: savedStyle?.name ?? "SavedStyle",
        },
      ],
      true,
    );
  }
  return getTextShadowString([textShadow], true);
};
