import type { TRPCClientErrorBase } from "@trpc/client";
import type { ErrorType } from "replo-publisher/src/trpc/trpc";
import type {
  PreviewElementProps,
  ReploElement,
} from "schemas/generated/element";

import React, { useState } from "react";

import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import usePreviewBeforePublishInfo from "@editor/hooks/usePreviewBeforePublishInfo";
import { useTemplateProductPublishingInfo } from "@editor/hooks/usePublishedInfo";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import {
  selectDraftElementCurrentVersion,
  selectDraftElementId,
  selectDraftElementType,
} from "@reducers/core-reducer";

import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import SplitButton from "@replo/design-system/components/button/SplitButton";
import { Spinner } from "@replo/design-system/components/spinner";
import copy from "copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { BsArrowUpRight } from "react-icons/bs";
import { QRCode } from "react-qrcode-logo";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import { successToast } from "../common/designSystem/Toast";
import { ConnectShopifyCallout } from "../editor/page/ConnectShopifyCallout";
import { PreviewLinkAndSVGSkeleton } from "../editor/SkeletonLoaders";

type PreviewPageButtonProps = {
  onCreatePreviewData: () => PreviewElementProps | undefined;
  onCreatePreviewSuccess?: (data: { html: string }) => void;
  isStoreClosed: boolean;
  isProjectConnectedToShopify: boolean;
};

function shouldShowPreviewsForElementType(
  draftElementType: ReploElement["type"],
): boolean {
  return exhaustiveSwitch({ type: draftElementType })({
    page: true,
    shopifyArticle: false,
    shopifySection: true,
    shopifyProductTemplate: true,
  });
}

const PreviewPageButton: React.FC<PreviewPageButtonProps> = ({
  onCreatePreviewData,
  onCreatePreviewSuccess,
  isStoreClosed,
  isProjectConnectedToShopify,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const logEvent = useLogAnalytics();
  const errorToast = useErrorToast();
  const [isPreviewPopoverOpen, setIsPreviewPopoverOpen] = React.useState(false);
  const { url: previewUrl } = usePreviewBeforePublishInfo();
  const { isProductTemplateWithFakeProduct } =
    useTemplateProductPublishingInfo();
  const [
    lastCreatedPreviewElementVersion,
    setLastCreatedPreviewElementVersion,
  ] = React.useState<number | null>(null);
  const currentElementVersion = useEditorSelector(
    selectDraftElementCurrentVersion,
  );
  const draftElementType = useEditorSelector(selectDraftElementType);

  const [lastCreatedPreviewElementId, setLastCreatedPreviewElementId] =
    React.useState<string | null>(null);
  const currentElementId = useEditorSelector(selectDraftElementId);

  const {
    mutateAsync: createPreviewElement,
    isPending: isPreviewCreateLoading,
    isSuccess: isPreviewCreateSuccessful,
  } = trpc.element.createPreview.useMutation({
    onSuccess: (data) => {
      if (data.html) {
        onCreatePreviewSuccess?.({ html: data.html });
      }
    },
    onError: (error) => {
      handleCreatePreviewElementError(error);
    },
  });

  function handleCreatePreviewElementError(
    error: TRPCClientErrorBase<ErrorType>,
  ) {
    setIsPreviewPopoverOpen(false);
    if ("status" in error && error.status === 404) {
      errorToast(
        "Page Not Found",
        "The page you are trying to preview does not exist. Maybe it was deleted by another user?",
        {
          eventName: "error.page.preview.not_found",
          eventProperties: {
            error: error.message,
          },
        },
      );
    } else {
      errorToast("Error", "Something went wrong. Please try again later.", {
        eventName: "error.page.preview.not_found.no_404",
        eventProperties: {
          error: error.message,
        },
      });
    }
  }

  async function onPopoverOpen(isPreviewPopoverOpen: boolean) {
    setIsPreviewPopoverOpen(isPreviewPopoverOpen);
    logEvent("preview.requested", {});
    if (
      isPreviewPopoverOpen &&
      (currentElementVersion !== lastCreatedPreviewElementVersion ||
        currentElementId !== lastCreatedPreviewElementId)
    ) {
      const previewData = onCreatePreviewData();
      if (previewData) {
        await createPreviewElement(previewData);
        setLastCreatedPreviewElementVersion(currentElementVersion ?? null);
        setLastCreatedPreviewElementId(currentElementId ?? null);
      }
    }
  }

  const popoverProps = isProjectConnectedToShopify
    ? {
        isOpen: isPreviewPopoverOpen,
        onOpenChange: (isOpen: boolean) => void onPopoverOpen(isOpen),
        content: (
          <PublishPreview
            isPreviewCreateLoading={isPreviewCreateLoading}
            isPreviewCreateSuccessful={isPreviewCreateSuccessful}
          />
        ),
        title: "Preview Options",
        titleClassnames: "text-sm font-semibold leading-5",
        className: "flex flex-col p-4 w-[234px] border border-slate-300 gap-2",
        sideOffset: 5,
        skipTriggerStyles: true,
        tooltipText: "Preview Options",
        ariaLabel: "Preview Options",
      }
    : {
        content: <ConnectShopifyCallout type="preview" />,
        className: "w-72",
        sideOffset: 16,
        hideCloseButton: true,
      };

  const isPreviewDisabled =
    isStoreClosed ||
    isProductTemplateWithFakeProduct ||
    !shouldShowPreviewsForElementType(draftElementType);

  return (
    <SplitButton
      buttonId="preview-button"
      buttonToolTipText={
        isProjectConnectedToShopify
          ? "Preview page in browser"
          : "Connect to Shopify to preview"
      }
      disabled={isPreviewDisabled}
      isButtonLoading={isButtonLoading}
      // NOTE (Kurt, 2024-12-26): We don't want to disable the InlinePopover for the
      // split button even when `isPreviewDisabled` is true because we show a "Connect to Shopify"
      // callout when the the project is not connected to Shopify.
      isButtonDisabled={!isProjectConnectedToShopify}
      onButtonClick={() => {
        void (async () => {
          setIsButtonLoading(true);
          logEvent("preview.requested", {});
          if (
            currentElementVersion === lastCreatedPreviewElementVersion &&
            currentElementId === lastCreatedPreviewElementId
          ) {
            window.open(previewUrl, "_blank");
          } else {
            const previewData = onCreatePreviewData();
            if (previewData) {
              await createPreviewElement(previewData);
              setLastCreatedPreviewElementVersion(
                currentElementVersion ?? null,
              );
              setLastCreatedPreviewElementId(currentElementId ?? null);
              window.open(previewUrl, "_blank");
            }
          }
          setIsButtonLoading(false);
        })();
      }}
      buttonText="Preview"
      popoverProps={{ ...popoverProps, dataTestId: "preview-popover" }}
      size="base"
      variant="secondary"
    />
  );
};

type PublishPreviewProps = {
  isPreviewCreateLoading: boolean;
  isPreviewCreateSuccessful: boolean;
};

const PublishPreview: React.FC<PublishPreviewProps> = (props) => {
  const { isPreviewCreateLoading, isPreviewCreateSuccessful } = props;
  const { url: previewUrl, previewUrlAvailable } =
    usePreviewBeforePublishInfo();

  const logEvent = useLogAnalytics();

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex flex-col w-full gap-1">
        <div className="text-slate-800 typ-header-small">Browser</div>
        <div className="flex flex-row w-full gap-1">
          <Button
            variant="secondary"
            className="w-full"
            endEnhancer={
              !isPreviewCreateLoading ? <BsArrowUpRight size={10} /> : undefined
            }
          >
            {isPreviewCreateLoading ? (
              <div className="flex items-center gap-2">
                <div className="animate-spin">
                  <Spinner size={16} variant="secondary" />
                </div>
              </div>
            ) : (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={previewUrl}
                className="cursor-pointer"
              >
                View Preview in Browser
              </a>
            )}
          </Button>
          <IconButton
            variant="secondary"
            size="sm"
            icon={<BiCopy size={16} />}
            onClick={() => {
              copy(previewUrl);
              successToast(
                "Preview URL copied",
                "You can now open the Preview in a new window.",
              );
              logEvent("preview.copied", {});
            }}
          />
        </div>
      </div>
      <div className="flex flex-col w-full gap-3">
        <div className="flex flex-col gap-1">
          <div className="text-slate-800 typ-header-small">Mobile</div>
          <div className="typ-body-small font-normal text-muted">
            Scan the QR code with your mobile device to preview.
          </div>
        </div>
        <div className="flex items-center justify-center">
          {!isPreviewCreateLoading &&
          isPreviewCreateSuccessful &&
          previewUrlAvailable ? (
            <QRCode
              value={previewUrl}
              qrStyle="dots"
              bgColor="black"
              fgColor="white"
              logoImage="/replo-logo-quartercircle-black-bg.svg"
              logoWidth={60}
              removeQrCodeBehindLogo={true}
              quietZone={5}
              // NOTE (Kurt, 2024-12-04): A width of 190px aligns the QR code with
              // the width of the parent container
              size={190}
              eyeRadius={2}
            />
          ) : (
            <PreviewLinkAndSVGSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewPageButton;
