import type {
  ReploClipboard,
  ReploClipboardFigmaPluginv2,
} from "@editor/utils/copyPaste";
import type { Component } from "schemas/component";

import * as React from "react";

import { infoToast } from "@editor/components/common/designSystem/Toast";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useBulkAiAltTextMutation } from "@editor/reducers/ai-reducer";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectComponentDataMapping,
  selectDraftComponentId,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorStore } from "@editor/store";
import { getComponentClipboard } from "@editor/utils/copyPaste";
import { useUploadFigmaImage } from "@hooks/figmaToReplo/useUploadFigmaImage";
import useApplyComponentAction from "@hooks/useApplyComponentAction";
import useCurrentProjectId from "@hooks/useCurrentProjectId";
import { useAvailableFontFamilies } from "@hooks/useFontFamilyOptions";
import { useGetAttributeRef } from "@hooks/useGetAttribute";
import useSetDraftElement from "@hooks/useSetDraftElement";
import {
  addWarning,
  reset as resetFigmaState,
  selectProgress,
  setPanelVisibility,
  updateProgress,
} from "@reducers/figma-to-replo-reducer";

import chunk from "lodash-es/chunk";
import { forEachComponentAndDescendants } from "replo-runtime";
import { refreshComponentIds } from "replo-shared/refreshComponentIds";
import { v4 as uuidv4 } from "uuid";

const IMAGES_PER_BATCH = 25;

const useUploadAndMatchFigmaImages = () => {
  const projectId = useCurrentProjectId();
  const { uploadFigmaImage } = useUploadFigmaImage();

  return async (
    reploComponent: Component,
    reploComponentIdToImageData: Record<string, string>,
  ) => {
    if (!projectId) {
      return { failedUploadComponentIds: [], imageComponentIdToUrl: {} };
    }
    const batches = chunk(
      Object.entries(reploComponentIdToImageData),
      IMAGES_PER_BATCH,
    );
    const nImages = Object.keys(reploComponentIdToImageData).length;

    const failedUploadComponentIds: string[] = [];
    const imageComponentIdToUrl: Record<string, string> = {};

    for (const [index, batch] of batches.entries()) {
      const startRange = index * IMAGES_PER_BATCH + 1;
      const endRange = Math.min(
        (index + 1) * IMAGES_PER_BATCH,
        Object.keys(reploComponentIdToImageData).length,
      );
      // eslint-disable-next-line no-console
      console.debug(`uploading images ${startRange}-${endRange} of ${nImages}`);

      await Promise.all(
        batch.map(async ([reploComponentId, imageDataUrl]) => {
          const uploadResult = await uploadFigmaImage({
            dataURL: imageDataUrl,
            name: "",
            id: uuidv4(),
          });
          if (uploadResult) {
            forEachComponentAndDescendants(reploComponent, (component) => {
              if (component.id === reploComponentId) {
                if (component.type === "image") {
                  component.props.src = uploadResult.url;
                  component.props.__imageSource = uploadResult.url;
                  // Note (Evan, 2024-08-16): Only trigger alt text for image components,
                  // since we don't have alt text on background images
                  imageComponentIdToUrl[reploComponentId] = uploadResult.url;
                }
                if (component.type === "container") {
                  component.props.style = {
                    ...component.props.style,
                    backgroundImage: `url("${uploadResult.url}")`,
                  };
                }
                return "stop";
              }
            });
          } else {
            failedUploadComponentIds.push(reploComponentId);
          }
        }),
      );
    }

    return { failedUploadComponentIds, imageComponentIdToUrl };
  };
};

const matchFonts = (
  reploComponent: Component,
  availableFontFamilies: { name: string; displayName: string | undefined }[],
): { unavailableFonts: Set<string> } => {
  const unavailableFonts = new Set<string>();

  forEachComponentAndDescendants(reploComponent, (component) => {
    if (component.type === "text") {
      const fontFamily = component.props.style?.fontFamily;
      if (!fontFamily) {
        return;
      }

      // Note (Evan, 2024-08-06): Don't search multiple times
      // for a font we've already checked
      if (unavailableFonts.has(fontFamily)) {
        return;
      }

      // Note (Evan, 2024-08-06): Search in increasingly fuzzy ways
      const font =
        // 1) Exact match for the true name/value
        availableFontFamilies.find(({ name }) => fontFamily === name) ??
        // 2) Exact match for display name
        availableFontFamilies.find(
          ({ displayName }) => fontFamily === displayName,
        ) ??
        // 3) Fuzzy match for true name/value
        availableFontFamilies.find(({ name }) =>
          fontFamily.toLowerCase().includes(name.toLowerCase()),
        ) ??
        // 4) Fuzzy match for display name
        availableFontFamilies.find(
          ({ displayName }) =>
            displayName &&
            fontFamily.toLowerCase().includes(displayName.toLowerCase()),
        );

      if (font) {
        component.props.style = {
          ...component.props.style,
          fontFamily: font.name,
        };
      } else {
        unavailableFonts.add(fontFamily);
      }
    }
  });

  return { unavailableFonts };
};

const refreshComponentIdsAndImageData = ({
  reploComponentWithOldIds,
  oldReploComponentIdToImageData,
}: {
  reploComponentWithOldIds: ReploClipboardFigmaPluginv2["reploComponent"];
  oldReploComponentIdToImageData: ReploClipboardFigmaPluginv2["reploComponentIdToImageData"];
}) => {
  const { component: reploComponent, oldIdToNewId } = refreshComponentIds(
    reploComponentWithOldIds,
  );

  const reploComponentIdToImageData: Record<string, string> = {};

  for (const [oldComponentId, imageData] of Object.entries(
    oldReploComponentIdToImageData,
  )) {
    reploComponentIdToImageData[oldIdToNewId[oldComponentId]!] = imageData;
  }

  return { reploComponent, reploComponentIdToImageData };
};

// Note (Evan, 2024-08-19): Returns two functions:
// pasteFromFigma (when we already have the Figma clipboard)
// pasteFromFigmaOrToast (which checks the clipboard, throws the toast if
// it's not a Figma export, and pastes otherwise)
const useFigmaPluginPaste = ({
  findParentForPaste,
}: {
  findParentForPaste: (
    reploClipboard: ReploClipboard,
    componentId: string,
  ) => {
    newParent: Component;
    positionWithinSiblings: number;
  } | null;
}) => {
  const dispatch = useEditorDispatch();
  const applyComponentAction = useApplyComponentAction();
  const availableFontFamilies = useAvailableFontFamilies();
  const projectId = useCurrentProjectId();
  const uploadAndMatchFigmaImages = useUploadAndMatchFigmaImages();
  const setDraftElement = useSetDraftElement();
  const logEvent = useLogAnalytics();
  const store = useEditorStore();
  const [triggerBulkAiAltText] = useBulkAiAltTextMutation();
  const getAttributeRef = useGetAttributeRef();
  const errorToast = useErrorToast();

  const pasteFromFigma = React.useCallback(
    async (
      reploClipboard: ReploClipboardFigmaPluginv2,
      pasteOnComponentId: string,
    ) => {
      if (!projectId) {
        return;
      }
      try {
        const progress = selectProgress(store.getState());
        if (progress !== "initializing") {
          infoToast(
            "Figma import already in progress",
            "Please accept or discard the current import before pasting another",
          );
          return;
        }
        dispatch(setPanelVisibility(true));
        dispatch(resetFigmaState());

        // Note (Evan, 2024-08-08): Just storing this separately to avoid
        // any potential issues getting this from Redux immediately after
        // dispatching it
        const analyticsWarnings = [];

        const {
          reploComponent: reploComponentWithOldIds,
          reploComponentIdToImageData: oldReploComponentIdToImageData,
          figmaId,
          exportWarnings,
        } = reploClipboard;

        // Note (Evan, 2024-08-26): We have to fresh component IDs here to avoid
        // having duplicate IDs if we e.g. paste a Figma import twice. We have to
        // also refresh the IDs in the ID -> Image data map, since we use that to
        // match the upload images to their corresponding components.
        const { reploComponent, reploComponentIdToImageData } =
          refreshComponentIdsAndImageData({
            reploComponentWithOldIds,
            oldReploComponentIdToImageData,
          });

        const { newParent, positionWithinSiblings } = findParentForPaste(
          reploClipboard,
          pasteOnComponentId,
        )!;

        dispatch(updateProgress("uploadingImages"));
        const { failedUploadComponentIds, imageComponentIdToUrl } =
          await uploadAndMatchFigmaImages(
            reploComponent,
            reploComponentIdToImageData,
          );

        if (failedUploadComponentIds.length > 0) {
          const warning = {
            type: "uploadError" as const,
            reploComponentIds: failedUploadComponentIds,
          };
          dispatch(addWarning(warning));
          analyticsWarnings.push(warning);
        }

        // Note (Evan, 2024-07-31): Matching fonts is basically instantaneous,
        // (just takes one traversal through the component) so no need to dispatch
        // a progress update here.
        const { unavailableFonts } = matchFonts(
          reploComponent,
          availableFontFamilies,
        );

        if (unavailableFonts.size > 0) {
          const warning = {
            type: "missingFonts" as const,
            fonts: Array.from(unavailableFonts),
          };
          dispatch(addWarning(warning));
          analyticsWarnings.push(warning);
        }

        if (exportWarnings) {
          if (exportWarnings.multipleFillsNodes.length > 0) {
            const warning = {
              type: "multipleFills" as const,
              nodeNames: exportWarnings.multipleFillsNodes.map(
                ({ name }) => name,
              ),
            };
            dispatch(addWarning(warning));
            analyticsWarnings.push(warning);
          }

          if (exportWarnings.maskNodes.length > 0) {
            const warning = {
              type: "mask" as const,
              nodeNames: exportWarnings.maskNodes.map(({ name }) => name),
            };
            dispatch(addWarning(warning));
            analyticsWarnings.push(warning);
          }
        }

        applyComponentAction({
          type: "addComponentToComponent",
          componentId: newParent!.id,
          value: {
            newComponent: reploComponent,
            position: "child",
            positionWithinSiblings,
          },
          source: "componentContextMenu",
          analyticsExtras: {
            actionType: "create",
            createdBy: "user",
          },
        });

        setDraftElement({
          componentIds: [reploComponent.id],
        });

        logEvent("editor.paste.figma", {
          figmaId,
          warnings: analyticsWarnings,
          entrypoint: "shortcut",
        });

        void triggerBulkAiAltText(imageComponentIdToUrl);
      } catch (error) {
        console.error("error", error);
      }
      dispatch(updateProgress("importComplete"));
    },
    [
      store,
      findParentForPaste,
      dispatch,
      applyComponentAction,
      projectId,
      availableFontFamilies,
      uploadAndMatchFigmaImages,
      setDraftElement,
      logEvent,
      triggerBulkAiAltText,
    ],
  );

  const pasteFromFigmaOrToast = React.useCallback(async () => {
    const storeState = store.getState();
    const draftElement =
      selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);

    if (!draftElement) {
      return;
    }

    const draftComponentId = selectDraftComponentId(storeState);
    const {
      moneyFormat,
      activeCurrency: currencyCode,
      activeLanguage: language,
    } = selectLocaleData(storeState);
    const componentDataMapping = selectComponentDataMapping(storeState);
    const productResolutionDependencies = {
      products: [],
      currencyCode,
      moneyFormat,
      language,
      templateProduct: null,
      isEditor: true,
      isShopifyProductsLoading: false,
    };
    const clipboard = await getComponentClipboard(
      undefined,
      draftElement,
      getAttributeRef.current,
      componentDataMapping,
      productResolutionDependencies,
      errorToast,
    );

    if (!clipboard || clipboard.type !== "figmaPluginExportv2") {
      infoToast(
        "No Figma Export detected",
        <div className="flex flex-col gap-2">
          <div>To paste files from Figma, use the Figma to Replo plugin.</div>
          <a
            className="text-blue-600"
            href="https://www.figma.com/community/plugin/1373402218933388606/figma-to-shopify-with-replo-ai-enabled-landing-page-builder"
            target="_blank"
            rel="noreferrer"
          >
            View Plugin
          </a>
        </div>,
      );
      return;
    }

    await pasteFromFigma(
      clipboard,
      draftComponentId ?? draftElement.component.id,
    );
  }, [store, getAttributeRef, pasteFromFigma, errorToast]);

  return { pasteFromFigma, pasteFromFigmaOrToast };
};

export default useFigmaPluginPaste;
