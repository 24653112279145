import * as React from "react";

import { Loader } from "@editor/components/common/Loader";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";

import { ExperimentActionsBar } from "@/features/experiments/components/ExperimentActionsBar";
import { ExperimentsLayout } from "@/features/experiments/Layout";
import { ExperimentsEditTabV2 } from "@/features/experiments/tabs/ExperimentsEditTabV2";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import { getExperimentStatus } from "schemas/experiment";

const ExperimentDetailsTab: React.FC = () => {
  const { isLoadingExperiment, experiment } = useExperimentDetails();
  const workspaceId = useCurrentWorkspaceId();

  if (isLoadingExperiment || !experiment || !workspaceId) {
    return <Loader />;
  }

  const status = getExperimentStatus(experiment);

  return (
    <ExperimentsLayout
      headerTitle={experiment.name}
      headerActions={
        <ExperimentActionsBar
          workspaceId={workspaceId}
          experiment={experiment}
        />
      }
      status={status}
      editable
      showBackButton
    >
      <ExperimentsEditTabV2 />
    </ExperimentsLayout>
  );
};

export default ExperimentDetailsTab;
