// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";
import { useSearchParams } from "react-router-dom";

export const Screen = {
  LOGIN: "/auth/login",
  SIGNUP: "/auth/signup",
  INIT_RESET_PASSWORD: "/auth/password/reset/init",
  RESET_PASSWORD: "/auth/password/reset",
};

export const ScreenData = {
  [Screen.LOGIN]: {
    header: "Sign In",
    footerText: "Don't have a Replo account?",
    footerCtaText: "Sign up for free now",
    footerCtaRedirectTo: Screen.SIGNUP,
  },
  [Screen.SIGNUP]: {
    header: "Create your free account",
    footerText: "Already have a Replo account?",
    footerCtaText: "Sign in",
    footerCtaRedirectTo: Screen.LOGIN,
  },
  [Screen.INIT_RESET_PASSWORD]: {
    header: "Reset Password",
    footerText: "Already have a Replo account?",
    footerCtaText: "Sign in",
    footerCtaRedirectTo: Screen.LOGIN,
  },
  [Screen.RESET_PASSWORD]: {
    header: "Enter New Password",
    footerText: "Already have a Replo account?",
    footerCtaText: "Sign in",
    footerCtaRedirectTo: Screen.LOGIN,
  },
};

export const ErrorMessage = ({
  errorMessage,
  className,
}: {
  errorMessage: string | null;
  className?: string;
}) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <div
      className={twMerge(
        "semibold h6 mb-2 rounded-sm bg-red-100 p-2 text-sm text-red-600",
        className,
      )}
    >
      {errorMessage}
    </div>
  );
};

export const prefilledErrorMapping = {
  login_in_to_associate_shopify: `Please create or log in to your Replo account to continue to Replo.`,
};

export function useNextUrl() {
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next");
  const [nextUrl, nextSearchParamsAsString] = next?.split("?") ?? ["/", ""];
  const nextSearchParams = new URLSearchParams(nextSearchParamsAsString);

  return { nextUrl, nextSearchParams };
}

export function useNextUrlWithPendingAppInstallationId() {
  const { nextUrl, nextSearchParams } = useNextUrl();
  const [searchParams] = useSearchParams();

  const shopifyIntegrationId = searchParams.get("shopifyIntegrationId");
  const pendingAppInstallationId = searchParams.get("pendingAppInstallationId");
  if (shopifyIntegrationId) {
    nextSearchParams.set("shopifyIntegrationId", shopifyIntegrationId);
  } else if (pendingAppInstallationId) {
    nextSearchParams.set("pendingAppInstallationId", pendingAppInstallationId);
  }

  return { nextUrl, nextSearchParams };
}
