import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const LooxReviewsWidget: React.FC<RenderComponentProps> = (props) => {
  const product = useProductFromProps(props.component.props, props.context);

  const liquidSourceWidget = `
  <div id="looxReviews" data-product-id="{{product.id}}" class="loox-reviews-default"></div>
  `;

  const liquid = wrapProductAssignmentToLiquidSource({
    product,
    liquidSource: liquidSourceWidget,
    context: props.context,
  });

  return (
    <SharedShopifyLiquid
      component={props.component}
      componentId={props.component.id}
      componentAttributes={props.componentAttributes}
      liquidSource={liquid}
      placeholder="The Loox Reviews Widget will appear here when you publish. Something not right? Let us know at support@replo.app"
      forceEditorPlaceholder={true}
      repeatedIndexPath={props.context.repeatedIndexPath}
      isLiquidSupported
      placeholderTitle="Loox Reviews"
      placeholderType="reviewFull"
    />
  );
};

export default LooxReviewsWidget;
