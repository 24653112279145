import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import { useGetCurrentStepResultsData } from "@components/flows/hooks/useGetCurrentStepResultsData";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import { useReploFlowsStepContext } from "@editor/components/flows/context/ReploFlowsStepContext";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepLayout, {
  OnboardingStepForm,
} from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import some from "lodash-es/some";
import { useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = { firstName: string; lastName: string };

const UserFullNameStep: React.FC = () => {
  const analytics = useLogAnalytics();
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.full-name">();
  const { currentStep, submitStep } = useReploFlowsStepContext();
  const { user } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const shopifyUrl = searchParams.get("shopify_shop_url");

  const initialFirstName = stepResultsData?.firstName ?? user?.firstName ?? "";
  const initialLastName = stepResultsData?.lastName ?? user?.lastName ?? "";

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: initialFirstName,
      lastName: initialLastName,
    },
  });
  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const watchAllFields = useWatch({ control });
  const someFieldIsEmpty = some(watchAllFields, isEmpty);

  const onSubmit = ({ firstName, lastName }: FormValues) => {
    if (currentStep) {
      submitStep(
        currentStep.id,
        currentStep.type,
        {
          firstName,
          lastName,
          shopifyUrl,
        },
        ({ instance, nextStep }) =>
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          }),
      );
      analytics("onboarding.flow.begin", {});
    }
  };

  const firstNameError = errors.firstName?.message;
  const lastNameError = errors.lastName?.message;

  return (
    <OnboardingStepLayout
      rightPanelContent={<StepImage src="/images/flows/your-name.png" />}
    >
      <OnboardingStepForm
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <StepTitle>
          What is your name?<span className="text-red-600">*</span>
        </StepTitle>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <LabeledControl
            label="First Name"
            className="text-default font-medium"
            size="sm"
          >
            <div className="flex flex-col gap-2">
              <Input
                aria-invalid={Boolean(firstNameError) ? "true" : undefined}
                aria-describedby={
                  Boolean(firstNameError) ? "error-first-name" : undefined
                }
                autoComplete="off"
                placeholder="First Name"
                {...register("firstName", {
                  required: "First Name is required",
                })}
                type="text"
                size="base"
                validityState={Boolean(firstNameError) ? "invalid" : "valid"}
                autoFocus
              />
              {firstNameError && (
                <ErrorMessage id="error-first-name" error={firstNameError} />
              )}
            </div>
          </LabeledControl>
          <LabeledControl
            label="Last Name"
            className="text-default font-medium"
            size="sm"
          >
            <div className="flex flex-col gap-2">
              <Input
                aria-invalid={Boolean(lastNameError) ? "true" : undefined}
                aria-describedby={
                  Boolean(lastNameError) ? "error-last-name" : undefined
                }
                autoComplete="off"
                placeholder="Last Name"
                {...register("lastName", {
                  required: "Last Name is required",
                })}
                type="text"
                size="base"
                validityState={Boolean(lastNameError) ? "invalid" : "valid"}
              />
              {lastNameError && (
                <ErrorMessage id="error-last-name" error={lastNameError} />
              )}
            </div>
          </LabeledControl>
        </div>
        <FlowActionButtons
          shouldDisableNextButton={someFieldIsEmpty}
          hideBackButton
        />
      </OnboardingStepForm>
    </OnboardingStepLayout>
  );
};

export default UserFullNameStep;
