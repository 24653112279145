import * as React from "react";

import Tooltip from "@replo/design-system/components/tooltip";

import twMerge from "../../utils/twMerge";
import Label from "../label";
import Description from "../label/Description";
import Toggle from "./Toggle";

type ToggleWithDescriptionProps = {
  label?: React.ReactNode;
  description?: React.ReactNode;
  tooltipText?: string;
  disabled?: boolean;
  size: "base" | "sm";
  className?: string;
  isOn?: boolean;
  onChange?: (isOn: boolean) => void;
};

const ToggleWithDescription = ({
  label,
  description,
  tooltipText,
  disabled = false,
  size,
  isOn,
  onChange,
  className,
}: ToggleWithDescriptionProps) => {
  const [isChecked, setIsChecked] = React.useState(false);

  // Determine whether to use controlled or uncontrolled state
  const isControlled = isOn !== undefined;

  const handleToggle = () => {
    if (isControlled) {
      onChange?.(!isOn);
    } else {
      setIsChecked((prev) => !prev);
    }
  };

  const checkedValue = isControlled ? isOn : isChecked;

  return (
    <div
      className={twMerge(
        "flex gap-4 items-center p-[12px] w-[267px] border-[.5px] border-border rounded-lg cursor-pointer",
        className,
      )}
      onClick={handleToggle}
    >
      {(label || description) && (
        <div className="flex-1 min-w-0 gap-1">
          <Label
            labelClassName="cursor-pointer"
            className="mb-0 break-words"
            label={label}
            size={size}
          />
          <Description
            className="mt-[2px] break-words"
            description={description}
            size={size}
          />
        </div>
      )}
      <div className="ml-auto shrink-0">
        <Tooltip content={tooltipText} triggerAsChild>
          <Toggle isOn={checkedValue} disabled={disabled} size={size} />
        </Tooltip>
      </div>
    </div>
  );
};

export default ToggleWithDescription;
