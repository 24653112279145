import type { DropTargetEdge } from "@editor/types/drop-target";

import * as React from "react";

import { image } from "@components/editor/templates/image";
import { successToast } from "@editor/components/common/designSystem/Toast";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import useDropFileZone from "@editor/hooks/useDropFileZone";
import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useStoreAndDraftElementProducts } from "@editor/hooks/useStoreProducts";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectComponentDataMapping,
  selectComponentMapping,
  selectDraftComponentId,
  selectDraftComponentType,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectImageSourceComponentProps,
  selectRootComponentId,
} from "@editor/reducers/core-reducer";
import {
  addEditorMediaUploadingComponentId,
  removeEditorMediaUploadingComponentId,
} from "@editor/reducers/editor-media-upload-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { finalizeDropComponentActions } from "@editor/utils/drop";
import { getImageSourceComponentActions } from "@editor/utils/getImageSourceComponentActions";
import getUpdatedImageComponentTemplate from "@editor/utils/getUpadatedImageComponentTemplate";

import classNames from "classnames";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { z } from "zod";

import {
  selectActiveCanvas,
  selectCanvasDeltaXY,
  selectCanvases,
  setDeltaXY,
} from "./canvas-reducer";

const responseSchema = z.object({
  data: z.object({
    asset: z.object({
      id: z.string(),
      publicUrl: z.string(),
      contentType: z.string(),
    }),
  }),
});

export const FileDropZone: React.FC<
  React.PropsWithChildren<{
    role?: string;
    className?: string;
    style?: React.CSSProperties;
  }>
> = ({ children, role, className, style }) => {
  const store = useEditorStore();
  const dispatch = useEditorDispatch();
  const applyComponentAction = useApplyComponentAction();
  const getAttribute = useGetAttribute();
  const setDraftElement = useSetDraftElement();
  const { products } = useStoreAndDraftElementProducts();
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const { activeCurrency, activeLanguage, moneyFormat } =
    useEditorSelector(selectLocaleData);
  const canvases = useEditorSelector(selectCanvases);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const activeCanvasWidth = canvases[activeCanvas].canvasWidth;
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;
  const errorToast = useErrorToast();

  const onDropBeforeUpload = () => {
    const draftComponentType = selectDraftComponentType(store.getState());
    const draftComponentId = selectDraftComponentId(store.getState());
    const draftElement =
      selectDraftElement_warningThisWillRerenderOnEveryUpdate(store.getState());
    const rootComponentId = selectRootComponentId(store.getState());

    if (!draftElement || !rootComponentId) {
      return null;
    }

    if (draftComponentType !== "image") {
      const componentIdToDrag = draftComponentId ?? rootComponentId;

      const target = {
        componentId: componentIdToDrag,
        edge: "top" as DropTargetEdge,
        error: null,
      };

      const imageTemplate = getUpdatedImageComponentTemplate(
        image,
        componentIdToDrag === rootComponentId
          ? 0
          : selectCanvasDeltaXY(store.getState()).deltaY,
      );

      const result = finalizeDropComponentActions(
        target,
        imageTemplate,
        draftElement,
        componentIdToDrag,
        getAttribute,
        selectComponentDataMapping(store.getState()),
        {
          products,
          currencyCode: activeCurrency,
          language: activeLanguage,
          moneyFormat,
          templateProduct,
          isEditor: true,
          isShopifyProductsLoading: false,
        },
      );

      if (result.result === "error") {
        errorToast(
          "Cannot add image here",
          "Please try dropping the image into a different container or section. If this issue persists, try selecting a container first before dropping the image.",
          {
            eventName: "error.file.upload",
            eventProperties: {
              error: result.message ?? "No message for error",
            },
          },
        );
      } else if (result.result === "success") {
        const componentActions = result.actions;
        if (componentActions.length > 0) {
          applyComponentAction({
            type: "applyCompositeAction",
            value: componentActions,
            activeCanvas: componentActions[0]!.activeCanvas,
          });

          if (result.addedComponent) {
            dispatch(
              addEditorMediaUploadingComponentId(result.addedComponent.id),
            );
            setDraftElement({
              componentIds: [result.addedComponent.id],
            });

            // NOTE (Fran 2024-05-03): If the user does not select anything before the image is dropped,
            // we will add the image at the top of the root component. In that case, we need to send
            // they to the top of the canvas.
            if (componentIdToDrag === rootComponentId) {
              dispatch(
                setDeltaXY({
                  deltaX: selectCanvasDeltaXY(store.getState()).deltaX ?? 0,
                  deltaY: 0,
                  frameWidth: activeCanvasWidth,
                }),
              );
            }

            return result.addedComponent.id;
          }
        }
      }
    } else if (draftComponentId) {
      // NOTE (Fran 2024-05-01): We need to remove the old image source when we upload a new image.
      applyComponentAction({
        type: "setStyles",
        value: {
          __imageSource: null,
        },
      });
      dispatch(addEditorMediaUploadingComponentId(draftComponentId));
      return draftComponentId;
    }

    return null;
  };

  const onUploadComplete = (res: unknown, imageComponentId: string | null) => {
    const responseParsed = responseSchema.safeParse(res);

    if (responseParsed.success) {
      const { publicUrl } = responseParsed.data.data.asset;
      // NOTE (Fran 2024-05-03): It's possible the user deselects the image component before the upload is complete.
      // In that case, we need to know which component we need to update, so we will use the id of the
      // new image component id created at the moment that the image was dropped.
      if (imageComponentId) {
        const componentDataMapping = selectComponentDataMapping(
          store.getState(),
        );
        // NOTE (Fran 2024-05-07): We need to check if the component still exists in the element.
        if (imageComponentId in componentDataMapping) {
          const component = getFromRecordOrNull(
            selectComponentMapping(store.getState()),
            imageComponentId,
          )?.component;

          // NOTE (Fran 2024-05-07): We will only replace the image source if the current image source is empty.
          const imageSource = getAttribute(
            component ?? null,
            "style.__imageSource",
          );
          if (!imageSource.value) {
            const {
              imageComponentId: _imageComponentId,
              imageSourceProp,
              imageUrl,
            } = selectImageSourceComponentProps(
              store.getState(),
              publicUrl,
              imageComponentId,
            );
            const actions = getImageSourceComponentActions({
              canvas: activeCanvas,
              targetComponentId:
                _imageComponentId ?? draftComponentId ?? undefined,
              imageSourceProp,
              imageUrl,
            });

            applyComponentAction({
              type: "applyCompositeAction",
              value: actions,
              componentId: imageComponentId,
            });

            successToast(
              "Image uploaded successfully",
              "Uploaded image has been added to Replo.",
            );
          }
        }
      }
    }

    if (imageComponentId) {
      // NOTE (Fran 2024-05-07): We want to always remove the id from the list of uploading components,
      // even if the component does not exist anymore.
      dispatch(removeEditorMediaUploadingComponentId(imageComponentId));
    }
  };

  const onError = (imageComponentId: string | null) => {
    if (imageComponentId) {
      dispatch(removeEditorMediaUploadingComponentId(imageComponentId));
    }
  };

  const { getRootProps, getInputProps } = useDropFileZone({
    sourceType: "files",
    onDropBeforeUpload,
    onUploadComplete,
    onError,
    acceptDropAssetType: "image/*",
    allowClickToUpload: false,
  });

  return (
    <div
      {...getRootProps({
        className: classNames("h-full w-full cursor-default", className),
        role,
        style,
      })}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};
