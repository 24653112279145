import * as React from "react";

import {
  BsChevronRight,
  BsImage,
  BsInfoCircle,
  BsStarFill,
  BsStarHalf,
} from "react-icons/bs";

import {
  FeatureFlagsContext,
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../shared/runtime-context";

interface RenderComponentPlaceholderProps {
  title?: string | null;
  placeholderTitle?: string;
  icon?: () => React.ReactNode;
  style?: React.CSSProperties;
  isLoading?: boolean;
  type?: "productOptions" | "reviewStars" | "reviewFull" | "gallery" | "form";
}

const DefaultPlaceholder: React.FC<RenderComponentPlaceholderProps> = (
  props,
) => {
  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",
        borderWidth: 2,
        borderColor: "#5393E9",
        borderStyle: "dashed",
        minHeight: "200px",
        minWidth: "200px",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Helvetica, sans-serif",
        fontSize: "15px",
        whiteSpace: "normal",
        color: "black",
        backgroundColor: "white",
        ...props.style,
      }}
    >
      <>
        {props.icon && props.icon()}
        {props.title && !props.isLoading && (
          <p
            style={{
              textAlign: "center",
              width: "100%",
              padding: 0,
              margin: 0,
            }}
          >
            {props.title}
          </p>
        )}
        {props.isLoading && (
          <p
            style={{
              textAlign: "center",
              width: "100%",
              padding: 0,
              margin: 0,
            }}
          >
            Loading...
          </p>
        )}
      </>
    </div>
  );
};

const PlaceholderAlert: React.FC<RenderComponentPlaceholderProps> = ({
  placeholderTitle,
}) => {
  return (
    <div className="w-full bg-blue-50 rounded-2xl flex gap-2 p-6">
      <div className="grow max-w-fit">
        <BsInfoCircle className="text-blue-600 grow" size={20} />
      </div>
      <div className="text-sm text-blue-600">
        This is a placeholder for the{" "}
        <span className="font-semibold">{placeholderTitle}</span> component.{" "}
        <span className="font-semibold">Publish</span> or{" "}
        <span className="font-semibold">open a preview page</span> to view the
        full component.
      </div>
    </div>
  );
};

const ProductOptionsPlaceholder: React.FC<RenderComponentPlaceholderProps> = ({
  placeholderTitle,
}) => {
  const ProductOptionsPlaceholderItemInactive: React.FC = () => {
    return (
      <div className="w-full r-lg:w-1/3 h-[200px] r-lg:h-[250px] rounded-[20px] border border-slate-300 flex justify-center flex-col gap-4 p-4">
        <div className="w-full grow bg-slate-100 rounded-2xl relative">
          <div className="size-5 rounded-full border-2 border-slate-300 absolute top-4 left-4 flex items-center justify-center">
            <div className="size-3 rounded-full bg-slate-300"></div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-3">
          <div className="w-full h-4 bg-slate-100 rounded-full"></div>
          <div className="w-10/12 h-4 bg-slate-100 rounded-full"></div>
        </div>
      </div>
    );
  };

  const ProductOptionsPlaceholderItemActive: React.FC = () => {
    return (
      <div className="w-full r-lg:w-1/3 h-[200px] r-lg:h-[250px] rounded-[20px] border-2 border-slate-300 flex justify-center flex-col gap-4 p-4">
        <div className="w-full grow bg-slate-300 rounded-2xl relative">
          <div className="size-5 rounded-full border-2 border-slate-600 absolute top-4 left-4 flex items-center justify-center">
            <div className="size-3 rounded-full bg-slate-600"></div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-3">
          <div className="w-full h-4 bg-slate-300 rounded-full"></div>
          <div className="w-10/12 h-4 bg-slate-300 rounded-full"></div>
        </div>
      </div>
    );
  };
  return (
    <div className="w-full flex flex-col gap-4 p-4 r-lg:p-6">
      <div className="w-full flex flex-col gap-4 r-md:flex-row items-center justify-center">
        <ProductOptionsPlaceholderItemInactive />
        <ProductOptionsPlaceholderItemActive />
        <ProductOptionsPlaceholderItemInactive />
      </div>
      <PlaceholderAlert placeholderTitle={placeholderTitle} />
    </div>
  );
};

const ReviewStarsPlaceholder: React.FC<RenderComponentPlaceholderProps> = ({
  placeholderTitle,
}) => {
  return (
    <div className="flex flex-col items-start gap-2">
      <div className="flex gap-2 items-center justify-start text-slate-600 rounded-2xl">
        <div className="text-3xl r-md:text-4xl font-bold">4.9</div>
        <div className="flex r-md:hidden gap-1">
          <BsStarFill size={14} />
          <BsStarFill size={14} />
          <BsStarFill size={14} />
          <BsStarFill size={14} />
          <BsStarHalf size={14} />
        </div>
        <div className="hidden r-md:flex gap-1">
          <BsStarFill size={16} />
          <BsStarFill size={16} />
          <BsStarFill size={16} />
          <BsStarFill size={16} />
          <BsStarHalf size={16} />
        </div>
      </div>
      <div className="bg-blue-50 rounded-lg gap-2 p-2 max-w-sm h-fit flex">
        <div className="grow max-w-fit">
          <BsInfoCircle className="text-blue-600 grow" size={18} />
        </div>
        <div className="text-sm text-blue-600">
          <span className="font-semibold">{placeholderTitle}</span> placeholder.{" "}
        </div>
      </div>
    </div>
  );
};

const ReviewFullPlaceholder: React.FC<RenderComponentPlaceholderProps> = ({
  placeholderTitle,
}) => {
  return (
    <div className="w-full flex flex-col gap-4 p-4 r-lg:p-6">
      <div className="w-full flex flex-col gap-4 items-center justify-center bg-slate-100 text-slate-600 rounded-2xl p-4 r-md:p-6">
        {/* Top Row */}
        <div className="w-full flex justify-between items-center">
          <div className="text-xl font-bold uppercase">Reviews</div>
          <div className="flex gap-2 items-center">
            <div className="text-3xl r-md:text-4xl font-bold">4.9</div>
            <div className="flex flex-col gap-1">
              <div className="flex r-md:hidden gap-1">
                <BsStarFill size={12} />
                <BsStarFill size={12} />
                <BsStarFill size={12} />
                <BsStarFill size={12} />
                <BsStarHalf size={12} />
              </div>
              <div className="hidden r-md:flex gap-1">
                <BsStarFill size={14} />
                <BsStarFill size={14} />
                <BsStarFill size={14} />
                <BsStarFill size={14} />
                <BsStarHalf size={14} />
              </div>
              <span className="text-xs font-semibold">50 reviews</span>
            </div>
          </div>
        </div>
        {/* Divider  */}
        <div className="w-full h-[1px] bg-slate-300"></div>
        {/* Bottom Row */}
        <div className="flex gap-4 w-full">
          <div className="w-[75px] h-[75px] r-md:w-[100px] r-md:h-[100px] bg-slate-300 rounded-2xl"></div>
          <div className="flex flex-col grow gap-4">
            <div className="flex gap-1">
              <BsStarFill size={14} />
              <BsStarFill size={14} />
              <BsStarFill size={14} />
              <BsStarFill size={14} />
              <BsStarHalf size={14} />
            </div>
            <div className="r-md:hidden w-1/2 h-3 bg-slate-300 rounded-full"></div>
            <div className="flex flex-col gap-2">
              <div className="w-full h-3 r-md:h-4 bg-slate-300 rounded-full"></div>
              <div className="w-10/12 h-3 r-md:h-4 bg-slate-300 rounded-full"></div>
            </div>
          </div>
        </div>
      </div>

      <PlaceholderAlert placeholderTitle={placeholderTitle} />
    </div>
  );
};

const GalleryPlaceholder: React.FC<RenderComponentPlaceholderProps> = ({
  placeholderTitle,
}) => {
  return (
    <div className="w-full flex flex-col gap-4 p-4 r-lg:p-6">
      <div className="w-full flex flex-col gap-4 p-4 r-sm:p-6 bg-slate-100 rounded-2xl">
        <div className="w-full flex items-center justify-end r-md:justify-between">
          <div className="w-5/12 hidden r-md:block h-[14px] bg-slate-200 rounded-full"></div>
          <div className="flex items-center justify-center gap-4 w-1/2 r-md:w-4/12">
            <div className="grow h-[14px] bg-slate-200 rounded-full"></div>
            <div className="p-3 rounded-full border border-slate-300 bg-white ">
              <BsChevronRight
                style={{ strokeWidth: 2 }}
                className="text-slate-600"
                size={14}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col r-md:flex-row gap-4">
          <div className="w-full h-[200px] bg-slate-200 rounded-2xl flex items-center justify-center">
            <BsImage className="text-slate-400" size={40} />
          </div>
          <div className="w-full h-[200px] bg-slate-200 rounded-2xl flex items-center justify-center">
            <BsImage className="text-slate-400" size={40} />
          </div>
          <div className="w-full h-[200px] bg-slate-200 rounded-2xl flex items-center justify-center">
            <BsImage className="text-slate-400" size={40} />
          </div>
        </div>
      </div>
      <PlaceholderAlert placeholderTitle={placeholderTitle} />
    </div>
  );
};

const FormPlaceholder: React.FC<RenderComponentPlaceholderProps> = ({
  placeholderTitle,
}) => {
  const FormPlaceholderDesktop: React.FC = () => {
    return (
      <div className="w-full r-md:flex hidden flex-col gap-4 p-10 bg-slate-100 rounded-2xl border border-slate-300">
        <div className="w-full flex gap-4">
          <div className="w-full h-[50px] bg-slate-200 rounded-2xl"></div>
          <div className="w-full h-[50px] bg-slate-200 rounded-2xl"></div>
        </div>
        <div className="w-full h-[120px] bg-slate-200 rounded-2xl"></div>
        <div className="w-[150px] h-[48px] bg-slate-600 rounded-lg font-semibold text-xl ml-auto text-white flex items-center justify-center">
          Submit
        </div>
      </div>
    );
  };

  const FormPlaceholderMobile: React.FC = () => {
    return (
      <div className="w-full r-md:hidden flex flex-col gap-4 p-4 r-sm:p-6 bg-slate-100 rounded-2xl border border-slate-300">
        <div className="w-full flex flex-col gap-2">
          <div className="w-[36%] h-[12px] bg-slate-200 rounded-full"></div>
          <div className="w-full h-[40px] bg-slate-200 rounded-xl"></div>
        </div>
        <div className="w-full flex flex-col gap-2">
          <div className="w-[30%] h-[12px] bg-slate-200 rounded-full"></div>
          <div className="w-full h-[40px] bg-slate-200 rounded-xl"></div>
        </div>
        <div className="w-full flex flex-col gap-2">
          <div className="w-[42%] h-[12px] bg-slate-200 rounded-full"></div>
          <div className="w-full h-[150px] bg-slate-200 rounded-xl"></div>
        </div>
        <div className="w-full h-[48px] bg-slate-600 rounded-lg font-semibold text-xl ml-auto text-white flex items-center justify-center">
          Submit
        </div>
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col gap-4 p-4 r-lg:p-6">
      <FormPlaceholderDesktop />
      <FormPlaceholderMobile />
      <PlaceholderAlert placeholderTitle={placeholderTitle} />
    </div>
  );
};

export const RenderComponentPlaceholder: React.FC<
  RenderComponentPlaceholderProps
> = (props) => {
  const { type, ...restProps } = props;

  // NOTE (Jackson, 2024-12-16): If no-mirror isn't enabled, we want to defer to existing
  // placeholder logic
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const featureFlagsContext = useRuntimeContext(FeatureFlagsContext);
  const isNoMirrorEnabled =
    isEditorApp && featureFlagsContext.featureFlags.noMirror;

  const checkedType = !isNoMirrorEnabled ? undefined : type;

  switch (checkedType) {
    case "productOptions":
      return <ProductOptionsPlaceholder {...restProps} />;
    case "reviewStars":
      return <ReviewStarsPlaceholder {...restProps} />;
    case "reviewFull":
      return <ReviewFullPlaceholder {...restProps} />;
    case "gallery":
      return <GalleryPlaceholder {...restProps} />;
    case "form":
      return <FormPlaceholder {...restProps} />;
    default:
      return <DefaultPlaceholder {...restProps} />;
  }
};

export default RenderComponentPlaceholder;
