// eslint-disable-next-line no-restricted-imports
import { extendTailwindMerge } from "tailwind-merge";

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      h: ["h-small", "h-base", "h-large"],
    },
    theme: {
      padding: ["none", "small", "base", "large"],
    },
  },
});

export default twMerge;
