import type { RemoveShopifyIntegrationModalProps } from "../AppModalTypes";

import * as React from "react";

import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import toast from "@editor/components/common/designSystem/Toast";
import { useModal } from "@editor/hooks/useModal";
import { publisherApi } from "@editor/reducers/api-reducer";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import { useDispatch } from "react-redux";

export const RemoveShopifyIntegrationModal: React.FC<
  RemoveShopifyIntegrationModalProps
> = ({ projectId, integrationId }) => {
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const { mutate: disconnectIntegration, isPending: isLoading } =
    trpc.integration.disconnectIntegrationFromProject.useMutation({
      onSuccess: () => {
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        void trpcUtils.project.findByUserId.invalidate();
        void trpcUtils.project.get.invalidate(projectId);
        // TODO (Sebas, 2024-07-12): Remove this when the migration to TRPC is complete
        // and invalidate tags from TRPC.
        dispatch(publisherApi.util.invalidateTags(["workspaces", "projects"]));
        toast({
          header: "Shopify Integration removed from Project",
        });
      },
      onSettled: () => {
        closeModal({ type: "removeShopifyIntegrationModal" });
      },
    });

  const handleConfirm = () => {
    disconnectIntegration({ projectId, integrationId });
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        closeModal({ type: "removeShopifyIntegrationModal" });
      }}
      className="w-auto"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        mainContent={() => (
          <div className="flex flex-col gap-6 p-2 w-full">
            <h2 className="text-xl font-medium">Remove Shopify Integration</h2>
            <p className="text-sm text-default">
              Removing the Shopify integration from this project will disconnect
              all dynamic data, and you won’t be able to publish new changes
              until you install the Shopify integration again.
            </p>
            <div className="flex gap-2 self-end">
              <Button
                variant="secondary"
                size="base"
                className="text-xs"
                onClick={() =>
                  closeModal({ type: "removeShopifyIntegrationModal" })
                }
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                size="base"
                className="text-xs"
                type="submit"
                isLoading={isLoading}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
      />
    </Modal>
  );
};
