import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type {
  ModifierType,
  ModifierTypeToControlType,
} from "schemas/modifiers";
import type {
  RuntimeStyleAttribute,
  SupportedCssProperties,
} from "schemas/styleAttribute";

import {
  selectAlignSelf,
  selectBackgroundColor,
  selectBackgroundImage,
  selectBoxShadow,
  selectColor,
  selectCursor,
  selectFlexDirection,
  selectFontFamily,
  selectFontSize,
  selectFontWeight,
  selectLetterSpacing,
  selectLineHeight,
  selectMaxHeight,
  selectMaxWidth,
  selectMinHeight,
  selectMinWidth,
  selectOpacity,
  selectOverflow,
  selectPosition,
  selectTextAlign,
  selectTextDecoration,
  selectTextOutline,
  selectTextShadow,
  selectTextTransform,
  selectTransform,
  selectTransformOrigin,
  selectZIndex,
} from "@editor/reducers/core-reducer";
import { getReadableTransformValue } from "@editor/utils/effects";
import {
  getDefaultControls,
  ModifierTypeToControls,
} from "@editor/utils/modifiers";

import { createSelector } from "@reduxjs/toolkit";
import isArray from "lodash-es/isArray";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { getTransformStyleString } from "replo-runtime/shared/utils/transform";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import { filterNulls } from "replo-utils/lib/array";
import { styleAttributeToDefaultStyle } from "schemas/styleAttribute";

const selectSizeModifierControlsToRender = createSelector(
  selectMinWidth,
  selectMinHeight,
  selectMaxWidth,
  selectMaxHeight,
  selectAlignSelf,
  (minWidth, minHeight, maxWidth, maxHeight, alignSelf) => {
    const defaultControls = getDefaultControls<"size">(
      ModifierTypeToControls["size"],
    );
    const properties: {
      [key in keyof SupportedCssProperties]?:
        | string
        | number
        | ReploMixedStyleValue;
    } = {
      minWidth: minWidth ?? undefined,
      minHeight: minHeight ?? undefined,
      maxWidth: maxWidth ?? undefined,
      maxHeight: maxHeight ?? undefined,
      alignSelf: alignSelf ?? undefined,
    };
    const controlsToRender =
      // NOTE (Sebas, 2024-09-10): We need to cast to (keyof SupportedCssProperties)[]
      // because TypeScript cannot infer the type of keys from the object.
      (Object.keys(properties) as (keyof SupportedCssProperties)[]).filter(
        (key) => {
          const value = properties[key];
          return (
            (!isMixedStyleValue(value) && !isDefaultValue(value, key)) ||
            isMixedStyleValue(value)
          );
        },
      );

    return new Set([...defaultControls, ...controlsToRender]);
  },
);

export const selectTextModifierControlsToRender = createSelector(
  selectTextDecoration,
  selectTextTransform,
  selectTextOutline,
  selectTextShadow,
  (textDecoration, textTransform, textOutline, textShadow) => {
    const defaultControls = getDefaultControls<"text">(
      ModifierTypeToControls["text"],
    );

    if (
      (!isMixedStyleValue(textDecoration) &&
        !isDefaultValue(textDecoration, "textDecoration")) ||
      isMixedStyleValue(textDecoration)
    ) {
      defaultControls.add("textDecoration");
    }

    if (
      (!isMixedStyleValue(textTransform) &&
        !isDefaultValue(textTransform, "textTransform")) ||
      isMixedStyleValue(textTransform)
    ) {
      defaultControls.add("textTransform");
    }

    // NOTE (Sebas, 2025-01-16): We need to filter nulls because the textOutline
    // can be an array with null/undefined values.
    const filteredTextOutline = isArray(textOutline)
      ? filterNulls(textOutline)
      : [];
    if (
      (!filteredTextOutline.some(isMixedStyleValue) &&
        !isDefaultValue(
          `${textOutline[0] ?? "0"} ${textOutline[1] ?? "currentcolor"}`,
          "__textStroke",
        )) ||
      (isArray(textOutline) && textOutline.some(isMixedStyleValue))
    ) {
      defaultControls.add("textOutline");
    }

    if (
      (!isMixedStyleValue(textShadow) &&
        !isDefaultValue(textShadow, "textShadow")) ||
      isMixedStyleValue(textShadow)
    ) {
      defaultControls.add("textShadow");
    }

    return defaultControls;
  },
);

export const selectTextModifierValuesWithSavedStylesReferences = createSelector(
  selectFontSize,
  selectFontWeight,
  selectFontFamily,
  selectLineHeight,
  selectLetterSpacing,
  selectTextDecoration,
  selectTextTransform,
  selectTextAlign,
  selectTextShadow,
  selectColor,
  (
    fontSize,
    fontWeight,
    fontFamily,
    lineHeight,
    letterSpacing,
    textDecoration,
    textTransform,
    textAlign,
    textShadow,
    color,
  ) => {
    const modifierValues = {
      fontSize,
      fontWeight,
      fontFamily,
      lineHeight,
      letterSpacing,
      textDecoration,
      textTransform,
      textAlign,
      textShadow,
      color,
    };

    return Object.fromEntries(
      Object.entries(modifierValues).filter(([_, value]) => {
        return isDynamicDesignLibraryValue(String(value) ?? "");
      }),
    );
  },
);

export const selectPositionModifierControlsToRender = createSelector(
  selectPosition,
  selectZIndex,
  (position, zIndex) => {
    const defaultControls = getDefaultControls<"position">(
      ModifierTypeToControls["position"],
    );
    if (
      (!isMixedStyleValue(position) && !isDefaultValue(position, "position")) ||
      isMixedStyleValue(position)
    ) {
      defaultControls.add("position");
    }
    if (
      (!isMixedStyleValue(zIndex) && !isDefaultValue(zIndex, "zIndex")) ||
      isMixedStyleValue(zIndex)
    ) {
      defaultControls.add("zIndex");
    }
    return defaultControls;
  },
);

const selectLayoutModifierControlsToRender = createSelector(
  selectFlexDirection,
  selectOverflow,
  (flexDirection, overflow) => {
    const defaultControls = getDefaultControls<"layout">(
      ModifierTypeToControls["layout"],
    );
    const [overflowX, overflowY] = !isMixedStyleValue(overflow)
      ? overflow?.split(" ") ?? []
      : [];

    if (
      (!isMixedStyleValue(flexDirection) &&
        !isDefaultValue(flexDirection, "flexDirection")) ||
      isMixedStyleValue(flexDirection)
    ) {
      defaultControls.add("order");
    }
    if (
      (!isMixedStyleValue(overflowX) &&
        !isDefaultValue(overflowX, "overflowX")) ||
      isMixedStyleValue(overflowX)
    ) {
      defaultControls.add("overflowX");
    }
    if (
      (!isMixedStyleValue(overflowY) &&
        !isDefaultValue(overflowY, "overflowY")) ||
      isMixedStyleValue(overflowY)
    ) {
      defaultControls.add("overflowY");
    }

    return defaultControls;
  },
);

const selectBackgroundModifierControlsToRender = createSelector(
  selectBackgroundColor,
  selectBackgroundImage,
  (backgroundColor, backgroundImage) => {
    const controls = new Set<ModifierTypeToControlType["background"]>();
    if (
      (!isMixedStyleValue(backgroundColor) &&
        !isDefaultValue(backgroundColor, "backgroundColor")) ||
      isMixedStyleValue(backgroundColor)
    ) {
      controls.add("backgroundColor");
    }
    if (
      (!isMixedStyleValue(backgroundImage) &&
        !isDefaultValue(backgroundImage, "backgroundImage")) ||
      isMixedStyleValue(backgroundImage)
    ) {
      controls.add("backgroundImage");
    }

    return controls;
  },
);

const selectEffectsModifierControlsToRender = createSelector(
  selectTransform,
  selectTransformOrigin,
  selectCursor,
  selectOpacity,
  selectBoxShadow,
  (transform, transformOrigin, cursor, opacity, boxShadow) => {
    const controls = new Set<ModifierTypeToControlType["effects"]>();

    // NOTE (Sebas/Fran 2024-10-11): We can't rely on `transform` from selector because for some
    // transform can be defined with default values even if there is no transform applied.
    // TODO (Fran 2024-10-11 REPL-14081): Investigate why the transform could still exist in the
    // component json even if there is no transform applied
    const transformValue = transform
      ? getReadableTransformValue(getTransformStyleString(transform))
      : null;

    if (
      (!isMixedStyleValue(transform) &&
        !isDefaultValue(transformValue, "transform")) ||
      isMixedStyleValue(transform)
    ) {
      controls.add("transform");
    }
    if (
      (!isMixedStyleValue(transformOrigin) &&
        !isDefaultValue(transformOrigin, "transformOrigin")) ||
      isMixedStyleValue(transformOrigin)
    ) {
      controls.add("transformOrigin");
    }
    if (
      (!isMixedStyleValue(cursor) &&
        !isDefaultValue(cursor, "cursor") &&
        cursor !== "default") ||
      isMixedStyleValue(cursor)
    ) {
      controls.add("cursor");
    }
    if (
      (!isMixedStyleValue(opacity) && !isDefaultValue(opacity, "opacity")) ||
      isMixedStyleValue(opacity)
    ) {
      controls.add("opacity");
    }
    if (
      (!isMixedStyleValue(boxShadow) &&
        !isDefaultValue(boxShadow, "boxShadow")) ||
      isMixedStyleValue(boxShadow)
    ) {
      controls.add("boxShadow");
    }

    return controls;
  },
);

const isDefaultValue = (
  value: string | number | null | undefined,
  cssProperty: RuntimeStyleAttribute,
) => {
  // Note (Sebas, 2022-10-12): In case we set "alignSelf: stretch" with
  // the width or height toggle group (depends on the flex direction), we
  // return 'auto' to prevent showing the alignment toggle group.
  const isDefaultByAlignSelf =
    value === "stretch" && cssProperty === "alignSelf";

  const defaultStyleValue = styleAttributeToDefaultStyle[cssProperty];

  return (
    !value ||
    String(value) === String(defaultStyleValue) ||
    isDefaultByAlignSelf
  );
};

export const modifierTypeToSelector: Record<ModifierType, any> = {
  position: selectPositionModifierControlsToRender,
  size: selectSizeModifierControlsToRender,
  layout: selectLayoutModifierControlsToRender,
  text: selectTextModifierControlsToRender,
  background: selectBackgroundModifierControlsToRender,
  effects: selectEffectsModifierControlsToRender,
};
