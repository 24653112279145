import type { ExperimentEditAction } from "@/features/experiments/contexts/ExperimentEditContext";
import type { Experiment } from "schemas/generated/experiment";
import type { Variation } from "schemas/generated/variation";

import * as React from "react";

import { Loader } from "@editor/components/common/Loader";

import { ExperimentEditContext } from "@/features/experiments/contexts/ExperimentEditContext";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Outlet } from "react-router-dom";
import { exhaustiveSwitchGeneric } from "replo-utils/lib/misc";
import { experimentSchema } from "schemas/experiment";
import { v4 as uuid } from "uuid";

import { positiveIntToCapitalLetter } from "./utils";

// NOTE (Kurt, 2024-12-03): This function calculates the allocation percentages
// for the variations in an experiment. It ensures that the total percentage
// is 100% and that the allocation percentages are distributed evenly, with
// any remainder added to the first variation.
function calculateVariationPercentages(variations: Variation[]) {
  const totalVariations = variations.length;
  const basePercentage = Math.floor(100 / totalVariations);
  const remainder = 100 % totalVariations;

  return variations.map((variation, index) => ({
    ...variation,
    allocationPercent:
      index === 0 ? basePercentage + remainder : basePercentage,
  }));
}

const LoadedExperimentEditWrapper: React.FC<{
  experiment: Experiment;
}> = ({ experiment }) => {
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty, errors, isValid },
  } = useForm<Experiment>({
    resolver: zodResolver(experimentSchema),
    defaultValues: experiment,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const handleSetValue = (
    key: keyof Experiment,
    value: Experiment[keyof Experiment],
  ) => {
    setValue(key, value, { shouldDirty: true, shouldValidate: true });
  };

  const dispatchExperimentEdit = (action: ExperimentEditAction) => {
    const variations = watch("variations");
    exhaustiveSwitchGeneric(
      action,
      "type",
    )({
      addVariation: () => {
        let nextSlug = `Variant-${positiveIntToCapitalLetter(variations.length + 1).toUpperCase()}`;
        if (variations.find((v) => v.slug === nextSlug)) {
          nextSlug = uuid().slice(0, 8);
        }

        const newVariation = {
          id: uuid(),
          target: null,
          slug: nextSlug,
          allocationPercent: 0,
        };

        const updatedVariations = calculateVariationPercentages([
          ...variations,
          newVariation,
        ]);

        handleSetValue("variations", updatedVariations);
      },
      setEqualVariationSplit: () => {
        const updatedVariations = calculateVariationPercentages(variations);
        handleSetValue("variations", updatedVariations);
      },
      changeVariation: (action) => {
        handleSetValue(
          "variations",
          variations.map((v) =>
            v.id === action.payload.id ? { ...v, ...action.payload } : v,
          ),
        );
      },
      removeVariation: (action) => {
        const remainingVariations = variations.filter(
          (variation) => variation.id !== action.payload,
        );

        const updatedVariations =
          calculateVariationPercentages(remainingVariations);

        handleSetValue("variations", updatedVariations);
      },
      changeProperty: (action) => {
        const { key, value } = action.payload;
        handleSetValue(key, value);
      },
    });
  };

  return (
    <ExperimentEditContext.Provider
      value={{
        watch,
        dispatchExperimentEdit,
        hasDataChanged: isDirty,
        isExperimentValid: isValid,
        handleReactHookFormSubmit: handleSubmit,
        errors,
      }}
    >
      <Outlet />
    </ExperimentEditContext.Provider>
  );
};

const ExperimentEditWrapper: React.FC = () => {
  const { isLoadingExperiment, experiment } = useExperimentDetails();

  if (isLoadingExperiment || !experiment) {
    return <Loader />;
  }

  return <LoadedExperimentEditWrapper experiment={experiment} />;
};

export default ExperimentEditWrapper;
