import type { Option } from "@editorComponents/Lists";
import type {
  ReploShopifyVariant,
  ShopifySellingPlan,
  StoreProduct,
} from "replo-runtime/shared/types";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type {
  Component,
  CustomComponentPropType,
  CustomPropDefinition,
} from "schemas/component";
import type {
  ContextRef,
  ProductRef,
  ProductRefOrDynamic,
  SelectedSellingPlanIdOrOneTimePurchase,
} from "schemas/product";

import * as React from "react";

import ButtonGroupComponent from "@common/designSystem/ButtonGroup";
import { Input } from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import { ProductSelector } from "@common/designSystem/ProductSelector";
import Selectable from "@common/designSystem/Selectable";
import SelectionIndicator from "@common/designSystem/SelectionIndicator";
import Switch from "@common/designSystem/Switch";
import Textarea from "@common/designSystem/Textarea";
import AnyDynamicDataValueSelector from "@components/editor/customProp/AnyDynamicDataValueSelector";
import { FieldWithDescription } from "@components/editor/customProp/FieldWithDescription";
import { ImageCustomPropModifier } from "@components/editor/customProp/ImageCustomPropModifier";
import { OptionsCustomPropModifier } from "@components/editor/customProp/OptionsCustomPropModifier";
import { ProductsCustomPropModifier } from "@components/editor/customProp/ProductsCustomPropModifier";
import { SwatchesCustomPropModifier } from "@components/editor/customProp/SwatchesCustomPropModifier";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { CodeEditorCustomPropModifier } from "@editor/components/editor/customProp/CodeEditorCustomPropModifier";
import { SUB_APP_APPID_TO_NAME } from "@editor/constants/sub-app-appid-to-name";
import { useModal } from "@editor/hooks/useModal";
import {
  selectProjectId,
  selectStoreShopifyUrl,
} from "@editor/reducers/core-reducer";
import { selectThemeId } from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DraggingTypes } from "@editor/utils/editor";
import { trpc } from "@editor/utils/trpc";
import SelectablePopover from "@editorComponents/SelectablePopover";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import {
  CombinedItemsSelector,
  ItemsSelector,
} from "@editorModifiers/ItemsModifier";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";
import Stepper from "@editorModifiers/Stepper";

import { Badge } from "@replo/design-system/components/badge";
import { skipToken } from "@tanstack/react-query";
import startCase from "lodash-es/startCase";
import truncate from "lodash-es/truncate";
import { BsFillBagPlusFill } from "react-icons/bs";
import { FiDatabase, FiMinus, FiPlus } from "react-icons/fi";
import { RiCloseFill } from "react-icons/ri";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import {
  DEFAULT_ACTIVE_CURRENCY,
  DEFAULT_ACTIVE_LANGUAGE,
  DEFAULT_MONEY_FORMAT,
} from "replo-runtime/shared/liquid";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { getProduct } from "replo-runtime/store/ReploProduct";
import { isNotNullish } from "replo-utils/lib/misc";
import { isBoolean } from "replo-utils/lib/type-check";
import { ItemsConfigType } from "schemas/dynamicData";
import { ReploError } from "schemas/errors";

import SellingPlanSelector from "./actions/SellingPlanSelector";
import { IntegerSelector } from "./customProp/IntegerSelector";
import { ConnectShopifyCallout } from "./page/ConnectShopifyCallout";
import SolidColorSelector from "./page/element-editor/components/SolidColorSelector";

class InvalidDateValueError extends ReploError {
  constructor(value: string) {
    super({
      message: "Invalid value returned from date picker input",
      additionalData: { value },
    });
  }
}

// https://webreflection.medium.com/using-the-input-datetime-local-9503e7efdce
function toDatetimeLocal(date: Date) {
  const ten = function (i: number) {
      return (i < 10 ? "0" : "") + i;
    },
    YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate()),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes()),
    SS = ten(date.getSeconds());
  return `${YYYY}-${MM}-${DD}T${HH}:${II}:${SS}`;
}

export type CustomComponentPropRenderProps = {
  component: Component;
  value: any;
  defaultValue?: any;
  customPropId: string;
  description: string;
  onChange(value: any): void;
  onDelete(): void;
  attributeName: string;
  options?: any;
  isEnabled: boolean;
  disabledDescription?: string;
  disableDynamicData?: boolean;
  definition: CustomPropDefinition;
  context: Context | null;
  products: StoreProduct[];
  templateProduct: StoreProduct | null;
  placeholder?: string;
};

type ComponentPropTypeEditorData = {
  render(renderProps: CustomComponentPropRenderProps): React.ReactNode;
  shouldRender(component: Component, context?: Context): boolean;
  defaultValue: any;
  hasCustomWrapper?: boolean;
};

/**
 * A product selector, but if the value is null, it displays as a dynamic data indicator.
 *
 * Useful for cases like Variant Select, where we want to take the product from the context
 * by default, but allow the user to override it if they want.
 */
const ProductSelectorDefaultingToContextValue: React.FC<{
  description: string;
  value: ProductRefOrDynamic | null;
  onChange: (value: ProductRefOrDynamic | null) => void;
}> = ({ description, value, onChange }) => {
  const [isShowingEmptyInput, setIsShowingEmptyInput] = useOverridableState(
    Boolean(value),
  );
  return (
    <FieldWithDescription description={description}>
      {isShowingEmptyInput || value ? (
        <ProductSelector
          selectedProductRef={value}
          onChange={onChange}
          allowDynamicData
          isMultiProducts={false}
        />
      ) : (
        <SelectionIndicator
          startEnhancer={
            <Badge
              type="icon"
              icon={<FiDatabase size={10} className="text-slate-50" />}
              isFilled
              className="bg-accent"
            />
          }
          title="Current Product"
          endEnhancer={
            <div
              className="h-3 w-3 cursor-pointer text-gray-400"
              onClick={() => {
                setIsShowingEmptyInput(true);
              }}
            >
              <RiCloseFill size={12} />
            </div>
          }
        />
      )}
    </FieldWithDescription>
  );
};

export const getCustomComponentPropTypeEditorData = (
  pt: CustomComponentPropType,
): ComponentPropTypeEditorData => {
  return customComponentPropTypeToEditorData[pt]!;
};

export const customComponentPropTypeToEditorData: Record<
  CustomComponentPropType,
  ComponentPropTypeEditorData | null
> = {
  component: {
    render: () => null,
    shouldRender: () => false,
    defaultValue: null,
  },
  timeInterval: {
    render: ({
      value,
      description,
      onChange,
      isEnabled,
      disabledDescription,
    }) => {
      const { minutes, hours, seconds, days } = value as {
        minutes: number;
        hours: number;
        seconds: number;
        days: number;
      };
      return (
        <FieldWithDescription
          description={
            isEnabled ? description : disabledDescription ?? description
          }
        >
          <LabeledControl label="Seconds" size="sm">
            <Stepper
              value={seconds}
              field="seconds"
              placeholder="0"
              onChange={(newSeconds) => {
                onChange({
                  ...value,
                  seconds: newSeconds,
                });
              }}
            />
          </LabeledControl>
          <LabeledControl label="Minutes" size="sm">
            <Stepper
              value={minutes}
              field="minutes"
              placeholder="0"
              onChange={(newMinutes) => {
                onChange({
                  ...value,
                  minutes: newMinutes,
                });
              }}
            />
          </LabeledControl>
          <LabeledControl label="Hours" size="sm">
            <Stepper
              value={hours}
              field="hours"
              placeholder="0"
              onChange={(newHours) => {
                onChange({
                  ...value,
                  hours: newHours,
                });
              }}
            />
          </LabeledControl>
          <LabeledControl label="Days" size="sm">
            <Stepper
              value={days}
              field="days"
              placeholder="0"
              onChange={(newDays) => {
                onChange({
                  ...value,
                  days: newDays,
                });
              }}
            />
          </LabeledControl>
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  boolean: {
    render: ({
      value,
      description,
      onChange,
      isEnabled,
      disabledDescription,
      definition,
    }) => {
      let displayedValue = Boolean(value);
      if (!isEnabled && !definition.preserveValueWhenDisabled) {
        displayedValue = Boolean(definition.defaultValue);
      }

      return (
        <div className="flex flex-row justify-between">
          <span className="w-4/5 text-xs text-slate-400">
            {isEnabled ? description : disabledDescription ?? description}
          </span>

          <Switch
            isDisabled={!isEnabled}
            isOn={displayedValue}
            backgroundOnColor="bg-blue-600"
            thumbColor="#fff"
            onChange={onChange}
          />
        </div>
      );
    },
    shouldRender: () => true,
    defaultValue: false,
  },
  product_variant: {
    render: ({ value, description, options, onChange }) => {
      const selectedVariant = options.find(
        (v: Option) => v.value === value,
      )?.label;
      return (
        <FieldWithDescription description={description}>
          <SelectablePopover
            title="Variants"
            itemSize={40}
            itemsOnViewCount={5}
            options={options}
            isRemovable={Boolean(selectedVariant)}
            onSelect={(newSelectedVariantId: number) => {
              onChange(newSelectedVariantId);
            }}
          >
            {/* NOTE (Matt 2024-03-27): we are not using the SelectionIndicator component
                because the SelectablePopover component holds the logic for whether or not
                the user's selection is valid, then applies a background color for an error
                state. If we use the SelectionIndicator, we can't inherit that background
                color without moving the validity state to an inopportune component.
            */}
            <span className="text-slate-600 h-6 p-1">
              {selectedVariant ? selectedVariant : "Select a Variant"}
            </span>
          </SelectablePopover>
        </FieldWithDescription>
      );
    },
    shouldRender: (component) => {
      return (
        Boolean(component.props._autoSelectVariant ?? true) &&
        // Note (Ovishek, 2022-11-08): If _product a context value that means a dynamic product
        // we can fix auto selected variant that's why we ignore that here. It works b/c productId can be only found
        // in a product ref.
        Boolean((component.props._product as ProductRef)?.productId)
      );
    },
    defaultValue: null,
  },
  productVariants: {
    render: ({ value, description, context, onChange }) => {
      const options = context?.attributes?._variants?.map(
        (variant: ReploShopifyVariant) => {
          return {
            label: truncate(variant.name, { length: 60 }),
            value: variant.id,
            isSelectable: true,
            isDefaultActive: !value || value.includes(variant.id),
          } as Option;
        },
      );
      if (options) {
        const allSelected = !value || value.length >= options.length;
        return (
          <FieldWithDescription description={description}>
            <SelectablePopover
              title="Variants"
              itemSize={40}
              itemsOnViewCount={5}
              options={options}
              isMultiselect
              allowSelectAll
              isRemovable={false}
              onSelect={(newValues) => {
                if (
                  Array.isArray(newValues) &&
                  newValues.length === options.length
                ) {
                  onChange(null);
                } else {
                  onChange(newValues);
                }
              }}
              placeholder="Select Variant"
              startEnhancer={
                <Badge
                  type="icon"
                  icon={
                    <BsFillBagPlusFill size={10} className="text-slate-50" />
                  }
                  className="bg-accent"
                />
              }
            >
              {/* NOTE (Matt 2024-03-27): we are not using the SelectionIndicator component
                because the SelectablePopover component holds the logic for whether or not
                the user's selection is valid, then applies a background color for an error
                state. If we use the SelectionIndicator, we can't inherit that background
                color without moving the validity state to an inopportune component.
              */}
              {allSelected
                ? "All Variants"
                : `${value.length} Variant${value.length !== 1 ? "s" : ""}`}
            </SelectablePopover>
          </FieldWithDescription>
        );
      }
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  productSellingPlan: {
    shouldRender: (component) => {
      return isNotNullish(component.props._product);
    },
    render: ({
      value,
      description,
      onChange,
      context,
      component,
      products,
      templateProduct,
    }) => {
      const product = getProduct(component.props._product ?? null, context, {
        productMetafieldValues: {},
        variantMetafieldValues: {},
        products,
        currencyCode: DEFAULT_ACTIVE_CURRENCY,
        language: DEFAULT_ACTIVE_LANGUAGE,
        moneyFormat: DEFAULT_MONEY_FORMAT,
        templateProduct,
        isEditor: true,
        isShopifyProductsLoading: false,
      });

      if (!product) {
        return null;
      }

      const allowAllPlans =
        !component.props._defaultSelectedVariantId ||
        !(
          isBoolean(component.props._autoSelectVariant) &&
          component.props._autoSelectVariant
        );

      return (
        <FieldWithDescription description={description}>
          <SellingPlanSelector
            product={product}
            allowAllPlans={allowAllPlans}
            selectedSellingPlanId={
              value as SelectedSellingPlanIdOrOneTimePurchase | null
            }
            onChange={onChange}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: null,
  },
  date: {
    render: ({ value, description, onChange }) => {
      const timeFormat =
        value != null ? toDatetimeLocal(new Date(value)) : null;
      return (
        <div className="flex flex-col gap-2">
          <span className="w-4/5 text-xs text-slate-400">{description}</span>
          <input
            value={timeFormat ?? undefined}
            type="datetime-local"
            className="text-xs"
            onChange={(e) => {
              try {
                onChange(new Date(e.target.value).toISOString());
              } catch (error) {
                if (error instanceof ReferenceError) {
                  throw new InvalidDateValueError(e.target.value);
                }
                throw error;
              }
            }}
          />
        </div>
      );
    },
    shouldRender: () => true,
    defaultValue: false,
  },
  product: {
    render: ({ value, description, onChange }) => {
      return (
        <ProductsCustomPropModifier
          onChange={(value) => onChange(value)}
          products={value}
          allowDynamicData
          description={description}
          isMultiProducts={false}
        />
      );
    },
    defaultValue: false,
    shouldRender: () => {
      return true;
    },
    hasCustomWrapper: true,
  },
  productFromPropsOrFromContext: {
    render: ({ value, description, onChange }) => {
      return (
        <ProductSelectorDefaultingToContextValue
          description={description}
          onChange={onChange}
          value={value}
        />
      );
    },
    defaultValue: false,
    shouldRender: () => {
      return true;
    },
  },
  products: {
    render: ({ value, onChange }) => {
      return (
        <ProductsCustomPropModifier
          isMultiProducts={true}
          onChange={(productRef) => onChange(productRef)}
          products={value}
          allowDynamicData={false}
        />
      );
    },
    defaultValue: false,
    shouldRender: () => true,
    hasCustomWrapper: true,
  },
  string: {
    render: ({ value, description, onChange, disableDynamicData }) => {
      return (
        <FieldWithDescription description={description}>
          <InputWithDynamicData
            onChange={onChange}
            value={value}
            disableDynamicData={disableDynamicData}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: "",
    shouldRender: () => true,
  },
  multiline: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <Textarea
            debounce
            size="sm"
            className="w-full text-xs"
            value={value}
            onChange={(value) => onChange(value)}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: "",
    shouldRender: () => true,
  },
  float: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <Input
            value={value}
            onChange={(e) => {
              const float = Number.parseFloat(e.target.value);
              if (!Number.isNaN(float)) {
                onChange(e.target.value);
              }
            }}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  integer: {
    render: ({
      value,
      description,
      onChange,
      isEnabled,
      disabledDescription,
      definition,
    }) => {
      return (
        <FieldWithDescription
          description={
            isEnabled ? description : disabledDescription ?? description
          }
        >
          <IntegerSelector
            min={definition.min}
            value={Number.parseInt(String(value))}
            onChange={(newQuantity) => {
              onChange(newQuantity);
            }}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  color: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <SolidColorSelector
            value={value}
            onChange={onChange}
            // NOTE (Fran 2024-12-26): This is the offset of the popover from the trigger is the result
            // of the spacing between the badge and the input and the edge of the panel.
            popoverSideOffset={14}
            showSavedStyles
            onSelectSavedStyle={onChange}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  ratingBalancer: {
    render: ({ value, description, onChange }) => {
      const defaultValue = 0;
      const onSubmit = () => {
        if (!value) {
          onChange(defaultValue);
          return;
        }
        const balancedValue = Math.ceil(value * 2) / 2;
        onChange(balancedValue);
      };
      return (
        <FieldWithDescription description={description}>
          <Input
            value={value}
            onBlur={onSubmit}
            onEnter={onSubmit}
            onChange={(e) => {
              const float = Number.parseFloat(e.target.value || "0");
              if (!Number.isNaN(float)) {
                onChange(e.target.value);
              }
            }}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  pixels: {
    render: ({ value, description, onChange, defaultValue }) => {
      return (
        <FieldWithDescription description={description}>
          <LengthInputSelector
            value={value}
            minDragValues={{ all: 0 }}
            placeholder={defaultValue ?? "16px"}
            field="customProp"
            onChange={(value) => onChange(value)}
            allowsNegativeValue={false}
            resetValue={defaultValue ?? "16px"}
            anchorValue={defaultValue ?? "16px"}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: 0,
    shouldRender: () => true,
  },
  pixelsIncludingNegativeValues: {
    render: ({ value, description, onChange, defaultValue }) => (
      <FieldWithDescription description={description}>
        <LengthInputSelector
          value={value}
          placeholder={defaultValue}
          field="customProp"
          onChange={(value) => onChange(value)}
          resetValue={defaultValue}
          anchorValue={defaultValue}
        />
      </FieldWithDescription>
    ),
    defaultValue: 0,
    shouldRender: () => true,
  },
  hashmark: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <div className="flex flex-row items-center">
            <div className="flex-1">
              <HashmarkInput
                value={String(value ?? "")}
                onValueChange={onChange}
              />
            </div>
          </div>
        </FieldWithDescription>
      );
    },
    defaultValue: "",
    shouldRender: () => true,
  },
  location: null,
  collection: null,
  image: {
    render: ({ value, customPropId, description, definition }) => {
      return (
        <div className="flex flex-col justify-between">
          <span className="text-xs text-slate-400">{description}</span>
          <div className="mt-2">
            <ImageCustomPropModifier
              value={value}
              customPropId={customPropId}
              definition={definition}
            />
          </div>
        </div>
      );
    },
    shouldRender: () => true,
    defaultValue: false,
  },
  inlineItems: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <ItemsSelector
            allowsDynamicDataWhenAvailable={false}
            allowedItemTypes={[ItemsConfigType.inline]}
            value={value}
            onChange={(value) => {
              onChange(value);
            }}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  htmlAttributes: {
    render: ({ value, description, onChange, onDelete }) => {
      return (
        <FieldWithDescription description={description}>
          <ItemsSelector
            allowsDynamicDataWhenAvailable
            allowedItemTypes={[ItemsConfigType.htmlAttributes]}
            value={value}
            onChange={onChange}
            onDelete={onDelete}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  dynamicItems: {
    render: ({ value, description, onChange, onDelete }) => {
      return (
        <FieldWithDescription description={description}>
          <CombinedItemsSelector
            value={value}
            onChange={onChange}
            onDelete={() => onDelete()}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  anyDynamicValue: {
    render: ({
      value,
      description,
      onChange,
      onDelete,
    }: Omit<CustomComponentPropRenderProps, "value"> & {
      value: string | ContextRef | null;
    }) => {
      return (
        <FieldWithDescription description={description}>
          <AnyDynamicDataValueSelector
            dynamicDataTemplate={value}
            onChange={onChange as (value: string) => void}
            onDelete={onDelete}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  seconds: {
    render: ({ value, description, onChange, attributeName }) => {
      const onChangeValue = (
        v: any,
        type: "increment" | "decrement" | "input",
      ) => {
        let val = Number.parseFloat(v);
        if (type === "increment") {
          val = val + 1;
        } else if (type === "decrement") {
          val = Math.max(val - 1, 0);
        }

        if (Number.isNaN(val)) {
          val = 0;
        }

        onChange(`${val}s`);
      };
      return (
        <FieldWithDescription description={description}>
          <div className="flex flex-row items-end justify-between">
            <div className="mr-1 flex-1">
              <LengthInputSelector
                placeholder="0s"
                key={`props.${attributeName}`}
                field={`props.${attributeName}`}
                value={value}
                onChange={(v: any) => onChange(v)}
                draggingType={DraggingTypes.Vertical}
                metrics={["s"]}
                resetValue="0s"
                anchorValue="0s"
                minDragValues={{ all: 0 }}
                minValues={{ all: 0 }}
                allowsNegativeValue={false}
              />
            </div>
            <ButtonGroupComponent
              options={[
                {
                  label: <FiMinus size={12} />,
                  onClick: () => onChangeValue(value, "decrement"),
                  isDisabled: value > 0,
                },
                {
                  label: <FiPlus size={12} />,
                  onClick: () => onChangeValue(value, "increment"),
                },
              ]}
            />
          </div>
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  productOptions: {
    render: ({ value, onChange }) => {
      return <OptionsCustomPropModifier value={value} onChange={onChange} />;
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  liquidCodeEditor: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <CodeEditorCustomPropModifier
            language="liquid"
            value={value}
            onChange={(value) => onChange(value)}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  htmlCodeEditor: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <CodeEditorCustomPropModifier
            language="html"
            value={value}
            onChange={(value) => onChange(value)}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },
  cssCodeEditor: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <CodeEditorCustomPropModifier
            language="css"
            value={value}
            onChange={(value) => onChange(value)}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },

  shopifyThemeSections: {
    render: ({ value, description, onChange }) => {
      return (
        <FieldWithDescription description={description}>
          <SectionsSelectable defaultValue={value} onChange={onChange} />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },

  selectable: {
    render: ({ value, description, onChange, options = [], placeholder }) => {
      return (
        <FieldWithDescription description={description}>
          <Selectable
            options={options}
            onSelect={(value) => onChange(value)}
            value={value}
            placeholder={placeholder ?? "Select an option"}
          />
        </FieldWithDescription>
      );
    },
    shouldRender: () => true,
    defaultValue: null,
  },

  swatches: {
    render: () => {
      return <SwatchesCustomPropModifier key="swatches" />;
    },
    shouldRender: () => true,
    defaultValue: null,
    hasCustomWrapper: true,
  },
  percentage: {
    render: ({ value, description, onChange, attributeName }) => {
      return (
        <FieldWithDescription description={description}>
          <LengthInputSelector
            value={value}
            metrics={["%"]}
            minValues={{ "%": 0 }}
            maxValues={{ "%": 100 }}
            placeholder="50%"
            field={`props.${attributeName}`}
            onChange={(value) => onChange(value)}
            allowsNegativeValue={false}
            resetValue="50%"
            anchorValue="50%"
            menuOptions={[
              {
                label: "0%",
                value: "0%",
              },
              {
                label: "25%",
                value: "25%",
              },
              {
                label: "50%",
                value: "50%",
              },
              {
                label: "75%",
                value: "75%",
              },
              {
                label: "100%",
                value: "100%",
              },
            ]}
          />
        </FieldWithDescription>
      );
    },
    defaultValue: "50%",
    shouldRender: () => true,
  },
  productSellingPlans: {
    render: ({ value, description, context, onChange }) => {
      const sellingPlans = context?.attributes?._sellingPlans;

      // Put selling plans without an appId we know at the end
      const sortedSellingPlans = sellingPlans
        ?.slice()
        .sort((a: ShopifySellingPlan, b: ShopifySellingPlan) => {
          if (!a.appId && b.appId) {
            return 1;
          }
          if (a.appId && !b.appId) {
            return -1;
          }
          return 0;
        });

      /**
       * NOTE (Jackson, 12-12-24): If we have a known appId, use the name from our mapping,
       * otherwise, convert the appId to title case and use that as the name instead. Oftentimes
       * this will be the name of the app. If no appId is found, return "Other"
       */
      const getAppNameFromAppId = (
        appId: string | null | undefined,
      ): string => {
        if (!appId) {
          return "Other";
        }

        if (appId in SUB_APP_APPID_TO_NAME) {
          return SUB_APP_APPID_TO_NAME[
            appId as keyof typeof SUB_APP_APPID_TO_NAME
          ];
        }

        return startCase(appId);
      };

      const options = sortedSellingPlans?.reduce(
        (acc: Option[], sellingPlan: ShopifySellingPlan) => {
          // If this is the first plan with this appId, add a group header
          const previousPlan =
            sortedSellingPlans[sortedSellingPlans.indexOf(sellingPlan) - 1];
          if (!previousPlan || previousPlan.appId !== sellingPlan.appId) {
            const appName = getAppNameFromAppId(sellingPlan.appId);

            acc.push({
              label: appName,
              isSelectable: false,
              isDefaultActive: false,
              isDisabled: true,
              value: null,
            });
          }

          acc.push({
            label: sellingPlan.name,
            value: sellingPlan.id,
            isSelectable: true,
            isDefaultActive: !value || value.includes(sellingPlan.id),
          });

          return acc;
        },
        [],
      );

      if (options) {
        const allSelected = !value || value.length >= options.length;
        return (
          <FieldWithDescription description={description}>
            <SelectablePopover
              title="Selling Plans"
              itemSize={32}
              itemsOnViewCount={8}
              options={options}
              isMultiselect
              allowSelectAll
              labelClassName="inline-block truncate"
              isRemovable={false}
              onSelect={(newValues) => {
                if (
                  Array.isArray(newValues) &&
                  newValues.length === options.length
                ) {
                  onChange(null);
                } else {
                  onChange(newValues);
                }
              }}
              placeholder="Select Selling Plan"
              startEnhancer={
                <Badge
                  type="icon"
                  icon={
                    <BsFillBagPlusFill size={10} className="text-slate-50" />
                  }
                  className="bg-accent"
                />
              }
            >
              {allSelected
                ? "All Selling Plans"
                : `${value.length} Selling Plan${value.length !== 1 ? "s" : ""}`}
            </SelectablePopover>
          </FieldWithDescription>
        );
      }
    },
    shouldRender: () => true,
    defaultValue: null,
  },
};

function HashmarkInput({
  value,
  onValueChange,
}: {
  value: string;
  onValueChange(value: string): void;
}) {
  const inputProps = useOverridableInput({ value, onValueChange });
  return (
    <Input
      startEnhancer={<span className="text-slate-400">#</span>}
      {...inputProps}
    />
  );
}

const InputWithDynamicData = ({
  onChange,
  value,
  disableDynamicData = false,
}: {
  onChange: (value: any) => void;
  value: any;
  disableDynamicData?: boolean;
}) => {
  const modal = useModal();
  const onClickDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "callback",
          onChange: (value: any) => {
            onChange(value);
          },
        },
        initialPath: value ? getPathFromVariable(value) : undefined,
      },
    });
  };

  const inputProps = useOverridableInput({ value, onValueChange: onChange });

  return typeof value === "string" &&
    isDynamicDataValue(value) &&
    !disableDynamicData ? (
    <DynamicDataValueIndicator
      type="text"
      templateValue="Dynamic Value"
      onClick={() => onClickDynamicData?.()}
      onRemove={() => {
        onChange(null);
      }}
    />
  ) : (
    <Input
      {...inputProps}
      allowsDynamicData={!disableDynamicData}
      onClickDynamicData={onClickDynamicData}
    />
  );
};

function SectionsSelectable({
  onChange,
  defaultValue,
}: {
  onChange: (value: string | null) => void;
  defaultValue: string | null;
}) {
  const themeId = useEditorSelector(selectThemeId);
  const projectId = useEditorSelector(selectProjectId);
  const shopifyUrl = useEditorSelector(selectStoreShopifyUrl);
  const { isSuccess, isLoading, data } =
    trpc.asset.getShopifyThemeSections.useQuery(
      projectId && shopifyUrl
        ? {
            storeId: projectId,
            themeId,
          }
        : skipToken,
    );

  if (!shopifyUrl) {
    return <ConnectShopifyCallout type="sections" />;
  }

  return (
    <SelectablePopover
      itemSize={40}
      title="Shopify Theme Sections"
      isRemovable={isNotNullish(defaultValue)}
      // TODO (Chance, 2023-04-12): We should disable this when the themes are
      // loading. SelectablePopover does not currently support a disabled input.
      // isDisabled={isLoading}
      options={
        isSuccess
          ? data.sections.map((section) => {
              const sectionName = getSectionName(section.key);
              return {
                label: sectionName,
                value: sectionName,
                isSelectable: true,
                isActive: defaultValue === sectionName,
              };
            })
          : []
      }
      onSelect={onChange}
    >
      <SelectionIndicator
        title={defaultValue ? getSectionName(defaultValue) : null}
        placeholder={isLoading ? "Loading Sections…" : "Select Section"}
      />
    </SelectablePopover>
  );
}

function getSectionName(key: string) {
  return key.replace(/^sections\//, "").replace(/\.liquid$/, "");
}
