// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { ExperimentStatus } from "schemas/experiment";
import type { ReploElementForExperiments } from "schemas/generated/element";
import type { Experiment } from "schemas/generated/experiment";
import type { Variation } from "schemas/generated/variation";

import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import { useExperimentApi } from "@components/projectDashboard/experiments/common";
import InputComponent from "@editor/components/common/designSystem/Input";
import Textarea from "@editor/components/common/designSystem/Textarea";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { Loader } from "@editor/components/common/Loader";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";
import ReploLogoBadge from "@svg/logo-badge";

import { useAnalyticsOnboardingOAuthLink } from "@/features/analytics/useAnalyticsOnboaredingOAuthLink";
import { DetailsContainer } from "@/features/experiments/components/DetailsContainer";
import { LinkSection } from "@/features/experiments/components/sections/LinkSection";
import { useExperimentEdit } from "@/features/experiments/tabs/hooks/useExperimentEdit";
import { TabMenu } from "@/features/experiments/tabs/TabMenu";
import { positiveIntToCapitalLetter } from "@/features/experiments/utils";
import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { Combobox } from "@replo/design-system/components/combobox";
import twMerge from "@replo/design-system/utils/twMerge";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import {
  BsBoxArrowUpRight,
  BsInfoCircle,
  BsPlus,
  BsSearch,
  BsTrash,
} from "react-icons/bs";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { SHOPIFY_APP_LISTING_URL } from "replo-runtime/shared/config";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { isValidHttpUrl } from "replo-utils/lib/url";
import { BillingTiers } from "schemas/billing";
import {
  getExperimentStatus,
  VARIATION_ERROR_MESSAGES,
} from "schemas/experiment";
import { isPathSafeSlug } from "schemas/utils";

export const ExperimentsEditTabV2: React.FC = () => {
  const { experimentId } = useParams();
  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const {
    list: { data, isFetching: isFetchingExperiments },
  } = useExperimentApi({ workspaceId, projectId: null });

  const experiment = data?.experiments.find((exp) => exp.id === experimentId);
  if (!workspaceId || !experiment || !data || isFetchingExperiments) {
    return <Loader />;
  }
  return (
    <ExperimentEditComponent
      workspaceId={workspaceId}
      experiment={experiment}
    />
  );
};

type SplitMethod = "equal" | "manual";

const ExperimentEditComponent = ({
  workspaceId,
  experiment,
}: {
  workspaceId: string;
  experiment: Experiment;
}) => {
  const {
    watch,
    dispatchExperimentEdit,
    hasDataChanged,
    isExperimentValid,
    handleReactHookFormSubmit,
  } = useExperimentEdit();
  const logEvent = useLogAnalytics();

  const {
    update: { mutateAsync: update },
  } = useExperimentApi({ workspaceId, projectId: null });

  const { data: allReploElements, isLoading: isLoadingAllReploElements } =
    trpc.workspace.getAllElementsWithShopifyUrl.useQuery(
      workspaceId ?? skipToken,
    );

  const [splitMethod, setSplitMethod] = React.useState<SplitMethod>("equal");

  const { errors } = useExperimentEdit();

  const {
    links: { data: links },
  } = useExperimentApi({
    workspaceId: workspaceId ?? undefined,
    projectId: null,
  });

  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;

  const shouldForceCreateNewLink = (links?.length ?? 0) === 0;

  const [linkSubSection, setLinkSubSection] = React.useState<
    "chooseLink" | "createNewLink"
  >(shouldForceCreateNewLink ? "createNewLink" : "chooseLink");

  const handleLinkSubsectionChange = (value: string) => {
    setLinkSubSection(value as "chooseLink" | "createNewLink");
    if (value === "createNewLink") {
      logEvent("experiment.link.create", {
        billingPlanTier: subscriptionTier,
      });
    }
  };

  const status = getExperimentStatus(experiment);
  const isEditable = status === "draft";

  const isUpdateAllowed = hasDataChanged && isExperimentValid;

  const onSave = async (data: Experiment) => {
    void update({
      ...data,
      status,
    });
    successToast("Experiment Updated", "");
  };

  const handleSubmissionClick = handleReactHookFormSubmit(onSave);

  const isAllocationPercentValid =
    errors.variations?.message !== VARIATION_ERROR_MESSAGES.allocationPercent;

  return (
    <div className="flex flex-col gap-2 pb-32">
      <div className="flex flex-row justify-between">
        <TabMenu />
        {hasDataChanged && (
          <div className="flex flex-row justify-end">
            <Button
              variant="primary"
              size="sm"
              disabled={!isUpdateAllowed}
              onClick={() => void handleSubmissionClick()}
            >
              Save Changes
            </Button>
          </div>
        )}
      </div>
      <div className="space-y-4 text-sm">
        <LinkSection
          linkSubSection={linkSubSection}
          handleLinkSubsectionChange={handleLinkSubsectionChange}
          isEditable={isEditable}
          links={links ?? []}
          shouldForceCreateNewLink={shouldForceCreateNewLink}
          experiment={{
            analyticsLinkId:
              watch("completedAnalyticsLinkId") ?? watch("analyticsLinkId"),
            name: experiment.name,
          }}
        />
        <DetailsContainer
          title="Pages to split traffic to"
          headerComponent={
            isEditable && (
              <ToggleGroup
                allowsDeselect={false}
                type="single"
                style={{ width: "275px" }}
                options={[
                  {
                    label: "Equal split",
                    value: "equal",
                  },
                  {
                    label: "Manual",
                    value: "manual",
                  },
                ]}
                value={splitMethod}
                onChange={(value: SplitMethod) => {
                  setSplitMethod(value);
                  if (value === "equal") {
                    dispatchExperimentEdit({
                      type: "setEqualVariationSplit",
                    });
                  }
                }}
              />
            )
          }
          isRequired
        >
          <div className="space-y-3">
            {watch("variations").map((variation, index) => (
              <VariationEntry
                key={variation.id}
                index={index}
                {...variation}
                isAllocationPercentValid={isAllocationPercentValid}
                splitMethod={splitMethod}
                allowRemoval={watch("variations").length > 1}
                status={status}
                allReploElements={allReploElements?.elements ?? []}
                isLoadingAllReploElements={isLoadingAllReploElements}
              />
            ))}
          </div>
          <div
            className={twMerge(
              "text-red-500",
              !errors.variations?.message && "hidden",
            )}
          >
            {errors.variations?.message}
          </div>
          {isEditable && (
            <div
              className="cursor-pointer bg-slate-50 hover:bg-slate-100 flex justify-center items-center py-4 border border-dashed border-slate-300 rounded"
              onClick={() => dispatchExperimentEdit({ type: "addVariation" })}
            >
              <div className="flex flex-row items-center gap-1">
                <BsPlus size={20} />
                <span>Add variant</span>
              </div>
            </div>
          )}
        </DetailsContainer>
        <DetailsContainer title="Description">
          <Textarea
            size="base"
            className="grow"
            maxLength={256}
            placeholder="Keep track of why you are testing or other test details, for yourself or others on your team."
            onChange={(description) =>
              dispatchExperimentEdit({
                type: "changeProperty",
                payload: { key: "description", value: description },
              })
            }
            value={watch("description") ?? ""}
          />
        </DetailsContainer>
      </div>
    </div>
  );
};

const ConnectShopifyButton: React.FC = () => {
  return (
    <div className="text-accent">
      <Button variant="inherit" size="sm" to={SHOPIFY_APP_LISTING_URL}>
        Connect Shopify
      </Button>
    </div>
  );
};

const EnableAnalyticsButton: React.FC = () => {
  const { oauthLink, isLoading } = useAnalyticsOnboardingOAuthLink();
  const productAnalytics = useLogAnalytics();

  return (
    <div className="text-accent">
      <Button
        size="sm"
        variant="inherit"
        to={oauthLink ?? ""}
        isLoading={isLoading || !oauthLink}
        onClick={() =>
          productAnalytics("analytics.connect", {
            tab: "experiment_details_tab",
          })
        }
      >
        Enable Analytics
      </Button>
    </div>
  );
};

const variationEntryInfoBannerOptions = {
  connectShopify: {
    description:
      "This page must be part of a Replo project with a Shopify integration to track results.",
    ButtonComponent: () => <ConnectShopifyButton />,
  },
  connectAnalytics: {
    description:
      "You must enable Analytics for this Shopify integration in order to track results on this page.",
    ButtonComponent: () => <EnableAnalyticsButton />,
  },
};

type VariationEntryInfoBannerProps = {
  type: keyof typeof variationEntryInfoBannerOptions;
};
const VariationEntryInfoBanner: React.FC<VariationEntryInfoBannerProps> = ({
  type,
}) => {
  const { description, ButtonComponent } =
    variationEntryInfoBannerOptions[type];

  return (
    <div className="bg-accent-emphasis flex flex-row rounded-lg p-4 items-center justify-between">
      <div className="flex flex-row gap-3 text-accent">
        <BsInfoCircle />
        <span className="text-xs">{description}</span>
      </div>
      <ButtonComponent />
    </div>
  );
};

function convertElementToFullUrl(element: ReploElementForExperiments) {
  return `https://${element.shopifyUrl}/pages/${element.shopifyPagePath}`;
}

const VariationEntryField: React.FC<{
  title: string | null;
  className?: string;
  children: React.ReactNode;
}> = ({ title, children, className }) => {
  return (
    <div className={twMerge("grid grid-rows-[0.4fr_0.6fr] gap-3", className)}>
      <span className="text-muted font-semibold">{title ?? ""}</span>
      <div className="flex flex-col justify-center">{children}</div>
    </div>
  );
};

const VariationNameStartEnhancer: React.FC<{ index: number }> = ({ index }) => {
  const { borderClassName, textClassName, backgroundClassName } =
    exhaustiveSwitch({
      type: (index % 3).toString(),
    })({
      0: {
        borderClassName: "border-blue-600",
        textClassName: "text-blue-600",
        backgroundClassName: "bg-blue-200",
      },
      1: {
        borderClassName: "border-purple-600",
        textClassName: "text-purple-600",
        backgroundClassName: "bg-purple-200",
      },
      2: {
        borderClassName: "border-green-600",
        textClassName: "text-green-600",
        backgroundClassName: "bg-green-200",
      },
    });

  return (
    <div
      className={twMerge(
        "rounded w-6 h-6 flex items-center justify-center mr-1 border",
        backgroundClassName,
        borderClassName,
      )}
    >
      <span className={twMerge("text-xs", textClassName)}>
        {positiveIntToCapitalLetter(index + 1).toUpperCase()}
      </span>
    </div>
  );
};

const VariationEntry = ({
  allowRemoval,
  isAllocationPercentValid,
  status,
  allReploElements,
  isLoadingAllReploElements,
  splitMethod,
  index,
  ...variation
}: Variation & {
  allowRemoval: boolean;
  isAllocationPercentValid: boolean;
  status: ExperimentStatus;
  allReploElements: ReploElementForExperiments[];
  isLoadingAllReploElements: boolean;
  splitMethod: SplitMethod;
  index: number;
}) => {
  const isEditable = status === "draft";

  const navigate = useNavigate();

  const { dispatchExperimentEdit } = useExperimentEdit();

  const allReploElementsOptions = React.useMemo(
    () =>
      allReploElements.map((element) => {
        const fullUrl = convertElementToFullUrl(element);
        return {
          label: fullUrl,
          value: fullUrl,
        };
      }),
    [allReploElements],
  );

  const target = variation.target;

  let isTargetConnectedToShopify = false;
  let isTargetStoreConnectedToAnalytics = false;

  let matchingElement: ReploElementForExperiments | undefined;

  if (target && isValidHttpUrl(target)) {
    const targetDomain = new URL(target).hostname;

    matchingElement = allReploElements.find(
      (element) => element.shopifyUrl === targetDomain,
    );

    isTargetConnectedToShopify = Boolean(matchingElement);
    isTargetStoreConnectedToAnalytics = Boolean(matchingElement?.hasWebPixelId);
  }

  const targetElement = allReploElements.find(
    (element) => convertElementToFullUrl(element) === target,
  );

  const handlePageLinkIconClick = () => {
    if (targetElement) {
      navigate(
        generatePath(routes.editor.element, {
          projectId: targetElement.projectId,
          elementId: targetElement.id,
        }),
      );
    }
  };

  const shouldShowShopifyBanner = target && !isTargetConnectedToShopify;
  // NOTE (Max, 2024-11-14): We only wanna show the analyticsBanner if the
  // target is connected to Shopify.
  const shouldShowAnalyticsBanner =
    target && isTargetConnectedToShopify && !isTargetStoreConnectedToAnalytics;

  return (
    <div className="flex flex-col gap-2 border border-slate-300 p-4 rounded">
      <div className="grid grid-cols-12 gap-3 pr-4">
        <VariationEntryField title="Variant Name" className="col-span-2">
          {isEditable ? (
            <InputComponent
              startEnhancer={<VariationNameStartEnhancer index={index} />}
              size="base"
              value={variation.slug}
              type="text"
              unsafe_className={classNames({
                "text-red-600 ring-1 ring-red-600": !isPathSafeSlug(
                  variation.slug,
                ),
              })}
              maxLength={256}
              onChange={(e) =>
                dispatchExperimentEdit({
                  type: "changeVariation",
                  payload: { ...variation, slug: e.currentTarget.value },
                })
              }
            />
          ) : (
            <div className="py-1">{variation.slug}</div>
          )}
        </VariationEntryField>
        <VariationEntryField title="Page URL" className="col-span-8">
          <Combobox.Root
            options={allReploElementsOptions}
            value={target ?? ""}
            onChange={(value) =>
              dispatchExperimentEdit({
                type: "changeVariation",
                payload: { ...variation, target: value },
              })
            }
            isDisabled={!isEditable}
            areOptionsCreatable
          >
            <Combobox.TriggerInput
              placeholder="Enter url or search for page"
              startEnhancer={<BsSearch className="h-3 w-3" />}
              endEnhancer={
                <ReploLogoBadge className="h-6 w-6 cursor-pointer text-default" />
              }
            />
            <Combobox.Content isLoading={isLoadingAllReploElements} />
          </Combobox.Root>
        </VariationEntryField>

        <VariationEntryField title="Percent" className="col-span-1">
          {isEditable ? (
            <InputComponent
              size="base"
              value={variation.allocationPercent.toString()}
              unsafe_className={classNames({
                "text-red-600 ring-1 ring-red-600": !isAllocationPercentValid,
              })}
              type="number"
              isDisabled={splitMethod === "equal"}
              onChange={(e) => {
                const percent = Number.parseInt(e.currentTarget.value);
                const allocationPercent = Number.isNaN(percent)
                  ? 0
                  : Math.max(percent, 0);
                dispatchExperimentEdit({
                  type: "changeVariation",
                  payload: { ...variation, allocationPercent },
                });
              }}
            />
          ) : (
            <div className="py-1">{variation.allocationPercent}%</div>
          )}
        </VariationEntryField>
        <VariationEntryField title={null} className="col-span-1">
          <div className="flex flex-row">
            <IconButton
              className={twMerge("h-7", !targetElement && "opacity-50")}
              variant="tertiary"
              size="sm"
              disabled={!targetElement}
              onClick={handlePageLinkIconClick}
              icon={<BsBoxArrowUpRight size={16} className="text-accent" />}
            />
            {isEditable && (
              <IconButton
                variant="tertiary"
                size="sm"
                className="h-7"
                disabled={!allowRemoval}
                onClick={() =>
                  dispatchExperimentEdit({
                    type: "removeVariation",
                    payload: variation.id,
                  })
                }
                icon={<BsTrash size={16} />}
              />
            )}
          </div>
        </VariationEntryField>
      </div>
      {shouldShowShopifyBanner && (
        <div className="mb-2">
          <VariationEntryInfoBanner type="connectShopify" />
        </div>
      )}
      {shouldShowAnalyticsBanner && (
        <div className="mb-2">
          <VariationEntryInfoBanner type="connectAnalytics" />
        </div>
      )}
    </div>
  );
};
