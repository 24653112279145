import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";
import type {
  SavedStyleColorType,
  SavedStyleTextAttributes,
} from "schemas/generated/savedStyles";

import { COLOR_MENU_ITEMS } from "@editor/components/common/constants/colorStyles";

export function generateTextSavedStylePreviewValue(
  attributes: SavedStyleTextAttributes,
) {
  return `${attributes.fontSize}/${attributes.lineHeight}`;
}

type GenerateNewNameOptions =
  | {
      savedStyles: SavedTextStyle[];
      type: "text";
    }
  | {
      savedStyles: SavedColorStyle[];
      type: "color";
      colorType: SavedStyleColorType;
    };

export const generateNewName = (
  baseName: string,
  options: GenerateNewNameOptions,
  type: "new" | "duplicate" = "new",
): string => {
  if (type === "duplicate") {
    return `${baseName} (Copy)`;
  }

  if (options.type === "text") {
    const existingLetters = options.savedStyles
      .filter((style) => style.name.startsWith(baseName))
      .map((style) => {
        const match = style.name.match(/ ([a-z])$/);
        return match ? match[1] : "";
      })
      .filter(Boolean);

    if (existingLetters.length === 0) {
      return `${baseName} a`;
    }

    const highestLetter = existingLetters.sort().pop() || "a";
    const nextLetter = String.fromCharCode(highestLetter.charCodeAt(0) + 1);
    return `${baseName} ${nextLetter}`;
  }

  if (options.type === "color") {
    const name = COLOR_MENU_ITEMS.find(
      (item) => item.colorType === options.colorType,
    )?.title;

    const baseName = name ?? String(options.colorType);
    const existingNumbers = options.savedStyles
      .filter((style) => style.name.startsWith(baseName))
      .map((style) => {
        const match = style.name.match(/\d+$/);
        return match ? Number.parseInt(match[0], 10) : 0;
      });
    const highestNumber = Math.max(0, ...existingNumbers);
    return `${baseName} ${highestNumber + 1}`;
  }

  return baseName;
};

// NOTE (Sebas, 2025-01-21): This regex is used to separate props from a string
// that can have a design library value. e.g "4px {{library.id.styles.id}}" -> ["4px", "{{library.id.styles.id}}"]
export const regexForSplittingPropValuesWithDynamicData =
  /({{.*?}}|#[\dA-Fa-f]{6,8}|\S+)/g;
