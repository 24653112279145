import type { SourceType } from "@editor/components/editor/page/AssetLibraryModal";

import { useErrorToast } from "@editor/hooks/useErrorToast";
import { useUpdateOrCreateAssetMutation } from "@editor/reducers/api-reducer";
import { selectProject } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { useDropzone } from "react-dropzone";
import { isEmpty } from "replo-utils/lib/misc";
import { MAX_FILE_UPLOAD_COUNT } from "schemas/asset";

function useDropFileZone({
  sourceType,
  onDropBeforeUpload,
  onUploadComplete,
  onError,
  acceptDropAssetType,
  allowClickToUpload,
}: {
  sourceType: SourceType;
  onDropBeforeUpload?: () => string | null;
  onUploadComplete: (res: any, imageComponentId: string | null) => void;
  onError?: (imageComponentId: string | null) => void;
  acceptDropAssetType: string[] | string | null | undefined;
  allowClickToUpload: boolean;
}) {
  const [updateOrCreateAsset, { isLoading }] = useUpdateOrCreateAssetMutation();
  const project = useEditorSelector(selectProject);
  const hasShopifyIntegration = Boolean(project?.integrations?.shopify);
  const errorToast = useErrorToast();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (!hasShopifyIntegration) {
        errorToast(
          "Store Not Connected",
          "Your Shopify store must be connected in order to add images in Replo. Please connect your store and try again.",
          {
            eventName: "error.asset.upload.shopify_not_connected",
            eventProperties: {},
          },
        );
        return;
      }
      acceptedFiles.forEach((file: any) => {
        const imageComponentId = onDropBeforeUpload?.();
        void updateOrCreateAsset({
          file,
          filename: file.name,
          storeId: project?.id ?? "",
          sourceType,
        })
          .then((res) => {
            if (!("error" in res)) {
              onUploadComplete(res, imageComponentId ?? null);
            } else {
              onError?.(imageComponentId ?? null);
            }
          })
          .catch(() => {
            onError?.(imageComponentId ?? null);
          });
      });
      if (!isEmpty(fileRejections)) {
        // Note (Evan, 2024-03-26): Extract all unique error codes
        const allErrorCodes = fileRejections.reduce((acc, fileRejection) => {
          fileRejection.errors.forEach((error) => acc.add(error.code));
          return acc;
        }, new Set<string>());
        if (allErrorCodes.has("too-many-files")) {
          errorToast(
            "Files Exceeding Limit",
            `Too many files selected. Please upload up to ${MAX_FILE_UPLOAD_COUNT} files at a time for a successful upload.`,
            {
              eventName: "error.asset.upload.too_many_files",
              eventProperties: {},
            },
          );
        }
      }
    },
    accept: acceptDropAssetType ?? undefined,
    maxFiles: MAX_FILE_UPLOAD_COUNT,
    noClick: !allowClickToUpload,
    noKeyboard: true,
  });

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    isLoadingAssetUpload: isLoading,
  };
}

export default useDropFileZone;
