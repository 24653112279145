import * as React from "react";

import { URL_PARAMS_FILTER_OPTIONS } from "@/features/analytics/moreFilters/constants";
import { Combobox } from "@replo/design-system/components/combobox";
import twMerge from "@replo/design-system/utils/twMerge";
import { BsFilter } from "react-icons/bs";

type TriggerBarPopoverComboboxProps = {
  handleUrlParamOptionClick: (value: string) => void;
};

const TriggerBarPopoverCombobox: React.FC<TriggerBarPopoverComboboxProps> = ({
  handleUrlParamOptionClick,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <Combobox
      options={URL_PARAMS_FILTER_OPTIONS.map((option) => ({
        ...option,
        onClick: () => handleUrlParamOptionClick(option.value),
      }))}
      open={open}
      onOpenChange={handleOpenChange}
      endEnhancer={<BsFilter className="h-4 w-4 text-default" />}
      staticPlaceholder="Filter"
      triggerClassName={twMerge(
        "bg-white hover:bg-slate-100",
        open && "bg-slate-100",
      )}
      contentClassName="w-[116px]"
    />
  );
};

export default TriggerBarPopoverCombobox;
