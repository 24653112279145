import * as React from "react";

import twMerge from "@replo/design-system/utils/twMerge";

/**
 * Helper components for rendering common parts in the main component
 */
export const ElementEditorHeading: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className = "" }) => {
  return (
    <div className={twMerge("text-xs font-medium text-default", className)}>
      {children}
    </div>
  );
};
