import type { ButtonProps } from "@replo/design-system/components/button";
import type { ButtonVariant } from "./button-shared";

import * as React from "react";

import Button from "@replo/design-system/components/button";
import twMerge from "@replo/design-system/utils/twMerge";

// NOTE (Chance 2023-12-04): This union type ensures that all icon buttons have
// a prop that can be used for accessible labels. A button shouldn't have both
// `aria-label` and `aria-labelledby`, but it can have one or the other
// alongside a `tooltipText` prop. If neither `aria-label` nor `aria-labelledby`
// is provided, then `tooltipText` is required as it will be used as the
// accessible label.
type AccessibleLabelProps =
  | {
      tooltipText?: React.ReactNode;
      "aria-label": string;
      "aria-labelledby"?: never;
    }
  | {
      tooltipText?: React.ReactNode;
      "aria-label"?: never;
      "aria-labelledby": string;
    }
  | {
      tooltipText: React.ReactNode;
      "aria-label"?: string;
      "aria-labelledby"?: never;
    }
  | {
      tooltipText: React.ReactNode;
      "aria-label"?: never;
      "aria-labelledby"?: string;
    }
  // NOTE (Chance 2023-12-04): For fake buttons, neither aria-label nor
  // aria-labelledby will make a difference because the underlying button
  // doesn't have a role. So we'd expect the parent component to ensure there is
  // an accessible label.
  | {
      tooltipText?: React.ReactNode;
      "aria-label"?: never;
      "aria-labelledby"?: never;
    };

type InheritedButtonProps = Omit<
  ButtonProps,
  "aria-label" | "aria-labelledby" | "tooltipText"
>;

type IconButtonProps = {
  variant: ButtonVariant;
  icon: React.ReactNode;
} & InheritedButtonProps &
  AccessibleLabelProps;

const IconButton = React.forwardRef<any, IconButtonProps>(
  (
    { icon, className, size = "base", hasMinDimensions = true, ...props },
    ref,
  ) => {
    const mergedClassName = twMerge(
      size === "sm" && "p-[4px]",
      size === "base" && "p-[7px]",
      size === "lg" && "p-[12px]",
      hasMinDimensions && "min-w-[1.5rem] min-h-[1.5rem]",
      className,
    );

    return (
      <Button
        ref={ref}
        {...props}
        className={mergedClassName}
        size={size}
        aria-label={
          props["aria-label"] ||
          props["aria-labelledby"] ||
          (typeof props.tooltipText === "string"
            ? props.tooltipText
            : undefined)
        }
        hasMinDimensions={hasMinDimensions}
      >
        {icon}
      </Button>
    );
  },
);

IconButton.displayName = "IconButton";

export default IconButton;
