import type { ButtonProps } from "@replo/design-system/components/button";

import * as React from "react";

import {
  SubtitleSkeleton,
  TitleSkeleton,
} from "@editor/components/dashboard/SkeletonLoaders";

import Button from "@replo/design-system/components/button";
import twMerge from "@replo/design-system/utils/twMerge";
import { isNotNullish } from "replo-utils/lib/misc";

export type HeaderProps = React.PropsWithChildren<{
  title: string | null;
  subtitle?: string;
  buttonLabel?: string;
  onButtonClick?(): void;
  href?: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  buttonTooltipText?: string;
}>;

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  buttonLabel,
  href,
  onButtonClick,
  isLoading,
  isButtonDisabled,
  buttonTooltipText,
  children,
}) => {
  const shouldShowButtonLink = isNotNullish(href);
  const shouldShowButton = !shouldShowButtonLink && isNotNullish(onButtonClick);
  const commonProps: ButtonProps = {
    size: "base",
    variant: "primary",
    disabled: isButtonDisabled,
    tooltipText: buttonTooltipText,
    className: twMerge("font-normal", isButtonDisabled && "bg-slate-200"),
  };
  return (
    <div className="flex w-full justify-between items-center">
      <div className="flex flex-col gap-2">
        {isLoading && (
          <>
            <TitleSkeleton />
            <SubtitleSkeleton />
          </>
        )}
        {!isLoading && title && (
          <h2 className="text-lg font-medium">{title}</h2>
        )}

        {!isLoading && subtitle && (
          <p className="text-sm font-normal text-slate-400">{subtitle}</p>
        )}
      </div>
      {shouldShowButtonLink && (
        <Button {...commonProps} to={href} target="_blank">
          {buttonLabel}
        </Button>
      )}
      {shouldShowButton && (
        <Button {...commonProps} onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      )}
      {children && <div className="flex items-center gap-2">{children}</div>}
    </div>
  );
};

export default Header;
