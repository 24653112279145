import type { MenuItem } from "@common/designSystem/Menu";
import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { AnyAction } from "redux";
import type { Operator } from "replo-runtime/shared/enums";
import type {
  ConditionStatement,
  EditVariantConditionValueProps,
  PredefinedVariantType,
  VariantQuery,
  VariantWithState,
} from "replo-runtime/shared/types";
import type { Component } from "schemas/component";
import type { ConditionField, ReploState } from "schemas/generated/symbol";

import * as React from "react";

import { setDraftElement } from "@actions/core-actions";
import DataTableRowSelect from "@common/DataTableRowSelect";
import Chip from "@common/designSystem/Chip";
import { Input } from "@common/designSystem/Input";
import { Menu, MenuTrigger } from "@common/designSystem/Menu";
import Selectable from "@common/designSystem/Selectable";
import * as ProductSelectionPopover from "@components/editor/page/ProductSelectionPopover";
import * as VariantSelectionPopover from "@components/editor/page/VariantSelectionPopover";
import Checkbox from "@editor/components/common/Checkbox";
import ButtonGroupComponent from "@editor/components/common/designSystem/ButtonGroup";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import {
  SortableItem,
  SortableList,
} from "@editor/components/common/designSystem/SortableList";
import {
  getConditionFieldEditorData,
  operationToSymbol,
} from "@editor/components/editor/condition";
import { NEW_STATE_NAME } from "@editor/components/editor/constants";
import { useApplyComponentAction } from "@editor/hooks/useApplyComponentAction";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  selectAnimateVariantTransitions,
  selectDraftComponent,
  selectDraftComponentConditionFields,
  selectDraftComponentId,
  selectDraftComponentVariants,
  selectNearestAncestorComponentWithVariants,
  selectNearestComponentWithVariantsId,
  selectPredefinedVariantsForDraftComponent,
  setComponentVariant as setComponentVariantAction,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { docs } from "@editor/utils/docs";
import ModifierGroup from "@editorExtras/ModifierGroup";

import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import Popover from "@replo/design-system/components/popover";
import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import startCase from "lodash-es/startCase";
import { BsPencil, BsPlus } from "react-icons/bs";
import { FiMinus, FiPlus } from "react-icons/fi";
import { ConditionFieldEditorValue } from "replo-runtime/shared/enums";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { mapNull } from "replo-runtime/shared/utils/optional";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import {
  exhaustiveSwitch,
  hasOwnProperty,
  isEmpty,
} from "replo-utils/lib/misc";
import { isNumber } from "replo-utils/lib/type-check";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";
import { v4 as uuidv4 } from "uuid";

import DocumentationInfoIcon from "../DocumentationInfoIcon";
import ModifierLabel from "../extras/ModifierLabel";

type VariantComponentActionType =
  | {
      componentActionType: "addVariant";
      predefinedVariantType?: never;
    }
  | {
      componentActionType: "addPredefinedVariant";
      predefinedVariantType: PredefinedVariantType;
    };

type VariantData = {
  query: VariantQuery | null;
  name: string;
} & VariantComponentActionType;

const VariantModifier: React.FC = () => {
  const [currentEditingVariantId, setCurrentEditingVariantId] = React.useState<
    string | null
  >(null);

  const applyComponentAction = useApplyComponentAction();

  const setDraftElement = useSetDraftElement();
  const variants = useEditorSelector(selectDraftComponentVariants);
  const hasMixedVariants = isMixedStyleValue(variants);
  const hasVariants = !hasMixedVariants && variants.length > 0;
  const [isOpen, setIsOpen] = useOverridableState(hasVariants);

  const addNewVariant = React.useCallback(
    (variantData: VariantData) => {
      const { query, name, componentActionType } = variantData;

      const newVariant = {
        query,
        name,
        id: uuidv4(),
      };

      exhaustiveSwitch({ type: componentActionType })({
        addVariant: () =>
          applyComponentAction({
            type: "addVariant",
            value: { variant: newVariant },
          }),
        addPredefinedVariant: () =>
          applyComponentAction({
            type: "addPredefinedVariant",
            value: {
              variant: newVariant,
              predefinedVariantType: variantData.predefinedVariantType,
            },
          }),
      });

      setDraftElement({ variantId: newVariant.id });

      // Note (Fran, 2024-12-04): We don't want to show the edit variant menu if the variant has
      // a trigger or a condition statement already set.
      if (query === null) {
        setCurrentEditingVariantId(newVariant.id);
      }
    },
    [applyComponentAction, setDraftElement],
  );

  return (
    <div className="flex flex-col gap-2">
      <AncestorVariants />
      <ModifierGroup
        titleEnhancer={
          <DocumentationInfoIcon href="https://support.replo.app/articles/1713125668-states" />
        }
        title="States"
        isCollapsible
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        endEnhancer={
          <AddVariantMenu onSelect={addNewVariant}>
            <IconButton
              aria-label="Add state"
              variant="tertiary"
              icon={<BsPlus className="text-muted" size={20} />}
              tooltipText="Add State"
              className="p-0"
            />
          </AddVariantMenu>
        }
      >
        {hasMixedVariants && (
          <div className="flex gap-1 bg-accent-emphasis p-2 rounded-lg items-center text-primary">
            <DocumentationInfoIcon href={docs.multiSelect.multipleStates} />
            <span className="typ-body-small">Mixed selected states</span>
          </div>
        )}
        {hasVariants && (
          <VariantList
            currentEditingVariantId={currentEditingVariantId}
            setCurrentEditingVariantId={setCurrentEditingVariantId}
          />
        )}
        {!hasMixedVariants && !hasVariants && (
          <div className="flex gap-2 flex-wrap">
            <Chip
              isSelected
              className="bg-blue-600 text-white py-0 px-3 h-[26px]"
              data-testid="variant-default"
            >
              Default
            </Chip>
            <PredefinedVariantList onClick={addNewVariant} />
          </div>
        )}
      </ModifierGroup>
    </div>
  );
};

const VariantList: React.FC<{
  currentEditingVariantId: string | null;
  setCurrentEditingVariantId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
}> = ({ currentEditingVariantId, setCurrentEditingVariantId }) => {
  const applyComponentAction = useApplyComponentAction();

  const draftComponent = useEditorSelector(selectDraftComponent);
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const variants = useEditorSelector(selectDraftComponentVariants);
  const hasMixedVariants = isMixedStyleValue(variants);
  const currentEditingVariant =
    !hasMixedVariants && currentEditingVariantId != null
      ? variants.find((v) => v.id === currentEditingVariantId)
      : null;

  if (hasMixedVariants) {
    return null;
  }

  return (
    // Popover component is needed at root in order to wrap the pane
    // and let us place the rest of the parts wherever we need them.
    <Popover
      isOpen={currentEditingVariantId !== null}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          setCurrentEditingVariantId(null);
        }
      }}
    >
      <div className="flex flex-wrap gap-2">
        <SortableList
          orientation="both-axis"
          onReorderEnd={({ oldIndex, newIndex }) => {
            // Note (Noah, 2022-11-09): Don't allow reordering of something
            // to override the default state, default state is always first
            if (oldIndex !== 0 && newIndex !== 0 && newIndex !== oldIndex) {
              applyComponentAction({
                type: "reorderVariant",
                value: {
                  oldIndex,
                  newIndex,
                },
              });
            }
          }}
          activationConstraint={{
            // Note (Sebas, 2023-01-19): We have to add a little more of delay
            // because it was causing some issues when trying to select a state
            delay: 150,
            tolerance: 15,
          }}
        >
          {variants.map((variant) => (
            <SortableItem
              key={variant.id}
              id={variant.id}
              className="rounded-xl"
            >
              <VariantChip
                key={variant.id}
                variant={variant}
                onSelectEdit={(id) => {
                  setCurrentEditingVariantId?.(id);
                }}
              />
            </SortableItem>
          ))}
        </SortableList>
        <Popover.Anchor className="absolute top-0 left-0" />
        {currentEditingVariant ? (
          <Popover.Content
            title="Configure State"
            shouldPreventDefaultOnInteractOutside={areModalsOpen}
          >
            <EditVariantMenu
              variants={variants}
              variant={currentEditingVariant}
              onDelete={() => {
                setCurrentEditingVariantId(null);
              }}
            />
          </Popover.Content>
        ) : null}
      </div>
      {draftComponent && <AnimationToggle component={draftComponent} />}
    </Popover>
  );
};

const getChipDropdownItems = (
  variantId: string,
  componentId: string,
  variantName: string,
  componentWithVariantsId: string,
  applyComponentAction: (action: UseApplyComponentActionType) => AnyAction,
): MenuItem[] => {
  return [
    {
      items: [
        {
          id: "resetStateToDefault",
          title: "Reset Styles to Default State",
          type: "leaf",
          isDisabled: variantName === "default",
          onSelect: () => {
            applyComponentAction({
              type: "resetStateToDefault",
              variantId,
              componentId,
            });
          },
        },
        {
          id: "pushPropsToDefault",
          title: "Push Changes to Default State",
          type: "leaf",
          isDisabled: variantName === "default",
          onSelect: () => {
            applyComponentAction({
              type: "pushOverridePropsToDefault",
              variantId,
              componentId,
            });
          },
        },
        {
          id: "delete",
          title: "Delete State",
          type: "leaf",
          isDisabled: variantName === "default",
          onSelect: () => {
            applyComponentAction({
              type: "deleteVariant",
              value: { variantId },
              componentId: componentWithVariantsId,
            });
          },
        },
      ],
      type: "section",
    },
  ];
};

const AncestorVariants: React.FC = () => {
  const dispatch = useEditorDispatch();

  const { ancestorComponentId, ancestorComponentName, variantsWithState } =
    useEditorSelector(selectNearestAncestorComponentWithVariants);
  const hasParentVariants = variantsWithState.length > 0;

  const onClick = (id: string) => {
    dispatch(
      setComponentVariantAction({
        componentId: ancestorComponentId ?? undefined,
        variantId: id ?? undefined,
      }),
    );
  };

  return hasParentVariants ? (
    <ModifierGroup title="Parent States">
      {ancestorComponentName && (
        <LabeledControl
          label={ancestorComponentName}
          className="mb-1 font-medium"
          size="sm"
          onClick={() => {
            if (ancestorComponentId) {
              dispatch(
                setDraftElement({
                  componentIds: [ancestorComponentId],
                }),
              );
            }
          }}
        />
      )}
      <div className="flex flex-wrap gap-2">
        {variantsWithState.map(({ isActive, id, name }) => (
          <AncestorVariantChip
            key={id}
            id={id}
            isActive={isActive}
            name={name}
            onClick={onClick}
          />
        ))}
      </div>
    </ModifierGroup>
  ) : null;
};

const ChipMenu: React.FC<
  React.PropsWithChildren<{
    id: string;
    name: string;
    onClick(id: string): void;
  }>
> = ({ id, children, name, onClick }) => {
  const applyComponentAction = useApplyComponentAction();
  const componentId = useEditorSelector(selectDraftComponentId);
  const componentWithVariantsId = useEditorSelector(
    selectNearestComponentWithVariantsId,
  );

  if (!componentId || !componentWithVariantsId) {
    return null;
  }

  return (
    <Menu
      items={getChipDropdownItems(
        id,
        componentId,
        name,
        componentWithVariantsId,
        applyComponentAction,
      )}
      customWidth={280}
      menuType="context"
      onRequestOpen={() => onClick(id)}
      trigger={<MenuTrigger className="bg-transparent">{children}</MenuTrigger>}
    />
  );
};

const AncestorVariantChip: React.FC<{
  id: string;
  name: string;
  isActive: boolean;
  onClick(id: string): void;
}> = ({ id, isActive, name, onClick }) => {
  return (
    <ChipMenu id={id} onClick={onClick} name={name}>
      <Chip
        isSelected={isActive}
        truncate
        className={classNames("px-3 font-normal", {
          "bg-blue-600 text-white": isActive,
        })}
        onClick={() => onClick(id)}
      >
        {startCase(name)}
      </Chip>
    </ChipMenu>
  );
};

const VariantChip: React.FC<{
  variant: VariantWithState;
  onSelectEdit: (id: string) => void;
}> = ({ variant: { id, name, isActive }, onSelectEdit }) => {
  const setDraftElement = useSetDraftElement();

  const isVariantDefault = name === "default";

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // e.detail stores the amount of clicks
    switch (e.detail) {
      case 1:
        return setDraftElement({ variantId: id });
      case 2:
        return !isVariantDefault && onSelectEdit?.(id);
      default:
        return null;
    }
  };

  return (
    <ChipMenu
      id={id}
      name={name}
      onClick={(id: string) => setDraftElement({ variantId: id })}
    >
      <div className="flex gap-2 flex-wrap group">
        <Chip
          isSelected={isActive}
          onClick={onClick}
          truncate={true}
          className={classNames("px-3", {
            "bg-blue-600 text-white": isActive,
          })}
          data-testid={`variant-${name}`}
        >
          {startCase(name)}
        </Chip>
        {!isVariantDefault && (
          <Tooltip content="Configure State" triggerAsChild>
            <Popover.Trigger asChild>
              <button
                aria-label="Configure State"
                type="button"
                onClick={() => onSelectEdit(id)}
                className={classNames(
                  "absolute top-[-8px] right-[-10px] grid h-5 w-5 rounded-full",
                  { "visible bg-blue-500": isActive },
                  {
                    "invisible bg-slate-400 group-hover:visible": !isActive,
                  },
                )}
              >
                <BsPencil
                  size={10}
                  color="white"
                  className="place-self-center"
                  aria-hidden
                />
              </button>
            </Popover.Trigger>
          </Tooltip>
        )}
      </div>
    </ChipMenu>
  );
};

const PredefinedVariantList: React.FC<{
  onClick(variantData: VariantData): void;
}> = ({ onClick }) => {
  const predefinedVariantsFromSelector = useEditorSelector(
    selectPredefinedVariantsForDraftComponent,
  );

  const predefinedVariants = predefinedVariantsFromSelector.map(
    (predefinedVariant) => {
      return {
        name: predefinedVariant.name,
        onClick: () => {
          onClick({
            query: predefinedVariant.query,
            name: predefinedVariant.name,
            componentActionType: "addPredefinedVariant",
            predefinedVariantType: predefinedVariant.predefinedVariantType,
          });
        },
      };
    },
  );

  return predefinedVariants.map((predefinedVariant) => {
    return (
      <Tooltip
        key={predefinedVariant.name}
        content={`Add ${predefinedVariant.name}`}
        triggerAsChild
      >
        <Chip
          isSelected
          className="border border-dashed border-slate-300 bg-transparent text-slate-300 px-3 h-[26px]"
          data-testid={`variant-${predefinedVariant.name}`}
          onClick={() => {
            if ("onClick" in predefinedVariant) {
              predefinedVariant.onClick?.();
            }
          }}
        >
          <span className="truncate">{`Add ${predefinedVariant.name}`}</span>
        </Chip>
      </Tooltip>
    );
  });
};

const AnimationToggle: React.FC<{ component: Component }> = ({ component }) => {
  const applyComponentAction = useApplyComponentAction();

  const isAnimated = Boolean(
    useEditorSelector(selectAnimateVariantTransitions),
  );

  return (
    <div className="mt-2 mb-2 flex flex-row items-center !text-xs font-medium">
      <Checkbox
        className="text-xs"
        isChecked={isAnimated}
        onChange={(checked) => {
          applyComponentAction({
            type: "setProps",
            componentId: component.id,
            value: {
              style: {
                __animateVariantTransitions: checked,
              },
            },
            allVariants: true,
          });
        }}
      >
        Animate State Transitions
      </Checkbox>
    </div>
  );
};

/**
 * Component that renders the add variant menu.
 */
const AddVariantMenu: React.FC<
  React.PropsWithChildren<{
    onSelect(variantData: VariantData): void;
  }>
> = ({ onSelect, children }) => {
  const predefinedVariants = useEditorSelector(
    selectPredefinedVariantsForDraftComponent,
  );

  const getMenuItems = () => {
    const items: MenuItem[] = [];

    if (predefinedVariants.length > 0) {
      items.push({
        items: predefinedVariants.map((variant) => ({
          id: variant.name,
          title: variant.name,
          onSelect: () => {
            onSelect({
              query: variant.query,
              name: variant.name,
              componentActionType: "addPredefinedVariant",
              predefinedVariantType: variant.predefinedVariantType,
            });
          },
          type: "leaf",
        })),
        type: "section",
      });
    }

    items.push({
      items: [
        {
          id: "custom",
          title: "Add Custom",
          type: "leaf",
          onSelect: () => {
            onSelect({
              query: null,
              name: NEW_STATE_NAME,
              componentActionType: "addVariant",
            });
          },
        },
      ],
      type: "section",
    });

    return items;
  };

  return (
    <Menu
      items={getMenuItems()}
      customWidth={140}
      contentClassNames="truncate"
      trigger={
        <MenuTrigger asChild>
          <div className="flex items-center justify-center">{children}</div>
        </MenuTrigger>
      }
    />
  );
};

/**
 * Component that renders the edit variant menu, where variant name
 * and conditions can be updated.
 */
const EditVariantMenu: React.FC<{
  variant: ReploState;
  variants: ReploState[];
  onDelete?: () => void;
}> = ({ variant, onDelete, variants }) => {
  const setDraftElement = useSetDraftElement();
  const applyComponentAction = useApplyComponentAction();

  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const variantId = variant.id;

  const inputProps = useOverridableInput({
    value: variant.name,
    onValueChange: React.useCallback(
      (newName: string) => {
        applyComponentAction({
          componentId: draftComponentId,
          type: "updateVariant",
          value: {
            variant: (variant) => {
              return variantId === variant.id && variant.name !== newName
                ? { ...variant, name: newName }
                : variant;
            },
          },
        });
      },
      [draftComponentId, variantId, applyComponentAction],
    ),
  });

  const handleDelete = () => {
    applyComponentAction({
      type: "deleteVariant",
      value: { variantId: variant.id },
    });
    onDelete?.();

    const deletedVariantId = variant.id;
    const deletedVariantIndex = variants.findIndex(
      (v) => v.id === deletedVariantId,
    );

    const previousVariantIndex = Math.max(deletedVariantIndex - 1, 0);
    const previousVariantId = variants[previousVariantIndex]?.id;

    setDraftElement({ variantId: previousVariantId });
  };

  return (
    <div className="flex min-h-[230px] flex-col justify-between">
      <div className="flex flex-col gap-2">
        <div id="variant-name" className="flex flex-row items-center">
          <ModifierLabel label="Name" className="text-subtle" />
          <Input id="variant-name" autoFocus {...inputProps} />
        </div>
        <EditVariantConditions variant={variant} />
      </div>
      <Button variant="secondaryDanger" onClick={handleDelete} isFullWidth>
        Delete State
      </Button>
    </div>
  );
};

/**
 * Component that renders proper field to edit variant conditions.
 */
const EditVariantConditions: React.FC<{ variant: ReploState }> = ({
  variant,
}) => {
  const applyComponentAction = useApplyComponentAction();

  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const conditionFields = useEditorSelector(
    selectDraftComponentConditionFields,
  );
  const activeCondition = variant.query?.statements?.[0] ?? null;
  const { operatorOptions, editorConfig } =
    mapNull(activeCondition?.field, (field) =>
      getConditionFieldEditorData(field),
    ) || {};

  const updateConditionOperator = React.useCallback(
    (operator: Operator) => {
      applyComponentAction({
        componentId: draftComponentId,
        type: "addVariantTriggerStatement",
        value: {
          variantId: variant.id,
          statement: {
            ...activeCondition!,
            operator,
          },
        },
      });
    },
    [activeCondition, applyComponentAction, draftComponentId, variant.id],
  );

  const updateConditionValue = React.useCallback(
    (value: Operator) => {
      applyComponentAction({
        componentId: draftComponentId,
        type: "addVariantTriggerStatement",
        value: {
          variantId: variant.id,
          statement: {
            ...activeCondition!,
            value,
          },
        },
      });
    },
    [activeCondition, applyComponentAction, draftComponentId, variant.id],
  );

  const removeCondition = (value: ConditionStatement) => {
    return applyComponentAction({
      componentId: draftComponentId,
      type: "deleteVariantTriggerStatement",
      value: {
        statementId: value.id,
        variantId: variant.id,
      },
    });
  };

  // Handles how to update the condition value when the field changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  const onConditionFieldChange = React.useCallback(
    (field: ConditionField) => {
      const { operatorOptions, defaultValue } =
        getConditionFieldEditorData(field);

      const id = activeCondition?.id ?? uuidv4();

      if (operatorOptions.length === 0) {
        return applyComponentAction({
          type: "addVariantTriggerStatement",
          value: {
            variantId: variant.id,
            statement: {
              field,
              id,
              operator: null,
              value: null,
            },
          },
        });
      }

      const [operator] = operatorOptions.some(
        (option) => option.value === activeCondition?.operator,
      )
        ? [activeCondition!.operator, activeCondition!.value]
        : [operatorOptions[0]!.value, defaultValue];

      return applyComponentAction({
        type: "addVariantTriggerStatement",
        value: {
          variantId: variant.id,
          statement: {
            field,
            id,
            operator: operator as Operator,
            // NOTE (Fran 2024-09-12): We need to reset the value when the condition field changes to avoid
            // issues with unsupported values across different types of condition fields.
            value: [],
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeCondition],
  );

  // Note (Evan, 2023-11-08): Here we check if the field of the active condition is valid, so that
  // states that get copied over don't display if they're not valid in their new context. (conditionFields
  // is dependent on context). We also fall back to null rather than undefined, since undefined would cause
  // the Selectable to revert to uncontrolled state.
  const fieldValueToDisplay =
    activeCondition?.field && conditionFields.includes(activeCondition?.field)
      ? activeCondition.field
      : null;

  return (
    <div className="flex flex-row w-full">
      <ModifierLabel label="Show If" className="text-subtle pt-1" />
      <div className="flex flex-col gap-2 truncate w-full">
        <Selectable
          value={fieldValueToDisplay}
          options={conditionFields.map((value) => {
            return {
              label: getConditionFieldEditorData(value)?.displayName,
              value,
            };
          })}
          onSelect={onConditionFieldChange}
          placeholder="Select a condition"
          disableDropdownFixedWidth={true}
          valueIndicator={({ value, label }) => {
            return (
              <ConditionSelectValueIndicator
                tooltip={String(label)}
                badgeContent={String(label).charAt(0)}
              >
                {(label || value) as React.ReactNode}
              </ConditionSelectValueIndicator>
            );
          }}
          onRemove={
            activeCondition === null
              ? undefined
              : () => removeCondition(activeCondition)
          }
          className="truncate"
        />
        {!isEmpty(operatorOptions) && editorConfig && (
          <>
            <Selectable
              value={activeCondition?.operator ?? undefined}
              options={operatorOptions}
              onSelect={updateConditionOperator}
              disableDropdownFixedWidth={true}
              valueIndicator={({ value, label }) => (
                <ConditionSelectValueIndicator
                  badgeContent={operationToSymbol[value as Operator]}
                >
                  {(label || value) as React.ReactNode}
                </ConditionSelectValueIndicator>
              )}
            />
            <EditVariantConditionValue
              type={editorConfig.type}
              value={activeCondition?.value}
              onChange={updateConditionValue}
              placeholder={
                hasOwnProperty(editorConfig, "placeholder")
                  ? String(editorConfig.placeholder)
                  : null
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

/**
 * Component that renders active value indicator for variant condition selects.
 */
const ConditionSelectValueIndicator: React.FC<
  React.PropsWithChildren<{ badgeContent: string; tooltip?: string }>
> = ({ children, tooltip }) => {
  const content = (
    <div className="flex items-center gap-2 text-xs font-normal truncate grow">
      <p className="truncate">{children}</p>
    </div>
  );
  if (tooltip) {
    return (
      <Tooltip content={tooltip} triggerAsChild>
        {content}
      </Tooltip>
    );
  }
  return content;
};

/**
 * Component that renders the variant condition value field, which
 * changes depending on the condition field type.
 */
const EditVariantConditionValue: React.FC<EditVariantConditionValueProps> = ({
  type,
  value,
  onChange,
  placeholder,
}) => {
  switch (type) {
    case ConditionFieldEditorValue.INPUT:
      return (
        <ConditionFieldEditorValueInput
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      );
    case ConditionFieldEditorValue.INPUT_WITH_STEPS:
      return (
        <InputWithSteps
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      );
    case ConditionFieldEditorValue.DATA_TABLE_ROW:
      return (
        <DataTableRowSelect
          className="mb-2 w-full"
          field="props.items"
          value={value}
          onChange={onChange}
        />
      );
    case ConditionFieldEditorValue.PRODUCT_VARIANT:
      return (
        <VariantSelectionPopover.Root onSubmit={onChange} value={value}>
          <VariantSelectionPopover.Content />
          <VariantSelectionPopover.Trigger />
        </VariantSelectionPopover.Root>
      );
    case ConditionFieldEditorValue.TEMPLATE_PRODUCT:
      return (
        <ProductSelectionPopover.Root
          onSubmit={onChange}
          value={value}
          isMultiSelection={true}
          className="w-full text-center"
        >
          <ProductSelectionPopover.Content offset={85} />
          <ProductSelectionPopover.Trigger />
        </ProductSelectionPopover.Root>
      );
    case ConditionFieldEditorValue.PRODUCT:
      return (
        <ProductSelectionPopover.Root
          onSubmit={onChange}
          value={value}
          isMultiSelection={true}
          className="w-full text-center"
        >
          <ProductSelectionPopover.Content offset={85} />
          <ProductSelectionPopover.Trigger />
        </ProductSelectionPopover.Root>
      );
    default:
      return null;
  }
};

function ConditionFieldEditorValueInput({
  placeholder,
  value: providedValue,
  onChange,
}: {
  placeholder: string | null;
  value: any;
  onChange(value: any): void;
}) {
  const inputProps = useOverridableInput({
    value: providedValue ?? "",
    onValueChange: onChange,
  });
  return <Input placeholder={placeholder} {...inputProps} />;
}

function getValidatedNumericValue(value: string | number) {
  if (value == null || value === "") {
    return "";
  }
  const _value = isNumber(value) ? value : Number.parseInt(value, 10);
  return String(Number.isNaN(_value) ? 0 : _value);
}

const InputWithSteps: React.FC<
  Pick<EditVariantConditionValueProps, "value" | "onChange" | "placeholder">
> = ({ value: providedValue, onChange, placeholder }) => {
  const validatedValue = getValidatedNumericValue(providedValue);

  const _onChange = React.useCallback(
    (value: string) => {
      const validated = getValidatedNumericValue(value);
      if (validated === "" || !Number.isNaN(value)) {
        // NOTE (Chance 2023-12-08): We only want to use the pixel value if
        // we've got a valid number. Empty inputs are just empty values.
        onChange?.(value ?? `${value}px`);
      }
    },
    [onChange],
  );

  const [value, setValue] = useOverridableState(
    validatedValue,
    useDebouncedCallback(_onChange, 300),
  );

  return (
    <div className="flex gap-2">
      <Input
        type="number"
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          setValue(getValidatedNumericValue(event.target.value));
        }}
      />
      <ButtonGroupComponent
        options={[
          {
            label: <FiMinus />,
            onClick: () => {
              const number = value ? Number.parseInt(value, 10) : 0;
              if (number > 0) {
                setValue(String(number - 1));
              }
            },
            tooltipText: "Subtract 1",
          },
          {
            label: <FiPlus />,
            onClick: () => {
              const number = value ? Number.parseInt(value, 10) : -1;
              setValue(String(number + 1));
            },
            tooltipText: "Add 1",
          },
        ]}
      />
    </div>
  );
};

export default VariantModifier;
