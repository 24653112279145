import type { MenuItem } from "@editor/components/common/designSystem/Menu";
import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { RichTextEditorTag } from "@editor/types/rich-text-editor";
import type { Editor as CoreEditor } from "@tiptap/core";
import type { SolidOrGradient } from "replo-runtime/shared/types";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";
import type { TextControlType } from "schemas/modifiers";
import type { GradientStop } from "schemas/styleAttribute";
import type { TextShadow } from "schemas/textShadow";

import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import ResizablePane from "@components/ResizablePane";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import { Menu } from "@editor/components/common/designSystem/Menu";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import DesignLibraryTextValueIndicator from "@editor/components/designLibrary/DesignLibraryTextValueIndicator";
import {
  BADGE_TRIGGER_OFFSET,
  TIPTAP_EDITOR_SCROLLABLE_DIV_ID,
} from "@editor/components/editor/constants";
import DocumentationInfoIcon from "@editor/components/editor/page/element-editor/components/DocumentationInfoIcon";
import ControlGroup from "@editor/components/editor/page/element-editor/components/extras/ControlGroup";
import { useRichTextComponent } from "@editor/components/RichTextComponentContext";
import TipTapToolbar from "@editor/components/TipTapRichTextToolbar";
import Tiptap, { useTipTapEditor } from "@editor/components/TiptapTextEditor";
import useGetDeletedSavedStyleValueIfNeeded from "@editor/hooks/designLibrary/useGetDeletedSavedStyleValueIfNeeded";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import useResetDesignLibraryTextValue from "@editor/hooks/designLibrary/useResetDesignLibraryTextValue";
import { useGetModifierControls } from "@editor/hooks/rightBar/useGetModifierControls";
import { useApplyComponentAction } from "@editor/hooks/useApplyComponentAction";
import { useEnableNonDynamicTextEditing } from "@editor/hooks/useEnableNonDynamicTextEditing";
import { useFontWeightOptions } from "@editor/hooks/useFontWeightOptions";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { useGlobalEditorActions } from "@editor/hooks/useGlobalEditorActions";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { getTargetFrameDocument } from "@editor/hooks/useTargetFrame";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectAncestorTextColor,
  selectColor,
  selectColorGradientStops,
  selectColorGradientTilt,
  selectDraftComponent,
  selectDraftComponentComputedStyleValue,
  selectDraftComponentId,
  selectDraftComponentsInnerTextsAndIds,
  selectDraftComponentText,
  selectDraftComponentTextTagWithMixedValues,
  selectDraftElementId,
  selectDraftRepeatedIndex,
  selectFontSize,
  selectFontStyle,
  selectFontWeight,
  selectLineHeight,
  selectTextAlign,
  selectTextDecoration,
  selectTextOutline,
  selectTextShadow,
  selectTextShadows,
  selectTextTransform,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import {
  selectOpenPopoverId,
  setIsRichTextEditorFocused,
  setOpenPopoverId,
} from "@editor/reducers/ui-reducer";
import {
  convertCSSStylesToReploStyles,
  getValidFilteredStyleProps,
} from "@editor/reducers/utils/component-actions";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { getHexColor } from "@editor/utils/colors";
import { getEditorComponentNode } from "@editor/utils/component";
import { docs } from "@editor/utils/docs";
import { naiveRemoveHtmlTags } from "@editor/utils/dom";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DraggingDirections } from "@editor/utils/editor";
import { useInCanvasPreview } from "@editor/utils/preview";
import { isAllTextColored } from "@editor/utils/rte";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import { getTextOutlineString } from "@editor/utils/textOutline";
import {
  formatTitle,
  getTextShadowString,
  parseTextShadows,
} from "@editor/utils/textShadow";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import ModifierGroup from "@editorExtras/ModifierGroup";
import ModifierLabel from "@editorExtras/ModifierLabel";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";
import {
  getCurrentTag,
  getUpdatedLineHeightForFontSize,
} from "@editorModifiers/utils";
import { selectSavedStyle } from "@reducers/core-reducer";

import {
  selectActiveCanvas,
  selectActiveCanvasFrame,
} from "@/features/canvas/canvas-reducer";
import { useDesignLibrary } from "@/features/canvas/stores/runtime";
import { Badge } from "@replo/design-system/components/badge";
import Button from "@replo/design-system/components/button";
import Popover from "@replo/design-system/components/popover";
import Tooltip from "@replo/design-system/components/tooltip";
import debounce from "lodash-es/debounce";
import differenceBy from "lodash-es/differenceBy";
import { EditorView } from "prosemirror-view";
import { AiOutlineEllipsis, AiOutlineFontSize } from "react-icons/ai";
import { BsBorderWidth } from "react-icons/bs";
import {
  RiAlignCenter,
  RiAlignLeft,
  RiAlignRight,
  RiItalic,
  RiLineHeight,
  RiStrikethrough,
  RiTextSpacing,
  RiUnderline,
} from "react-icons/ri";
import { isDynamicDataValue } from "replo-runtime";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { getSavedStyleValue } from "replo-runtime/shared/savedStyles";
import {
  isDynamicDesignLibraryValue,
  resolveTextWithSavedStyles,
} from "replo-runtime/shared/utils/designLibrary";
import {
  CSS_LENGTH_TYPES,
  CSS_LENGTH_TYPES_WITH_COMPUTED,
} from "replo-runtime/shared/utils/units";
import {
  isMixedStyleValue,
  REPLO_MIXED_STYLE_VALUE,
} from "replo-runtime/store/utils/mixed-values";
import { filterNulls } from "replo-utils/lib/array";
import { coerceNumberToString } from "replo-utils/lib/misc";
import { v4 as uuidv4 } from "uuid";

import DynamicColorModifier, {
  DynamicColorSelector,
} from "./DynamicColorModifier";
import FontFamilyControl from "./FontFamilyControl";

// NOTE (Reinaldo 2022-06-03): Hacky solution to fix editor breaking when selecting a text node
// https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
EditorView.prototype.updateState = function updateState(state) {
  // @ts-ignore
  if (!this.docView) {
    return;
  } // This prevents the matchesNode error on hot reloads
  // @ts-ignore
  this.updateStateInner(state, this.state.plugins != state.plugins);
};

const DEFAULT_TEXT_SHADOW = {
  id: uuidv4(),
  offsetX: "0px",
  offsetY: "4px",
  blur: "1px",
  color: "#00000040",
};

const TEXT_TRANSFORM_OPTIONS = [
  {
    value: "capitalize",
    label: "Ag",
    tooltipContent: "Capitalize",
  },
  {
    value: "uppercase",
    label: "AG",
    tooltipContent: "Uppercase",
  },
  {
    value: "lowercase",
    label: "ag",
    tooltipContent: "Lowercase",
  },
];

const MIN_EDITOR_HEIGHT = 40;
const CUTOFF_EDITOR_EXPANSION = 200;

const TextStyleModifier: React.FC<{
  includeTextEditor: boolean;
}> = ({ includeTextEditor }) => {
  const modal = useModal();
  const text = useEditorSelector(selectDraftComponentText);
  useTextStyleHotkeys();

  const handleOpenDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "prop",
          propId: "text",
        },
        initialPath: getPathFromVariable(text ?? ""),
      },
    });
  };

  const groupRef = React.useRef<HTMLDivElement>(null);
  const [controls, addControl] = useGetModifierControls<"text">(
    "text",
    groupRef,
  );
  const handleAddTextShadow = useAddDefaultShadow();
  const fontSize = useEditorSelector(selectFontSize);
  const isDesignLibraryValue = isDynamicDesignLibraryValue(
    String(fontSize) ?? "",
  );

  const menuItems: MenuItem[] = [
    {
      id: "textDecoration",
      title: "Format",
      type: "leaf",
      onSelect: () => addControl("textDecoration"),
      isDisabled: controls.has("textDecoration") || isDesignLibraryValue,
    },
    {
      id: "textTransform",
      title: "Casing",
      type: "leaf",
      onSelect: () => addControl("textTransform"),
      isDisabled: controls.has("textTransform") || isDesignLibraryValue,
    },
    {
      id: "textOutline",
      title: "Outline",
      type: "leaf",
      onSelect: () => addControl("textOutline"),
      isDisabled: controls.has("textOutline") || isDesignLibraryValue,
    },
    {
      id: "textShadow",
      title: "Shadow",
      type: "leaf",
      onSelect: () => {
        addControl("textShadow");
        handleAddTextShadow();
      },
      isDisabled: isDesignLibraryValue,
    },
  ];

  const mapControlsToComponent: Array<{
    property: TextControlType;
    component: React.ReactNode;
  }> = [
    { property: "textAlign", component: <TextAlignmentControl /> },
    {
      property: "fontFamily",
      component: (
        <div id="font-family-selectable">
          <FontFamilyControl className="p-1 text-xs h-6 grow hover:bg-slate-100" />
        </div>
      ),
    },
    {
      property: "fontWeight",
      component: <FontWeightControl />,
    },
    {
      property: "fontSize",
      component: <FontSizeControl />,
    },
    {
      property: "lineHeight",
      component: <LineHeightControl />,
    },
    { property: "color", component: <ForegroundColorControl /> },
    {
      property: "letterSpacing",
      component: <LetterSpacingControl />,
    },
    {
      property: "textDecoration",
      component: <TextFormatControl />,
    },
    {
      property: "htmlTag",
      component: <TagControl />,
    },
    {
      property: "textTransform",
      component: <TextTransformControl />,
    },
    {
      property: "textOutline",
      component: <TextOutlineControl />,
    },
    {
      property: "textShadow",
      component: <TextShadowControl />,
    },
  ];

  return (
    <ModifierGroup
      ref={groupRef}
      title="Text"
      menuItems={menuItems}
      tooltipText="Add Additional Text Format"
      // Note (Noah, 2024-10-25): Disable focusing the menu trigger on close, since this will
      // dismiss the popover that opens when we add a text shadow
      disableMenuTriggerFocusOnClose
      endEnhancer={<HeaderMenu />}
      titleEnhancer={<DocumentationInfoIcon href={docs.modifiers.text} />}
    >
      <div id="text-style-modifier" className="flex flex-col gap-2">
        {includeTextEditor && (
          <TextControl handleOpenDynamicData={handleOpenDynamicData} />
        )}
        <TextSavedStyleGroup />
        {mapControlsToComponent.map(({ property, component }) => {
          if (controls.has(property) && component) {
            return <React.Fragment key={property}>{component}</React.Fragment>;
          }
          return null;
        })}
      </div>
    </ModifierGroup>
  );
};

const HeaderMenu: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const colorValue = useEditorSelector(selectColor);
  const textValue = useEditorSelector(selectDraftComponentText);
  return (
    <Menu
      items={[
        {
          type: "leaf",
          id: "pasteFromFigma",
          title: "Paste Style From Figma",
          onSelect: () => {
            void navigator.clipboard.readText().then((text) => {
              const styleObject = convertCSSStylesToReploStyles(text, true);
              const { style: textValidStyles } = getValidFilteredStyleProps(
                { style: styleObject },
                "text",
                {
                  colorValue: !isMixedStyleValue(colorValue)
                    ? colorValue ?? null
                    : null,
                  textValue: textValue ?? "",
                },
              );
              applyComponentAction({
                type: "setStyles",
                value: textValidStyles ?? {},
              });
            });
          },
        },
      ]}
      customWidth={200}
      trigger={
        <Button
          // NOTE (Chance 2023-11-02): `Menu` renders a button by default so this
          // is just for the styles
          variant="secondary"
          style={{ minWidth: 0 }}
          className="h-6 w-6"
        >
          <AiOutlineEllipsis className="h-4 w-4" />
        </Button>
      }
    />
  );
};

const TextControl: React.FC<{
  handleOpenDynamicData: () => void;
}> = ({ handleOpenDynamicData }) => {
  const text = useEditorSelector(selectDraftComponentText);

  if (isMixedStyleValue(text)) {
    return null;
  }

  return typeof text === "string" && isDynamicDataValue(text) ? (
    <DynamicTextControl handleOpenDynamicData={handleOpenDynamicData} />
  ) : (
    <RichTextControl handleOpenDynamicData={handleOpenDynamicData} />
  );
};

const DynamicTextControl: React.FC<{
  handleOpenDynamicData: () => void;
}> = ({ handleOpenDynamicData }) => {
  const text = useEditorSelector(selectDraftComponentText);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const applyComponentAction = useApplyComponentAction();

  return (
    <div className="col-span-2">
      <DynamicDataValueIndicator
        type="text"
        templateValue={text}
        componentId={draftComponentId ?? undefined}
        onClick={handleOpenDynamicData}
        onRemove={() => {
          applyComponentAction({
            type: "applyCompositeAction",
            value: [
              {
                type: "setStyles",
                value: {
                  fontWeight: "normal",
                  textDecoration: "none",
                  fontStyle: "normal",
                },
                analyticsExtras: {
                  actionType: "edit",
                  createdBy: "replo",
                },
              },
              {
                type: "setProps",
                value: { text: "Add new text here" },
                analyticsExtras: {
                  actionType: "edit",
                  createdBy: "replo",
                },
              },
            ],
          });
        }}
      />
    </div>
  );
};

const toolbarId = "pane-rte-toolbar";

const RichTextControl: React.FC<{ handleOpenDynamicData(): void }> = ({
  handleOpenDynamicData,
}) => {
  const designLibrary = useDesignLibrary();
  const dispatch = useEditorDispatch();
  const { applyChanges, setTipTapEditor, setTag } = useRichTextComponent();
  const onChangeRichTextEditor = React.useMemo(
    () => debounce((newValue: string) => applyChanges(newValue), 300),
    [applyChanges],
  );

  const onBlurRichTextEditor = React.useCallback(() => {
    dispatch(setIsRichTextEditorFocused(false));
  }, [dispatch]);

  const onFocusRichTextEditor = React.useCallback(() => {
    dispatch(setIsRichTextEditorFocused(true));
  }, [dispatch]);

  const onSelectionUpdate = (editor: CoreEditor | null) => {
    setTag(getCurrentTag(editor));
  };

  const onCreate = (editor: CoreEditor | null) => {
    setTag(getCurrentTag(editor));
  };

  const text = useEditorSelector(selectDraftComponentText);
  const resolvedText = text
    ? resolveTextWithSavedStyles(text, designLibrary)
    : text;

  const tipTapEditor = useTipTapEditor(
    resolvedText,
    onChangeRichTextEditor,
    onBlurRichTextEditor,
    onFocusRichTextEditor,
    onSelectionUpdate,
    onCreate,
  );

  const isRichTextEditorFocused = useEditorSelector(
    (state) => state.ui.isRichTextEditorFocused,
  );

  const [shouldAutoResize, setShouldAutoResize] = React.useState(true);
  const [resizableHeight, setResizableHeight] =
    React.useState(MIN_EDITOR_HEIGHT);
  const [currentEditorHeight, setCurrentEditorHeight] =
    React.useState(MIN_EDITOR_HEIGHT);

  // Synchronize text changes to the editor that don't originate from the
  // editor.
  const textRef = React.useRef(resolvedText);
  React.useEffect(() => {
    const prevText = textRef.current;
    textRef.current = resolvedText;
    if (!isRichTextEditorFocused && tipTapEditor && resolvedText !== prevText) {
      tipTapEditor.commands.setContent(resolvedText!);
      setShouldAutoResize(true);
    }
  }, [isRichTextEditorFocused, tipTapEditor, resolvedText]);

  React.useEffect(() => {
    // pass this instance of the editor to context so it can be used elsewhere
    if (tipTapEditor) {
      setTipTapEditor(tipTapEditor);
    }
    // Make sure to remove the editor from context when this component is
    // unmounted
    return () => {
      setTipTapEditor(null);
    };
  }, [setTipTapEditor, tipTapEditor]);

  // NOTE (Max 2024-05-08): Listens for when editor's height changes, as we need
  // to update the resizable's component height accordingly. We only allow to
  // automatically increase the height until cutoffEditorExpansion
  React.useEffect(() => {
    const editorDiv = document.getElementById(TIPTAP_EDITOR_SCROLLABLE_DIV_ID);

    if (editorDiv && shouldAutoResize) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { height } = entry.contentRect;
          setCurrentEditorHeight(height);
          if (height <= CUTOFF_EDITOR_EXPANSION) {
            // NOTE (Sebas, 2024-10-22): If the text editor is smaller than the minimum height, we set the
            // current editor height to the minimum height. This is needed to prevent a weird flash on the
            // text editor when the user selects a text component. Also if the user tries to expand the editor
            // the size will be set automatically to the minimum height without moving the mouse, which was weird.
            setResizableHeight(
              height <= MIN_EDITOR_HEIGHT ? MIN_EDITOR_HEIGHT : height + 10,
            );
          } else {
            setResizableHeight(CUTOFF_EDITOR_EXPANSION);
          }
        }
      });

      resizeObserver.observe(editorDiv);
      return () => resizeObserver.disconnect();
    }
  }, [shouldAutoResize]);

  // NOTE (Max 2024-05-08): In the context of the ResizablePane's height being larger than the
  // actual editor's height, we want the user to be able to click on ResizablePane and still
  // trigger the editor. Otherwise, the only place where user could click to start typing
  // would be within the actual editor - not good UX.
  const propagateClick = () => {
    const editorDiv = document.getElementById(
      "in-tiptap-editor",
    ) as HTMLElement | null;
    editorDiv?.focus();
  };

  return (
    <>
      <div
        className="col-span-2"
        data-testid="rich-text-editor"
        // NOTE (Sebas, 2024-06-27): We need to stop propagation of the keydown event
        // to prevent the hotkeys from being triggered.
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className="rounded bg-subtle  focus-within:outline focus-within:outline-1 p-2 flex flex-col gap-2">
          <TipTapToolbar editor={tipTapEditor} id={toolbarId} />
          <div onClick={propagateClick}>
            <ResizablePane
              isVertical
              minSize={MIN_EDITOR_HEIGHT}
              maxSize={1500}
              size={resizableHeight}
              onResize={(newSize) => {
                setResizableHeight(newSize);
              }}
              onResizeStart={() => setShouldAutoResize(false)}
              contentClassName={`cursor-text styled-scrollbar ${currentEditorHeight > resizableHeight ? "overflow-auto" : "overflow-none"}`}
            >
              <div
                id={TIPTAP_EDITOR_SCROLLABLE_DIV_ID}
                className="pb-2 text-xs w-full h-max"
              >
                <Tiptap editor={tipTapEditor} />
              </div>
              <div className="absolute right-0 bottom-0">
                <DynamicDataButton onClick={handleOpenDynamicData} />
              </div>
            </ResizablePane>
          </div>
        </div>
      </div>
    </>
  );
};

const tagOptions: {
  label: string;
  value: RichTextEditorTag;
}[] = [
  {
    label: "Paragraph",
    value: "P",
  },
  {
    label: "Heading 1",
    value: "1",
  },
  {
    label: "Heading 2",
    value: "2",
  },
  {
    label: "Heading 3",
    value: "3",
  },
  {
    label: "Heading 4",
    value: "4",
  },
  {
    label: "Heading 5",
    value: "5",
  },
  {
    label: "Heading 6",
    value: "6",
  },
];

const TagControl: React.FC = () => {
  const { setTag, currentTag } = useRichTextComponent();
  const applyComponentAction = useApplyComponentAction();
  const store = useEditorStore();
  const text = useEditorSelector(selectDraftComponentText);
  const tagWithMixedValues = useEditorSelector(
    selectDraftComponentTextTagWithMixedValues,
  );
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();

  const onChangeTag = (tag: RichTextEditorTag) => {
    if (text && isDynamicDataValue(text)) {
      const dynamicString = naiveRemoveHtmlTags(text);
      applyComponentAction({
        type: "setProps",
        value: {
          text:
            tag === "P"
              ? `<p>${dynamicString}</p>`
              : `<h${tag}>${dynamicString}</h${tag}>`,
        },
      });
    } else {
      const actions = [];
      const savedStyle = selectSavedStyle(store.getState());
      if (isMixedStyleValue(savedStyle)) {
        const actionsWithoutSavedStyles =
          getRemoveDesignLibraryValuesActions(tag);
        if (actionsWithoutSavedStyles) {
          actions.push(...actionsWithoutSavedStyles);
        }
      }
      if (actions.length > 0) {
        applyComponentAction({
          type: "applyCompositeAction",
          value: actions,
        });
      }
      setTag(tag);
    }
  };

  return (
    <div className="flex items-center w-full">
      <ModifierLabel label="Tag" />
      <Selectable
        className="w-full"
        placeholder="Select tag"
        defaultValue="P"
        value={currentTag ?? tagWithMixedValues}
        options={tagOptions}
        onSelect={onChangeTag}
      />
    </div>
  );
};

const FontWeightControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const fontWeightFromSelector = useEditorSelector(selectFontWeight);
  const store = useEditorStore();
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const savedStyleFontWeightValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(fontWeightFromSelector) ?? null,
    )?.fontWeight;
  const fontWeight = savedStyleFontWeightValue ?? fontWeightFromSelector;
  const options = useFontWeightOptions();
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();

  const onSelect = (newValue: string) => {
    const actions = [];
    const savedStyle = selectSavedStyle(store.getState());
    if (isMixedStyleValue(savedStyle)) {
      const actionsWithoutSavedStyles = getRemoveDesignLibraryValuesActions();
      if (actionsWithoutSavedStyles) {
        actions.push(...actionsWithoutSavedStyles);
      }
    }

    applyComponentAction({
      type: "applyCompositeAction",
      value: [
        ...actions,
        {
          type: "setStyles",
          value: {
            fontWeight: newValue,
          },
        },
      ],
    });
  };

  return (
    <ControlGroup label="Weight">
      <Selectable
        className="w-full"
        placeholder="Weight"
        options={options}
        ignoreValueMismatchError
        value={
          isMixedStyleValue(fontWeight) ? fontWeight : fontWeight?.toString()
        }
        onSelect={onSelect}
      />
    </ControlGroup>
  );
};

const FontSizeControl: React.FC = () => {
  const fontSizeFromSelector = useEditorSelector(selectFontSize);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(fontSizeFromSelector) ?? null,
    )?.fontSize;
  const fontSize = deletedSavedStyleValue ?? fontSizeFromSelector;

  const store = useEditorStore();
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();

  const draftComponent = useEditorSelector(selectDraftComponent);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const lineHeight = useEditorSelector(selectLineHeight);
  const applyComponentAction = useApplyComponentAction();
  const getAttribute = useGetAttribute();
  const {
    setPreviewCSSPropertyValue,
    enableCanvasPreviewCSSProperties,
    disableCanvasPreviewCSSProperties,
  } = useInCanvasPreview();

  const draftRepeatedIndex = useEditorSelector(selectDraftRepeatedIndex);

  const activeCanvasFrame = useEditorSelector(selectActiveCanvasFrame);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const targetDocument = getTargetFrameDocument(activeCanvasFrame);

  if (!draftComponent) {
    return null;
  }

  const fontSizeMenuItems = [
    "Reset",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "24px",
    "32px",
  ].map((v) => ({
    label: v,
    value: v === "Reset" ? null : v,
  }));

  // Note (Noah, 2022-12-09, REPL-5261): Calculate the computed value of the
  // node's font size in order to use it as a placeholder if no value is set. We
  // actually use the parent node's computed value here, to avoid an issue where
  // when you reset the font size, the input will still show the old value as
  // the placeholder because React hasn't yet completed its DOM commits (this is
  // fine, since it's the same value on the parent due to CSS cascading)
  const draftComponentNode = targetDocument
    ? getEditorComponentNode({
        targetDocument,
        canvas: activeCanvas,
        elementId: draftElementId,
        componentId: draftComponent.id,
        repeatedId: draftRepeatedIndex,
      })?.parentElement
    : null;
  const propertyForPlaceholder = draftComponentNode
    ? getComputedStyle(draftComponentNode).fontSize
    : null;

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Size" />}
      field="style.fontSize"
      value={isMixedStyleValue(fontSize) ? fontSize : fontSize?.toString()}
      dragTrigger="label"
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      allowsNegativeValue={false}
      anchorValue="16px"
      resetValue={null}
      placeholder={propertyForPlaceholder ?? "Font Size"}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      startEnhancer={
        <Tooltip inheritCursor content="Font Size" triggerAsChild>
          <span tabIndex={0}>
            <AiOutlineFontSize />
          </span>
        </Tooltip>
      }
      menuOptions={fontSizeMenuItems}
      onPreviewChange={(value: string) => {
        const lineHeight = getUpdatedLineHeightForFontSize(
          value,
          draftComponent,
          getAttribute,
        );
        setPreviewCSSPropertyValue(["lineHeight"], lineHeight);
      }}
      onDragStart={() => {
        const lineHeightValue = lineHeight ?? "normal";
        enableCanvasPreviewCSSProperties(
          ["lineHeight"],
          String(lineHeightValue),
        );
        setPreviewCSSPropertyValue(["lineHeight"], String(lineHeightValue));
      }}
      onDragEnd={() => {
        disableCanvasPreviewCSSProperties(["lineHeight"]);
      }}
      onChange={(value: string) => {
        const lineHeight = getUpdatedLineHeightForFontSize(
          value,
          draftComponent,
          getAttribute,
        );
        const actions = [];
        const savedStyle = selectSavedStyle(store.getState());
        if (isMixedStyleValue(savedStyle)) {
          const actionsWithoutSavedStyles =
            getRemoveDesignLibraryValuesActions();
          if (actionsWithoutSavedStyles) {
            actions.push(...actionsWithoutSavedStyles);
          }
        }

        applyComponentAction({
          type: "applyCompositeAction",
          value: [
            ...actions,
            {
              type: "setStyles",
              value: {
                fontSize: value,
                lineHeight,
              },
            },
          ],
        });
      }}
      previewProperty="fontSize"
    />
  );
};

const LineHeightControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const store = useEditorStore();
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const fontSize = useEditorSelector(selectFontSize);
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();
  const computedFontSize =
    useEditorSelector((state) =>
      selectDraftComponentComputedStyleValue(state, "fontSize", activeCanvas),
    ) ?? "16px";
  const lineHeightDefaultValue =
    styleAttributeToEditorData.lineHeight.defaultValue;

  const lineHeightFromSelector = useEditorSelector(selectLineHeight);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(lineHeightFromSelector) ?? null,
    )?.lineHeight;
  const lineHeight = deletedSavedStyleValue ?? lineHeightFromSelector;

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Line" />}
      startEnhancer={
        <Tooltip inheritCursor content="Line Height" triggerAsChild>
          <span tabIndex={0}>
            <RiLineHeight />
          </span>
        </Tooltip>
      }
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      field="style.lineHeight"
      value={lineHeight?.toString()}
      anchorValue={fontSize ? String(fontSize) : String(computedFontSize)}
      dragTrigger="label"
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      resetValue={lineHeightDefaultValue}
      allowsNegativeValue={false}
      previewProperty="lineHeight"
      onChange={(newValue: string) => {
        const actions = [];
        const savedStyle = selectSavedStyle(store.getState());
        if (isMixedStyleValue(savedStyle)) {
          const actionsWithoutSavedStyles =
            getRemoveDesignLibraryValuesActions();
          if (actionsWithoutSavedStyles) {
            actions.push(...actionsWithoutSavedStyles);
          }
        }

        applyComponentAction({
          type: "applyCompositeAction",
          value: [
            ...actions,
            {
              type: "setStyles",
              value: {
                lineHeight:
                  newValue === "auto" ? lineHeightDefaultValue : newValue,
              },
            },
          ],
        });
      }}
    />
  );
};

const LetterSpacingControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const store = useEditorStore();
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();
  const letterSpacingDefaultValue =
    styleAttributeToEditorData.letterSpacing.defaultValue;

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Spacing" />}
      startEnhancer={
        <Tooltip inheritCursor content="Letter Spacing" triggerAsChild>
          <span tabIndex={0}>
            <RiTextSpacing />
          </span>
        </Tooltip>
      }
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      field="style.letterSpacing"
      resetValue={letterSpacingDefaultValue}
      anchorValue="1px"
      dragTrigger="label"
      onChange={(newValue: string) => {
        const actions = [];
        const savedStyle = selectSavedStyle(store.getState());
        if (isMixedStyleValue(savedStyle)) {
          const actionsWithoutSavedStyles =
            getRemoveDesignLibraryValuesActions();
          if (actionsWithoutSavedStyles) {
            actions.push(...actionsWithoutSavedStyles);
          }
        }

        applyComponentAction({
          type: "applyCompositeAction",
          value: [
            ...actions,
            {
              type: "setStyles",
              value: {
                letterSpacing: newValue,
              },
            },
          ],
        });
      }}
      menuOptions={[
        { label: "Reset", value: "" },
        { label: "1px", value: "1px" },
        { label: "2px", value: "2px" },
        { label: "4px", value: "4px" },
      ]}
      previewProperty="letterSpacing"
    />
  );
};

const normalizeTextDirectionForInput = (
  textDirection?: CSSStyleDeclaration["textAlign"] | null,
) => {
  if (textDirection === "start") {
    return "left";
  } else if (textDirection === "end") {
    return "right";
  }
  return textDirection;
};

const TextAlignmentControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const store = useEditorStore();
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();
  const textAlignFromSelector = useEditorSelector(selectTextAlign);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(textAlignFromSelector) ?? null,
      true,
    )?.textAlign;
  const textAlign = deletedSavedStyleValue ?? textAlignFromSelector;
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const computedStyleValue =
    normalizeTextDirectionForInput(
      useEditorSelector((state) =>
        selectDraftComponentComputedStyleValue(
          state,
          "textAlign",
          activeCanvas,
        ),
      ),
    ) ?? null;

  const options = [
    {
      value: "left",
      label: <RiAlignLeft size={16} />,
      tooltipContent: "Align Text Left",
    },
    {
      value: "center",
      label: <RiAlignCenter size={16} />,
      tooltipContent: "Align Text Center",
    },
    {
      value: "right",
      label: <RiAlignRight size={16} />,
      tooltipContent: "Align Text Right",
    },
  ];

  return (
    <ControlGroup label="Alignment">
      <ToggleGroup
        type="single"
        style={{
          // TODO (Fran 2024-10-09 REPL-14029): Make the changes need it in the ToggleGroup component to use
          // w-full instead of this.
          width: "100%",
        }}
        value={
          !isMixedStyleValue(textAlign) ? textAlign ?? computedStyleValue : null
        }
        options={options}
        onChange={(newValue: string) => {
          const savedStyle = selectSavedStyle(store.getState());
          const actions = [];
          if (isMixedStyleValue(savedStyle)) {
            const actionsWithoutSavedStyles =
              getRemoveDesignLibraryValuesActions();
            if (actionsWithoutSavedStyles) {
              actions.push(...actionsWithoutSavedStyles);
            }
          }

          applyComponentAction({
            type: "applyCompositeAction",
            value: [
              ...actions,
              {
                type: "setStyles",
                value: { textAlign: newValue },
              },
            ],
          });
        }}
      />
    </ControlGroup>
  );
};

const TextFormatControl: React.FC = () => {
  const textDecorationFromSelector = useEditorSelector(selectTextDecoration);
  const fontStyle = useEditorSelector(selectFontStyle);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(textDecorationFromSelector) ?? null,
    )?.textDecoration;
  const textDecoration = deletedSavedStyleValue ?? textDecorationFromSelector;
  const store = useEditorStore();
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();
  const { tipTapEditor, queueAction, clearQueuedActions } =
    useRichTextComponent();

  const applyComponentAction = useApplyComponentAction();

  const selectedValues = filterNulls(
    [textDecoration, fontStyle].map((value) =>
      !isMixedStyleValue(value) ? String(value) : null,
    ),
  );

  function onChange(newValue: string, isSelected: boolean) {
    let chain = tipTapEditor?.chain().selectAll();
    let action: UseApplyComponentActionType | undefined;
    const actions = [];

    const savedStyle = selectSavedStyle(store.getState());
    if (isMixedStyleValue(savedStyle)) {
      const actionsWithoutSavedStyles = getRemoveDesignLibraryValuesActions();
      if (actionsWithoutSavedStyles) {
        actions.push(...actionsWithoutSavedStyles);
      }
    }

    if (newValue !== "italic") {
      let textDecoration;
      if (
        newValue === "line-through" &&
        textDecoration !== newValue &&
        isSelected
      ) {
        textDecoration = "line-through";
      } else if (
        newValue === "underline" &&
        textDecoration !== newValue &&
        isSelected
      ) {
        textDecoration = "underline";
      } else {
        textDecoration = "none";
      }

      action = {
        type: "applyCompositeAction",
        value: [
          ...actions,
          {
            type: "setStyles",
            value: {
              textDecoration,
            },
          },
        ],
      };

      queueAction(action);
      chain = chain?.unsetStrike().unsetUnderline();
    } else if (newValue === "italic") {
      let fontStyle;
      if (fontStyle !== newValue && isSelected) {
        fontStyle = "italic";
      } else {
        fontStyle = "normal";
      }

      action = {
        type: "applyCompositeAction",
        value: [
          ...actions,
          {
            type: "setStyles",
            value: {
              fontStyle,
            },
          },
        ],
      };

      queueAction(action);
      chain = chain?.unsetItalic();
    }
    const prevHtml = tipTapEditor?.getHTML();
    chain?.run();
    const postHtml = tipTapEditor?.getHTML();
    // if the html is the same, then we need to manually apply the changes
    if (prevHtml === postHtml && action) {
      applyComponentAction(action);
      clearQueuedActions();
    }
  }

  const options = [
    {
      value: "italic",
      label: <RiItalic size={16} />,
      tooltipContent: "Italic",
    },
    {
      value: "line-through",
      label: <RiStrikethrough size={16} />,
      tooltipContent: "Strikethrough",
    },
    {
      value: "underline",
      label: <RiUnderline size={16} />,
      tooltipContent: "Underline",
    },
  ];

  const handleOnChange = (values: string[]) => {
    if (selectedValues.length > values.length) {
      const [deselectValue] = differenceBy(selectedValues, values);
      // NOTE (Chance 2023-12-05): Non-null assertion is safe here because
      // we know checked that `selectedValues.length > values.length`, which
      // means it's not empty.
      onChange(deselectValue!, false);
    } else if (values.length > selectedValues.length) {
      // NOTE (Chance 2023-12-05): Non-null assertion is safe here because
      // we know checked that `values.length > selectedValues.length`, which
      // means it's not empty.
      const [selectValue] = differenceBy(values, selectedValues);
      onChange(selectValue!, true);
    }
  };

  return (
    <ControlGroup label="Format">
      <ToggleGroup
        type="multi"
        style={{
          // TODO (Fran 2024-10-09 REPL-14029): Make the changes need it in the ToggleGroup component to use
          // w-full instead of this.
          width: "100%",
        }}
        value={selectedValues}
        options={options}
        onChange={handleOnChange}
      />
    </ControlGroup>
  );
};

const TextTransformControl: React.FC<React.PropsWithChildren> = () => {
  const applyComponentAction = useApplyComponentAction();
  const store = useEditorStore();
  const textTransform = useEditorSelector(selectTextTransform);
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();

  const handleOnChange = (newValue: string | null) => {
    const actions = [];
    const savedStyle = selectSavedStyle(store.getState());
    if (isMixedStyleValue(savedStyle)) {
      const actionsWithoutSavedStyles = getRemoveDesignLibraryValuesActions();
      if (actionsWithoutSavedStyles) {
        actions.push(...actionsWithoutSavedStyles);
      }
    }

    applyComponentAction({
      type: "applyCompositeAction",
      value: [
        ...actions,
        {
          type: "setStyles",
          value: { textTransform: !newValue ? "none" : newValue },
        },
      ],
    });
  };

  return (
    <ControlGroup label="Casing">
      <ToggleGroup
        type="single"
        style={{
          // TODO (Fran 2024-10-09 REPL-14029): Make the changes need it in the ToggleGroup component to use
          // w-full instead of this.
          width: "100%",
        }}
        allowsDeselect
        value={
          !isMixedStyleValue(textTransform) ? textTransform ?? "none" : null
        }
        options={TEXT_TRANSFORM_OPTIONS}
        onChange={handleOnChange}
      />
    </ControlGroup>
  );
};

const ForegroundColorControl: React.FC = () => {
  const store = useEditorStore();
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();
  // NOTE (Fran 2024-04-15): Given the text color inheritance, we need to show if the component
  // has a color or if any ancestor text color will apply.
  const ancestorTextColor = useEditorSelector(selectAncestorTextColor);
  const color = useEditorSelector(selectColor);
  const colorGradientTilt = useEditorSelector(selectColorGradientTilt);
  const colorGradientStops = useEditorSelector(
    selectColorGradientStops,
  ) as GradientStop[];

  const applyComponentAction = useApplyComponentAction();

  // if there is a instance of the tipTapEditor being used by the rich text
  // control we must use the same one and queue our changes
  const { tipTapEditor, queueAction } = useRichTextComponent();

  const changeColor = (newValue: string | SolidOrGradient) => {
    let gradientOrSolid = null;
    if (typeof newValue === "string") {
      gradientOrSolid = newValue;
    } else if (newValue.type === "solid") {
      gradientOrSolid = newValue.color;
    } else {
      gradientOrSolid = newValue.gradient;
    }

    const newStyleValue =
      gradientOrSolid && typeof gradientOrSolid === "object"
        ? {
            color: "alchemy:gradient",
            __alchemyGradient__color__tilt: gradientOrSolid?.tilt,
            __alchemyGradient__color__stops: gradientOrSolid?.stops,
            // NOTE (Fran 2024-11-27): Remove gradient data from design library when setting gradient color
            __reploGradient__color__design_library: null,
          }
        : {
            color: gradientOrSolid,
            // NOTE (Fran 2024-11-27): Remove gradient data when setting solid color
            __alchemyGradient__color__tilt: null,
            __alchemyGradient__color__stops: null,
            __reploGradient__color__design_library: null,
          };

    const actions = [];
    const savedStyle = selectSavedStyle(store.getState());
    if (isMixedStyleValue(savedStyle)) {
      const actionsWithoutSavedStyles = getRemoveDesignLibraryValuesActions();
      if (actionsWithoutSavedStyles) {
        actions.push(...actionsWithoutSavedStyles);
      }
    }

    const action: UseApplyComponentActionType = {
      type: "applyCompositeAction",
      value: [
        ...actions,
        {
          type: "setStyles",
          value: newStyleValue,
        },
      ],
    };

    // If all text in the editor already has a color then we've got to unset it
    // in order for the changes to have an effect.
    if (tipTapEditor && isAllTextColored(tipTapEditor)) {
      queueAction(action);
      tipTapEditor?.chain().selectAll().unsetColor().run();
    } else {
      applyComponentAction(action);
    }
  };

  const modifierValue = color ?? ancestorTextColor;
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(modifierValue) ?? null,
    )?.color;
  const colorValue = deletedSavedStyleValue ?? modifierValue;

  const onRemove = () => {
    // NOTE (Fran 2024-05-09): If the color is not set we will show the inherited color from any
    // ancestor, so in this case, we should set the color to transparent. If the color is set, we
    // should remove it and show the inherited color.
    const onRemoveNewColor = color ? null : "#00000000";
    changeColor({
      type: "solid",
      color: onRemoveNewColor,
    });
  };

  const isMixedGradient =
    isMixedStyleValue(colorGradientTilt) ||
    isMixedStyleValue(colorGradientStops);

  return (
    <ControlGroup label="Color">
      <DynamicColorModifier
        previewProperty="color"
        popoverTitle="Text Color"
        gradientSelectionType="color"
        gradientData={
          isMixedGradient
            ? REPLO_MIXED_STYLE_VALUE
            : {
                tilt: coerceNumberToString(colorGradientTilt) ?? "90deg",
                stops: colorGradientStops ?? [
                  {
                    id: "c7795a8c-4e13-4011-889b-64adb0e11e41",
                    color: "#df9393",
                    location: "0%",
                  },
                ],
              }
        }
        field="style.color"
        value={colorValue ?? undefined}
        onChange={changeColor}
        onRemove={onRemove}
        popoverSideOffset={BADGE_TRIGGER_OFFSET}
        showSavedStyles
      />
    </ControlGroup>
  );
};

const TextOutlineControl: React.FC = () => {
  const logEvent = useLogAnalytics();
  const applyComponentAction = useApplyComponentAction();
  const store = useEditorStore();
  const componentId = useEditorSelector(selectDraftComponentId);
  const textOutlineFromSelector = useEditorSelector(selectTextOutline);
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();

  const widthValue = textOutlineFromSelector[0];
  const colorValue = textOutlineFromSelector[1];

  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      !isMixedStyleValue(colorValue) ? colorValue ?? null : null,
    )?.color;

  if (!componentId) {
    return null;
  }

  const textOutlineValue = deletedSavedStyleValue
    ? {
        width: widthValue ?? "1px",
        color: deletedSavedStyleValue,
      }
    : {
        width: widthValue ?? "1px",
        color: colorValue ?? "#000000",
      };

  const handleInputChange = (value: string, inputType: "width" | "color") => {
    let textOutlineString = null;
    if (value && value !== "0px") {
      const newTextOutline = {
        width:
          (!isMixedStyleValue(textOutlineValue?.width) &&
            textOutlineValue?.width) ||
          "1px",
        color: (() => {
          if (isMixedStyleValue(textOutlineValue?.color)) {
            return "#000000";
          }
          if (isDynamicDesignLibraryValue(textOutlineValue?.color)) {
            return textOutlineValue?.color;
          }
          return getHexColor(textOutlineValue?.color) || "#000000";
        })(),
        [inputType]: value,
      };
      textOutlineString = getTextOutlineString(newTextOutline);
    }

    const actions = [];
    const savedStyle = selectSavedStyle(store.getState());
    if (isMixedStyleValue(savedStyle)) {
      const actionsWithoutSavedStyles = getRemoveDesignLibraryValuesActions();
      if (actionsWithoutSavedStyles) {
        actions.push(...actionsWithoutSavedStyles);
      }
    }

    applyComponentAction({
      type: "applyCompositeAction",
      value: [
        ...actions,
        {
          type: "setStyles",
          value: {
            __textStroke: textOutlineString,
          },
        },
      ],
    });
  };

  const onSavedOutlineSelect = (value: string) => {
    let textOutlineString = null;

    if (value) {
      const newTextOutline = {
        width:
          (!isMixedStyleValue(textOutlineValue?.width) &&
            textOutlineValue?.width) ||
          "1px",
        color: value,
      };
      textOutlineString = getTextOutlineString(newTextOutline);
    }
    applyComponentAction({
      type: "setStyles",
      value: {
        __textStroke: textOutlineString,
      },
    });
    logEvent("library.style.apply", {
      modifier: "textOutlineColor",
      type: "color",
    });
  };

  return (
    <>
      <LengthInputSelector.Root
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        minDragValues={{ px: 0 }}
        minValues={{ px: 0 }}
        maxValues={{ px: 30 }}
        maxDragValues={{ px: 30 }}
        field="width"
        resetValue="0px"
        anchorValue="0px"
        value={textOutlineValue?.width ?? null}
        onChange={(value: string) => handleInputChange(value, "width")}
        previewProperty="__textStroke"
        previewSubProperty="width"
        dragTrigger="label"
      >
        {/* NOTE (Fran 2024-10-16): 74px is the fixed width of the label. */}
        <div className="grid grid-cols-[74px,auto] w-full gap-y-2 items-center">
          <LengthInputSelector.DraggableArea>
            <ModifierLabel label="Outline" />
          </LengthInputSelector.DraggableArea>
          <LengthInputSelector.Input
            placeholder="0px"
            startEnhancer={<BsBorderWidth />}
          />
          <div className="col-start-2">
            <DynamicColorSelector
              field="color"
              componentId={componentId}
              allowsGradientSelection={false}
              popoverTitle="Color"
              value={textOutlineValue?.color ?? null}
              onChange={(value: string) => handleInputChange(value, "color")}
              popoverSideOffset={BADGE_TRIGGER_OFFSET}
              showSavedStyles
              onSelectSavedStyle={onSavedOutlineSelect}
            />
          </div>
        </div>
      </LengthInputSelector.Root>
    </>
  );
};

const TextShadowControl: React.FC = () => {
  const textShadow = useEditorSelector(selectTextShadow);
  const textShadows = useEditorSelector(selectTextShadows);
  const savedStyle = useEditorSelector(selectSavedStyle);
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();

  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(textShadow) ?? null,
    )?.textShadow;
  const savedStyleParsedTextShadows = deletedSavedStyleValue
    ? parseTextShadows(deletedSavedStyleValue.split(",") ?? "")
    : // TODO (Fran 2024-11-29 REPL-14885): Allow add a text shadow if there is no shadow selected in the
      // deleted saved style
      [DEFAULT_TEXT_SHADOW];

  const resolvedTextShadows = isDynamicDesignLibraryValue(String(textShadow))
    ? savedStyleParsedTextShadows
    : textShadows;

  const openPopoverId = useEditorSelector(selectOpenPopoverId);
  const dispatch = useEditorDispatch();

  const applyComponentAction = useApplyComponentAction();

  const { colorSavedStyles } = useGetDesignLibrarySavedStyles();

  if (!resolvedTextShadows) {
    return null;
  }

  const isPopoverOpen =
    openPopoverId &&
    typeof openPopoverId === "object" &&
    "text-shadow" in openPopoverId;

  const currentShadowIndex = isPopoverOpen
    ? openPopoverId["text-shadow"]
    : null;
  const currentShadow =
    currentShadowIndex != null ? resolvedTextShadows[currentShadowIndex] : null;

  const createOrUpdateTextShadow = (value: string) => {
    const actions = [];
    if (isMixedStyleValue(savedStyle)) {
      const actionsWithoutSavedStyles = getRemoveDesignLibraryValuesActions();
      if (actionsWithoutSavedStyles) {
        actions.push(...actionsWithoutSavedStyles);
      }
    }

    applyComponentAction({
      type: "applyCompositeAction",
      value: [
        ...actions,
        {
          type: "setStyles",
          value: {
            textShadow: value,
          },
        },
      ],
    });
  };

  const handleRemoveTextShadow = (index: number) => {
    // TODO (Martin 2025-01-22): Figure out how to remove a single value since
    // we store shadows as a single string.
    if (
      resolvedTextShadows.some((textShadow) => isMixedStyleValue(textShadow))
    ) {
      createOrUpdateTextShadow("none");
      return;
    }

    const filteredTextShadows = resolvedTextShadows.filter(
      (_, shadowIndex) => shadowIndex !== index,
    );
    const updatedTextShadows = getTextShadowString(
      filteredTextShadows as TextShadow[],
    );
    createOrUpdateTextShadow(updatedTextShadows);
  };

  const handleTextShadowChange = (value: TextShadow, index: number) => {
    // TODO (Martin 2025-01-22): Figure out how to update a single value since
    // we store shadows as a single string.
    if (
      resolvedTextShadows.some((textShadow) => isMixedStyleValue(textShadow))
    ) {
      createOrUpdateTextShadow(getTextShadowString([value]));
      return;
    }

    const newTextShadows = resolvedTextShadows.map((textShadow, i) => {
      return i === index ? value : textShadow;
    });
    const updatedTextShadows = getTextShadowString(
      newTextShadows as TextShadow[],
    );
    createOrUpdateTextShadow(updatedTextShadows);
  };

  const getBadgeColor = (color?: string) => {
    if (color && isDynamicDesignLibraryValue(color)) {
      const savedStyle = getSavedStyleValue(colorSavedStyles, color);
      return savedStyle?.attributes && "color" in savedStyle.attributes
        ? String(savedStyle.attributes.color)
        : "text-subtle";
    }
    return color ?? "text-subtle";
  };

  return (
    // NOTE (Fran 2024-10-16): 74px is the fixed width of the label.
    <div className="grid grid-cols-[74px,auto] w-full items-center">
      <ModifierLabel label="Shadow" />
      {resolvedTextShadows.map((textShadow, index) => {
        const isMixedTextShadow = isMixedStyleValue(textShadow);
        return (
          <div className="col-start-2 py-1" key={`textShadow-${index}`}>
            <SelectionIndicator
              className="max-w-40"
              title={
                !isMixedTextShadow
                  ? formatTitle(textShadow, colorSavedStyles)
                  : "Mixed"
              }
              onClick={() =>
                dispatch(setOpenPopoverId({ "text-shadow": index }))
              }
              startEnhancer={
                <Badge
                  type={!isMixedTextShadow ? "color" : "mixed"}
                  isFilled
                  backgroundColor={
                    !isMixedTextShadow ? getBadgeColor(textShadow.color) : ""
                  }
                />
              }
              endEnhancer={
                <FormFieldXButton
                  onClick={(event) => {
                    // NOTE (Sebas, 2024-10-10): This is necessary to prevent executing the onClick event to avoid open
                    // the popover when the user wants to remove the shadow.
                    event.stopPropagation();
                    handleRemoveTextShadow(index);
                  }}
                />
              }
            />
          </div>
        );
      })}
      {currentShadow ? (
        <TextShadowPopover
          isOpen={isPopoverOpen ?? false}
          activeTextShadow={currentShadow}
          handleTextShadowChange={(value) =>
            handleTextShadowChange(value, currentShadowIndex ?? 0)
          }
          textShadowIndex={currentShadowIndex ?? 0}
          onRequestClose={() => dispatch(setOpenPopoverId(null))}
        />
      ) : null}
    </div>
  );
};

const TextShadowPopover: React.FC<
  React.PropsWithChildren<{
    isOpen: boolean;
    activeTextShadow: TextShadow | ReploMixedStyleValue;
    textShadowIndex: number;
    handleTextShadowChange(value: TextShadow, index: number): void;
    onRequestClose(): void;
  }>
> = ({
  isOpen,
  activeTextShadow,
  handleTextShadowChange,
  textShadowIndex,
  onRequestClose,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const isMixedTextShadow = isMixedStyleValue(activeTextShadow);

  const handleInputChange = (
    value: string,
    inputType: "offsetX" | "offsetY" | "blur" | "color",
  ) => {
    const newTextShadow = {
      ...(!isMixedTextShadow ? activeTextShadow : DEFAULT_TEXT_SHADOW),
      [inputType]: value,
    };
    handleTextShadowChange(newTextShadow, textShadowIndex);
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={(newValue: boolean) => {
        if (!newValue) {
          onRequestClose();
        }
      }}
    >
      <Popover.Content
        title="Text Shadow"
        shouldPreventDefaultOnInteractOutside={areModalsOpen}
        onRequestClose={onRequestClose}
      >
        <TextShadowPopoverContent
          offsetX={
            !isMixedTextShadow ? activeTextShadow.offsetX : activeTextShadow
          }
          offsetY={
            !isMixedTextShadow ? activeTextShadow.offsetY : activeTextShadow
          }
          blur={!isMixedTextShadow ? activeTextShadow.blur : activeTextShadow}
          color={!isMixedTextShadow ? activeTextShadow.color : activeTextShadow}
          textShadowIndex={textShadowIndex}
          handleInputChange={handleInputChange}
        />
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};

const TextShadowPopoverContent: React.FC<{
  offsetX?: string | ReploMixedStyleValue;
  offsetY?: string | ReploMixedStyleValue;
  blur?: string | ReploMixedStyleValue;
  color?: string | ReploMixedStyleValue;
  textShadowIndex: number;
  handleInputChange(
    value: string,
    inputType: "offsetX" | "offsetY" | "blur" | "color",
  ): void;
}> = ({
  offsetX,
  offsetY,
  blur,
  color,
  textShadowIndex,
  handleInputChange,
}) => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const logEvent = useLogAnalytics();

  return (
    <div className="flex flex-col gap-2">
      <LengthInputSelector
        label={<ModifierLabel label="X Axis" />}
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        field="offsetX"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        value={offsetX || null}
        onChange={(value: string) => handleInputChange(value, "offsetX")}
        previewProperty="textShadow"
        previewSubProperty="offsetX"
        previewPropertyIndex={textShadowIndex}
        draggingDirection={DraggingDirections.Negative}
        autofocus
        dragTrigger="label"
      />
      <LengthInputSelector
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        label={<ModifierLabel label="Y Axis" />}
        field="offsetY"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        value={offsetY || null}
        onChange={(value: string) => handleInputChange(value, "offsetY")}
        previewProperty="textShadow"
        previewSubProperty="offsetY"
        previewPropertyIndex={textShadowIndex}
        dragTrigger="label"
        draggingDirection={DraggingDirections.Negative}
      />
      <LengthInputSelector
        label={<ModifierLabel label="Blur" />}
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        minDragValues={{ px: 0 }}
        minValues={{ px: 0 }}
        field="blur"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        value={blur || null}
        onChange={(value: string) => handleInputChange(value, "blur")}
        previewProperty="textShadow"
        previewSubProperty="blur"
        previewPropertyIndex={textShadowIndex}
        dragTrigger="label"
      />
      <div className="flex items-center">
        <ModifierLabel label="Color" />
        <DynamicColorSelector
          field="color"
          componentId={draftComponentId ?? ""}
          allowsGradientSelection={false}
          popoverTitle="Color"
          value={color ?? null}
          onChange={(value: string) => handleInputChange(value, "color")}
          showSavedStyles
          onSelectSavedStyle={(value: string) => {
            logEvent("library.style.apply", {
              modifier: "textShadowColor",
              type: "color",
            });
            handleInputChange(value, "color");
          }}
          onRemove={() => handleInputChange("", "color")}
        />
      </div>
    </div>
  );
};

const TextSavedStyleGroup: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const savedStyle = useEditorSelector(selectSavedStyle);
  const store = useEditorStore();
  const logEvent = useLogAnalytics();
  const { getRemoveDesignLibraryValuesActions } =
    useResetDesignLibraryTextValue();
  const onSelectSavedStyle = (value: string) => {
    const textsAndIds = selectDraftComponentsInnerTextsAndIds(store.getState());

    logEvent("library.style.apply", {
      type: "text",
      modifier: "text",
    });

    const setPropActions =
      textsAndIds?.map(({ id, text }) => ({
        type: "setProps" as const,
        componentId: id,
        value: {
          text: `<{{ ${value}.htmlTag }}>${text}</{{ ${value}.htmlTag }}>`,
        },
      })) ?? [];

    applyComponentAction({
      type: "applyCompositeAction",
      value: [
        ...setPropActions,
        {
          type: "setStyles",
          value: {
            color: `{{ ${value}.color }}`,
            fontFamily: `{{ ${value}.fontFamily }}`,
            fontSize: `{{ ${value}.fontSize }}`,
            fontWeight: `{{ ${value}.fontWeight }}`,
            letterSpacing: `{{ ${value}.letterSpacing }}`,
            lineHeight: `{{ ${value}.lineHeight }}`,
            textAlign: `{{ ${value}.textAlign }}`,
            textDecoration: `{{ ${value}.textDecoration }}`,
            fontStyle: `{{ ${value}.textDecoration }}`,
            textTransform: `{{ ${value}.textTransform }}`,
            textShadow: `{{ ${value}.textShadow }}`,
            __textStroke: `{{ ${value}.textStroke }}`,
          },
        },
      ],
    });
  };

  const onSavedStyleRemove = () => {
    const textsAndIds = selectDraftComponentsInnerTextsAndIds(store.getState());

    if (isMixedStyleValue(savedStyle)) {
      const actionsWithoutSavedStyles = getRemoveDesignLibraryValuesActions();
      if (actionsWithoutSavedStyles) {
        applyComponentAction({
          type: "applyCompositeAction",
          value: actionsWithoutSavedStyles,
        });
      }
    } else {
      const setPropActions =
        textsAndIds?.map(({ id, text }) => ({
          type: "setProps" as const,
          componentId: id,
          value: {
            text: `<p>${text}</p>`,
          },
        })) ?? [];

      applyComponentAction({
        type: "applyCompositeAction",
        value: [
          ...setPropActions,
          {
            type: "setStyles",
            value: {
              color: null,
              fontFamily: null,
              fontSize: null,
              fontWeight: null,
              letterSpacing: null,
              lineHeight: null,
              textAlign: null,
              textDecoration: null,
              textTransform: null,
              textShadow: null,
              __textStroke: null,
            },
          },
        ],
      });
    }
  };

  return (
    <ControlGroup label="Style">
      <DesignLibraryTextValueIndicator
        popoverSideOffset={84}
        savedStyleValueReference={
          isMixedStyleValue(savedStyle) ? savedStyle : String(savedStyle) ?? ""
        }
        onSelectSavedStyle={onSelectSavedStyle}
        onRemove={onSavedStyleRemove}
      />
    </ControlGroup>
  );
};

function useTextStyleHotkeys() {
  const globalActions = useGlobalEditorActions();
  const { isMenuOpen: isAIMenuOpen } = useAIStreaming();
  const enableNonDynamicTextEditing = useEnableNonDynamicTextEditing();
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);

  useReploHotkeys({
    toggleBoldText: globalActions.toggleBoldText,
    toggleH1Text: globalActions.toggleH1Text,
    toggleH2Text: globalActions.toggleH2Text,
    toggleH3Text: globalActions.toggleH3Text,
    toggleH4Text: globalActions.toggleH4Text,
    toggleH5Text: globalActions.toggleH5Text,
    toggleH6Text: globalActions.toggleH6Text,
    toggleBulletList: globalActions.toggleBulletList,
    toggleNumberedList: globalActions.toggleNumberedList,
    toggleLinkText: globalActions.toggleLinkText,
    toggleItalicText: globalActions.toggleItalicText,
    toggleUnderlineText: globalActions.toggleUnderlineText,
    toggleStrikethroughText: globalActions.toggleStrikethroughText,
    decreaseFontSize: globalActions.decreaseFontSize,
    increaseFontSize: globalActions.increaseFontSize,
    decreaseLetterSpacing: globalActions.decreaseLetterSpacing,
    increaseLetterSpacing: globalActions.increaseLetterSpacing,
    decreaseLineHeight: globalActions.decreaseLineHeight,
    increaseLineHeight: globalActions.increaseLineHeight,
    decreaseFontWeight: globalActions.decreaseFontWeight,
    increaseFontWeight: globalActions.increaseFontWeight,
    ...(!isAIMenuOpen && !areModalsOpen
      ? {
          editText: [
            enableNonDynamicTextEditing,
            { disallowWhenDialogsAndPopoversOpen: true },
          ],
        }
      : {}),
  });
}

const useAddDefaultShadow = () => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const dispatch = useEditorDispatch();

  return () => {
    const draftComponentTextShadow = selectTextShadow(store.getState());
    const draftComponentId = selectDraftComponentId(store.getState());
    const draftComponentTextShadows = !isMixedStyleValue(
      draftComponentTextShadow,
    )
      ? draftComponentTextShadow?.split(",") ?? []
      : [];
    const textShadows =
      draftComponentTextShadows.length > 0
        ? parseTextShadows(draftComponentTextShadows)
        : [];
    const updatedTextShadows = getTextShadowString([
      ...textShadows,
      DEFAULT_TEXT_SHADOW,
    ]);

    const lastIndex = textShadows.length;
    dispatch(setOpenPopoverId({ "text-shadow": lastIndex }));

    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: {
        textShadow: updatedTextShadows,
      },
    });
  };
};

export default TextStyleModifier;
