import * as React from "react";

import { Group } from "@common/designSystem/Group";
import { selectDraftElementColors } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { Badge } from "@replo/design-system/components/badge/Badge";
import Tooltip from "@replo/design-system/components/tooltip/Tooltip";
import { isEmpty } from "replo-utils/lib/misc";

const PageColors: React.FC<{
  onDocumentColorChange: (value: string) => void;
}> = ({ onDocumentColorChange }) => {
  const elementColors = useEditorSelector(selectDraftElementColors);

  if (isEmpty(elementColors)) {
    return null;
  }

  return (
    <Group
      name="Page Colors"
      isCollapsible={false}
      className="w-full"
      contentClassName="py-2"
    >
      <div className="flex flex-wrap gap-x-2 gap-y-1">
        {elementColors.map((color, i) => (
          <Tooltip content={color.toUpperCase()} key={i} triggerAsChild>
            <div
              role="button"
              tabIndex={0}
              onClick={() => onDocumentColorChange(color)}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  event.preventDefault();
                  event.currentTarget.click();
                }
              }}
            >
              <Badge type="color" isFilled backgroundColor={color} />
            </div>
          </Tooltip>
        ))}
      </div>
    </Group>
  );
};

export default PageColors;
