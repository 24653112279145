import React from "react";

import { Skeleton } from "@common/designSystem/SkeletonLoader";

export const SavedStyleSkeleton = () => {
  return (
    <div className="flex flex-col gap-3 pt-2">
      {new Array(4).fill(0).map((_, index) => {
        const isEven = index % 2 === 0;
        return (
          <div key={index} className="flex items-center gap-2">
            <Skeleton className="h-4 w-4" />
            <Skeleton className={isEven ? "h-4 w-32" : "h-4 w-24"} />
            <Skeleton className="h-4 w-12" />
          </div>
        );
      })}
    </div>
  );
};
