import type { SolidOrGradient } from "replo-runtime/shared/types";
import type { ReploMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

import * as React from "react";

import SavedStylesColorList from "@common/designSystem/color/SavedStylesColorList";
import Separator from "@common/designSystem/Separator";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";

import InlinePopover from "@replo/design-system/components/popover/InlinePopover";

type ColorPickerWrapperProps = {
  trigger: React.ReactNode;
  content: React.ReactNode;
  value: string | SolidOrGradient | ReploMixedStyleValue | null;
  popoverTitle?: string;
  popoverSideOffset?: number;
  popoverSide?: "top" | "right" | "bottom" | "left";
  isPopoverOpen?: boolean;
  showSavedStyles?: boolean;
  allowsGradientSelection?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  onSelectSavedStyle?: (value: string) => void;
  onInteractOutside?: (event: CustomEvent) => void;
};

const ColorPickerWrapper: React.FC<ColorPickerWrapperProps> = ({
  content,
  trigger,
  value,
  popoverTitle,
  isPopoverOpen,
  popoverSideOffset,
  popoverSide,
  showSavedStyles,
  allowsGradientSelection,
  onOpenPopoverChange,
  onSelectSavedStyle,
  onInteractOutside,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);

  return (
    <InlinePopover
      title={popoverTitle}
      isOpen={isPopoverOpen}
      onOpenChange={onOpenPopoverChange}
      shouldPreventDefaultOnInteractOutside={areModalsOpen}
      sideOffset={popoverSideOffset}
      side={popoverSide}
      onInteractOutside={onInteractOutside}
      onRequestClose={() => {
        onOpenPopoverChange?.(false);
      }}
      content={
        <div className="flex flex-col gap-2 w-full">
          {content}
          {showSavedStyles && (
            <>
              <Separator />
              <SavedStylesColorList
                onSelectSavedStyle={onSelectSavedStyle}
                allowsGradientSelection={allowsGradientSelection}
                value={value}
              />
            </>
          )}
        </div>
      }
    >
      <div
        data-testid={
          popoverTitle
            ? `${popoverTitle.toLowerCase()}-gradient-selector`
            : undefined
        }
      >
        {trigger}
      </div>
    </InlinePopover>
  );
};

export default ColorPickerWrapper;
