import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const accordionBlock: ComponentTemplate = {
  id: "35625d1a-3c26-2bdc-b700-c4a39d918e00",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Component/Accordion",
  leftBarCategory: { main: "interactive", subCategory: "toggles" },
  preview: "component/accordion.svg",
  transforms: [],
  template: {
    id: "61253b3e-1961-41b1-941e-46c78f735165",
    name: "Accordion Block 1",
    type: "accordionBlock",
    props: {
      style: {
        display: "flex",
        alignSelf: "stretch",
        flexDirection: "column",
      },
      _autoClose: true,
    },
    children: [
      {
        id: "f801f76c-9d9f-4886-9883-15e4f3a75da2",
        name: "Collapsible 1",
        type: "collapsibleV2",
        props: {
          style: {
            display: "flex",
            flexGrow: 1,
            alignSelf: "stretch",
            flexDirection: "column",
          },
          _openComponent: {
            id: "05ca1d1d-df81-44aa-8b94-182692dc7960",
            name: "Collapsible Header",
            type: "collapsibleV2Header",
            props: {
              style: {
                display: "flex",
                alignItems: "center",
                paddingTop: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingBottom: "12px",
                justifyContent: "space-between",
                borderBottomColor: "#000000",
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                __animateVariantTransitions: true,
              },
              onClick: [
                {
                  id: "846df0f1-b7db-4b56-8832-a5f06b034cbd",
                  type: "toggleCollapsible",
                  value: null,
                },
              ],
            },
            children: [
              {
                id: "4123eea5-2039-48e3-8861-1c1d7fad537c",
                type: "text",
                props: {
                  text: "<p>Click To Open</p>",
                  style: {
                    color: "black",
                    fontSize: "16px",
                    lineHeight: "20px",
                  },
                },
              },
              {
                id: "41b7ebb4-27c7-444f-b726-a46c5bf31638",
                type: "icon",
                props: {
                  style: {
                    width: "24px",
                    height: "24px",
                    __iconAltText: "Collapse/Expand",
                  },
                  iconName: "chevronDown",
                  _accessibilityHidden: true,
                },
              },
            ],
            variants: [
              {
                id: "67619ff4-b830-4400-ac3b-6d92fbfc6fdb",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "016f335c-0c60-448c-94a5-11773193b41b",
                name: "Collapsible Open",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "cf57cf8a-9404-42ac-8c0b-79f432c55087",
                      field: "state.collapsibleV2.isOpen",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "016f335c-0c60-448c-94a5-11773193b41b": {
                componentOverrides: {
                  "41b7ebb4-27c7-444f-b726-a46c5bf31638": {
                    props: {
                      iconName: "chevronUp",
                    },
                  },
                },
              },
            },
          },
          _collapsibleComponent: {
            id: "856f3f3a-3b5e-42a5-9349-bb67631ccc97",
            name: "Collapsible Content",
            type: "collapsibleV2Content",
            props: {
              style: {
                display: "flex",
                paddingTop: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
                flexDirection: "column",
                paddingBottom: "12px",
              },
            },
            children: [
              {
                id: "117de459-a997-44d7-b466-a0e84d293aed",
                type: "text",
                props: {
                  text: "<p>This content will be hidden until the user opens the collapsible. You can replace this content with whatever you feel should be hidden.</p>",
                  style: {
                    color: "black",
                    width: "100%",
                    __width: "100%",
                    fontSize: "14px",
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
        },
      },
      {
        id: "b92b9363-54e1-4e99-9eee-15d78a7daaaf",
        name: "Collapsible 2",
        type: "collapsibleV2",
        props: {
          style: {
            display: "flex",
            flexGrow: 1,
            alignSelf: "stretch",
            flexDirection: "column",
          },
          _openComponent: {
            id: "80073508-b62d-41a7-a0a3-1e3e32b88717",
            name: "Collapsible Header",
            type: "collapsibleV2Header",
            props: {
              style: {
                display: "flex",
                alignItems: "center",
                paddingTop: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingBottom: "12px",
                justifyContent: "space-between",
                borderBottomColor: "#000000",
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                __animateVariantTransitions: true,
              },
              onClick: [
                {
                  id: "8446c168-d38f-47c9-bd08-ca9d1dbf80ce",
                  type: "toggleCollapsible",
                  value: null,
                },
              ],
            },
            children: [
              {
                id: "8e2e3cba-630d-4b19-be60-e680d9728a3a",
                type: "text",
                props: {
                  text: "<p>Click To Open</p>",
                  style: {
                    color: "black",
                    fontSize: "16px",
                    lineHeight: "20px",
                  },
                },
              },
              {
                id: "57dfe4ac-7f77-41a7-92f1-105783a9bcd6",
                type: "icon",
                props: {
                  style: {
                    width: "24px",
                    height: "24px",
                    __iconAltText: "Collapse/Expand",
                  },
                  iconName: "chevronDown",
                  _accessibilityHidden: true,
                },
              },
            ],
            variants: [
              {
                id: "6f0d2687-c9f5-4eef-a0ee-5cf9ae900956",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "55298b21-8dda-4414-99d5-fb4e3bfd487a",
                name: "Collapsible Open",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "cf57cf8a-9404-42ac-8c0b-79f432c55087",
                      field: "state.collapsibleV2.isOpen",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "55298b21-8dda-4414-99d5-fb4e3bfd487a": {
                componentOverrides: {
                  "57dfe4ac-7f77-41a7-92f1-105783a9bcd6": {
                    props: {
                      iconName: "chevronUp",
                    },
                  },
                },
              },
            },
          },
          _collapsibleComponent: {
            id: "0546262b-9904-4c0e-8332-c4be3c1e1c5f",
            name: "Collapsible Content",
            type: "collapsibleV2Content",
            props: {
              style: {
                display: "flex",
                paddingTop: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
                flexDirection: "column",
                paddingBottom: "12px",
              },
            },
            children: [
              {
                id: "7cb1c7c6-b145-4f2d-a402-048bf949f1e2",
                type: "text",
                props: {
                  text: "<p>This content will be hidden until the user opens the collapsible. You can replace this content with whatever you feel should be hidden.</p>",
                  style: {
                    color: "black",
                    width: "100%",
                    __width: "100%",
                    fontSize: "14px",
                    lineHeight: "24px",
                  },
                },
              },
            ],
          },
        },
      },
    ],
  },
};
