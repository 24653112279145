import type { Gradient } from "replo-runtime/shared/types.ts";

// NOTE (Matt 2024-12-20): Currently we only support linear gradients,
// but if we start to support more then we can use this util to verify
// if a css value is a gradient.
export function isCssGradient(cssString: string) {
  return cssString.startsWith("linear-gradient");
}

export function gradientToCssGradient(
  gradient: Partial<Gradient>,
): string | null {
  let stops = gradient.stops?.map((stop) => {
    return `${stop.color} ${stop.location}`;
  });

  if (stops?.length === 0) {
    return null;
  }

  if (stops?.length === 1) {
    stops = stops.concat(stops);
  }

  return `linear-gradient(${gradient.tilt}, ${stops?.join(", ")})`;
}

export function cssGradientToGradient(gradientString: string): Gradient {
  const tiltRegex = /linear-gradient\((\d+deg)/;
  const stopsRegex = /([\w#]+)\s+([\d.]+%)/g;

  const tiltMatch = gradientString.match(tiltRegex);
  const tilt = tiltMatch ? tiltMatch[1]! : "0deg";

  const stops: { color: string; location: string; id: string }[] = [];
  let match = stopsRegex.exec(gradientString);

  while (match !== null) {
    stops.push({
      color: match[1]!,
      location: match[2]!,
      // NOTE (Fran 2024-12-06): We need to generate a unique id for each stop and we need to have an
      // stable id until the gradient is changed.
      id: `${match[1]}-${match[2]}-${stops.length}`,
    });
    match = stopsRegex.exec(gradientString);
  }

  return { tilt, stops };
}
