import type {
  CloseModalPayload,
  CloseModalsWithExclusionsPayload,
  OpenModalPayload,
} from "@editor/reducers/modals-reducer";

import * as React from "react";

import {
  closeModal,
  closeModalsWithExclusions,
  openModal,
  selectModals,
} from "@editor/reducers/modals-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

export const useModal = () => {
  const dispatch = useEditorDispatch();
  const modals = useEditorSelector(selectModals);

  return {
    modals,
    openModal: React.useCallback(
      (payload: OpenModalPayload) => dispatch(openModal(payload)),
      [dispatch],
    ),
    closeModal: React.useCallback(
      (payload: CloseModalPayload) => dispatch(closeModal(payload)),
      [dispatch],
    ),
    closeModalsWithExclusions: React.useCallback(
      (payload: CloseModalsWithExclusionsPayload) =>
        dispatch(closeModalsWithExclusions(payload)),
      [dispatch],
    ),
  };
};

export const useOpenModal = () => {
  const dispatch = useEditorDispatch();
  return React.useCallback(
    (payload: OpenModalPayload) => {
      return dispatch(openModal(payload));
    },
    [dispatch],
  );
};
