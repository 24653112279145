import type { ClassValue } from "clsx";

import twMerge from "@replo/design-system/utils/twMerge";
import { clsx } from "clsx";

const clsxMerge = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export default clsxMerge;
