import * as React from "react";

import { selectSrcDocFontUrls } from "../../reducers/core-reducer";
import { useEditorSelector } from "../../store";
import getFontFamilyNameFromSrcUrl from "../../utils/getFontFamilyNameFromSrcUrl";

const getFontType = (url: string): string | null => {
  if (url.toLowerCase().match(/\.woff2($|\?|#)/)) {
    return "woff2";
  } else if (url.toLowerCase().match(/\.woff($|\?|#)/)) {
    return "woff";
  }
  return null;
};

// NOTE (Jackson, 2024-12-18): This component is used to load fonts into the editor based
// on mirror response data. We'll attempt to get the proper font family name from the URL and then
// create a style element to load the font.
export function SrcDocFontLoader() {
  const fontUrls = useEditorSelector(selectSrcDocFontUrls);

  React.useEffect(() => {
    const existingFontLinks = document.head.querySelectorAll(
      "[data-src-doc-font]",
    );
    existingFontLinks.forEach((link) => link.remove());

    for (const url of fontUrls) {
      try {
        const fontFamily = decodeURIComponent(getFontFamilyNameFromSrcUrl(url));
        const fontType = getFontType(url);

        if (!fontType) {
          console.warn("Unsupported font format:", url);
          return;
        }

        // NOTE (Jackson, 2024-12-18): This should help us get around occasional cors issues
        const linkElement = document.createElement("link");
        linkElement.dataset.srcDocFont = "true";
        linkElement.rel = "preload";
        linkElement.href = url;
        linkElement.as = "font";
        linkElement.type = `font/${fontType}`;
        linkElement.crossOrigin = "anonymous";
        document.head.append(linkElement);

        // Create the @font-face
        const styleElement = document.createElement("style");
        styleElement.dataset.srcDocFont = "true";
        styleElement.textContent = `
          @font-face {
            font-family: ${fontFamily};
            src: url('${url}') format('${fontType}');
          }
        `;
        document.head.append(styleElement);
      } catch (error) {
        console.error("Failed to load font:", error, "URL:", url);
      }
    }

    // Cleanup on unmount
    return () => {
      const elements = document.head.querySelectorAll("[data-src-doc-font]");
      elements.forEach((element) => element.remove());
    };
  }, [fontUrls]);

  return null;
}
