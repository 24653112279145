import * as React from "react";

import { useTargetFrameDocument } from "@editor/hooks/useTargetFrame";
import { selectFontFamily } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { FONT_WEIGHT_OPTIONS } from "replo-runtime/shared/utils/font";
import { isMixedStyleValue } from "replo-runtime/store/utils/mixed-values";

const calculateFontWeights = (
  paintableDocument: Document | null,
  fontFamily: string | null,
  isSafeOrNotAvailableFont?: boolean,
) => {
  if (!fontFamily || isSafeOrNotAvailableFont || !paintableDocument) {
    return FONT_WEIGHT_OPTIONS.map(({ label, value }) => ({
      label,
      value: value.toString(),
    }));
  }

  const editorFontsArray = Array.from(paintableDocument.fonts);
  return FONT_WEIGHT_OPTIONS.filter((fontWeight) =>
    editorFontsArray
      .filter((font) => fontFamily.includes(font.family))
      .find((font) => font.weight === fontWeight.value.toString()),
  ).map(({ label, value }) => ({
    label,
    value: value.toString(),
  }));
};

export const useFontWeightOptions = () => {
  const fontFamily = useEditorSelector(selectFontFamily) ?? null;
  // NOTE (Chance 2024-06-24): It shouldn't matter which canvas we use here
  // since the DOM contents should be the same for each. We just need to read
  // the document's `fonts` property.
  const targetFrameDocument = useTargetFrameDocument("desktop");
  // NOTE (Sebas, 2023-03-27): This counter is required to avoid
  // an infinite loop in case the font is not found on the document.
  // This can happen if the user selects a safe font, like Arial,
  // or if the font is not being imported correctly.
  const intervalCounter = React.useRef(0);
  const [options, setOptions] = React.useState(
    calculateFontWeights(
      targetFrameDocument,
      !isMixedStyleValue(fontFamily) ? fontFamily : null,
      true,
    ),
  );

  // NOTE (Sebas, 2023-03-21): We need this effect because we need a timeout in case the
  // user selects a new font we need to wait for the font to be downloaded/added to the
  // DOM and then calculate the available weights for that font.
  React.useEffect(() => {
    const interval = setInterval(() => {
      const newOptions = calculateFontWeights(
        targetFrameDocument,
        !isMixedStyleValue(fontFamily) ? fontFamily : null,
        intervalCounter.current > 5,
      );
      intervalCounter.current += 1;
      if (newOptions.length > 0) {
        // NOTE (Fran 2025-01-07): We only want to set the options if the new options are not empty
        // because we don't want to show an error until the font is downloaded to the DOM.
        setOptions(newOptions);
        clearInterval(interval);
        intervalCounter.current = 0;
      }
    }, 100);
    return () => {
      clearInterval(interval);
      intervalCounter.current = 0;
    };
  }, [fontFamily, targetFrameDocument]);

  return options;
};
