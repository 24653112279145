import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const JUDGE_PRODUCT_RATING_WIDGET_LIQUID_SOURCE = `
  <!-- Start of Judge.me code -->
    <div style='{{ jm_style }}' class='jdgm-widget jdgm-preview-badge' data-id='{{ product.id }}'>
      {{ product.metafields.judgeme.badge }}
    </div>
  <!-- End of Judge.me code -->
`;

function JudgeProductRatingWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: JUDGE_PRODUCT_RATING_WIDGET_LIQUID_SOURCE,
      context: props.context,
    },
  );
  return (
    <SharedShopifyLiquid
      componentAttributes={componentAttributes}
      component={component}
      componentId={component.id}
      liquidSource={liquidSourceWithProductAssignment}
      placeholder="Judge.me Product Rating Widget will appear here"
      forceEditorPlaceholder={true}
      placeholderTitle="Judge.me Product Rating"
      placeholderType="reviewStars"
      repeatedIndexPath={context.repeatedIndexPath}
      isLiquidSupported
    />
  );
}

export default JudgeProductRatingWidget;
