import { z } from "zod";

import {
  savedStyleColorAttributesSchema,
  savedStyleTextAttributesSchema,
} from "./savedStyles";

export const savedColorStyleSchema = z
  .object({
    type: z.literal("color"),
    name: z.string(),
    attributes: savedStyleColorAttributesSchema,
    optionalUsageGuidelines: z.string().nullish(),
    deletedAt: z.date().nullish(),
    isPrimary: z.boolean().optional(),
  })
  .describe("SavedColorStyle");

export const savedTextStyleSchema = z
  .object({
    type: z.literal("text"),
    name: z.string(),
    attributes: savedStyleTextAttributesSchema,
    optionalUsageGuidelines: z.string().nullish(),
    deletedAt: z.date().nullish(),
    isPrimary: z.boolean().optional(),
  })
  .describe("SavedTextStyle");

export const designLibrarySavedStylesSchema = z
  .record(z.string(), z.union([savedColorStyleSchema, savedTextStyleSchema]))
  .describe("DesignLibrarySavedStylesMapping");

export const designLibrarySchema = z
  .object({
    id: z.string().uuid(),
    createdAt: z.date(),
    updatedAt: z.date(),
    deletedAt: z.date().nullish(),
    savedStyles: designLibrarySavedStylesSchema,
  })
  .describe("DesignLibrary");

export const designLibrarySavedStyleMetadataSchema = z
  .object({
    library: z.record(
      z.string(),
      z.object({
        styles: designLibrarySavedStylesSchema,
      }),
    ),
  })
  .describe("DesignLibrarySavedStyleMetadata");
