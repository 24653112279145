import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const paymentTerms: ComponentTemplate = {
  id: "payment-terms-template",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Payment Terms",
  leftBarCategory: { main: "product", subCategory: "productData" },
  preview: "product/payment-terms.svg",
  tags: ["product", "payment terms", "installments"],
  transforms: [],
  template: {
    id: "$uid",
    type: "paymentTerms",
    props: {},
  },
};
