import type { SourceType } from "@editor/components/editor/page/AssetLibraryModal";
import type { UploadResult } from "@editor/reducers/commerce-reducer";
import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import * as React from "react";

import useDropFileZone from "@editor/hooks/useDropFileZone";

import { Spinner } from "@replo/design-system/components/spinner";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { BsPlus } from "react-icons/bs";

const DropZone: React.FC<{
  projectId: string | null | undefined;
  forceLoading?: boolean;
  onUploadComplete(
    res:
      | { data: UploadResult }
      | { error: FetchBaseQueryError | SerializedError },
  ): void;
  assetContentType?: "image" | "video";
  acceptDropAssetType: string[] | string | null | undefined;
  sourceType: SourceType;
  inputSize?: "small" | "big";
  dragText?: string;
  uploadText?: string;
  loadingText?: string;
  className?: string;
}> = ({
  forceLoading,
  onUploadComplete,
  assetContentType,
  acceptDropAssetType,
  sourceType,
  inputSize = "big",
  dragText = "Drop to Upload",
  uploadText = "Upload",
  loadingText = "Uploading...",
  className,
}) => {
  const { getRootProps, getInputProps, isDragActive, isLoadingAssetUpload } =
    useDropFileZone({
      sourceType,
      onUploadComplete,
      acceptDropAssetType,
      allowClickToUpload: true,
    });
  const updateOrCreateAssetIsLoading = isLoadingAssetUpload || forceLoading;

  return inputSize === "big" ? (
    <div
      {...getRootProps({
        className:
          "h-full flex flex-col justify-center items-center bg-slate-50 font-medium text-sm text-blue-500 cursor-pointer",
      })}
    >
      <input {...getInputProps()} />
      {updateOrCreateAssetIsLoading && <Spinner size={10} variant="primary" />}
      {isDragActive && !updateOrCreateAssetIsLoading && (
        <div className="text-base">{dragText}</div>
      )}
      {!updateOrCreateAssetIsLoading && !isDragActive && (
        <div className="text-base">
          Upload {assetContentType === "video" ? "Video" : "Image"}
        </div>
      )}
    </div>
  ) : (
    <div
      {...getRootProps({
        className: twMerge(
          "flex cursor-pointer items-center border-t border-slate-200 pt-2 text-blue-600 font-medium",
          className,
        ),
      })}
    >
      {isLoadingAssetUpload ? (
        <Spinner variant="primary" size={16} className="ml-0 mr-2" />
      ) : (
        <BsPlus size={18} className="mr-1" />
      )}
      <span
        className={classNames("text-xs", {
          "cursor-not-allowed text-slate-300": isLoadingAssetUpload,
        })}
      >
        <input {...getInputProps()} />
        {(() => {
          if (isLoadingAssetUpload) {
            return loadingText;
          }
          if (isDragActive) {
            return dragText;
          }
          return uploadText;
        })()}
      </span>
    </div>
  );
};

export default DropZone;
