import * as React from "react";

// NOTE (Jackson, 2024-12-20): Use a store here so that the Trigger can subscribe to hovered labels
// from the OptionsList and update the hovered label without necessitating a full re-render of
// the component. We want to keep Combobox composable, so we don't want to pass down the hovered
// label as a prop which would demand cloning children and other nonsense.
const createStore = (initialState: string | null = null) => {
  let state = initialState;
  const listeners = new Set<(label: string | null) => void>();

  return {
    subscribe: (listener: (label: string | null) => void) => {
      listeners.add(listener);
      return () => {
        listeners.delete(listener);
      };
    },
    getState: () => state,
    setState: (newState: string | null) => {
      state = newState;
      listeners.forEach((listener) => listener(state));
    },
  };
};

const hoverStore = createStore();

export const useHoveredLabel = () => {
  const [hoveredLabel, setHoveredLabel] = React.useState<string | null>(null);

  React.useEffect(() => {
    return hoverStore.subscribe(setHoveredLabel);
  }, []);

  return hoveredLabel;
};

export const setHoveredLabel = (label: string | null) => {
  hoverStore.setState(label);
};
