import type {
  ComboboxContentProps,
  ComboboxRootProps,
  ComboboxTriggerProps,
} from "./types";

import React from "react";

import { Command } from "@replo/design-system/components/shadcn/core/command";
import { Popover } from "@replo/design-system/components/shadcn/core/popover";
import { useControllableState } from "replo-utils/react/use-controllable-state";

import { ComboboxContext } from "./ComboboxContext";
import { setHoveredLabel } from "./ComboboxHoverStore";
import { ComboboxContent } from "./components/ComboboxContent";
import { ComboboxFooter } from "./components/ComboboxFooter";
import { ComboboxTriggerButton } from "./components/triggers/ComboboxTriggerButton";
import { ComboboxTriggerInput } from "./components/triggers/ComboboxTriggerInput";

const ComboboxRoot: React.FC<React.PropsWithChildren<ComboboxRootProps>> = ({
  children,
  size = "sm",
  options,
  defaultValue,
  value: controlledValue,
  onChange: onControlledChange,
  open: controlledOpen,
  onOpenChange: onControlledOpenChange,
  input: inputValue,
  onInputChange: onControlledInputChange,
  isDisabled,
  previewOnHover,
  areOptionsCreatable = false,
}) => {
  const [open, setOpen] = useControllableState(
    controlledOpen,
    false,
    onControlledOpenChange,
  );
  const [value, setValue] = useControllableState(
    controlledValue,
    defaultValue ?? "",
    onControlledChange,
  );
  const [input, setInput] = useControllableState(
    inputValue,
    "",
    onControlledInputChange,
  );

  /**
   * NOTE (Max, 2025-01-10): We use & expose this flag through
   * the ComboboxContext for its consumers to know whether the
   * trigger is an input or a select.
   *
   * For example, <OptionsList> (use in <ComboboxContent>) needs to access
   * this flag to know whether to filter the options based on the input value.
   */
  const [isUsingTriggerInput, setIsUsingTriggerInput] = React.useState(false);

  const triggerInputRef = React.useRef<HTMLInputElement | null>(null);

  const selectedLabel = (() => {
    if (!value) {
      return null;
    }
    const option = options.find((option) => option.value === value);

    return option?.label ?? value;
  })();

  const handleOpenChange = (isOpen: boolean) => {
    if (previewOnHover) {
      setHoveredLabel(null);
    }
    setOpen(isOpen);
    // NOTE (Max, 2025-01-03): Ensures that when the optionsList popover is opened,
    // the trigger input remains focused
    if (isOpen) {
      setTimeout(() => {
        triggerInputRef.current?.focus();
      }, 0);
    }
  };

  const optionsWithCreatable = [...options];

  if (areOptionsCreatable) {
    const doesInputExistInOptions = options.some(
      (option) => option.value === input,
    );

    // NOTE (Max, 2025-01-03): If the input value is not in the options, add it.
    // This allows the user to select the current value they're typing as an option.
    if (input && !doesInputExistInOptions) {
      optionsWithCreatable.unshift({
        label: `Create "${input}"`,
        value: input,
      });
    }
  }

  const handleValueChange = (newValue: string) => {
    setValue(newValue);

    /**
     * NOTE (Max, 2025-01-04): Ensures that the actual selected value & the triggerInput value
     * are in sync.
     *
     * setTimeout() prevents the input to be updated before the
     * optionsList is closed, which would lead to a UI flicker, as the optionsList's
     * items get would be updated (as they get filtered by the new input value).
     */
    if (isUsingTriggerInput) {
      setTimeout(() => {
        setInput(newValue);
      }, 100);
    }
  };

  return (
    <ComboboxContext.Provider
      value={{
        open,
        setOpen,
        value,
        setValue: handleValueChange,
        input,
        setInput,
        selectedLabel,
        options: optionsWithCreatable,
        isDisabled,
        size,
        previewOnHover,
        triggerInputRef,
        isUsingTriggerInput,
        setIsUsingTriggerInput,
      }}
    >
      <div>
        <Popover
          open={isDisabled ? false : open}
          onOpenChange={handleOpenChange}
        >
          <Command
            className="flex-1 overflow-hidden flex flex-col"
            filter={(search) => {
              if (!search) {
                return 1;
              }

              const option = optionsWithCreatable.find((opt) =>
                opt.value?.toLowerCase().includes(search.toLowerCase().trim()),
              );

              return option ? 1 : 0;
            }}
          >
            {children}
          </Command>
        </Popover>
      </div>
    </ComboboxContext.Provider>
  );
};

type ComboboxProps = ComboboxRootProps &
  ComboboxTriggerProps &
  ComboboxContentProps;

export const Combobox = (props: ComboboxProps) => {
  return (
    <ComboboxRoot {...props}>
      <ComboboxTriggerButton
        triggerClassName={props.triggerClassName}
        placeholder={props.placeholder}
        startEnhancer={props.startEnhancer}
        endEnhancer={props.endEnhancer}
        placeholderPrefix={props.placeholderPrefix}
        staticPlaceholder={props.staticPlaceholder}
      />
      <ComboboxContent
        title={props.title}
        sideOffset={props.sideOffset}
        side={props.side}
        align={props.align}
        areOptionsSearchable={props.areOptionsSearchable}
        inputPlaceholder={props.inputPlaceholder}
        emptySearchMessage={props.emptySearchMessage}
        itemsOnViewCount={props.itemsOnViewCount}
        itemSize={props.itemSize}
        contentClassName={props.contentClassName}
        isLoading={props.isLoading}
      >
        {props.children}
      </ComboboxContent>
    </ComboboxRoot>
  );
};

Combobox.Root = ComboboxRoot;
Combobox.TriggerButton = ComboboxTriggerButton;
Combobox.TriggerInput = ComboboxTriggerInput;
Combobox.Content = ComboboxContent;
Combobox.Footer = ComboboxFooter;
