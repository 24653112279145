import type { FormKey } from "@editor/contexts/ElementEditorErrorContext";
import type {
  ElementErrorType,
  ElementRequestType,
} from "@editor/utils/element";
import type {
  IntegerMetafield,
  StringMetafield,
} from "replo-runtime/shared/types";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import Banner from "@editor/components/common/designSystem/Banner";
import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Selectable from "@editor/components/common/designSystem/Selectable";
import Switch from "@editor/components/common/designSystem/Switch";
import { TextTab } from "@editor/components/common/TextTab";
import { useElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import { useElementEditorErrorContext } from "@editor/contexts/ElementEditorErrorContext";
import { useShopifyBlogs } from "@editor/hooks/element";
import { useInitial } from "@editor/hooks/useInitial";
import useOnChangeMetafields from "@editor/hooks/useOnChangeMetafields";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { selectIsShopifyIntegrationEnabled } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import validatePageModalPath from "@editor/utils/validatePageModalPath";

import Editor, { useMonaco } from "@monaco-editor/react";
import debounce from "lodash-es/debounce";
import { removeFolderNameFromElementName } from "replo-utils/element";
import { slugify } from "replo-utils/lib/string";

import ImageSourceSelector from "../page/element-editor/components/ImageSourceSelector";
import { ElementEditorDescription } from "./ElementEditorDescription";
import { ElementEditorErrors } from "./ElementEditorErrors";
import { ElementEditorHeading } from "./ElementEditorHeading";
import ElementNameEditor from "./ElementNameEditor";
import { ElementSettingsSwitch } from "./ElementSettingsSwitch";
import SeoElementEditor from "./SeoElementEditor";
import { ShopifyThemeSettings } from "./ShopifyThemeSettings";

const PageElementEditor: React.FC<{
  requestType: ElementRequestType;
  showLayoutSelector?: boolean;
  canEditSEOSettings?: boolean;
  initialName?: string;
}> = ({
  requestType,
  showLayoutSelector = true,
  canEditSEOSettings = true,
  initialName,
}) => {
  const editorPageSettingsTabs = [
    { value: "basics", label: "Basics", isVisible: true },
    { value: "advanced", label: "Advanced", isVisible: true },
  ];
  const [selectedTab, setSelectedTab] = React.useState<string>(
    editorPageSettingsTabs[0]!.value,
  );

  return (
    <>
      <div className="m-2 mt-4 flex h-fit flex-col gap-y-2">
        {requestType === "update" && (
          <TextTab
            options={editorPageSettingsTabs}
            onChange={setSelectedTab}
            selectedValue={selectedTab}
            containerClassName="flex gap-4 mb-2"
            className="text-sm"
          />
        )}
        {(() => {
          switch (selectedTab) {
            case "basics":
              return (
                <PageBasicSettings
                  requestType={requestType}
                  showLayoutSelector={showLayoutSelector}
                  canEditSEOSettings={canEditSEOSettings}
                  initialName={initialName}
                />
              );
            case "advanced":
              return <PageAdvancedSettings />;
            default:
              return null;
          }
        })()}
      </div>
    </>
  );
};

const PageBasicSettings: React.FC<{
  requestType: ElementRequestType;
  showLayoutSelector: boolean;
  canEditSEOSettings: boolean;
  initialName?: string;
}> = ({ requestType, showLayoutSelector, canEditSEOSettings, initialName }) => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const { errorMapping, setErrors, clearErrors } =
    useElementEditorErrorContext();

  const initialElementIsPublished = useInitial(element.isPublished);
  const { metafields, onMetafieldChange } = useOnChangeMetafields(
    element,
    onChangeElement,
  );

  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  const { shopifyBlogs } = useShopifyBlogs(element.type);

  const handleNameChange = (name: string) => {
    const shopifyPagePath =
      requestType === "new" || !element?.shopifyPagePath
        ? slugify(removeFolderNameFromElementName(name))
        : element.shopifyPagePath;
    if (errorMapping["path"]?.includes("pathAlreadyExists")) {
      clearErrors("path", ["pathAlreadyExists"]);
    }
    onChangeElement({
      ...element,
      name,
      shopifyPagePath,
    });
  };

  const handlePathChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const shopifyPagePath = e.target.value;

    if (shopifyPagePath.length === 0) {
      setErrors("path", ["pathIsEmpty"]);
    } else {
      clearErrors("path", ["pathIsEmpty"]);
      if (validatePageModalPath(shopifyPagePath)) {
        setErrors("path", ["isInvalidPathName"]);
      } else {
        clearErrors("path", ["isInvalidPathName"]);
      }
    }

    if (errorMapping["path"]?.includes("pathAlreadyExists")) {
      clearErrors("path", ["pathAlreadyExists"]);
    }
    if (errorMapping["path"]?.includes("pathIsEmpty")) {
      clearErrors("path", ["pathIsEmpty"]);
    }

    onChangeElement({
      ...element,
      shopifyPagePath,
    });
  };

  const handleRouteChange = (value: string) => {
    if (isShopifyIntegrationEnabled) {
      let shopifyBlogId = undefined;
      if (value.startsWith("blogs")) {
        shopifyBlogId = shopifyBlogs?.find(
          (blog) => blog.handle === value.split("/")[1],
        )?.id;
      }

      onChangeElement({
        ...element,
        type: shopifyBlogId ? "shopifyArticle" : "page",
        shopifyBlogId,
      });
    }
  };

  const routes = [
    {
      value: "pages/",
      isSelected:
        element.type === "page" ||
        (element.type === "shopifyArticle" && !element.shopifyBlogId),
    },
  ];

  if (isShopifyIntegrationEnabled) {
    shopifyBlogs?.forEach((blog) =>
      routes.push({
        value: `blogs/${blog.handle}/`,
        isSelected:
          element.type === "shopifyArticle" &&
          blog.id === element.shopifyBlogId,
      }),
    );
  }

  return (
    <>
      {requestType !== "update" && isFeatureEnabled("turbo") && (
        <div className="mb-4 bg-slate-100 p-4 rounded">
          <ElementSettingsSwitch
            title="Use Turbo"
            description="Use Replo Edge technology to deploy this page rather than Shopify."
            isOn={element.isTurbo}
            onChange={(value) =>
              onChangeElement({ ...element, isTurbo: value })
            }
          />
        </div>
      )}

      <ElementNameEditor
        type="page"
        inputName="page-name"
        initialName={initialName ?? element.name}
        onChange={handleNameChange}
      />

      {element.type === "shopifyArticle" && (
        <LabeledControl
          label="Post Featured Image"
          id="page-data-featured-image"
        >
          <ImageSourceSelector
            src={element.shopifyArticleImage?.src}
            onChangeImageSource={(value: string) => {
              onChangeElement({
                ...element,
                shopifyArticleImage: { src: value },
              });
            }}
            onRemove={() =>
              onChangeElement({
                ...element,
                shopifyArticleImage: { src: "" },
              })
            }
            allowsDynamicData={false}
            size="base"
          />
        </LabeledControl>
      )}

      {element.isTurbo ? (
        <LabeledControl label="URL" id="page-data-path">
          <Input
            id="page-data-path"
            value={element.slug}
            startEnhancer={<div className="text-slate-400">/</div>}
            placeholder="path"
            size="base"
            onChange={(e) => {
              const slug = e.target.value;
              onChangeElement({
                ...element,
                slug,
              });
            }}
          />
        </LabeledControl>
      ) : (
        <LabeledControl
          label="URL"
          id="page-data-path"
          error={
            <PathInputError
              errorMapping={errorMapping}
              elementType={element.type}
            />
          }
        >
          <Input
            startEnhancer={
              <Selectable
                className="bg-slate-400 text-white w-fit max-w-[150px] truncate"
                labelClassName="text-slate-100 truncate block"
                arrowClassName="text-slate-300"
                options={routes.map((route) => ({
                  label: route.value,
                  value: route.value,
                }))}
                value={routes.find((route) => route.isSelected)?.value}
                onSelect={handleRouteChange}
                disableDropdownFixedWidth
                dropdownAlign="start"
              />
            }
            startEnhancerClassName="ml-0"
            id="page-data-path"
            value={element.shopifyPagePath}
            placeholder="path"
            size="base"
            onChange={handlePathChange}
          />
        </LabeledControl>
      )}

      {requestType === "update" && initialElementIsPublished && (
        <div className="mt-4">
          <ElementSettingsSwitch
            title="Is Published"
            description="If the page is published it means public users can see the page."
            isOn={element.isPublished}
            onChange={(isPublished) =>
              onChangeElement({ ...element, isPublished })
            }
          />
        </div>
      )}
      {element.type === "page" &&
        !element.isTurbo &&
        isShopifyIntegrationEnabled && (
          <div className="mt-4">
            <ElementSettingsSwitch
              title="Set As Homepage"
              description="If page is homepage it will be set as the homepage of your store."
              isOn={element.isHomepage}
              onChange={(isHomepage) =>
                onChangeElement({ ...element, isHomepage })
              }
            />
          </div>
        )}

      {canEditSEOSettings && !element.isTurbo && (
        <div className="mt-4">
          <SeoElementEditor
            isPageSettings={element.type === "page"}
            metafields={metafields}
            onMetafieldChange={onMetafieldChange}
          />
        </div>
      )}

      {(element.type === "page" || element.type === "shopifyArticle") &&
        !element.isTurbo &&
        showLayoutSelector &&
        isShopifyIntegrationEnabled && <ShopifyThemeSettings />}
    </>
  );
};

const PathInputError: React.FC<{
  errorMapping: Record<FormKey, ElementErrorType[] | null>;
  elementType: ReploElementType;
}> = ({ errorMapping, elementType }) => {
  const isError =
    errorMapping &&
    Object.keys(errorMapping).some((key) =>
      errorMapping[key as FormKey]?.some((error) =>
        [
          "pathAlreadyExists",
          "isInvalidPathName",
          "pathIsEmpty",
          "loadingMissingData",
          "errorLoadingData",
          "storeHasNoBlogs",
        ].includes(error),
      ),
    );

  if (isError) {
    const errors = Object.values(errorMapping).flat();
    const primaryErrorType = errors.find(
      (error) =>
        error?.toLowerCase().includes("path") && typeof error === "string",
    );
    const errorType = primaryErrorType ?? errors[0];
    if (errorType) {
      return (
        <ElementEditorErrors errorType={errorType} elementType={elementType} />
      );
    }
  }

  return null;
};

const PageAdvancedSettings: React.FC = () => {
  const { element, onChangeElement } = useElementEditorDataContext();
  const { metafields, onMetafieldChange } = useOnChangeMetafields(
    element,
    onChangeElement,
  );
  const [errors, setErrors] = React.useState<string[]>([]);

  const monaco = useMonaco();
  monaco?.editor.defineTheme("replo", {
    base: "vs",
    colors: {
      "editor.background": "#f8fafc",
      "editor.lineHighlightBackground": "#f8fafc",
    },
    inherit: true,
    rules: [],
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now: eslint-disable-next-line react-hooks/exhaustive-deps
  const handleHeadContentChange = React.useCallback(
    debounce((value: string) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        `<html>${value}</html>`,
        "application/xml",
      );
      const errors = Array.from(doc.querySelectorAll("parsererror > div"))
        .map((el) => el.innerHTML)
        // NOTE (Evan, 9/25/23) Omitting the closing meta tag is very common,
        // so we hide associated errors to avoid confusion.
        .filter(
          (error) => !error.includes("Opening and ending tag mismatch: meta"),
        );
      setErrors(errors);
      onChangeElement((element) => ({
        ...element,
        customHeadContent: value,
      }));
    }, 300),
    [onMetafieldChange, debounce],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const beforeHeadEndContent =
    // NOTE (Gabe 2023-11-08): This fallback is where we used to store the
    // customHead. It no longer exists in the schema.
    // @ts-expect-error
    element.customHeadContent ??
    (
      metafields?.find((field) => field.key === "before_head_end_content") as
        | StringMetafield
        | undefined
    )?.value;

  const hiddenFromSEOMetafieldValue = (
    metafields?.find(
      (field) => field.key === "hidden" && field.namespace === "seo",
    ) as IntegerMetafield | undefined
  )?.value;

  return (
    <>
      <div className="mb-4 flex flex-row items-center justify-between text-slate-400">
        <div>
          <ElementEditorHeading>Hide Page from SEO</ElementEditorHeading>
          <ElementEditorDescription>
            If enabled, this page will be hidden from search engines like
            Google.
          </ElementEditorDescription>
        </div>
        <Switch
          isOn={Boolean(hiddenFromSEOMetafieldValue)}
          onChange={(isHiddenFromSEO) =>
            onMetafieldChange(
              "hidden",
              Number(isHiddenFromSEO),
              "seo",
              "number_integer",
            )
          }
          backgroundOnColor="bg-blue-600"
        />
      </div>
      {element.type !== "shopifyArticle" && (
        <>
          <LabeledControl label="Custom Page <head>" id="page-data-name">
            <div
              className="flex flex-col gap-2 rounded-lg py-2"
              data-testid="custom-head-editor"
            >
              <Editor
                height="300px"
                defaultLanguage="html"
                defaultValue={beforeHeadEndContent}
                onChange={(value) => handleHeadContentChange(String(value))}
                options={{
                  minimap: { enabled: false },
                  scrollbar: { verticalScrollbarSize: 0 },
                  lineNumbers: "off",
                  lineDecorationsWidth: 0,
                  padding: { top: 16 },
                }}
                theme="replo"
              />
              {errors.length > 0 && (
                <Banner
                  isDismissable={false}
                  backgroundColor="bg-red-300"
                  className="rounded p-4 text-xs text-default"
                >
                  <div className="flex flex-col gap-2">
                    <p>
                      Replo detected HTML which may be invalid. This may cause
                      issues after this page is published. If you see this page
                      not working correctly, please reach out to
                      support@replo.app with any questions.
                    </p>
                    <ul>
                      {errors.map((error) => (
                        <li
                          key="error"
                          className="ml-4"
                          style={{ listStyleType: "circle" }}
                        >
                          {error}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Banner>
              )}
            </div>
          </LabeledControl>
          <em className="text-xs text-slate-300">
            {
              "This content will be inserted before the closing </head> tag in your Shopify theme"
            }
          </em>
        </>
      )}
    </>
  );
};

export default PageElementEditor;
