import * as React from "react";

import DeltaIndicator from "@/editor/components/analytics/DeltaIndicator";
import {
  DEFAULT_CURRENCY,
  METRICS_REQUIRING_CURRENCY,
  METRICS_REQUIRING_DECIMALS,
  METRICS_REQUIRING_PERCENTAGE,
} from "@/features/analytics/constants";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { BsDash } from "react-icons/bs";
import { formatWithCommasAndTwoDecimals } from "replo-utils/lib/math";

const MetricWithDelta: React.FC<{
  name: string;
  value: number | null;
  delta?: number;
  doesCompareValueExist: boolean;
  wrapperClassName?: string;
  valueClassName?: string;
}> = ({
  name,
  value,
  delta,
  doesCompareValueExist,
  wrapperClassName,
  valueClassName,
}) => {
  const showPercentage = METRICS_REQUIRING_PERCENTAGE.includes(name);
  const showCurrency = METRICS_REQUIRING_CURRENCY.includes(name);
  const showDecimals = METRICS_REQUIRING_DECIMALS.includes(name);

  return (
    <div
      className={twMerge(
        "inline-flex items-center justify-left gap-3",
        wrapperClassName,
      )}
    >
      <div className={classNames("min-w-[10px]", valueClassName)}>
        {value !== null ? (
          <>
            {showCurrency && DEFAULT_CURRENCY}
            {formatWithCommasAndTwoDecimals(value, showDecimals)}
            {showPercentage && `%`}
          </>
        ) : (
          <BsDash />
        )}
      </div>

      {delta !== undefined && (
        <div
          className={classNames("text-sm font-normal", {
            "text-gray-600": delta == 0,
            "text-green-600": delta > 0,
            "text-red-600": delta < 0,
          })}
        >
          <DeltaIndicator
            delta={delta}
            doesCompareValueExist={doesCompareValueExist}
          />
        </div>
      )}
    </div>
  );
};

export default MetricWithDelta;
