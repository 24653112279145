// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type {
  InfiniteLoadingSettings,
  Option,
  SelectedValue,
} from "@editorComponents/Lists";

import * as React from "react";

import InputComponent from "@editor/components/common/designSystem/Input";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import { InfiniteList, RegularList } from "@editorComponents/Lists";

import Popover from "@replo/design-system/components/popover";
import twMerge from "@replo/design-system/utils/twMerge";
import classNames from "classnames";
import { BsSearch } from "react-icons/bs";
import { filterNulls } from "replo-utils/lib/array";
import { hasOwnProperty } from "replo-utils/lib/misc";
import { useControllableState } from "replo-utils/react/use-controllable-state";

type SelectablePopoverProps = {
  title: string;
  options: Option[];
  itemSize: number;
  onSelect?(value: SelectedValue | SelectedValue[]): void;
  listHeight?: number;
  startEnhancer?: React.ReactNode;
  onSearch?(value: string | number | null): void;
  infiniteLoading?: InfiniteLoadingSettings;
  extraContent?: React.ReactNode;
  isRemovable?: boolean;
  itemsOnViewCount?: number;
  allowSelectAll?: boolean;
  isMultiselect?: boolean;
  labelClassName?: string;
  rowContainerClassname?: string;
  placeholder?: string | null;
  noItemsPlaceholder?: React.ReactNode;
  searchPlaceholder?: string;
  allowNull?: boolean;
  selectedItems?: SelectedValue[];
  trigger?: JSX.Element;
  triggerAsChild?: boolean;
  popoverSide?: "top" | "bottom" | "left" | "right";
  fromYTransform?: number;
  popoverContentStyle?: React.CSSProperties;
  shouldCloseOnSelect?: boolean;
  triggerEndEnhancer?: React.ReactNode;
  titleClassnames?: string;
  hideClosePopoverButton?: boolean;
  selectFirstSearchOptionOnInputEnter?: boolean;
  triggerId?: string;
  isDefaultOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  fallbackValidValues?: string[];
  childrenClassname?: string;
  placeholderClassname?: string;
  popoverSideOffset?: number;
  triggerClassname?: string;
  updatePlaceholderOnItemHover?: boolean;
};

const SELECT_ALL_VALUES = "SELECT_ALL_VALUES";

const getOptionDisplayText = (option: Option): string => {
  if (!option.label) {
    return "";
  }

  if (typeof option.label === "string") {
    return option.label;
  }

  const element = option.label as React.ReactElement;

  if (element.type === "img" && element.props.alt) {
    return element.props.alt;
  }

  return String(option.value || "");
};

const SelectablePopover: React.FC<
  React.PropsWithChildren<SelectablePopoverProps>
> = ({
  title,
  options,
  children,
  onSelect,
  startEnhancer,
  onSearch,
  infiniteLoading,
  itemSize,
  extraContent,
  isRemovable = true,
  itemsOnViewCount,
  isMultiselect,
  allowSelectAll,
  labelClassName,
  placeholder,
  noItemsPlaceholder,
  searchPlaceholder,
  allowNull,
  selectedItems: controlledSelectedItems,
  trigger,
  triggerAsChild,
  popoverSide,
  fromYTransform,
  popoverContentStyle,
  shouldCloseOnSelect,
  triggerEndEnhancer,
  titleClassnames,
  hideClosePopoverButton,
  selectFirstSearchOptionOnInputEnter,
  triggerId,
  isDefaultOpen,
  onOpenChange,
  fallbackValidValues,
  childrenClassname,
  placeholderClassname,
  popoverSideOffset,
  triggerClassname,
  updatePlaceholderOnItemHover = false,
}) => {
  const [selectedItems, setSelectedItems, isControlled] = useControllableState<
    SelectedValue[]
  >(controlledSelectedItems, [], undefined);
  const [shouldClose, setShouldClose] = React.useState(false);
  const [hoveredOption, setHoveredOption] = React.useState<Option | null>(null);

  React.useEffect(() => {
    if (!isControlled) {
      // Note (Mariano, 08-08-2022): We need to listen for the options list because
      // it may change (i.e. for the infinite loading), and the default selected item could be included after the first initial load,
      // and the "selectedItems" list may also have more selected items at the moment we render the default one
      // We don't want to add "selectedItems" as a dependency because we want to be able to de-select the default item
      const items = options
        .filter((i) => i.isDefaultActive)
        .map((i) => i.value);

      setSelectedItems((prevItems) => [
        ...prevItems,
        ...items.filter(
          (item): item is SelectedValue =>
            item !== undefined &&
            (allowNull || item !== null) &&
            !prevItems.includes(item),
        ),
      ]);
    }
  }, [isControlled, setSelectedItems, options, allowNull]);

  const [searchTerm, setSearchTerm] = React.useState("");
  const { filteredOptions: _filteredOptions } = useSearchableOptions(
    options,
    searchTerm,
    Boolean(onSearch),
  );
  // Note (Noah, 2023-12-21, USE-627): Since we're about to unshift
  // into this array, it's important to copy it since it was passed
  // in as a prop and we don't want to modify it.
  const filteredOptions = [..._filteredOptions];

  const searchActive = filteredOptions.length < options.length;
  const currentlyVisibleValues: SelectedValue[] = filterNulls(
    filteredOptions.map(({ value }) => value),
  );
  let allOptionsSelected = currentlyVisibleValues.every(
    (value) => selectedItems.includes(value) || value == SELECT_ALL_VALUES,
  );
  if (searchActive) {
    allOptionsSelected = filteredOptions.every(
      ({ value }) =>
        !value || value == SELECT_ALL_VALUES || selectedItems.includes(value),
    );
  }
  if (allowSelectAll && filteredOptions.length > 0) {
    const selectAllLabel =
      filteredOptions.length < options.length
        ? `Select All ${filteredOptions.length}`
        : "Select All";
    if (filteredOptions[0]?.value != SELECT_ALL_VALUES) {
      filteredOptions.unshift({
        label: selectAllLabel,
        value: SELECT_ALL_VALUES,
        isSelectable: true,
        isDefaultActive: allOptionsSelected,
      });
    }
  }

  React.useEffect(() => {
    // NOTE (Matt 2023-12-06): This useEffect is necessary to handle when selectAllIsCurrentlyChecked
    // changes. This basically allows the "select all" checkbox to reflect whether the
    // currently visible options (ie filtered by search vs all options) are all selected.
    // This syncs the 'select all' value when a user searches.
    if (allowSelectAll) {
      if (!allOptionsSelected && selectedItems.includes(SELECT_ALL_VALUES)) {
        setSelectedItems(
          selectedItems.filter((value) => value != SELECT_ALL_VALUES),
        );
      } else if (
        allOptionsSelected &&
        !selectedItems.includes(SELECT_ALL_VALUES)
      ) {
        setSelectedItems([SELECT_ALL_VALUES, ...selectedItems]);
      }
    }
  }, [allOptionsSelected, selectedItems, setSelectedItems, allowSelectAll]);

  const _onSearch = (value: string) => {
    // Note (Sebas, 2023-07-14): In case you select an option and open the modal popover
    // again, shouldClose will be true and if you start typing on the input search box
    // it will close. To prevent this we set shouldClose to false on search.
    if (shouldCloseOnSelect) {
      setShouldClose(false);
    }
    if (onSearch) {
      onSearch(value);
    }
    setSearchTerm(value);
  };

  const _onSelect = (item: SelectedValue) => {
    if (!onSelect) {
      return;
    }
    let updatedItems = selectedItems;

    const currentlyVisibleValues: SelectedValue[] = filterNulls(
      filteredOptions.map(({ value }) => value),
    );
    const selectAllIsCurrentlyChecked = currentlyVisibleValues.every((value) =>
      selectedItems.includes(value),
    );
    if (isMultiselect && allowSelectAll && item == SELECT_ALL_VALUES) {
      if (selectAllIsCurrentlyChecked) {
        // unselect all visible values
        updatedItems = selectedItems.filter(
          (item) => !currentlyVisibleValues.includes(item),
        );
      } else {
        // select all visible values
        updatedItems = Array.from(
          new Set([...selectedItems, ...currentlyVisibleValues]),
        );
      }
    } else {
      if (item && selectedItems.includes(item)) {
        updatedItems = selectedItems.filter(
          (i) => i !== item && i !== SELECT_ALL_VALUES,
        );
      } else {
        updatedItems = [...selectedItems, item];
      }
    }

    setSelectedItems(isMultiselect ? updatedItems : [item]);
    onSelect(
      isMultiselect
        ? // Note (Noah, 2023-12-21, USE-627): Ensure we filter out the SELECT_ALL_VALUES
          // here since we never want to report it in the onChange handler
          updatedItems.filter((item) => {
            return item !== SELECT_ALL_VALUES;
          })
        : item,
    );
    if (shouldCloseOnSelect) {
      setShouldClose(true);
    }
  };

  const onInputEnter = () => {
    const firstFilteredOption = allowSelectAll
      ? filteredOptions[1]
      : filteredOptions[0];
    if (
      searchTerm !== "" &&
      selectFirstSearchOptionOnInputEnter &&
      firstFilteredOption?.value
    ) {
      _onSelect(firstFilteredOption.value);
    }
  };

  // NOTE (Matt 2024-02-28): In order to show the invalid selection state
  // we need to see if any values in the selectedItems array do not have
  // isSelectable. It is possible for selectedItems to be null (a default value)
  // or the string SELECT_ALL_VALUES, both of which we consider to be valid
  // selections.
  const invalidSelectedItem = selectedItems.find((item) => {
    if (
      !item ||
      String(item) === SELECT_ALL_VALUES ||
      hasOwnProperty(item, "__reploOneTimePurchase")
    ) {
      return false;
    }

    const matchingOption = options.find((option) => {
      return String(option.value) == String(item);
    });

    if (!matchingOption) {
      return !fallbackValidValues?.find((value) => {
        return value === String(item);
      });
    }

    return !matchingOption.isSelectable;
  });

  // NOTE (Matt 2024-02-28): An option can have a 'disabledMessage' which provides
  // information as to why an option won't work (ex: selling plan option does not
  // apply to currently selected variant).
  const invalidItemMessage =
    invalidSelectedItem &&
    options.find(({ value }) => String(value) == String(invalidSelectedItem))
      ?.disabledMessage;

  const baseListProps = {
    itemSize,
    options: filteredOptions,
    onSelect: _onSelect,
    itemsOnViewCount,
    selectedItems,
    isMultiselect,
    labelClassName,
    noItemsPlaceholder,
    totalItems: options.length,
    onHover: updatePlaceholderOnItemHover
      ? (option: Option | null) => setHoveredOption(option)
      : undefined,
  };

  return (
    <Popover
      onOpenChange={(isOpen) => {
        setSearchTerm("");
        onOpenChange?.(isOpen);
      }}
      shouldClose={shouldClose}
      isDefaultOpen={isDefaultOpen}
    >
      <Popover.Content
        title={title}
        side={popoverSide}
        fromYTransform={fromYTransform}
        style={popoverContentStyle}
        titleClassnames={titleClassnames}
        hideCloseButton={hideClosePopoverButton}
        shouldPreventDefaultOnInteractOutside={false}
        sideOffset={popoverSideOffset}
      >
        <div className="mb-2">
          <InputComponent
            size="sm"
            value={searchTerm}
            startEnhancer={<BsSearch />}
            endEnhancer={() =>
              searchTerm?.trim() && (
                <FormFieldXButton onClick={() => _onSearch("")} />
              )
            }
            placeholder={searchPlaceholder ?? `Search ${title}`}
            onChange={(e) => _onSearch(e.target.value)}
            autoFocus
            onEnter={onInputEnter}
          />
        </div>
        {infiniteLoading ? (
          <InfiniteList {...baseListProps} settings={infiniteLoading} />
        ) : (
          <RegularList {...baseListProps} />
        )}
        {extraContent}
      </Popover.Content>
      <Popover.Trigger asChild={triggerAsChild ?? !trigger}>
        {trigger ?? (
          <div
            id={triggerId}
            className={twMerge(
              classNames(
                "col-span-2 flex items-center justify-between rounded bg-subtle h-6 gap-2 p-1",
                {
                  "border border-red-600 bg-danger-emphasis":
                    Boolean(invalidSelectedItem),
                },
              ),
              triggerClassname,
            )}
          >
            <div
              className="flex w-full cursor-pointer items-center text-xs gap-2 justify-between"
              data-testid="trigger-selectable"
            >
              <div className="flex gap-2 w-full">
                {Boolean(startEnhancer) && (
                  <div className="flex shrink-0 text-subtle">
                    {startEnhancer}
                  </div>
                )}
                {Boolean(children) ? (
                  <div
                    className={twMerge("truncate w-full", childrenClassname)}
                  >
                    {updatePlaceholderOnItemHover && hoveredOption
                      ? getOptionDisplayText(hoveredOption)
                      : children}
                  </div>
                ) : (
                  <span
                    className={twMerge(
                      "text-subtle w-full",
                      placeholderClassname,
                    )}
                  >
                    {updatePlaceholderOnItemHover && hoveredOption
                      ? getOptionDisplayText(hoveredOption)
                      : placeholder}
                  </span>
                )}
              </div>
              <div className="flex gap-2 items-center">
                {onSelect && isRemovable && selectedItems.length > 0 && (
                  <FormFieldXButton
                    onClick={() => {
                      onSelect(null);
                      setSelectedItems([]);
                    }}
                  />
                )}
                {triggerEndEnhancer ? triggerEndEnhancer : null}
              </div>
            </div>
          </div>
        )}
      </Popover.Trigger>
      {invalidItemMessage && (
        <span className="text-danger text-xs">{invalidItemMessage}</span>
      )}
    </Popover>
  );
};

export const useSearchableOptions = (
  options: Option[],
  searchValue: string,
  isControlledSearch: boolean,
) => {
  const filteredOptions =
    !isControlledSearch && searchValue.length > 0
      ? options
          .filter((option) => {
            if (!option.isSelectable) {
              // TODO (Noah, 2022-08-20): Not sure this is the right condition,
              // I think this is the case to always show headers/loading cells
              // but I think that probably should be different than isSelectable
              return true;
            }
            const valuesToSearch = option.searchValue
              ? [option.searchValue]
              : [];
            valuesToSearch.push(getOptionDisplayText(option));

            return valuesToSearch
              .map((value) => value.toLowerCase())
              .some((value) =>
                value.includes(searchValue.trim().toLowerCase()),
              );
          })
          .filter((option, idx, array) => {
            if (!option?.isSelectable && !array[idx + 1]?.isSelectable) {
              return false;
            }
            return option;
          })
      : options;

  return {
    filteredOptions,
  };
};

export default SelectablePopover;
