import type {
  ActionValueTypeValueOf,
  AlchemyActionType,
  AlchemyActionValueType,
} from "replo-runtime/shared/enums";
import type {
  AddProductVariantToCartEditorPropsValue,
  LinkData,
  StoreProduct,
} from "replo-runtime/shared/types";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type { ReploElement } from "schemas/generated/element";
import type { ReploState, ReploSymbol } from "schemas/generated/symbol";
import type {
  ContextRef,
  ProductRef,
  ProductRefOrDynamic,
} from "schemas/product";

import * as React from "react";

import DataTableRowSelect from "@common/DataTableRowSelect";
import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import LinkEditor from "@common/designSystem/LinkEditor";
import { ProductSelector } from "@common/designSystem/ProductSelector";
import Selectable from "@common/designSystem/Selectable";
import Switch from "@common/designSystem/Switch";
import Textarea from "@common/designSystem/Textarea";
import { getComponentName } from "@components/editor/component";
import { CodeEditorCustomPropModifier } from "@components/editor/customProp/CodeEditorCustomPropModifier";
import { IntegerSelector } from "@components/editor/customProp/IntegerSelector";
import OffsetHashmarkSelector from "@editor/components/editor/actions/OffsetHashmarkSelector";
import { useModal } from "@editor/hooks/useModal";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import { useEditorSelector } from "@editor/store";
import { isModal } from "@editor/utils/component";
import { docs } from "@editor/utils/docs";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DraggingTypes } from "@editor/utils/editor";
import SelectablePopover from "@editorComponents/SelectablePopover";
import { SellingPlansSelector } from "@editorComponents/SellingPlansSelector";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";

import { Badge } from "@replo/design-system/components/badge";
import pickBy from "lodash-es/pickBy";
import uniq from "lodash-es/uniq";
import {
  BsCurrencyDollar,
  BsFillBagPlusFill,
  BsFillLightningChargeFill,
  BsFillTelephoneFill,
  BsFront,
} from "react-icons/bs";
import { IoArrowRedoSharp } from "react-icons/io5";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { AlchemyActionTrigger } from "replo-runtime/shared/enums";
import { forEachComponentAndDescendants } from "replo-runtime/shared/utils/component";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";
import { getProduct, isContextRef } from "replo-runtime/store/ReploProduct";
import { filterNulls } from "replo-utils/lib/array";
import { parseInteger } from "replo-utils/lib/math";
import { isNullish } from "replo-utils/lib/misc";

import SellingPlanSelector from "./actions/SellingPlanSelector";

export const getActionOptions = (
  actionTypes: AlchemyActionType[],
  trigger: AlchemyActionTrigger,
) => {
  return filterNulls(
    // Note (Evan, 2024-09-30): We really shouldn't have duplicates here, REPL-12382
    uniq(actionTypes).map((value) => {
      const { label, applicableTriggers } = actionTypeToEditorData[value];
      if (applicableTriggers.includes(trigger)) {
        return {
          label,
          value,
        };
      }
      return null;
    }),
  );
};

const getCursorLabel = (text: string, label: React.ReactNode) => {
  return (
    <span className="flex items-center text-xs gap-2 w-full">
      <Badge
        type="icon"
        icon={label}
        isFilled
        className="bg-accent text-white"
      />
      {text}
    </span>
  );
};

const actionsWithoutProductPlaceholders: Set<AlchemyActionType> = new Set([
  "addProductVariantToCart",
  "redirectToProductPage",
]);

type AddProductVariantToCartEditorProps = {
  // TODO (Noah, 2022-08-08, REPL-3428): Specifying this type shouldn't be
  // necessary, this entire mapping should be inside a mapped type so typescript
  // can infer it
  value: AddProductVariantToCartEditorPropsValue | null;
  onChange(newValue: AddProductVariantToCartEditorPropsValue): void;
  extras: ActionValueRenderExtras;
};
const AddProductVariantToCartEditor = ({
  value,
  onChange,
  extras,
}: AddProductVariantToCartEditorProps) => {
  const modal = useModal();
  const { moneyFormat, activeLanguage, activeCurrency } =
    useEditorSelector(selectLocaleData);
  const onClickDynamicDataForQuantity = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.INTEGER,
        referrerData: {
          type: "callback",
          onChange: (newValue: any) => {
            onChange({
              ...value!,
              quantity: newValue,
            });
          },
        },
        initialPath:
          value?.quantity && typeof value.quantity === "string"
            ? getPathFromVariable(value?.quantity)
            : undefined,
      },
    });
  };

  const options = [
    {
      label: "Go to cart after?",
      key: "redirectToCart",
      isOn: value?.redirectToCart,
      onChange: (checked: boolean) =>
        onChange({
          ...value!,
          redirectToCart: checked,
          redirectToCheckout:
            // NOTE (Mariano, 2022-04-04): only 1 action should be enabled,
            // if the other option is already active when enabling this one, we want to disable the other one.
            checked && value?.redirectToCheckout
              ? false
              : value?.redirectToCheckout,
        }),
    },
    {
      label: "Go to checkout after?",
      key: "redirectToCheckout",
      isOn: value?.redirectToCheckout,
      onChange: (checked: boolean) =>
        onChange({
          ...value!,
          redirectToCheckout: checked,
          redirectToCart:
            // NOTE (Mariano, 2022-04-04): only 1 action should be enabled,
            // if the other option is already active when enabling this one, we want to disable the other one.
            checked && value?.redirectToCart
              ? false
              : value?.redirectToCart ?? false,
        }),
    },
  ];
  const productRef: ProductRefOrDynamic | null = value?.product ?? null;
  const product = getProduct(
    productRef,
    getCurrentComponentContext(extras.componentId, 0) ?? null,
    {
      productMetafieldValues: {},
      variantMetafieldValues: {},
      products: extras.products,
      currencyCode: activeCurrency,
      language: activeLanguage,
      moneyFormat,
      templateProduct: extras.templateProduct,
      isEditor: true,
      isShopifyProductsLoading: false,
    },
  );

  const shouldHidePlaceholders = actionsWithoutProductPlaceholders.has(
    extras.actionType,
  );
  return (
    <div className="flex flex-col items-stretch gap-2">
      <ProductSelector
        selectedProductRef={value?.product ?? null}
        onChange={(productRef) => {
          onChange({
            ...value!,
            product: productRef!,
            sellingPlanId: undefined,
          });
        }}
        isMultiProducts={false}
        isVariantSelectable
        showPlaceholders={!shouldHidePlaceholders}
      />
      {extras.actionType === "addProductVariantToCart" && (
        <>
          {product?.variants &&
            product.variants.length > 1 &&
            value &&
            !isContextRef(value?.product) && (
              <LabeledControl label="Variant" size="sm">
                <SelectablePopover
                  title="Variants"
                  itemSize={40}
                  itemsOnViewCount={5}
                  options={product.variants.map((v) => ({
                    label: v.title,
                    isSelectable: true,
                    isActive: value?.product?.variantId === v.id,
                    value: v.id,
                  }))}
                  isRemovable={false}
                  onSelect={(newSelectedVariantId: number) => {
                    onChange({
                      ...value,
                      product: {
                        ...value?.product,
                        variantId: newSelectedVariantId,
                      },
                    });
                  }}
                >
                  <span className="text-slate-600">
                    {value?.product?.variantId
                      ? product?.variants.find(
                          (v) => v.id === value.product.variantId,
                        )?.title
                      : "Select Variant"}
                  </span>
                </SelectablePopover>
              </LabeledControl>
            )}
          {/* Note (Noah, 2022-12-29, REPL-5618): We allow you to select a selling plan even if the variant
            selected is dynamic, as long as we've determined that the current dynamic value has
            applicable selling plans. This helps for cases where the user wants to use the dynamic
            variant list selector, but also use tabs for selling plan selection (common case).
            Technically this doesn't function correctly in repeated components like data collections
            and product collection components, but we're opting to support the more common use case
            of just having the one product component with dynamic variant selection and static selling
            plan selection for now.

            Eventually when we have a dynamic selling plan list/dropdown, this will need to change, since
            we'll want the user to be able to select the "currently selected selling plan" using dynamic data.
            */}

          {value &&
          (isNullish(value.quantity) ||
            (value.quantity && isDynamicDataValue(String(value.quantity)))) ? (
            <LabeledControl label="Quantity" size="sm">
              <DynamicDataValueIndicator
                type="other"
                templateValue={
                  value.quantity && isDynamicDataValue(String(value.quantity))
                    ? String(value.quantity)
                    : "{{selectedQuantity}}"
                }
                onClick={onClickDynamicDataForQuantity}
                onRemove={() => {
                  onChange({
                    ...value,
                    quantity: 1,
                  });
                }}
              />
            </LabeledControl>
          ) : (
            <IntegerSelector
              label="Quantity"
              min={1}
              value={
                value?.quantity ? Number.parseInt(String(value.quantity)) : 1
              }
              onClickDynamicData={onClickDynamicDataForQuantity}
              onChange={(newQuantity) => {
                onChange({
                  ...value!,
                  quantity: newQuantity,
                });
              }}
            />
          )}

          {!isNullish(product) && (
            <SellingPlansSelector
              product={product}
              sellingPlanId={value?.sellingPlanId ?? null}
              allowThirdPartySellingPlan={value?.allowThirdPartySellingPlan}
              onChangeThirdPartySellingPlan={(checked: boolean) =>
                onChange({
                  ...value!,
                  allowThirdPartySellingPlan: checked,
                })
              }
              onChange={(sellingPlanId: string | number | null) => {
                const newValue: AddProductVariantToCartEditorPropsValue = {
                  ...value!,
                  sellingPlanId,
                };
                return onChange(newValue);
              }}
              onRemove={() => {
                const newValue = pickBy(value, (_, key) => {
                  return key !== "sellingPlanId";
                });
                return onChange(
                  newValue as AddProductVariantToCartEditorPropsValue,
                );
              }}
            />
          )}

          {options.map((option) => (
            <div
              key={option.key}
              className="mt-2 flex w-full flex-row justify-between"
            >
              <span className="text-xs text-slate-400">{option.label}</span>
              <Switch
                data-testid={`product-${option.key}`}
                isOn={option.isOn}
                onChange={option.onChange}
                thumbColor="#fff"
                backgroundOnColor="bg-blue-600"
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

type ActionTypeEditorData = {
  displayName: string;
  label: React.ReactNode;
  valueType: AlchemyActionValueType | null;
  applicableTriggers: AlchemyActionTrigger[];
};

const iconSize = 12;

export const actionTypeToEditorData: Record<
  AlchemyActionType,
  ActionTypeEditorData
> = {
  redirect: {
    displayName: "Redirect to",
    label: getCursorLabel("Redirect to", <IoArrowRedoSharp size={iconSize} />),
    valueType: "url",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  redirectToProductPage: {
    displayName: "Redirect to Product Page",
    label: getCursorLabel(
      "Redirect to Product Page",
      <IoArrowRedoSharp size={iconSize} />,
    ),
    valueType: "productRedirect",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  clearCart: {
    displayName: "Clear Cart",
    label: getCursorLabel("Clear Cart", <BsFillBagPlusFill size={iconSize} />),
    valueType: "none",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  applyDiscountCode: {
    displayName: "Apply Discount Code",
    label: getCursorLabel(
      "Apply Discount Code",
      <BsCurrencyDollar size={iconSize} />,
    ),
    valueType: "discountCode",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  clearDiscountCode: {
    displayName: "Clear Discount Codes",
    label: getCursorLabel(
      "Clear Discount Codes",
      <BsCurrencyDollar size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  addProductVariantToCart: {
    displayName: "Add Product to Cart",
    label: getCursorLabel(
      "Add Product to Cart",
      <BsFillBagPlusFill size={iconSize} />,
    ),
    valueType: "productVariant",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  updateCurrentProduct: {
    displayName: "Update Current Product",
    label: getCursorLabel(
      "Update Current Product",
      <BsFillBagPlusFill size={iconSize} />,
    ),
    valueType: "productWithOptionalVariant",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  // Note (Fran: 2022-11-10): This action for now doesn't have a ui to select
  // a product, the purpose of this is combine more than one add product
  // variant to cart into one action if is necessary.
  // TODO: Make UI for this and change the properties needed here.
  multipleProductVariantsAddToCart: {
    displayName: "Multiple Add Product to Cart",
    label: null,
    valueType: "none",
    applicableTriggers: [],
  },
  addVariantToTemporaryCart: {
    displayName: "Add Product to Temporary Cart",
    label: getCursorLabel(
      "Add Product to Temporary Cart",
      <BsFillBagPlusFill size={iconSize} />,
    ),
    valueType: "productVariant",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  removeVariantFromTemporaryCart: {
    displayName: "Remove Product from Temporary Cart",
    label: getCursorLabel(
      "Remove Product to Temporary Cart",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "productVariant",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  decreaseVariantCountInTemporaryCart: {
    displayName: "Remove One Of Product from Temporary Cart",
    label: getCursorLabel(
      "Remove One of Product to Temporary Cart",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "productVariant",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  updateCart: {
    displayName: "Update Cart",
    label: getCursorLabel(
      "Update Cart",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "product",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  close: {
    displayName: "Close Popup",
    label: getCursorLabel(
      "Close Popup",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  closeModalComponent: {
    displayName: "Close Popup",
    label: getCursorLabel(
      "Close Popup",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  addTemporaryCartProductsToCart: {
    displayName: "Move Temporary Cart to Real Cart",
    label: getCursorLabel(
      "Move Temporary Cart to Real Cart",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  scrollToNextCarouselItem: {
    displayName: "Move To Next Carousel Item",
    label: getCursorLabel(
      "Move To Next Carousel Item",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  scrollToPreviousCarouselItem: {
    displayName: "Move To Previous Carousel Item",
    label: getCursorLabel(
      "Move To Previous Carousel Item",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  scrollToSpecificCarouselItem: {
    displayName: "Move To Carousel Item",
    label: getCursorLabel(
      "Move To Previous Carousel Item",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "integer",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  goToItem: {
    displayName: "Go To Slide",
    label: getCursorLabel(
      "Go To Slide",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "integer",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  goToNextItem: {
    displayName: "Go To Next Slide",
    label: getCursorLabel(
      "Go To Next Slide",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  goToPrevItem: {
    displayName: "Go To Previous Slide",
    label: getCursorLabel(
      "Go To Previous Slide",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  setSelectedListItem: {
    displayName: "Set Selected List Item",
    label: getCursorLabel(
      "Set Selected List Item",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  toggleCollapsible: {
    displayName: "Toggle Collapsible",
    label: getCursorLabel(
      "Toggle Collapsible",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  togglePlay: {
    displayName: "Toggle Play",
    label: getCursorLabel(
      "Toggle Play",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  toggleMute: {
    displayName: "Toggle Mute",
    label: getCursorLabel(
      "Toggle Mute",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  toggleFullScreen: {
    displayName: "Toggle Fullscreen",
    label: getCursorLabel(
      "Toggle Fullscreen",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  setCurrentCollectionSelection: {
    displayName: "Set Collection Detail Selection",
    label: getCursorLabel(
      "Set Collection Detail Selection",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "dataTableRow",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  scrollContainerLeft: {
    displayName: "Scroll container left",
    label: getCursorLabel(
      "Scroll container left",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "pixels",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  scrollContainerRight: {
    displayName: "Scroll container right",
    label: getCursorLabel(
      "Scroll container right",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "pixels",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  scrollToUrlHashmark: {
    displayName: "Scroll to a URL hashmark",
    label: getCursorLabel(
      "Scroll to a URL hashmark",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "offsetHashmark",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  openModal: {
    displayName: "Open a popup",
    label: getCursorLabel(
      "Open a popup",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "modalComponent",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  openKlaviyoModal: {
    displayName: "Open a Klaviyo Popup",
    label: getCursorLabel(
      "Open a Klaviyo Popup",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "klaviyoComponent",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  toggleDropdown: {
    displayName: "Open/close the dropdown",
    label: getCursorLabel(
      "Open/close the dropdown",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  setDropdownItem: {
    displayName: "Set selection of the dropdown",
    label: getCursorLabel(
      "Set selection of the dropdown",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  setActiveVariant: {
    displayName: "Set the selected variant of a Product",
    label: getCursorLabel(
      "Set the selected variant of a Product",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  setActiveOptionValue: {
    displayName: "Set the selected option of a Product",
    label: getCursorLabel(
      "Set the selected option of a Product",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  setActiveSellingPlan: {
    displayName: "Set selected selling plan",
    label: getCursorLabel(
      "Set selected selling plan",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "sellingPlan",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  increaseProductQuantity: {
    displayName: "Add Product Quantity",
    label: getCursorLabel(
      "Add Product Quantity",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "productQuantity",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  setProductQuantity: {
    displayName: "Set Product Quantity To",
    label: getCursorLabel(
      "Set Product Quantity To",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "productQuantity",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  decreaseProductQuantity: {
    displayName: "Reduce Product Quantity",
    label: getCursorLabel(
      "Reduce Product Quantity",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "productQuantity",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
  activateTabId: {
    displayName: "Activate Tab",
    label: getCursorLabel(
      "Activate Tab",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "none",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  setActiveTabIndex: {
    displayName: "Activate Tab",
    label: getCursorLabel(
      "Activate Tab",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "tabIndex",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  executeJavascript: {
    displayName: "Run Javascript",
    label: getCursorLabel("Run Javascript", <BsFront size={iconSize} />),
    valueType: "jsCodeEditor",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  setActiveAlchemyVariant: {
    displayName: "Activate State",
    label: getCursorLabel(
      "Activate State",
      <BsFillLightningChargeFill size={iconSize} />,
    ),
    valueType: "alchemyVariant",
    applicableTriggers: [
      AlchemyActionTrigger.OnClick,
      AlchemyActionTrigger.OnHover,
    ],
  },
  phoneNumber: {
    displayName: "Start phone call",
    label: getCursorLabel(
      "Start phone call",
      <BsFillTelephoneFill size={iconSize} />,
    ),
    valueType: "url",
    applicableTriggers: [AlchemyActionTrigger.OnClick],
  },
};

export type ActionValueRenderExtras = {
  element: ReploElement | null;
  actionType: AlchemyActionType;
  products: StoreProduct[];
  componentId: string;
  symbols?: Record<string, ReploSymbol> | null;
  componentContext?: Context | null;
  templateProduct: StoreProduct | null;
};
export type ActionValueEditorData<ValueType> = {
  isValid(value: unknown, extras?: Partial<ActionValueRenderExtras>): boolean;
  render(
    value: ValueType | null | undefined,
    onChange: (value: ValueType | null) => void,
    extras: ActionValueRenderExtras,
  ): React.ReactNode;
};
export const getActionValueTypeToEditorData = (
  actionValueType: AlchemyActionValueType,
) => {
  return getFromRecordOrNull(actionValueTypeToEditorData, actionValueType);
};

type ActionValueTypeToEditorDataRecord = {
  [K in AlchemyActionValueType]: ActionValueEditorData<
    ActionValueTypeValueOf<K>
  >;
};

const actionValueTypeToEditorData: ActionValueTypeToEditorDataRecord = {
  none: {
    isValid: () => true,
    render: () => {
      return null;
    },
  },
  string: {
    isValid: (value) => {
      return typeof value === "string" ? value.length > 0 : false;
    },
    render: (value, onChange) => {
      return (
        <Input
          autoFocus={true}
          size="sm"
          value={value ?? undefined}
          placeholder="Add value..."
          onChange={(e) => onChange(e.target.value)}
        />
      );
    },
  },
  multilineString: {
    isValid: (value) => {
      return typeof value === "string" ? value.length > 0 : false;
    },
    render: (value, onChange: (value: string) => void) => {
      return (
        <Textarea
          autoFocus={true}
          value={value ?? undefined}
          className="w-full"
          size="base"
          placeholder="Code"
          onChange={onChange}
        />
      );
    },
  },
  discountCode: {
    isValid: (value) => {
      return typeof value === "string" ? value.length > 0 : false;
    },
    render: (value, onChange) => {
      return (
        <div className="flex flex-col items-start">
          <Input
            autoFocus={true}
            size="sm"
            value={value ?? undefined}
            placeholder="Discount Code"
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      );
    },
  },
  url: {
    isValid: (
      data: string | Partial<LinkData> | null,
      extras: Partial<ActionValueRenderExtras>,
    ) => {
      if (typeof data === "string") {
        return Boolean(data);
      }
      if (extras.actionType === "phoneNumber") {
        return /[^\D-+()]/g.test(data?.url ?? "");
      }
      // Note (Noah, 2024-01-12, USE-662): Types are wrong here, but isValid is
      // called during when the user is editing the action. We want to make sure
      // the user can't save a value that doesn't have a URL, since that would
      // cause render errors.
      return Boolean(data?.url);
    },
    render: (
      data: LinkData,
      onChange: (data: LinkData | string) => void,
      extras,
    ) => {
      const placeholders: Partial<Record<AlchemyActionType, string>> = {
        redirect: "https://www.mywebsite.com",
        phoneNumber: "5555555555",
      };
      return (
        <LinkEditor
          allowsDynamicData
          data={data}
          onChange={(linkData: LinkData | string) => {
            onChange(
              typeof linkData === "string"
                ? linkData
                : { ...(data as LinkData), ...(linkData as LinkData) },
            );
          }}
          placeholder={placeholders[extras.actionType] ?? "URL"}
          type={extras.actionType}
        />
      );
    },
  },
  product: {
    isValid: () => true,
    render: (value, onChange) => {
      return (
        <ProductSelector
          selectedProductRef={value ?? null}
          onChange={(productRef) => {
            onChange(
              productRef && !isContextRef(productRef)
                ? // Note (Ovishek, 2022-11-30, REPL-5308): We are excluding variantId from here, there is a case
                  // when variant ids might get changed in shopify then this redirection doesn't work. Also product
                  // page redirection doesn't need to know about variant id at all.
                  { id: productRef.id, productId: productRef.productId }
                : productRef,
            );
          }}
          isMultiProducts={false}
        />
      );
    },
  },
  productRedirect: {
    isValid: () => true,
    render: (value, onChange) => {
      return (
        <div className="flex flex-col gap-2">
          <ProductSelector
            selectedProductRef={value?.product ?? null}
            onChange={(productRef) => {
              onChange({
                product:
                  productRef && !isContextRef(productRef)
                    ? // Note (Ovishek, 2022-11-30, REPL-5308): We are excluding variantId from here, there is a case
                      // when variant ids might get changed in shopify then this redirection doesn't work. Also product
                      // page redirection doesn't need to know about variant id at all.
                      {
                        id: productRef.id,
                        productId: productRef.productId,
                      }
                    : productRef,
                openInNewTab: value?.openInNewTab ?? false,
              });
            }}
            isMultiProducts={false}
            showPlaceholders={false}
          />
          <div className="mt-2 flex w-full flex-row justify-between">
            <span className="text-xs text-slate-400">Open new tab</span>
            <Switch
              isOn={value?.openInNewTab ?? false}
              onChange={(checked) => {
                onChange({
                  product: value?.product ?? null,
                  openInNewTab: checked,
                });
              }}
              thumbColor="#fff"
              backgroundOnColor="bg-blue-600"
            />
          </div>
        </div>
      );
    },
  },
  productWithOptionalVariant: {
    isValid: () => true,
    render: (value, onChange, extras) => {
      return (
        <ProductWithOptionalVariant
          value={value ?? null}
          onChange={onChange}
          extras={extras}
        />
      );
    },
  },
  productVariant: {
    // Note / TODO (Noah, 2023-11-06, USE-537, REPL-9181, REPL-9184): This is to
    // make sure that we avoid issues where the product for an add product to
    // cart action is undefined. Currently our types assume it's defined.
    // However, this is brittle because there's other things we're assuming
    // about actions due to the fact that our action validation system does not
    // actually use defined schemas. What we really should do is make it so that
    // all our actions are defined by schemas, and if the schema parsing fails
    // we don't let the user save
    isValid: (value: AddProductVariantToCartEditorPropsValue | null) => {
      return Boolean(value?.product);
    },
    render: (
      // TODO (Noah, 2022-08-08, REPL-3428): Specifying this type shouldn't be
      // necessary, this entire mapping should be inside a mapped type so typescript
      // can infer it
      value: AddProductVariantToCartEditorPropsValue | null,
      onChange,
      extras,
    ) => {
      return (
        <AddProductVariantToCartEditor
          value={value}
          onChange={onChange}
          extras={extras}
        />
      );
    },
  },
  dataTableRow: {
    isValid: (value) => value !== null,
    render: (value, onChange) => {
      return (
        <DataTableRowSelect
          className="mb-2 w-full"
          field="props.items"
          value={value ?? undefined}
          onChange={onChange}
        />
      );
    },
  },
  pixels: {
    isValid: (value) => value !== null,
    render: (value, onChange) => {
      return (
        <LengthInputSelector
          value={value ? `${value.pixels}px` : null}
          onChange={(stringValue: string) => {
            onChange({ pixels: Number.parseInt(stringValue) });
          }}
          draggingType={DraggingTypes.Vertical}
          field="ActionPixels"
          placeholder="0px"
          metrics={CSS_LENGTH_TYPES}
          minValues={{ px: 0 }}
          allowsNegativeValue={false}
        />
      );
    },
  },
  integer: {
    isValid: (value) => value !== null,
    render: (value, onChange) => {
      return (
        <Input
          autoFocus={true}
          size="sm"
          value={value ?? undefined}
          placeholder="0"
          onChange={(event) => {
            const value = event.target.value.trim();
            if (value === "") {
              onChange(null);
            } else {
              const numericValue = parseInteger(value);
              if (!Number.isNaN(numericValue)) {
                onChange(numericValue);
              }
            }
          }}
        />
      );
    },
  },
  productQuantity: {
    isValid: (value) => value !== null,
    render: (value, onChange, { actionType }) => {
      return (
        <IntegerSelector
          label={
            // TODO (Noah, 2022-11-19): We shouldn't really have to check the action
            // type here, there should be some way to specify as part of the action
            // that the type is configurable with like, a `type: "increase" | "decrease"
            // | "set"` or something
            actionType === "setProductQuantity"
              ? "Quantity to set"
              : "Amount to change the quantity by"
          }
          value={value ?? 1}
          onChange={onChange}
        />
      );
    },
  },
  sellingPlan: {
    isValid: () => true,
    render: (value, onChange, extras) => {
      const product = extras.componentContext?.attributes?._product;
      const selectedSellingPlanId = value?.sellingPlanId;

      return (
        <SellingPlanSelector
          product={product}
          allowAllPlans={true}
          selectedSellingPlanId={selectedSellingPlanId ?? null}
          onChange={(newValue) =>
            onChange(
              newValue
                ? {
                    sellingPlanId: newValue,
                  }
                : null,
            )
          }
        />
      );
    },
  },
  tabIndex: {
    isValid: (value) =>
      Boolean(value && Number.parseInt(value as any) !== null),
    render: (value, onChange) => {
      return (
        <Input
          autoFocus={true}
          size="sm"
          value={value?.index}
          placeholder="0"
          onChange={(e) => {
            const stringValue = e.target.value;
            const numberValue = Number.parseInt(stringValue);
            if (stringValue && !Number.isNaN(numberValue)) {
              onChange({ index: numberValue });
            } else {
              onChange(null);
            }
          }}
        />
      );
    },
  },
  offsetHashmark: {
    isValid: (value: any) => {
      if (typeof value === "string") {
        return value && value.length > 0;
      }
      return (
        value?.hashmark &&
        value?.hashmark.length > 0 &&
        value?.offset !== undefined
      );
    },
    render: (value, onChange) => (
      <OffsetHashmarkSelector value={value} onChange={onChange} />
    ),
  },
  modalComponent: {
    isValid: (value) => value !== null,
    render: (value, onChange, extras: any) => {
      const element: ReploElement = extras.element;
      const possibleComponents: { id: string; name: string }[] = [];
      forEachComponentAndDescendants(element.component, (component) => {
        if (isModal(component.type)) {
          possibleComponents.push({
            id: component.id,
            name: component.name || "Popup",
          });
        }
      });
      return (
        <Selectable
          value={value?.componentId ?? undefined}
          placeholder="Select a popup..."
          options={possibleComponents.map((p) => {
            return { label: p.name, value: p.id };
          })}
          onSelect={(value) => onChange({ componentId: value })}
        />
      );
    },
  },
  klaviyoComponent: {
    isValid: (value) => value !== null,
    render: (value, onChange, extras) => {
      const component = extras.element?.component ?? null;
      const possibleComponents: { id: string; name: string }[] = [];
      forEachComponentAndDescendants(component, (component) => {
        if (component.type === "klaviyoEmbed") {
          possibleComponents.push({
            id: component.id,
            name: component.name || "Klaviyo popup",
          });
        }
      });
      return (
        <>
          <Selectable
            value={value?.componentId ?? undefined}
            placeholder="Select a Klaviyo popup..."
            options={possibleComponents.map((p) => {
              return { label: p.name, value: p.id };
            })}
            onSelect={(value) => onChange({ componentId: value })}
          />
          <span className="text-xs font-normal text-slate-400 mt-0.5">
            Note: Klaviyo component must be configured as a Popup in your
            Klaviyo Dashboard.{" "}
            <a
              className="text-blue-600 underline"
              target="_blank"
              href={docs.klaviyo}
              rel="noreferrer"
            >
              Read more
            </a>
            .
          </span>
        </>
      );
    },
  },
  alchemyVariant: {
    isValid: (value: any) => value?.componentId && value?.variantId,
    render: (value, onChange, extras) => {
      const { element, symbols = null } = extras;
      let possibleVariants: { id: string; name: string }[] = [];
      const possibleComponents: {
        id: string;
        name: string;
        variants: ReploState[];
      }[] = [];

      let componentName: string | null = null;

      const component = element?.component ?? null;
      forEachComponentAndDescendants(component, (component) => {
        const variants = component.variants;
        if (variants) {
          componentName = getComponentName(component, symbols);
          possibleComponents.push({
            id: component.id,
            name: componentName ?? "",
            variants,
          });
        }
        if (value?.componentId === component.id) {
          possibleVariants = (variants ?? []).map((v) => {
            let name = v.name || "Variant";
            if (name === "default") {
              name = "Default";
            }
            return {
              id: v.id,
              name,
            };
          });
        }
      });

      return (
        <>
          <Selectable
            options={possibleComponents.map((p) => {
              return { label: p.name, value: p.id };
            })}
            onSelect={(componentId) =>
              onChange({ componentId: componentId ?? undefined })
            }
            placeholder="Select a component..."
            value={value?.componentId}
          />

          {value?.componentId && (
            <Selectable
              className="mt-2"
              options={possibleVariants.map((p) => {
                return { label: p.name, value: p.id };
              })}
              onSelect={(variantId) =>
                onChange({
                  variantId: variantId ?? undefined,
                  componentId: value?.componentId,
                  type: extras?.actionType,
                })
              }
              placeholder="Select a state..."
              value={value?.variantId}
            />
          )}
        </>
      );
    },
  },
  jsCodeEditor: {
    isValid: (value) => value !== null,
    render: (value, onChange) => {
      return (
        <CodeEditorCustomPropModifier
          value={value ?? null}
          onChange={onChange}
          language="javascript"
        />
      );
    },
  },
};

function ProductWithOptionalVariant({
  value,
  extras,
  onChange,
}: {
  value: ProductRef | ContextRef | null;
  onChange: (value: ProductRef | ContextRef | null) => void;
  extras: ActionValueRenderExtras;
}) {
  const { activeCurrency, activeLanguage, moneyFormat } =
    useEditorSelector(selectLocaleData);
  const product = getProduct(
    value,
    getCurrentComponentContext(extras.componentId, 0) ?? null,
    {
      productMetafieldValues: {},
      variantMetafieldValues: {},
      products: extras.products,
      currencyCode: activeCurrency,
      language: activeLanguage,
      moneyFormat,
      templateProduct: extras.templateProduct,
      isEditor: true,
      isShopifyProductsLoading: false,
    },
  );
  return (
    <div className="flex flex-col gap-2">
      <ProductSelector
        selectedProductRef={value ?? null}
        onChange={(productRef) => {
          if (productRef == null) {
            onChange(null);
          } else {
            onChange(
              isContextRef(productRef)
                ? productRef
                : {
                    // Note (Noah, 2023-12-30): We set variantId to
                    // undefined because by default the productRef we get
                    // from ProductSelector has the default variant for the
                    // product as its variantId. When a product is changed
                    // here, we actually want to reset the variant id and
                    // make the user choose a new one. If they don't choose
                    // one, the default one will be used anyway, but this is
                    // more resistant to the user deleting the selected
                    // variant in Shopify, etc
                    variantId: undefined,
                    productId: Number(productRef.productId),
                  },
            );
          }
        }}
        isMultiProducts={false}
      />
      {product?.variants &&
        product.variants.length > 1 &&
        value &&
        !isContextRef(value) && (
          <LabeledControl label="Variant" size="sm">
            <SelectablePopover
              title="Variants"
              itemSize={40}
              itemsOnViewCount={5}
              selectedItems={value?.variantId ? [value.variantId] : []}
              options={product.variants.map((v) => ({
                label: v.title,
                isSelectable: true,
                isActive: value.variantId === v.id,
                value: v.id,
              }))}
              isRemovable={false}
              onSelect={(newSelectedVariantId: number) => {
                onChange({
                  ...value,
                  variantId: newSelectedVariantId,
                });
              }}
            >
              <span className="text-slate-600">
                {value.variantId
                  ? product?.variants.find((v) => v.id === value.variantId)
                      ?.title
                  : "Select Variant"}
              </span>
            </SelectablePopover>
          </LabeledControl>
        )}
    </div>
  );
}
